import React from 'react';
import { TextAreaField } from '../../Components/utils/InputField';
import { UseDcDialButton } from '../../Components/utils/Button';

const SlowDownComp = ({
  inputValue,
  handleChangeText,
  titleText,
  text,
  name,
  placeholderT,
  isImage,
  buttonText,
  image,
  buttonWidth,
  isInput,
}) => {
  return (
    <div className="mt-[100px] w-[424px] h-max rounded-[24px] bg-white py-[30px] px-10 flex flex-col gap-6 shadow-[0_1px_2px_0_#f3f8fc]">
      <p className="paragraphText font-bold text-LightPrimary text-center">
        {titleText}
      </p>
      {isImage && (
        <>
          <div className="min-h-[210px]">
            <img src={image} alt={name} />
          </div>
        </>
      )}
      {isInput && (
        <TextAreaField
          type="text"
          value={inputValue}
          placeholder={placeholderT}
          name={name}
          handleOnChange={handleChangeText}
          background="#F9F9FB"
          radius="8px"
          row="4"
          fontSize="14px"
        />
      )}
      {text && (
        <p
          className="text-textSmall font-DMSansFont font-normal text-LightPrimary"
          dangerouslySetInnerHTML={{ __html: text }}
        ></p>
      )}

      <div className="flex justify-center">
        <UseDcDialButton
          width={buttonWidth ? buttonWidth : '236px'}
          title={buttonText}
        />
      </div>
    </div>
  );
};

export default SlowDownComp;
