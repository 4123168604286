import React from 'react';
import { useTranslation } from 'react-i18next';
import BgAnimatedLayer from './BgAnimatedLayer';
import { useNavigate } from 'react-router-dom';

const ContinueWithDC = ({ title, subTitle1, isbreakable, subTitle2, link }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="relative w-full h-[550px] max_sm:h-[450px] flex justify-center items-center overflow-hidden z-0">
      <div className="flex flex-col items-center gap-[40px] z-50">
        <h2 className="headingText max_sm:px-5 max_sm:text-center">{title}</h2>
        <p
          className={`text-center paragraphText max_md:px-10 max_sm:px-5 ${
            isbreakable ? 'max_md:hidden' : ''
          }`}
          dangerouslySetInnerHTML={{ __html: subTitle1 }}
        ></p>
        {/* {isbreakable && ( */}
        <p
          className={`hidden text-center paragraphText max_md:px-10 max_sm:px-5 ${
            isbreakable ? 'max_md:block' : ''
          }`}
        >
          {subTitle2}
        </p>
        {/* )} */}

        {link ? (
          <>
            <button
              className="rounded-[10px] text-center max_md:mt-[30px] cursor-pointer bg-ButtonPrimary hover:bg-ButtonPrimaryHover"
              onClick={() => {
                navigate(link);
              }}
            >
              <div className="text-white subParagraphText p-4 max_sm:p-3">
                {t('ContinueDCDial')}
              </div>
            </button>
          </>
        ) : (
          <>
            <button
              className="rounded-[10px] text-center max_md:mt-[30px] cursor-pointer bg-ButtonPrimary hover:bg-ButtonPrimaryHover"
            >
              {/* <a
                href="https://www.dcdial.com/signup/?v=5&d=m/"
                rel="noopener noreferrer"
                target="_blank"
              > */}
                <div className="text-white subParagraphText p-4 max_sm:p-3">
                  {t('ContinueDCDial')}
                </div>
              {/* </a> */}
            </button>
          </>
        )}
      </div>
      <div className="animated-layer absolute w-[1440px] h-full max_md:w-[100%]">
        <BgAnimatedLayer />
      </div>
    </div>
  );
};

export default ContinueWithDC;
