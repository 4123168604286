import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import CommonSection from '../CommonSection';
import { AccessIcon, ContingencyIcon, ControlIcon, TransparencyIcon } from '../../assets/Icons/Security/SecurityIcon';

const DataHandling = () => {
  const { t } = useTranslation();
  const [dataHandlingSer, setDataHandlingSer] = useState([])
  useEffect(() => {
    setDataHandlingSer(getTranslatedList(t))
  },[t])

  return (
    <CommonSection>
      <div className="flex justify-between mb-[80px] max_md:mb-10 max_md:flex-col max_md:gap-[60px]">
        <div className="max-w-[440px] max_xl:max-w-[392px] max_md:max-w-full">
          <p className="text50">{t('DCDialDataHandling')}</p>
          <p className="mt-4 subHeadingText font-normal text-LightTextParagraph max_md:text-headingNormal max_sm:text-textSmall">
            {t('DCDialDataHandlingText')}
          </p>
        </div>
        <div className="flex flex-wrap gap-[50px] max-w-[550px] max_xl:gap-[30px] max_xl:max-w-[530px]">
          {dataHandlingSer.map((service, index) => (
            <div key={index} className="max-w-[250px] ">
              <div>{service.icon}</div>
              <div className="mt-6 flex gap-[6px] items-center">
                <div className="w-[3px] h-5 bg-LightPrimary rounded"></div>
                <p className="subHeadingText tracking-[-1px] max_md:text-[24px] max_sm:text-headingNormal">
                  {service.title}
                </p>
              </div>
              <p className="mt-4 subParagraphText max_md:text-headingNormal max_sm:text-textSmall font-normal tracking-[-1px]">
                {service.text}
              </p>
            </div>
          ))}
        </div>
      </div>
    </CommonSection>
  );
}
const getTranslatedList = (t) => {
  const services = [
    {
      title: t('Transparency'),
      text: t('TransparencyT'),
      icon: <TransparencyIcon />,
    },
    {
      title: t('Control'),
      text: t('ControlT'),
      icon: <ControlIcon />,
    },
    {
      title: t('AccessAwareness'),
      text: t('AccessAwarenessT'),
      icon: <AccessIcon />,
    },
    {
      title: t('ContingencyPlanning'),
      text: t('ContingencyPlanningT'),
      icon: <ContingencyIcon />,
    },
  ];


  return services;
};


export default DataHandling