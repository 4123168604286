import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css'; // Core Swiper
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import SwiperCore from 'swiper';
import {
  LeftArrowIcon,
  RightArrowIcon,
  RoundIcon,
} from '../../assets/Icons/icons';
import WhiteBg from '../../assets/images/Homepage/Backgroung.svg';
import Lottie from 'react-lottie';
// import PaymentDetailsEn from '../../animation/en/Productivity/Payment_Detail.json';
// import ProcessPaymentEn from '../../animation/en/Productivity/Process_Payment.json';
// import AIAssistantEn from '../../animation/en/Productivity/AI_Assistant.json';
// import ContactDetailsEn from '../../animation/en/Productivity/Contact_Detail.json';
import CommonSizeContainer from '../CommonSizeContainer';
import useVisibility from '../../Hooks/useVisibility';

SwiperCore.use([Pagination]);
const Potential = () => {
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const animationContainerRef = useRef(null);
  const [playAnimation, setPlayAnimation] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const isInView = useVisibility(animationContainerRef, 0.2);
  const [toBeVisible, setToBeVisible] = useState(false);
  const [animationData, setAnimationData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!toBeVisible && isInView) {
      setToBeVisible(true);
    }
  }, [isInView]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setPlayAnimation(true);
        } else {
          setPlayAnimation(false);
        }
      },
      {
        root: null, // viewport
        threshold: 0.7,
      }
    );

    if (animationContainerRef.current) {
      observer.observe(animationContainerRef.current);
    }

    return () => {
      if (animationContainerRef.current) {
        observer.unobserve(animationContainerRef.current);
      }
    };
  }, []);

  const lottieRefs = useRef([]);

  // Swiper's slideChange event to update activeSlideIndex
  useEffect(() => {
    const swiper = swiperRef1.current.swiper;

    const onSlideChange = () => {
      setActiveSlideIndex(swiper.activeIndex);
    };

    swiper.on('slideChange', onSlideChange);

    // Cleanup
    return () => {
      swiper.off('slideChange', onSlideChange);
    };
  }, []);

  useEffect(() => {
    lottieRefs.current.forEach((ref, index) => {
      if (ref) {
        if (playAnimation && index === activeSlideIndex) {
          ref.play();
        } else {
          ref.stop();
        }
      }
    });
  }, [playAnimation, activeSlideIndex, toBeVisible]);

  const swiperRef1 = useRef(null);
  const swiperRef2 = useRef(null);
  const handleNext = () => {
    swiperRef1.current.swiper.slideNext();
    swiperRef2.current.swiper.slideNext();
    updateButtonState();
  };

  const handlePrev = () => {
    swiperRef1.current.swiper.slidePrev();
    swiperRef2.current.swiper.slidePrev();
    updateButtonState();
  };


  const updateButtonState = () => {
    if (swiperRef1.current && swiperRef2.current) {
      setIsBeginning(swiperRef1.current.swiper.isBeginning);
      setIsEnd(swiperRef1.current.swiper.isEnd);
    }
  };

  // const PaymentDetails = {
  //   loop: false,
  //   autoplay: false,
  //   animationData: PaymentDetailsEn,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice',
  //   },
  // };
  // const ProcessPayment = {
  //   loop: false,
  //   autoplay: false,
  //   animationData: ProcessPaymentEn,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice',
  //   },
  // };
  // const AIAssistant = {
  //   loop: false,
  //   autoplay: false,
  //   animationData: AIAssistantEn,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice',
  //   },
  // };
  // const ContactDetails = {
  //   loop: false,
  //   autoplay: false,
  //   animationData: ContactDetailsEn,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice',
  //   },
  // };

    const loadAnimation = async (url, key) => {
      const response = await fetch(url);
      const data = await response.json();
      setAnimationData((prevData) => ({ ...prevData, [key]: data }));
      setIsLoading(false);
    };

    useEffect(() => {
      const animations = {
        PaymentDetails:
          'https://images.dcdial.com/dcdial.com/v2/Payment_Detail.json',
        ProcessPayment:
          'https://images.dcdial.com/dcdial.com/v2/Process_Payment.json',
        AIAssistant:
          'https://images.dcdial.com/dcdial.com/v2/AI_Assistant.json',
        ContactDetails:
          'https://images.dcdial.com/dcdial.com/v2/Contact_Detail.json',
      };

      Object.keys(animations).forEach((key) => {
        loadAnimation(animations[key], key);
      });
    }, []);
  
  const createLottieOptions = (animationKey) => ({
    loop: false,
    autoplay: false,
    animationData: animationData[animationKey],
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  });
  return (
    <CommonSizeContainer>
      <div className="bg-BgPrimary rounded-[30px] flex pt-[80px] max_xl:pt-[50px] max_md:flex-col max_md:gap-5">
        <div
          className="w-[55%] flex items-end justify-center max_md:w-full"
          ref={animationContainerRef}
        >
          <div className="relative w-[70%] max_md:w-[62%] max_720:w-[88%] flex justify-center max_sm:w-[92%]">
            <img src={WhiteBg} alt="background_img" />
            <div className="absolute bottom-0 w-[60%] flex justify-center">
              <Swiper
                pagination={false}
                modules={[Pagination]}
                className="mySwiper"
                ref={swiperRef1}
              >
                <SwiperSlide>
                  {toBeVisible && (
                    <>
                      <div>
                        <Lottie
                          options={createLottieOptions('PaymentDetails')}
                          // options={PaymentDetails}
                          ref={(el) => (lottieRefs.current[0] = el)}
                        />
                      </div>
                    </>
                  )}
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <Lottie
                      options={createLottieOptions('ProcessPayment')}
                      // options={ProcessPayment}
                      ref={(el) => (lottieRefs.current[1] = el)}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <Lottie
                      options={createLottieOptions('AIAssistant')}
                      // options={AIAssistant}
                      ref={(el) => (lottieRefs.current[2] = el)}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <Lottie
                      options={createLottieOptions('ContactDetails')}
                      // options={ContactDetails}
                      ref={(el) => (lottieRefs.current[3] = el)}
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
        <div className="w-[42%] right-container pb-5 max_md:w-full">
          <div>
            <div className="commonHeadingFea  max-w-[410px] relative z-10 max_md:text-center max_md:m-auto max_sm_380:leading-[42px] max_sm_380:px-5 max_md:mt-2">
              Unlock Your Potential
              <span className="absolute left-[230px] max_sm:left-[300px] max_sm_380:left-[222px] top-[-12px] z-[-10] max_sm:w-[13%] max_md:top-[0px] max_md:right-0 max_sm:top-[-10px] max_esm:left-[185px] max_md:left-auto">
                <RoundIcon style={{ width: '100%' }} />
              </span>
            </div>
          </div>
          <Swiper
            pagination={true}
            modules={[Pagination]}
            className="mySwiper"
            ref={swiperRef2}
          >
            <SwiperSlide>
              <p className="paragraphText max_md:px-5 max_md:text-center max_md:mt-2">
                Boost your outreach and connect with your audience through
                dynamic voice and text campaigns, designed to engage and
                inspire.
              </p>
            </SwiperSlide>
            <SwiperSlide>
              <p className="paragraphText max_md:px-5 max_md:text-center max_md:mt-2">
                Embrace the future of finance with automated payments: swift,
                secure, and simple.
              </p>
            </SwiperSlide>
            <SwiperSlide>
              <p className="paragraphText max_md:px-5 max_md:text-center max_md:mt-2">
                Elevate your productivity with AI Assistant, your virtual
                sidekick empowering you to conquer tasks with ease and
                efficiency.
              </p>
            </SwiperSlide>
            <SwiperSlide>
              <p className="paragraphText paragraphText max_md:px-5 max_md:text-center max_md:mt-2">
                Manage your contacts effortlessly, ensuring every connection is
                nurtured and every opportunity seized.
              </p>
            </SwiperSlide>
          </Swiper>

          <div className="flex gap-3 mt-[30px] max_xl:mt-5 max_md:justify-center">
            <div
              className={`bg-white rounded-[14px] p-3 ${
                isBeginning ? '' : 'cursor-pointer'
              }`}
              onClick={handlePrev}
            >
              <LeftArrowIcon color={isBeginning ? '#8C90A5' : '#19224C'} />
            </div>
            <div
              className={`bg-white rounded-[14px] p-3 ${
                isEnd ? '' : 'cursor-pointer'
              }`}
              onClick={() => handleNext()}
            >
              <RightArrowIcon color={isEnd ? '#8C90A5' : '#19224C'} />
            </div>
          </div>
        </div>
      </div>
    </CommonSizeContainer>
  );
};

export default Potential;
