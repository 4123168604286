import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Navbar from '../Components/Navbar';
import { CSSTransition } from 'react-transition-group';
import Footer from './Footer';
import MobileMenu from './MobileMenu';
import ModalCustom from './utils/ModalCustom';
import { useTranslation } from 'react-i18next';
import CookiesConsent from './Cookies/CookiesConsent';
import ManageCookies from './Cookies/ManageCookies';
import { useCookies } from 'react-cookie';
import moment from 'moment';

const Layout = ({ children }) => {
  const nodeRef = useRef(null);
  const { t } = useTranslation();
  const outsideRef = useRef(null);
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [manageClicked, setManageClicked] = useState(false);
  const [navbarChangeColor, setNavbarChangeColor] = useState(false);
  // useOnClickOutside(outsideRef, () => setIsMobileMenuOpen(false));
  const [activeLangauge, setActiveLangauge] = useState('عربي');
  // const [langaugeList] = useState([t('English'), t('Arabic')]);
  // useEffect(() => {
  //   const isLang = localStorage.getItem('lang');
  //   const getLang = document.getElementById('main-app');
  //   if (isLang) {
  //     if (isLang === 'ar') {
  //       setActiveLangauge('English');
  //       getLang.dir = 'rtl';
  //     } else {
  //       getLang.dir = 'ltr';
  //       setActiveLangauge('عربي');
  //     }
  //   } else {
  //     localStorage.setItem('lang', 'en');
  //     getLang.dir = 'ltr';
  //   }
  // }, []);

  const [cookies, setCookie] = useCookies([
    'cookie_consent',
    'Analytics_Cookies',
    'Functional_Cookies',
    'Targeting_Cookies',
  ]);

  useEffect(() => {
    if (cookies.cookie_consent === undefined) {
      setIsModalOpen(true);
    }
  }, []);

  const handleSelection = (id, data) => {
    if (id === 'accept') {
      setCookie('cookie_consent', true, {
        expires: moment().add(1, 'months').toDate(),
      });
      setIsModalOpen(false);
    } else if (id === 'reject') {
      setCookie('cookie_consent', false, {
        expires: moment().add(1, 'months').toDate(),
      });
      setIsModalOpen(false);
    } else if (id === 'allow-all') {
      setCookie('cookie_consent', true, {
        expires: moment().add(1, 'months').toDate(),
      });
      setCookie('Analytics_Cookies', true, {
        expires: moment().add(1, 'months').toDate(),
      });
      setCookie('Functional_Cookies', true, {
        expires: moment().add(1, 'months').toDate(),
      });
      setCookie('Targeting_Cookies', true, {
        expires: moment().add(1, 'months').toDate(),
      });
      setIsModalOpen(false);
    } else if (id === 'reject-all') {
      setCookie('cookie_consent', false, {
        expires: moment().add(1, 'months').toDate(),
      });
      setCookie('Analytics_Cookies', false, {
        expires: moment().add(1, 'months').toDate(),
      });
      setCookie('Functional_Cookies', false, {
        expires: moment().add(1, 'months').toDate(),
      });
      setCookie('Targeting_Cookies', false, {
        expires: moment().add(1, 'months').toDate(),
      });
      setIsModalOpen(false);
    } else {
      setCookie('cookie_consent', true, {
        expires: moment().add(1, 'months').toDate(),
      });
      data.forEach((cookie) => {
        if (cookie.checked) {
          setCookie(cookie.id, true, {
            expires: moment().add(1, 'months').toDate(),
          });
        } else {
          setCookie(cookie.id, false, {
            expires: moment().add(1, 'months').toDate(),
          });
        }
      });
      setIsModalOpen(false);
    }
  };

  const changeBackground = () => {
    if (window.scrollY >= 20) {
      setNavbarChangeColor(true);
    } else {
      setNavbarChangeColor(false);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', changeBackground);
      // cleanup function
      return () => {
        window.removeEventListener('scroll', changeBackground);
      };
    }
  }, []);

  function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = React.useState(null);

    React.useEffect(() => {
      let lastScrollY = window.pageYOffset;

      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        const direction = scrollY > lastScrollY ? 'down' : 'up';
        if (
          direction !== scrollDirection &&
          (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
        ) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener('scroll', updateScrollDirection); // add event listener
      return () => {
        window.removeEventListener('scroll', updateScrollDirection); // clean up
      };
    }, [scrollDirection]);

    return scrollDirection;
  }

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden !important';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMobileMenuOpen]);

  useEffect(() => {
    if (isModalOpen && location.pathname !== '/legal/cookie-policy') {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isModalOpen]);

  const scrollDirection = useScrollDirection();

  return (
    <>
      <div className="relative w-full">
        <div
          className={`sticky ${
            scrollDirection === 'down' ? '-top-24' : 'top-0'
          } ${isModalOpen ? '' : 'z-50'} transition-all duration-500`}
        >
          <div
            className={`relative m-auto w-full ${
              navbarChangeColor
                ? 'bg-white shadow-sm transition'
                : location.pathname === '/' ||
                  location.pathname === '/contact/get-in-touch' ||
                  location.pathname === '/legal/terms-of-service' ||
                  location.pathname === '/legal/product-specific-terms' ||
                  location.pathname === '/legal/acceptable-use' ||
                  location.pathname === '/legal/developer-policy' ||
                  location.pathname === '/legal/developer-terms' ||
                  location.pathname === '/legal/dpa' ||
                  location.pathname === '/legal/website-terms-of-use' ||
                  location.pathname === '/legal/website-accessibility' ||
                  location.pathname === '/legal/privacy-policy' ||
                  location.pathname === '/legal/cookie-policy' ||
                  location.pathname === '/legal/digital-services-act'
                ? 'bg-transparent transition bg-BgPrimaryLight'
                : location.pathname === '/chrome/uninstall-feedback' ||
                  location.pathname === '/chrome/uninstall-success'
                ? 'bg-white shadow-[0_1px_2px_0_#f3f8fc]'
                : 'bg-transparent transition bg-white'
            }`}
          >
            <Navbar
              setIsMobileMenuOpen={setIsMobileMenuOpen}
              activeLangauge={activeLangauge}
              setActiveLangauge={setActiveLangauge}
              isModalOpen={isModalOpen}
            />

            <CSSTransition
              in={isMobileMenuOpen}
              nodeRef={nodeRef}
              timeout={300}
              classNames="menu"
              type={'out-in' | 'in-out'}
              unmountOnExit
              onEnter={() => setIsMobileMenuOpen(true)}
              onExited={() => setIsMobileMenuOpen(false)}
            >
              <MobileMenu
                nodeRef={nodeRef}
                outsideRef={outsideRef}
                setIsMobileMenuOpen={setIsMobileMenuOpen}
                activeLangauge={activeLangauge}
              />
            </CSSTransition>
          </div>
        </div>
        <div>{children}</div>
        {location.pathname !== '/chrome/uninstall-feedback' && (
          <>
            {location.pathname !== '/chrome/uninstall-success' && (
              <div>
                <Footer />
              </div>
            )}
          </>
        )}
      </div>
      <ModalCustom
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        manageClicked={manageClicked}
      >
        {manageClicked ? (
          <>
            <ManageCookies
              setManageClicked={setManageClicked}
              handleSelection={handleSelection}
            />
          </>
        ) : (
          <>
            <CookiesConsent
              handleReject={() => setIsModalOpen(false)}
              handleManage={() => setManageClicked(true)}
              handleSelection={handleSelection}
            />
          </>
        )}
      </ModalCustom>
    </>
  );
};

export default Layout;
