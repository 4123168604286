import React, { useRef, useState } from 'react';
import { DropDownArrow } from '../../assets/Icons/icons';
import { useOnClickOutside } from '../../Hooks/useOutSideClick';
import { SearchIcon } from '../../assets/Icons/IntegrationPayment/paymentIcon';
import NotVisible from '../../assets/images/Login/Notvisible.svg';
import Visible from '../../assets/images/Login/visible.svg';
// import { getStates } from 'country-state-picker';
import { ErrorIcon } from '../../assets/Icons/Features/FeatureIcon';
// import { State } from 'country-state-city';
import StateList from './CountryList';

export const InputField = ({
  type,
  placeholder,
  handleOnChange,
  name,
  error,
  value,
}) => {
  return (
    <div>
      <input
        style={{ border: error ? '1px solid #FF0000' : '' }}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={(e) => handleOnChange(e, name)}
        className={`w-full outline-none border-[1px] border-LightOutline py-3 px-5 rounded-[30px] font-DMSansFont text-textSmall text-LightPrimary ${
          value !== '' ? 'font-semibold' : 'font-medium'
        }`}
      />
    </div>
  );
};
export const TextField = ({
  type,
  placeholder,
  handleOnChange,
  name,
  error,
  value,
  label,
  errorMsg,
  handleOnBlur,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  return (
    <div>
      <p className="labelClass">{label}</p>
      <div className="w-full relative">
        <input
          autoComplete="new-password"
          style={{ border: error ? '1px solid #FF0000' : '' }}
          type={passwordVisible && name === 'password' ? 'text' : type}
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={(e) => handleOnChange(e)}
          onBlur={() => {
            if (handleOnBlur) handleOnBlur(name);
          }}
          className={`w-full outline-none border-[1px] border-LightOutline py-[15px] px-[15px] rounded-[7px] font-DMSansFont text-textExtraSmall text-LightPrimary font-normal placeholder:text-LightSecondary`}
        />

        {type === 'password' && (
          <>
            <div
              className="absolute top-[14px] right-[15px] cursor-pointer"
              onClick={() => setPasswordVisible(!passwordVisible)}
            >
              <img
                src={passwordVisible ? Visible : NotVisible}
                alt="visibleIcon"
              />
            </div>
          </>
        )}
      </div>
      {error && (
        <div className="flex gap-1 items-center mt-[1px]">
          <ErrorIcon />
          <p className="font-DMSansFont text-[#ff0000] text-[13px] font-normal ml-[2px] max_sm:ml-0 mt-[1px]">
            {errorMsg}
          </p>
        </div>
      )}
    </div>
  );
};

export const SelectBoxField = ({
  placeholder,
  data,
  handleOnChange,
  name,
  value,
  error,
  label,
  isTop,
  isDisable,
}) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => {
    if (open) {
      setOpen(false);
    }
  });

  return (
    <div>
      <p className="labelClass">{label}</p>
      <div
        style={{ border: error ? '1px solid #FF0000' : '' }}
        className={`relative w-full outline-none border-[1px] border-LightOutline py-[15px] px-[15px] rounded-[7px] font-DMSansFont text-textExtraSmall text-LightPrimary font-normal  ${
          value
            ? 'font-normal text-LightPrimary'
            : 'font-normal text-LightSecondary'
        }`}
        onClick={() => {
          if (!isDisable) setOpen(!open);
        }}
        ref={dropdownRef}
        name={name}
      >
        {value ? value : placeholder}
        <div
          className={`absolute right-5 top-[14px] cursor-pointer transition-transform duration-200 ${
            open ? 'rotate-180' : ''
          }`}
        >
          <DropDownArrow color="#6D77A0" />
        </div>

        {open && (
          <>
            <div
              className={`absolute left-0 w-full rounded-[8px] py-2 flex flex-col gap-2 bg-white z-30 max-h-[260px] overflow-y-scroll ${
                isTop ? 'bottom-14' : 'top-14'
              }`}
              style={{ boxShadow: '0px 2px 20px 0px #00000026' }}
            >
              {data?.map((inquiry, index) => (
                <p
                  className="mx-2 rounded-md py-1 px-2 text-textExtraSmall font-medium text-LightSecondary hover:text-LightPrimary hover:bg-BgSecondary cursor-pointer"
                  key={index + 1}
                  onClick={(e) => handleOnChange(inquiry, name)}
                >
                  {inquiry}
                </p>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export const SelectState = ({
  placeholder,
  data,
  handleOnChange,
  name,
  value,
  error,
  label,
  isTop,
  isDisable,
  searchText,
}) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => {
    if (open) {
      setOpen(false);
    }
  });

  const [searchValue, setSearchValue] = useState('');

  // Function to perform case-insensitive search and filter data
  const filteredData = data?.filter((inquiry) =>
    inquiry?.name?.toLowerCase().includes(searchValue.toLowerCase())
  );
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <div>
      <p className="labelClass">{label}</p>
      <div
        style={{ border: error ? '1px solid #FF0000' : '' }}
        className={`relative w-full outline-none border-[1px] border-LightOutline py-[15px] px-[15px] rounded-[7px] font-DMSansFont text-textExtraSmall text-LightPrimary font-normal  ${
          value
            ? 'font-normal text-LightPrimary'
            : 'font-normal text-LightSecondary'
        }`}
        // onClick={() => {
        //   if (!isDisable) setOpen(!open);
        // }}
        ref={dropdownRef}
        name={name}
      >
        <div
          onClick={() => {
            if (!isDisable) setOpen(!open);
          }}
        >
          <p className="max-w-[calc(100%-26px)] truncate">
            {value ? value : placeholder}
          </p>
          <div
            className={`absolute right-5 top-3  cursor-pointer transition-transform duration-200 ${
              open ? 'rotate-180' : ''
            }`}
          >
            <DropDownArrow color="#6D77A0" />
          </div>
        </div>

        {open && (
          <>
            <div
              className={`absolute left-0 w-full rounded-[8px] py-2 flex flex-col gap-2 bg-white z-30  ${
                isTop ? 'bottom-14' : 'top-14'
              }`}
              style={{ boxShadow: '0px 2px 20px 0px #00000026' }}
            >
              {data?.length > 0 && (
                <div className="mx-3 flex items-center gap-1 border-[1px] border-LightOutline rounded-[8px] px-2">
                  <SearchIcon />
                  <input
                    type="text"
                    value={searchValue}
                    placeholder={searchText}
                    onChange={(e) => handleSearch(e)}
                    className={`w-full outline-none py-[6px] px-2 font-DMSansFont text-textExtraSmall text-LightPrimary font-medium`}
                  />
                </div>
              )}
              <div
                className={` overflow-y-scroll ${
                  data?.length > 0 ? 'max-h-[240px] h-[240px]' : 'h-[40px]'
                }`}
              >
                {filteredData?.length > 0 ? (
                  filteredData?.map((inquiry, index) => (
                    <p
                      className="mx-2 rounded-md py-1 px-2 text-textExtraSmall font-medium text-LightSecondary hover:text-LightPrimary hover:bg-BgSecondary cursor-pointer"
                      key={index + 1}
                      onClick={(e) => {
                        handleOnChange(inquiry.name, name);
                        // setStateList(getStates(inquiry.code));
                        setSearchValue('');
                        setOpen(false);
                      }}
                    >
                      {inquiry.name}
                    </p>
                  ))
                ) : (
                  <p className="text-center text-LightSecondary mt-3">
                    No results found
                  </p>
                )}
                {/* {data?.map((inquiry, index) => (
                  <p
                    className="mx-2 rounded-md py-1 px-2 text-textExtraSmall font-medium text-LightSecondary hover:text-LightPrimary hover:bg-BgSecondary cursor-pointer"
                    key={index + 1}
                    onClick={(e) => handleOnChange(inquiry, name)}
                  >
                    {inquiry}
                  </p>
                ))} */}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export const SelectCountry = ({
  placeholder,
  data,
  handleOnChange,
  name,
  value,
  error,
  label,
  setStateList,
  setSelectedCountry,
}) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => {
    if (open) {
      setOpen(false);
    }
  });
  const [searchValue, setSearchValue] = useState('');

  // Function to perform case-insensitive search and filter data
  const filteredData = data.filter((inquiry) =>
    inquiry.name.toLowerCase().includes(searchValue.toLowerCase())
  );
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };
  return (
    <div>
      <p className="labelClass">{label}</p>
      <div
        style={{ border: error ? '1px solid #FF0000' : '' }}
        className={`relative w-full outline-none border-[1px] border-LightOutline py-[15px] px-[15px] rounded-[7px] font-DMSansFont text-textExtraSmall text-LightPrimary font-normal  ${
          value
            ? 'font-normal text-LightPrimary'
            : 'font-normal text-LightSecondary'
        }`}
        ref={dropdownRef}
        name={name}
      >
        <div onClick={() => setOpen(!open)}>
          <p className="max-w-[calc(100%-26px)] truncate">
            {value ? value : placeholder}
          </p>
          <div
            className={`absolute right-5 top-3  cursor-pointer transition-transform duration-200 ${
              open ? 'rotate-180' : ''
            }`}
          >
            <DropDownArrow color="#6D77A0" />
          </div>
        </div>

        {open && (
          <>
            <div
              className="absolute top-14 left-0 w-full rounded-[8px] py-2 flex flex-col gap-2 bg-white z-30 "
              style={{ boxShadow: '0px 2px 20px 0px #00000026' }}
            >
              <div className="mx-3 flex items-center gap-1 border-[1px] border-LightOutline rounded-[8px] px-2">
                <SearchIcon />
                <input
                  type="text"
                  value={searchValue}
                  placeholder="Search country"
                  onChange={(e) => handleSearch(e)}
                  className={`w-full outline-none py-[6px] px-2 font-DMSansFont text-textExtraSmall text-LightPrimary font-medium`}
                />
              </div>
              <div className="max-h-[234px] overflow-y-scroll">
                {filteredData.length > 0 ? (
                  filteredData.map((inquiry, index) => (
                    <p
                      className="mx-2 rounded-md py-1 px-2 text-textExtraSmall font-medium text-LightSecondary hover:text-LightPrimary hover:bg-BgSecondary cursor-pointer"
                      key={index + 1}
                      onClick={(e) => {
                        handleOnChange(inquiry.name, name);
                        const allStates = StateList[inquiry.isoCode];
                        setSelectedCountry(allStates);                        
                        setStateList(allStates ? allStates.states : []);
                        setSearchValue('');
                        setOpen(false);
                      }}
                    >
                      {inquiry.name}
                    </p>
                  ))
                ) : (
                  <p className="text-center text-LightSecondary">
                    No results found for {searchValue}
                  </p>
                )}
                {/* {data?.map((inquiry, index) => (
                  <p
                    className="mx-2 rounded-md py-1 px-2 text-textExtraSmall font-medium text-LightSecondary hover:text-LightPrimary hover:bg-BgSecondary cursor-pointer"
                    key={index + 1}
                    onClick={(e) => {
                      handleOnChange(inquiry.name, name);
                      setStateList(getStates(inquiry.code));
                    }}
                  >
                    {inquiry.name}
                  </p>
                ))} */}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const TextAreaField = ({
  type,
  placeholder,
  handleOnChange,
  name,
  error,
  value,
  background,
  row,
  radius,
  fontSize,
}) => {
  return (
    <div>
      <textarea
        style={{
          border: error ? '1px solid #FF0000' : '',
          background: background ? background : '',
          borderRadius: radius ? radius : '',
          fontSize: fontSize ? fontSize : '',
        }}
        type={type}
        value={value}
        placeholder={placeholder}
        rows={row ? row : 7}
        onChange={(e) => handleOnChange(e, name)}
        className={`w-full outline-none border-[1px] border-LightOutline py-3 px-5 rounded-[20px] font-DMSansFont text-textSmall text-LightPrimary resize-none ${
          value !== '' ? 'font-semibold' : 'font-medium'
        }`}
      />
    </div>
  );
};

export const SelectBox = ({
  placeholder,
  data,
  handleOnChange,
  name,
  value,
  error,
}) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => {
    if (open) {
      setOpen(false);
    }
  });
  return (
    <div
      style={{ border: error ? '1px solid #FF0000' : '' }}
      className={`relative border-[1px] border-LightOutline py-3 px-5 rounded-[30px] font-DMSansFont text-textSmall font-normal  ${
        value
          ? 'font-semibold text-LightPrimary'
          : 'font-medium text-LightTextParagraph'
      }`}
      onClick={() => setOpen(!open)}
      ref={dropdownRef}
      name={name}
    >
      {value ? value : placeholder}
      <div
        className={`absolute right-5 top-3  cursor-pointer transition-transform duration-200 ${
          open ? 'rotate-180' : ''
        }`}
      >
        <DropDownArrow />
      </div>

      {open && (
        <>
          <div
            className="absolute top-14 left-0 w-full rounded-[10px] py-2 flex flex-col gap-2 bg-white z-30"
            style={{ boxShadow: '0px 8px 40px 0px #19224C1A' }}
          >
            {data?.map((inquiry, index) => (
              <p
                className="py-2 text-textExtraSmall font-medium px-3 hover:text-LightPrimary hover:bg-LightBgSecondary cursor-pointer"
                key={index + 1}
                onClick={(e) => handleOnChange(inquiry.name, name)}
              >
                {inquiry.name}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export const InputSearch = ({
  type,
  placeholder,
  handleOnChange,
  name,
  error,
  value,
}) => {
  return (
    <div className="flex gap-3 items-center border-[1px] border-LightOutline rounded-[10px] px-3">
      <SearchIcon />
      <input
        style={{ border: error ? '1px solid #FF0000' : '' }}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={(e) => handleOnChange(e, name)}
        className={`w-full outline-none py-3 font-DMSansFont text-textSmall text-LightPrimary font-medium`}
      />
    </div>
  );
};
export const CheckboxInput = ({ name, formState, handleChange, labelText }) => {
  return (
    <div className="cursor-pointer">
      <label className="cursor-pointer text-textSmall font-DMSansFont font-[450] text-LightPrimary flex gap-3 items-center">
        <input
          type="checkbox"
          name={name}
          checked={formState}
          onChange={handleChange}
        />
        {labelText}
      </label>
    </div>
  );
};
