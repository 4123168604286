import React, { useEffect } from 'react'
import Layout from '../Components/Layout'
import HeroSection from '../Components/Security/HeroSection'
import SecurityServices from '../Components/Security/SecurityServices'
import TitleAndDesc from '../Components/Helmet/TitleAndDesc'

const Security = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TitleAndDesc
        title="Security"
        description="Discover how DCDial ensures the security of your data with robust measures, including role-based access control, data encryption, and compliance with industry standards."
      />
      <Layout>
        <HeroSection />
        <SecurityServices />
      </Layout>
    </>
  );
}

export default Security