import React from 'react'

const ToggleTab = ({
  selected,
  setSelected,
  pricingOptions,
  handleClickScroll,
  isQuestion
}) => {
  return (
    <div
      className={`border-[1px] border-LightOutline p-1 rounded-[10px] flex text-center flex-wrap justify-center gap-y-2 w-fit ${
        isQuestion
          ? 'max_md:w-[464px] max_sm:w-[344px] max_sm_380:w-[186px]'
          : 'w-[70%] max_md:w-[610px] max_720:w-[316px] max_sm:w-[310px] max_350:w-[100%]'
      }`}
    >
      {pricingOptions?.map((list, index) => {
        return (
          <div
            key={index + 1}
            className={`cursor-pointer rounded-[10px]  max_sm:px-1  subParagraphText max_sm:text-textExtraSmall py-3 max_sm:py-2 flex justify-center ${
              selected?.name === list.name
                ? 'text-white bg-ButtonPrimary'
                : 'text-LightSecondary'
            } ${
              isQuestion
                ? 'w-[210px] max_sm:w-[162px]'
                : 'w-[190px] max_md:w-[150px]'
            }`}
            onClick={() => {
              setSelected(list);
              handleClickScroll(list.id);
            }}
          >
            {list.name}
          </div>
        );
      })}
    </div>
  );
};

export default ToggleTab