import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AgentIcon,
  AIAssistantIcon,
  ContactManagementIcon,
  CustomerPortalIcon,
  EmailIcon,
  PayIcon,
  TextMsgIcon,
  VoiceIcon,
} from '../../assets/Icons/icons';
import VoiceBackground from '../../assets/images/Homepage/VoiceBackground.svg';
import VoiceBackgroundFull from '../../assets/images/Homepage/VoiceBGFull.svg';
import CommonSizeContainer from '../CommonSizeContainer';
import LearnMoreComp from '../LearnMoreComp';

// import PaymentAutoAnim from '../../animation/en/Features/PaymentAutomation.json';
// import VoiceCampaignAnim from '../../animation/en/Features/VoiceCampaigns.json';
// import TextMessageAnim from '../../animation/en/Features/TextMessaging.json';
// import EmailCampaignsAnim from '../../animation/en/Features/EmailCampaigns.json';
// import ContactManagementAnim from '../../animation/en/Features/ContactManagement.json';
// import AIAssistantAnim from '../../animation/en/Features/AIAssistant.json';
// import AgentCoPilotAnim from '../../animation/en/Features/AgentCoPilot.json';
// import CustomerPortalAnim from '../../animation/en/Features/CustomerPortal.json';
import Lottie from 'react-lottie';

const Feature = () => {
  const { t } = useTranslation();

  const useAnimationVisibility = () => {
    const [isPaused, setIsPaused] = useState(true);
    const [hasPlayed, setHasPlayed] = useState(false); // New state to track if the animation has been played
    const animationRef = useRef(null);
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (!hasPlayed) {
            if (entry.isIntersecting) {
              setIsPaused(false);
              setHasPlayed(true);
            } else {
              setIsPaused(true);
            }
          } else {
          }
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 0.4,
        }
      );

      if (animationRef.current) {
        observer.observe(animationRef.current);
      }

      return () => {
        if (animationRef.current) {
          observer.unobserve(animationRef.current);
        }
      };
    }, [animationRef, hasPlayed]);

    return [animationRef, isPaused];
  };

  const [feaAnimRef1, isPaused1] = useAnimationVisibility();
  const [feaAnimRef2, isPaused2] = useAnimationVisibility();
  const [feaAnimRef3, isPaused3] = useAnimationVisibility();
  const [feaAnimRef4, isPaused4] = useAnimationVisibility();
  const [feaAnimRef5, isPaused5] = useAnimationVisibility();
  const [feaAnimRef6, isPaused6] = useAnimationVisibility();
  const [feaAnimRef7, isPaused7] = useAnimationVisibility();
  const [feaAnimRef8, isPaused8] = useAnimationVisibility();

  const LottieAnimation = ({ isPaused, animationData }) => {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return <Lottie options={defaultOptions} isPaused={isPaused} />;
  };

  const animations = {
    PaymentAutoAnim:
      'https://images.dcdial.com/dcdial.com/v2/PaymentAutomation.json',
    VoiceCampaignAnim:
      'https://images.dcdial.com/dcdial.com/v2/VoiceCampaigns.json',
    TextMessageAnim:
      'https://images.dcdial.com/dcdial.com/v2/TextMessaging.json',
    EmailCampaignsAnim:
      'https://images.dcdial.com/dcdial.com/v2/EmailCampaigns.json',
    ContactManagementAnim:
      'https://images.dcdial.com/dcdial.com/v2/ContactManagement.json',
    AIAssistantAnim: 'https://images.dcdial.com/dcdial.com/v2/AIAssistant.json',
    AgentCoPilotAnim:
      'https://images.dcdial.com/dcdial.com/v2/AgentCoPilot.json',
    CustomerPortalAnim:
      'https://images.dcdial.com/dcdial.com/v2/CustomerPortal.json',
  };

  const [animationData, setAnimationData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const loadAnimation = async (url, key) => {
    const response = await fetch(url);
    const data = await response.json();
    setAnimationData((prevData) => ({ ...prevData, [key]: data }));
    setIsLoading(false);
  };

  useEffect(() => {
    Object.keys(animations).forEach((key) => {
      loadAnimation(animations[key], key);
    });
  }, []);

  return (
    <div className="">
      <CommonSizeContainer>
        <h2 className="headingText text-center">{t('Features')}</h2>

        <p className="paragraphText text-center mt-5">{t('FeatureText')}</p>
      </CommonSizeContainer>

      {/* FIRST SECTION */}
      <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] flex justify-between max_md:flex-col-reverse max_md:items-center max_md:gap-[30px] mt-[150px] max_xl:mt-[100px] max_sm:mt-[60px] max_md:px-10 max_sm:px-5">
        <div className="flex flex-col max_md:mt-0 w-[48%] max_xl:w-[50%] pl-[100px] max_xl:pl-[80px] max_md:pl-0 max_md:w-[80%] max_md:text-center max_md:flex max_md:flex-col max_md:items-center max_sm:w-[100%]">
          <h3 className="relative commonHeadingFea max-w-[250px] max_md:max-w-[216px]">
            {t('PaymentAutomation')}

            <div
              className={`absolute -z-10  ${
                // i18n.language === 'en'
                'left-[180px] top-[-8px] max_xl:w-[22%] max_md:left-[180px] max_md:top-[-15px] max_sm:left-[164px] max_sm:w-[18%]'
                // : 'top-[47%] right-0 max_md:top-[88%] max_800:top-[44%]'
              }`}
            >
              {' '}
              <PayIcon style={{ width: '100%' }} />
            </div>
          </h3>
          <div className="w-[80%] max_md:w-[90%] mt-5 paragraphText">
            {t('PayAutomationtext')}
          </div>
          <LearnMoreComp link="/products/payments" />
        </div>
        <div
          className="min-h-[606px] max_sm:min-h-[272px] w-[55%] flex items-center max_md:w-[72%] max_sm:w-[100%]"
          ref={feaAnimRef1}
        >
          {!isPaused1 && (
            <LottieAnimation
              isPaused={isPaused1}
              // animationData={PaymentAutoAnim}
              animationData={animationData.PaymentAutoAnim}
              // animationData={
              //   i18n.language === 'en' ? EffortEmailData : EffortEmailDataAR
              // }
            />
          )}
          {/* <img src={Feature1Img} alt="Feature1Img" /> */}
        </div>
      </div>

      {/* SECOND SECTION */}
      <div className="max-w-[1920px] m-auto relative mt-[150px] max_xl:mt-[100px] max_sm:mt-[60px] max_md:px-10 max_sm:px-5">
        <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] flex justify-between flex-row-reverse max_sm:mt-[40px] max_md:flex-col-reverse max_md:items-center max_md:gap-[30px] items-center">
          <div className="max_md:mt-0 flex flex-col w-[44%] max_xl:w-[44%] pr-[100px] max_xl:pr-[80px] max_md:pr-0 max_md:w-[80%] max_md:text-center max_md:flex max_md:flex-col max_md:items-center max_sm:w-[100%]">
            <h3 className="relative commonHeadingFea max-w-[222px]">
              {t('VoiceCampaigns')}

              <div
                className={`absolute top-[-6px] z-[-1] ${
                  // i18n.language === 'en'
                  'left-[100px] max_xl:w-[40%] max_md:left-[140px] max_md:w-[34%] max_sm:left-[140px] max_sm:w-[30%] max_sm:top-[-10px]'
                  // : 'max_xl:w-[ top-[-26%] right-[-15%] max_md:right-[8%]'
                }`}
              >
                {' '}
                <VoiceIcon style={{ width: '100%' }} />
              </div>
            </h3>
            <div className="max_md:w-[90%] paragraphText mt-5">
              {t('VoiceCampaignsText')}
            </div>
            <LearnMoreComp link="/products/communication" />
          </div>
          <div
            className="min-h-[618px] max_xl:min-h-[506px] max_720:min-h-[460px] max_sm:min-h-[360px] w-[48%] max_xl:w-[50%] flex items-center max_md:w-[64%] max_sm:w-[100%]"
            ref={feaAnimRef2}
          >
            {!isPaused2 && (
              <LottieAnimation
                isPaused={isPaused2}
                // animationData={VoiceCampaignAnim}
                animationData={animationData.VoiceCampaignAnim}
              />
            )}
            {/* <img src={Feature2Img} alt="Feature2Img" /> */}
          </div>
        </div>
        <div className="absolute left-[8%] max_1680:left-[3%] max_1440:left-[-5%] top-0 max_1440:top-[12%] -z-10 max_1680:w-[36%] max_xl:w-[32%] max_xl:left-[3%] max_sm:w-[60%] max_sm:top-[16%] max_sm_380:top-[13%] max_md:left-[10%] max_md:w-[42%] max_md:top-[9%] max_sm:left-[-3%]">
          {/* <img
            src={VoiceBackground}
            alt="background_img"
            className="hidden max_1440:block"
          /> */}
          <img
            src={VoiceBackgroundFull}
            alt="background_img"
            // className="max_1440:hidden"
          />
        </div>
      </div>

      <CommonSizeContainer>
        {/* THIRD SECTION TextMessaging */}
        <div className="flex justify-between items-center max_md:flex-col-reverse max_md:items-center max_md:gap-[30px]">
          <div className="flex flex-col max_md:mt-0 w-[48%] max_xl:w-[50%] pl-[100px] max_xl:pl-[80px] max_md:pl-0 max_md:w-[80%] max_md:text-center max_md:flex max_md:flex-col max_md:items-center max_sm:w-[100%]">
            <h3 className="relative commonHeadingFea max-w-[340px] max_md:max-w-[210px]">
              {t('TextMessaging')}

              <div
                className={`absolute -z-10  ${
                  // i18n.language === 'en'
                  'left-[74px] top-[-10px] max_xl:w-[12%] max_md:left-[140px] max_md:top-[-6px] max_sm:top-[-20px] max_md:w-[15%] max_sm:left-[126px]'
                  // : 'top-[47%] right-0 max_md:top-[88%] max_800:top-[44%]'
                }`}
              >
                {' '}
                <TextMsgIcon style={{ width: '100%' }} />
              </div>
            </h3>
            <div className="max_md:w-[90%] mt-5 paragraphText">
              {t('TextMessagingText')}
            </div>
            <LearnMoreComp link="/products/communication" />
          </div>
          <div
            className="min-h-[755px] max_xl:min-h-[596px] max_720:min-h-[460px] max_sm:min-h-[425px] w-[46%] flex items-center max_md:w-[72%] max_sm:w-[100%]"
            ref={feaAnimRef3}
          >
            {!isPaused3 && (
              <LottieAnimation
                isPaused={isPaused3}
                // animationData={TextMessageAnim}
                animationData={animationData.TextMessageAnim}
              />
            )}
            {/* <img src={Feature3Img} alt="Feature3Img" /> */}
          </div>
        </div>

        {/* FORTH SECTION EmailCampaigns */}
        <div className="mt-[150px] max_xl:mt-[100px] max_sm:mt-[60px] flex justify-between flex-row-reverse max_md:flex-col-reverse max_md:items-center max_md:gap-[30px] items-center">
          <div className="max_md:mt-0 flex flex-col w-[44%] max_xl:w-[44%] pr-[100px] max_xl:pr-[80px] max_md:pr-0 max_md:w-[80%] max_md:text-center max_md:flex max_md:flex-col max_md:items-center max_sm:w-[100%]">
            <h3 className="relative commonHeadingFea max-w-[212px] max_md:max-w-[220px]">
              {t('EmailCampaigns')}
              <div
                className={`absolute top-[-10px] z-[-1] ${'left-[108px] max_xl:w-[24%] max_xl:top-[-6px] max_md:left-[140px] max_sm:w-[16%] max_sm:top-[-12px]'}`}
              >
                {' '}
                <EmailIcon style={{ width: '100%' }} />
              </div>
            </h3>
            <div className="max_md:w-[90%] paragraphText mt-5">
              {t('EmailCampaignsText')}
            </div>
            <LearnMoreComp link="/products/communication" />
          </div>
          <div
            className="min-h-[700px] max_xl:min-h-[590px] max_720:min-h-[486px] max_sm:min-h-[375px] w-[46%] max_xl:w-[49%] flex items-center max_md:w-[62%] max_sm:w-[100%]"
            ref={feaAnimRef4}
          >
            {!isPaused4 && (
              <LottieAnimation
                isPaused={isPaused4}
                // animationData={EmailCampaignsAnim}
                animationData={animationData.EmailCampaignsAnim}
              />
            )}
          </div>
        </div>

        {/* FIFTH SECTION ContactManagement */}
        <div className="flex justify-between items-center max_md:flex-col-reverse max_md:items-center max_md:gap-[30px] mt-[150px] max_xl:mt-[100px] max_sm:mt-[60px]">
          <div className="flex flex-col max_md:mt-0 w-[48%] max_xl:w-[50%] pl-[100px] max_xl:pl-[80px] max_md:pl-0 max_md:w-[80%] max_md:text-center max_md:flex max_md:flex-col max_md:items-center max_sm:w-[100%]">
            <h3 className="relative commonHeadingFea max-w-[300px] max_md:max-w-[250px]">
              {t('ContactManagement')}

              <div
                className={`absolute -z-10  ${'left-[162px] top-[-10px] max_xl:w-[16%] max_xl:top-[-4px] max_md:w-[16%] max_md:top-[-10px] max_md:left-[190px] max_sm:left-[176px] max_sm:w-[15%]'}`}
              >
                {' '}
                <ContactManagementIcon style={{ width: '100%' }} />
              </div>
            </h3>
            <div className="max_md:w-[90%] mt-5 paragraphText">
              {t('ContactText')}
            </div>
            <LearnMoreComp link="/products/crm" />
          </div>
          <div
            className="min-h-[740px] max_xl:min-h-[588px] max_720:min-h-[500px] max_sm:min-h-[460px] w-[43%] flex items-center max_md:w-[72%] max_sm:w-[100%]"
            ref={feaAnimRef5}
          >
            {!isPaused5 && (
              <LottieAnimation
                isPaused={isPaused5}
                // animationData={ContactManagementAnim}
                animationData={animationData.ContactManagementAnim}
              />
            )}
          </div>
        </div>

        {/* SIXTH SECTION AIAssistant */}
        <div className="mt-[150px] max_xl:mt-[100px] max_sm:mt-[60px] flex justify-between flex-row-reverse max_md:flex-col-reverse max_md:items-center max_md:gap-[30px] items-center">
          <div className="max_md:mt-0 flex flex-col w-[48%] max_xl:w-[44%] pr-[100px] max_xl:pr-[80px] max_md:pr-0 max_md:w-[80%] max_md:text-center max_md:flex max_md:flex-col max_md:items-center max_sm:w-[100%]">
            <h3 className="relative commonHeadingFea">
              {t('AIAssistant')}
              <div
                className={`absolute top-[30px] z-[-1] ${'left-[250px] max_xl:w-[25%] max_md:left-[192px] max_md:top-0 max_sm:left-[162px] max_sm:top-[-4px]'}`}
              >
                {' '}
                <AIAssistantIcon style={{ width: '100%' }} />
              </div>
            </h3>
            <div className="max_md:w-[90%] paragraphText mt-5">
              {t('AIAssistantText')}
            </div>
            <LearnMoreComp link="/products/ai-assistant" />
          </div>
          <div
            className="min-h-[600px] max_720:min-h-[430px] max_sm:min-h-[300px] w-[44%] max_xl:w-[49%] flex items-center max_md:w-[62%] max_sm:w-[100%]"
            ref={feaAnimRef6}
          >
            {!isPaused6 && (
              <LottieAnimation
                isPaused={isPaused6}
                // animationData={AIAssistantAnim}
                animationData={animationData.AIAssistantAnim}
              />
            )}
            {/* <img src={Feature6Img} alt="Feature6Img" /> */}
          </div>
        </div>

        {/* SEVENTH SECTION AgentCoPilot */}
        <div className="flex justify-between max_md:flex-col-reverse max_md:items-center max_md:gap-[30px] mt-[150px] max_xl:mt-[100px] max_sm:mt-[60px]">
          <div className="flex flex-col max_md:mt-0 w-[48%] max_xl:w-[50%] pl-[100px] max_xl:pl-[80px] max_md:pl-0 max_md:w-[80%] max_md:text-center max_md:flex max_md:flex-col max_md:items-center max_sm:w-[100%] justify-center">
            <h3 className="relative commonHeadingFea">
              {t('AgentCoPilot')}

              <div
                className={`absolute -z-10   ${'left-[272px] top-[-12px] w-[10%] max_md:w-[20%] max_md:left-[232px] max_sm:w-[15%] max_sm:left-[172px] max_sm:top-[-18px]'}`}
              >
                {' '}
                <AgentIcon style={{ width: '100%' }} />
              </div>
            </h3>
            <div className="max_md:w-[90%] mt-5 paragraphText">
              {t('AgentCoPilotText')}
            </div>
            <LearnMoreComp link="/products/ai-assistant" />
          </div>
          <div
            className="min-h-[678px] max_720:min-h-[520px] max_xl:min-h-[547px] max_sm:min-h-[390px] w-[45%] max_xl:w-[46%] flex items-center max_md:w-[72%] max_sm:w-[100%]"
            ref={feaAnimRef7}
          >
            {!isPaused7 && (
              <LottieAnimation
                isPaused={isPaused7}
                // animationData={AgentCoPilotAnim}
                animationData={animationData.AgentCoPilotAnim}
              />
            )}
            {/* <img src={Feature7Img} alt="Feature5Img" /> */}
          </div>
        </div>
      </CommonSizeContainer>

      {/* EIGHT SECTION CustomerPortal */}
      <div className="max-w-[1920px] m-auto relative mt-[150px] max_xl:mt-[100px] max_sm:mt-[60px] max_md:px-10 max_sm:px-5">
        <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] flex justify-between flex-row-reverse max_sm:mt-[40px] max_md:flex-col-reverse max_md:items-center max_md:gap-[30px] items-center">
          <div className="max_md:mt-0 flex flex-col w-[44%] max_xl:w-[44%] pr-[100px] max_xl:pr-[80px] max_md:pr-0 max_md:w-[80%] max_md:text-center max_md:flex max_md:flex-col max_md:items-center max_sm:w-[100%]">
            <h3 className="relative commonHeadingFea max-w-[222px]">
              {t('CustomerPortal')}

              <div
                className={`absolute bottom-[-8px]  z-[-1] ${
                  // i18n.language === 'en'
                  'left-[112px] max_xl:w-[21%] max_md:w-[18%] max_md:left-[150px] max_sm:w-[14%] max_sm:left-[144px]'
                  // : 'max_xl:w-[ top-[-26%] right-[-15%] max_md:right-[8%]'
                }`}
              >
                {' '}
                <CustomerPortalIcon style={{ width: '100%' }} />
              </div>
            </h3>
            <div className="max_md:w-[90%] paragraphText mt-5">
              {t('CustomerPortalText')}
            </div>
            <LearnMoreComp link="/products/payments" />
          </div>
          <div
            className="min-h-[390px] max_720:min-h-[300px] max_sm:min-h-[280px] w-[50%] max_xl:w-[49%] flex items-center max_md:w-[66%] max_sm:w-[100%]"
            ref={feaAnimRef8}
          >
            {!isPaused8 && (
              <LottieAnimation
                isPaused={isPaused8}
                // animationData={CustomerPortalAnim}
                animationData={animationData.CustomerPortalAnim}
              />
            )}
            {/* <img src={Feature8Img} alt="Feature8Img" /> */}
          </div>
        </div>
        <div className="absolute left-[8%] max_1680:left-[3%] max_1440:left-0 top-0 -z-10 max_1680:w-[36%] max_xl:w-[32%] max_md:left-5 max_sm:w-[62%] max_sm:left-[-40px]">
          <img
            src={VoiceBackground}
            alt="background_img"
            className="hidden max_1440:block max_md:hidden"
          />
          <img
            src={VoiceBackgroundFull}
            alt="background_img"
            className="max_1440:hidden max_md:block"
          />
        </div>
      </div>
    </div>
  );
};

export default Feature;
