import React from 'react'

const ChartDataCard = ({details,isBy,isLine}) => {
  return (
    <div
      className={`flex ${
        isLine
          ? 'max-w-[402px] max_xl:max-w-[312px]'
          : 'max-w-[360px] max_xl:max-w-[306px]'
      }`}
    >
      <div className="w-1 h-[162px] bg-ButtonPrimary rounded max_600:h-[100px]"></div>
      <div className="w-[calc(100%-4px)] flex flex-col gap-[6px] pl-4">
        <p className="text-[30px] max_600:text-textMedium font-DMSansFont font-bold text-LightSecondary">
          {isBy && 'By '}
          <span className="text40class pl-1 leading-[52px] max_600:text-subHeadingTxt max_600:leading-[31px]">
            {details?.data}
          </span>
        </p>
        <p className="subParagraphText font-normal leading-[26px] max_720:leading-[23px] max_md:text-headingNormal max_600:text-textSmall max_600:leading-[20px]">
          {details?.text}
        </p>
      </div>
    </div>
  );
}

export default ChartDataCard