import React from 'react';
import { useTranslation } from 'react-i18next';
import CommonSizeContainer from '../CommonSizeContainer';
import { ButtonFilled } from '../utils/Button';
import TagsComp from '../utils/TagsComp';

const CommonProductBanner = ({ tagText, title, text ,id}) => {
  const { t } = useTranslation();
  return (
    <>
      <CommonSizeContainer>
        <div className="flex flex-col gap-6 max_md:gap-4" id={id}>
          <TagsComp
            px="16px"
            py="8px"
            text={tagText}
            color="#00A0FC"
            borderColor="#00A0FC"
          />
          <h2 className="text50Product w-[95%] max_xl:w-[80%] max_md:mt-[14px] max_md:w-full max_md:text-[32px] max_md:leading-[41px] max_sm:text-subHeadingTxt max_sm:leading-[31px]">
            {title}
          </h2>
          <div className="flex max_md:flex-col justify-between items-center max_md:items-start max_md:gap-[30px]">
            <p className="max-w-[800px] max_xl:max-w-[700px] max_md:max-w-full paragraphText">
              {' '}
              {text}
            </p>
            <div className="max_600:hidden">
              <a
                href="https://calendly.com/dcdial-sales/30min"
                rel="noopener noreferrer"
                target="_blank"
              >
                <ButtonFilled
                  title={t('BookADemo')}
                  fontSize="16px"
                  width="140px"
                  fontWeight="600"
                  height="48px"
                />
              </a>
            </div>
          </div>
        </div>
      </CommonSizeContainer>
    </>
  );
};

export default CommonProductBanner;
