import React from 'react'
import NumberWithSubHeadingComp from './Legal/NumberWithSubHeadingComp';
import NumberWithHeadingComp from './Legal/NumberWithHeadingComp';

const CommonlegalComponent = ({ data, title, count, id, handleOnClick }) => {
  return (
    <div className="mt-[30px] flex flex-col" id={id}>
      <p className="text22">
        {count}. {title}
      </p>
      {data.map((uses, index3) => (
        <React.Fragment key={index3}>
          {uses.subSection ? (
            <>
              <NumberWithSubHeadingComp
                data={uses}
                subData={uses.subSection}
                handleOnClick={handleOnClick}
              />
            </>
          ) : (
            <>
              <NumberWithHeadingComp
                data={uses}
                handleOnClick={handleOnClick}
              />
            </>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default CommonlegalComponent