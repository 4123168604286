import React from 'react';

import SecurityStandard from './SecurityStandard';
import OnlinePaymentSecurity from './OnlinePaymentSecurity';
import HandleCustomerData from './HandleCustomerData';
import DataHandling from './DataHandling';
import ProtectingData from './ProtectingData';

const SecurityServices = () => {
  return (
    <>
      <SecurityStandard />
      <OnlinePaymentSecurity />
      <HandleCustomerData />
      <DataHandling />
      <ProtectingData />
    </>
  );
};

export default SecurityServices;
