import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from '../../Hooks/useOutSideClick';
import { InputSearch } from '../utils/InputField';
import {
  AsanaIcon,
  AWeberIcon,
  Bitrix24Icon,
  CalendlyIcon,
  ChatGPTIcon,
  CHatWorkIcon,
  ClickUpIcon,
  ClokifyIcon,
  ClosePIcon,
  CloudConvertIcon,
  DropboxIcon,
  FreshDeskIcon,
  FreshsalesIcon,
  GmailIcon,
  GoogleCalanderIcon,
  GoogleContactIcon,
  GoogleSheetIcon,
  HubspotIcon,
  KeapIcon,
  KommoIcon,
  MailChipIcon,
  MicrosoftExcelIcon,
  MicrosoftOutlookIcon,
  MondayIcon,
  MotionIcon,
  OneDriveIcon,
  OpenAIIcon,
  PardotIcon,
  PayPalIcon,
  PipDriveIcon,
  ProcessStreetIcon,
  SalesforceIcon,
  SeamlessAIIcon,
  SlackIcon,
  StripeIcon,
  TwilioIcon,
  WaveIcon,
  XeroIcon,
  ZapierIcon,
  ZendeskIcon,
  ZohoCampaignsIcon,
  ZOHODeskIcon,
  ZOHOIcon,
} from '../../assets/Icons/IntegrationPayment/paymentIcon';
import { useDebounce } from '../../Hooks/useDebounce';
import { useWindowSize } from '../../Hooks/useWindowSize';
import CommonAppsComp from './CommonAppsComp';

const ConnectedApps = () => {
  const { t } = useTranslation();
  const productRef = useRef();
  const { width: windowWidth } = useWindowSize();
  const isTablet = windowWidth <= 980;
  const [appType, setAppType] = useState();
  const [allAppList, setAllAppList] = useState([]);
  const [appList, setAppList] = useState();
  const [isShowMore, setIsShowMore] = useState(false);
  const [selectedType, setSelectedType] = useState(t('All'));
  const [hoverIndex, setHoverIndex] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [inputSearch, setInputSearch] = useState('');

  const debouncedHandleSearch = useDebounce(inputSearch, 1000);
  useEffect(() => {
    if (inputSearch) {
      handleSearchApps(inputSearch);
      setAppList(handleSearchApps(inputSearch));
    }
  }, [debouncedHandleSearch]);

  useOnClickOutside(productRef, () => setIsOpen(false));
  useEffect(() => {
    setAppType(getTranslatedList(t, 'type'));
    setAllAppList(getTranslatedList(t, 'allApps'));
  }, [t]);
  const handleOnChange = (e) => {
    setInputSearch(e.target.value);
  };
  useEffect(() => {
    if (allAppList) {
      if (isShowMore) {
        setAppList(allAppList);
      } else {
        if (isTablet) {
          setAppList(allAppList.slice(0, 16));
        } else {
          setAppList(allAppList.slice(0, 15));
        }
      }
    }
  }, [allAppList, isShowMore]);

  const filterAppsByTag = (tag) => {
    const lowerCaseTag = tag.toLowerCase(); // Convert the search tag to lowercase
    return allAppList.filter((app) =>
      app.tags.some((appTag) => appTag.toLowerCase().includes(lowerCaseTag))
    );
  };
  const filterAppsByTagSelect = (tag) => {
    // const lowerCaseTag = tag.toLowerCase(); // Convert the search tag to lowercase
    return allAppList.filter((app) =>
      app.tags.some((appTag) => appTag.includes(tag))
    );
  };
  const handleSearchApps = (search) => {
    return allAppList.filter((app) =>
      app.name.toLowerCase().includes(search.toLowerCase())
    );
  };
  const handleApps = (value) => {
    if (value === t('All')) {
      setAppList(allAppList.slice(0, 15));
      setIsShowMore(false);
    } else {
      if (value === "AI") {
        setAppList(filterAppsByTagSelect(value));
      } else {
        
        setAppList(filterAppsByTag(value));
      }
    }
  };

  return (
    <div className="mt-[100px] max_md:mt-[60px] max_sm:mt-5">
      <div className="flex justify-between items-center max_md:flex-col-reverse max_md:gap-[30px]">
        {/* SELECTION TAB */}
        <div className="relative p-1 bg-LightBgSecondary flex rounded-[10px] max_md:w-[635px] max_720:flex-wrap max_720:w-full max_md:justify-center">
          {appType?.slice(0, 6)?.map((list, index) => (
            <p
              className={`cursor-pointer py-[9.5px] px-3 max_xl:px-2 smallText18 max_sm:text-textExtraSmall font-medium ${
                selectedType === list.name
                  ? 'text-ButtonPrimary bg-white rounded-[10px]'
                  : 'text-LightTextParagraph'
              }`}
              key={index}
              onClick={() => {
                setSelectedType(list.name);
                handleApps(list.name);
              }}
            >
              {list.name}
            </p>
          ))}
          <p
            className={`relative cursor-pointer py-[9.5px] px-3 smallText18 max_sm:text-textExtraSmall max_xl:text-[17px] font-medium ${
              selectedType === t('More')
                ? 'text-ButtonPrimary bg-white rounded-[10px]'
                : 'text-LightTextParagraph'
            }`}
            ref={productRef}
            onClick={() => {
              setSelectedType(t('More'));
              setIsOpen(!isOpen);
            }}
          >
            {t('More')}
            {isOpen && (
              <div
                className="absolute right-0 top-14 shadow-[0px_8px_40px_0px_#19224C1A] bg-white rounded-[10px] py-1 w-[200px] max-h-[232px] overflow-y-auto"
                // ref={productRef}
              >
                <div className="py-2 flex flex-col gap-2">
                  {appType?.slice(6)?.map((list, index) => (
                    <p
                      className={`cursor-pointer py-2 px-3 text-textExtraSmall font-medium font-DMSansFont text-LightSecondary hover:bg-LightBgSecondary hover:text-LightPrimary`}
                      key={index}
                      onClick={() => {
                        handleApps(list.name);
                        setIsOpen(false);
                        setSelectedType(t('More'));
                      }}
                    >
                      {list.name}
                    </p>
                  ))}
                </div>
              </div>
            )}
          </p>
        </div>

        {/* INPUT FIELD */}
        <div className="max_md:w-[635px] max_720:w-[100%]">
          <InputSearch
            type="text"
            placeholder="Search"
            handleOnChange={handleOnChange}
            value={inputSearch}
          />
        </div>
      </div>

      {/* ALL APPS */}
      {appList?.length > 0 ? (
        <>
          {' '}
          <div className="grid grid-cols-3 gap-[28px] mt-[60px] max_md:grid-cols-2 max_600:grid-cols-1">
            {appList?.map((app, index1) => {
              return (
                <React.Fragment key={index1}>
                  <a href={app.link} rel="noopener noreferrer" target="_blank">
                    <CommonAppsComp
                      index={index1}
                      app={app}
                      hoverIndex={hoverIndex}
                      setHoverIndex={setHoverIndex}
                    />
                  </a>
                </React.Fragment>
              );
            })}
          </div>
        </>
      ) : (
        <>
          <p className="w-full F-JC-AI-CENTER text-subHeadingXl font-DMSansFont font-medium text-LightSecondary m-auto mt-20">
            Apps Not Found
          </p>
        </>
      )}
      {!isShowMore && selectedType === t('All') && inputSearch === '' && (
        <div
          className="mt-[60px] m-auto w-max bg-white px-5 py-[11.5px] shadow-[4px_8px_24px_0px_#DFE4EC80] rounded-lg border-[1px] border-LightOutline cursor-pointer"
          onClick={() => setIsShowMore(true)}
        >
          <p className="smallText18 text-ButtonPrimary font-medium">
            {t('ShowMore')}
          </p>
        </div>
      )}
    </div>
  );
};
const getTranslatedList = (t, listkey) => {
  const lists = {
    type: [
      {
        name: t('All'),
      },
      {
        name: t('ProjectManagement'),
      },
      {
        name: t('Automation'),
      },
      {
        name: t('CRM'),
      },
      {
        name: t('Email'),
      },
      {
        name: t('Marketing'),
      },
      {
        name: t('PaymentProcessing'),
      },
      {
        name: t('Spreadsheets'),
      },
      {
        name: t('Google'),
      },
      {
        name: t('Scheduling'),
      },
      // {
      //   name: t('ArtificialIntelligence'),
      // },
      {
        name: t('Booking'),
      },
      {
        name: t('AI'),
      },
      {
        name: t('Calendar'),
      },
      {
        name: t('ContactManagementTag'),
      },
      {
        name: t('Accounting'),
      },
      {
        name: t('Phone'),
      },
      {
        name: t('SMS'),
      },
      {
        name: t('CustomerSupportTag'),
      },
      {
        name: t('Collaboration'),
      },
      {
        name: t('TimeTracking'),
      },
      {
        name: t('FileManagement'),
      },
      {
        name: t('Microsoft'),
      },
    ],
    allApps: [
      {
        name: 'Hubspot',
        icon: <HubspotIcon />,
        description: t('HubspotDesc'),
        tags: [t('Marketing'), t('Automation')],
        bgColor: '#FFFBFA',
        color: '#FF7A59',
        link: 'https://www.hubspot.com/',
      },
      {
        name: 'Salesforce',
        icon: <SalesforceIcon />,
        description: t('SalesforceDesc'),
        tags: [t('CRM')],
        bgColor: '#E8F8FF',
        color: '#00A1E0',
        link: 'https://www.salesforce.com/',
      },
      {
        name: 'Mailchimp',
        icon: <MailChipIcon />,
        description: t('MailchimpDesc'),
        tags: [t('Marketing'), t('Email')],
        bgColor: '#FFFDF1',
        color: '#BFA200',
        link: 'https://mailchimp.com/',
      },
      {
        name: 'Stripe',
        icon: <StripeIcon />,
        description: t('StripeDesc'),
        tags: [t('PaymentProcessing')],
        bgColor: '#F5F4FF',
        color: '#635BFF',
        link: 'https://stripe.com/',
      },
      {
        name: 'Zapier',
        icon: <ZapierIcon />,
        description: t('ZapierDesc'),
        tags: [t('Marketing'), t('Automation')],
        bgColor: '#FFFBFA',
        color: '#FF4A00',
        link: 'https://zapier.com/',
      },
      {
        name: 'Google Sheets',
        icon: <GoogleSheetIcon />,
        description: t('GoogleSheetDesc'),
        tags: [t('Spreadsheets'), t('Google')],
        bgColor: '#EAFFEF',
        color: '#34A853',
        link: 'https://www.google.com/sheets/about/',
      },
      {
        name: 'Gmail',
        icon: <GmailIcon />,
        description: t('GmailDesc'),
        tags: [t('Email'), t('Google')],
        bgColor: '#FFF4F3',
        color: '#C5221F',
        link: 'https://mail.google.com/',
      },
      {
        name: 'PayPal',
        icon: <PayPalIcon />,
        description: t('GoogleSheetDesc'),
        tags: [t('PaymentProcessing')],
        bgColor: '#EDF2FF',
        color: '#002C8A',
        link: 'https://www.paypal.com/',
      },
      {
        name: 'Calendly',
        icon: <CalendlyIcon />,
        description: t('CalendlyDesc'),
        tags: [t('Scheduling'), t('Booking')],
        bgColor: '#EDF5FF',
        color: '#006BFF',
        link: 'https://calendly.com/',
      },
      {
        name: 'Asana',
        icon: <AsanaIcon />,
        description: t('GoogleSheetDesc'),
        tags: [t('ProjectManagement')],
        bgColor: '#FFEFEF',
        color: '#F95353',
        link: 'https://asana.com/',
      },
      {
        name: 'Monday.com',
        icon: <MondayIcon />,
        description: t('GoogleSheetDesc'),
        tags: [t('ProjectManagement')],
        bgColor: '#ECFFF7',
        color: '#00CA72',
        link: 'https://monday.com/',
      },
      {
        name: 'ChatGPT',
        icon: <ChatGPTIcon />,
        description: t('GoogleSheetDesc'),
        tags: [t('AI')],
        bgColor: '#F3FFFC',
        color: '#74AA9C',
        link: 'https://chat.openai.com/',
      },
      {
        name: 'Google Calendar',
        icon: <GoogleCalanderIcon />,
        description: t('GoogleCalendarDesc'),
        tags: [t('Calendar'), t('Google')],
        bgColor: '#EEF4FF',
        color: '#4285F4',
        link: 'https://calendar.google.com/calendar/',
      },
      {
        name: 'ClickUp',
        icon: <ClickUpIcon />,
        description: t('ClickUpDesc'),
        tags: [t('ProjectManagement')],
        bgColor: '#FFF2F4',
        color: '#FF6479',
        link: 'https://clickup.com/',
      },
      {
        name: 'Google Contacts',
        icon: <GoogleContactIcon />,
        description: t('GoogleContactsDesc'),
        tags: [t('ContactManagementTag'), t('Google')],
        bgColor: '#ECF4FF',
        color: '#0057CC',
        link: 'https://contacts.google.com/',
      },
      {
        name: 'Twilio',
        icon: <TwilioIcon />,
        description: t('TwilioDesc'),
        tags: [t('Phone'), t('SMS')],
        bgColor: '#FFEFF1',
        color: '#F22F46',
        link: 'https://www.twilio.com/',
      },
      {
        name: 'Zoho CRM',
        icon: <ZOHOIcon />,
        description: t('ZohoCRMDesc'),
        tags: [t('CRM')],
        bgColor: '#FFFDF1',
        color: '#FFDD22',
        link: 'https://www.zoho.com/',
      },
      {
        name: 'OpenAI',
        icon: <OpenAIIcon />,
        description: t('GoogleContactsDesc'),
        tags: [t('AI')],
        bgColor: '#F1F1F1',
        color: '#000000',
        link: 'https://openai.com/',
      },
      {
        name: 'Xero',
        icon: <XeroIcon />,
        description: t('GoogleContactsDesc'),
        tags: [t('Accounting')],
        bgColor: '#F0FCFF',
        color: '#1FC0E7',
        link: 'https://www.xero.com/',
      },
      {
        name: 'Zendesk',
        icon: <ZendeskIcon />,
        description: t('ZendeskDesc'),
        tags: [t('CustomerSupportTag')],
        bgColor: '#F5FEFF',
        color: '#03363D',
        link: 'https://www.zendesk.com/',
      },
      {
        name: 'Wave',
        icon: <WaveIcon />,
        description: t('WaveDesc'),
        tags: [t('Accounting')],
        bgColor: '#F2F8FF',
        color: '#0A4F99',
        link: 'https://www.waveapps.com/',
      },
      {
        name: 'Close',
        icon: <ClosePIcon />,
        description: t('CloseDesc'),
        tags: [t('CRM')],
        bgColor: '#EFFFF7',
        color: '#49BC82',
        link: 'https://www.close.com/',
      },
      {
        name: 'AWeber',
        icon: <AWeberIcon />,
        description: t('AWeberDesc'),
        tags: [t('Email'), t('Marketing')],
        bgColor: '#F1F6FF',
        color: '#246BE8',
        link: 'https://www.aweber.com/',
      },
      {
        name: 'Keap',
        icon: <KeapIcon />,
        description: t('KeapDesc'),
        tags: [t('CRM')],
        bgColor: '#F2FFF2',
        color: '#36A635',
        link: 'https://keap.com/',
      },
      {
        name: 'Motion',
        icon: <MotionIcon />,
        description: t('MotionDesc'),
        tags: [t('ProjectManagement')],
        bgColor: '#FFF1FA',
        color: '#BB4B96',
        link: 'https://www.usemotion.com/',
      },
      {
        name: 'Chatwork',
        icon: <CHatWorkIcon />,
        description: t('ChatworkDesc'),
        tags: [t('Collaboration')],
        bgColor: '#FFF8F8',
        color: '#F03748',
        link: 'https://go.chatwork.com/',
      },
      {
        name: 'Slack',
        icon: <SlackIcon />,
        description: t('SlackDesc'),
        tags: [t('Collaboration')],
        bgColor: '#FFFDF1',
        color: '#ECB22E',
        link: 'https://slack.com/',
      },
      {
        name: 'Kommo',
        icon: <KommoIcon />,
        description: t('KommoDesc'),
        tags: [t('CRM')],
        bgColor: '#F5F6FF',
        color: '#0006A0',
        link: 'https://www.kommo.com/',
      },
      {
        name: 'Clockify',
        icon: <ClokifyIcon />,
        description: t('ClockifyDesc'),
        tags: [t('TimeTracking')],
        bgColor: '#F2FBFF',
        color: '#03A9F4',
        link: 'https://clockify.me/',
      },
      {
        name: 'Pardot',
        icon: <PardotIcon />,
        description: t('SlackDesc'),
        tags: [t('Marketing'), t('Automation')],
        bgColor: '#E8F8FF',
        color: '#00A1E0',
        link: 'https://www.salesforce.com/products/b2b-marketing-automation/?redirect=pardot.com',
      },
      {
        name: 'Zoho Campaigns',
        icon: <ZohoCampaignsIcon />,
        description: t('ZohoCampaignsDesc'),
        tags: [t('Marketing'), t('Automation')],
        bgColor: '#FFF1F1',
        color: '#E42527',
        link: 'https://www.zoho.com/',
      },
      {
        name: 'Bitrix24 CRM',
        icon: <Bitrix24Icon />,
        description: t('Bitrix24CRMDesc'),
        tags: [t('CRM')],
        bgColor: '#F3FCFF',
        color: '#2FC7F7',
        link: 'https://www.bitrix24.in/',
      },
      {
        name: 'CloudConvert',
        icon: <CloudConvertIcon />,
        description: t('CloudConvertDesc'),
        tags: [t('FileManagement')],
        bgColor: '#FAFAFA',
        color: '#595959',
        link: 'https://cloudconvert.com/',
      },
      {
        name: 'Process Street',
        icon: <ProcessStreetIcon />,
        description: t('ProcessStreetDesc'),
        tags: [t('ProjectManagement')],
        bgColor: '#F2FAFF',
        color: '#3A94D1',
        link: 'https://www.process.st/',
      },
      {
        name: 'Zoho Desk',
        icon: <ZOHODeskIcon />,
        description: t('ZohoDeskDesc'),
        tags: [t('CustomerSupportTag')],
        bgColor: '#F6FFFA',
        color: '#089949',
        link: 'https://www.zoho.com/',
      },
      {
        name: 'Microsoft Outlook',
        icon: <MicrosoftOutlookIcon />,
        description: t('MicrosoftOutlookDesc'),
        tags: [t('Email'), t('Calendar'), t('Microsoft')],
        bgColor: '#F4FAFF',
        color: '#0364B8',
        link: 'https://www.microsoft.com/en-us/microsoft-365/outlook/email-and-calendar-software-microsoft-outlook',
      },
      {
        name: 'Freshdesk',
        icon: <FreshDeskIcon />,
        description: t('FreshdeskDesc'),
        tags: [t('CustomerSupportTag')],
        bgColor: '#F4FFF9',
        color: '#25C16F',
        link: 'https://support.freshdesk.com/',
      },
      {
        name: 'OneDrive',
        icon: <OneDriveIcon />,
        description: t('OneDriveDesc'),
        tags: [t('FileManagement')],
        bgColor: '#F8FBFF',
        color: '#0A3C7C',
        link: 'https://onedrive.live.com/',
      },
      {
        name: 'Seamless AI',
        icon: <SeamlessAIIcon />,
        description: t('SeamlessAIDesc'),
        tags: [t('ContactManagementTag'), t('AI')],
        bgColor: '#F4F8FF',
        color: '#3361AB',
        link: 'https://seamless.ai/',
      },
      {
        name: 'Pipedrive',
        icon: <PipDriveIcon />,
        description: t('PipedriveDesc'),
        tags: [t('CRM')],
        bgColor: '#F4F8FF',
        color: '#017737',
        link: 'https://www.pipedrive.com/',
      },
      {
        name: 'Dropbox',
        icon: <DropboxIcon />,
        description: t('DropboxDesc'),
        tags: [t('FileManagement')],
        bgColor: '#F5FBFF',
        color: '#0F82E2',
        link: 'https://www.dropbox.com/',
      },
      {
        name: 'Freshsales',
        icon: <FreshsalesIcon />,
        description: t('FreshsalesDesc'),
        tags: [t('CRM')],
        bgColor: '#FFFBF2',
        color: '#FFA800',
        link: 'https://www.freshworks.com/crm/lp/freshsales-pricing/?tactic_id=3389380&utm_source=google-adwords&utm_medium=Fsales-Search-INDIA-Brand&utm_campaign=Fsales-Search-INDIA-Brand&utm_term=freshsales&device=c&matchtype=e&network=g&gclid=Cj0KCQjw_qexBhCoARIsAFgBles6-oN_Rr1fWF6wSUKGjQvGeJYhh3Xa2bzeghP2CmlxHHWSO44lcCcaAmxcEALw_wcB&gad_source=1',
      },
      {
        name: 'Microsoft Excel',
        icon: <MicrosoftExcelIcon />,
        description: t('MicrosoftExcelDesc'),
        tags: [t('Spreadsheets'), t('Microsoft')],
        bgColor: '#F5FFFA',
        color: '#185C37',
        link: 'https://www.microsoft.com/en-in/microsoft-365/excel',
      },
    ],
  };
  return lists[listkey];
};

export default ConnectedApps;
