import React, { useEffect, useState } from 'react';
import { ButtonFilledArrow } from '../utils/Button';
import BtnArrowEn from '../../assets/images/Homepage/ButtonArrowEn.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const DCDialGuidance = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const steps = [1, 2, 3, 4];
  const [guidanceList, setGuidanceList] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [activeStepLine, setActiveStepLine] = useState(0);
  const [visitedSteps, setVisitedSteps] = useState([0]);
  const [visitedStepsLine, setVisitedStepsLine] = useState([0]);
  useEffect(() => {
    setGuidanceList(getTranslatedList(t));
  }, [t]);

  const handleNext = (index) => {
    const updatedVisitedSteps = Array.from({ length: index + 1 }, (_, i) => i);

    setActiveStep(index);
    setActiveStepLine(index);
    setVisitedSteps(updatedVisitedSteps);
    setVisitedStepsLine(updatedVisitedSteps);
  };
  return (
    <div className="relative w-full pb-[150px] max_md:pb-[100px] max_sm:pb-[60px] pt-[150px] max_md:pt-[100px] max_sm:pt-[60px]">
      <div className="m-auto max-w-[1040px] max_xl:max-w-[980px] max_md:px-[40px] max_sm:px-[20px] ">
        <div
          id="discover-element"
          className="w-full h-full rounded-2xl bg-BgPrimary flex p-10 justify-between max_md:flex-col max_sm:p-5"
        >
          <div className="w-[40%] max_md:w-[100%] max_md:text-center flex flex-col justify-between">
            <div className="">
              <div className="tracking-[-1px] text-text3Xl font-DMSansFont font-bold text-LightPrimary max_sm:text-subHeadingTxt">
                {t('StartDCDial')}
              </div>
              <div className="tracking-[-1px] leading-[30px] max_sm:leading-5 subParagraphText mt-5 max_sm:mt-1">
                {t('StartDCDialText')}
              </div>
            </div>

            <div className="mt-10 max_md:flex max_md:justify-center max_sm:mt-5">
              {/* <a
                href="https://www.dcdial.com/signup/?v=5&d=m/"
                rel="noopener noreferrer"
                target="_blank"
              > */}
              <ButtonFilledArrow
                title={t('GetStarted')}
                // onClick={toggleModal}
                background="#1678F2"
                // image={i18n.language === 'en' ? ArrowIcon : ArrowIconAR}
                image={BtnArrowEn}
                maxWidth="178px"
                onClick={()=>navigate('/signup')}
              />
              {/* </a> */}
            </div>
          </div>

          {/* Stepper Component */}
          <div className="w-[18%] max_md:w-[100%] max_md:mt-10">
            <div className="stepper">
              {steps.map((stepItem, index) => (
                <React.Fragment key={index}>
                  <div className="stepOuter">
                    <div
                      key={index}
                      className={`step ${
                        index === activeStep
                          ? 'active'
                          : visitedSteps.includes(index)
                          ? 'visited'
                          : ''
                      } font-DMSansFont text-headingNormal cursor-pointer`}
                      onClick={() => handleNext(index)}
                    >
                      {stepItem}
                    </div>
                  </div>
                  {stepItem !== steps.length && (
                    <>
                      <div className="ProgressOuter">
                        <div
                          className={`ProgressInner ${
                            index === activeStepLine
                              ? 'ProgressInner'
                              : visitedStepsLine.includes(index)
                              ? 'visitedProgress'
                              : ''
                          } font-DMSansMedium text-[20px]`}
                        ></div>
                      </div>
                    </>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>

          <div className="w-[40%]  max_md:w-[100%] max_md:mt-10 max_sm:mt-9">
            <div className="tracking-[-1px] leading-[52px] text-text2xl font-DMSansFont font-bold text-LightPrimary max_sm:text-headingNormal">
              {guidanceList[activeStep]?.step}
            </div>
            <div
              className="tracking-[-1px] subHeadingText w-[90%] leading-[35px] min-h-[210px] max_md:min-h-[80px] max_sm:min-h-[125px] text-LightTextParagraph font-medium mt-4 max_sm:mt-2 max_sm:text-textSmall max_sm:leading-[22px]"
            >
              {guidanceList[activeStep]?.description}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const getTranslatedList = (t) => {
  // Define your lists here
  const lists = [
    {
      step: t('Step1'),
      description: t('Step1Text'),
    },
    {
      step: t('Step2'),
      description: t('Step2Text'),
    },
    {
      step: t('Step3'),
      description: t('Step3Text'),
    },
    {
      step: t('Step4'),
      description: t('Step4Text'),
    },
  ];

  return lists;
};
export default DCDialGuidance;
