import React from 'react';
import { ButtonFilled } from '../utils/Button';
import { useTranslation } from 'react-i18next';
// import BannerImage from '../../assets/images/Homepage/Img .svg';

const Banner = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="pb-[170px] max_md:pb-0 max_sm:pb-0">
        <div className="w-full relative mt-[100px] max_xl:mt-[70px] max_md:mt-10 max-w-[1920px] m-auto">
          <div className="relative max-w-[1240px] max_xl:max-w-[980px] m-auto flex items-center max_md:flex-col max_md:items-center">
            <div className="w-[43%] max_md:w-full">
              <h1 className="m-auto headingText max-w-[587px] max_md:max-w-[600px] tracking-[-2px] max_md:text-center max_md:leading-[52px] max_sm:leading-10 max_md:tracking-[-1px] max_sm:px-5">
                {t('BannerHeading')}
              </h1>
              <div className="hidden max_md:block mt-[60px] max_sm:mt-6">
                <img
                  src="https://images.dcdial.com/dcdial.com/v2/AccelerateGrowth.svg"
                  alt="Banner_img"
                  className="w-full"
                  loading="lazy"
                />
              </div>
              <p className="paragraphText mt-5 max_md:mt-10 max_md:text-center max_md:px-10 max_sm:px-5">
                {t('BannerSubHeading')}
              </p>
              <div className="mt-[50px] w-fit max_md:mt-10 max_md:m-auto">
                <a
                  href="https://calendly.com/dcdial-sales/30min"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <ButtonFilled title={t('ScheduleDemo')} rounded="8px" />
                </a>
              </div>
            </div>
          </div>
          <div className="absolute right-0 top-[-12%] max_1680:top-[-26px] w-[45%] max_1500:w-[50%] max_xl:w-[53%] max_xl:top-0 max_xl1160:top-[8%] max_md:hidden">
            <img
              src="https://images.dcdial.com/dcdial.com/v2/AccelerateGrowth.svg"
              alt="Banner_img"
              className="w-full"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;



{/* <div className="absolute right-[-190px] max_xl:right-[-290px] top-[-4%] w-[1034px] max_1680:w-[980px] max_xl1160:w-[870px] max_xl1160:right-[-258px] max_md:hidden">
  <img src={BannerImage} alt="Banner_img" className="w-full" loading="lazy" />
  {isSmallDesktop ? (
              <BannerImage1300 style={{ width: '100%', height: '100%' }} />
            ) : (
              <BannerImage style={{ width: '100%', height: '100%' }} />
            )}
</div>; */}