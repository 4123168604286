import React from 'react';
import CommonSizeContainer from '../../CommonSizeContainer';
import TagsComp from '../../utils/TagsComp';
// import InvoiceImg from '../../../assets/images/Products/InvoiceImg1.svg';
import { useTranslation } from 'react-i18next';
import { TickMarkIcon } from '../../../assets/Icons/Features/FeatureIcon';
import { useWindowSize } from '../../../Hooks/useWindowSize';

const InvoiceAndQuotes = () => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 500;
  return (
    <CommonSizeContainer>
      <div
        className="flex justify-between max_md:flex-col-reverse"
        id="Invoices"
      >
        <div className="max_md:hidden">
          <img
            src="https://images.dcdial.com/dcdial.com/v2/InvoiceAndQuotes.svg"
            alt="Invoice"
          />
        </div>
        <div className="max-w-[500px] max_md:max-w-full">
          {/* <TagsComp
            px="16px"
            py="8px"
            text={t('ComingSoon')}
            color="#00A0FC"
            borderColor="#00A0FC"
          /> */}
          <p className="text50Product mt-6 max_md:mt-[30px] max_sm:mt-4">
            {t('InvoicesQuotes')}
          </p>
          <p className="paragraphText mt-6 max_md:mt-4 max_600:mt-6">
            {t('InvoiceText')}
          </p>
          <div className="hidden max_md:block mt-[60px] max_600:mt-10">
            <img
              src="https://images.dcdial.com/dcdial.com/v2/InvoiceAndQuotes.svg"
              alt="Invoice"
            />
          </div>
          <div className="flex flex-col max_md:flex-row max_600:flex-col max_md:mt-[30px] max_600:mt-10 max_md:gap-5 max_600:gap-4">
            <div className="mt-[60px] max_md:mt-0 flex gap-2">
              <div>
                <TickMarkIcon
                  color="#00A0FC"
                  width={isMobileView ? '20' : '26'}
                  height={isMobileView ? '20' : '26'}
                />
              </div>
              <p className="smallText18 max_sm:text-textSmall font-medium text-LightTextParagraph">
                {t('InvoiceText1')}
              </p>
            </div>
            <div className="mt-4 max_md:mt-0 flex gap-2">
              <div>
                <TickMarkIcon
                  color="#00A0FC"
                  width={isMobileView ? '20' : '26'}
                  height={isMobileView ? '20' : '26'}
                />
              </div>
              <p className="smallText18 max_sm:text-textSmall font-medium text-LightTextParagraph">
                {t('InvoiceText2')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </CommonSizeContainer>
  );
};

export default InvoiceAndQuotes;
