import React, { useEffect, useState } from 'react';
import Layout from '../../Layout';
import HeroSection from '../../HeroSection';
import { useTranslation } from 'react-i18next';
import CommonProductBanner from '../CommonProductBanner';
import CRMChartComp from './CRMChartComp';
import ThirdpartyIntegration from './ThirdpartyIntegration';
import BgCenterComp from '../BgCenterComp';
import { ControlledAccess, ImprovedAccuracy, IntegrationCapability, ReactTimeUpdate, Scalability, ShiftManagement, StreamlinedAdmin } from '../../../assets/Icons/Products/ProductsIcon';
// import ListLoadImg from '../../../assets/images/Products/ListLoadImg1.svg'
// import OptimizeWorkflowImg from '../../../assets/images/Products/OptimizeWorkFlow1.svg'
import SynchronizeAndCustomizeCRm from './SynchronizeAndCustomizeCRm';
import ContactActivityTrack from './ContactActivityTrack';
import TitleDescWithKeyword from '../../Helmet/TitleDescWithKeyword';

const CRM = () => {
  const { t } = useTranslation()
  const [data, setData] = useState({
    listLoadData: [],
    optimizeWorkData:[],
  });
    useEffect(() => {
      setData((prevData) => ({
        ...prevData,
        listLoadData: getTranslatedList(t, 'list_load'),
        optimizeWorkData: getTranslatedList(t, 'optimize_work'),
      }));
    }, [t]);
  
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TitleDescWithKeyword
        title="DCDial | Contact Management"
        description="Efficiently manage contacts, automate list imports, and integrate with your favorite tools for seamless data synchronization."
        keywords="CRM solutions, Contact management, Centralized contact repository, Business productivity tools, Increase sales productivity, CRM system benefits, Revenue increase with CRM, ROI on CRM investment, CRM integration, Third-party application integration, List load automation, Automate contact list import, Reduce data entry errors, Scale outreach efforts, Real-time contact updates, Synchronize CRM data, Customizable CRM fields, Customer data management, Personalized customer insights, Targeted communication, Track contact activity, Customer engagement history, Optimize workforce management, User access management, Assign roles and permissions, Secure data access, Shift management, Automate payment processes, Advanced call routing, Predictive dialing features"
        isPageURL="https://dcdial.com/products/crm"
        isImageURL="https://images.dcdial.com/dcdial.com/v2/CRM.svg"
      />
      <Layout>
        <HeroSection title={t('CRM')} text={t('CRMText')} />

        <CommonProductBanner
          tagText={t('ContactManagement')}
          title={t('ContactManagementTitle')}
          text={t('ContactManagementText')}
          id="ContactManagement"
        />

        <CRMChartComp />

        <ThirdpartyIntegration />

        <BgCenterComp
          title={t('ListAutomation')}
          text={t('ListAutomationText')}
          tagText={t('Features')}
          background="#EDF8FF"
          data={data.listLoadData}
          id="ListAutomation"
        >
          <div className="flex justify-center mt-[-212px] max_md:mt-[-22px] max_md:mb-[-22px]">
            <img
              src="https://images.dcdial.com/dcdial.com/v2/Automation.svg"
              alt="ListLoad"
              className=""
            />
          </div>
          {/* <div id="Synchronize"></div> */}
        </BgCenterComp>

        <SynchronizeAndCustomizeCRm />

        <ContactActivityTrack />

        <BgCenterComp
          title={t('OptimizeWorkforce')}
          text={t('OptimizeWorkforceT')}
          background="#ffffff"
          data={data.optimizeWorkData}
          id="Optimize"
        >
          <div className="flex justify-center mt-10 max_md:mt-0">
            <img
              src="https://images.dcdial.com/dcdial.com/v2/WorkFlow.svg"
              alt="OptimizeWorkflow"
              className=""
            />
          </div>
        </BgCenterComp>
      </Layout>
    </>
  );
};
const getTranslatedList = (t, listKey) => {
  const lists = {
    list_load: [
      {
        name: t('ImprovedAccuracy'),
        text: t('ImprovedAccuracyT'),
        icon: <ImprovedAccuracy />,
      },
      {
        name: t('EnhancedScalability'),
        text: t('EnhancedScalabilityT'),
        icon: <Scalability />,
      },
      {
        name: t('IntegrationCapability'),
        text: t('IntegrationCapabilityT'),
        icon: <IntegrationCapability />,
      },
      {
        name: t('ReactTimeUpdate'),
        text: t('ReactTimeUpdateT'),
        icon: <ReactTimeUpdate />,
      },
    ],
    optimize_work: [
      {
        name: t('StreamlinedAdmin'),
        text: t('StreamlinedAdminT'),
        icon: <StreamlinedAdmin />,
      },
      {
        name: t('ControlledAccess'),
        text: t('ControlledAccessT'),
        icon: <ControlledAccess />,
      },
      {
        name: t('ShiftManagement'),
        text: t('ShiftManagementT'),
        icon: <ShiftManagement />,
      },
    ],
  };

  return lists[listKey];
};

export default CRM;
