import React from 'react'
import CommonSection from '../CommonSection'
import { useTranslation } from 'react-i18next';

const HeroSection = () => {
  const {t} = useTranslation()
  return (
    <CommonSection>
      <h1 className="headingText text-center">{t('FAQFull')}</h1>
    </CommonSection>
  );
}

export default HeroSection