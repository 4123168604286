import React from 'react'
import LearnMoreComp from '../LearnMoreComp';

const CommonAppsComp = ({
  app,
  index,
  hoverIndex,
  setHoverIndex,
  isLearnMore,
}) => {
  return (
    <div
      className={`flex flex-col gap-5 max_xl:gap-4 rounded-[20px] px-[26px] py-[30px] max_xl:p-5 max_md:p-[30px] max_md:gap-5 border-[1px] max_720:p-5 ${
        hoverIndex === index
          ? 'shadow-[0px_12px_30px_0px_#3C425714] border-white max_sm:shadow-none max_sm:border-LightOutline'
          : ' border-LightOutline'
      }`}
      onMouseEnter={() => setHoverIndex(index)}
      onMouseLeave={() => setHoverIndex(null)}
    >
      <div>{app.icon}</div>
      <div>
        <p className="paragraphText text-LightPrimary max_md:text-subHeadingTxt font-bold">
          {app.name}
        </p>
        <p className="mt-3 subParagraphText font-normal max_md:text-headingNormal max_sm:text-textSmall max_xl:min-h-[88px] max_md:min-h-0 max_800:min-h-[88px] max_600:min-h-0">
          {app.description}
        </p>
      </div>

      <div className="flex flex-wrap gap-2">
        {app.tags.map((tag, index2) => (
          <div
            key={index2}
            className={`rounded-[30px] px-[10px] py-1 border-[${app.color}] border-[1px]`}
            style={{
              backgroundColor: `${app.bgColor}`,
              borderColor: `${app.color}`,
            }}
          >
            <p
              className={`smallText16 font-normal max_sm:text-textExtraSmall`}
              style={{
                color: `${app.color}`,
              }}
            >
              {tag}
            </p>
          </div>
        ))}
      </div>

      {isLearnMore && (
        <LearnMoreComp
          fontSize="16px"
          fontWeight="400"
          marginTop="0"
          link={app.link}
        />
      )}
    </div>
  );
};

export default CommonAppsComp