import React, { useEffect, useState } from 'react';
import CommonSizeContainer from '../../CommonSizeContainer';
import { useTranslation } from 'react-i18next';
import {
  BoostAR,
  EmailSmsCampaign,
  PaymentIVR,
  VoiceBroadcast,
} from '../../../assets/Icons/Products/ProductsIcon';
import { useWindowSize } from '../../../Hooks/useWindowSize';

const PaymentCollection = () => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 500;
  const [paymentCampaign, setPaymentCampaign] = useState([]);
  useEffect(() => {
    setPaymentCampaign(getTranslatedList(t, 'services'));
  }, [t]);
  return (
    <CommonSizeContainer>
      <div className="flex flex-col" id="PaymentCollectionId">
        <h2 className="text50Product text-center">{t('PaymentCollection')}</h2>
        <p className="paragraphText leading-[30px] max_600:leading-[21px] text-center mt-6 max_md:mt-4">
          {t('PaymentCollectionText')}
        </p>
        <div className="mt-[60px] max_600:mt-10 grid grid-cols-2 gap-20 max_md:gap-[60px] max_600:grid-cols-1 max_720:gap-10 justify-center">
          {paymentCampaign.map((list, index) => (
            <div className="" key={index}>
              <div>
                {React.cloneElement(list.icon, {
                  style: {
                    width: isMobileView ? 60 : 80,
                    height: isMobileView ? 60 : 81,
                  },
                })}
              </div>
              <p className="subHeadingText mt-6 max_600:mt-4">{list.title}</p>
              <p className="mt-3 smallText18 font-medium text-LightTextParagraph max_600:text-textSmall">
                {list.text}
              </p>
            </div>
          ))}
        </div>
      </div>
    </CommonSizeContainer>
  );
};
const getTranslatedList = (t, listKey) => {
  const lists = {
    services: [
      {
        title: t('VoiceBroadcastS'),
        text: t('VoiceBroadcastST'),
        icon: <VoiceBroadcast />,
      },
      {
        title: t('EmailSMSCampaigns'),
        text: t('EmailSMSCampaignsT'),
        icon: <EmailSmsCampaign />,
      },
      {
        title: t('PaymentIVR'),
        text: t('PaymentIVRT'),
        icon: <PaymentIVR />,
      },
      {
        title: t('BoostAR'),
        text: t('BoostART'),
        icon: <BoostAR />,
      },
    ],
  };

  return lists[listKey];
};
export default PaymentCollection;
