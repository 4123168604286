import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home';
import { useTranslation } from 'react-i18next';
import Pricing from './Pages/Pricing';
import FAQ from './Pages/FAQ';
import AboutUs from './Pages/AboutUs';
import Features from './Pages/Features';
import GetInTouch from './Pages/GetInTouch';
import Products from './Pages/Products';
import Blogs from './Pages/Blogs';
import Solutions from './Pages/Solutions';
import IntegrationAndPayment from './Pages/IntegrationAndPayment';
import Security from './Pages/Security';
import Legal from './Pages/Legal';
import TermesOfService from './Pages/TermesOfService';
import SpecificProductService from './Pages/LegalService/SpecificProductService';
import DataProcessing from './Pages/LegalService/DataProcessing';
import AcceptableUsePolicy from './Pages/LegalService/AcceptableUsePolicy';
import DeveloperPolicy from './Pages/LegalService/DeveloperPolicy';
import DeveloperTerms from './Pages/LegalService/DeveloperTerms';
import WebTermsOfUse from './Pages/LegalService/WebTermsOfUse';
import WebAccessibility from './Pages/LegalService/WebAccessibility';
import PrivacyPolicy from './Pages/LegalService/PrivacyPolicy';
import CookiePolicy from './Pages/LegalService/CookiePolicy';
import DigitalServicePolicy from './Pages/LegalService/DigitalServicePolicy';
import Communication from './Components/Products/Communication';
import Payments from './Components/Products/Payments/Payments';
import AIAssitant from './Components/Products/AIAssistant/AIAssitant';
import CRM from './Components/Products/CRM/CRM';
import ChatTawkToScript from './Components/ChatTawkToScript'
import PageNotFound from './Pages/PageNotFound';
import Register from './Pages/Register';
import UninstallScreen from './Pages/UninstallExt/UninstallScreen';
import UninstallSuccess from './Pages/UninstallExt/UninstallSuccess';
import 'react-loading-skeleton/dist/skeleton.css';


function App() {
  const { i18n } = useTranslation();
  return (
    <>
      <div lang="en" dir="ltr" id="main-app">
        {/* <ScrollToTop /> */}

        <Routes>
          <Route path="/signup" element={<Register />} />
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/features" element={<Features />} />
          <Route path="/contact/get-in-touch" element={<GetInTouch />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/communication" element={<Communication />} />
          <Route path="/products/payments" element={<Payments />} />
          <Route path="/products/ai-assistant" element={<AIAssitant />} />
          <Route path="/products/crm" element={<CRM />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/apps-payments" element={<IntegrationAndPayment />} />
          <Route path="/security" element={<Security />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/legal/terms-of-service" element={<TermesOfService />} />
          <Route
            path="/legal/product-specific-terms"
            element={<SpecificProductService />}
          />
          <Route
            path="/legal/acceptable-use"
            element={<AcceptableUsePolicy />}
          />
          <Route path="/legal/developer-policy" element={<DeveloperPolicy />} />
          <Route path="/legal/developer-terms" element={<DeveloperTerms />} />
          <Route path="/legal/dpa" element={<DataProcessing />} />
          <Route
            path="/legal/website-terms-of-use"
            element={<WebTermsOfUse />}
          />
          <Route
            path="/legal/website-accessibility"
            element={<WebAccessibility />}
          />
          <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/legal/cookie-policy" element={<CookiePolicy />} />
          <Route
            path="/legal/digital-services-act"
            element={<DigitalServicePolicy />}
          />
          <Route
            path="/chrome/uninstall-feedback"
            element={<UninstallScreen />}
          />
          <Route
            path="/chrome/uninstall-success"
            element={<UninstallSuccess />}
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <ChatTawkToScript />
      </div>
    </>
  );
}

export default App;
