import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import UseOfServiceComp from './UseOfServiceComp';
import FeesComp from './FeesComp';
import CommonlegalComponent from '../CommonlegalComponent';
import ConfidentialityComp from './ConfidentialityComp';
import CommonHeadText from './CommonHeadText';
import Definition from './DPA/Definition';

const TermsOfServiceContent = () => {
  const { t } = useTranslation();
  const [customerTermsList, setCustomerTermsList] = useState([]);
  const [definationList, setDefinationList] = useState([]);
  const [UseOfServicesList, setUseOfServices] = useState([]);
  const [feesList, setFeesList] = useState([]);
  const [TermsAndTerminationList, setTermsAndTerminationList] = useState([]);
  const [CustomerDataList, setCustomerDataList] = useState([]);
  const [IntellectualProperty, setIntellectualProperty] = useState([]);
  const [ConfidentialityList, setConfidentialityList] = useState([]);
  const [DisclaimersList, setDisclaimersList] = useState([]);
  const [MiscellaneousList, setMiscellaneousList] = useState([]);
  useEffect(() => {
    setCustomerTermsList(getTranslatedList(t, 'customerTermsList'));
    setDefinationList(getTranslatedList(t, 'defList'));
    setUseOfServices(getTranslatedList(t, 'useOfService'));
    setFeesList(getTranslatedList(t, 'fees'));
    setTermsAndTerminationList(getTranslatedList(t, 'terms_termination'));
    setCustomerDataList(getTranslatedList(t, 'customerData'));
    setIntellectualProperty(getTranslatedList(t, 'intellectualProperty'));
    setConfidentialityList(getTranslatedList(t, 'confidentiality'));
    setDisclaimersList(getTranslatedList(t, 'disclaimers'));
    setMiscellaneousList(getTranslatedList(t, 'miscellaneous'));
  }, [t]);

  const navigate = useNavigate();
  const handleOnClick = (event) => {
    let element = event.target;
    while (element != null && !element.classList.contains('underlineSpan')) {
      element = element.parentElement;
    }

    if (element && element.dataset.url) {
      if (element.dataset.url.includes('https://')) {
        window.open(
          element.dataset.url,
          '_blank'
        );
      } else {
        navigate(element.dataset.url,{state:'send'});
        localStorage.setItem('scrollPositionT', window.scrollY);
      }
    }
  };

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <p className="text40class text-LightPrimary">{t('ForCustomer1Title')}</p>
      <p className="mt-5 paragraphText">Last modified: April 23, 2024</p>
      <p className="paragraphText text-[18px] font-bold mt-10">
        {t('PLEASEREADCAREFULLY')}
      </p>
      <div className="flex flex-col  gap-[16px]">
        <p className="mt-4 smallText16Normal">{t('CustomerText')}</p>
        <p
          className="smallText16Normal"
          dangerouslySetInnerHTML={{ __html: t('GeneralTermsT') }}
          onClick={() => navigate('/legal')}
        ></p>
        {/* {t('GeneralTermsT')} */}
        {/* </div> */}
        <div className="smallText16Normal">
          <span
            className="text-ButtonPrimary underline cursor-pointer"
            onClick={() => navigate('/legal/product-specific-terms')}
          >
            {t('ProductTerms')}
          </span>
          {t('ProductTermsT')}
        </div>
        <div className="smallText16Normal">
          <span
            className="text-ButtonPrimary underline cursor-pointer"
            onClick={() => navigate('/legal/dpa')}
          >
            {t('DataProcessingAgreement')}
          </span>
          {t('DataProcessingAgreementT')}
        </div>
        <div className="smallText16Normal">
          <span
            className="text-ButtonPrimary underline cursor-pointer"
            onClick={() => navigate('/legal/acceptable-use')}
          >
            {t('AcceptableUsePolicy')}
          </span>
          {t('AcceptableUsePolicyT')}
        </div>
        <p className="smallText16Normal">{t('CustomerText1')}</p>
        <p className="smallText16Normal">{t('CustomerText2')}</p>
      </div>
      <div className="mt-[30px] flex flex-col gap-3">
        <p className="text22">{t('GeneralTerm')}</p>
        <div className="flex flex-col gap-4">
          {customerTermsList.map((list1, index) => (
            <div className="flex gap-2 w-max" key={index}>
              <p className="smallText16Normal text-LightPrimary">
                {index + 1}.
              </p>
              <p
                className="smallText16Normal cursor-pointer text-LightPrimary underline"
                onClick={() => handleClickScroll(list1.id)}
              >
                {list1.name}
              </p>
            </div>
          ))}
        </div>
      </div>
      {/* 1. DEFINATION */}
      <Definition
        count={1}
        title={t('Definitions')}
        data={definationList}
        handleOnClick={handleOnClick}
        id="Definitions"
      />
      {/* 2..... UseOfServices */}
      <UseOfServiceComp
        data={UseOfServicesList}
        id="UseOfServices"
        handleOnClick={handleOnClick}
      />
      {/* 3..... Fees */}
      <FeesComp data={feesList} id="Fees" handleOnClick={handleOnClick} />
      {/* 4 Term & Termination */}
      <CommonlegalComponent
        data={TermsAndTerminationList}
        title={t('TermTermination')}
        count={4}
        id="TermTermination"
        handleOnClick={handleOnClick}
      />
      {/* 5 CustomerData */}
      <CommonlegalComponent
        data={CustomerDataList}
        title={t('CustomerData')}
        count={5}
        id="CustomerData"
        handleOnClick={handleOnClick}
      />
      {/* 6 IntellectualProperty */}
      <CommonlegalComponent
        data={IntellectualProperty}
        title={t('IntellectualProperty')}
        count={6}
        id="IntellectualProperty"
      />
      {/* 7 Confidentiality */}
      <ConfidentialityComp
        data={ConfidentialityList}
        count={7}
        title={t('Confidentiality')}
        id="Confidentiality"
      />
      {/* 8 Publicity */}
      <CommonHeadText
        data={[t('PublicityT')]}
        title={t('Publicity')}
        count={8}
        id="Publicity"
        handleOnClick={handleOnClick}
      />
      {/* 9 Publicity */}
      <CommonHeadText
        data={[t('IndemnificationT')]}
        title={t('Indemnification')}
        count={9}
        id="Indemnification"
      />
      {/* 10 Confidentiality */}
      <ConfidentialityComp
        data={DisclaimersList}
        count={10}
        title={t('DisclaimersLimitationsLiability')}
        id="DisclaimersLimitationsLiability"
      />
      {/* 11 MISCELLANEOUS */}
      <ConfidentialityComp
        data={MiscellaneousList}
        count={11}
        title={t('Miscellaneous')}
        id="Miscellaneous"
      />
    </div>
  );
};
const getTranslatedList = (t, key) => {
  const lists = {
    customerTermsList: [
      {
        id: 'Definitions',
        name: t('Definitions'),
      },
      {
        id: 'UseOfServices',
        name: t('UseOfServices'),
      },
      {
        id: 'Fees',
        name: t('Fees'),
      },
      {
        id: 'TermTermination',
        name: t('TermTermination'),
      },
      {
        id: 'CustomerData',
        name: t('CustomerData'),
      },
      {
        id: 'IntellectualProperty',
        name: t('IntellectualProperty'),
      },
      {
        id: 'Confidentiality',
        name: t('Confidentiality'),
      },
      {
        id: 'Publicity',
        name: t('Publicity'),
      },
      {
        id: 'Indemnification',
        name: t('Indemnification'),
      },
      {
        id: 'DisclaimersLimitationsLiability',
        name: t('DisclaimersLimitationsLiability'),
      },
      {
        id: 'Miscellaneous',
        name: t('Miscellaneous'),
      },
    ],
    defList: [
      t('Def1'),
      t('Def2'),
      t('Def3'),
      t('Def4'),
      t('Def5'),
      t('Def6'),
      t('Def7'),
      t('Def8'),
      t('Def9'),
      t('Def10'),
      t('Def11'),
      t('Def12'),
      t('Def13'),
      t('Def14'),
      t('Def15'),
      t('Def16'),
      t('Def17'),
      t('Def18'),
      t('Def19'),
      t('Def20'),
      t('Def21'),
      t('Def22'),
      t('Def23'),
      t('Def24'),
      t('Def25'),
      t('Def26'),
      t('Def27'),
      t('Def28'),
      t('Def29'),
      t('Def30'),
    ],
    useOfService: [
      {
        name: t('2.1Access'),
        text: [t('Access1'), t('Access2')],
      },
      {
        name: t('2.2AdditionalFeatures'),
        text: [t('AdditionalF')],
      },
      {
        name: t('2.3AvailabilityUptime'),
        text: [t('Availability')],
      },
      {
        name: t('2.4Limits'),
        text: [t('Limits1'), t('Limits2')],
      },
      {
        name: t('2.5Downgrades'),
        text: [t('Downgrades')],
      },
      {
        name: t('2.6Modifications'),
        text: [t('Modifications')],
      },
      {
        name: t('2.7CustomerSupport'),
        text: [t('CusSupport')],
      },
      {
        name: t('2.8AcceptableUse'),
        text: [t('AcceptableUse')],
      },
      {
        name: t('2.9Prohibited'),
        text: [
          t('Prohibited1'),
          t('Prohibited2'),
          t('Prohibited3'),
          t('Prohibited4'),
        ],
      },
      {
        name: t('2.10NoSensitive'),
        text: [t('Sensitive')],
      },
      {
        name: t('2.11Responsibilities'),
        text: [t('Responsibilities')],
      },
      {
        name: t('2.12FreeTrial'),
        text: [t('FreeTrial')],
      },
      {
        name: t('2.13LegacyProduct'),
        text: [t('LegacyProduct')],
      },
    ],
    fees: [
      {
        name: t('3.1SubscriptionFees'),
        text: [
          t('SubscriptionFee1'),
          t('SubscriptionFee2'),
          t('SubscriptionFee3'),
          t('SubscriptionFee4'),
        ],
      },
      {
        name: t('3.2FeeAdjustments'),
        text: [t('FeeAdjustmentsT')],
      },
      {
        name: t('3.3PaymentFees'),
        text: [t('PaymentFeesT1'), t('PaymentFeesT2')],
      },
      {
        name: t('3.4PaymentInvoice'),
        text: [t('PaymentInvoice')],
      },
      {
        name: t('3.5PaymentInfo'),
        text: [t('PaymentInfo')],
      },
      {
        name: t('3.6CarrierSurcharges'),
        text: [t('CarrierSurcharges')],
      },
    ],
    terms_termination: [
      {
        name: t('4.1TermRenewal'),
        text: [t('TermRenewal')],
      },
      {
        name: t('4.2NoticeNonRenewal'),
        text: [t('NoticeNonRenewal')],
        isExternalLink: true,
        link: 'https://support.dcdial.com/',
      },
      {
        name: t('4.3EarlyCancellation'),
        text: [t('EarlyCancellation')],
      },
      {
        name: t('4.4TerminationCause'),
        text: [
          t('TerminationCause1'),
          t('TerminationCause2'),
          t('TerminationCause3'),
          t('TerminationCause4'),
        ],
      },
      {
        name: t('4.5Suspension'),
        text: [],
        subSection: [
          {
            name: t('4.5.1Suspension'),
            text: [
              t('Suspension1'),
              t('Suspension2'),
              t('Suspension3'),
              t('Suspension4'),
              t('Suspension5'),
            ],
          },
          {
            name: t('4.5.2Suspension'),
            text: [t('SuspensionNon')],
          },
          {
            name: t('4.5.3SuspensionHarm'),
            text: [t('SuspensionHarm')],
          },
          {
            name: t('4.5.4TerminationServices'),
            text: [t('SuspensionHarm')],
          },
        ],
      },
      {
        name: t('4.6TerminationExpiration'),
        text: [t('TerminationExpiration')],
      },
    ],
    customerData: [
      {
        name: t('5.1CustomerRights'),
        text: [t('CustomerRightsT')],
      },
      {
        name: t('5.2LimitsDCDial'),
        text: [t('LimitsDCDial')],
      },
      {
        name: t('5.3DataMachineLearning'),
        subSection: [
          {
            name: t('5.3.1UsageData'),
            text: [t('UsageData1')],
          },
          {
            name: t('5.3.2MachineLearning'),
            text: [t('MachineLearning')],
          },
          {
            name: t('5.3.3PrivacyPolicy'),
            text: [t('3PrivacyPolicy')],
          },
        ],
      },
      {
        name: t('5.4ProtectionCustomer'),
        text: [t('ProtectionCustomerT')],
      },
      {
        name: t('5.5RegionalHosting'),
        text: [t('RegionalHostingT')],
      },
      {
        name: t('5.6CustomerTransfers'),
        text: [t('CustomerTransfersT')],
      },
      {
        name: t('5.7RetentionDeletionRetrieval'),
        text: [t('RetentionDeletionRetrievalT')],
      },
    ],
    intellectualProperty: [
      {
        name: t('6.1IntellectualProperty'),
        text: [t('IntellectualPropertyT')],
      },
      {
        name: t('6.2FeedbackSuggestions'),
        text: [t('FeedbackSuggestionsT')],
      },
    ],
    confidentiality: [
      {
        name: t('7.1ProtectionConfidential'),
        text: [
          t('ProtectionConf1'),
          t('ProtectionConf2'),
          t('ProtectionConf3'),
          t('ProtectionConf4'),
        ],
      },
      {
        name: t('7.2LegalDisclosure'),
        text: [t('LegalDisclosureT')],
      },
    ],
    disclaimers: [
      {
        name: t('10.1PerformanceWarranty'),
        text: [t('PerformanceWarrantyT')],
      },
      {
        name: t('10.2DisclaimerWarranties'),
        text: [t('DisclaimerWarrantiesT')],
      },
      {
        name: t('10.1PerformanceWarranty'),
        text: [t('PerformanceWarrantyT')],
      },
      {
        name: t('10.3IndirectDamages'),
        text: [t('IndirectDamagesT')],
      },
      {
        name: t('10.4LimitationLiability'),
        text: [t('LimitationLiabilityT')],
      },
      {
        name: t('10.5ThirdParty'),
        text: [t('ThirdPartyT')],
      },
      {
        name: t('10.6AgreementLiability'),
        text: [t('AgreementLiabilityT')],
      },
    ],
    miscellaneous: [
      {
        name: t('11.1AmendmentWaiver'),
        text: [t('AmendmentWaiverT')],
      },
      {
        name: t('11.2ForceMajeure'),
        text: [t('ForceMajeureT')],
      },
      {
        name: t('11.3ActionsPermitted'),
        text: [t('ActionsPermittedT')],
      },
      {
        name: t('11.4RelationshipParties'),
        text: [t('RelationshipPartiesT')],
      },
      {
        name: t('11.5ComplianceLaws'),
        text: [t('ComplianceLawsT')],
      },
      {
        name: t('11.6Severability'),
        text: [t('SeverabilityT')],
      },
      {
        name: t('11.7Notices'),
        text: [t('NoticesT')],
      },
      {
        name: t('11.8EntireAgreement'),
        text: [t('EntireAgreementT')],
      },
      {
        name: t('11.9Assignment'),
        text: [t('AssignmentT')],
      },
      {
        name: t('11.10ThirdBeneficiaries'),
        text: [t('ThirdBeneficiariesT')],
      },
      {
        name: t('11.11ContractServices'),
        text: [t('ContractServicesT')],
      },
      {
        name: t('11.12Authority'),
        text: [t('AuthorityT')],
      },
      {
        name: t('11.13Insurance'),
        text: [t('InsuranceT')],
      },
      {
        name: t('11.14ArbitrationMediation'),
        text: [t('ArbitrationMediationT')],
      },
      {
        name: t('11.15Jurisdiction'),
        text: [t('JurisdictionT')],
      },
    ],
  };

  return lists[key];
};
export default TermsOfServiceContent;
