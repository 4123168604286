import React from 'react';
import { Helmet } from 'react-helmet';

const TitleAndDesc = ({ title, description, isImageURL, isPageURL }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      {isImageURL && <meta property="og:image" content={isImageURL} />}

      {isPageURL && (
        <meta property="og:url" content={isPageURL}></meta>
      )}
    </Helmet>
  );
};

export default TitleAndDesc;
