import React from 'react'
import { SaveIconL, SaveIconT } from '../../assets/Icons/Pricing/pricingIcons';
import { useWindowSize } from '../../Hooks/useWindowSize';

const TogglePlan = ({ selected, setSelected, pricingOptions }) => {
    const { width: windowWidth } = useWindowSize();
  const isTablate = windowWidth <= 600;
  const isSmalldevice = windowWidth <=320
  return (
    <div className="relative border-[1px] border-LightOutline p-1 rounded-[10px] flex w-fit text-center max_esm:flex-wrap max_esm:justify-center max_esm:w-[190px]">
      {pricingOptions.map((list, index) => {
        return (
          <React.Fragment key={index}>
            <div
              className={`cursor-pointer w-[190px] rounded-[10px] subParagraphText py-3 max_sm:py-2 flex justify-center max_md:w-[144px] max_sm:w-[100px] max_esm:w-full max_sm:text-textExtraSmall ${
                selected.name === list.name
                  ? 'text-white bg-ButtonPrimary'
                  : 'text-LightSecondary'
              }`}
              onClick={() => setSelected(list)}
            >
              {list.name}
            </div>
          </React.Fragment>
        );
      })}

      <div className="absolute right-[-58px] top-1 max_800:right-[-26px] max_800:top-[-32px] max_600:right-[38px] max_600:top-[-26px] max_esm:top-[120px] max_esm:right-[-2px]">
        {isTablate ? (
          <SaveIconT style={{ transform: isSmalldevice ? 'scaleY(-1)' : '' }} />
        ) : (
          <SaveIconL />
        )}
        <div className="absolute top-[-23px] right-[-49px] max_600:right-[-42px] max_600:top-[-18px] max_esm:right-[-38px]">
          <p className="text-textSmall font-DMSansFont font-medium text-LightPrimary max_600:text-[12px]">
            Save
          </p>
          <p className="text-subHeadingXl font-DMSansFont font-bold text-ButtonPrimary max_600:text-textMedium">
            20%
          </p>
        </div>
      </div>
    </div>
  );
};

export default TogglePlan