export const ExploreArrow = (props) => {
  return (
    <>
      <svg
        {...props}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1033_35546)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.7012 4.16137L2.43021 9.55298C2.02251 9.67965 2.20423 10.342 2.69928 10.5329L10.9448 13.7144L14.1261 21.9597C14.3173 22.4541 14.9785 22.6357 15.1057 22.2285L20.4973 4.95746C20.5564 4.74937 20.4907 4.56252 20.2931 4.36539C20.0956 4.16814 19.8779 4.1052 19.7012 4.16137ZM17.9001 5.90991L11.0369 12.7731L4.27505 10.1638L17.9001 5.90991Z"
            fill="#00A0FC"
          />
        </g>
        <defs>
          <clipPath id="clip0_1033_35546">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
export const TickMarkIcon = ({color,width='26',height='26'}) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.9993 24.6458C6.57518 24.6458 1.35352 19.4241 1.35352 13C1.35352 6.57579 6.57518 1.35413 12.9993 1.35413C19.4235 1.35413 24.6452 6.57579 24.6452 13C24.6452 19.4241 19.4235 24.6458 12.9993 24.6458ZM12.9993 2.97913C7.47435 2.97913 2.97852 7.47496 2.97852 13C2.97852 18.525 7.47435 23.0208 12.9993 23.0208C18.5243 23.0208 23.0202 18.525 23.0202 13C23.0202 7.47496 18.5243 2.97913 12.9993 2.97913Z"
          fill={color}
        />
        <path
          d="M11.4616 16.8783C11.2449 16.8783 11.0391 16.7916 10.8874 16.64L7.82156 13.5742C7.5074 13.26 7.5074 12.74 7.82156 12.4258C8.13573 12.1117 8.65573 12.1117 8.9699 12.4258L11.4616 14.9175L17.0299 9.34915C17.3441 9.03498 17.8641 9.03498 18.1782 9.34915C18.4924 9.66332 18.4924 10.1833 18.1782 10.4975L12.0357 16.64C11.8841 16.7916 11.6782 16.8783 11.4616 16.8783Z"
          fill={color}
        />
      </svg>
    </>
  );
};
export const RoundBack = (props) => {
  return (
    <>
      <svg
        {...props}
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="60" cy="60" r="60" fill="#EEF3FC" />
      </svg>
    </>
  );
};
export const RoundGroup = (props) => {
  return (
    <>
      <svg
        {...props}
        width="150"
        height="120"
        viewBox="0 0 150 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="60" cy="60" r="60" fill="#EEF3FC" />
        <circle cx="136" cy="106" r="14" fill="#EEF3FC" />
        <circle cx="140" cy="72" r="8" fill="#EEF3FC" />
      </svg>
    </>
  );
};
export const RoundGroupDown = (props) => {
  return (
    <>
      <svg
        {...props}
        width="120"
        height="150"
        viewBox="0 0 120 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="60"
          cy="60"
          r="60"
          transform="rotate(90 60 60)"
          fill="#EEF3FC"
        />
        <circle
          cx="14"
          cy="136"
          r="14"
          transform="rotate(90 14 136)"
          fill="#EEF3FC"
        />
        <circle
          cx="48"
          cy="140"
          r="8"
          transform="rotate(90 48 140)"
          fill="#EEF3FC"
        />
      </svg>
    </>
  );
};
export const RoundGroupUp = (props) => {
  return (
    <>
      <svg
        {...props}
        width="120"
        height="150"
        viewBox="0 0 120 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="60"
          cy="90"
          r="60"
          transform="rotate(-90 60 90)"
          fill="#EEF3FC"
        />
        <circle
          cx="106"
          cy="14"
          r="14"
          transform="rotate(-90 106 14)"
          fill="#EEF3FC"
        />
        <circle
          cx="72"
          cy="10"
          r="8"
          transform="rotate(-90 72 10)"
          fill="#EEF3FC"
        />
      </svg>
    </>
  );
};
export const RoundBackLG = (props) => {
  return (
    <>
      <svg
        {...props}
        width="450"
        height="450"
        viewBox="0 0 450 450"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="225" cy="225" r="225" fill="#EEF3FC" />
      </svg>
    </>
  );
};
export const StarGroup = (props) => {
  return (
    <>
      <svg
        {...props}
        width="114"
        height="102"
        viewBox="0 0 114 102"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M43.3426 22.4893C45.4381 16.0402 54.5619 16.0402 56.6574 22.4894L59.6541 31.7123C60.5912 34.5964 63.2789 36.5491 66.3115 36.5491H76.009C82.7901 36.5491 85.6095 45.2265 80.1235 49.2123L72.2781 54.9123C69.8246 56.6949 68.798 59.8544 69.7352 62.7386L72.7319 71.9615C74.8273 78.4107 67.446 83.7735 61.96 79.7877L54.1145 74.0877C51.6611 72.3051 48.3389 72.3051 45.8855 74.0877L38.04 79.7877C32.554 83.7735 25.1727 78.4107 27.2681 71.9615L30.2648 62.7386C31.202 59.8544 30.1754 56.6949 27.7219 54.9123L19.8765 49.2123C14.3905 45.2265 17.2099 36.5491 23.991 36.5491H33.6885C36.7211 36.5491 39.4088 34.5964 40.3459 31.7123L43.3426 22.4893Z"
          fill="#FFF6D5"
        />
        <path
          d="M93.9122 9.50329C94.8841 6.51206 99.1159 6.51206 100.088 9.50329C100.522 10.841 101.769 11.7467 103.176 11.7467C106.321 11.7467 107.628 15.7714 105.084 17.6201C103.946 18.4468 103.47 19.9123 103.905 21.25C104.876 24.2412 101.453 26.7286 98.9084 24.8799C97.7704 24.0532 96.2296 24.0532 95.0916 24.8799C92.5471 26.7286 89.1235 24.2412 90.0955 21.25C90.5301 19.9123 90.054 18.4468 88.916 17.6201C86.3715 15.7714 87.6792 11.7467 90.8244 11.7467C92.2309 11.7467 93.4775 10.841 93.9122 9.50329Z"
          fill="#FFF6D5"
        />
      </svg>
    </>
  );
};
export const StarGroupCross = (props) => {
  return (
    <>
      <svg
        {...props}
        width="138"
        height="184"
        viewBox="0 0 138 184"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="54.5" cy="54.5" r="54.5" fill="#EEF3FC" />
        <circle cx="51.5" cy="167.5" r="16.5" fill="#EEF3FC" />
        <circle cx="122.5" cy="98.5" r="15.5" fill="#EEF3FC" />
      </svg>
    </>
  );
};
export const StarIcon = (props) => {
  return (
    <>
      <svg
        {...props}
        width="304"
        height="292"
        viewBox="0 0 304 292"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M123.468 20.8115C132.449 -6.82785 171.551 -6.82781 180.532 20.8115L195.78 67.7417C199.796 80.1024 211.315 88.4712 224.312 88.4712H273.657C302.719 88.4712 314.802 125.66 291.291 142.742L251.37 171.746C240.855 179.385 236.455 192.926 240.471 205.287L255.72 252.217C264.701 279.857 233.066 302.84 209.555 285.758L169.634 256.754C159.119 249.115 144.881 249.115 134.366 256.754L94.4452 285.758C70.9338 302.84 39.2994 279.857 48.28 252.217L63.5285 205.287C67.5448 192.926 63.145 179.385 52.6304 171.746L12.7092 142.742C-10.8022 125.66 1.28107 88.4712 30.3428 88.4712H79.6881C92.6849 88.4712 104.204 80.1024 108.22 67.7417L123.468 20.8115Z"
          fill="#FFF6D5"
        />
      </svg>
    </>
  );
};
export const ErrorIcon = (props) => {
  return (
    <>
      <svg
        {...props}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.00004 15.1666C4.04671 15.1666 0.833374 11.9533 0.833374 7.99992C0.833374 4.04659 4.04671 0.833252 8.00004 0.833252C11.9534 0.833252 15.1667 4.04659 15.1667 7.99992C15.1667 11.9533 11.9534 15.1666 8.00004 15.1666ZM8.00004 1.83325C4.60004 1.83325 1.83337 4.59992 1.83337 7.99992C1.83337 11.3999 4.60004 14.1666 8.00004 14.1666C11.4 14.1666 14.1667 11.3999 14.1667 7.99992C14.1667 4.59992 11.4 1.83325 8.00004 1.83325Z"
          fill="#FF0000"
        />
        <path
          d="M8 9.16659C7.72667 9.16659 7.5 8.93992 7.5 8.66659V5.33325C7.5 5.05992 7.72667 4.83325 8 4.83325C8.27333 4.83325 8.5 5.05992 8.5 5.33325V8.66659C8.5 8.93992 8.27333 9.16659 8 9.16659Z"
          fill="#FF0000"
        />
        <path
          d="M8.00004 11.3332C7.91337 11.3332 7.82671 11.3132 7.74671 11.2798C7.66671 11.2465 7.59337 11.1998 7.52671 11.1398C7.46671 11.0732 7.42004 11.0065 7.38671 10.9198C7.35337 10.8398 7.33337 10.7532 7.33337 10.6665C7.33337 10.5798 7.35337 10.4932 7.38671 10.4132C7.42004 10.3332 7.46671 10.2598 7.52671 10.1932C7.59337 10.1332 7.66671 10.0865 7.74671 10.0532C7.90671 9.98651 8.09337 9.98651 8.25337 10.0532C8.33337 10.0865 8.40671 10.1332 8.47337 10.1932C8.53337 10.2598 8.58004 10.3332 8.61337 10.4132C8.64671 10.4932 8.66671 10.5798 8.66671 10.6665C8.66671 10.7532 8.64671 10.8398 8.61337 10.9198C8.58004 11.0065 8.53337 11.0732 8.47337 11.1398C8.40671 11.1998 8.33337 11.2465 8.25337 11.2798C8.17337 11.3132 8.08671 11.3332 8.00004 11.3332Z"
          fill="#FF0000"
        />
      </svg>
    </>
  );
};
