import React, { useEffect } from 'react';
import Layout from '../../Components/Layout';
import LegalSideLayout from '../../Components/LegalSideLayout';
import WebTermsOfUseContent from '../../Components/Legal/WebTermsOfUseContent';
import TitleAndDesc from '../../Components/Helmet/TitleAndDesc';
import { useLocation } from 'react-router-dom';

const WebTermsOfUse = () => {
    const location = useLocation();
    const state = location.state;
    useEffect(() => {
      if (state && state !== null) {
        window.scrollTo(0, 0);
      }
    }, [state]);

    var scrollPosition = localStorage.getItem('scrollPositionWT');
    if (scrollPosition !== null) {
      window.scrollTo(0, parseInt(scrollPosition));
    }
  return (
    <>
      <TitleAndDesc
        title="Website Terms of Use"
        description="By accessing DCDial's website, you agree to abide by our Website Terms of Use, which govern your use of our site and its content."
      />
      <Layout>
        <LegalSideLayout>
          <WebTermsOfUseContent />
        </LegalSideLayout>
      </Layout>
    </>
  );
};

export default WebTermsOfUse;
