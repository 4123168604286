import React, { useEffect, useState } from 'react';

import DcDialLogoEn from '../assets/images/Navbar/LogoEn.svg';
import Menu from '../assets/images/Navbar/menu.svg';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { ButtonFilled } from './utils/Button';
import { useTranslation } from 'react-i18next';

const Navbar = ({
  setIsMobileMenuOpen,
  activeLangauge,
  // setActiveLangauge,
  // handleClickScroll,
  // onChangeFun,
  // toggleModal,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [MenuList, setMenuList] = useState([]);

  useEffect(() => {
    setMenuList(getTranslatedList(t, activeLangauge));
  }, [activeLangauge, t]);

  // useEffect(() => {
  //   // Update lists when language changes
  //   setMenuList(getTranslatedList(t, activeLangauge));

  // }, [t, activeLangauge]);

  const handleClick = (to, e) => {
    e.preventDefault(); // Prevent default navigation
    navigate(to);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 50);
  };

  const handleSignUp = () => {
    navigate('/signup');
  };
  return (
    <div
      className={`z-[20] w-[100%] m-auto flex items-center max_md:px-10 max_sm:px-5 max-w-[1240px] max_xl:max-w-[980px] ${
        location.pathname === '/chrome/uninstall-feedback' ||
        location.pathname === '/chrome/uninstall-success'
          ? 'h-[80px]'
          : 'h-[66px]'
      }`}
    >
      <div className="F-JC-AI-CENTER w-full justify-between ">
        <div className="anime-fade-in flex items-center justify-between cursor-pointer max_md:w-[100%]">
          <div
            className="z-40 w-[140px] max_sm:w-[90px] "
            onClick={() => {
              if (location.pathname === '/') {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth',
                });
              } else {
                navigate('/');
                setTimeout(() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                }, 100);
              }
            }}
          >
            <img draggable="false" src={DcDialLogoEn} alt="Logo" />
          </div>
          {location.pathname !== '/chrome/uninstall-feedback' && (
            <>
              {location.pathname !== '/chrome/uninstall-success' && (
                <div className="hidden max_md:block">
                  <div className="flex items-center gap-7 max_sm:gap-3">
                    <div
                      className="cursor-pointer z-40"
                      onClick={() => {
                        setIsMobileMenuOpen(true);
                      }}
                    >
                      <img src={Menu} alt="Menu" />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <div className="relative F-JC-AI-CENTER flex gap-x-[20px] max_md:hidden">
          {location.pathname === '/chrome/uninstall-feedback' ||
          location.pathname === '/chrome/uninstall-success' ? (
            <>
              <a
                href="https://chromewebstore.google.com/detail/dcdial-copilot-ai-powered/jdnegmegbiomfoacccogppbebbiopmhi"
                rel="noopener noreferrer"
                target="_self"
              >
                <div className="flex gap-3 p-3 border-[1px] rounded-md border-LightOutline cursor-pointer">
                  <p>🚀</p>
                  <p className="text-[#1678F2] font-DMSansFont text-textSmall font-normal">
                    Continue to use DCDial
                  </p>
                </div>
              </a>
            </>
          ) : (
            <>
              {MenuList.map((menu, index) => {
                return (
                  <div key={index}>
                    <NavLink
                      className="z-[90] flex h-[50px] w-full items-center justify-end no-underline"
                      to={menu.link}
                      onClick={(e) => handleClick(menu.link, e)}
                    >
                      {({ isActive }) => (
                        <>
                          <div
                            className={`${
                              isActive &&
                              (location.pathname === '/products' ||
                                location.pathname === '/solutions' ||
                                location.pathname === '/pricing')
                                ? 'text-LightPrimary'
                                : 'text-LightTextParagraph'
                            } cursor-pointer text-textSmall font-[530] font-DMSansFont hover:text-LightPrimary`}
                            // ref={outSideRef}
                          >
                            {menu.name}
                          </div>
                        </>
                      )}
                    </NavLink>
                  </div>
                );
              })}
              {/* Popup menu  */}

              <div className="F-JC-AI-CENTER text-base font-DMSansFont font-medium text-ButtonPrimary cursor-pointer">
                <a
                  href="https://www.dcdial.com/app/login"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('SignIn')}
                </a>{' '}
              </div>

              <div className="z-[60]">
                <ButtonFilled
                  title={t('GetStarted')}
                  fontWeight="700"
                  fontSize="18px"
                  onClick={handleSignUp}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const getTranslatedList = (t) => {
  // Define your lists here
  const lists = [
    {
      name: t('Products'),
      link: '/products',
      langauge: false,
    },
    {
      name: t('Solutions'),
      link: '/solutions',
      langauge: false,
    },
    {
      name: t('Pricing'),
      link: '/pricing',
      langauge: false,
    },
    // {
    //   name: activeLangauge,
    //   link: '',
    //   langauge: true,
    // },
  ];

  return lists;
};
export default Navbar;

// if (menu.langauge) {
//   if (i18n.language === 'en') {
//     onChangeFun('ar');
//     setActiveLangauge('English');
//   } else {
//     onChangeFun('en');
//     setActiveLangauge('عربي');
//   }
// }
