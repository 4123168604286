import React from 'react';
import { ButtonOutLine } from '../utils/Button';
import PopularImg from '../../assets/images/Pricing/Popular.svg';
import { useNavigate } from 'react-router-dom';
import {
  BasicPlanIcon,
  EverythingPlanIcon,
  GoldPlanIcon,
  GrowPlanIcon,
  OnDemandIcon,
  PlatinumIcon,
  ProPlanIcon,
  SilverPlanIcon,
  StartupIcon,
} from '../../assets/Icons/Pricing/pricingIcons';

const PlanCard = ({ planInfo, fullWidth, selected }) => {
  const navigate = useNavigate();
  function convertToTitleCase(str) {
    return str
      .toLowerCase() // Convert the entire string to lowercase
      .split(' ') // Split the string into an array of words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' '); // Join the words back into a single string
  }
  const convertedString = planInfo?.name
    ? convertToTitleCase(planInfo.name)
    : '';

  const filteredPrice =
    planInfo &&
    planInfo.prices &&
    planInfo.prices.find((price) => price.schedule === selected.val);

  const formatNumberWithCommas = (number) => {
    return number.toLocaleString();
  };

  const descriptionParts = filteredPrice?.description.split(' and ') || [];
  const convertToCents = (value) => {
    return (value * 100).toFixed(1);
  };
  const formatNumber = (value) => {
    return parseFloat(value).toString();
  };
  return (
    <div
      className={`relative border-[1px] rounded-[16px] hover:border-ButtonPrimary p-6 ${
        fullWidth
          ? 'w-[285px] max_sm:w-[280px] max_sm_380:w-[260px]'
          : 'w-[240px] max_1440:w-[250px]'
      } ${
        convertedString === 'Grow' || convertedString === 'Gold'
          ? 'bg-BgPrimary border-BgPrimary'
          : 'border-LightWhite'
      }`}
    >
      <div>
        {/* {React.cloneElement(planInfo.icon, {
          color: planInfo.isPopular ? '#19224C' : '#6D77A0',
        })} */}
        {convertedString === 'On Demand' ? (
          <OnDemandIcon />
        ) : convertedString === 'Basic' ? (
          <BasicPlanIcon />
        ) : convertedString === 'Startup' ? (
          <StartupIcon />
        ) : convertedString === 'Grow' ? (
          <GrowPlanIcon />
        ) : convertedString === 'Pro' ? (
          <ProPlanIcon />
        ) : convertedString === 'Silver' ? (
          <SilverPlanIcon />
        ) : convertedString === 'Gold' ? (
          <GoldPlanIcon />
        ) : convertedString === 'Platinum' ? (
          <PlatinumIcon />
        ) : (
          <EverythingPlanIcon />
        )}
      </div>
      <p
        className={`subParagraphText font-semibold mt-5 ${
          convertedString === 'Grow' || convertedString === 'Gold'
            ? 'text-LightPrimary'
            : 'text-LightTextParagraph'
        }`}
      >
        {convertedString}
      </p>
      <div
        className={`text-[38px] font-DMSansFont font-semibold ${
          convertedString === 'Grow' || convertedString === 'Gold'
            ? 'text-ButtonPrimary'
            : 'text-LightPrimary'
        }`}
      >
        {filteredPrice.price === '0.00' || filteredPrice.price === '0' ? (
          '4¢'
        ) : (
          <>
            {filteredPrice.schedule === 'year'
              ? '$' +
                formatNumberWithCommas(
                  parseFloat(filteredPrice.price).toFixed(2).split('.')[0] / 12
                )
              : '$' +
                formatNumberWithCommas(
                  parseFloat(filteredPrice.price).toFixed(2).split('.')[0] / 1
                )}
          </>
        )}

        <span className="text-[16px] font-semibold ml-2 ">
          {convertedString === 'On Demand'
            ? 'per minute/text'
            : filteredPrice.schedule === 'month' ||
              filteredPrice.schedule === 'year'
            ? '/month'
            : '/week'}
        </span>
      </div>
      <div
        className={` ${
          convertedString === 'Grow' || convertedString === 'Gold'
            ? '!text-LightPrimary'
            : '!text-LightTextParagraph'
        }`}
      >
        <p className={`mt-[30px] text-[22px] font-DMSansFont font-semibold `}>
          {convertedString === 'On Demand' ? (
            <>{descriptionParts[0].toLowerCase()}</>
          ) : (
            <>
              {convertedString === 'Silver' && filteredPrice.schedule === 'week'
                ? descriptionParts[0]?.replace(' minutes/text', '')
                : descriptionParts[0]?.replace(' minutes/texts', '')}
            </>
          )}
          {convertedString !== 'On Demand' && (
            <span className="text-textSmall font-medium ml-2">
              minutes/texts
            </span>
          )}
        </p>
        <p className="smallText18 font-semibold mt-3">
          {descriptionParts[1]?.replace('emails', '')}
          <span className="smallText18 font-medium ml-1">emails</span>
        </p>

        <div className="mt-5">
          <ButtonOutLine
            title="SIGN UP"
            isFilled={
              convertedString === 'Grow' || convertedString === 'Gold'
                ? true
                : false
            }
            background="#00A0FC"
            onClick={() =>
              navigate('/signup', {
                state: {
                  pr_id: filteredPrice?.pr_id,
                },
              })
            }
          />
        </div>
        <p className="smallText18 font-semibold mt-[30px]">
          Additional Services
        </p>
        <p className="smallText18 font-semibold mt-5">
          {convertToCents(filteredPrice.per_unit_charge) + '¢'}
          <span className="smallText18 font-medium ml-2">per min/text</span>
        </p>
        <p className="smallText18 font-semibold mt-5">
          {'$' + formatNumber(filteredPrice.per_number_charge)}
          <span className="smallText18 font-medium ml-2">per number</span>
        </p>
      </div>

      {/* IMAGE */}
      {(convertedString === 'Grow' || convertedString === 'Gold') && (
        <div className="absolute right-0 top-0">
          <img src={PopularImg} alt="Popular_img" />
        </div>
      )}
    </div>
  );
};

export default PlanCard;
