import React from 'react'
import { CheckboxInput } from '../../Components/utils/InputField';
import { ButtonFilled } from '../../Components/utils/Button';

const Feedback = ({
  checkedValue,
  formState,
  handleChange,
  handleSubmit,
  isLoading,
}) => {
  return (
    <div className={`Div1 ${checkedValue ? 'slide-out' : 'slide-out-back'}`}>
      <div className="flex flex-col mt-10 w-[460px] gap-10">
        <div>
          <p className="text-[22px] font-DMSansFont font-semibold text-LightTextParagraph text-center">
            About experience
          </p>
          <div className="mt-3 flex flex-col gap-[10px] items-start">
            <div className="bg-white shadow-[0_1px_2px_0_#f3f8fc] rounded-[10px] p-4 w-full">
              <CheckboxInput
                handleChange={handleChange}
                name="slow_down_pc"
                formState={formState.slow_down_pc}
                labelText="DCDial slows down my computer"
              />
            </div>
            <div className="bg-white shadow-[0_1px_2px_0_#f3f8fc] rounded-[10px] p-4 w-full">
              <CheckboxInput
                handleChange={handleChange}
                name="not_working"
                formState={formState.not_working}
                labelText="DCDial doesn’t work for certain websites or apps"
              />
            </div>
            <div className="bg-white shadow-[0_1px_2px_0_#f3f8fc] rounded-[10px] p-4 w-full">
              <CheckboxInput
                handleChange={handleChange}
                name="donotknow"
                formState={formState.donotknow}
                labelText="I don’t know how to use DCDial"
              />
            </div>
            <div className="bg-white shadow-[0_1px_2px_0_#f3f8fc] rounded-[10px] p-4 w-full">
              <CheckboxInput
                handleChange={handleChange}
                name="difficult2use"
                formState={formState.difficult2use}
                labelText="DCDial gets in my way or is difficult to use"
              />
            </div>
          </div>
        </div>
        <div>
          <p className="text-[22px] font-DMSansFont font-semibold text-LightTextParagraph text-center">
            Other
          </p>
          <div className="mt-3 flex flex-col gap-[10px] items-start">
            <div className="bg-white shadow-[0_1px_2px_0_#f3f8fc] rounded-[10px] p-4 w-full">
              <CheckboxInput
                handleChange={handleChange}
                name="product_manager"
                formState={formState.product_manager}
                labelText="Vent to the product manager about DCDial"
              />
            </div>
            <div className="bg-white shadow-[0_1px_2px_0_#f3f8fc] rounded-[10px] p-4 w-full">
              <CheckboxInput
                handleChange={handleChange}
                name="find_better_tool"
                formState={formState.find_better_tool}
                labelText="I use other AI extensions or found a better tool"
              />
            </div>
            <div className="bg-white shadow-[0_1px_2px_0_#f3f8fc] rounded-[10px] p-4 w-full">
              <CheckboxInput
                handleChange={handleChange}
                name="privacy_security_concern"
                formState={formState.privacy_security_concern}
                labelText="I have a privacy or security concern"
              />
            </div>
          </div>

          <div className="mt-10">
            <ButtonFilled
              title="Submit and uninstall"
              fontSize="16px"
              fontWeight="500"
              height="40px"
              disabled={!checkedValue}
              onClick={handleSubmit}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback