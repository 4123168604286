import React, { useState } from 'react';
import CommonSizeContainer from '../CommonSizeContainer';
import { useTranslation } from 'react-i18next';
import {
  AgentInterfaceIcon,
  APIAccessIcon,
  CallRecordIcon,
  CompilanceIcon,
  ContactManageIcon,
  CustomerPortalIcon,
  RealTimeReportIcon,
  UserManagementIcon,
  WorkFlowIcon,
} from '../../assets/Icons/Pricing/pricingIcons';

const AllPlans = () => {
  const { t } = useTranslation();
  const AllPlansData = [
    {
      name: t('ContactManagement'),
      icon: <ContactManageIcon />,
    },
    {
      name: t('CustomerPortal'),
      icon: <CustomerPortalIcon />,
    },
    {
      name: t('AgentInterface'),
      icon: <AgentInterfaceIcon />,
    },
    {
      name: t('WorkflowAutomation'),
      icon: <WorkFlowIcon />,
    },
    {
      name: t('RealAnalyticsReports'),
      icon: <RealTimeReportIcon />,
    },
    {
      name: t('AdvancedCompliance'),
      icon: <CompilanceIcon />,
    },
    {
      name: t('CallRecordingMon'),
      icon: <CallRecordIcon />,
    },
    {
      name: t('APIAccess'),
      icon: <APIAccessIcon />,
    },
    {
      name: t('DedicatedCustomer'),
      icon: <UserManagementIcon />,
    },
  ];
  const [allPlanDetails] = useState(AllPlansData);
  return (
    <CommonSizeContainer>
      <h2 className="headingText text-center">{t('AllPlans')}</h2>
      <div className="flex justify-center">
        <div className="mt-[100px] max_xl:mt-[70px] grid grid-cols-3 gap-5 max_xl:grid-cols-2 max_800:grid-cols-1 max_800:w-[400px] max_sm:w-[100%]">
          {allPlanDetails?.map((plans, index) => {
            return (
              <React.Fragment key={index}>
                <div className="py-4 px-5 rounded-[14px] flex gap-5 items-center border-[1px] max_sm:gap-3 border-LightOutline max_sm_380:px-[10px] max_sm_380:py-3">
                  <div className="rounded-full w-12 h-12 bg-ButtonPrimary F-JC-AI-CENTER ">
                    {plans.icon}
                  </div>
                  <p className="smallText18 font-medium text-LightTextParagraph max_sm:w-[calc(100%-58px)]">
                    {plans.name}
                  </p>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </CommonSizeContainer>
  );
};

export default AllPlans;
