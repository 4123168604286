import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { CloseCircleIcon, TickMarkGreenIcon } from '../../assets/Icons/Security/SecurityIcon';

const HandleCustomerData = () => {
  const { t } = useTranslation();
  const [dataProtect,setDataProtect] = useState([])
  const [dataNotProtect,setDataNotProtect] = useState([])
   useEffect(() => {
     setDataProtect(getTranslatedList(t, 'protect'));
     setDataNotProtect(getTranslatedList(t, 'not_protect'));
   }, [t]); 
  return (
    <div
      className="clip-path-class bg-LightBgSecondary py-[200px] max_xl:py-[140px] max_sm:py-[100px] mt-[50px]"
    >
      <div className="max-w-[1240px] max_xl:max-w-[980px] m-auto max_md:px-10 max_sm:px-5">
        <div className="flex flex-col gap-4">
          <p className="text50">{t('HandleCustomerdata')}</p>
          <p className="subHeadingText font-normal max_md:text-headingNormal max_sm:text-textSmall">
            {t('CusDataText1')}
          </p>
          <p className="subHeadingText font-normal max_md:text-headingNormal max_sm:text-textSmall">
            {t('CusDataText1')}
          </p>
        </div>
        <div className="mt-[60px] flex justify-between max_md:flex-col max_md:gap-10 max_sm:gap-5">
          <div className="bg-white rounded-[20px] p-[30px] max-w-[540px] max_xl:max-w-[460px] max_md:max-w-full">
            <p className="subHeadingText max_md:text-subHeadingTxt max_sm:text-headingNormal ">
              {t('DataProtect')}
            </p>
            <div className="mt-5 flex flex-col gap-4">
              {dataProtect.map((point, index1) => (
                <div className="flex gap-2" key={index1}>
                  <div className="mt-[2px]">
                    <TickMarkGreenIcon />
                  </div>
                  <p className="subParagraphText font-normal">{point}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-white rounded-[20px] p-[30px] max-w-[540px] max_xl:max-w-[460px] max_md:max-w-full">
            <p className="subHeadingText max_md:text-subHeadingTxt max_sm:text-headingNormal ">
              {t('DataNotProtect')}
            </p>
            <div className="mt-5 flex flex-col gap-4">
              {dataNotProtect.map((point, index2) => (
                <div className="flex gap-2" key={index2}>
                  <div className="mt-[2px]">
                    <CloseCircleIcon />
                  </div>
                  <p className="subParagraphText font-normal">{point}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const getTranslatedList = (t, listKey) => {
  const lists = {
    protect: [
      t('DataProtect1'),
      t('DataProtect2'),
      t('DataProtect3'),
      t('DataProtect4'),
    ],
    not_protect: [
      t('DataNotProtect1'),
      t('DataNotProtect2'),
      t('DataNotProtect3'),
    ],
  };

  return lists[listKey];
};

export default HandleCustomerData