import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RoundBackLG, RoundGroup, StarGroup, StarGroupCross, StarIcon, TickMarkIcon } from '../../assets/Icons/Features/FeatureIcon';

const AllFeatures = () => {
  const { t } = useTranslation();
  const [features, setFeatures] = useState({});
  useEffect(() => {
    setFeatures({
      feature1: getTranslatedList(t, 'feature1'),
      feature2: getTranslatedList(t, 'feature2'),
      feature3: getTranslatedList(t, 'feature3'),
      feature4: getTranslatedList(t, 'feature4'),
      feature5: getTranslatedList(t, 'feature5'),
      feature6: getTranslatedList(t, 'feature6'),
      feature7: getTranslatedList(t, 'feature7'),
    });
  }, [t]);
  return (
    <div className="mt-[150px] max_xl:mt-[100px] max_sm:mt-[60px] max_md:px-10 max_sm:px-5 flex flex-col gap-[150px] max_md:gap-[100px] max_sm:gap-[60px] overflow-hidden">
      {/* FEATURE 1 */}
      <div className="w-full">
        <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] flex justify-between max_md:flex-col-reverse max_md:gap-[60px] max_sm:gap-10 items-center">
          <div className="w-[40%] pl-[50px] max_xl:w-[45%] max_xl:pl-[30px] max_md:w-[336px] max_md:pl-0 max_sm:w-[100%]">
            <h3 className="text40class text-LightPrimary max_sm:max-w-[300px]">
              {t('CuttingEdgeDialer')}
            </h3>
            <div className="mt-10 max_xl:mt-8 flex flex-col gap-5 max_sm:gap-4 max_sm:mt-5">
              {features?.feature1?.map((feature1, index1) => (
                <div key={index1} className="flex gap-3 ">
                  <div className="mt-[2px] max_sm:mt-0">
                    <TickMarkIcon color="#A9CFFF" />
                  </div>
                  <p className="subParagraphText text-LightPrimary">
                    {feature1}
                  </p>
                </div>
              ))}
            </div>
          </div>
          {/* Image */}
          <div className="relative w-[61%] max_xl:w-[55%] z-20 max_md:w-[86%] max_sm:w-[100%]">
            <img
              src="https://images.dcdial.com/dcdial.com/v2/CuttingEdge.svg"
              alt="CuttingEdgeDialer"
              className="w-full z-10"
            />
            <div className="absolute right-[-150px] w-[55%] top-0 -z-10 max_xl:w-[50%] max_md:right-[-80px]">
              <RoundBackLG style={{ width: '100%', height: '100%' }} />
            </div>
            <div className="absolute bottom-[42px] left-[72px] -z-10">
              <RoundGroup style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
        </div>
      </div>

      {/* FEATURE 2 */}
      <div className="w-full">
        <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] flex flex-row-reverse justify-between max_md:flex-col-reverse max_md:gap-[60px] max_sm:gap-10 items-center">
          <div className="w-[35%] pr-[50px] max_xl:w-[45%] max_xl:pr-[30px] max_md:w-[326px] max_md:pr-0 max_sm:w-[100%]">
            <h3 className="text40class text-LightPrimary">
              {t('EmpowerYourAgents')}
            </h3>
            <div className="mt-10 max_xl:mt-8 flex flex-col gap-5 max_sm:gap-4 max_sm:mt-5">
              {features?.feature2?.map((feature1, index1) => (
                <div key={index1} className="flex gap-3">
                  <div className="mt-[2px] max_sm:mt-0">
                    <TickMarkIcon color="#FDB600" />
                  </div>
                  <p className="subParagraphText text-LightPrimary">
                    {feature1}
                  </p>
                </div>
              ))}
            </div>
          </div>
          {/* Image */}
          <div className="relative w-[55%] max_xl:w-[50%] max_md:w-[86%] max_sm:w-[100%]">
            <img
              src="https://images.dcdial.com/dcdial.com/v2/EmpowerAgent.svg"
              alt="EmpowerAgent"
              className="w-full"
            />
            <div className="absolute left-[-150px] max_md:left-[-92px] max_sm:left-[-66px] w-[47%] top-0 -z-10 max_xl:w-[42%]">
              <StarIcon style={{ width: '100%', height: '100%' }} />
            </div>
            <div className="absolute right-[-34px] bottom-[34px] max_xl:right-[-48px] max_xl:bottom-[18px] -z-10">
              <StarGroup style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
        </div>
      </div>

      {/* FEATURE 3 */}
      <div className="w-full">
        <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] flex justify-between max_md:flex-col-reverse max_md:gap-[60px] max_sm:gap-10 items-center">
          <div className="w-[40%] pl-[50px] max_xl:w-[45%] max_xl:pl-[30px] max_md:w-[386px] max_md:pl-0 max_sm:w-[100%]">
            <h3 className="text40class text-LightPrimary">
              {t('SyncWithCRM')}
            </h3>
            <div className="mt-10 max_xl:mt-8 flex flex-col gap-5 max_sm:gap-4 max_sm:mt-5">
              {features?.feature3?.map((feature1, index1) => (
                <div key={index1} className="flex gap-3 ">
                  <div className="mt-[2px] max_sm:mt-0">
                    <TickMarkIcon color="#A9CFFF" />
                  </div>
                  <p className="subParagraphText text-LightPrimary">
                    {feature1}
                  </p>
                </div>
              ))}
            </div>
          </div>
          {/* Image */}
          <div className="relative w-[54%] max_xl:w-[55%] z-20 max_md:w-[86%] max_sm:w-[100%]">
            <img
              src="https://images.dcdial.com/dcdial.com/v2/SyncCRM.svg"
              alt="SyncCRM"
              className="w-full z-10"
            />
            <div className="absolute right-[-150px] max_md:right-[-110px] w-[58%] top-[-40px] -z-10 max_xl:w-[50%]">
              <RoundBackLG style={{ width: '100%', height: '100%' }} />
            </div>
            <div className="absolute bottom-1 left-[186px] -z-10 max_xl:left-[125px] max_xl:bottom-0 max_720:bottom-[-30px] max_720:left-[82px] max_sm:left-[30px] max_sm:bottom-[-36px]">
              <RoundGroup style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
        </div>
      </div>

      {/* FEATURE 4 */}
      <div className="w-full">
        <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] flex flex-row-reverse justify-between max_md:flex-col-reverse max_md:gap-[60px] max_sm:gap-10 items-center">
          <div className="w-[35%] pr-[50px] max_xl:w-[45%] max_xl:pr-[30px] max_md:w-[362px] max_md:pr-0 max_sm:w-[100%]">
            <h3 className="text40class text-LightPrimary">
              {t('UnleashAIPower')}
            </h3>
            <div className="mt-10 max_xl:mt-8 flex flex-col gap-5 max_sm:gap-4 max_sm:mt-5">
              {features?.feature4?.map((feature1, index1) => (
                <div key={index1} className="flex gap-3">
                  <div className="mt-[2px] max_sm:mt-0">
                    <TickMarkIcon color="#FDB600" />
                  </div>
                  <p className="subParagraphText text-LightPrimary">
                    {feature1}
                  </p>
                </div>
              ))}
            </div>
          </div>
          {/* Image */}
          <div className="relative w-[55%] max_xl:w-[53%] max_md:w-[86%] max_sm:w-[100%]">
            <img
              src="https://images.dcdial.com/dcdial.com/v2/UnleaseAIPower.svg"
              alt="UnleaseAIPower"
              className="w-full"
            />
            <div className="absolute left-[-150px] max_md:left-[-85px] w-[47%] top-0 -z-10 max_xl:w-[40%]">
              <StarIcon style={{ width: '100%', height: '100%' }} />
            </div>
            <div className="absolute right-[190px] bottom-[168px] max_xl:right-[132px] max_xl:bottom-[130px] max_md:bottom-[112px] max_md:right-[180px] max_800:right-[140px] max_720:right-[100px] max_sm:w-[22%] max_sm:bottom-7 max_sm:right-[92px] -z-10">
              <StarGroup style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
        </div>
      </div>

      {/* FEATURE 5 */}
      <div className="w-full">
        <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] flex justify-between max_md:flex-col-reverse max_md:gap-[60px] max_sm:gap-10 items-center">
          <div className="w-[40%] pl-[50px] max_xl:w-[45%] max_xl:pl-[30px] max_md:w-[385px] max_md:pl-0 max_sm:w-[100%]">
            <h3 className="text40class text-LightPrimary">
              {t('StreamlinePayments')}
            </h3>
            <div className="mt-10 max_xl:mt-8 flex flex-col gap-5 max_sm:gap-4 max_sm:mt-5">
              {features?.feature5?.map((feature1, index1) => (
                <div key={index1} className="flex gap-3 ">
                  <div className="mt-[2px] max_sm:mt-0">
                    <TickMarkIcon color="#A9CFFF" />
                  </div>
                  <p className="subParagraphText text-LightPrimary">
                    {feature1}
                  </p>
                </div>
              ))}
            </div>
          </div>
          {/* Image */}
          <div className="relative w-[56%] max_xl:w-[55%] z-20 max_md:w-[86%] max_sm:w-[100%]">
            <img
              src="https://images.dcdial.com/dcdial.com/v2/AutomateCollection.svg"
              alt="AutomateCollection"
              className="w-full z-10"
            />
            <div className="absolute right-[12px] w-[42%] top-[-24px] -z-10 max_xl:w-[38%] max_xl:right-5 max_xl:top-2">
              <RoundBackLG style={{ width: '100%', height: '100%' }} />
            </div>
            <div className="absolute bottom-[94px] left-[33px] -z-10 max_xl:left-[23px] max_xl:bottom-[65px] max_720:left-[-6px] max_sm:left-4 max_sm:bottom-14 max_sm_380:bottom-10 max_xl:w-[22%]">
              <StarGroupCross style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
        </div>
      </div>

      {/* FEATURE 6 */}
      <div className="w-full">
        <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] flex flex-row-reverse justify-between max_md:flex-col-reverse max_md:gap-[60px] max_sm:gap-10 items-center">
          <div className="w-[35%] pr-[50px] max_xl:w-[43%] max_xl:pr-[30px] max_md:w-[360px] max_md:pr-0 max_sm:w-[100%]">
            <h3 className="text40class text-LightPrimary">
              {t('DataInsightsF')}
            </h3>
            <div className="mt-10 max_xl:mt-8 flex flex-col gap-5 max_sm:gap-4 max_sm:mt-5">
              {features?.feature6?.map((feature1, index1) => (
                <div key={index1} className="flex gap-3">
                  <div className="mt-[2px] max_sm:mt-0">
                    <TickMarkIcon color="#FDB600" />
                  </div>
                  <p className="subParagraphText text-LightPrimary">
                    {feature1}
                  </p>
                </div>
              ))}
            </div>
          </div>
          {/* Image */}
          <div className="relative w-[52%] max_xl:w-[50%] max_md:w-[86%] max_sm:w-[100%]">
            <img
              src="https://images.dcdial.com/dcdial.com/v2/DataInsights.svg"
              alt="DataInsights"
              className="w-full"
            />
            <div className="absolute left-[-150px] w-[47%] top-0 -z-10 max_xl:w-[40%] max_xl:left-[-92px] max_720:left-[-64px]">
              <StarIcon style={{ width: '100%', height: '100%' }} />
            </div>
            <div className="absolute right-[-30px] bottom-[174px] max_xl:right-[-42px] max_xl:bottom-[130px] max_sm:w-[20%] max_sm:right-[-28px] max_sm:bottom-20  -z-10">
              <StarGroup style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
        </div>
      </div>

      {/* FEATURE 7 */}
      <div className="w-full">
        <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] flex justify-between max_md:flex-col-reverse max_md:gap-[60px] max_sm:gap-10 items-center">
          <div className="w-[34%] pl-[50px] max_xl:w-[39%] max_xl:pl-[30px] max_md:w-[314px] max_md:pl-0 max_sm:w-[100%]">
            <h3 className="text40class text-LightPrimary">
              {t('StayCompliant')}
            </h3>
            <div className="mt-10 max_xl:mt-8 flex flex-col gap-5 max_sm:gap-4 max_sm:mt-5">
              {features?.feature7?.map((feature1, index1) => (
                <div key={index1} className="flex gap-3 ">
                  <div className="mt-[2px] max_sm:mt-0">
                    <TickMarkIcon color="#A9CFFF" />
                  </div>
                  <p className="subParagraphText text-LightPrimary">
                    {feature1}
                  </p>
                </div>
              ))}
            </div>
          </div>
          {/* Image */}
          <div className="relative w-[54%] z-20 max_md:w-[86%] max_sm:w-[100%]">
            <img
              src="https://images.dcdial.com/dcdial.com/v2/Compliant.svg"
              alt="Compliant"
              className="w-full z-10"
            />
            <div className="absolute right-[-86px] w-[42%] top-[60px] -z-10 max_xl:w-[38%] max_xl:right-5 max_xl:top-2">
              <RoundBackLG style={{ width: '100%', height: '100%' }} />
            </div>
            <div className="absolute bottom-[50px] left-[230px] -z-10 max_xl:left-[170px] max_sm:left-[130px] max_sm_380:left-[100px] max_xl:bottom-[30px] max_xl:w-[25%] ">
              <RoundGroup style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getTranslatedList = (t, listKey) => {
  // Define your lists here
  const lists = {
    feature1: [
      t('PredictiveDialer'),
      t('PowerDialer'),
      t('PreviewManualDialer'),
      t('VoiceBroadcast'),
      t('Click-to-Dial'),
      t('CampaignManagement'),
      t('Two-wayTextMessaging'),
      t('SMSBroadcastCampaigns'),
    ],
    feature2: [
      t('InboundCallRouting'),
      t('CallRecording'),
      t('InteractiveVoiceC'),
      t('CallQueuing'),
      t('SmartRoutingC'),
      t('CallMonitoring'),
      t('CallWhisper'),
      t('Cloud-based'),
    ],
    feature3: [
      t('SeamlessIntegration'),
      t('APIIntegration'),
      t('ContactManagement'),
      t('UserManagement'),
      t('CRMDataSyncingC'),
      t('CustomizableCRMFieldsC'),
    ],
    feature4: [
      t('WorkflowAutomation'),
      t('DynamicScripting'),
      t('RealTimeAssistance'),
      t('AutomatedCall'),
      t('CustomerSelfService'),
      t('VirtualAgents'),
    ],
    feature5: [
      t('PaymentProcessingIntegration'),
      t('PaymentPlanManagement'),
      t('AutomatedPaymentReminders'),
      t('BudgetCalculatorC'),
      t('PCICompliance'),
      t('BillingAutomation'),
    ],
    feature6: [
      t('CustomizableReporting'),
      t('RealTimeDashboards'),
      t('CampaignReports'),
      t('AgentPerformanceMetrics'),
      t('PerformanceTrend'),
      t('CustomerPaymentReports'),
      t('BillingReports'),
    ],
    feature7: [
      t('TCPACompliance'),
      t('SecureDataHandling'),
      t('Role-BasedAccess'),
      t('Do-Not-Call'),
      t('CallTimeRestrictions'),
      t('OptOutMechanisms'),
      t('CallMonitoringReporting'),
    ],
  };

  return lists[listKey]?.map(t) || [];
};

export default AllFeatures;
