import React from 'react'
import Skeleton from 'react-loading-skeleton'

const SkeletonCard = ({ fullWidth }) => {
  return (
    <div
      className={`relative border-[1px] rounded-[16px] p-6 ${
        fullWidth
          ? 'w-[285px] max_sm:w-[280px] max_sm_380:w-[260px]'
          : 'w-[240px] max_1440:w-[250px]'
      } border-LightWhite `}
    >
      <Skeleton height={40} width={30} className="" baseColor="#EEF6FF" />
      <Skeleton height={25} width={80} className="mt-5" baseColor="#EEF6FF" />
      <Skeleton height={35} width={110} className="mt-2" baseColor="#EEF6FF" />
      <Skeleton
        height={30}
        width={150}
        className="mt-[30px]"
        baseColor="#EEF6FF"
      />
      <Skeleton
        height={30}
        width={150}
        className="mt-2"
        baseColor="#EEF6FF"
      />
      <Skeleton
        height={40}
        width={170}
        className="mt-4"
        baseColor="#EEF6FF"
      />
      <Skeleton
        height={25}
        width={130}
        className="mt-6"
        baseColor="#EEF6FF"
      />
      <Skeleton
        height={25}
        width={130}
        className="mt-2"
        baseColor="#EEF6FF"
      />
      <Skeleton
        height={25}
        width={130}
        className="mt-2"
        baseColor="#EEF6FF"
      />
    </div>
  );
};

export default SkeletonCard