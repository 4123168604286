import React, { useEffect } from 'react';
import LegalSideLayout from '../../Components/LegalSideLayout';
import Layout from '../../Components/Layout';
import DPAContent from '../../Components/Legal/DPAContent';
import TitleAndDesc from '../../Components/Helmet/TitleAndDesc';
import { useLocation } from 'react-router-dom';

const DataProcessing = () => {
  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    if (state && state !== null) {
      window.scrollTo(0, 0);
    }
  }, [state]);

  var scrollPosition = localStorage.getItem('scrollPositionDPA');
  if (scrollPosition !== null) {
    window.scrollTo(0, parseInt(scrollPosition));
  }
  return (
    <>
      <TitleAndDesc
        title="Data Processing Agreement"
        description="Read DCDial's Data Processing Agreement to understand how we process and protect your personal data, ensuring compliance with privacy laws."
      />
      <Layout>
        <LegalSideLayout>
          <DPAContent />
        </LegalSideLayout>
      </Layout>
    </>
  );
};

export default DataProcessing;
