import React, { useState } from 'react';
import {
  ACHLogo,
  AuthorizLogo,
  PayPalLogo,
  RepayLogo,
  StripeLogo,
  USAPayLogo,
} from '../../assets/Icons/Payments/paymentMethodIcon';
import { useTranslation } from 'react-i18next';
import { ButtonFilled } from '../utils/Button';
import TagsComp from '../utils/TagsComp';
import DigitalWallets from './DigitalWallets';
import { useNavigate } from 'react-router-dom';
import AnimateSection from './AnimateSection';

const PaymentMethod = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [paymentMethodLogo] = useState({
    list1: [
      {
        icon: <RepayLogo />,
      },
      {
        icon: <StripeLogo />,
      },
      {
        icon: <PayPalLogo />,
      },
    ],
    list2: [
      {
        icon: <ACHLogo />,
      },
      {
        icon: <USAPayLogo />,
      },
      {
        icon: <AuthorizLogo />,
      },
    ],
  });
  return (
    <div className="mt-[100px] max_md:mt-[60px]">
      {/* LOGOS */}
      <div className="flex justify-between w-full max_md:hidden">
        {paymentMethodLogo.list1.map((logo, index1) => (
          <div className={`w-[220px]`} key={index1}>
            {logo.icon}
          </div>
        ))}
      </div>
      <div className="mt-20 flex justify-between w-full max_md:hidden">
        {paymentMethodLogo?.list2.map((logo, index1) => (
          <div className={`w-max`} key={index1}>
            {logo.icon}
          </div>
        ))}
      </div>
      {/* TABLATE DESIGN */}
      <div className="hidden max_md:block">
        <div className="mt-20 max_600:mt-10 flex w-[530px] max_600:w-[260px] max_600:gap-10 max_600:justify-center m-auto gap-20 flex-wrap">
          {paymentMethodLogo?.list1
            .concat(paymentMethodLogo?.list2)
            .map((logo, index1) => (
              <div className={`flex`} key={index1}>
                {logo.icon}
              </div>
            ))}
        </div>
      </div>

      <div className="mt-[150px] max_xl:mt-[100px] max_sm:mt-[60px] flex flex-col gap-6">
        <TagsComp
          px="16px"
          py="8px"
          text={t('PaymentMethods')}
          color="#00A0FC"
          borderColor="#00A0FC"
        />
        <p className="text50">{t('FlexiblePaymentOptions')}</p>
        <div className="flex items-center justify-between max_md:flex-col max_md:items-start max_md:gap-5">
          <p className="paragraphText max-w-[800px] max_xl:max-w-[660px] max_md:max-w-full">
            {t('PaymentOptionsText')}
          </p>
          <a
            href="https://calendly.com/dcdial-sales/30min"
            rel="noopener noreferrer"
            target="_blank"
          >
            <ButtonFilled
              title={t('LetsConnect')}
              // onClick={() => navigate('/contact/get-in-touch')}
            />
          </a>
        </div>
      </div>

      <div>
        {/* ANIMATED LOGOS */}
        <AnimateSection />
        <DigitalWallets />
      </div>
    </div>
  );
};

export default PaymentMethod;
