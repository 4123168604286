import React, { useEffect, useRef, useState } from 'react';
import {
  BankIcon,
  BankIconSM,
  CardPayIcon,
  CardPayIconSM,
  DollarIcon,
  DollarIconSM,
  LineAnim,
  LineAnimRes,
  LineAnimTab,
  PayPalIcon,
  PayPalIconSM,
  WireTransferIcon,
  WireTransferIconSM,
} from '../../assets/Icons/Payments/paymentMethodIcon';
import { useWindowSize } from '../../Hooks/useWindowSize';
import useVisibility from '../../Hooks/useVisibility';

const AnimateSection = () => {
  const paymentRefToStartAnim = useRef();
  const AnimIsInView = useVisibility(paymentRefToStartAnim, 0.7);
  const [isStartAnim, setIsStartAnim] = useState(false);
  const intervals = [200, 1000, 1200, 1400, 3200];
  const { width: windowWidth } = useWindowSize();
  const isSmallDesk = windowWidth <= 1300;
  // const [activeShadow, setActiveShadow] = useState(0);
  const [count, setCount] = useState(1);
  const [visitedDiv] = useState([]);
  useEffect(() => {
    if (AnimIsInView) {
      setIsStartAnim(true);
    }
  }, [AnimIsInView]);
  useEffect(() => {
    if (isStartAnim) {
      if (count < 6) {
        // Only set interval if count is less than 5
        const interval = setInterval(() => {
          // setActiveShadow((prevActiveShadow) => (prevActiveShadow + 1) % 5);
          visitedDiv.push(count);
          setCount((prevCount) => prevCount + 1);
        }, intervals[count - 1]);

        return () => clearInterval(interval);
      }
    }
  }, [count, isStartAnim]);

  return (
    <div className="flex mt-24 justify-center max_720:mt-[60px] max_sm:mt-[50px]" ref={paymentRefToStartAnim}>
      <div className="relative max_md:w-[100%] max_720:hidden">
        <div className="max_md:hidden">
          <LineAnim isAnimating={isStartAnim} width="100%" />
        </div>
        <div className="hidden max_md:block">
          <LineAnimRes isAnimating={isStartAnim} style={{ width: '100%' }} />
        </div>
        <div
          className={`absolute border-[1px] left-[-88px] top-[-26px] p-3 max_xl:p-2 max_xl:left-[-12px] rounded-[20px] z-20 bg-white ${
            visitedDiv.includes(1)
              ? 'shadow-animate  border-white'
              : 'border-[1px] border-LightOutline'
          }`}
        >
          {isSmallDesk ? (
            <CardPayIconSM
              color={visitedDiv.includes(1) ? '#00A0FC' : '#DFE4EC'}
            />
          ) : (
            <CardPayIcon
              color={visitedDiv.includes(1) ? '#00A0FC' : '#DFE4EC'}
            />
          )}
        </div>
        <div
          className={`absolute border-[1px] left-[180px] max_xl:left-[188px] top-[60px] p-4 max_xl:p-3 rounded-[20px] max_md:left-[19%] z-20 bg-white ${
            visitedDiv.includes(2)
              ? 'shadow-animate border-white '
              : 'border-[1px] border-LightOutline'
          }`}
        >
          {isSmallDesk ? (
            <BankIconSM
              color={visitedDiv.includes(2) ? '#00A0FC' : '#DFE4EC'}
            />
          ) : (
            <BankIcon color={visitedDiv.includes(2) ? '#00A0FC' : '#DFE4EC'} />
          )}
        </div>
        <div
          className={`absolute border-[1px] left-[440px] max_md:left-[43%] top-3 p-3 max_xl:p-2 rounded-[20px] z-20 bg-white ${
            visitedDiv.includes(3)
              ? 'shadow-animate border-white '
              : 'border-[1px] border-LightOutline'
          }`}
        >
          {isSmallDesk ? (
            <PayPalIconSM
              color={visitedDiv.includes(3) ? '#00A0FC' : '#DFE4EC'}
            />
          ) : (
            <PayPalIcon
              color={visitedDiv.includes(3) ? '#00A0FC' : '#DFE4EC'}
            />
          )}
        </div>
        <div
          className={`absolute border-[1px] right-[180px] max_xl:right-[187px] max_md:right-[19%] top-[-35px] max_md:top-[-26px] p-4 max_xl:p-3 rounded-[20px] z-20 bg-white ${
            visitedDiv.includes(4)
              ? 'shadow-animate border-white '
              : 'border-[1px] border-LightOutline'
          }`}
        >
          {isSmallDesk ? (
            <WireTransferIconSM
              color={visitedDiv.includes(4) ? '#00A0FC' : '#DFE4EC'}
            />
          ) : (
            <WireTransferIcon
              color={visitedDiv.includes(4) ? '#00A0FC' : '#DFE4EC'}
            />
          )}
        </div>
        <div
          className={`absolute border-[1px] right-[-82px] max_xl:right-[-12px] top-10 p-3 max_xl:p-2 rounded-[20px] z-20 bg-white ${
            visitedDiv.includes(5)
              ? 'shadow-animate border-white '
              : 'border-[1px] border-LightOutline'
          }`}
        >
          {isSmallDesk ? (
            <DollarIconSM
              color={visitedDiv.includes(5) ? '#00A0FC' : '#DFE4EC'}
            />
          ) : (
            <DollarIcon
              color={visitedDiv.includes(5) ? '#00A0FC' : '#DFE4EC'}
            />
          )}
        </div>
      </div>

      <div className="hidden max_720:block">
        <div className="relative">
          <LineAnimTab isAnimating={isStartAnim} />

          <div
            className={`absolute border-[1px] left-[-10px] top-[-10px] p-2 rounded-[20px] z-20 bg-white ${
              visitedDiv.includes(1)
                ? 'shadow-animate  border-white'
                : 'border-[1px] border-LightOutline'
            }`}
          >
            <CardPayIconSM
              color={visitedDiv.includes(1) ? '#00A0FC' : '#DFE4EC'}
            />
          </div>

          <div
            className={`absolute border-[1px] right-[-34px] top-[94px] p-4 max_esm:p-3 max_esm:top-[98px] max_esm:right-[-25px] rounded-[20px] z-20 bg-white ${
              visitedDiv.includes(2)
                ? 'shadow-animate border-white '
                : 'border-[1px] border-LightOutline'
            }`}
          >
            <BankIcon color={visitedDiv.includes(2) ? '#00A0FC' : '#DFE4EC'} />
          </div>

          <div
            className={`absolute border-[1px] top-[244px] left-[-16px] p-3 max_xl:p-2 rounded-[20px] z-20 bg-white ${
              visitedDiv.includes(3)
                ? 'shadow-animate border-white '
                : 'border-[1px] border-LightOutline'
            }`}
          >
            <PayPalIconSM
              color={visitedDiv.includes(3) ? '#00A0FC' : '#DFE4EC'}
            />
          </div>

          <div
            className={`absolute border-[1px] right-2 top-[406px] p-2 rounded-[20px] z-20 bg-white ${
              visitedDiv.includes(4)
                ? 'shadow-animate border-white '
                : 'border-[1px] border-LightOutline'
            }`}
          >
            <WireTransferIcon
              color={visitedDiv.includes(4) ? '#00A0FC' : '#DFE4EC'}
            />
          </div>

          <div
            className={`absolute border-[1px] bottom-[-26px] left-[-25px] p-2 rounded-[20px] z-20 bg-white ${
              visitedDiv.includes(5)
                ? 'shadow-animate border-white '
                : 'border-[1px] border-LightOutline'
            }`}
          >
            <DollarIconSM
              color={visitedDiv.includes(5) ? '#00A0FC' : '#DFE4EC'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnimateSection;

{
  /* <div className="relative">
        <LineAnim />
        <div className="absolute left-[-88px] top-[-26px] p-3 rounded-[20px] z-20 bg-white shadow-[0px_8px_60px_0px_#00A0FC1A]">
          <CardPayIcon color="#00A0FC" />
        </div>
        <div className="absolute left-[180px] top-[60px] p-4 rounded-[20px] z-20 bg-white shadow-[0px_8px_60px_0px_#00A0FC1A]">
          <BankIcon color="#00A0FC" />
        </div>
        <div className="absolute left-[440px] top-3 p-3 rounded-[20px] z-20 bg-white shadow-[0px_8px_60px_0px_#00A0FC1A]">
          <PayPalIcon color="#00A0FC" />
        </div>
        <div className="absolute right-[180px] top-[-35px] p-4 rounded-[20px] z-20 bg-white shadow-[0px_8px_60px_0px_#00A0FC1A]">
          <WireTransferIcon color="#00A0FC" />
        </div>
        <div className="absolute right-[-82px] top-10 p-3 rounded-[20px] z-20 bg-white shadow-[0px_8px_60px_0px_#00A0FC1A]">
          <DollarIcon color="#00A0FC" />
        </div>
      </div> */
}
