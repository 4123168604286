import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import CommonSection from '../CommonSection'
import BannerAnimation from '../../animation/en/AboutUs/AboutAnimation.json'
import Lottie from 'react-lottie';
import CountUp from 'react-countup';
import useVisibility from '../../Hooks/useVisibility';

const HeroSection = () => {
  const { t } = useTranslation();
  const counterRef = useRef()
  const isInView = useVisibility(counterRef, 0.5)
  const [startAnim,setStartAnim] = useState(false)
  useEffect(() => {
    if (!startAnim && isInView) {
      setStartAnim(true);
    }
  },[isInView])
  const [infoList] = useState([
    {
      value: '12',
      title: '+ Years',
      text: 'on the market',
      width: true,
    },
    {
      value: '96',
      title: '%',
      text: 'customer satisfaction and loyalty ',
    },
    {
      value: '7',
      title: '+ Years',
      text: 'our employees stay at DCDial on average',
    },
    {
      value: '99',
      title: '%',
      text: 'uptime guarantee',
      width: true,
    },
  ]);
  const aboutBannerAnimation = {
    loop: false,
    autoplay: true,
    animationData: BannerAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };


  return (
    <CommonSection>
      <h1 className="headingText text-center">{t('DCDialJourney')}</h1>
      <p className="paragraphText text-center mt-10 max_sm:mt-5">
        {t('AboutUsText')}
      </p>
      {/* <PuzzlePiece /> */}
      <div className="mt-[40px] max_xl:mt-30 max_md:mt-5 max_sm:mt-3">
        {/* <img src={BannerImg} alt="BannerImg" /> */}
        <Lottie options={aboutBannerAnimation} />
      </div>
      <div className="mt-[100px] max_md:mt-[60px] max_sm:mt-10 flex justify-between max_md:flex-wrap max_md:gap-y-[100px] max_sm:gap-y-[30px]" ref={counterRef}>
        {infoList.map((info, index) => {
          return (
            <React.Fragment key={index + 1}>
              <div className="w-[230px] flex gap-4 max_md:w-[48%] max_sm:w-[100%]">
                <div className="w-[4px] bg-ButtonPrimary h-full rounded"></div>
                <div className="w-[calc(100%-20px)]">
                  <p className="text-text2xl font-DMSansFont font-bold text-LightPrimary">
                    <CountUp
                      end={info.value}
                      startOnMount={false}
                      start={startAnim}
                    />
                    {info.title}
                  </p>
                  <p
                    className={`text-headingNormal font-DMSansFont text-LightTextParagraph font-normal mt-[6px] max_xl:mt-1 ${
                      info.width
                        ? 'max-w-[90px]'
                        : 'max_md:w-[204px] max_sm:w-[210px]'
                    }`}
                  >
                    {info.text}
                  </p>
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </CommonSection>
  );
}

export default HeroSection