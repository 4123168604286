import React from 'react'

const Definition = ({ count, title, data, handleOnClick, defText ,id}) => {
  return (
    <div className="mt-[30px] flex flex-col gap-4" id={id}>
      <p className="text22">
        {count}. {title}
      </p>
      {data.map((defs, index2) => (
        <div key={index2} className="flex gap-3">
          <p className="w-[6px] h-[6px] bg-LightSecondary rounded-full mt-[6px]"></p>
          <p
            className="smallText16Normal w-[calc(100%-18px)]"
            dangerouslySetInnerHTML={{ __html: defs }}
            onClick={handleOnClick}
          ></p>
        </div>
      ))}
      {defText && <p className="smallText16Normal">{defText}</p>}
    </div>
  );
};

export default Definition