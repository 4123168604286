import React, { useEffect } from 'react'
import Layout from '../../Components/Layout';
import LegalSideLayout from '../../Components/LegalSideLayout';
import CookiePolicyContent from '../../Components/Legal/CookiePolicyContent';
import TitleAndDesc from '../../Components/Helmet/TitleAndDesc';
import { useLocation } from 'react-router-dom';

const CookiePolicy = () => {
    const location = useLocation();
    const state = location.state;
    useEffect(() => {
      if (state && state !== null) {
        window.scrollTo(0, 0);
      }
    }, [state]);

    var scrollPosition = localStorage.getItem('scrollPositionCP');
    if (scrollPosition !== null) {
      window.scrollTo(0, parseInt(scrollPosition));
    }
  return (
    <>
      <TitleAndDesc
        title="Cookie Policy"
        description="Read DCDial's Cookie Policy to understand how we use cookies on our website to enhance your browsing experience and track usage for analytics."
      />
      <Layout>
        <LegalSideLayout>
          <CookiePolicyContent />
        </LegalSideLayout>
      </Layout>
    </>
  );
}

export default CookiePolicy