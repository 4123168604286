import React, { useEffect, useState } from 'react';
import TagsComp from '../utils/TagsComp';
import { useTranslation } from 'react-i18next';
import {
  AmazonPayLogo,
  AppleLogo,
  GpayLogo,
  PayPAlLogo,
  VenmoLogo,
} from '../../assets/Icons/Payments/paymentMethodIcon';

const DigitalWallets = () => {
  const { t } = useTranslation();
  const [digitalWalletLogo, setDigitalWalletLogo] = useState([
    <AppleLogo />,
    <GpayLogo />,
    <VenmoLogo />,
    <PayPAlLogo />,
    <AmazonPayLogo />,
  ]);
  const [digitalFeature, setDigitalFeature] = useState([])
  useEffect(() => {
    setDigitalFeature(getTranslatedList(t))
  },[])

  return (
    <div className="mt-[180px] max_xl:mt-[100px] max_sm:mt-[60px] flex justify-between max_md:flex-col max_md:gap-[60px] max_sm:gap-10">
      <div className="flex flex-col gap-6 max-w-[460px] max_xl:max-w-[400px] max_md:max-w-full">
        <TagsComp
          text={t('DigitalWallets')}
          color="#00A0FC"
          borderColor="#00A0FC"
        />
        <p className="text50Product max_md:mt-[6px]">{t('DigitalWalletsText')}</p>
        <div className="flex flex-wrap gap-10 max_sm:gap-[30px] items-center mt-4">
          {digitalWalletLogo.map((logo, index) => (
            <React.Fragment key={index}>
              <div>{logo}</div>
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="flex flex-wrap gap-[60px] justify-end max_xl:gap-10 max_md:justify-between max_md:gap-y-5 max_md:gap-x-2 max_sm:gap-10">
        {digitalFeature.map((feature, index) => (
          <div
            className="w-[250px] max_xl:w-[240px] max_md:w-[45%] max_sm:w-full"
            key={index}
          >
            <div className="flex items-center gap-2">
              <div className="w-[4px] h-[46px] max_sm:h-[28px] max_md:h-[34px] rounded bg-LightPrimary"></div>
              <p className="text-text2xl font-DMSansFont font-bold text-LightPrimary max_md:text-subHeadingTxt max_sm:text-headingNormal">
                {feature.title}
              </p>
            </div>

            <p className="subParagraphText font-normal mt-3">{feature.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
const getTranslatedList = (t) => {
  // Define your lists here
  const featureList = [
    {
      title: t('Convenience'),
      text: t('ConvenienceText'),
    },
    {
      title: t('Innovation'),
      text: t('InnovationTextWallet'),
    },
    {
      title: t('SecurityT'),
      text: t('SecurityTextWallet'),
    },
    {
      title: t('Efficiency'),
      text: t('EfficiencyText'),
    },
  ];

  return featureList;
};

export default DigitalWallets;
