import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LogoEn from '../assets/images/Footer/DCDialLogoEn.svg';
import AppleImg from '../assets/images/Footer/Apple1.svg';
import PlaystoreImg from '../assets/images/Footer/PlayStore.svg';
import ChromeImg from '../assets/images/Footer/InstallChrom.svg';
import {
  FacbookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
  YoutubeIcon,
} from '../assets/Icons/icons';
import { useWindowSize } from '../Hooks/useWindowSize';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  CloseCircleIcon,
  TickMarkGreenIcon,
} from '../assets/Icons/Security/SecurityIcon';
// import {
//   GoogleReCaptcha,
//   GoogleReCaptchaProvider,
// } from 'react-google-recaptcha-v3';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 500;
  const [hoverIndex, setHoverIndex] = useState(null);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isExists, setIsExits] = useState(false);

  const [socialIcons] = useState([
    {
      icon: <TwitterIcon />,
      link: 'https://twitter.com/dcdialapp',
    },
    {
      icon: <FacbookIcon />,
      link: 'https://www.facebook.com/dcdialapp',
    },
    {
      icon: <LinkedinIcon />,
      link: 'https://www.linkedin.com/company/dcdialapp',
    },
    {
      icon: <InstagramIcon />,
      link: 'https://www.instagram.com/dcdialapp/',
    },
    {
      icon: <YoutubeIcon />,
      link: 'https://www.youtube.com/channel/UCc6nP07WWreh--Ees7merAw',
    },
  ]);
  // const [token, setToken] = useState('');
  // const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  // const setTokenFunc = (getToken) => {
  //   setToken(getToken);
  // };

  const handleClick = (to) => {
    navigate(to);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 50);
  };

  const sendEmail = async () => {
    const data = {
      type: 'send_general_email',
      email_type: 'thankyou_newsletter',
      email: email,
      api_key: '71a629e3685846a9173884a03f8f559a4d3c6510',
    };

    axios
      .post(process.env.REACT_APP_BASE_URL, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setIsSuccess(true);
          setIsLoading(false);
          setEmail('');
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err.response &&
          err.response.data &&
          err.response.data.status === 400
        ) {
          setIsExits(true);
          setIsError(true);
        }
      });
  };

  const handleSubscribe = () => {
    setIsExits(false);
    setIsSuccess(false);
    if (email.trim() === '') {
      setIsError(true);
    } else {
      const IsValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
      if (IsValidEmail) {
        setIsLoading(true);
        sendEmail();
        // const data = { email: email };
        // axios
        //   .post(`${BASE_URL}/email/create-email`, data)
        //   .then((response) => {
        //     if (response.status === 200 || response.status === 201) {
        //       setIsSuccess(true)
        //       setIsLoading(false);
        //       setEmail('');
        //     }
        //   })
        //   .catch((err) => {
        //     setIsLoading(false);
        //     if (err.response.data.status === 400) {
        //       setIsExits(true);
        //       setIsError(true)
        //     }
        //   });
      } else {
        setIsError(true);
      }
    }
  };

  return (
    <>
      <div
        className={`m-auto max-w-[1240px] pt-[80px] pb-[40px] max_xl:max-w-[980px] flex justify-between gap-[200px] max_xl:gap-[100px] max_md:gap-[30px]  max_md:flex-col-reverse max_md:px-10 max_sm:px-5 ${
          pathname !== '/contact/get-in-touch'
            ? 'mt-[150px] max_xl:mt-[100px] max_md:mt-[60px] max_sm:mt-10'
            : ''
        }`}
      >
        <div className="flex gap-x-[150px] gap-y-[40px] flex-wrap max_xl:gap-x-[100px] max_800:gap-x-16 max_720:gap-x-12 max_sm:gap-x-5 max_sm_380:gap-x-4 max_sm:gap-y-[30px] max_350:gap-y-5">
          <div className="min-w-[150px] max_sm_380:min-w-[140px] max_350:min-w-[160px]">
            <p className="subHeadingText">{t('Products')}</p>
            <p
              className="cursor-pointer w-fit hover:text-LightPrimary smallText16 mt-4"
              onClick={() => handleClick('/products/crm')}
            >
              {t('CRM')}
            </p>
            <p
              className="cursor-pointer w-fit hover:text-LightPrimary smallText16 mt-2"
              onClick={() => handleClick('/products/payments')}
            >
              {t('Payments')}
            </p>
            <p
              className="cursor-pointer w-fit hover:text-LightPrimary smallText16 mt-2"
              onClick={() => handleClick('/products/ai-assistant')}
            >
              {t('AIAssistant')}
            </p>
            <p
              className="cursor-pointer w-fit hover:text-LightPrimary smallText16 mt-2"
              onClick={() => handleClick('/products/communication')}
            >
              {t('Communication')}
            </p>
          </div>
          <div className="min-w-[150px] max_sm_380:min-w-[140px] max_350:min-w-[160px]">
            <p className="subHeadingText">{t('Resources')}</p>
            <p className="cursor-pointer w-fit hover:text-LightPrimary smallText16 mt-4">
              <a
                href="https://support.dcdial.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('KnowledgeCenter')}
              </a>
            </p>
            <p className="cursor-pointer w-fit hover:text-LightPrimary smallText16 mt-2">
              {t('Blog')}
            </p>
            <p
              className="cursor-pointer w-fit hover:text-LightPrimary smallText16 mt-2"
              onClick={() => handleClick('/faq')}
            >
              {t('FAQ')}
            </p>
            <p className="cursor-pointer w-fit hover:text-LightPrimary smallText16 mt-2">
              <a
                href="https://apidocs.dcdial.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('API')}
              </a>
            </p>
            <p
              className="cursor-pointer w-fit hover:text-LightPrimary smallText16 mt-2"
              onClick={() => handleClick('/apps-payments')}
            >
              {t('Integration')}
            </p>
          </div>
          <div className="min-w-[150px] max_sm_380:min-w-[140px] max_350:min-w-[160px]">
            <p className="subHeadingText">{t('Contact')}</p>
            <p
              className="cursor-pointer w-fit hover:text-LightPrimary smallText16 mt-4"
              onClick={() => handleClick('/contact/get-in-touch')}
            >
              {t('GetInTouch')}
            </p>
            <p className="cursor-pointer w-fit hover:text-LightPrimary smallText16 mt-2">
              (888) 878-1011
            </p>
            <p className="cursor-pointer w-fit hover:text-LightPrimary smallText16 mt-2">
              <span>sales</span>
              <span>@</span>
              <span>dcdial.</span>
              <span>com</span>
            </p>
          </div>
          <div className="min-w-[150px] max_sm_380:min-w-[140px] max_350:min-w-[160px]">
            <p
              className="subHeadingText cursor-pointer w-fit"
              onClick={() => handleClick('/solutions')}
            >
              {t('Solutions')}
            </p>
            <p
              className="subHeadingText mt-5 cursor-pointer w-fit"
              onClick={() => handleClick('/features')}
            >
              {t('Features')}
            </p>
            <p
              className="subHeadingText mt-5 cursor-pointer w-fit"
              onClick={() => handleClick('/pricing')}
            >
              {t('Pricing')}
            </p>
          </div>
          <div className="min-w-[150px] max_sm_380:min-w-[140px] max_350:min-w-[160px]">
            <p className="subHeadingText">{t('Company')}</p>
            <p
              className="cursor-pointer w-fit hover:text-LightPrimary smallText16 mt-4"
              onClick={() => handleClick('/about-us')}
            >
              {t('AboutDCDial')}
            </p>
            <p
              className="cursor-pointer w-fit hover:text-LightPrimary smallText16 mt-2"
              onClick={() => handleClick('/legal')}
            >
              {t('Legal')}
            </p>
            <p
              className="cursor-pointer w-fit hover:text-LightPrimary smallText16 mt-2"
              onClick={() => handleClick('/legal/privacy-policy')}
            >
              {t('PrivacyPolicy')}
            </p>
            <p
              className="cursor-pointer w-fit hover:text-LightPrimary smallText16 mt-2"
              onClick={() => navigate('/security')}
            >
              {t('SecurityT')}
            </p>
          </div>
          <div className="min-w-[150px] max_sm_380:min-w-[140px] max_350:min-w-[160px]">
            <p className="subHeadingText">{t('Support')}</p>
            <p className="cursor-pointer w-fit hover:text-LightPrimary smallText16 mt-4">
              <a
                href="https://status.dcdial.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('Status')}
              </a>
            </p>
            <p className="cursor-pointer w-fit hover:text-LightPrimary smallText16 mt-2">
              {t('ReleaseUpdates')}
            </p>
            <p className="cursor-pointer w-fit hover:text-LightPrimary smallText16 mt-2">
              (888) 640-2260
            </p>
            <p className="cursor-pointer w-fit hover:text-LightPrimary smallText16 mt-2">
              <span>support</span>
              <span>@</span>
              <span>dcdial.</span>
              <span>com</span>
            </p>
          </div>
          <div className="w-[100%] max_xl:w-[93%] max_md:w-[80%] max_sm:w-[100%]">
            <div className="flex">
              <input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setIsError(false);
                }}
                type="text"
                placeholder="Enter your email address"
                className={`w-[calc(100%-190px)] max_md:w-[calc(100%-130px)] max_sm:w-[calc(100%-92px)] py-[15px] px-[22px] outline-none border-[1px] rounded-tl-[10px] rounded-bl-[10px] text-textSmall font-DMSansFont font-medium text-LightPrimary ${
                  isError ? 'border-[#ff0000]' : 'border-LightOutline'
                }`}
              />
              <div
                className={`F-JC-AI-CENTER w-[190px] max_md:w-[130px] max_sm:w-[92px] bg-ButtonPrimary hover:bg-ButtonPrimaryHover rounded-tr-[10px] rounded-br-[10px] text-textMedium max_sm:text-textSmall font-DMSansFont font-medium text-white ${
                  isLoading ? 'cursor-no-drop' : 'cursor-pointer'
                }`}
                onClick={() => {
                  if (!isLoading) {
                    handleSubscribe();
                  }
                }}
              >
                {isLoading ? (
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      class="inline w-8 h-8 text-gray-200 animate-spinner dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#ffffff"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#008CDD"
                      />
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  t('Subscribe')
                )}
              </div>
              {/* <div>
                <GoogleReCaptchaProvider reCaptchaKey="6LcnAb4pAAAAANMQznBJBEwt-ZQiikMgolCrpb4u">
                  <GoogleReCaptcha
                    className="google-recaptcha-custom-class"
                    onVerify={setTokenFunc}
                    refreshReCaptcha={refreshReCaptcha}
                  />
                </GoogleReCaptchaProvider>
              </div> */}
            </div>
            {isExists && (
              <div className="mt-1 flex gap-2 pl-[2px]">
                <div className="">
                  <CloseCircleIcon style={{ width: 20, height: 20 }} />
                </div>
                <p className="smallText16 text-[#ff0000] max_sm:text-[14px]">
                  This email is already subscribed. Thanks for staying
                  connected!
                </p>
              </div>
            )}
            {isSuccess && (
              <div className="mt-1 flex gap-2 pl-[2px]">
                <div className="">
                  <TickMarkGreenIcon style={{ width: 20, height: 20 }} />
                </div>
                <p className="smallText16 text-GreenSucces max_sm:text-[14px]">
                  Thanks for joining our newsletter!
                </p>
              </div>
            )}
          </div>
        </div>
        {/* RIGHT SIDE */}
        <div className="flex flex-col max_md:flex-row max_md:justify-between">
          <div>
            <div
              className="max_sm:w-[110px] cursor-pointer"
              onClick={() => handleClick('/')}
            >
              <img src={LogoEn} alt="Logo" className="max_sm:w-full" />
            </div>
            <div className="flex gap-[6px] mt-[30px] max_sm:mt-5 max_esm:gap-[2px]">
              {socialIcons.map((iconList, index) => {
                return (
                  <React.Fragment key={index + 1}>
                    <div
                      onMouseEnter={() => setHoverIndex(index)}
                      onMouseLeave={() => setHoverIndex(null)}
                      className="cursor-pointer"
                    >
                      <a
                        href={iconList.link}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {React.cloneElement(iconList.icon, {
                          color: index === hoverIndex ? '#19224C' : '#6D77A0',
                          width: isMobileView ? '24px' : '30px',
                          height: isMobileView ? '24px' : '30px',
                        })}
                      </a>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          <div className="mt-[100px] max_md:mt-0">
            <div className="flex flex-col gap-2">
              <div className="cursor-pointer max_sm:w-[120px]">
                <a
                  href="https://apps.apple.com/us/app/dcdial-auto-dialer-sms/id1455469901"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src={AppleImg} alt="apple" className="max_sm:w-full" />
                </a>
              </div>
              <div className="cursor-pointer max_sm:w-[120px]">
                <a
                  href="https://play.google.com/store/apps/details?id=com.palmlabs.dcdial"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    src={PlaystoreImg}
                    alt="apple"
                    className="max_sm:w-full"
                  />
                </a>
              </div>
              <div className="cursor-pointer max_sm:w-[120px]">
                <img src={ChromeImg} alt="apple" className="max_sm:w-full" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#f5f7f9] py-5 max_md:py-3 F-JC-AI-CENTER border-LightOutline border-t-[1px]">
        <p className="smallText16 text-LightPrimary leading-[20px]max_sm:text-textExtraSmall">
          © {currentYear} Palm Labs LLC. All Rights Reserved.
        </p>
      </div>
    </>
  );
};

export default Footer;
