import React, { useEffect, useState } from 'react';
import Layout from '../Components/Layout';
import { useTranslation } from 'react-i18next';
import CommonSection from '../Components/CommonSection';
import ToggleMethod from '../Components/IntegrationAndPayments/ToggleMethod';
import HeadingComp from '../Components/IntegrationAndPayments/HeadingComp';
import ConnectedApps from '../Components/IntegrationAndPayments/ConnectedApps';
import PaymentMethod from '../Components/IntegrationAndPayments/PaymentMethod';
import { useWindowSize } from '../Hooks/useWindowSize';
import TitleAndDesc from '../Components/Helmet/TitleAndDesc';

const IntegrationAndPayment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();
   const { width: windowWidth } = useWindowSize();
   const isTabletView = windowWidth <= 720;
   const isMobileView = windowWidth <= 500;

  const [produtsType, setProductType] = useState([]);
  const [selectedValue, setSelectedValue] = useState(produtsType[0]);
 
  useEffect(() => {
    setProductType(getTranslatedList(t));
  }, [t]);
  useEffect(() => {
    if (produtsType) {
      setSelectedValue(produtsType[0]);
    }
  }, [produtsType]);
 
  return (
    <>
      <TitleAndDesc
        title="Integrations & Payment Gateways | DCDial"
        description="Explore DCDial's seamless integrations with top CRM platforms and payment gateways. Simplify your workflows, enhance efficiency, and ensure secure transactions."
      />
      <Layout>
        <CommonSection>
          <ToggleMethod
            selected={selectedValue}
            setSelected={setSelectedValue}
            options={produtsType}
            width={isMobileView ? '150px' : isTabletView ? '220px' : '260px'}
            // handleClickScroll={handleClickScroll}
          />
          <HeadingComp
            title={
              selectedValue?.name === t('SoftwareIntegrations')
                ? t('IntegrationsApps')
                : t('PaymentGateways')
            }
            text={
              selectedValue?.name === t('SoftwareIntegrations')
                ? t('IntegrationsAppsText')
                : t('PaymentGatewaysText')
            }
          />
          {selectedValue && (
            <>
              {selectedValue?.name === t('SoftwareIntegrations') ? (
                <>
                  <ConnectedApps />
                </>
              ) : (
                <>
                  <PaymentMethod />
                </>
              )}
            </>
          )}
        </CommonSection>
      </Layout>
    </>
  );
};
const getTranslatedList = (t) => {
  const lists = [
    {
      id: 'SoftwareIntegrations',
      name: t('SoftwareIntegrations'),
    },
    {
      id: 'PaymentGateways',
      name: t('PaymentGateways'),
    },
  ];

  return lists;
};

export default IntegrationAndPayment;
