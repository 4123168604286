import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TitleWithText from './TitleWithText';
import BulletPoints from '../BulletPoints';
import CommonAlphabeticComp from './CommonAlphabeticComp';
import { useNavigate } from 'react-router-dom';

const WebTermsOfUseContent = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [tableOfContent, setTableOfContent] = useState([]);
  const [webTermsData, setWebTermsData] = useState({
    ProhibitedSite: [],
    GeneralProvision: [],
    SubmissionNotification: [],
    ReceiptNotification: [],
    CountNotification: [],
    ReceiptCountNotification:[],
  });
  useEffect(() => {
    setTableOfContent(getTranslatedList(t, 'tableOfContent'));
    setWebTermsData((prevData) => ({
      ...prevData,
      ProhibitedSite: getTranslatedList(t, 'prohibited_site'),
      GeneralProvision: getTranslatedList(t, 'general_provision'),
      SubmissionNotification: getTranslatedList(t, 'submission'),
      ReceiptNotification: getTranslatedList(t, 'receipt'),
      CountNotification: getTranslatedList(t, 'counter'),
      ReceiptCountNotification: getTranslatedList(t, 'receipt_count'),
    }));
  }, [t])
   const handleClickScroll = (id) => {
     const element = document.getElementById(id);
     if (element) {
       element.scrollIntoView({ behavior: 'smooth' });
     }
  };
  const handleOnClick = (event) => {
    let element = event.target;
    while (element != null && !element.classList.contains('underlineSpan')) {
      element = element.parentElement;
    }

    if (element && element.dataset.url) {
      if (element.dataset.url.includes('https://')) {
        window.open(element.dataset.url, '_blank');
      } else {
        navigate(element.dataset.url, { state: 'send' });
        localStorage.setItem('scrollPositionWT', window.scrollY);
      }
    }
  };
  return (
    <div>
      <p className="text40class text-LightPrimary">{t('ForEveryone1Title')}</p>
      <p className="mt-5 paragraphText">{t('LastModifyDate')}</p>
      <p className="paragraphText text-[18px] font-bold mt-10">
        {t('PleaseReadWebTerm')}
      </p>
      <div className="mt-[30px] flex flex-col gap-[16px]">
        <p className="smallText16Normal">{t('WebTermT1')}</p>
        <p
          className="smallText16Normal"
          dangerouslySetInnerHTML={{ __html: t('WebTermT2') }}
          onClick={handleOnClick}
        ></p>
        <p
          className="smallText16Normal"
          dangerouslySetInnerHTML={{ __html: t('WebTermT3') }}
          onClick={handleOnClick}
        ></p>
        <p className="smallText16Normal">{t('WebTermT4')}</p>
        <p className="smallText16Normal">{t('WebTermT5')}</p>
      </div>
      {/* TABLE OF CONTENT */}
      <div className="mt-[30px] flex flex-col gap-3">
        <div className="flex flex-col gap-4">
          {tableOfContent.map((list1, index) => (
            <div className="flex gap-2" key={index}>
              <p className="w-3 smallText16Normal text-LightPrimary">
                {index + 1}.
              </p>
              <p
                className="smallText16Normal cursor-pointer text-LightPrimary underline"
                onClick={() => handleClickScroll(list1.id)}
              >
                {list1.name}
              </p>
            </div>
          ))}
        </div>
      </div>
      <TitleWithText
        title={t('PermittedSite')}
        count={1}
        text={[t('PermittedSiteT')]}
        id="PermittedSite"
      />
      <TitleWithText
        title={t('ProhibitedSite')}
        count={2}
        text={[t('ProhibitedSiteT')]}
        id="ProhibitedSite"
      >
        <div className="flex flex-col gap-4">
          {webTermsData.ProhibitedSite.map((points, index1) => (
            <BulletPoints
              data={points}
              key={index1}
              pl="24px"
              handleOnClick={handleOnClick}
            />
          ))}
          <p className="smallText16Normal">{t('ProhibitedSiteT2')}</p>
        </div>
      </TitleWithText>

      <TitleWithText
        title={t('CopyrightsTrademarks')}
        count={3}
        text={[t('CopyrightsTradeT1'), t('CopyrightsTradeT2')]}
        id="CopyrightsTrademarks"
      />
      <TitleWithText
        title={t('InformationProvide')}
        count={4}
        text={[t('InformationProvideT1'), t('InformationProvideT2')]}
        id="InformationProvide"
      />
      <TitleWithText
        title={t('LinksThirdPartyWeb')}
        count={5}
        text={[t('LinksThirdPartyT1')]}
        id="LinksThirdPartyWeb"
      />
      <TitleWithText
        title={t('DownloadingFiles')}
        count={6}
        text={[t('DownloadingFilesT')]}
        id="DownloadingFiles"
      />
      <TitleWithText
        title={t('DisclaimersLimitationsLiability')}
        count={7}
        text={[t('DisclaimersT1'), t('DisclaimersT2'), t('DisclaimersT3')]}
        id="DisclaimersLimitationsLiability"
      />
      <TitleWithText
        title={t('Indemnification')}
        count={8}
        text={[t('IndemT')]}
        id="Indemnification"
      />

      <CommonAlphabeticComp
        title={t('GeneralProvisions')}
        count={9}
        data={webTermsData.GeneralProvision}
        id="GeneralProvisions"
      />

      <div className="mt-10">
        <p className="text22">{t('ClaimsCopyrightInfringement')}</p>
        <p className="mt-5 paragraphText text-[18px] font-bold">
          {t('DMCANotices')}
        </p>
        <div className="flex flex-col gap-4 mt-4">
          <p className="smallText16Normal">{t('DMCA_T1')}</p>
          <p className="smallText16Normal">{t('DMCA_T2')}</p>
          {/* 0 */}
          <p className="smallText16Normal font-semibold text-LightPrimary">
            {t('ContactDCDial')}
          </p>
          <p className="smallText16Normal">{t('ContactDCDialT')}</p>
          {/* 1 */}
          <p className="smallText16Normal font-semibold text-LightPrimary">
            {t('SubmissionNotification')}
          </p>
          <p className="smallText16Normal">{t('SubNotificationT')}</p>
          {webTermsData.SubmissionNotification.map((points, index3) => (
            <BulletPoints
              isRoman
              data={points.name}
              id={points.id}
              key={index3}
              pl="24px"
            />
          ))}
          {/* 2 */}
          <p className="smallText16Normal font-semibold text-LightPrimary">
            {t('ReceiptNotification')}
          </p>
          <p className="smallText16Normal">{t('ReceiptNotificationT')}</p>
          {webTermsData.ReceiptNotification.map((points, index3) => (
            <BulletPoints
              isRoman
              data={points.name}
              id={points.id}
              key={index3}
              pl="24px"
            />
          ))}
          {/* 3 */}
          <p className="smallText16Normal font-semibold text-LightPrimary">
            {t('CounterNotification')}
          </p>
          <p className="smallText16Normal">{t('CountNotificationT')}</p>
          {webTermsData.CountNotification.map((points, index3) => (
            <BulletPoints
              isRoman
              data={points.name}
              id={points.id}
              key={index3}
              pl="24px"
            />
          ))}
          {/* 4 */}
          <p className="smallText16Normal font-semibold text-LightPrimary">
            {t('ReceiptCountNotification')}
          </p>
          <p className="smallText16Normal">{t('ReceiptCountNotificationT')}</p>
          {webTermsData.ReceiptCountNotification.map((points, index3) => (
            <BulletPoints
              isRoman
              data={points.name}
              id={points.id}
              key={index3}
              pl="24px"
            />
          ))}
        </div>
      </div>
    </div>
  );
};
const getTranslatedList = (t, key) => {
  const lists = {
    tableOfContent: [
      { id: 'PermittedSite', name: t('PermittedSite') },
      { id: 'ProhibitedSite', name: t('ProhibitedSite') },
      { id: 'CopyrightsTrademarks', name: t('CopyrightsTrademarks') },
      { id: 'InformationProvide', name: t('InformationProvide') },
      { id: 'LinksThirdPartyWeb', name: t('LinksThirdPartyWeb') },
      { id: 'DownloadingFiles', name: t('DownloadingFiles') },
      {
        id: 'DisclaimersLimitationsLiability',
        name: t('DisclaimersLimitationsLiability'),
      },
      { id: 'Indemnification', name: t('Indemnification') },
      { id: 'GeneralProvisions', name: t('GeneralProvisions') },
    ],
    prohibited_site: [
      t('ProhibitedSite_1'),
      t('ProhibitedSite_2'),
      t('ProhibitedSite_3'),
      t('ProhibitedSite_4'),
      t('ProhibitedSite_5'),
      t('ProhibitedSite_6'),
      t('ProhibitedSite_7'),
      t('ProhibitedSite_8'),
      t('ProhibitedSite_9'),
      t('ProhibitedSite_10'),
    ],
    general_provision: [
      {
        id: 'a',
        name: t('GeneralProv_a'),
      },
      {
        id: 'b',
        name: t('GeneralProv_b'),
      },
      {
        id: 'c',
        name: t('GeneralProv_c'),
      },
    ],
    submission: [
      {
        id: '1',
        name: t('submission_1'),
      },
      {
        id: '2',
        name: t('submission_2'),
      },
      {
        id: '3',
        name: t('submission_3'),
      },
      {
        id: '4',
        name: t('submission_4'),
      },
      {
        id: '5',
        name: t('submission_5'),
      },
      {
        id: '6',
        name: t('submission_6'),
      },
    ],
    receipt: [
      {
        id: 'a)',
        name: t('receipt_a'),
      },
      {
        id: 'b)',
        name: t('receipt_b'),
      },
      {
        id: 'c)',
        name: t('receipt_c'),
      },
    ],
    counter: [
      {
        id: 1,
        name: t('counter_1'),
      },
      {
        id: 2,
        name: t('counter_2'),
      },
      {
        id: 3,
        name: t('counter_3'),
      },
      {
        id: 4,
        name: t('counter_4'),
      },
    ],
    receipt_count: [
      {
        id: 'a)',
        name: t('receipt_count_a'),
      },
      {
        id: 'b)',
        name: t('receipt_count_b'),
      },
      {
        id: 'c)',
        name: t('receipt_count_c'),
      },
    ],
  };

  return lists[key];
};
export default WebTermsOfUseContent;
