import axios from "axios";


export const send_feedback = async (payload) => {
  try {
    const res = await axios.post(
      `https://apiv2.dcdial.com/preference/add-feedback`,
      payload
    );
    return res.data;
  } catch (error) {
    return error?.response;
  }
};
