import React, { useEffect } from 'react';
import Layout from '../../Components/Layout';
import LegalSideLayout from '../../Components/LegalSideLayout';
import DeveloperTermsContent from '../../Components/Legal/DeveloperTermsContent';
import TitleAndDesc from '../../Components/Helmet/TitleAndDesc';
import { useLocation } from 'react-router-dom';

const DeveloperTerms = () => {
  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    if (state && state !== null) {
      window.scrollTo(0, 0);
    }
  }, [state]);

  var scrollPosition = localStorage.getItem('scrollPositionDT');
  if (scrollPosition !== null) {
    window.scrollTo(0, parseInt(scrollPosition));
  }
  return (
    <>
      <TitleAndDesc
        title="DCDial Developer Terms"
        description="Discover the terms governing the use of DCDial's developer tools and APIs, ensuring developers understand their rights and responsibilities."
      />
      <Layout>
        <LegalSideLayout>
          <DeveloperTermsContent />
        </LegalSideLayout>
      </Layout>
    </>
  );
};

export default DeveloperTerms;
