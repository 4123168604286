import React, { useEffect, useState } from 'react'
import Layout from '../../Layout'
import HeroSection from '../../HeroSection'
import { useTranslation } from 'react-i18next'
import CommonProductBanner from '../CommonProductBanner'
import PaymentChartComp from './PaymentChartComp'
import InvoiceAndQuotes from './InvoiceAndQuotes'
import { Consistency, Customization, CustomizeBrandingIcon, Efficiency, ImprovedCashFlow, PayFlexibilityIcon, SelfServiceIcon } from '../../../assets/Icons/Products/ProductsIcon'
import BgCenterComp from '../BgCenterComp'
// import CRMImg from '../../../assets/images/Products/Dashboard.svg';
// import CRMImgRes from '../../../assets/images/Products/DashboardRes1.svg';
import PaymentPlans from './PaymentPlans'
// import VirtualCollectorImg from '../../../assets/images/Products/VirtualCollector.svg'
import PaymentCollection from './PaymentCollection'
import { useWindowSize } from '../../../Hooks/useWindowSize'
import TitleDescWithKeyword from '../../Helmet/TitleDescWithKeyword'

const Payments = () => {
  const { t } = useTranslation()
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 980;
  const [customerPortalData, setCustomerPortalData] = useState([])
  const [virtualColData, setVirtualColData] = useState([])
  useEffect(() => {
    setCustomerPortalData(getTranslatedList(t, 'customer_data'));
    setVirtualColData(getTranslatedList(t, 'virtual_data'));
  }, [t])
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TitleDescWithKeyword
        title="DCDial | Accept Online Payments"
        description="Automate billing with DCDial. Set up recurring payments, create subscriptions, and generate secure payment links."
        keywords="Payment process optimization, Subscription management, Recurring payments, Predictable revenue streams, Payment links generation, Automated billing solutions, Customer payment portal, Invoice and quote creation, Debt collection automation, Virtual Collector tool, Payment collection campaigns, Enhanced cash flow, Automated payment reminders, Flexible payment methods, Secure payment portal, Branded payment interfaces, Personalized payment plans, Financial stability with recurring billing, Improved cash flow management, Self-service payment capabilities, Custom settlement offers, Debt repayment plans, Accounts receivable efficiency, Voice broadcast payment reminders, Email and SMS payment campaigns, Payment IVR systems, Customer payment flexibility, Seamless payment processing, Subscription service growth potential, Customized branding for payments"
        isPageURL="https://dcdial.com/products/payments"
        isImageURL="https://images.dcdial.com/dcdial.com/v2/Payments.svg"
      />
      <Layout>
        <HeroSection title={t('Payments')} text={t('PaymentProText')} />
        <CommonProductBanner
          tagText={t('Subscriptions')}
          title={t('PaymentProduct')}
          text={t('SubscriptionsText')}
          id="Subscriptions"
        />

        <PaymentChartComp />
        <InvoiceAndQuotes />

        <BgCenterComp
          title={t('CustomerPortal')}
          text={t('CustomerPortalT')}
          tagText={t('Tools')}
          background="#EDF8FF"
          data={customerPortalData}
          id="CustomerPortal"
        >
          <div className="flex justify-center mt-[60px] max_md:mt-0 w-full ">
            <img
              src={
                isMobileView
                  ? 'https://images.dcdial.com/dcdial.com/v2/CRM_DashboardRes.svg'
                  : 'https://images.dcdial.com/dcdial.com/v2/CRM_Dashboard.svg'
              }
              alt="OutboundSec"
              className=""
            />
          </div>
        </BgCenterComp>

        <PaymentPlans />

        <BgCenterComp
          title={t('VirtualCollectorU')}
          text={t('VirtualCollectorText')}
          tagText={t('Features')}
          background="#EDF8FF"
          data={virtualColData}
          id="VirtualCollectorId"
        >
          <div className="flex justify-center mt-0 max_md:mt-[-50px] w-full">
            <img
              src="https://images.dcdial.com/dcdial.com/v2/VirtualCollector.svg"
              alt="OutboundSec"
              className="mb-[-48px] max_xl:mb-[-38px] max_md:mb-0"
            />
          </div>
        </BgCenterComp>

        <PaymentCollection />
      </Layout>
    </>
  );
}
const getTranslatedList = (t, listKey) => {
  const lists = {
    customer_data: [
      {
        name: t('PaymentFlexibility'),
        text: t('PaymentFlexT'),
        icon: <PayFlexibilityIcon />,
      },
      {
        name: t('SelfCapabilities'),
        text: t('SelfCapabilitiesT'),
        icon: <SelfServiceIcon />,
      },
      {
        name: t('CustomizedBranding'),
        text: t('CustomizedBrandingT'),
        icon: <CustomizeBrandingIcon />,
      },
    ],
    virtual_data: [
      {
        name: t('Efficiency'),
        text: t('EfficiencyT'),
        icon: <Efficiency />,
      },
      {
        name: t('Consistency'),
        text: t('ConsistencyT'),
        icon: <Consistency />,
      },
      {
        name: t('CustomizationProduct'),
        text: t('CustomizedBrandingT'),
        icon: <Customization />,
      },
      {
        name: t('ImprovedCashflow'),
        text: t('ImprovedCashflowT'),
        icon: <ImprovedCashFlow />,
      },
    ],
  };

  return lists[listKey];
};

export default Payments