import React, { useEffect, useState } from 'react';
import Layout from '../../Components/Layout';
import FeedbackReason from './FeedbackReason';
import Feedback from './Feedback';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { send_feedback } from '../../api/apis';
// import { send_feedback } from '../../api/apis';

const UninstallScreen = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get('userId');
  const [storeUser, setStoreUser] = useState('');
  const [formState, setFormState] = useState({
    slow_down_pc: false,
    not_working: false,
    donotknow: false,
    difficult2use: false,
    product_manager: false,
    find_better_tool: false,
    privacy_security_concern: false,
  });

  const [reasonText, setReasonText] = useState({
    slow_down_pc: '',
    not_working: '',
    donotknow: '',
    difficult2use: '',
    product_manager: '',
    find_better_tool: '',
    privacy_security_concern: '',
  });
  const handleChangeText = (e, name) => {
    setReasonText({
      ...reasonText,
      [name]: e.target.value, // Update the specific field based on fieldName
    });
  };

  const [lastChecked, setLastChecked] = useState([]);
  const [checkedValue, setCheckedValue] = useState('');
  const [isSubmitFeedback, setIsSubmitFeedback] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const username = localStorage.getItem('agent');
    if (username) {
      setStoreUser(username);
    }
  }, []);

  const sendFeedbackApi = async (dynamicPayload) => {
    const payload = {
      feedbacks: dynamicPayload,
    };
    try {
      const response = await send_feedback(payload);      
      if (response.status === 200 || response.status === 201) {
        setIsLoading(false)
        navigate('/chrome/uninstall-success');
      }
    } catch (err) {
      console.log('errr', err);
      setIsLoading(false);
    }
  };
  const generateDynamicPayload = () => {
    const payload = [];

    for (const key in formState) {
      if (formState[key]) {
        payload.push({
          option_value: key,
          notes: reasonText[key],
          username: userId,
        });
      }
    }

    return payload;
  };

  const handleSubmit = () => {
    setIsSubmitFeedback(true);
    setIsLoading(true)
    const dynamicPayload = generateDynamicPayload();
    sendFeedbackApi(dynamicPayload);
    // navigate('/chrome/uninstall-success');
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setFormState({
      ...formState,
      [name]: checked,
    });
    if (checked) {
      const newArray = [...lastChecked];
      newArray.push(name);
      setLastChecked(newArray);
      const lastElement = newArray.slice(-1);
      setCheckedValue(lastElement[0]);
    } else {
      const newArray = [...lastChecked];
      const updatedArray = newArray.filter((item) => item !== name);
      setLastChecked(updatedArray);
      if (updatedArray.length === 0) {
        setCheckedValue('');
      } else {
        const lastElement = updatedArray.slice(-1);
        setCheckedValue(lastElement[0]);
      }
    }
  };
  return (
    <Layout>
      <div className="backImage">
        <div className="py-10 h-[calc(100vh-80px)] overflow-y-auto">
          <div className="text-[30px] font-DMSansFont font-bold text-LightPrimary text-center">
            <p>Almost there! Just one last thing</p>
            <p>🥺 Could you share with us why you're uninstalling?</p>
          </div>

          <div className="relative flex justify-center gap-10">
            <Feedback
              formState={formState}
              checkedValue={checkedValue}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              isLoading={isLoading}
            />
            <FeedbackReason
              formState={formState}
              checkedValue={checkedValue}
              reasonText={reasonText}
              handleChangeText={handleChangeText}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UninstallScreen;
