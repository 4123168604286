import React from 'react'

const IndustrySelectCard = ({ industry, handleClickScroll }) => {
  return (
    <div
      className="p-[30px] rounded-[20px] border-[1px] border-LightOutline hover:border-ButtonPrimary flex flex-col gap-5 cursor-pointer"
      onClick={() => handleClickScroll(industry.id)}
    >
      <div>{industry.icon}</div>
      <div>
        <p className="paragraphText max_md:text-subHeadingTxt max_sm:text-headingNormal font-bold text-LightPrimary">
          {industry.name}
        </p>
        <p className="subParagraphText max_md:text-headingNormal font-normal mt-3 min-h-[60px] max_sm:min-h-0 max_sm:text-textSmall">
          {industry.text}
        </p>
      </div>
      {industry.isTag && (
        <div className="flex gap-[10px] flex-wrap">
          {industry.tags.map((tag, index1) => (
            <p
              key={index1}
              className="py-1 px-[10px] rounded-[30px] bg-LightBgSecondary smallText16 font-normal text-LightBright max_sm:text-textExtraSmall"
            >
              {tag}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default IndustrySelectCard