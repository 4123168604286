import React, { useEffect } from 'react';
import Layout from '../Components/Layout';
import HeroSection from '../Components/HeroSection';
import { useTranslation } from 'react-i18next';
import SelectSolution from '../Components/Solutions/SelectSolution';
import TitleDescWithKeyword from '../Components/Helmet/TitleDescWithKeyword';

const Solutions = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TitleDescWithKeyword
        title="DCDial | Communication & Payment Solutions For Every Industry"
        description="Discover how DCDial's tailored solutions can enhance efficiency and customer satisfaction across industries like professional services, utilities, collections, insurance, e-commerce, nonprofits, and more."
        keywords="Industry-specific communication solutions, Professional services automation, Utilities customer service optimization, Debt collection tools, Predictive dialer for collections, CRM synchronization for agents, Automated payment reminders, Recurring payment management, Client communication for legal firms, Appointment reminder automation, Intelligent call routing, Insurance communication tools, Multi-channel notifications for policyholders, Online payment portal for insurance, E-commerce customer engagement, Subscription management solutions, Nonprofit donor engagement, Fundraising automation tools, Donation receipt generation, Automated donor payment reminders"
        isPageURL="https://dcdial.com/solutions"
        isImageURL="https://images.dcdial.com/dcdial.com/v2/ProfessionalServices.svg"
      />
      <Layout>
        <HeroSection title={t('WhatDCDialDoforYou')} text={t('SolutionText')} />
        <div className="overflow-x-hidden">
          <SelectSolution />
        </div>
      </Layout>
    </>
  );
};

export default Solutions;
