import React from 'react'

const CommonSizeContainer = ({ children }) => {
  return (
    <div
      className="m-auto max-w-[1240px] max_xl:max-w-[980px] mt-[150px] max_xl:mt-[100px] max_sm:mt-[60px] max_md:px-10 max_sm:px-5"
    >
      {children}
    </div>
  );
};

export default CommonSizeContainer