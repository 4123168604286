import React from 'react';

const CommonAlphabeticComp = ({
  data,
  title,
  count,
  isBullet,
  pl,
  isBulletText,
  id,
  handleOnClick,
}) => {
  return (
    <div className="mt-[30px] flex flex-col" id={id}>
      <p className="text22">
        {count}. <span className="pl-1">{title}</span>
      </p>
      {data.map((uses, index3) => (
        <React.Fragment key={index3}>
          {uses.name && (
            <div
              className={`flex mt-4 ${isBulletText ? 'gap-2' : 'gap-1'}`}
              style={{ paddingLeft: pl ? pl : '' }}
            >
              {isBulletText ? (
                <p className="w-[6px] h-[6px] bg-LightSecondary rounded-full mt-2"></p>
              ) : (
                <p className="w-5 smallText16Normal text-LightSecondary font-medium">
                  {uses.id}.
                </p>
              )}

              <div
                className="w-[calc(100%-24px)] smallText16Normal"
                dangerouslySetInnerHTML={{ __html: uses.name }}
                onClick={handleOnClick}
              ></div>
            </div>
          )}
          {uses.text && (
            <>
              {uses.text.map((list, index2) => (
                <div className="flex gap-2 mt-4 pl-[26px]" key={index2}>
                  {isBullet ? (
                    <>
                      <p className="w-[6px] h-[6px] bg-LightSecondary rounded-full mt-2"></p>
                    </>
                  ) : (
                    <p className="text-LightSecondary font-semibold"> - </p>
                  )}

                  <p className="w-[calc(100%-14px)] smallText16Normal">
                    {list}
                  </p>
                </div>
              ))}
            </>
          )}
          {uses.moreText && (
            <>
              {uses.moreText.map((list1, index4) => (
                <p
                  className={`smallText16Normal mt-4 ${
                    uses.name ? 'pl-6' : ''
                  }`}
                  key={index4}
                >
                  {list1}
                </p>
              ))}
            </>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default CommonAlphabeticComp;
