import React, { useEffect } from 'react';
import SignUp from '../Components/Auth/SignUp';
// import { Helmet } from 'react-helmet';

const Register = () => {
  console.log("11");
  
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.charset = 'utf-8';
    script.src = 'https://web-sdk.smartlook.com/recorder.js';

    const scriptContent = `
      window.smartlook||(function(d) {
    var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
    var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
    c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
    })(document);
    smartlook('init', '416ccefe6b9a1c7ed2cb6ba8ce69cbf2f4ba15b7', { region: 'eu' })
    smartlook('record', { forms: true, numbers: true, emails: true })
    `;

    const inlineScript = document.createElement('script');
    inlineScript.textContent = scriptContent;

    document.head.appendChild(script);
    document.head.appendChild(inlineScript);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(inlineScript);
    };
  }, []);
//   <script type=‘text/javascript’>
//    window.smartlook||(function(d){
//     var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName(‘head’)[0];
//     var c=d.createElement(‘script’);o.api=new Array();c.async=true;c.type=‘text/javascript’;
//     c.charset=‘utf-8’;c.src=’https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
//     })(document);
//     smartlook(‘init’, ‘416ccefe6b9a1c7ed2cb6ba8ce69cbf2f4ba15b7’, { region: ‘eu’ });
// </script>

  return (
    <>
      {/* <Helmet>
        <script type="text/javascript">
          {`
            window.smartlook||(function(d) {
              var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
              var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
              c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
            })(document);
            smartlook('init', '416ccefe6b9a1c7ed2cb6ba8ce69cbf2f4ba15b7', { region: 'eu' });
          `}
        </script>
      </Helmet> */}
      <SignUp />
    </>
  );
};

export default Register;
