import React from 'react';
import { TickMarkIcon } from '../../assets/Icons/Solution/SolutionIcon';
import {
  RoundBackLG,
  
  RoundGroupDown,
  RoundGroupUp,
} from '../../assets/Icons/Features/FeatureIcon';

const ServiceComponent = ({ serviceList, index2 }) => {
  return (
    <div
      className={`relative flex max_md:flex-col items-center justify-between ${
        index2 % 2 !== 0 ? 'flex-row-reverse' : ''
      }`}
    >
      <div
        className="absolute top-[-60px] left-0 invisible"
        id={serviceList.id}
      ></div>
      <div className="">
        <h2 className="text40class text-LightPrimary max_md:text-center max-w-[390px] max_md:max-w-full">
          {serviceList.name}
        </h2>
        <div className="grid grid-cols-2 mt-[60px] max_xl:mt-10 max_md:mt-[60px] max_sm:mt-5 gap-[50px] max_xl:gap-6 max_md:gap-[50px] max_sm:grid-cols-1">
          {serviceList.services.map((service, index4) => (
            <div key={index4} className="w-[250px] flex gap-2">
              <div>
                <TickMarkIcon />
              </div>
              <p className="subParagraphText font-normal tracking-[-1px] max_md:text-headingNormal">
                {service}
              </p>
            </div>
          ))}
        </div>
      </div>
      {/* IMAGEs */}
      <div
        className={`max_md:mt-[60px] max_sm:mt-10 ${
          index2 === 2 ? 'max_xl:w-[44%]' : 'max_xl:w-[40%]'
        } max_md:w-[450px] max_sm:w-[100%] ${
          index2 % 2 !== 0 ? 'max_sm:justify-center' : 'max_sm:justify-center'
        } max_sm:flex `}
      >
        <div className={`relative rounded-[40px] max_sm:w-[100%]`}>
          <img
            src={serviceList.image}
            alt={serviceList.id}
            className="rounded-[40px]"
          />
          {index2 === 0 && (
            <div>
              <div className="absolute right-[-82px] w-[55%] top-[100px] -z-10 max_xl:w-[50%] max_md:right-[-80px]">
                <RoundBackLG style={{ width: '100%', height: '100%' }} />
              </div>
              <div className="absolute bottom-[90px] left-[-10px] -z-10">
                <RoundGroupDown style={{ width: '100%', height: '100%' }} />
              </div>
            </div>
          )}
          {index2 === 2 && (
            <div>
              <div className="absolute right-[-82px] w-[46%] top-0 -z-10 max_xl:w-[50%] max_md:right-[-80px]">
                <RoundBackLG style={{ width: '100%', height: '100%' }} />
              </div>
              <div className="absolute bottom-[-30px] left-[100px] -z-10">
                <RoundGroupDown style={{ width: '100%', height: '100%' }} />
              </div>
            </div>
          )}
          {index2 === 3 && (
            <div>
              <div className="absolute left-[-82px] w-[52%] top-[100px] -z-10 max_xl:w-[50%] max_md:right-[-80px]">
                <RoundBackLG style={{ width: '100%', height: '100%' }} />
              </div>
              <div className="absolute bottom-10 right-[-20px] -z-10">
                <RoundGroupUp style={{ width: '100%', height: '100%' }} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceComponent;
