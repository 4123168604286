import React, { useEffect } from 'react'
import Layout from '../../Components/Layout';
import LegalSideLayout from '../../Components/LegalSideLayout';
import ProductSpecificContent from '../../Components/Legal/ProductSpecificContent';
import TitleAndDesc from '../../Components/Helmet/TitleAndDesc';
import { useLocation } from 'react-router-dom';

const SpecificProductService = () => {
   const location = useLocation();
   const state = location.state;
   useEffect(() => {
     if (state && state !== null) {
       window.scrollTo(0, 0);
     }
   }, [state]);
  
  
  var scrollPosition = localStorage.getItem('scrollPositionS');
  if (scrollPosition !== null) {
    window.scrollTo(0, parseInt(scrollPosition));
  } 

  return (
    <>
      <TitleAndDesc
        title="Product Specific Terms"
        description="Learn about the additional rules and conditions that apply to specific products or features offered by DCDial in our Product Specific Terms."
      />
      <Layout>
        <LegalSideLayout>
          <ProductSpecificContent />
        </LegalSideLayout>
      </Layout>
    </>
  );
}

export default SpecificProductService