import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonFilled } from '../utils/Button';
import { RightArrow } from '../../assets/Icons/Products/ProductsIcon';
import { useNavigate } from 'react-router-dom';

const AllNewProducts = () => {
  const { t } = useTranslation();
  const [visibleIndex, setVisibleIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const productRefs = useRef([]);
  const navigate = useNavigate();


  const [products, setProducts] = useState([]);

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 80;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // setTimeout(() => {
            setVisibleIndex(Number(entry.target.dataset.index));
            // }, 400);
          }
        });
      },
      { threshold: 0.7 }
    );

    // Attach observer to elements
    productRefs.current.forEach((ref) => ref && observer.observe(ref));

    // Cleanup observer on component unmount or dependencies change
    return () => {
      observer.disconnect();
    };
  }, [products]);

  useEffect(() => {
    setProducts(getTranslatedList(t, 'productsList'));
  }, [t]);

  return (
    <>
      <div className="w-[100%] mt-[100px] max_xl:mt-[80px] max_md:hidden max_sm:hidden">
        <div id="work-container" className="">
          <div className="w-full max-w-[1240px] max_xl:max-w-[980px] flex justify-between mx-auto">
            <div
              className={`flex flex-col gap-[150px] max_xl:gap-[100px] my-[100px]`}
            >
              {products.map((allproduct, index) => (
                <div
                  className="max-w-[390px] max_xl:max-w-[380px]"
                  key={index}
                  ref={(el) => (productRefs.current[index] = el)}
                  data-index={index}
                >
                  <h3 className="text40class text-LightPrimary leading-[50px]">
                    {allproduct.name}
                  </h3>
                  <p className="mt-4 paragraphText font-normal leading-[32px]">
                    {allproduct.text}
                  </p>
                  <div className="mt-[30px]">
                    <ButtonFilled
                      title={`Start with ${allproduct.name}`}
                      fontWeight="500"
                      fontSize="16px"
                      width="max-content"
                      onClick={() => navigate(allproduct.link)}
                    />
                  </div>
                  <div className="mt-[30px]">
                    <div className="flex gap-2 items-center">
                      <p className="subParagraphText text-LightPrimary">
                        See also
                      </p>
                      <div className="max_sm_380:mt-[2px] flex items-center">
                        <RightArrow color="#6D77A0" />
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 mt-3">
                      {allproduct.product.map((item, index1) => (
                        <p
                          className="subParagraphText text-ButtonPrimary font-normal cursor-pointer w-max"
                          key={index1}
                          onClick={() => {
                            // handleStore()
                            navigate(allproduct.link);
                            setTimeout(() => {
                              handleClickScroll(item.id);
                            }, 400);
                          }}
                        >
                          {item.title}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div
              id="product-work-text"
              className={`text-content items-end content-center self-start sticky flex flex-wrap top-0`}
            >
              <div className="headingText">
                <img
                  src={products[visibleIndex]?.image}
                  alt={products[visibleIndex]?.name}
                  draggable="false"
                  className={`transition-opacity duration-500 ${
                    fade ? 'opacity-100' : 'opacity-0'
                  }`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden max_md:block">
        <div className="px-10 max_sm:px-5 m-auto mt-[100px] max_sm:mt-[60px]">
          <div className={`flex flex-col gap-[100px] max_600:gap-[60px]`}>
            {products.map((allproduct, index) => (
              <div
                className="flex flex-col gap-[60px] max_600:gap-10 items-center"
                key={index}
              >
                <div
                  className="flex max_600:flex-col gap-[60px] max_600:gap-10"
                  data-index={index}
                >
                  <div className="max-w-[350px] max_600:max-w-full">
                    <p className="text40class text-LightPrimary leading-[50px] max_600:leading-[40px]">
                      {allproduct.name}
                    </p>
                    <p className="mt-4 paragraphText font-normal leading-[30px] max_600:leading-6">
                      {allproduct.text}
                    </p>
                    <div className="mt-[30px]">
                      <ButtonFilled
                        title={`Start with ${allproduct.name}`}
                        fontWeight="500"
                        fontSize="16px"
                        width="max-content"
                        onClick={() => navigate(allproduct.link)}
                      />
                    </div>
                  </div>

                  {/* Mobile Image */}
                  <div className="hidden max_600:block">
                    <div className="flex justify-center">
                      <img src={allproduct.image} alt={allproduct.id} />
                    </div>
                  </div>

                  <div className="min-w-[250px] max_sm:max-w-full">
                    <div className="flex gap-2 items-center">
                      <p className="subParagraphText text-LightPrimary max_md:text-headingNormal max_sm:text-textSmall">
                        See also
                      </p>
                      <div className="max_sm_380:mt-[2px] flex items-center">
                        <RightArrow color="#6D77A0" />
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 mt-3">
                      {allproduct.product.map((item, index1) => (
                        <p
                          className="subParagraphText text-ButtonPrimary font-normal cursor-pointer max_md:text-headingNormal max_sm:text-textSmall"
                          id={item.id}
                          key={index1}
                          onClick={() => {
                            navigate(allproduct.link);
                            setTimeout(() => {
                              handleClickScroll(item.id);
                            }, 400);
                          }}
                        >
                          {item.title}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="max_600:hidden">
                  <div className="flex justify-center w-full">
                    <img
                      src={allproduct.image}
                      alt={allproduct.id}
                      className="w-full"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
const getTranslatedList = (t, listKey) => {
  const lists = {
    productsList: [
      {
        id: 'communication',
        name: t('Communication'),
        text: t('CommunicationText'),
        image: 'https://images.dcdial.com/dcdial.com/v2/Communication.svg',
        link: '/products/communication',
        product: [
          {
            id: 'inbound',
            title: t('InboundCenter'),
            text: t('InboundCenterText'),
          },
          {
            id: 'IVR',
            title: t('InteractiveVoice'),
            text: t('InteractiveVoiceText'),
          },
          {
            id: 'AgentInterface',
            title: t('AgentInterface'),
            text: t('AgentInterfaceText'),
          },
          {
            id: 'Outbound',
            title: t('OutboundCenter'),
            text: t('OutboundCenterText'),
          },
          {
            id: 'UnifiedCommunications',
            title: t('UnifiedCommunications'),
            text: t('UnifiedComText'),
          },
          {
            id: 'SmartRouting',
            title: t('SmartRouting'),
            // text: t('AutomaticCallText'),
          },
        ],
      },
      {
        id: 'crm',
        name: t('CRM'),
        text: t('CRMText'),
        image: 'https://images.dcdial.com/dcdial.com/v2/CRM.svg',
        link: '/products/crm',
        product: [
          {
            id: 'ContactManagement',
            title: t('ContactManagementS'),
            text: t('ContactManagementText'),
          },
          {
            id: 'Thirdparty',
            title: t('ThirdPartyIntegration'),
            text: t('ThirdPartyText'),
          },
          {
            id: 'ListAutomation',
            title: t('ListAutomation'),
            text: t('ListAutomationText'),
          },
          {
            id: 'Synchronize',
            title: t('CRMDataSyncing'),
            text: t('CRMDataSyncingText'),
          },
          {
            id: 'Customizable',
            title: t('CustomizableCRMFields'),
            text: t('CustomizableText'),
          },
          {
            id: 'Tracking',
            title: t('ContactTracking'),
            text: t('ContactTracking'),
          },
          {
            id: 'Optimize',
            title: t('UserManagementS'),
            text: t('UserManagementText'),
          },
        ],
      },
      {
        id: 'payments',
        name: t('Payments'),
        text: t('PaymentProText'),
        image: 'https://images.dcdial.com/dcdial.com/v2/Payments.svg',
        link: '/products/payments',
        product: [
          {
            id: 'Subscriptions',
            title: t('Subscriptions'),
            text: t('SubscriptionsText'),
          },
          {
            id: 'Invoices',
            title: t('InvoicesQuotes'),
            text: t('InvoicesQuotes'),
          },
          {
            id: 'CustomerPortal',
            title: t('CustomerPortalS'),
            text: t('CustomerPortalT'),
          },
          {
            id: 'PaymentPlan',
            title: t('RecurringPayments'),
            text: t('RecurringPaymentsText'),
          },
          {
            id: 'PaymentLinks',
            title: t('PaymentLinks'),
            text: t('PaymentLinksText'),
          },
          {
            id: 'BudgetCalculatorId',
            title: t('BudgetCalculator'),
            text: t('RecurringPaymentsText'),
          },
          {
            id: 'VirtualCollectorId',
            title: t('VirtualCollector'),
            text: t('VirtualCollectorText'),
          },
          {
            id: 'PaymentCollectionId',
            title: t('PaymentCollection'),
            text: t('RecurringPaymentsText'),
          },
        ],
      },
      {
        id: 'ai-assistant',
        name: t('AIAssistant'),
        text: t('AIText'),
        image: 'https://images.dcdial.com/dcdial.com/v2/AIAssistant.svg',
        link: '/products/ai-assistant',
        product: [
          {
            id: 'AgentAssistance',
            title: t('AgentAssistance'),
            text: t('AgentAssistanceText'),
          },
          {
            id: 'WorkAutomation',
            title: t('WorkAutomation'),
            text: t('WorkAutomationText'),
          },
          {
            id: 'Chatbots',
            title: t('Chatbots'),
            text: t('ChatbotsText'),
          },
          {
            id: 'PredictiveEngagement',
            title: t('PredictiveEngagement'),
            text: t('PredictiveEngagementText'),
          },
          {
            id: 'KnowledgeTools',
            title: t('KnowledgeTools'),
            text: t('KnowledgeToolsText'),
          },
          {
            id: 'VirtualAssistantsId',
            title: t('VirtualAssistants'),
            text: t('VirtualAssistantsText'),
          },
          {
            id: 'Voicebots',
            title: t('Voicebots'),
            text: t('VoicebotsText'),
          },
        ],
      },
    ],
  };

  return lists[listKey];
};
export default AllNewProducts;

// useEffect(() => {
//   const observer = new IntersectionObserver(
//     (entries) => {
//       entries.forEach((entry) => {
//         if (entry.isIntersecting) {
//           setTimeout(() => {
//             setVisibleIndex(Number(entry.target.dataset.index));
//           }, 400);
//         }
//       });
//     },
//     { threshold: 0.7 }
//   );

//   // Attach observer to elements
//   productRefs.current.forEach((ref) => ref && observer.observe(ref));

//   // Cleanup observer on component unmount or dependencies change
//   return () => {
//     observer.disconnect();
//   };
// }, [product]);

// onClick={() => {
//   if (openQuestionIndex === index) {
//     setOpenQuestionIndex(null);
//   } else {
//     setOpenQuestionIndex(index);
//   }
// }}


// useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           if (
  //             Number(entry.target.dataset.index) === 0 &&
  //             scrollDirection == 'down'
  //           ) {
  //             setVisibleIndex(Number(entry.target.dataset.index));
  //           } else if (
  //             Number(entry.target.dataset.index) === 3 &&
  //             scrollDirection == 'up'
  //           ) {
  //             setVisibleIndex(Number(entry.target.dataset.index));
  //           } else {
  //             setTimeout(() => {
  //               setFade(false); // Start fading out the current image
  //               setTimeout(() => {
  //                 setVisibleIndex(Number(entry.target.dataset.index));
  //                 setFade(true); // Start fading in the new image
  //               }, 500); // 500ms matches the CSS transition time
  //             }, 400);
  //           }
  //         }
  //       });
  //     },
  //     { threshold: 0.7 }
  //   );

  //   productRefs.current.forEach((ref) => ref && observer.observe(ref));

  //   return () => {
  //     observer.disconnect();
  //   };
  // }, [products, scrollDirection]);
