import React from 'react';

const HeadingComp = ({title,text}) => {
  return (
    <div className="flex flex-col items-center mt-[60px] max_sm:mt-5">
      <p
        className="headingText text-center max-w-[920px] max_md:max-w-[554px] max_sm:max-w-[100%]"
        dangerouslySetInnerHTML={{ __html: title }}
      ></p>
      <p className="subHeadingText max_md:text-headingNormal max_sm:text-textSmall mt-10 max_sm:mt-5 text-LightTextParagraph font-medium text-center w-[88%] max_md:w-full">
        {text}
      </p>
    </div>
  );
};

export default HeadingComp;
