import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import CommonSection from '../../CommonSection';
import useVisibility from '../../../Hooks/useVisibility';
import ChartDataCard from '../ChartDataCard';
import { ButtonFilled } from '../../utils/Button';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);
const PaymentChartComp = () => {
  const { t } = useTranslation();
  const [chartDetails, setChartDetails] = useState([]);
  const chartContainerRef = useRef(null);
  useEffect(() => {
    setChartDetails(getTranslatedList(t));
  }, [t]);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
    scales: {
      // to remove the labels
      x: {
        ticks: {
          display: false,
        },

        // to remove the x-axis grid
        grid: {
          drawBorder: false,
          display: false,
        },
      },
      // to remove the y-axis
      y: {
        ticks: {
          display: false,
          beginAtZero: false,
        },
        // to remove the y-axis grid
        grid: {
          drawBorder: false,
          display: false,
        },
      },
    },
  };

  const labels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K','L'];

  const data1 = {
    labels,
    datasets: [
      {
        fill: true,
        label: '',
        data: [240, 210, 240, 255, 245, 270, 230, 220, 235, 240, 270, 280],
        borderColor: 'rgb(53, 162, 235)',
        borderWidth: 2,
        backgroundColor: (context) => {
          const { ctx, chartArea } = context.chart;
          if (!chartArea) {
            return;
          }
          const { top, bottom } = chartArea;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0.4, 'rgba(0, 160, 252, 0.3)');
          gradientBg.addColorStop(1, 'rgba(255,255,255,0.6)');
          return gradientBg;
        },
        radius: 0,
      },
      {
        fill: true,
        label: '',
        data: [340, 350, 450, 430, 400, 390, 500, 520, 540, 530, 540,560],
        borderColor: 'rgba(253, 182, 0)',
        borderWidth: 2,
        backgroundColor: (context) => {
          const { ctx, chartArea } = context.chart;
          if (!chartArea) {
            return;
          }
          const { top, bottom } = chartArea;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, 'rgba(253, 182, 0, 0.3)');
          gradientBg.addColorStop(0.8, 'rgba(255,255,255,0.6)');
          return gradientBg;
        },
        radius: 0,
      },
      {
        fill: false,
        label: '',
        data: [100, 150, 200, 250, 300, 350, 400, 450, 500, 650, 700],
        borderColor: 'rgba(255,255,255, 1 )',
        borderWidth: 1,
        radius: 0,
      },
    ],
  };
  const data2 = {
    labels,
    datasets: [
      {
        fill: true,
        label: '',
        data: [230, 210,200, 190, 210, 260, 280, 240, 235, 210, 190, 240],
        borderColor: 'rgb(53, 162, 235)',
        borderWidth: 2,
        backgroundColor: (context) => {
          const { ctx, chartArea } = context.chart;
          if (!chartArea) {
            return;
          }
          const { top, bottom } = chartArea;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0.4, 'rgba(0, 160, 252, 0.3)');
          gradientBg.addColorStop(1, 'rgba(255,255,255,0.6)');
          return gradientBg;
        },
        radius: 0,
      },
      {
        fill: true,
        label: '',
        data: [560, 530, 540, 530, 480, 470, 460, 450, 420, 450, 560,460],
        borderColor: 'rgba(253, 182, 0)',
        borderWidth: 2,
        backgroundColor: (context) => {
          const { ctx, chartArea } = context.chart;
          if (!chartArea) {
            return;
          }
          const { top, bottom } = chartArea;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, 'rgba(253, 182, 0, 0.3)');
          gradientBg.addColorStop(0.8, 'rgba(255,255,255,0.6)');
          return gradientBg;
        },
        radius: 0,
      },
      {
        fill: false,
        label: '',
        data: [100, 150, 200, 250, 300, 350, 400, 450, 500, 650, 700],
        borderColor: 'rgba(255,255,255, 1 )',
        borderWidth: 1,
        radius: 0,
      },
    ],
  };
  const data3 = {
    labels,
    datasets: [
      {
        fill: true,
        label: '',
        data: [
          330, 300, 290, 240, 260, 295, 320, 300, 330, 320, 290,310
        ],
        borderColor: 'rgb(53, 162, 235)',
        borderWidth: 2,
        backgroundColor: (context) => {
          const { ctx, chartArea } = context.chart;
          if (!chartArea) {
            return;
          }
          const { top, bottom } = chartArea;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0.4, 'rgba(0, 160, 252, 0.3)');
          gradientBg.addColorStop(1, 'rgba(255,255,255,0.6)');
          return gradientBg;
        },
        radius: 0,
      },
      {
        fill: true,
        label: '',
        data: [450, 550, 570, 560, 490, 460, 560, 580, 610, 650, 630,600],
        borderColor: 'rgba(253, 182, 0)',
        borderWidth: 2,
        backgroundColor: (context) => {
          const { ctx, chartArea } = context.chart;
          if (!chartArea) {
            return;
          }
          const { top, bottom } = chartArea;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, 'rgba(253, 182, 0, 0.3)');
          gradientBg.addColorStop(0.7, 'rgba(255,255,255,0.6)');
          return gradientBg;
        },
        radius: 0,
      },
      {
        fill: false,
        label: '',
        data: [100, 150, 200, 250, 300, 350, 400, 450, 500, 650, 700],
        borderColor: 'rgba(255,255,255, 1 )',
        borderWidth: 1,
        radius: 0,
      },
    ],
  };

  const AnimIsInView = useVisibility(chartContainerRef, 0.5);
  return (
    <CommonSection>
      <div
        className="flex flex-col gap-10 max_md:pt-5 max_600:pt-0"
        ref={chartContainerRef}
      >
        <div className="flex gap-[30px] items-end justify-between min-h-[200px] max_md:hidden">
          <div className="chart-class-payment w-[360px] ml-[-8px]">
            {AnimIsInView && (
              <Line options={options} data={data1} dot={false} />
            )}
          </div>
          <div className="chart-class-payment w-[355px]">
            {AnimIsInView && (
              <Line options={options} data={data2} dot={false} />
            )}
          </div>
          <div className="chart-class-payment w-[370px]">
            {AnimIsInView && (
              <Line options={options} data={data3} dot={false} />
            )}
          </div>
        </div>
        <div className="flex justify-between gap-[30px] max_md:hidden">
          {chartDetails.map((details, index) => (
            <ChartDataCard details={details} key={index} />
          ))}
        </div>

        {/* Tablate design */}
        <div className="hidden max_md:block max_600:hidden">
          <div className="flex flex-col max_md:gap-[60px]">
            <div className="flex justify-between gap-[30px] items-center max_720:gap-4 max_600:hidden">
              <div>
                <Line options={options} data={data1} dot={false} />
              </div>
              <div>
                <ChartDataCard details={chartDetails[0]} isBy />
              </div>
            </div>
            <div className="flex justify-between gap-[30px] items-center max_720:gap-4 max_600:hidden">
              <div>
                <Line options={options} data={data2} dot={false} />
              </div>
              <div>
                <ChartDataCard details={chartDetails[1]} isBy />
              </div>
            </div>
            <div className="flex justify-between gap-[30px] items-center max_720:gap-4 max_600:hidden">
              <div>
                <Line options={options} data={data3} dot={false} />
              </div>
              <div>
                <ChartDataCard details={chartDetails[2]} isBy />
              </div>
            </div>
          </div>
        </div>

        {/* Mobile DEIGN */}
        <div className="hidden max_600:block">
          <div className="flex flex-col gap-10">
            {chartDetails.map((details, index) => (
              <ChartDataCard details={details} key={index} isBy />
            ))}
          </div>
          <div className="max_600:block mt-10">
            <a
              href="https://calendly.com/dcdial-sales/30min"
              rel="noopener noreferrer"
              target="_blank"
            >
              <ButtonFilled
                title={t('BookADemo')}
                fontSize="16px"
                width="140px"
                fontWeight="600"
                height="48px"
              />
            </a>
          </div>
        </div>
      </div>
    </CommonSection>
  );
};
const getTranslatedList = (t) => {
  const list = [
    {
      text: t('PaymentChart1'),
      data: '9X',
    },
    {
      text: t('PaymentChart2'),
      data: '200%',
    },
    {
      text: t('PaymentChart3'),
      data: '30-300%',
    },
  ];

  return list;
};

export default PaymentChartComp;
