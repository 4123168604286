import React from 'react';
import CommonSection from '../CommonSection';
import { useTranslation } from 'react-i18next';
import HeadingComp from '../IntegrationAndPayments/HeadingComp';
import SecurityBanner from '../../assets/images/Security/BannerSecurity.svg';

const HeroSection = () => {
  const { t } = useTranslation();
  return (
    <>
      <CommonSection>
        <HeadingComp
          title={t('SecurityAndDataPolicy')}
          text={t('SecurityAndDataPolicyText')}
        />
      </CommonSection>
      <CommonSection>
        <div className='mb-10'>
          <img src={SecurityBanner} alt="Banner" />
        </div>
      </CommonSection>
    </>
  );
};

export default HeroSection;
