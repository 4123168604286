import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RightArrow } from '../assets/Icons/Products/ProductsIcon';
import { useTranslation } from 'react-i18next';

const LearnMoreComp = ({ link, fontSize, fontWeight, marginTop }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div
      className="flex items-center mt-4 gap-2 cursor-pointer w-max"
      onClick={() => navigate(link)}
      style={{ marginTop: marginTop ? marginTop : '' }}
    >
      <p
        className="text-headingNormal font-DMSansFont font-medium text-ButtonPrimary"
        style={{
          fontWeight: fontWeight ? fontWeight : '',
          fontSize: fontSize ? fontSize : '',
        }}
      >
        {t('Learnmore')}
      </p>
      <div>
        <RightArrow color="#00A0FC" />
      </div>
    </div>
  );
};

export default LearnMoreComp;
