import React, { useEffect } from 'react'
import Layout from '../Components/Layout'
import GetInTouchContent from '../Components/GetInTouch/GetInTouchContent'
import TitleAndDesc from '../Components/Helmet/TitleAndDesc';

const GetInTouch = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TitleAndDesc
        title="Contact Us | DCDial"
        description="Have questions or need support? Get in touch with DCDial's dedicated customer service team. We're here to help you with any inquiries or assistance you may need."
      />
      <Layout>
        <GetInTouchContent />
      </Layout>
    </>
  );
}

export default GetInTouch