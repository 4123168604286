import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BulletPoints from '../../BulletPoints';

const Annex1 = ({ title, id, handleOnClick }) => {
  const { t } = useTranslation();
  const [ListParties, setListParties] = useState({
    AParties: [],
    BParties: [],
  });
  useEffect(() => {
    setListParties((prevItems) => ({
      ...prevItems,
      AParties: getTranslatedList(t, 'a_parties'),
      BParties: getTranslatedList(t, 'b_parties'),
    }));
  }, [t]);
  return (
    <div className="mt-[30px] flex flex-col" id={id}>
      <p className="text22">{title}</p>
      <div className="flex flex-col gap-4 mt-4">
        <p className="paragraphText text-[18px] font-bold">
          {t('AListParties')}
        </p>
        {ListParties.AParties.map((parties, index) => (
          <div key={index} className="flex flex-col gap-4">
            <p className="smallText16Normal">{parties.name}</p>
            {parties.text.map((points, index2) => (
              <BulletPoints
                data={points}
                key={index2}
                pl="12px"
                bgColor="#8C90A5"
              />
            ))}
          </div>
        ))}
        <p className="paragraphText text-[18px] font-bold">
          {t('BListParties')}
        </p>
        {ListParties.BParties.map((desc, index3) => (
          <React.Fragment key={index3}>
            <div className="flex flex-col gap-4">
              <BulletPoints data={desc.text} pl="12px" />
              <BulletPoints data={desc.subText.text} pl="26px" />

              {desc.subText.list && (
                <>
                  {desc.subText.list.map((subList, index4) => (
                    <div className="flex gap-1 pl-10" key={index4}>
                      <p className="w-4 smallText16Normal font-medium">
                        {index4 + 1}.
                      </p>
                      <p
                        className="w-[calc(100%-20px)] smallText16Normal"
                        dangerouslySetInnerHTML={{ __html: subList }}
                        onClick={handleOnClick}
                      >
                        {/* {subList} */}
                      </p>
                    </div>
                  ))}
                </>
              )}
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
const getTranslatedList = (t, key) => {
  const lists = {
    a_parties: [
      {
        name: t('AListParties_1'),
        text: [
          t('Export_1'),
          t('Export_2'),
          t('Export_3'),
          t('Export_4'),
          t('Export_5'),
        ],
      },
      {
        name: t('AListParties_2'),
        text: [
          t('Import_1'),
          t('Import_2'),
          t('Import_3'),
          t('Import_4'),
          t('Import_5'),
        ],
      },
    ],
    b_parties: [
      {
        text: t('Des_1'),
        subText: {
          text: t('Des_1_sub'),
          list: [t('Des_1_sub_in1')],
        },
      },
      {
        text: t('Des_2'),
        subText: {
          text: t('Des_2_sub'),
          list: [t('Des_2_sub_in1'), t('Des_2_sub_in2')],
        },
      },
      {
        text: t('Des_3'),
        subText: {
          text: t('Des_3_sub'),
        },
      },
      {
        text: t('Des_4'),
        subText: {
          text: t('Des_4_sub'),
        },
      },
      {
        text: t('Des_5'),
        subText: {
          text: t('Des_5_sub'),
          list: [t('Des_5_sub_in1'), t('Des_5_sub_in2')],
        },
      },
      {
        text: t('Des_6'),
        subText: {
          text: t('Des_6_sub'),
        },
      },
      {
        text: t('Des_7'),
        subText: {
          text: t('Des_7_sub'),
        },
      },
    ],
  };

  return lists[key];
};

export default Annex1;
