import React, { useEffect, useState } from 'react';
import CommonSizeContainer from '../../CommonSizeContainer';
// import VirtualAssistantImg from '../../../assets/images/Products/VirtualAssitant.svg';
import { useTranslation } from 'react-i18next';
import { TickMarkIcon } from '../../../assets/Icons/Features/FeatureIcon';
import { ButtonFilledArrow } from '../../utils/Button';
import BtnArrowEn from '../../../assets/images/Homepage/ButtonArrowEn.svg';

const VirtualAssistant = () => {
  const { t } = useTranslation();
  const [virtualAssistantD, setVirtualAssistantD] = useState([]);
  const [voicebot, setVoicebot] = useState([]);
  useEffect(() => {
    setVirtualAssistantD(getTranslatedList(t, 'virtual_assistant'));
    setVoicebot(getTranslatedList(t, 'voicebot'));
  }, [t]);
  return (
    <>
      <CommonSizeContainer>
        <div
          className="flex justify-between max_xl:gap-[100px] max_md:gap-10 max_md:flex-col-reverse"
          id="VirtualAssistantsId"
        >
          <div className="flex justify-center">
            <img
              src="https://images.dcdial.com/dcdial.com/v2/VirtualAssitant.svg"
              alt="VirtualAssistant"
            />
          </div>
          <div className="max-w-[450px]">
            <p className="text50Product">{t('VirtualAssistant')}</p>
            <p className="paragraphText mt-6">{t('VirtualAssistantT')}</p>
            <div className="max_md:hidden max_sm:block">
              <div className="mt-10 max_xl:mt-8 flex flex-col gap-5 max_sm:gap-4 max_sm:mt-5 ">
                {virtualAssistantD?.map((feature, index1) => (
                  <div key={index1} className="flex gap-3 ">
                    <div className="mt-[2px] max_sm:mt-0">
                      <TickMarkIcon color="#00A0FC" />
                    </div>
                    <p className="subParagraphText text-LightPrimary">
                      {feature}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className="hidden max_md:block max_sm:hidden">
              <div className="flex gap-[60px] max_600:gap-5">
                <div className="mt-10 max_xl:mt-8 flex flex-col gap-5 max_sm:gap-4 max_sm:mt-5">
                  {virtualAssistantD?.slice(0, 3).map((feature, index1) => (
                    <div key={index1} className="flex gap-3 w-[210px]">
                      <div className="mt-[2px] max_sm:mt-0">
                        <TickMarkIcon color="#00A0FC" />
                      </div>
                      <p className="subParagraphText text-[18px] text-LightPrimary">
                        {feature}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="mt-10 max_xl:mt-8 flex flex-col gap-5 max_sm:gap-4 max_sm:mt-5">
                  {virtualAssistantD?.slice(3, 6).map((feature, index1) => (
                    <div key={index1} className="flex gap-3 w-[230px]">
                      <div className="mt-[2px] max_sm:mt-0">
                        <TickMarkIcon color="#A9CFFF" />
                      </div>
                      <p className="subParagraphText text-[18px] text-LightPrimary">
                        {feature}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </CommonSizeContainer>

      <div
        className="mt-[150px] max_xl:mt-[100px] max_sm:mt-[60px]"
        id="Voicebots"
      >
        <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] max_md:px-10 max_sm:px-5">
          <h2 className="text50Product text-center">{t('Voicebots')}</h2>
          <p className="paragraphText mt-6 text-center">{t('VoicebotsText')}</p>
        </div>
        <div className="mt-[60px] max_sm:mt-10 flex max-w-[1440px] m-auto max_md:flex-col max_md:max-w-[480px] max_sm:max-w-full">
          {voicebot.map((items, index2) => (
            <div
              className={`p-[50px] max_1440:p-[40px] max_xl:p-[30px] flex flex-col gap-3 border-[1px] border-LightOutline ${
                index2 === 1
                  ? 'border-l-[0px] max_md:border-l-[1px] border-r-[0px] max_md:border-r-[1px] border-t-[1px] max_md:border-t-[0px] border-b-[1px] max_md:border-b-[0px]'
                  : 'border-[1px]'
              }`}
              key={index2}
            >
              <p className="smallText18 text-ButtonPrimary font-medium">
                {items.id}.
              </p>
              <p className="subHeadingText max_xl:min-h-[50px] max_md:min-h-0 leading-[25px]">
                {items.name}
              </p>
              <p className="smallText18 font-medium text-LightTextParagraph">
                {items.text}
              </p>
              <div className="mt-7 w-max">
                <ButtonFilledArrow
                  title={items.buttonText}
                  image={BtnArrowEn}
                  padding="12px"
                  fontSize="16px"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
const getTranslatedList = (t, listKey) => {
  const lists = {
    virtual_assistant: [
      t('EmailAssistant'),
      t('MeetingScheduler'),
      t('LiveChat'),
      t('WebsiteChatbot'),
      t('SMSAssistant'),
      t('ReportsInsights'),
    ],
    voicebot: [
      {
        id: '01',
        name: t('NaturalEngagement'),
        text: t('NaturalEngagementT'),
        buttonText: t('HowDoesNLU'),
      },
      {
        id: '02',
        name: t('SelfCapabilities'),
        text: t('SelfCapabilitiesText'),
        buttonText: t('Learnmore'),
      },
      {
        id: '03',
        name: t('EnhancedExperience'),
        text: t('EnhancedExperienceT'),
        buttonText: t('WhatVoicebot'),
      },
    ],
  };

  return lists[listKey];
};

export default VirtualAssistant;
