import React from 'react'

const TagsComp = ({ px="16px", py="8px", text, borderColor, color, bgColor }) => {
  return (
    <div
      className="w-max subParagraphText max_sm:text-textExtraSmall rounded-[30px] border-[1px] border-ButtonPrimary text-ButtonPrimary py-2 px-4"
      style={{
        paddingLeft: px,
        paddingRight: px,
        paddingTop: py,
        paddingBottom: py,
        borderColor: borderColor,
        color: color,
        backgroundColor: bgColor ? bgColor : '',
      }}
    >
      {text}
    </div>
  );
};

export default TagsComp