import React from 'react';
import { useTranslation } from 'react-i18next';
import TagsComp from '../../utils/TagsComp';
// import ContactTracingImg from '../../../assets/images/Products/ContactTracking.svg'
// import ContactTracingResImg from '../../../assets/images/Products/ContactTrackingRes1.svg'
const ContactActivityTrack = () => {
  const { t } = useTranslation();
  return (
    <>
      <div
        id="Tracking"
        className="relative clip-path-class mt-[150px] m-auto max-w-[1920px] max_xl:mt-[100px] max_sm:mt-[60px] bg-LightBgSecondary"
      >
        <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] py-[150px] max_xl:py-[120px] max_600:py-20 max_sm:py-16 max_md:px-10 max_sm:px-5 max_md:flex max_md:flex-col max_md:gap-[60px] max_sm:gap-10">
          <div className="flex flex-col gap-6 max-w-[450px] max_xl:max-w-[450px] max_md:max-w-full max_md:gap-4">
            <TagsComp
              px="16px"
              py="8px"
              text={t('Features')}
              color="#00A0FC"
              borderColor="#00A0FC"
            />
            <h2 className="text50Product max_md:mt-[14px] max_sm:mt-0">
              {t('ContactTracking')}
            </h2>
            <p className="paragraphText">{t('ActivityTrackT')}</p>
          </div>

          <div className="hidden max_md:block w-full">
            <img
              src="https://images.dcdial.com/dcdial.com/v2/ContactTrackingRes.svg"
              alt="ContactTracing"
              className="w-full"
            />
          </div>
        </div>

        <div className="absolute bottom-10 right-0 max_xl:w-[53%] max_xl1160:w-[55%] max_xl1160:bottom-14 max_md:hidden">
          <img
            src="https://images.dcdial.com/dcdial.com/v2/ContactTracking.svg"
            alt="ContactTracing"
            className="w-full"
          />
        </div>
      </div>
    </>
  );
};

export default ContactActivityTrack;
