import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OnlinePayment from '../../assets/images/Security/OnlinePayment.svg';
import Circle from '../../assets/images/Security/Circle.svg';
import TickMark from '../../assets/images/Security/tick-circle.svg';
import { ButtonFilledArrow } from '../utils/Button';
import BtnArrowEn from '../../assets/images/Homepage/ButtonArrowEn.svg';

const OnlinePaymentSecurity = () => {
  const { t } = useTranslation();
  const [onlinePService] = useState([
    t('OnlinePService1'),
    t('OnlinePService2'),
    t('OnlinePService3'),
    t('OnlinePService4'),
    t('OnlinePService5'),
    t('OnlinePService6'),
  ]);

  return (
    <>
      <div className="max-w-[1920px] w-full m-auto mt-10 max_md:hidden">
        <div className="relative max-w-[1240px] max_xl:max-w-[980px] m-auto max_md:px-10 max_sm:px-5 flex justify-between items-center">
          <div className="w-[50%] max-w-[630px]"></div>
          <div className="flex flex-col gap-6 max-w-[550px] mt-2 max_xl:mt-0">
            <p className="subParagraphText text-ButtonPrimary max_md:text-headingNormal max_sm:text-textSmall">
              {t('OnlinePaymentsSecurity')}
            </p>
            <p className="text50">{t('DCDialPCI-DSS')}</p>
            <p className="paragraphText font-normal">
              {t('DCDialPCI-DSS-Text')}
            </p>
            <div className="flex flex-col gap-4">
              {onlinePService.map((service, index) => (
                <div className="flex items-center gap-2" key={index}>
                  <div>
                    <img src={TickMark} alt="Tickmark" />
                  </div>
                  <p className="smallText18 text-LightPrimary font-medium">
                    {service}
                  </p>
                </div>
              ))}
            </div>
            <a
              href="https://www.pcisecuritystandards.org/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <ButtonFilledArrow
                title={t('LearnMore')}
                background="#1678F2"
                image={BtnArrowEn}
                maxWidth="160px"
                padding="12px"
                fontSize="16px"
              />
            </a>
          </div>

          <div className="absolute w-[65%] max-w-[780px] top-0 left-[-380px] max_1440:left-[-280px]">
            <img src={OnlinePayment} alt="service" className="w-full h-full" />
          </div>
        </div>
      </div>
      <div className="hidden max_md:block mt-10">
        <div className="flex flex-col gap-[60px] max_sm:gap-10">
          {/* <div className="w-[80%] ml-[-90px] max_sm:ml-[-50px] max_sm:w-[90%]">
            <img src={OnlinePayment} alt="service" className="w-full h-full" />
          </div> */}
          <div className="flex flex-col gap-6 mt-2 px-10 max_sm:px-5">
            <p className="subParagraphText text-ButtonPrimary max_md:text-headingNormal max_sm:text-textSmall">
              {t('OnlinePaymentsSecurity')}
            </p>
            <p className="text50">{t('DCDialPCI-DSS')}</p>
            <p className="paragraphText font-normal max_md:text-[20px] max_sm:text-textSmall">
              {t('DCDialPCI-DSS-Text')}
            </p>
            <div className="flex justify-between gap-5">
              <div className="flex flex-col gap-4 w-[60%] max_600:w-full">
                {onlinePService.map((service, index) => (
                  <div className="flex gap-2" key={index}>
                    <div className="w-[28px] mt-[2px]">
                      <img src={TickMark} alt="Tickmark" />
                    </div>
                    <p className="w-[calc(100vw-36px)] smallText18 text-LightPrimary font-medium">
                      {service}
                    </p>
                  </div>
                ))}
              </div>
              <div className="w-[40%] flex justify-center items-center max_600:hidden">
                <img src={Circle} alt="service" className="w-full" />
              </div>
            </div>
            <a
              href="https://www.pcisecuritystandards.org/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <ButtonFilledArrow
                title={t('LearnMore')}
                background="#1678F2"
                image={BtnArrowEn}
                maxWidth="160px"
                padding="12px"
                fontSize="16px"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnlinePaymentSecurity;
