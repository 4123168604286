const StateList = {
  AF: {
    name: 'Afghanistan',
    type: null,
    states: [
      {
        name: 'Badakhshan',
        value: 'Badakhshan',
      },
      {
        name: 'Badghis',
        value: 'Badghis',
      },
      {
        name: 'Baghlan',
        value: 'Baghlan',
      },
      {
        name: 'Balkh',
        value: 'Balkh',
      },
      {
        name: 'Bamyan',
        value: 'Bamyan',
      },
      {
        name: 'Daykundi',
        value: 'Daykundi',
      },
      {
        name: 'Farah',
        value: 'Farah',
      },
      {
        name: 'Faryab',
        value: 'Faryab',
      },
      {
        name: 'Ghazni',
        value: 'Ghazni',
      },
      {
        name: 'Ghōr',
        value: 'Ghōr',
      },
      {
        name: 'Helmand',
        value: 'Helmand',
      },
      {
        name: 'Herat',
        value: 'Herat',
      },
      {
        name: 'Jowzjan',
        value: 'Jowzjan',
      },
      {
        name: 'Kabul',
        value: 'Kabul',
      },
      {
        name: 'Kandahar',
        value: 'Kandahar',
      },
      {
        name: 'Kapisa',
        value: 'Kapisa',
      },
      {
        name: 'Khost',
        value: 'Khost',
      },
      {
        name: 'Kunar',
        value: 'Kunar',
      },
      {
        name: 'Kunduz Province',
        value: 'Kunduz Province',
      },
      {
        name: 'Laghman',
        value: 'Laghman',
      },
      {
        name: 'Logar',
        value: 'Logar',
      },
      {
        name: 'Nangarhar',
        value: 'Nangarhar',
      },
      {
        name: 'Nimruz',
        value: 'Nimruz',
      },
      {
        name: 'Nuristan',
        value: 'Nuristan',
      },
      {
        name: 'Paktia',
        value: 'Paktia',
      },
      {
        name: 'Paktika',
        value: 'Paktika',
      },
      {
        name: 'Panjshir',
        value: 'Panjshir',
      },
      {
        name: 'Parwan',
        value: 'Parwan',
      },
      {
        name: 'Samangan',
        value: 'Samangan',
      },
      {
        name: 'Sar-e Pol',
        value: 'Sar-e Pol',
      },
      {
        name: 'Takhar',
        value: 'Takhar',
      },
      {
        name: 'Urozgan',
        value: 'Urozgan',
      },
      {
        name: 'Zabul',
        value: 'Zabul',
      },
    ],
  },
  AL: {
    name: 'Albania',
    type: null,
    states: [
      {
        name: 'Berat County',
        value: 'Berat County',
      },
      {
        name: 'Berat District',
        value: 'Berat District',
      },
      {
        name: 'Bulqizë District',
        value: 'Bulqizë District',
      },
      {
        name: 'Delvinë District',
        value: 'Delvinë District',
      },
      {
        name: 'Devoll District',
        value: 'Devoll District',
      },
      {
        name: 'Dibër County',
        value: 'Dibër County',
      },
      {
        name: 'Dibër District',
        value: 'Dibër District',
      },
      {
        name: 'Durrës County',
        value: 'Durrës County',
      },
      {
        name: 'Durrës District',
        value: 'Durrës District',
      },
      {
        name: 'Elbasan County',
        value: 'Elbasan County',
      },
      {
        name: 'Fier County',
        value: 'Fier County',
      },
      {
        name: 'Fier District',
        value: 'Fier District',
      },
      {
        name: 'Gjirokastër County',
        value: 'Gjirokastër County',
      },
      {
        name: 'Gjirokastër District',
        value: 'Gjirokastër District',
      },
      {
        name: 'Gramsh District',
        value: 'Gramsh District',
      },
      {
        name: 'Has District',
        value: 'Has District',
      },
      {
        name: 'Kavajë District',
        value: 'Kavajë District',
      },
      {
        name: 'Kolonjë District',
        value: 'Kolonjë District',
      },
      {
        name: 'Korçë County',
        value: 'Korçë County',
      },
      {
        name: 'Korçë District',
        value: 'Korçë District',
      },
      {
        name: 'Krujë District',
        value: 'Krujë District',
      },
      {
        name: 'Kuçovë District',
        value: 'Kuçovë District',
      },
      {
        name: 'Kukës County',
        value: 'Kukës County',
      },
      {
        name: 'Kukës District',
        value: 'Kukës District',
      },
      {
        name: 'Kurbin District',
        value: 'Kurbin District',
      },
      {
        name: 'Lezhë County',
        value: 'Lezhë County',
      },
      {
        name: 'Lezhë District',
        value: 'Lezhë District',
      },
      {
        name: 'Librazhd District',
        value: 'Librazhd District',
      },
      {
        name: 'Lushnjë District',
        value: 'Lushnjë District',
      },
      {
        name: 'Malësi e Madhe District',
        value: 'Malësi e Madhe District',
      },
      {
        name: 'Mallakastër District',
        value: 'Mallakastër District',
      },
      {
        name: 'Mat District',
        value: 'Mat District',
      },
      {
        name: 'Mirditë District',
        value: 'Mirditë District',
      },
      {
        name: 'Peqin District',
        value: 'Peqin District',
      },
      {
        name: 'Përmet District',
        value: 'Përmet District',
      },
      {
        name: 'Pogradec District',
        value: 'Pogradec District',
      },
      {
        name: 'Pukë District',
        value: 'Pukë District',
      },
      {
        name: 'Sarandë District',
        value: 'Sarandë District',
      },
      {
        name: 'Shkodër County',
        value: 'Shkodër County',
      },
      {
        name: 'Shkodër District',
        value: 'Shkodër District',
      },
      {
        name: 'Skrapar District',
        value: 'Skrapar District',
      },
      {
        name: 'Tepelenë District',
        value: 'Tepelenë District',
      },
      {
        name: 'Tirana County',
        value: 'Tirana County',
      },
      {
        name: 'Tirana District',
        value: 'Tirana District',
      },
      {
        name: 'Tropojë District',
        value: 'Tropojë District',
      },
      {
        name: 'Vlorë County',
        value: 'Vlorë County',
      },
      {
        name: 'Vlorë District',
        value: 'Vlorë District',
      },
    ],
  },
  DZ: {
    name: 'Algeria',
    type: null,
    states: [
      {
        name: 'Adrar',
        value: 'Adrar',
      },
      {
        name: 'Aïn Defla',
        value: 'Aïn Defla',
      },
      {
        name: 'Aïn Témouchent',
        value: 'Aïn Témouchent',
      },
      {
        name: 'Algiers',
        value: 'Algiers',
      },
      {
        name: 'Annaba',
        value: 'Annaba',
      },
      {
        name: 'Batna',
        value: 'Batna',
      },
      {
        name: 'Béchar',
        value: 'Béchar',
      },
      {
        name: 'Béjaïa',
        value: 'Béjaïa',
      },
      {
        name: 'Béni Abbès',
        value: 'Béni Abbès',
      },
      {
        name: 'Biskra',
        value: 'Biskra',
      },
      {
        name: 'Blida',
        value: 'Blida',
      },
      {
        name: 'Bordj Baji Mokhtar',
        value: 'Bordj Baji Mokhtar',
      },
      {
        name: 'Bordj Bou Arréridj',
        value: 'Bordj Bou Arréridj',
      },
      {
        name: 'Bouïra',
        value: 'Bouïra',
      },
      {
        name: 'Boumerdès',
        value: 'Boumerdès',
      },
      {
        name: 'Chlef',
        value: 'Chlef',
      },
      {
        name: 'Constantine',
        value: 'Constantine',
      },
      {
        name: 'Djanet',
        value: 'Djanet',
      },
      {
        name: 'Djelfa',
        value: 'Djelfa',
      },
      {
        name: 'El Bayadh',
        value: 'El Bayadh',
      },
      {
        name: "El M'ghair",
        value: "El M'ghair",
      },
      {
        name: 'El Menia',
        value: 'El Menia',
      },
      {
        name: 'El Oued',
        value: 'El Oued',
      },
      {
        name: 'El Tarf',
        value: 'El Tarf',
      },
      {
        name: 'Ghardaïa',
        value: 'Ghardaïa',
      },
      {
        name: 'Guelma',
        value: 'Guelma',
      },
      {
        name: 'Illizi',
        value: 'Illizi',
      },
      {
        name: 'In Guezzam',
        value: 'In Guezzam',
      },
      {
        name: 'In Salah',
        value: 'In Salah',
      },
      {
        name: 'Jijel',
        value: 'Jijel',
      },
      {
        name: 'Khenchela',
        value: 'Khenchela',
      },
      {
        name: 'Laghouat',
        value: 'Laghouat',
      },
      {
        name: "M'Sila",
        value: "M'Sila",
      },
      {
        name: 'Mascara',
        value: 'Mascara',
      },
      {
        name: 'Médéa',
        value: 'Médéa',
      },
      {
        name: 'Mila',
        value: 'Mila',
      },
      {
        name: 'Mostaganem',
        value: 'Mostaganem',
      },
      {
        name: 'Naama',
        value: 'Naama',
      },
      {
        name: 'Oran',
        value: 'Oran',
      },
      {
        name: 'Ouargla',
        value: 'Ouargla',
      },
      {
        name: 'Ouled Djellal',
        value: 'Ouled Djellal',
      },
      {
        name: 'Oum El Bouaghi',
        value: 'Oum El Bouaghi',
      },
      {
        name: 'Relizane',
        value: 'Relizane',
      },
      {
        name: 'Saïda',
        value: 'Saïda',
      },
      {
        name: 'Sétif',
        value: 'Sétif',
      },
      {
        name: 'Sidi Bel Abbès',
        value: 'Sidi Bel Abbès',
      },
      {
        name: 'Skikda',
        value: 'Skikda',
      },
      {
        name: 'Souk Ahras',
        value: 'Souk Ahras',
      },
      {
        name: 'Tamanghasset',
        value: 'Tamanghasset',
      },
      {
        name: 'Tébessa',
        value: 'Tébessa',
      },
      {
        name: 'Tiaret',
        value: 'Tiaret',
      },
      {
        name: 'Timimoun',
        value: 'Timimoun',
      },
      {
        name: 'Tindouf',
        value: 'Tindouf',
      },
      {
        name: 'Tipasa',
        value: 'Tipasa',
      },
      {
        name: 'Tissemsilt',
        value: 'Tissemsilt',
      },
      {
        name: 'Tizi Ouzou',
        value: 'Tizi Ouzou',
      },
      {
        name: 'Tlemcen',
        value: 'Tlemcen',
      },
      {
        name: 'Touggourt',
        value: 'Touggourt',
      },
    ],
  },
  AD: {
    name: 'Andorra',
    type: null,
    states: [
      {
        name: 'Andorra la Vella',
        value: 'Andorra la Vella',
      },
      {
        name: 'Canillo',
        value: 'Canillo',
      },
      {
        name: 'Encamp',
        value: 'Encamp',
      },
      {
        name: 'Escaldes-Engordany',
        value: 'Escaldes-Engordany',
      },
      {
        name: 'La Massana',
        value: 'La Massana',
      },
      {
        name: 'Ordino',
        value: 'Ordino',
      },
      {
        name: 'Sant Julià de Lòria',
        value: 'Sant Julià de Lòria',
      },
    ],
  },
  AO: {
    name: 'Angola',
    type: null,
    states: [
      {
        name: 'Bengo Province',
        value: 'Bengo Province',
      },
      {
        name: 'Benguela Province',
        value: 'Benguela Province',
      },
      {
        name: 'Bié Province',
        value: 'Bié Province',
      },
      {
        name: 'Cabinda Province',
        value: 'Cabinda Province',
      },
      {
        name: 'Cuando Cubango Province',
        value: 'Cuando Cubango Province',
      },
      {
        name: 'Cuanza Norte Province',
        value: 'Cuanza Norte Province',
      },
      {
        name: 'Cuanza Sul',
        value: 'Cuanza Sul',
      },
      {
        name: 'Cunene Province',
        value: 'Cunene Province',
      },
      {
        name: 'Huambo Province',
        value: 'Huambo Province',
      },
      {
        name: 'Huíla Province',
        value: 'Huíla Province',
      },
      {
        name: 'Luanda Province',
        value: 'Luanda Province',
      },
      {
        name: 'Lunda Norte Province',
        value: 'Lunda Norte Province',
      },
      {
        name: 'Lunda Sul Province',
        value: 'Lunda Sul Province',
      },
      {
        name: 'Malanje Province',
        value: 'Malanje Province',
      },
      {
        name: 'Moxico Province',
        value: 'Moxico Province',
      },
      {
        name: 'Uíge Province',
        value: 'Uíge Province',
      },
      {
        name: 'Zaire Province',
        value: 'Zaire Province',
      },
    ],
  },
  AG: {
    name: 'Antigua and Barbuda',
    type: null,
    states: [
      {
        name: 'Barbuda',
        value: 'Barbuda',
      },
      {
        name: 'Redonda',
        value: 'Redonda',
      },
      {
        name: 'Saint George Parish',
        value: 'Saint George Parish',
      },
      {
        name: 'Saint John Parish',
        value: 'Saint John Parish',
      },
      {
        name: 'Saint Mary Parish',
        value: 'Saint Mary Parish',
      },
      {
        name: 'Saint Paul Parish',
        value: 'Saint Paul Parish',
      },
      {
        name: 'Saint Peter Parish',
        value: 'Saint Peter Parish',
      },
      {
        name: 'Saint Philip Parish',
        value: 'Saint Philip Parish',
      },
    ],
  },
  AR: {
    name: 'Argentina',
    type: 'province',
    states: [
      {
        name: 'Buenos Aires',
        value: 'Buenos Aires',
      },
      {
        name: 'Catamarca',
        value: 'Catamarca',
      },
      {
        name: 'Chaco',
        value: 'Chaco',
      },
      {
        name: 'Chubut',
        value: 'Chubut',
      },
      {
        name: 'Ciudad Autónoma de Buenos Aires',
        value: 'Ciudad Autónoma de Buenos Aires',
      },
      {
        name: 'Córdoba',
        value: 'Córdoba',
      },
      {
        name: 'Corrientes',
        value: 'Corrientes',
      },
      {
        name: 'Entre Ríos',
        value: 'Entre Ríos',
      },
      {
        name: 'Formosa',
        value: 'Formosa',
      },
      {
        name: 'Jujuy',
        value: 'Jujuy',
      },
      {
        name: 'La Pampa',
        value: 'La Pampa',
      },
      {
        name: 'La Rioja',
        value: 'La Rioja',
      },
      {
        name: 'Mendoza',
        value: 'Mendoza',
      },
      {
        name: 'Misiones',
        value: 'Misiones',
      },
      {
        name: 'Neuquén',
        value: 'Neuquén',
      },
      {
        name: 'Río Negro',
        value: 'Río Negro',
      },
      {
        name: 'Salta',
        value: 'Salta',
      },
      {
        name: 'San Juan',
        value: 'San Juan',
      },
      {
        name: 'San Luis',
        value: 'San Luis',
      },
      {
        name: 'Santa Cruz',
        value: 'Santa Cruz',
      },
      {
        name: 'Santa Fe',
        value: 'Santa Fe',
      },
      {
        name: 'Santiago del Estero',
        value: 'Santiago del Estero',
      },
      {
        name: 'Tierra del Fuego',
        value: 'Tierra del Fuego',
      },
      {
        name: 'Tucumán',
        value: 'Tucumán',
      },
    ],
  },
  AM: {
    name: 'Armenia',
    type: null,
    states: [
      {
        name: 'Aragatsotn Region',
        value: 'Aragatsotn Region',
      },
      {
        name: 'Ararat Province',
        value: 'Ararat Province',
      },
      {
        name: 'Armavir Region',
        value: 'Armavir Region',
      },
      {
        name: 'Gegharkunik Province',
        value: 'Gegharkunik Province',
      },
      {
        name: 'Kotayk Region',
        value: 'Kotayk Region',
      },
      {
        name: 'Lori Region',
        value: 'Lori Region',
      },
      {
        name: 'Shirak Region',
        value: 'Shirak Region',
      },
      {
        name: 'Syunik Province',
        value: 'Syunik Province',
      },
      {
        name: 'Tavush Region',
        value: 'Tavush Region',
      },
      {
        name: 'Vayots Dzor Region',
        value: 'Vayots Dzor Region',
      },
      {
        name: 'Yerevan',
        value: 'Yerevan',
      },
    ],
  },
  AU: {
    name: 'Australia',
    type: 'state',
    states: [
      {
        name: 'Australian Capital Territory',
        value: 'Australian Capital Territory',
      },
      {
        name: 'New South Wales',
        value: 'New South Wales',
      },
      {
        name: 'Northern Territory',
        value: 'Northern Territory',
      },
      {
        name: 'Queensland',
        value: 'Queensland',
      },
      {
        name: 'South Australia',
        value: 'South Australia',
      },
      {
        name: 'Tasmania',
        value: 'Tasmania',
      },
      {
        name: 'Victoria',
        value: 'Victoria',
      },
      {
        name: 'Western Australia',
        value: 'Western Australia',
      },
    ],
  },
  AT: {
    name: 'Austria',
    type: null,
    states: [
      {
        name: 'Burgenland',
        value: 'Burgenland',
      },
      {
        name: 'Carinthia',
        value: 'Carinthia',
      },
      {
        name: 'Lower Austria',
        value: 'Lower Austria',
      },
      {
        name: 'Salzburg',
        value: 'Salzburg',
      },
      {
        name: 'Styria',
        value: 'Styria',
      },
      {
        name: 'Tyrol',
        value: 'Tyrol',
      },
      {
        name: 'Upper Austria',
        value: 'Upper Austria',
      },
      {
        name: 'Vienna',
        value: 'Vienna',
      },
      {
        name: 'Vorarlberg',
        value: 'Vorarlberg',
      },
    ],
  },
  AZ: {
    name: 'Azerbaijan',
    type: null,
    states: [
      {
        name: 'Absheron District',
        value: 'Absheron District',
      },
      {
        name: 'Agdam District',
        value: 'Agdam District',
      },
      {
        name: 'Agdash District',
        value: 'Agdash District',
      },
      {
        name: 'Aghjabadi District',
        value: 'Aghjabadi District',
      },
      {
        name: 'Agstafa District',
        value: 'Agstafa District',
      },
      {
        name: 'Agsu District',
        value: 'Agsu District',
      },
      {
        name: 'Astara District',
        value: 'Astara District',
      },
      {
        name: 'Babek District',
        value: 'Babek District',
      },
      {
        name: 'Baku',
        value: 'Baku',
      },
      {
        name: 'Balakan District',
        value: 'Balakan District',
      },
      {
        name: 'Barda District',
        value: 'Barda District',
      },
      {
        name: 'Beylagan District',
        value: 'Beylagan District',
      },
      {
        name: 'Bilasuvar District',
        value: 'Bilasuvar District',
      },
      {
        name: 'Dashkasan District',
        value: 'Dashkasan District',
      },
      {
        name: 'Fizuli District',
        value: 'Fizuli District',
      },
      {
        name: 'Ganja',
        value: 'Ganja',
      },
      {
        name: 'Gədəbəy',
        value: 'Gədəbəy',
      },
      {
        name: 'Gobustan District',
        value: 'Gobustan District',
      },
      {
        name: 'Goranboy District',
        value: 'Goranboy District',
      },
      {
        name: 'Goychay',
        value: 'Goychay',
      },
      {
        name: 'Goygol District',
        value: 'Goygol District',
      },
      {
        name: 'Hajigabul District',
        value: 'Hajigabul District',
      },
      {
        name: 'Imishli District',
        value: 'Imishli District',
      },
      {
        name: 'Ismailli District',
        value: 'Ismailli District',
      },
      {
        name: 'Jabrayil District',
        value: 'Jabrayil District',
      },
      {
        name: 'Jalilabad District',
        value: 'Jalilabad District',
      },
      {
        name: 'Julfa District',
        value: 'Julfa District',
      },
      {
        name: 'Kalbajar District',
        value: 'Kalbajar District',
      },
      {
        name: 'Kangarli District',
        value: 'Kangarli District',
      },
      {
        name: 'Khachmaz District',
        value: 'Khachmaz District',
      },
      {
        name: 'Khizi District',
        value: 'Khizi District',
      },
      {
        name: 'Khojali District',
        value: 'Khojali District',
      },
      {
        name: 'Kurdamir District',
        value: 'Kurdamir District',
      },
      {
        name: 'Lachin District',
        value: 'Lachin District',
      },
      {
        name: 'Lankaran',
        value: 'Lankaran',
      },
      {
        name: 'Lankaran District',
        value: 'Lankaran District',
      },
      {
        name: 'Lerik District',
        value: 'Lerik District',
      },
      {
        name: 'Martuni',
        value: 'Martuni',
      },
      {
        name: 'Masally District',
        value: 'Masally District',
      },
      {
        name: 'Mingachevir',
        value: 'Mingachevir',
      },
      {
        name: 'Nakhchivan Autonomous Republic',
        value: 'Nakhchivan Autonomous Republic',
      },
      {
        name: 'Neftchala District',
        value: 'Neftchala District',
      },
      {
        name: 'Oghuz District',
        value: 'Oghuz District',
      },
      {
        name: 'Ordubad District',
        value: 'Ordubad District',
      },
      {
        name: 'Qabala District',
        value: 'Qabala District',
      },
      {
        name: 'Qakh District',
        value: 'Qakh District',
      },
      {
        name: 'Qazakh District',
        value: 'Qazakh District',
      },
      {
        name: 'Quba District',
        value: 'Quba District',
      },
      {
        name: 'Qubadli District',
        value: 'Qubadli District',
      },
      {
        name: 'Qusar District',
        value: 'Qusar District',
      },
      {
        name: 'Saatly District',
        value: 'Saatly District',
      },
      {
        name: 'Sabirabad District',
        value: 'Sabirabad District',
      },
      {
        name: 'Sadarak District',
        value: 'Sadarak District',
      },
      {
        name: 'Salyan District',
        value: 'Salyan District',
      },
      {
        name: 'Samukh District',
        value: 'Samukh District',
      },
      {
        name: 'Shabran District',
        value: 'Shabran District',
      },
      {
        name: 'Shahbuz District',
        value: 'Shahbuz District',
      },
      {
        name: 'Shaki',
        value: 'Shaki',
      },
      {
        name: 'Shaki District',
        value: 'Shaki District',
      },
      {
        name: 'Shamakhi District',
        value: 'Shamakhi District',
      },
      {
        name: 'Shamkir District',
        value: 'Shamkir District',
      },
      {
        name: 'Sharur District',
        value: 'Sharur District',
      },
      {
        name: 'Shirvan',
        value: 'Shirvan',
      },
      {
        name: 'Shusha District',
        value: 'Shusha District',
      },
      {
        name: 'Siazan District',
        value: 'Siazan District',
      },
      {
        name: 'Sumqayit',
        value: 'Sumqayit',
      },
      {
        name: 'Tartar District',
        value: 'Tartar District',
      },
      {
        name: 'Tovuz District',
        value: 'Tovuz District',
      },
      {
        name: 'Ujar District',
        value: 'Ujar District',
      },
      {
        name: 'Yardymli District',
        value: 'Yardymli District',
      },
      {
        name: 'Yevlakh',
        value: 'Yevlakh',
      },
      {
        name: 'Yevlakh District',
        value: 'Yevlakh District',
      },
      {
        name: 'Zangilan District',
        value: 'Zangilan District',
      },
      {
        name: 'Zaqatala District',
        value: 'Zaqatala District',
      },
      {
        name: 'Zardab District',
        value: 'Zardab District',
      },
    ],
  },
  BH: {
    name: 'Bahrain',
    type: null,
    states: [
      {
        name: 'Capital',
        value: 'Capital',
      },
      {
        name: 'Central',
        value: 'Central',
      },
      {
        name: 'Muharraq',
        value: 'Muharraq',
      },
      {
        name: 'Northern',
        value: 'Northern',
      },
      {
        name: 'Southern',
        value: 'Southern',
      },
    ],
  },
  BD: {
    name: 'Bangladesh',
    type: 'district',
    states: [
      {
        name: 'Bagerhat District',
        value: 'Bagerhat District',
      },
      {
        name: 'Bahadia',
        value: 'Bahadia',
      },
      {
        name: 'Bandarban District',
        value: 'Bandarban District',
      },
      {
        name: 'Barguna District',
        value: 'Barguna District',
      },
      {
        name: 'Barisal District',
        value: 'Barisal District',
      },
      {
        name: 'Barisal Division',
        value: 'Barisal Division',
      },
      {
        name: 'Bhola District',
        value: 'Bhola District',
      },
      {
        name: 'Bogra District',
        value: 'Bogra District',
      },
      {
        name: 'Brahmanbaria District',
        value: 'Brahmanbaria District',
      },
      {
        name: 'Chandpur District',
        value: 'Chandpur District',
      },
      {
        name: 'Chapai Nawabganj District',
        value: 'Chapai Nawabganj District',
      },
      {
        name: 'Chittagong District',
        value: 'Chittagong District',
      },
      {
        name: 'Chittagong Division',
        value: 'Chittagong Division',
      },
      {
        name: 'Chuadanga District',
        value: 'Chuadanga District',
      },
      {
        name: 'Comilla District',
        value: 'Comilla District',
      },
      {
        name: "Cox's Bazar District",
        value: "Cox's Bazar District",
      },
      {
        name: 'Dhaka District',
        value: 'Dhaka District',
      },
      {
        name: 'Dhaka Division',
        value: 'Dhaka Division',
      },
      {
        name: 'Dinajpur District',
        value: 'Dinajpur District',
      },
      {
        name: 'Faridpur District',
        value: 'Faridpur District',
      },
      {
        name: 'Feni District',
        value: 'Feni District',
      },
      {
        name: 'Gaibandha District',
        value: 'Gaibandha District',
      },
      {
        name: 'Gazipur District',
        value: 'Gazipur District',
      },
      {
        name: 'Gopalganj District',
        value: 'Gopalganj District',
      },
      {
        name: 'Habiganj District',
        value: 'Habiganj District',
      },
      {
        name: 'Jamalpur District',
        value: 'Jamalpur District',
      },
      {
        name: 'Jessore District',
        value: 'Jessore District',
      },
      {
        name: 'Jhalokati District',
        value: 'Jhalokati District',
      },
      {
        name: 'Jhenaidah District',
        value: 'Jhenaidah District',
      },
      {
        name: 'Joypurhat District',
        value: 'Joypurhat District',
      },
      {
        name: 'Khagrachari District',
        value: 'Khagrachari District',
      },
      {
        name: 'Khulna District',
        value: 'Khulna District',
      },
      {
        name: 'Khulna Division',
        value: 'Khulna Division',
      },
      {
        name: 'Kishoreganj District',
        value: 'Kishoreganj District',
      },
      {
        name: 'Kurigram District',
        value: 'Kurigram District',
      },
      {
        name: 'Kushtia District',
        value: 'Kushtia District',
      },
      {
        name: 'Lakshmipur District',
        value: 'Lakshmipur District',
      },
      {
        name: 'Lalmonirhat District',
        value: 'Lalmonirhat District',
      },
      {
        name: 'Madaripur District',
        value: 'Madaripur District',
      },
      {
        name: 'Meherpur District',
        value: 'Meherpur District',
      },
      {
        name: 'Moulvibazar District',
        value: 'Moulvibazar District',
      },
      {
        name: 'Munshiganj District',
        value: 'Munshiganj District',
      },
      {
        name: 'Mymensingh District',
        value: 'Mymensingh District',
      },
      {
        name: 'Mymensingh Division',
        value: 'Mymensingh Division',
      },
      {
        name: 'Naogaon District',
        value: 'Naogaon District',
      },
      {
        name: 'Narail District',
        value: 'Narail District',
      },
      {
        name: 'Narayanganj District',
        value: 'Narayanganj District',
      },
      {
        name: 'Natore District',
        value: 'Natore District',
      },
      {
        name: 'Netrokona District',
        value: 'Netrokona District',
      },
      {
        name: 'Nilphamari District',
        value: 'Nilphamari District',
      },
      {
        name: 'Noakhali District',
        value: 'Noakhali District',
      },
      {
        name: 'Pabna District',
        value: 'Pabna District',
      },
      {
        name: 'Panchagarh District',
        value: 'Panchagarh District',
      },
      {
        name: 'Patuakhali District',
        value: 'Patuakhali District',
      },
      {
        name: 'Pirojpur District',
        value: 'Pirojpur District',
      },
      {
        name: 'Rajbari District',
        value: 'Rajbari District',
      },
      {
        name: 'Rajshahi District',
        value: 'Rajshahi District',
      },
      {
        name: 'Rajshahi Division',
        value: 'Rajshahi Division',
      },
      {
        name: 'Rangamati Hill District',
        value: 'Rangamati Hill District',
      },
      {
        name: 'Rangpur District',
        value: 'Rangpur District',
      },
      {
        name: 'Rangpur Division',
        value: 'Rangpur Division',
      },
      {
        name: 'Satkhira District',
        value: 'Satkhira District',
      },
      {
        name: 'Shariatpur District',
        value: 'Shariatpur District',
      },
      {
        name: 'Sherpur District',
        value: 'Sherpur District',
      },
      {
        name: 'Sirajganj District',
        value: 'Sirajganj District',
      },
      {
        name: 'Sunamganj District',
        value: 'Sunamganj District',
      },
      {
        name: 'Sylhet District',
        value: 'Sylhet District',
      },
      {
        name: 'Sylhet Division',
        value: 'Sylhet Division',
      },
      {
        name: 'Tangail District',
        value: 'Tangail District',
      },
      {
        name: 'Thakurgaon District',
        value: 'Thakurgaon District',
      },
    ],
  },
  BB: {
    name: 'Barbados',
    type: null,
    states: [
      {
        name: 'Christ Church',
        value: 'Christ Church',
      },
      {
        name: 'Saint Andrew',
        value: 'Saint Andrew',
      },
      {
        name: 'Saint George',
        value: 'Saint George',
      },
      {
        name: 'Saint James',
        value: 'Saint James',
      },
      {
        name: 'Saint John',
        value: 'Saint John',
      },
      {
        name: 'Saint Joseph',
        value: 'Saint Joseph',
      },
      {
        name: 'Saint Lucy',
        value: 'Saint Lucy',
      },
      {
        name: 'Saint Michael',
        value: 'Saint Michael',
      },
      {
        name: 'Saint Peter',
        value: 'Saint Peter',
      },
      {
        name: 'Saint Philip',
        value: 'Saint Philip',
      },
      {
        name: 'Saint Thomas',
        value: 'Saint Thomas',
      },
    ],
  },
  BY: {
    name: 'Belarus',
    type: null,
    states: [
      {
        name: 'Brest Region',
        value: 'Brest Region',
      },
      {
        name: 'Gomel Region',
        value: 'Gomel Region',
      },
      {
        name: 'Grodno Region',
        value: 'Grodno Region',
      },
      {
        name: 'Minsk',
        value: 'Minsk',
      },
      {
        name: 'Minsk Region',
        value: 'Minsk Region',
      },
      {
        name: 'Mogilev Region',
        value: 'Mogilev Region',
      },
      {
        name: 'Vitebsk Region',
        value: 'Vitebsk Region',
      },
    ],
  },
  BE: {
    name: 'Belgium',
    type: null,
    states: [
      {
        name: 'Antwerp',
        value: 'Antwerp',
      },
      {
        name: 'Brussels-Capital Region',
        value: 'Brussels-Capital Region',
      },
      {
        name: 'East Flanders',
        value: 'East Flanders',
      },
      {
        name: 'Flanders',
        value: 'Flanders',
      },
      {
        name: 'Flemish Brabant',
        value: 'Flemish Brabant',
      },
      {
        name: 'Hainaut',
        value: 'Hainaut',
      },
      {
        name: 'Liège',
        value: 'Liège',
      },
      {
        name: 'Limburg',
        value: 'Limburg',
      },
      {
        name: 'Luxembourg',
        value: 'Luxembourg',
      },
      {
        name: 'Namur',
        value: 'Namur',
      },
      {
        name: 'Wallonia',
        value: 'Wallonia',
      },
      {
        name: 'Walloon Brabant',
        value: 'Walloon Brabant',
      },
      {
        name: 'West Flanders',
        value: 'West Flanders',
      },
    ],
  },
  BZ: {
    name: 'Belize',
    type: null,
    states: [
      {
        name: 'Belize District',
        value: 'Belize District',
      },
      {
        name: 'Cayo District',
        value: 'Cayo District',
      },
      {
        name: 'Corozal District',
        value: 'Corozal District',
      },
      {
        name: 'Orange Walk District',
        value: 'Orange Walk District',
      },
      {
        name: 'Stann Creek District',
        value: 'Stann Creek District',
      },
      {
        name: 'Toledo District',
        value: 'Toledo District',
      },
    ],
  },
  BJ: {
    name: 'Benin',
    type: null,
    states: [
      {
        name: 'Alibori Department',
        value: 'Alibori Department',
      },
      {
        name: 'Atakora Department',
        value: 'Atakora Department',
      },
      {
        name: 'Atlantique Department',
        value: 'Atlantique Department',
      },
      {
        name: 'Borgou Department',
        value: 'Borgou Department',
      },
      {
        name: 'Collines Department',
        value: 'Collines Department',
      },
      {
        name: 'Donga Department',
        value: 'Donga Department',
      },
      {
        name: 'Kouffo Department',
        value: 'Kouffo Department',
      },
      {
        name: 'Littoral Department',
        value: 'Littoral Department',
      },
      {
        name: 'Mono Department',
        value: 'Mono Department',
      },
      {
        name: 'Ouémé Department',
        value: 'Ouémé Department',
      },
      {
        name: 'Plateau Department',
        value: 'Plateau Department',
      },
      {
        name: 'Zou Department',
        value: 'Zou Department',
      },
    ],
  },
  BM: {
    name: 'Bermuda',
    type: 'municipality',
    states: [
      {
        name: 'Devonshire',
        value: 'Devonshire',
      },
      {
        name: 'Hamilton',
        value: 'Hamilton',
      },
      {
        name: 'Paget',
        value: 'Paget',
      },
      {
        name: 'Pembroke',
        value: 'Pembroke',
      },
      {
        name: "Saint George's",
        value: "Saint George's",
      },
      {
        name: 'Sandys',
        value: 'Sandys',
      },
      {
        name: "Smith's",
        value: "Smith's",
      },
      {
        name: 'Southampton',
        value: 'Southampton',
      },
      {
        name: 'Warwick',
        value: 'Warwick',
      },
    ],
  },
  BT: {
    name: 'Bhutan',
    type: null,
    states: [
      {
        name: 'Bumthang District',
        value: 'Bumthang District',
      },
      {
        name: 'Chukha District',
        value: 'Chukha District',
      },
      {
        name: 'Dagana District',
        value: 'Dagana District',
      },
      {
        name: 'Gasa District',
        value: 'Gasa District',
      },
      {
        name: 'Haa District',
        value: 'Haa District',
      },
      {
        name: 'Lhuntse District',
        value: 'Lhuntse District',
      },
      {
        name: 'Mongar District',
        value: 'Mongar District',
      },
      {
        name: 'Paro District',
        value: 'Paro District',
      },
      {
        name: 'Pemagatshel District',
        value: 'Pemagatshel District',
      },
      {
        name: 'Punakha District',
        value: 'Punakha District',
      },
      {
        name: 'Samdrup Jongkhar District',
        value: 'Samdrup Jongkhar District',
      },
      {
        name: 'Samtse District',
        value: 'Samtse District',
      },
      {
        name: 'Sarpang District',
        value: 'Sarpang District',
      },
      {
        name: 'Thimphu District',
        value: 'Thimphu District',
      },
      {
        name: 'Trashigang District',
        value: 'Trashigang District',
      },
      {
        name: 'Trongsa District',
        value: 'Trongsa District',
      },
      {
        name: 'Tsirang District',
        value: 'Tsirang District',
      },
      {
        name: 'Wangdue Phodrang District',
        value: 'Wangdue Phodrang District',
      },
      {
        name: 'Zhemgang District',
        value: 'Zhemgang District',
      },
    ],
  },
  BO: {
    name: 'Bolivia',
    type: null,
    states: [
      {
        name: 'Beni Department',
        value: 'Beni Department',
      },
      {
        name: 'Chuquisaca Department',
        value: 'Chuquisaca Department',
      },
      {
        name: 'Cochabamba Department',
        value: 'Cochabamba Department',
      },
      {
        name: 'La Paz Department',
        value: 'La Paz Department',
      },
      {
        name: 'Oruro Department',
        value: 'Oruro Department',
      },
      {
        name: 'Pando Department',
        value: 'Pando Department',
      },
      {
        name: 'Potosí Department',
        value: 'Potosí Department',
      },
      {
        name: 'Santa Cruz Department',
        value: 'Santa Cruz Department',
      },
      {
        name: 'Tarija Department',
        value: 'Tarija Department',
      },
    ],
  },
  BQ: {
    name: 'Bonaire, Sint Eustatius and Saba',
    type: 'special municipality',
    states: [
      {
        name: 'Bonaire',
        value: 'Bonaire',
      },
      {
        name: 'Saba',
        value: 'Saba',
      },
      {
        name: 'Sint Eustatius',
        value: 'Sint Eustatius',
      },
    ],
  },
  BA: {
    name: 'Bosnia and Herzegovina',
    type: null,
    states: [
      {
        name: 'Bosnian Podrinje Canton',
        value: 'Bosnian Podrinje Canton',
      },
      {
        name: 'Brčko District',
        value: 'Brčko District',
      },
      {
        name: 'Canton 10',
        value: 'Canton 10',
      },
      {
        name: 'Central Bosnia Canton',
        value: 'Central Bosnia Canton',
      },
      {
        name: 'Federation of Bosnia and Herzegovina',
        value: 'Federation of Bosnia and Herzegovina',
      },
      {
        name: 'Herzegovina-Neretva Canton',
        value: 'Herzegovina-Neretva Canton',
      },
      {
        name: 'Posavina Canton',
        value: 'Posavina Canton',
      },
      {
        name: 'Republika Srpska',
        value: 'Republika Srpska',
      },
      {
        name: 'Sarajevo Canton',
        value: 'Sarajevo Canton',
      },
      {
        name: 'Tuzla Canton',
        value: 'Tuzla Canton',
      },
      {
        name: 'Una-Sana Canton',
        value: 'Una-Sana Canton',
      },
      {
        name: 'West Herzegovina Canton',
        value: 'West Herzegovina Canton',
      },
      {
        name: 'Zenica-Doboj Canton',
        value: 'Zenica-Doboj Canton',
      },
    ],
  },
  BW: {
    name: 'Botswana',
    type: null,
    states: [
      {
        name: 'Central District',
        value: 'Central District',
      },
      {
        name: 'Ghanzi District',
        value: 'Ghanzi District',
      },
      {
        name: 'Kgalagadi District',
        value: 'Kgalagadi District',
      },
      {
        name: 'Kgatleng District',
        value: 'Kgatleng District',
      },
      {
        name: 'Kweneng District',
        value: 'Kweneng District',
      },
      {
        name: 'Ngamiland',
        value: 'Ngamiland',
      },
      {
        name: 'North-East District',
        value: 'North-East District',
      },
      {
        name: 'North-West District',
        value: 'North-West District',
      },
      {
        name: 'South-East District',
        value: 'South-East District',
      },
      {
        name: 'Southern District',
        value: 'Southern District',
      },
    ],
  },
  BR: {
    name: 'Brazil',
    type: null,
    states: [
      {
        name: 'Acre',
        value: 'Acre',
      },
      {
        name: 'Alagoas',
        value: 'Alagoas',
      },
      {
        name: 'Amapá',
        value: 'Amapá',
      },
      {
        name: 'Amazonas',
        value: 'Amazonas',
      },
      {
        name: 'Bahia',
        value: 'Bahia',
      },
      {
        name: 'Ceará',
        value: 'Ceará',
      },
      {
        name: 'Distrito Federal',
        value: 'Distrito Federal',
      },
      {
        name: 'Espírito Santo',
        value: 'Espírito Santo',
      },
      {
        name: 'Goiás',
        value: 'Goiás',
      },
      {
        name: 'Maranhão',
        value: 'Maranhão',
      },
      {
        name: 'Mato Grosso',
        value: 'Mato Grosso',
      },
      {
        name: 'Mato Grosso do Sul',
        value: 'Mato Grosso do Sul',
      },
      {
        name: 'Minas Gerais',
        value: 'Minas Gerais',
      },
      {
        name: 'Pará',
        value: 'Pará',
      },
      {
        name: 'Paraíba',
        value: 'Paraíba',
      },
      {
        name: 'Paraná',
        value: 'Paraná',
      },
      {
        name: 'Pernambuco',
        value: 'Pernambuco',
      },
      {
        name: 'Piauí',
        value: 'Piauí',
      },
      {
        name: 'Rio de Janeiro',
        value: 'Rio de Janeiro',
      },
      {
        name: 'Rio Grande do Norte',
        value: 'Rio Grande do Norte',
      },
      {
        name: 'Rio Grande do Sul',
        value: 'Rio Grande do Sul',
      },
      {
        name: 'Rondônia',
        value: 'Rondônia',
      },
      {
        name: 'Roraima',
        value: 'Roraima',
      },
      {
        name: 'Santa Catarina',
        value: 'Santa Catarina',
      },
      {
        name: 'São Paulo',
        value: 'São Paulo',
      },
      {
        name: 'Sergipe',
        value: 'Sergipe',
      },
      {
        name: 'Tocantins',
        value: 'Tocantins',
      },
    ],
  },
  BN: {
    name: 'Brunei',
    type: null,
    states: [
      {
        name: 'Belait District',
        value: 'Belait District',
      },
      {
        name: 'Brunei-Muara District',
        value: 'Brunei-Muara District',
      },
      {
        name: 'Temburong District',
        value: 'Temburong District',
      },
      {
        name: 'Tutong District',
        value: 'Tutong District',
      },
    ],
  },
  BG: {
    name: 'Bulgaria',
    type: null,
    states: [
      {
        name: 'Blagoevgrad Province',
        value: 'Blagoevgrad Province',
      },
      {
        name: 'Burgas Province',
        value: 'Burgas Province',
      },
      {
        name: 'Dobrich Province',
        value: 'Dobrich Province',
      },
      {
        name: 'Gabrovo Province',
        value: 'Gabrovo Province',
      },
      {
        name: 'Haskovo Province',
        value: 'Haskovo Province',
      },
      {
        name: 'Kardzhali Province',
        value: 'Kardzhali Province',
      },
      {
        name: 'Kyustendil Province',
        value: 'Kyustendil Province',
      },
      {
        name: 'Lovech Province',
        value: 'Lovech Province',
      },
      {
        name: 'Montana Province',
        value: 'Montana Province',
      },
      {
        name: 'Pazardzhik Province',
        value: 'Pazardzhik Province',
      },
      {
        name: 'Pernik Province',
        value: 'Pernik Province',
      },
      {
        name: 'Pleven Province',
        value: 'Pleven Province',
      },
      {
        name: 'Plovdiv Province',
        value: 'Plovdiv Province',
      },
      {
        name: 'Razgrad Province',
        value: 'Razgrad Province',
      },
      {
        name: 'Ruse Province',
        value: 'Ruse Province',
      },
      {
        name: 'Shumen',
        value: 'Shumen',
      },
      {
        name: 'Silistra Province',
        value: 'Silistra Province',
      },
      {
        name: 'Sliven Province',
        value: 'Sliven Province',
      },
      {
        name: 'Smolyan Province',
        value: 'Smolyan Province',
      },
      {
        name: 'Sofia City Province',
        value: 'Sofia City Province',
      },
      {
        name: 'Sofia Province',
        value: 'Sofia Province',
      },
      {
        name: 'Stara Zagora Province',
        value: 'Stara Zagora Province',
      },
      {
        name: 'Targovishte Province',
        value: 'Targovishte Province',
      },
      {
        name: 'Varna Province',
        value: 'Varna Province',
      },
      {
        name: 'Veliko Tarnovo Province',
        value: 'Veliko Tarnovo Province',
      },
      {
        name: 'Vidin Province',
        value: 'Vidin Province',
      },
      {
        name: 'Vratsa Province',
        value: 'Vratsa Province',
      },
      {
        name: 'Yambol Province',
        value: 'Yambol Province',
      },
    ],
  },
  BF: {
    name: 'Burkina Faso',
    type: null,
    states: [
      {
        name: 'Balé Province',
        value: 'Balé Province',
      },
      {
        name: 'Bam Province',
        value: 'Bam Province',
      },
      {
        name: 'Banwa Province',
        value: 'Banwa Province',
      },
      {
        name: 'Bazèga Province',
        value: 'Bazèga Province',
      },
      {
        name: 'Boucle du Mouhoun Region',
        value: 'Boucle du Mouhoun Region',
      },
      {
        name: 'Bougouriba Province',
        value: 'Bougouriba Province',
      },
      {
        name: 'Boulgou',
        value: 'Boulgou',
      },
      {
        name: 'Cascades Region',
        value: 'Cascades Region',
      },
      {
        name: 'Centre',
        value: 'Centre',
      },
      {
        name: 'Centre-Est Region',
        value: 'Centre-Est Region',
      },
      {
        name: 'Centre-Nord Region',
        value: 'Centre-Nord Region',
      },
      {
        name: 'Centre-Ouest Region',
        value: 'Centre-Ouest Region',
      },
      {
        name: 'Centre-Sud Region',
        value: 'Centre-Sud Region',
      },
      {
        name: 'Comoé Province',
        value: 'Comoé Province',
      },
      {
        name: 'Est Region',
        value: 'Est Region',
      },
      {
        name: 'Ganzourgou Province',
        value: 'Ganzourgou Province',
      },
      {
        name: 'Gnagna Province',
        value: 'Gnagna Province',
      },
      {
        name: 'Gourma Province',
        value: 'Gourma Province',
      },
      {
        name: 'Hauts-Bassins Region',
        value: 'Hauts-Bassins Region',
      },
      {
        name: 'Houet Province',
        value: 'Houet Province',
      },
      {
        name: 'Ioba Province',
        value: 'Ioba Province',
      },
      {
        name: 'Kadiogo Province',
        value: 'Kadiogo Province',
      },
      {
        name: 'Kénédougou Province',
        value: 'Kénédougou Province',
      },
      {
        name: 'Komondjari Province',
        value: 'Komondjari Province',
      },
      {
        name: 'Kompienga Province',
        value: 'Kompienga Province',
      },
      {
        name: 'Kossi Province',
        value: 'Kossi Province',
      },
      {
        name: 'Koulpélogo Province',
        value: 'Koulpélogo Province',
      },
      {
        name: 'Kouritenga Province',
        value: 'Kouritenga Province',
      },
      {
        name: 'Kourwéogo Province',
        value: 'Kourwéogo Province',
      },
      {
        name: 'Léraba Province',
        value: 'Léraba Province',
      },
      {
        name: 'Loroum Province',
        value: 'Loroum Province',
      },
      {
        name: 'Mouhoun',
        value: 'Mouhoun',
      },
      {
        name: 'Nahouri Province',
        value: 'Nahouri Province',
      },
      {
        name: 'Namentenga Province',
        value: 'Namentenga Province',
      },
      {
        name: 'Nayala Province',
        value: 'Nayala Province',
      },
      {
        name: 'Nord Region, Burkina Faso',
        value: 'Nord Region, Burkina Faso',
      },
      {
        name: 'Noumbiel Province',
        value: 'Noumbiel Province',
      },
      {
        name: 'Oubritenga Province',
        value: 'Oubritenga Province',
      },
      {
        name: 'Oudalan Province',
        value: 'Oudalan Province',
      },
      {
        name: 'Passoré Province',
        value: 'Passoré Province',
      },
      {
        name: 'Plateau-Central Region',
        value: 'Plateau-Central Region',
      },
      {
        name: 'Poni Province',
        value: 'Poni Province',
      },
      {
        name: 'Sahel Region',
        value: 'Sahel Region',
      },
      {
        name: 'Sanguié Province',
        value: 'Sanguié Province',
      },
      {
        name: 'Sanmatenga Province',
        value: 'Sanmatenga Province',
      },
      {
        name: 'Séno Province',
        value: 'Séno Province',
      },
      {
        name: 'Sissili Province',
        value: 'Sissili Province',
      },
      {
        name: 'Soum Province',
        value: 'Soum Province',
      },
      {
        name: 'Sourou Province',
        value: 'Sourou Province',
      },
      {
        name: 'Sud-Ouest Region',
        value: 'Sud-Ouest Region',
      },
      {
        name: 'Tapoa Province',
        value: 'Tapoa Province',
      },
      {
        name: 'Tuy Province',
        value: 'Tuy Province',
      },
      {
        name: 'Yagha Province',
        value: 'Yagha Province',
      },
      {
        name: 'Yatenga Province',
        value: 'Yatenga Province',
      },
      {
        name: 'Ziro Province',
        value: 'Ziro Province',
      },
      {
        name: 'Zondoma Province',
        value: 'Zondoma Province',
      },
      {
        name: 'Zoundwéogo Province',
        value: 'Zoundwéogo Province',
      },
    ],
  },
  BI: {
    name: 'Burundi',
    type: null,
    states: [
      {
        name: 'Bubanza Province',
        value: 'Bubanza Province',
      },
      {
        name: 'Bujumbura Mairie Province',
        value: 'Bujumbura Mairie Province',
      },
      {
        name: 'Bujumbura Rural Province',
        value: 'Bujumbura Rural Province',
      },
      {
        name: 'Bururi Province',
        value: 'Bururi Province',
      },
      {
        name: 'Cankuzo Province',
        value: 'Cankuzo Province',
      },
      {
        name: 'Cibitoke Province',
        value: 'Cibitoke Province',
      },
      {
        name: 'Gitega Province',
        value: 'Gitega Province',
      },
      {
        name: 'Karuzi Province',
        value: 'Karuzi Province',
      },
      {
        name: 'Kayanza Province',
        value: 'Kayanza Province',
      },
      {
        name: 'Kirundo Province',
        value: 'Kirundo Province',
      },
      {
        name: 'Makamba Province',
        value: 'Makamba Province',
      },
      {
        name: 'Muramvya Province',
        value: 'Muramvya Province',
      },
      {
        name: 'Muyinga Province',
        value: 'Muyinga Province',
      },
      {
        name: 'Mwaro Province',
        value: 'Mwaro Province',
      },
      {
        name: 'Ngozi Province',
        value: 'Ngozi Province',
      },
      {
        name: 'Rumonge Province',
        value: 'Rumonge Province',
      },
      {
        name: 'Rutana Province',
        value: 'Rutana Province',
      },
      {
        name: 'Ruyigi Province',
        value: 'Ruyigi Province',
      },
    ],
  },
  KH: {
    name: 'Cambodia',
    type: 'province',
    states: [
      {
        name: 'Banteay Meanchey',
        value: 'Banteay Meanchey',
      },
      {
        name: 'Battambang',
        value: 'Battambang',
      },
      {
        name: 'Kampong Cham',
        value: 'Kampong Cham',
      },
      {
        name: 'Kampong Chhnang',
        value: 'Kampong Chhnang',
      },
      {
        name: 'Kampong Speu',
        value: 'Kampong Speu',
      },
      {
        name: 'Kampong Thom',
        value: 'Kampong Thom',
      },
      {
        name: 'Kampot',
        value: 'Kampot',
      },
      {
        name: 'Kandal',
        value: 'Kandal',
      },
      {
        name: 'Kep',
        value: 'Kep',
      },
      {
        name: 'Koh Kong',
        value: 'Koh Kong',
      },
      {
        name: 'Kratie',
        value: 'Kratie',
      },
      {
        name: 'Mondulkiri',
        value: 'Mondulkiri',
      },
      {
        name: 'Oddar Meanchey',
        value: 'Oddar Meanchey',
      },
      {
        name: 'Pailin',
        value: 'Pailin',
      },
      {
        name: 'Phnom Penh',
        value: 'Phnom Penh',
      },
      {
        name: 'Preah Vihear',
        value: 'Preah Vihear',
      },
      {
        name: 'Prey Veng',
        value: 'Prey Veng',
      },
      {
        name: 'Pursat',
        value: 'Pursat',
      },
      {
        name: 'Ratanakiri',
        value: 'Ratanakiri',
      },
      {
        name: 'Siem Reap',
        value: 'Siem Reap',
      },
      {
        name: 'Sihanoukville',
        value: 'Sihanoukville',
      },
      {
        name: 'Stung Treng',
        value: 'Stung Treng',
      },
      {
        name: 'Svay Rieng',
        value: 'Svay Rieng',
      },
      {
        name: 'Takeo',
        value: 'Takeo',
      },
    ],
  },
  CM: {
    name: 'Cameroon',
    type: null,
    states: [
      {
        name: 'Adamawa',
        value: 'Adamawa',
      },
      {
        name: 'Centre',
        value: 'Centre',
      },
      {
        name: 'East',
        value: 'East',
      },
      {
        name: 'Far North',
        value: 'Far North',
      },
      {
        name: 'Littoral',
        value: 'Littoral',
      },
      {
        name: 'North',
        value: 'North',
      },
      {
        name: 'Northwest',
        value: 'Northwest',
      },
      {
        name: 'South',
        value: 'South',
      },
      {
        name: 'Southwest',
        value: 'Southwest',
      },
      {
        name: 'West',
        value: 'West',
      },
    ],
  },
  CA: {
    name: 'Canada',
    type: 'province',
    states: [
      {
        name: 'Alberta',
        value: 'Alberta',
      },
      {
        name: 'British Columbia',
        value: 'British Columbia',
      },
      {
        name: 'Manitoba',
        value: 'Manitoba',
      },
      {
        name: 'New Brunswick',
        value: 'New Brunswick',
      },
      {
        name: 'Newfoundland and Labrador',
        value: 'Newfoundland and Labrador',
      },
      {
        name: 'Northwest Territories',
        value: 'Northwest Territories',
      },
      {
        name: 'Nova Scotia',
        value: 'Nova Scotia',
      },
      {
        name: 'Nunavut',
        value: 'Nunavut',
      },
      {
        name: 'Ontario',
        value: 'Ontario',
      },
      {
        name: 'Prince Edward Island',
        value: 'Prince Edward Island',
      },
      {
        name: 'Quebec',
        value: 'Quebec',
      },
      {
        name: 'Saskatchewan',
        value: 'Saskatchewan',
      },
      {
        name: 'Yukon',
        value: 'Yukon',
      },
    ],
  },
  CV: {
    name: 'Cape Verde',
    type: null,
    states: [
      {
        name: 'Barlavento Islands',
        value: 'Barlavento Islands',
      },
      {
        name: 'Boa Vista',
        value: 'Boa Vista',
      },
      {
        name: 'Brava',
        value: 'Brava',
      },
      {
        name: 'Maio Municipality',
        value: 'Maio Municipality',
      },
      {
        name: 'Mosteiros',
        value: 'Mosteiros',
      },
      {
        name: 'Paul',
        value: 'Paul',
      },
      {
        name: 'Porto Novo',
        value: 'Porto Novo',
      },
      {
        name: 'Praia',
        value: 'Praia',
      },
      {
        name: 'Ribeira Brava Municipality',
        value: 'Ribeira Brava Municipality',
      },
      {
        name: 'Ribeira Grande',
        value: 'Ribeira Grande',
      },
      {
        name: 'Ribeira Grande de Santiago',
        value: 'Ribeira Grande de Santiago',
      },
      {
        name: 'Sal',
        value: 'Sal',
      },
      {
        name: 'Santa Catarina',
        value: 'Santa Catarina',
      },
      {
        name: 'Santa Catarina do Fogo',
        value: 'Santa Catarina do Fogo',
      },
      {
        name: 'Santa Cruz',
        value: 'Santa Cruz',
      },
      {
        name: 'São Domingos',
        value: 'São Domingos',
      },
      {
        name: 'São Filipe',
        value: 'São Filipe',
      },
      {
        name: 'São Lourenço dos Órgãos',
        value: 'São Lourenço dos Órgãos',
      },
      {
        name: 'São Miguel',
        value: 'São Miguel',
      },
      {
        name: 'São Vicente',
        value: 'São Vicente',
      },
      {
        name: 'Sotavento Islands',
        value: 'Sotavento Islands',
      },
      {
        name: 'Tarrafal',
        value: 'Tarrafal',
      },
      {
        name: 'Tarrafal de São Nicolau',
        value: 'Tarrafal de São Nicolau',
      },
    ],
  },
  CF: {
    name: 'Central African Republic',
    type: null,
    states: [
      {
        name: 'Bamingui-Bangoran Prefecture',
        value: 'Bamingui-Bangoran Prefecture',
      },
      {
        name: 'Bangui',
        value: 'Bangui',
      },
      {
        name: 'Basse-Kotto Prefecture',
        value: 'Basse-Kotto Prefecture',
      },
      {
        name: 'Haut-Mbomou Prefecture',
        value: 'Haut-Mbomou Prefecture',
      },
      {
        name: 'Haute-Kotto Prefecture',
        value: 'Haute-Kotto Prefecture',
      },
      {
        name: 'Kémo Prefecture',
        value: 'Kémo Prefecture',
      },
      {
        name: 'Lobaye Prefecture',
        value: 'Lobaye Prefecture',
      },
      {
        name: 'Mambéré-Kadéï',
        value: 'Mambéré-Kadéï',
      },
      {
        name: 'Mbomou Prefecture',
        value: 'Mbomou Prefecture',
      },
      {
        name: 'Nana-Grébizi Economic Prefecture',
        value: 'Nana-Grébizi Economic Prefecture',
      },
      {
        name: 'Nana-Mambéré Prefecture',
        value: 'Nana-Mambéré Prefecture',
      },
      {
        name: "Ombella-M'Poko Prefecture",
        value: "Ombella-M'Poko Prefecture",
      },
      {
        name: 'Ouaka Prefecture',
        value: 'Ouaka Prefecture',
      },
      {
        name: 'Ouham Prefecture',
        value: 'Ouham Prefecture',
      },
      {
        name: 'Ouham-Pendé Prefecture',
        value: 'Ouham-Pendé Prefecture',
      },
      {
        name: 'Sangha-Mbaéré',
        value: 'Sangha-Mbaéré',
      },
      {
        name: 'Vakaga Prefecture',
        value: 'Vakaga Prefecture',
      },
    ],
  },
  TD: {
    name: 'Chad',
    type: 'province',
    states: [
      {
        name: 'Bahr el Gazel',
        value: 'Bahr el Gazel',
      },
      {
        name: 'Batha',
        value: 'Batha',
      },
      {
        name: 'Borkou',
        value: 'Borkou',
      },
      {
        name: 'Chari-Baguirmi',
        value: 'Chari-Baguirmi',
      },
      {
        name: 'Ennedi-Est',
        value: 'Ennedi-Est',
      },
      {
        name: 'Ennedi-Ouest',
        value: 'Ennedi-Ouest',
      },
      {
        name: 'Guéra',
        value: 'Guéra',
      },
      {
        name: 'Hadjer-Lamis',
        value: 'Hadjer-Lamis',
      },
      {
        name: 'Kanem',
        value: 'Kanem',
      },
      {
        name: 'Lac',
        value: 'Lac',
      },
      {
        name: 'Logone Occidental',
        value: 'Logone Occidental',
      },
      {
        name: 'Logone Oriental',
        value: 'Logone Oriental',
      },
      {
        name: 'Mandoul',
        value: 'Mandoul',
      },
      {
        name: 'Mayo-Kebbi Est',
        value: 'Mayo-Kebbi Est',
      },
      {
        name: 'Mayo-Kebbi Ouest',
        value: 'Mayo-Kebbi Ouest',
      },
      {
        name: 'Moyen-Chari',
        value: 'Moyen-Chari',
      },
      {
        name: "N'Djamena",
        value: "N'Djamena",
      },
      {
        name: 'Ouaddaï',
        value: 'Ouaddaï',
      },
      {
        name: 'Salamat',
        value: 'Salamat',
      },
      {
        name: 'Sila',
        value: 'Sila',
      },
      {
        name: 'Tandjilé',
        value: 'Tandjilé',
      },
      {
        name: 'Tibesti',
        value: 'Tibesti',
      },
      {
        name: 'Wadi Fira',
        value: 'Wadi Fira',
      },
    ],
  },
  CL: {
    name: 'Chile',
    type: null,
    states: [
      {
        name: 'Aisén del General Carlos Ibañez del Campo',
        value: 'Aisén del General Carlos Ibañez del Campo',
      },
      {
        name: 'Antofagasta',
        value: 'Antofagasta',
      },
      {
        name: 'Arica y Parinacota',
        value: 'Arica y Parinacota',
      },
      {
        name: 'Atacama',
        value: 'Atacama',
      },
      {
        name: 'Biobío',
        value: 'Biobío',
      },
      {
        name: 'Coquimbo',
        value: 'Coquimbo',
      },
      {
        name: 'La Araucanía',
        value: 'La Araucanía',
      },
      {
        name: "Libertador General Bernardo O'Higgins",
        value: "Libertador General Bernardo O'Higgins",
      },
      {
        name: 'Los Lagos',
        value: 'Los Lagos',
      },
      {
        name: 'Los Ríos',
        value: 'Los Ríos',
      },
      {
        name: 'Magallanes y de la Antártica Chilena',
        value: 'Magallanes y de la Antártica Chilena',
      },
      {
        name: 'Maule',
        value: 'Maule',
      },
      {
        name: 'Ñuble',
        value: 'Ñuble',
      },
      {
        name: 'Región Metropolitana de Santiago',
        value: 'Región Metropolitana de Santiago',
      },
      {
        name: 'Tarapacá',
        value: 'Tarapacá',
      },
      {
        name: 'Valparaíso',
        value: 'Valparaíso',
      },
    ],
  },
  CN: {
    name: 'China',
    type: 'province',
    states: [
      {
        name: 'Anhui',
        value: 'Anhui',
      },
      {
        name: 'Beijing',
        value: 'Beijing',
      },
      {
        name: 'Chongqing',
        value: 'Chongqing',
      },
      {
        name: 'Fujian',
        value: 'Fujian',
      },
      {
        name: 'Gansu',
        value: 'Gansu',
      },
      {
        name: 'Guangdong',
        value: 'Guangdong',
      },
      {
        name: 'Guangxi Zhuang',
        value: 'Guangxi Zhuang',
      },
      {
        name: 'Guizhou',
        value: 'Guizhou',
      },
      {
        name: 'Hainan',
        value: 'Hainan',
      },
      {
        name: 'Hebei',
        value: 'Hebei',
      },
      {
        name: 'Heilongjiang',
        value: 'Heilongjiang',
      },
      {
        name: 'Henan',
        value: 'Henan',
      },
      {
        name: 'Hong Kong SAR',
        value: 'Hong Kong SAR',
      },
      {
        name: 'Hubei',
        value: 'Hubei',
      },
      {
        name: 'Hunan',
        value: 'Hunan',
      },
      {
        name: 'Inner Mongolia',
        value: 'Inner Mongolia',
      },
      {
        name: 'Jiangsu',
        value: 'Jiangsu',
      },
      {
        name: 'Jiangxi',
        value: 'Jiangxi',
      },
      {
        name: 'Jilin',
        value: 'Jilin',
      },
      {
        name: 'Liaoning',
        value: 'Liaoning',
      },
      {
        name: 'Macau SAR',
        value: 'Macau SAR',
      },
      {
        name: 'Ningxia Huizu',
        value: 'Ningxia Huizu',
      },
      {
        name: 'Qinghai',
        value: 'Qinghai',
      },
      {
        name: 'Shaanxi',
        value: 'Shaanxi',
      },
      {
        name: 'Shandong',
        value: 'Shandong',
      },
      {
        name: 'Shanghai',
        value: 'Shanghai',
      },
      {
        name: 'Shanxi',
        value: 'Shanxi',
      },
      {
        name: 'Sichuan',
        value: 'Sichuan',
      },
      {
        name: 'Taiwan',
        value: 'Taiwan',
      },
      {
        name: 'Tianjin',
        value: 'Tianjin',
      },
      {
        name: 'Xinjiang',
        value: 'Xinjiang',
      },
      {
        name: 'Xizang',
        value: 'Xizang',
      },
      {
        name: 'Yunnan',
        value: 'Yunnan',
      },
      {
        name: 'Zhejiang',
        value: 'Zhejiang',
      },
    ],
  },
  CO: {
    name: 'Colombia',
    type: null,
    states: [
      {
        name: 'Amazonas',
        value: 'Amazonas',
      },
      {
        name: 'Antioquia',
        value: 'Antioquia',
      },
      {
        name: 'Arauca',
        value: 'Arauca',
      },
      {
        name: 'Archipiélago de San Andrés, Providencia y Santa Catalina',
        value: 'Archipiélago de San Andrés, Providencia y Santa Catalina',
      },
      {
        name: 'Atlántico',
        value: 'Atlántico',
      },
      {
        name: 'Bogotá D.C.',
        value: 'Bogotá D.C.',
      },
      {
        name: 'Bolívar',
        value: 'Bolívar',
      },
      {
        name: 'Boyacá',
        value: 'Boyacá',
      },
      {
        name: 'Caldas',
        value: 'Caldas',
      },
      {
        name: 'Caquetá',
        value: 'Caquetá',
      },
      {
        name: 'Casanare',
        value: 'Casanare',
      },
      {
        name: 'Cauca',
        value: 'Cauca',
      },
      {
        name: 'Cesar',
        value: 'Cesar',
      },
      {
        name: 'Chocó',
        value: 'Chocó',
      },
      {
        name: 'Córdoba',
        value: 'Córdoba',
      },
      {
        name: 'Cundinamarca',
        value: 'Cundinamarca',
      },
      {
        name: 'Guainía',
        value: 'Guainía',
      },
      {
        name: 'Guaviare',
        value: 'Guaviare',
      },
      {
        name: 'Huila',
        value: 'Huila',
      },
      {
        name: 'La Guajira',
        value: 'La Guajira',
      },
      {
        name: 'Magdalena',
        value: 'Magdalena',
      },
      {
        name: 'Meta',
        value: 'Meta',
      },
      {
        name: 'Nariño',
        value: 'Nariño',
      },
      {
        name: 'Norte de Santander',
        value: 'Norte de Santander',
      },
      {
        name: 'Putumayo',
        value: 'Putumayo',
      },
      {
        name: 'Quindío',
        value: 'Quindío',
      },
      {
        name: 'Risaralda',
        value: 'Risaralda',
      },
      {
        name: 'Santander',
        value: 'Santander',
      },
      {
        name: 'Sucre',
        value: 'Sucre',
      },
      {
        name: 'Tolima',
        value: 'Tolima',
      },
      {
        name: 'Valle del Cauca',
        value: 'Valle del Cauca',
      },
      {
        name: 'Vaupés',
        value: 'Vaupés',
      },
      {
        name: 'Vichada',
        value: 'Vichada',
      },
    ],
  },
  KM: {
    name: 'Comoros',
    type: null,
    states: [
      {
        name: 'Anjouan',
        value: 'Anjouan',
      },
      {
        name: 'Grande Comore',
        value: 'Grande Comore',
      },
      {
        name: 'Mohéli',
        value: 'Mohéli',
      },
    ],
  },
  CG: {
    name: 'Congo',
    type: null,
    states: [
      {
        name: 'Bouenza Department',
        value: 'Bouenza Department',
      },
      {
        name: 'Brazzaville',
        value: 'Brazzaville',
      },
      {
        name: 'Cuvette Department',
        value: 'Cuvette Department',
      },
      {
        name: 'Cuvette-Ouest Department',
        value: 'Cuvette-Ouest Department',
      },
      {
        name: 'Kouilou Department',
        value: 'Kouilou Department',
      },
      {
        name: 'Lékoumou Department',
        value: 'Lékoumou Department',
      },
      {
        name: 'Likouala Department',
        value: 'Likouala Department',
      },
      {
        name: 'Niari Department',
        value: 'Niari Department',
      },
      {
        name: 'Plateaux Department',
        value: 'Plateaux Department',
      },
      {
        name: 'Pointe-Noire',
        value: 'Pointe-Noire',
      },
      {
        name: 'Pool Department',
        value: 'Pool Department',
      },
      {
        name: 'Sangha Department',
        value: 'Sangha Department',
      },
    ],
  },
  CR: {
    name: 'Costa Rica',
    type: null,
    states: [
      {
        name: 'Alajuela Province',
        value: 'Alajuela Province',
      },
      {
        name: 'Guanacaste Province',
        value: 'Guanacaste Province',
      },
      {
        name: 'Heredia Province',
        value: 'Heredia Province',
      },
      {
        name: 'Limón Province',
        value: 'Limón Province',
      },
      {
        name: 'Provincia de Cartago',
        value: 'Provincia de Cartago',
      },
      {
        name: 'Puntarenas Province',
        value: 'Puntarenas Province',
      },
      {
        name: 'San José Province',
        value: 'San José Province',
      },
    ],
  },
  CI: {
    name: "Cote D'Ivoire (Ivory Coast)",
    type: null,
    states: [
      {
        name: 'Abidjan',
        value: 'Abidjan',
      },
      {
        name: 'Agnéby',
        value: 'Agnéby',
      },
      {
        name: 'Bafing Region',
        value: 'Bafing Region',
      },
      {
        name: 'Bas-Sassandra District',
        value: 'Bas-Sassandra District',
      },
      {
        name: 'Bas-Sassandra Region',
        value: 'Bas-Sassandra Region',
      },
      {
        name: 'Comoé District',
        value: 'Comoé District',
      },
      {
        name: 'Denguélé District',
        value: 'Denguélé District',
      },
      {
        name: 'Denguélé Region',
        value: 'Denguélé Region',
      },
      {
        name: 'Dix-Huit Montagnes',
        value: 'Dix-Huit Montagnes',
      },
      {
        name: 'Fromager',
        value: 'Fromager',
      },
      {
        name: 'Gôh-Djiboua District',
        value: 'Gôh-Djiboua District',
      },
      {
        name: 'Haut-Sassandra',
        value: 'Haut-Sassandra',
      },
      {
        name: 'Lacs District',
        value: 'Lacs District',
      },
      {
        name: 'Lacs Region',
        value: 'Lacs Region',
      },
      {
        name: 'Lagunes District',
        value: 'Lagunes District',
      },
      {
        name: 'Lagunes region',
        value: 'Lagunes region',
      },
      {
        name: 'Marahoué Region',
        value: 'Marahoué Region',
      },
      {
        name: 'Montagnes District',
        value: 'Montagnes District',
      },
      {
        name: 'Moyen-Cavally',
        value: 'Moyen-Cavally',
      },
      {
        name: 'Moyen-Comoé',
        value: 'Moyen-Comoé',
      },
      {
        name: "N'zi-Comoé",
        value: "N'zi-Comoé",
      },
      {
        name: 'Sassandra-Marahoué District',
        value: 'Sassandra-Marahoué District',
      },
      {
        name: 'Savanes Region',
        value: 'Savanes Region',
      },
      {
        name: 'Sud-Bandama',
        value: 'Sud-Bandama',
      },
      {
        name: 'Sud-Comoé',
        value: 'Sud-Comoé',
      },
      {
        name: 'Vallée du Bandama District',
        value: 'Vallée du Bandama District',
      },
      {
        name: 'Vallée du Bandama Region',
        value: 'Vallée du Bandama Region',
      },
      {
        name: 'Woroba District',
        value: 'Woroba District',
      },
      {
        name: 'Worodougou',
        value: 'Worodougou',
      },
      {
        name: 'Yamoussoukro',
        value: 'Yamoussoukro',
      },
      {
        name: 'Zanzan Region',
        value: 'Zanzan Region',
      },
    ],
  },
  HR: {
    name: 'Croatia',
    type: 'county',
    states: [
      {
        name: 'Bjelovar-Bilogora',
        value: 'Bjelovar-Bilogora',
      },
      {
        name: 'Brod-Posavina',
        value: 'Brod-Posavina',
      },
      {
        name: 'Dubrovnik-Neretva',
        value: 'Dubrovnik-Neretva',
      },
      {
        name: 'Istria',
        value: 'Istria',
      },
      {
        name: 'Karlovac',
        value: 'Karlovac',
      },
      {
        name: 'Koprivnica-Križevci',
        value: 'Koprivnica-Križevci',
      },
      {
        name: 'Krapina-Zagorje',
        value: 'Krapina-Zagorje',
      },
      {
        name: 'Lika-Senj',
        value: 'Lika-Senj',
      },
      {
        name: 'Međimurje',
        value: 'Međimurje',
      },
      {
        name: 'Osijek-Baranja',
        value: 'Osijek-Baranja',
      },
      {
        name: 'Požega-Slavonia',
        value: 'Požega-Slavonia',
      },
      {
        name: 'Primorje-Gorski Kotar',
        value: 'Primorje-Gorski Kotar',
      },
      {
        name: 'Šibenik-Knin',
        value: 'Šibenik-Knin',
      },
      {
        name: 'Sisak-Moslavina',
        value: 'Sisak-Moslavina',
      },
      {
        name: 'Split-Dalmatia',
        value: 'Split-Dalmatia',
      },
      {
        name: 'Varaždin',
        value: 'Varaždin',
      },
      {
        name: 'Virovitica-Podravina',
        value: 'Virovitica-Podravina',
      },
      {
        name: 'Vukovar-Syrmia',
        value: 'Vukovar-Syrmia',
      },
      {
        name: 'Zadar',
        value: 'Zadar',
      },
      {
        name: 'Zagreb',
        value: 'Zagreb',
      },
      {
        name: 'Zagreb',
        value: 'Zagreb',
      },
    ],
  },
  CU: {
    name: 'Cuba',
    type: null,
    states: [
      {
        name: 'Artemisa Province',
        value: 'Artemisa Province',
      },
      {
        name: 'Camagüey Province',
        value: 'Camagüey Province',
      },
      {
        name: 'Ciego de Ávila Province',
        value: 'Ciego de Ávila Province',
      },
      {
        name: 'Cienfuegos Province',
        value: 'Cienfuegos Province',
      },
      {
        name: 'Granma Province',
        value: 'Granma Province',
      },
      {
        name: 'Guantánamo Province',
        value: 'Guantánamo Province',
      },
      {
        name: 'Havana Province',
        value: 'Havana Province',
      },
      {
        name: 'Holguín Province',
        value: 'Holguín Province',
      },
      {
        name: 'Isla de la Juventud',
        value: 'Isla de la Juventud',
      },
      {
        name: 'Las Tunas Province',
        value: 'Las Tunas Province',
      },
      {
        name: 'Matanzas Province',
        value: 'Matanzas Province',
      },
      {
        name: 'Mayabeque Province',
        value: 'Mayabeque Province',
      },
      {
        name: 'Pinar del Río Province',
        value: 'Pinar del Río Province',
      },
      {
        name: 'Sancti Spíritus Province',
        value: 'Sancti Spíritus Province',
      },
      {
        name: 'Santiago de Cuba Province',
        value: 'Santiago de Cuba Province',
      },
      {
        name: 'Villa Clara Province',
        value: 'Villa Clara Province',
      },
    ],
  },
  CY: {
    name: 'Cyprus',
    type: 'district',
    states: [
      {
        name: 'Famagusta District (Mağusa)',
        value: 'Famagusta District (Mağusa)',
      },
      {
        name: 'Kyrenia District (Keryneia)',
        value: 'Kyrenia District (Keryneia)',
      },
      {
        name: 'Larnaca District (Larnaka)',
        value: 'Larnaca District (Larnaka)',
      },
      {
        name: 'Limassol District (Leymasun)',
        value: 'Limassol District (Leymasun)',
      },
      {
        name: 'Nicosia District (Lefkoşa)',
        value: 'Nicosia District (Lefkoşa)',
      },
      {
        name: 'Paphos District (Pafos)',
        value: 'Paphos District (Pafos)',
      },
    ],
  },
  CZ: {
    name: 'Czech Republic',
    type: null,
    states: [
      {
        name: 'Benešov',
        value: 'Benešov',
      },
      {
        name: 'Beroun',
        value: 'Beroun',
      },
      {
        name: 'Blansko',
        value: 'Blansko',
      },
      {
        name: 'Břeclav',
        value: 'Břeclav',
      },
      {
        name: 'Brno-město',
        value: 'Brno-město',
      },
      {
        name: 'Brno-venkov',
        value: 'Brno-venkov',
      },
      {
        name: 'Bruntál',
        value: 'Bruntál',
      },
      {
        name: 'Česká Lípa',
        value: 'Česká Lípa',
      },
      {
        name: 'České Budějovice',
        value: 'České Budějovice',
      },
      {
        name: 'Český Krumlov',
        value: 'Český Krumlov',
      },
      {
        name: 'Cheb',
        value: 'Cheb',
      },
      {
        name: 'Chomutov',
        value: 'Chomutov',
      },
      {
        name: 'Chrudim',
        value: 'Chrudim',
      },
      {
        name: 'Děčín',
        value: 'Děčín',
      },
      {
        name: 'Domažlice',
        value: 'Domažlice',
      },
      {
        name: 'Frýdek-Místek',
        value: 'Frýdek-Místek',
      },
      {
        name: 'Havlíčkův Brod',
        value: 'Havlíčkův Brod',
      },
      {
        name: 'Hodonín',
        value: 'Hodonín',
      },
      {
        name: 'Hradec Králové',
        value: 'Hradec Králové',
      },
      {
        name: 'Jablonec nad Nisou',
        value: 'Jablonec nad Nisou',
      },
      {
        name: 'Jeseník',
        value: 'Jeseník',
      },
      {
        name: 'Jičín',
        value: 'Jičín',
      },
      {
        name: 'Jihlava',
        value: 'Jihlava',
      },
      {
        name: 'Jihočeský kraj',
        value: 'Jihočeský kraj',
      },
      {
        name: 'Jihomoravský kraj',
        value: 'Jihomoravský kraj',
      },
      {
        name: 'Jindřichův Hradec',
        value: 'Jindřichův Hradec',
      },
      {
        name: 'Karlovarský kraj',
        value: 'Karlovarský kraj',
      },
      {
        name: 'Karlovy Vary',
        value: 'Karlovy Vary',
      },
      {
        name: 'Karviná',
        value: 'Karviná',
      },
      {
        name: 'Kladno',
        value: 'Kladno',
      },
      {
        name: 'Klatovy',
        value: 'Klatovy',
      },
      {
        name: 'Kolín',
        value: 'Kolín',
      },
      {
        name: 'Kraj Vysočina',
        value: 'Kraj Vysočina',
      },
      {
        name: 'Královéhradecký kraj',
        value: 'Královéhradecký kraj',
      },
      {
        name: 'Kroměříž',
        value: 'Kroměříž',
      },
      {
        name: 'Kutná Hora',
        value: 'Kutná Hora',
      },
      {
        name: 'Liberec',
        value: 'Liberec',
      },
      {
        name: 'Liberecký kraj',
        value: 'Liberecký kraj',
      },
      {
        name: 'Litoměřice',
        value: 'Litoměřice',
      },
      {
        name: 'Louny',
        value: 'Louny',
      },
      {
        name: 'Mělník',
        value: 'Mělník',
      },
      {
        name: 'Mladá Boleslav',
        value: 'Mladá Boleslav',
      },
      {
        name: 'Moravskoslezský kraj',
        value: 'Moravskoslezský kraj',
      },
      {
        name: 'Most',
        value: 'Most',
      },
      {
        name: 'Náchod',
        value: 'Náchod',
      },
      {
        name: 'Nový Jičín',
        value: 'Nový Jičín',
      },
      {
        name: 'Nymburk',
        value: 'Nymburk',
      },
      {
        name: 'Olomouc',
        value: 'Olomouc',
      },
      {
        name: 'Olomoucký kraj',
        value: 'Olomoucký kraj',
      },
      {
        name: 'Opava',
        value: 'Opava',
      },
      {
        name: 'Ostrava-město',
        value: 'Ostrava-město',
      },
      {
        name: 'Pardubice',
        value: 'Pardubice',
      },
      {
        name: 'Pardubický kraj',
        value: 'Pardubický kraj',
      },
      {
        name: 'Pelhřimov',
        value: 'Pelhřimov',
      },
      {
        name: 'Písek',
        value: 'Písek',
      },
      {
        name: 'Plzeň-jih',
        value: 'Plzeň-jih',
      },
      {
        name: 'Plzeň-město',
        value: 'Plzeň-město',
      },
      {
        name: 'Plzeň-sever',
        value: 'Plzeň-sever',
      },
      {
        name: 'Plzeňský kraj',
        value: 'Plzeňský kraj',
      },
      {
        name: 'Prachatice',
        value: 'Prachatice',
      },
      {
        name: 'Praha-východ',
        value: 'Praha-východ',
      },
      {
        name: 'Praha-západ',
        value: 'Praha-západ',
      },
      {
        name: 'Praha, Hlavní město',
        value: 'Praha, Hlavní město',
      },
      {
        name: 'Přerov',
        value: 'Přerov',
      },
      {
        name: 'Příbram',
        value: 'Příbram',
      },
      {
        name: 'Prostějov',
        value: 'Prostějov',
      },
      {
        name: 'Rakovník',
        value: 'Rakovník',
      },
      {
        name: 'Rokycany',
        value: 'Rokycany',
      },
      {
        name: 'Rychnov nad Kněžnou',
        value: 'Rychnov nad Kněžnou',
      },
      {
        name: 'Semily',
        value: 'Semily',
      },
      {
        name: 'Sokolov',
        value: 'Sokolov',
      },
      {
        name: 'Strakonice',
        value: 'Strakonice',
      },
      {
        name: 'Středočeský kraj',
        value: 'Středočeský kraj',
      },
      {
        name: 'Šumperk',
        value: 'Šumperk',
      },
      {
        name: 'Svitavy',
        value: 'Svitavy',
      },
      {
        name: 'Tábor',
        value: 'Tábor',
      },
      {
        name: 'Tachov',
        value: 'Tachov',
      },
      {
        name: 'Teplice',
        value: 'Teplice',
      },
      {
        name: 'Třebíč',
        value: 'Třebíč',
      },
      {
        name: 'Trutnov',
        value: 'Trutnov',
      },
      {
        name: 'Uherské Hradiště',
        value: 'Uherské Hradiště',
      },
      {
        name: 'Ústecký kraj',
        value: 'Ústecký kraj',
      },
      {
        name: 'Ústí nad Labem',
        value: 'Ústí nad Labem',
      },
      {
        name: 'Ústí nad Orlicí',
        value: 'Ústí nad Orlicí',
      },
      {
        name: 'Vsetín',
        value: 'Vsetín',
      },
      {
        name: 'Vyškov',
        value: 'Vyškov',
      },
      {
        name: 'Žďár nad Sázavou',
        value: 'Žďár nad Sázavou',
      },
      {
        name: 'Zlín',
        value: 'Zlín',
      },
      {
        name: 'Zlínský kraj',
        value: 'Zlínský kraj',
      },
      {
        name: 'Znojmo',
        value: 'Znojmo',
      },
    ],
  },
  CD: {
    name: 'Democratic Republic of the Congo',
    type: null,
    states: [
      {
        name: 'Bas-Uélé',
        value: 'Bas-Uélé',
      },
      {
        name: 'Équateur',
        value: 'Équateur',
      },
      {
        name: 'Haut-Katanga',
        value: 'Haut-Katanga',
      },
      {
        name: 'Haut-Lomami',
        value: 'Haut-Lomami',
      },
      {
        name: 'Haut-Uélé',
        value: 'Haut-Uélé',
      },
      {
        name: 'Ituri',
        value: 'Ituri',
      },
      {
        name: 'Kasaï',
        value: 'Kasaï',
      },
      {
        name: 'Kasaï Central',
        value: 'Kasaï Central',
      },
      {
        name: 'Kasaï Oriental',
        value: 'Kasaï Oriental',
      },
      {
        name: 'Kinshasa',
        value: 'Kinshasa',
      },
      {
        name: 'Kongo Central',
        value: 'Kongo Central',
      },
      {
        name: 'Kwango',
        value: 'Kwango',
      },
      {
        name: 'Kwilu',
        value: 'Kwilu',
      },
      {
        name: 'Lomami',
        value: 'Lomami',
      },
      {
        name: 'Lualaba',
        value: 'Lualaba',
      },
      {
        name: 'Mai-Ndombe',
        value: 'Mai-Ndombe',
      },
      {
        name: 'Maniema',
        value: 'Maniema',
      },
      {
        name: 'Mongala',
        value: 'Mongala',
      },
      {
        name: 'Nord-Kivu',
        value: 'Nord-Kivu',
      },
      {
        name: 'Nord-Ubangi',
        value: 'Nord-Ubangi',
      },
      {
        name: 'Sankuru',
        value: 'Sankuru',
      },
      {
        name: 'Sud-Kivu',
        value: 'Sud-Kivu',
      },
      {
        name: 'Sud-Ubangi',
        value: 'Sud-Ubangi',
      },
      {
        name: 'Tanganyika',
        value: 'Tanganyika',
      },
      {
        name: 'Tshopo',
        value: 'Tshopo',
      },
      {
        name: 'Tshuapa',
        value: 'Tshuapa',
      },
    ],
  },
  DK: {
    name: 'Denmark',
    type: null,
    states: [
      {
        name: 'Capital Region of Denmark',
        value: 'Capital Region of Denmark',
      },
      {
        name: 'Central Denmark Region',
        value: 'Central Denmark Region',
      },
      {
        name: 'North Denmark Region',
        value: 'North Denmark Region',
      },
      {
        name: 'Region of Southern Denmark',
        value: 'Region of Southern Denmark',
      },
      {
        name: 'Region Zealand',
        value: 'Region Zealand',
      },
    ],
  },
  DJ: {
    name: 'Djibouti',
    type: null,
    states: [
      {
        name: 'Ali Sabieh Region',
        value: 'Ali Sabieh Region',
      },
      {
        name: 'Arta Region',
        value: 'Arta Region',
      },
      {
        name: 'Dikhil Region',
        value: 'Dikhil Region',
      },
      {
        name: 'Djibouti',
        value: 'Djibouti',
      },
      {
        name: 'Obock Region',
        value: 'Obock Region',
      },
      {
        name: 'Tadjourah Region',
        value: 'Tadjourah Region',
      },
    ],
  },
  DM: {
    name: 'Dominica',
    type: null,
    states: [
      {
        name: 'Saint Andrew Parish',
        value: 'Saint Andrew Parish',
      },
      {
        name: 'Saint David Parish',
        value: 'Saint David Parish',
      },
      {
        name: 'Saint George Parish',
        value: 'Saint George Parish',
      },
      {
        name: 'Saint John Parish',
        value: 'Saint John Parish',
      },
      {
        name: 'Saint Joseph Parish',
        value: 'Saint Joseph Parish',
      },
      {
        name: 'Saint Luke Parish',
        value: 'Saint Luke Parish',
      },
      {
        name: 'Saint Mark Parish',
        value: 'Saint Mark Parish',
      },
      {
        name: 'Saint Patrick Parish',
        value: 'Saint Patrick Parish',
      },
      {
        name: 'Saint Paul Parish',
        value: 'Saint Paul Parish',
      },
      {
        name: 'Saint Peter Parish',
        value: 'Saint Peter Parish',
      },
    ],
  },
  DO: {
    name: 'Dominican Republic',
    type: null,
    states: [
      {
        name: 'Azua Province',
        value: 'Azua Province',
      },
      {
        name: 'Baoruco Province',
        value: 'Baoruco Province',
      },
      {
        name: 'Barahona Province',
        value: 'Barahona Province',
      },
      {
        name: 'Dajabón Province',
        value: 'Dajabón Province',
      },
      {
        name: 'Distrito Nacional',
        value: 'Distrito Nacional',
      },
      {
        name: 'Duarte Province',
        value: 'Duarte Province',
      },
      {
        name: 'El Seibo Province',
        value: 'El Seibo Province',
      },
      {
        name: 'Espaillat Province',
        value: 'Espaillat Province',
      },
      {
        name: 'Hato Mayor Province',
        value: 'Hato Mayor Province',
      },
      {
        name: 'Hermanas Mirabal Province',
        value: 'Hermanas Mirabal Province',
      },
      {
        name: 'Independencia',
        value: 'Independencia',
      },
      {
        name: 'La Altagracia Province',
        value: 'La Altagracia Province',
      },
      {
        name: 'La Romana Province',
        value: 'La Romana Province',
      },
      {
        name: 'La Vega Province',
        value: 'La Vega Province',
      },
      {
        name: 'María Trinidad Sánchez Province',
        value: 'María Trinidad Sánchez Province',
      },
      {
        name: 'Monseñor Nouel Province',
        value: 'Monseñor Nouel Province',
      },
      {
        name: 'Monte Cristi Province',
        value: 'Monte Cristi Province',
      },
      {
        name: 'Monte Plata Province',
        value: 'Monte Plata Province',
      },
      {
        name: 'Pedernales Province',
        value: 'Pedernales Province',
      },
      {
        name: 'Peravia Province',
        value: 'Peravia Province',
      },
      {
        name: 'Puerto Plata Province',
        value: 'Puerto Plata Province',
      },
      {
        name: 'Samaná Province',
        value: 'Samaná Province',
      },
      {
        name: 'San Cristóbal Province',
        value: 'San Cristóbal Province',
      },
      {
        name: 'San José de Ocoa Province',
        value: 'San José de Ocoa Province',
      },
      {
        name: 'San Juan Province',
        value: 'San Juan Province',
      },
      {
        name: 'San Pedro de Macorís',
        value: 'San Pedro de Macorís',
      },
      {
        name: 'Sánchez Ramírez Province',
        value: 'Sánchez Ramírez Province',
      },
      {
        name: 'Santiago Province',
        value: 'Santiago Province',
      },
      {
        name: 'Santiago Rodríguez Province',
        value: 'Santiago Rodríguez Province',
      },
      {
        name: 'Santo Domingo Province',
        value: 'Santo Domingo Province',
      },
      {
        name: 'Valverde Province',
        value: 'Valverde Province',
      },
    ],
  },
  EC: {
    name: 'Ecuador',
    type: 'province',
    states: [
      {
        name: 'Azuay',
        value: 'Azuay',
      },
      {
        name: 'Bolívar',
        value: 'Bolívar',
      },
      {
        name: 'Cañar',
        value: 'Cañar',
      },
      {
        name: 'Carchi',
        value: 'Carchi',
      },
      {
        name: 'Chimborazo',
        value: 'Chimborazo',
      },
      {
        name: 'Cotopaxi',
        value: 'Cotopaxi',
      },
      {
        name: 'El Oro',
        value: 'El Oro',
      },
      {
        name: 'Esmeraldas',
        value: 'Esmeraldas',
      },
      {
        name: 'Galápagos',
        value: 'Galápagos',
      },
      {
        name: 'Guayas',
        value: 'Guayas',
      },
      {
        name: 'Imbabura',
        value: 'Imbabura',
      },
      {
        name: 'Loja',
        value: 'Loja',
      },
      {
        name: 'Los Ríos',
        value: 'Los Ríos',
      },
      {
        name: 'Manabí',
        value: 'Manabí',
      },
      {
        name: 'Morona-Santiago',
        value: 'Morona-Santiago',
      },
      {
        name: 'Napo',
        value: 'Napo',
      },
      {
        name: 'Orellana',
        value: 'Orellana',
      },
      {
        name: 'Pastaza',
        value: 'Pastaza',
      },
      {
        name: 'Pichincha',
        value: 'Pichincha',
      },
      {
        name: 'Santa Elena',
        value: 'Santa Elena',
      },
      {
        name: 'Santo Domingo de los Tsáchilas',
        value: 'Santo Domingo de los Tsáchilas',
      },
      {
        name: 'Sucumbíos',
        value: 'Sucumbíos',
      },
      {
        name: 'Tungurahua',
        value: 'Tungurahua',
      },
      {
        name: 'Zamora Chinchipe',
        value: 'Zamora Chinchipe',
      },
    ],
  },
  EG: {
    name: 'Egypt',
    type: null,
    states: [
      {
        name: 'Alexandria',
        value: 'Alexandria',
      },
      {
        name: 'Aswan',
        value: 'Aswan',
      },
      {
        name: 'Asyut',
        value: 'Asyut',
      },
      {
        name: 'Beheira',
        value: 'Beheira',
      },
      {
        name: 'Beni Suef',
        value: 'Beni Suef',
      },
      {
        name: 'Cairo',
        value: 'Cairo',
      },
      {
        name: 'Dakahlia',
        value: 'Dakahlia',
      },
      {
        name: 'Damietta',
        value: 'Damietta',
      },
      {
        name: 'Faiyum',
        value: 'Faiyum',
      },
      {
        name: 'Gharbia',
        value: 'Gharbia',
      },
      {
        name: 'Giza',
        value: 'Giza',
      },
      {
        name: 'Ismailia',
        value: 'Ismailia',
      },
      {
        name: 'Kafr el-Sheikh',
        value: 'Kafr el-Sheikh',
      },
      {
        name: 'Luxor',
        value: 'Luxor',
      },
      {
        name: 'Matrouh',
        value: 'Matrouh',
      },
      {
        name: 'Minya',
        value: 'Minya',
      },
      {
        name: 'Monufia',
        value: 'Monufia',
      },
      {
        name: 'New Valley',
        value: 'New Valley',
      },
      {
        name: 'North Sinai',
        value: 'North Sinai',
      },
      {
        name: 'Port Said',
        value: 'Port Said',
      },
      {
        name: 'Qalyubia',
        value: 'Qalyubia',
      },
      {
        name: 'Qena',
        value: 'Qena',
      },
      {
        name: 'Red Sea',
        value: 'Red Sea',
      },
      {
        name: 'Sharqia',
        value: 'Sharqia',
      },
      {
        name: 'Sohag',
        value: 'Sohag',
      },
      {
        name: 'South Sinai',
        value: 'South Sinai',
      },
      {
        name: 'Suez',
        value: 'Suez',
      },
    ],
  },
  SV: {
    name: 'El Salvador',
    type: null,
    states: [
      {
        name: 'Ahuachapán Department',
        value: 'Ahuachapán Department',
      },
      {
        name: 'Cabañas Department',
        value: 'Cabañas Department',
      },
      {
        name: 'Chalatenango Department',
        value: 'Chalatenango Department',
      },
      {
        name: 'Cuscatlán Department',
        value: 'Cuscatlán Department',
      },
      {
        name: 'La Libertad Department',
        value: 'La Libertad Department',
      },
      {
        name: 'La Paz Department',
        value: 'La Paz Department',
      },
      {
        name: 'La Unión Department',
        value: 'La Unión Department',
      },
      {
        name: 'Morazán Department',
        value: 'Morazán Department',
      },
      {
        name: 'San Miguel Department',
        value: 'San Miguel Department',
      },
      {
        name: 'San Salvador Department',
        value: 'San Salvador Department',
      },
      {
        name: 'San Vicente Department',
        value: 'San Vicente Department',
      },
      {
        name: 'Santa Ana Department',
        value: 'Santa Ana Department',
      },
      {
        name: 'Sonsonate Department',
        value: 'Sonsonate Department',
      },
      {
        name: 'Usulután Department',
        value: 'Usulután Department',
      },
    ],
  },
  GQ: {
    name: 'Equatorial Guinea',
    type: null,
    states: [
      {
        name: 'Annobón Province',
        value: 'Annobón Province',
      },
      {
        name: 'Bioko Norte Province',
        value: 'Bioko Norte Province',
      },
      {
        name: 'Bioko Sur Province',
        value: 'Bioko Sur Province',
      },
      {
        name: 'Centro Sur Province',
        value: 'Centro Sur Province',
      },
      {
        name: 'Insular Region',
        value: 'Insular Region',
      },
      {
        name: 'Kié-Ntem Province',
        value: 'Kié-Ntem Province',
      },
      {
        name: 'Litoral Province',
        value: 'Litoral Province',
      },
      {
        name: 'Río Muni',
        value: 'Río Muni',
      },
      {
        name: 'Wele-Nzas Province',
        value: 'Wele-Nzas Province',
      },
    ],
  },
  ER: {
    name: 'Eritrea',
    type: null,
    states: [
      {
        name: 'Anseba Region',
        value: 'Anseba Region',
      },
      {
        name: 'Debub Region',
        value: 'Debub Region',
      },
      {
        name: 'Gash-Barka Region',
        value: 'Gash-Barka Region',
      },
      {
        name: 'Maekel Region',
        value: 'Maekel Region',
      },
      {
        name: 'Northern Red Sea Region',
        value: 'Northern Red Sea Region',
      },
      {
        name: 'Southern Red Sea Region',
        value: 'Southern Red Sea Region',
      },
    ],
  },
  EE: {
    name: 'Estonia',
    type: null,
    states: [
      {
        name: 'Harju County',
        value: 'Harju County',
      },
      {
        name: 'Hiiu County',
        value: 'Hiiu County',
      },
      {
        name: 'Ida-Viru County',
        value: 'Ida-Viru County',
      },
      {
        name: 'Järva County',
        value: 'Järva County',
      },
      {
        name: 'Jõgeva County',
        value: 'Jõgeva County',
      },
      {
        name: 'Lääne County',
        value: 'Lääne County',
      },
      {
        name: 'Lääne-Viru County',
        value: 'Lääne-Viru County',
      },
      {
        name: 'Pärnu County',
        value: 'Pärnu County',
      },
      {
        name: 'Põlva County',
        value: 'Põlva County',
      },
      {
        name: 'Rapla County',
        value: 'Rapla County',
      },
      {
        name: 'Saare County',
        value: 'Saare County',
      },
      {
        name: 'Tartu County',
        value: 'Tartu County',
      },
      {
        name: 'Valga County',
        value: 'Valga County',
      },
      {
        name: 'Viljandi County',
        value: 'Viljandi County',
      },
      {
        name: 'Võru County',
        value: 'Võru County',
      },
    ],
  },
  SZ: {
    name: 'Eswatini',
    type: null,
    states: [
      {
        name: 'Hhohho District',
        value: 'Hhohho District',
      },
      {
        name: 'Lubombo District',
        value: 'Lubombo District',
      },
      {
        name: 'Manzini District',
        value: 'Manzini District',
      },
      {
        name: 'Shiselweni District',
        value: 'Shiselweni District',
      },
    ],
  },
  ET: {
    name: 'Ethiopia',
    type: null,
    states: [
      {
        name: 'Addis Ababa',
        value: 'Addis Ababa',
      },
      {
        name: 'Afar Region',
        value: 'Afar Region',
      },
      {
        name: 'Amhara Region',
        value: 'Amhara Region',
      },
      {
        name: 'Benishangul-Gumuz Region',
        value: 'Benishangul-Gumuz Region',
      },
      {
        name: 'Dire Dawa',
        value: 'Dire Dawa',
      },
      {
        name: 'Gambela Region',
        value: 'Gambela Region',
      },
      {
        name: 'Harari Region',
        value: 'Harari Region',
      },
      {
        name: 'Oromia Region',
        value: 'Oromia Region',
      },
      {
        name: 'Somali Region',
        value: 'Somali Region',
      },
      {
        name: "Southern Nations, Nationalities, and Peoples' Region",
        value: "Southern Nations, Nationalities, and Peoples' Region",
      },
      {
        name: 'Tigray Region',
        value: 'Tigray Region',
      },
    ],
  },
  FO: {
    name: 'Faroe Islands',
    type: 'region',
    states: [
      {
        name: 'Eysturoy',
        value: 'Eysturoy',
      },
      {
        name: 'Northern Isles',
        value: 'Northern Isles',
      },
      {
        name: 'Sandoy',
        value: 'Sandoy',
      },
      {
        name: 'Streymoy',
        value: 'Streymoy',
      },
      {
        name: 'Suðuroy',
        value: 'Suðuroy',
      },
      {
        name: 'Vágar',
        value: 'Vágar',
      },
    ],
  },
  FJ: {
    name: 'Fiji Islands',
    type: null,
    states: [
      {
        name: 'Ba',
        value: 'Ba',
      },
      {
        name: 'Bua',
        value: 'Bua',
      },
      {
        name: 'Cakaudrove',
        value: 'Cakaudrove',
      },
      {
        name: 'Central Division',
        value: 'Central Division',
      },
      {
        name: 'Eastern Division',
        value: 'Eastern Division',
      },
      {
        name: 'Kadavu',
        value: 'Kadavu',
      },
      {
        name: 'Lau',
        value: 'Lau',
      },
      {
        name: 'Lomaiviti',
        value: 'Lomaiviti',
      },
      {
        name: 'Macuata',
        value: 'Macuata',
      },
      {
        name: 'Nadroga-Navosa',
        value: 'Nadroga-Navosa',
      },
      {
        name: 'Naitasiri',
        value: 'Naitasiri',
      },
      {
        name: 'Namosi',
        value: 'Namosi',
      },
      {
        name: 'Northern Division',
        value: 'Northern Division',
      },
      {
        name: 'Ra',
        value: 'Ra',
      },
      {
        name: 'Rewa',
        value: 'Rewa',
      },
      {
        name: 'Rotuma',
        value: 'Rotuma',
      },
      {
        name: 'Serua',
        value: 'Serua',
      },
      {
        name: 'Tailevu',
        value: 'Tailevu',
      },
      {
        name: 'Western Division',
        value: 'Western Division',
      },
    ],
  },
  FI: {
    name: 'Finland',
    type: 'region',
    states: [
      {
        name: 'Åland Islands',
        value: 'Åland Islands',
      },
      {
        name: 'Central Finland',
        value: 'Central Finland',
      },
      {
        name: 'Central Ostrobothnia',
        value: 'Central Ostrobothnia',
      },
      {
        name: 'Finland Proper',
        value: 'Finland Proper',
      },
      {
        name: 'Kainuu',
        value: 'Kainuu',
      },
      {
        name: 'Kymenlaakso',
        value: 'Kymenlaakso',
      },
      {
        name: 'Lapland',
        value: 'Lapland',
      },
      {
        name: 'North Karelia',
        value: 'North Karelia',
      },
      {
        name: 'Northern Ostrobothnia',
        value: 'Northern Ostrobothnia',
      },
      {
        name: 'Northern Savonia',
        value: 'Northern Savonia',
      },
      {
        name: 'Ostrobothnia',
        value: 'Ostrobothnia',
      },
      {
        name: 'Päijänne Tavastia',
        value: 'Päijänne Tavastia',
      },
      {
        name: 'Pirkanmaa',
        value: 'Pirkanmaa',
      },
      {
        name: 'Satakunta',
        value: 'Satakunta',
      },
      {
        name: 'South Karelia',
        value: 'South Karelia',
      },
      {
        name: 'Southern Ostrobothnia',
        value: 'Southern Ostrobothnia',
      },
      {
        name: 'Southern Savonia',
        value: 'Southern Savonia',
      },
      {
        name: 'Tavastia Proper',
        value: 'Tavastia Proper',
      },
      {
        name: 'Uusimaa',
        value: 'Uusimaa',
      },
    ],
  },
  FR: {
    name: 'France',
    type: 'metropolitan department',
    states: [
      {
        name: 'Ain',
        value: 'Ain',
      },
      {
        name: 'Aisne',
        value: 'Aisne',
      },
      {
        name: 'Allier',
        value: 'Allier',
      },
      {
        name: 'Alpes-de-Haute-Provence',
        value: 'Alpes-de-Haute-Provence',
      },
      {
        name: 'Alpes-Maritimes',
        value: 'Alpes-Maritimes',
      },
      {
        name: 'Alsace',
        value: 'Alsace',
      },
      {
        name: 'Ardèche',
        value: 'Ardèche',
      },
      {
        name: 'Ardennes',
        value: 'Ardennes',
      },
      {
        name: 'Ariège',
        value: 'Ariège',
      },
      {
        name: 'Aube',
        value: 'Aube',
      },
      {
        name: 'Aude',
        value: 'Aude',
      },
      {
        name: 'Auvergne-Rhône-Alpes',
        value: 'Auvergne-Rhône-Alpes',
      },
      {
        name: 'Aveyron',
        value: 'Aveyron',
      },
      {
        name: 'Bas-Rhin',
        value: 'Bas-Rhin',
      },
      {
        name: 'Bouches-du-Rhône',
        value: 'Bouches-du-Rhône',
      },
      {
        name: 'Bourgogne-Franche-Comté',
        value: 'Bourgogne-Franche-Comté',
      },
      {
        name: 'Bretagne',
        value: 'Bretagne',
      },
      {
        name: 'Calvados',
        value: 'Calvados',
      },
      {
        name: 'Cantal',
        value: 'Cantal',
      },
      {
        name: 'Centre-Val de Loire',
        value: 'Centre-Val de Loire',
      },
      {
        name: 'Charente',
        value: 'Charente',
      },
      {
        name: 'Charente-Maritime',
        value: 'Charente-Maritime',
      },
      {
        name: 'Cher',
        value: 'Cher',
      },
      {
        name: 'Clipperton',
        value: 'Clipperton',
      },
      {
        name: 'Corrèze',
        value: 'Corrèze',
      },
      {
        name: 'Corse',
        value: 'Corse',
      },
      {
        name: 'Corse-du-Sud',
        value: 'Corse-du-Sud',
      },
      {
        name: "Côte-d'Or",
        value: "Côte-d'Or",
      },
      {
        name: "Côtes-d'Armor",
        value: "Côtes-d'Armor",
      },
      {
        name: 'Creuse',
        value: 'Creuse',
      },
      {
        name: 'Deux-Sèvres',
        value: 'Deux-Sèvres',
      },
      {
        name: 'Dordogne',
        value: 'Dordogne',
      },
      {
        name: 'Doubs',
        value: 'Doubs',
      },
      {
        name: 'Drôme',
        value: 'Drôme',
      },
      {
        name: 'Essonne',
        value: 'Essonne',
      },
      {
        name: 'Eure',
        value: 'Eure',
      },
      {
        name: 'Eure-et-Loir',
        value: 'Eure-et-Loir',
      },
      {
        name: 'Finistère',
        value: 'Finistère',
      },
      {
        name: 'French Guiana',
        value: 'French Guiana',
      },
      {
        name: 'French Polynesia',
        value: 'French Polynesia',
      },
      {
        name: 'French Southern and Antarctic Lands',
        value: 'French Southern and Antarctic Lands',
      },
      {
        name: 'Gard',
        value: 'Gard',
      },
      {
        name: 'Gers',
        value: 'Gers',
      },
      {
        name: 'Gironde',
        value: 'Gironde',
      },
      {
        name: 'Grand-Est',
        value: 'Grand-Est',
      },
      {
        name: 'Guadeloupe',
        value: 'Guadeloupe',
      },
      {
        name: 'Haut-Rhin',
        value: 'Haut-Rhin',
      },
      {
        name: 'Haute-Corse',
        value: 'Haute-Corse',
      },
      {
        name: 'Haute-Garonne',
        value: 'Haute-Garonne',
      },
      {
        name: 'Haute-Loire',
        value: 'Haute-Loire',
      },
      {
        name: 'Haute-Marne',
        value: 'Haute-Marne',
      },
      {
        name: 'Haute-Saône',
        value: 'Haute-Saône',
      },
      {
        name: 'Haute-Savoie',
        value: 'Haute-Savoie',
      },
      {
        name: 'Haute-Vienne',
        value: 'Haute-Vienne',
      },
      {
        name: 'Hautes-Alpes',
        value: 'Hautes-Alpes',
      },
      {
        name: 'Hautes-Pyrénées',
        value: 'Hautes-Pyrénées',
      },
      {
        name: 'Hauts-de-France',
        value: 'Hauts-de-France',
      },
      {
        name: 'Hauts-de-Seine',
        value: 'Hauts-de-Seine',
      },
      {
        name: 'Hérault',
        value: 'Hérault',
      },
      {
        name: 'Île-de-France',
        value: 'Île-de-France',
      },
      {
        name: 'Ille-et-Vilaine',
        value: 'Ille-et-Vilaine',
      },
      {
        name: 'Indre',
        value: 'Indre',
      },
      {
        name: 'Indre-et-Loire',
        value: 'Indre-et-Loire',
      },
      {
        name: 'Isère',
        value: 'Isère',
      },
      {
        name: 'Jura',
        value: 'Jura',
      },
      {
        name: 'La Réunion',
        value: 'La Réunion',
      },
      {
        name: 'Landes',
        value: 'Landes',
      },
      {
        name: 'Loir-et-Cher',
        value: 'Loir-et-Cher',
      },
      {
        name: 'Loire',
        value: 'Loire',
      },
      {
        name: 'Loire-Atlantique',
        value: 'Loire-Atlantique',
      },
      {
        name: 'Loiret',
        value: 'Loiret',
      },
      {
        name: 'Lot',
        value: 'Lot',
      },
      {
        name: 'Lot-et-Garonne',
        value: 'Lot-et-Garonne',
      },
      {
        name: 'Lozère',
        value: 'Lozère',
      },
      {
        name: 'Maine-et-Loire',
        value: 'Maine-et-Loire',
      },
      {
        name: 'Manche',
        value: 'Manche',
      },
      {
        name: 'Marne',
        value: 'Marne',
      },
      {
        name: 'Martinique',
        value: 'Martinique',
      },
      {
        name: 'Mayenne',
        value: 'Mayenne',
      },
      {
        name: 'Mayotte',
        value: 'Mayotte',
      },
      {
        name: 'Métropole de Lyon',
        value: 'Métropole de Lyon',
      },
      {
        name: 'Meurthe-et-Moselle',
        value: 'Meurthe-et-Moselle',
      },
      {
        name: 'Meuse',
        value: 'Meuse',
      },
      {
        name: 'Morbihan',
        value: 'Morbihan',
      },
      {
        name: 'Moselle',
        value: 'Moselle',
      },
      {
        name: 'Nièvre',
        value: 'Nièvre',
      },
      {
        name: 'Nord',
        value: 'Nord',
      },
      {
        name: 'Normandie',
        value: 'Normandie',
      },
      {
        name: 'Nouvelle-Aquitaine',
        value: 'Nouvelle-Aquitaine',
      },
      {
        name: 'Occitanie',
        value: 'Occitanie',
      },
      {
        name: 'Oise',
        value: 'Oise',
      },
      {
        name: 'Orne',
        value: 'Orne',
      },
      {
        name: 'Paris',
        value: 'Paris',
      },
      {
        name: 'Pas-de-Calais',
        value: 'Pas-de-Calais',
      },
      {
        name: 'Pays-de-la-Loire',
        value: 'Pays-de-la-Loire',
      },
      {
        name: 'Provence-Alpes-Côte-d’Azur',
        value: 'Provence-Alpes-Côte-d’Azur',
      },
      {
        name: 'Puy-de-Dôme',
        value: 'Puy-de-Dôme',
      },
      {
        name: 'Pyrénées-Atlantiques',
        value: 'Pyrénées-Atlantiques',
      },
      {
        name: 'Pyrénées-Orientales',
        value: 'Pyrénées-Orientales',
      },
      {
        name: 'Rhône',
        value: 'Rhône',
      },
      {
        name: 'Saint Pierre and Miquelon',
        value: 'Saint Pierre and Miquelon',
      },
      {
        name: 'Saint-Barthélemy',
        value: 'Saint-Barthélemy',
      },
      {
        name: 'Saint-Martin',
        value: 'Saint-Martin',
      },
      {
        name: 'Saône-et-Loire',
        value: 'Saône-et-Loire',
      },
      {
        name: 'Sarthe',
        value: 'Sarthe',
      },
      {
        name: 'Savoie',
        value: 'Savoie',
      },
      {
        name: 'Seine-et-Marne',
        value: 'Seine-et-Marne',
      },
      {
        name: 'Seine-Maritime',
        value: 'Seine-Maritime',
      },
      {
        name: 'Seine-Saint-Denis',
        value: 'Seine-Saint-Denis',
      },
      {
        name: 'Somme',
        value: 'Somme',
      },
      {
        name: 'Tarn',
        value: 'Tarn',
      },
      {
        name: 'Tarn-et-Garonne',
        value: 'Tarn-et-Garonne',
      },
      {
        name: 'Territoire de Belfort',
        value: 'Territoire de Belfort',
      },
      {
        name: "Val-d'Oise",
        value: "Val-d'Oise",
      },
      {
        name: 'Val-de-Marne',
        value: 'Val-de-Marne',
      },
      {
        name: 'Var',
        value: 'Var',
      },
      {
        name: 'Vaucluse',
        value: 'Vaucluse',
      },
      {
        name: 'Vendée',
        value: 'Vendée',
      },
      {
        name: 'Vienne',
        value: 'Vienne',
      },
      {
        name: 'Vosges',
        value: 'Vosges',
      },
      {
        name: 'Wallis and Futuna',
        value: 'Wallis and Futuna',
      },
      {
        name: 'Yonne',
        value: 'Yonne',
      },
      {
        name: 'Yvelines',
        value: 'Yvelines',
      },
    ],
  },
  GA: {
    name: 'Gabon',
    type: null,
    states: [
      {
        name: 'Estuaire Province',
        value: 'Estuaire Province',
      },
      {
        name: 'Haut-Ogooué Province',
        value: 'Haut-Ogooué Province',
      },
      {
        name: 'Moyen-Ogooué Province',
        value: 'Moyen-Ogooué Province',
      },
      {
        name: 'Ngounié Province',
        value: 'Ngounié Province',
      },
      {
        name: 'Nyanga Province',
        value: 'Nyanga Province',
      },
      {
        name: 'Ogooué-Ivindo Province',
        value: 'Ogooué-Ivindo Province',
      },
      {
        name: 'Ogooué-Lolo Province',
        value: 'Ogooué-Lolo Province',
      },
      {
        name: 'Ogooué-Maritime Province',
        value: 'Ogooué-Maritime Province',
      },
      {
        name: 'Woleu-Ntem Province',
        value: 'Woleu-Ntem Province',
      },
    ],
  },
  GM: {
    name: 'Gambia The',
    type: null,
    states: [
      {
        name: 'Banjul',
        value: 'Banjul',
      },
      {
        name: 'Central River Division',
        value: 'Central River Division',
      },
      {
        name: 'Lower River Division',
        value: 'Lower River Division',
      },
      {
        name: 'North Bank Division',
        value: 'North Bank Division',
      },
      {
        name: 'Upper River Division',
        value: 'Upper River Division',
      },
      {
        name: 'West Coast Division',
        value: 'West Coast Division',
      },
    ],
  },
  GE: {
    name: 'Georgia',
    type: null,
    states: [
      {
        name: 'Abkhazia',
        value: 'Abkhazia',
      },
      {
        name: 'Adjara',
        value: 'Adjara',
      },
      {
        name: 'Guria',
        value: 'Guria',
      },
      {
        name: 'Imereti',
        value: 'Imereti',
      },
      {
        name: 'Kakheti',
        value: 'Kakheti',
      },
      {
        name: 'Khelvachauri Municipality',
        value: 'Khelvachauri Municipality',
      },
      {
        name: 'Kvemo Kartli',
        value: 'Kvemo Kartli',
      },
      {
        name: 'Mtskheta-Mtianeti',
        value: 'Mtskheta-Mtianeti',
      },
      {
        name: 'Racha-Lechkhumi and Kvemo Svaneti',
        value: 'Racha-Lechkhumi and Kvemo Svaneti',
      },
      {
        name: 'Samegrelo-Zemo Svaneti',
        value: 'Samegrelo-Zemo Svaneti',
      },
      {
        name: 'Samtskhe-Javakheti',
        value: 'Samtskhe-Javakheti',
      },
      {
        name: 'Senaki Municipality',
        value: 'Senaki Municipality',
      },
      {
        name: 'Shida Kartli',
        value: 'Shida Kartli',
      },
      {
        name: 'Tbilisi',
        value: 'Tbilisi',
      },
    ],
  },
  DE: {
    name: 'Germany',
    type: null,
    states: [
      {
        name: 'Baden-Württemberg',
        value: 'Baden-Württemberg',
      },
      {
        name: 'Bavaria',
        value: 'Bavaria',
      },
      {
        name: 'Berlin',
        value: 'Berlin',
      },
      {
        name: 'Brandenburg',
        value: 'Brandenburg',
      },
      {
        name: 'Bremen',
        value: 'Bremen',
      },
      {
        name: 'Hamburg',
        value: 'Hamburg',
      },
      {
        name: 'Hesse',
        value: 'Hesse',
      },
      {
        name: 'Lower Saxony',
        value: 'Lower Saxony',
      },
      {
        name: 'Mecklenburg-Vorpommern',
        value: 'Mecklenburg-Vorpommern',
      },
      {
        name: 'North Rhine-Westphalia',
        value: 'North Rhine-Westphalia',
      },
      {
        name: 'Rhineland-Palatinate',
        value: 'Rhineland-Palatinate',
      },
      {
        name: 'Saarland',
        value: 'Saarland',
      },
      {
        name: 'Saxony',
        value: 'Saxony',
      },
      {
        name: 'Saxony-Anhalt',
        value: 'Saxony-Anhalt',
      },
      {
        name: 'Schleswig-Holstein',
        value: 'Schleswig-Holstein',
      },
      {
        name: 'Thuringia',
        value: 'Thuringia',
      },
    ],
  },
  GH: {
    name: 'Ghana',
    type: 'region',
    states: [
      {
        name: 'Ahafo',
        value: 'Ahafo',
      },
      {
        name: 'Ashanti',
        value: 'Ashanti',
      },
      {
        name: 'Bono',
        value: 'Bono',
      },
      {
        name: 'Bono East',
        value: 'Bono East',
      },
      {
        name: 'Central',
        value: 'Central',
      },
      {
        name: 'Eastern',
        value: 'Eastern',
      },
      {
        name: 'Greater Accra',
        value: 'Greater Accra',
      },
      {
        name: 'North East',
        value: 'North East',
      },
      {
        name: 'Northern',
        value: 'Northern',
      },
      {
        name: 'Oti',
        value: 'Oti',
      },
      {
        name: 'Savannah',
        value: 'Savannah',
      },
      {
        name: 'Upper East',
        value: 'Upper East',
      },
      {
        name: 'Upper West',
        value: 'Upper West',
      },
      {
        name: 'Volta',
        value: 'Volta',
      },
      {
        name: 'Western',
        value: 'Western',
      },
      {
        name: 'Western North',
        value: 'Western North',
      },
    ],
  },
  GR: {
    name: 'Greece',
    type: null,
    states: [
      {
        name: 'Achaea Regional Unit',
        value: 'Achaea Regional Unit',
      },
      {
        name: 'Aetolia-Acarnania Regional Unit',
        value: 'Aetolia-Acarnania Regional Unit',
      },
      {
        name: 'Arcadia Prefecture',
        value: 'Arcadia Prefecture',
      },
      {
        name: 'Argolis Regional Unit',
        value: 'Argolis Regional Unit',
      },
      {
        name: 'Attica Region',
        value: 'Attica Region',
      },
      {
        name: 'Boeotia Regional Unit',
        value: 'Boeotia Regional Unit',
      },
      {
        name: 'Central Greece Region',
        value: 'Central Greece Region',
      },
      {
        name: 'Central Macedonia',
        value: 'Central Macedonia',
      },
      {
        name: 'Chania Regional Unit',
        value: 'Chania Regional Unit',
      },
      {
        name: 'Corfu Prefecture',
        value: 'Corfu Prefecture',
      },
      {
        name: 'Corinthia Regional Unit',
        value: 'Corinthia Regional Unit',
      },
      {
        name: 'Crete Region',
        value: 'Crete Region',
      },
      {
        name: 'Drama Regional Unit',
        value: 'Drama Regional Unit',
      },
      {
        name: 'East Attica Regional Unit',
        value: 'East Attica Regional Unit',
      },
      {
        name: 'East Macedonia and Thrace',
        value: 'East Macedonia and Thrace',
      },
      {
        name: 'Epirus Region',
        value: 'Epirus Region',
      },
      {
        name: 'Euboea',
        value: 'Euboea',
      },
      {
        name: 'Grevena Prefecture',
        value: 'Grevena Prefecture',
      },
      {
        name: 'Imathia Regional Unit',
        value: 'Imathia Regional Unit',
      },
      {
        name: 'Ioannina Regional Unit',
        value: 'Ioannina Regional Unit',
      },
      {
        name: 'Ionian Islands Region',
        value: 'Ionian Islands Region',
      },
      {
        name: 'Karditsa Regional Unit',
        value: 'Karditsa Regional Unit',
      },
      {
        name: 'Kastoria Regional Unit',
        value: 'Kastoria Regional Unit',
      },
      {
        name: 'Kefalonia Prefecture',
        value: 'Kefalonia Prefecture',
      },
      {
        name: 'Kilkis Regional Unit',
        value: 'Kilkis Regional Unit',
      },
      {
        name: 'Kozani Prefecture',
        value: 'Kozani Prefecture',
      },
      {
        name: 'Laconia',
        value: 'Laconia',
      },
      {
        name: 'Larissa Prefecture',
        value: 'Larissa Prefecture',
      },
      {
        name: 'Lefkada Regional Unit',
        value: 'Lefkada Regional Unit',
      },
      {
        name: 'Pella Regional Unit',
        value: 'Pella Regional Unit',
      },
      {
        name: 'Peloponnese Region',
        value: 'Peloponnese Region',
      },
      {
        name: 'Phthiotis Prefecture',
        value: 'Phthiotis Prefecture',
      },
      {
        name: 'Preveza Prefecture',
        value: 'Preveza Prefecture',
      },
      {
        name: 'Serres Prefecture',
        value: 'Serres Prefecture',
      },
      {
        name: 'South Aegean',
        value: 'South Aegean',
      },
      {
        name: 'Thessaloniki Regional Unit',
        value: 'Thessaloniki Regional Unit',
      },
      {
        name: 'West Greece Region',
        value: 'West Greece Region',
      },
      {
        name: 'West Macedonia Region',
        value: 'West Macedonia Region',
      },
    ],
  },
  GD: {
    name: 'Grenada',
    type: null,
    states: [
      {
        name: 'Carriacou and Petite Martinique',
        value: 'Carriacou and Petite Martinique',
      },
      {
        name: 'Saint Andrew Parish',
        value: 'Saint Andrew Parish',
      },
      {
        name: 'Saint David Parish',
        value: 'Saint David Parish',
      },
      {
        name: 'Saint George Parish',
        value: 'Saint George Parish',
      },
      {
        name: 'Saint John Parish',
        value: 'Saint John Parish',
      },
      {
        name: 'Saint Mark Parish',
        value: 'Saint Mark Parish',
      },
      {
        name: 'Saint Patrick Parish',
        value: 'Saint Patrick Parish',
      },
    ],
  },
  GT: {
    name: 'Guatemala',
    type: null,
    states: [
      {
        name: 'Alta Verapaz Department',
        value: 'Alta Verapaz Department',
      },
      {
        name: 'Baja Verapaz Department',
        value: 'Baja Verapaz Department',
      },
      {
        name: 'Chimaltenango Department',
        value: 'Chimaltenango Department',
      },
      {
        name: 'Chiquimula Department',
        value: 'Chiquimula Department',
      },
      {
        name: 'El Progreso Department',
        value: 'El Progreso Department',
      },
      {
        name: 'Escuintla Department',
        value: 'Escuintla Department',
      },
      {
        name: 'Guatemala Department',
        value: 'Guatemala Department',
      },
      {
        name: 'Huehuetenango Department',
        value: 'Huehuetenango Department',
      },
      {
        name: 'Izabal Department',
        value: 'Izabal Department',
      },
      {
        name: 'Jalapa Department',
        value: 'Jalapa Department',
      },
      {
        name: 'Jutiapa Department',
        value: 'Jutiapa Department',
      },
      {
        name: 'Petén Department',
        value: 'Petén Department',
      },
      {
        name: 'Quetzaltenango Department',
        value: 'Quetzaltenango Department',
      },
      {
        name: 'Quiché Department',
        value: 'Quiché Department',
      },
      {
        name: 'Retalhuleu Department',
        value: 'Retalhuleu Department',
      },
      {
        name: 'Sacatepéquez Department',
        value: 'Sacatepéquez Department',
      },
      {
        name: 'San Marcos Department',
        value: 'San Marcos Department',
      },
      {
        name: 'Santa Rosa Department',
        value: 'Santa Rosa Department',
      },
      {
        name: 'Sololá Department',
        value: 'Sololá Department',
      },
      {
        name: 'Suchitepéquez Department',
        value: 'Suchitepéquez Department',
      },
      {
        name: 'Totonicapán Department',
        value: 'Totonicapán Department',
      },
    ],
  },
  GN: {
    name: 'Guinea',
    type: null,
    states: [
      {
        name: 'Beyla Prefecture',
        value: 'Beyla Prefecture',
      },
      {
        name: 'Boffa Prefecture',
        value: 'Boffa Prefecture',
      },
      {
        name: 'Boké Prefecture',
        value: 'Boké Prefecture',
      },
      {
        name: 'Boké Region',
        value: 'Boké Region',
      },
      {
        name: 'Conakry',
        value: 'Conakry',
      },
      {
        name: 'Coyah Prefecture',
        value: 'Coyah Prefecture',
      },
      {
        name: 'Dabola Prefecture',
        value: 'Dabola Prefecture',
      },
      {
        name: 'Dalaba Prefecture',
        value: 'Dalaba Prefecture',
      },
      {
        name: 'Dinguiraye Prefecture',
        value: 'Dinguiraye Prefecture',
      },
      {
        name: 'Dubréka Prefecture',
        value: 'Dubréka Prefecture',
      },
      {
        name: 'Faranah Prefecture',
        value: 'Faranah Prefecture',
      },
      {
        name: 'Forécariah Prefecture',
        value: 'Forécariah Prefecture',
      },
      {
        name: 'Fria Prefecture',
        value: 'Fria Prefecture',
      },
      {
        name: 'Gaoual Prefecture',
        value: 'Gaoual Prefecture',
      },
      {
        name: 'Guéckédou Prefecture',
        value: 'Guéckédou Prefecture',
      },
      {
        name: 'Kankan Prefecture',
        value: 'Kankan Prefecture',
      },
      {
        name: 'Kankan Region',
        value: 'Kankan Region',
      },
      {
        name: 'Kérouané Prefecture',
        value: 'Kérouané Prefecture',
      },
      {
        name: 'Kindia Prefecture',
        value: 'Kindia Prefecture',
      },
      {
        name: 'Kindia Region',
        value: 'Kindia Region',
      },
      {
        name: 'Kissidougou Prefecture',
        value: 'Kissidougou Prefecture',
      },
      {
        name: 'Koubia Prefecture',
        value: 'Koubia Prefecture',
      },
      {
        name: 'Koundara Prefecture',
        value: 'Koundara Prefecture',
      },
      {
        name: 'Kouroussa Prefecture',
        value: 'Kouroussa Prefecture',
      },
      {
        name: 'Labé Prefecture',
        value: 'Labé Prefecture',
      },
      {
        name: 'Labé Region',
        value: 'Labé Region',
      },
      {
        name: 'Lélouma Prefecture',
        value: 'Lélouma Prefecture',
      },
      {
        name: 'Lola Prefecture',
        value: 'Lola Prefecture',
      },
      {
        name: 'Macenta Prefecture',
        value: 'Macenta Prefecture',
      },
      {
        name: 'Mali Prefecture',
        value: 'Mali Prefecture',
      },
      {
        name: 'Mamou Prefecture',
        value: 'Mamou Prefecture',
      },
      {
        name: 'Mamou Region',
        value: 'Mamou Region',
      },
      {
        name: 'Mandiana Prefecture',
        value: 'Mandiana Prefecture',
      },
      {
        name: 'Nzérékoré Prefecture',
        value: 'Nzérékoré Prefecture',
      },
      {
        name: 'Nzérékoré Region',
        value: 'Nzérékoré Region',
      },
      {
        name: 'Pita Prefecture',
        value: 'Pita Prefecture',
      },
      {
        name: 'Siguiri Prefecture',
        value: 'Siguiri Prefecture',
      },
      {
        name: 'Télimélé Prefecture',
        value: 'Télimélé Prefecture',
      },
      {
        name: 'Tougué Prefecture',
        value: 'Tougué Prefecture',
      },
      {
        name: 'Yomou Prefecture',
        value: 'Yomou Prefecture',
      },
    ],
  },
  GW: {
    name: 'Guinea-Bissau',
    type: null,
    states: [
      {
        name: 'Bafatá',
        value: 'Bafatá',
      },
      {
        name: 'Biombo Region',
        value: 'Biombo Region',
      },
      {
        name: 'Bolama Region',
        value: 'Bolama Region',
      },
      {
        name: 'Cacheu Region',
        value: 'Cacheu Region',
      },
      {
        name: 'Gabú Region',
        value: 'Gabú Region',
      },
      {
        name: 'Leste Province',
        value: 'Leste Province',
      },
      {
        name: 'Norte Province',
        value: 'Norte Province',
      },
      {
        name: 'Oio Region',
        value: 'Oio Region',
      },
      {
        name: 'Quinara Region',
        value: 'Quinara Region',
      },
      {
        name: 'Sul Province',
        value: 'Sul Province',
      },
      {
        name: 'Tombali Region',
        value: 'Tombali Region',
      },
    ],
  },
  GY: {
    name: 'Guyana',
    type: null,
    states: [
      {
        name: 'Barima-Waini',
        value: 'Barima-Waini',
      },
      {
        name: 'Cuyuni-Mazaruni',
        value: 'Cuyuni-Mazaruni',
      },
      {
        name: 'Demerara-Mahaica',
        value: 'Demerara-Mahaica',
      },
      {
        name: 'East Berbice-Corentyne',
        value: 'East Berbice-Corentyne',
      },
      {
        name: 'Essequibo Islands-West Demerara',
        value: 'Essequibo Islands-West Demerara',
      },
      {
        name: 'Mahaica-Berbice',
        value: 'Mahaica-Berbice',
      },
      {
        name: 'Pomeroon-Supenaam',
        value: 'Pomeroon-Supenaam',
      },
      {
        name: 'Potaro-Siparuni',
        value: 'Potaro-Siparuni',
      },
      {
        name: 'Upper Demerara-Berbice',
        value: 'Upper Demerara-Berbice',
      },
      {
        name: 'Upper Takutu-Upper Essequibo',
        value: 'Upper Takutu-Upper Essequibo',
      },
    ],
  },
  HT: {
    name: 'Haiti',
    type: null,
    states: [
      {
        name: 'Artibonite',
        value: 'Artibonite',
      },
      {
        name: 'Centre',
        value: 'Centre',
      },
      {
        name: "Grand'Anse",
        value: "Grand'Anse",
      },
      {
        name: 'Nippes',
        value: 'Nippes',
      },
      {
        name: 'Nord',
        value: 'Nord',
      },
      {
        name: 'Nord-Est',
        value: 'Nord-Est',
      },
      {
        name: 'Nord-Ouest',
        value: 'Nord-Ouest',
      },
      {
        name: 'Ouest',
        value: 'Ouest',
      },
      {
        name: 'Sud',
        value: 'Sud',
      },
      {
        name: 'Sud-Est',
        value: 'Sud-Est',
      },
    ],
  },
  HN: {
    name: 'Honduras',
    type: null,
    states: [
      {
        name: 'Atlántida Department',
        value: 'Atlántida Department',
      },
      {
        name: 'Bay Islands Department',
        value: 'Bay Islands Department',
      },
      {
        name: 'Choluteca Department',
        value: 'Choluteca Department',
      },
      {
        name: 'Colón Department',
        value: 'Colón Department',
      },
      {
        name: 'Comayagua Department',
        value: 'Comayagua Department',
      },
      {
        name: 'Copán Department',
        value: 'Copán Department',
      },
      {
        name: 'Cortés Department',
        value: 'Cortés Department',
      },
      {
        name: 'El Paraíso Department',
        value: 'El Paraíso Department',
      },
      {
        name: 'Francisco Morazán Department',
        value: 'Francisco Morazán Department',
      },
      {
        name: 'Gracias a Dios Department',
        value: 'Gracias a Dios Department',
      },
      {
        name: 'Intibucá Department',
        value: 'Intibucá Department',
      },
      {
        name: 'La Paz Department',
        value: 'La Paz Department',
      },
      {
        name: 'Lempira Department',
        value: 'Lempira Department',
      },
      {
        name: 'Ocotepeque Department',
        value: 'Ocotepeque Department',
      },
      {
        name: 'Olancho Department',
        value: 'Olancho Department',
      },
      {
        name: 'Santa Bárbara Department',
        value: 'Santa Bárbara Department',
      },
      {
        name: 'Valle Department',
        value: 'Valle Department',
      },
      {
        name: 'Yoro Department',
        value: 'Yoro Department',
      },
    ],
  },
  HK: {
    name: 'Hong Kong S.A.R.',
    type: 'district',
    states: [
      {
        name: 'Central and Western',
        value: 'Central and Western',
      },
      {
        name: 'Eastern',
        value: 'Eastern',
      },
      {
        name: 'Islands',
        value: 'Islands',
      },
      {
        name: 'Kowloon City',
        value: 'Kowloon City',
      },
      {
        name: 'Kwai Tsing',
        value: 'Kwai Tsing',
      },
      {
        name: 'Kwun Tong',
        value: 'Kwun Tong',
      },
      {
        name: 'North',
        value: 'North',
      },
      {
        name: 'Sai Kung',
        value: 'Sai Kung',
      },
      {
        name: 'Sha Tin',
        value: 'Sha Tin',
      },
      {
        name: 'Sham Shui Po',
        value: 'Sham Shui Po',
      },
      {
        name: 'Southern',
        value: 'Southern',
      },
      {
        name: 'Tai Po',
        value: 'Tai Po',
      },
      {
        name: 'Tsuen Wan',
        value: 'Tsuen Wan',
      },
      {
        name: 'Tuen Mun',
        value: 'Tuen Mun',
      },
      {
        name: 'Wan Chai',
        value: 'Wan Chai',
      },
      {
        name: 'Wong Tai Sin',
        value: 'Wong Tai Sin',
      },
      {
        name: 'Yau Tsim Mong',
        value: 'Yau Tsim Mong',
      },
      {
        name: 'Yuen Long',
        value: 'Yuen Long',
      },
    ],
  },
  HU: {
    name: 'Hungary',
    type: 'county',
    states: [
      {
        name: 'Bács-Kiskun',
        value: 'Bács-Kiskun',
      },
      {
        name: 'Baranya',
        value: 'Baranya',
      },
      {
        name: 'Békés',
        value: 'Békés',
      },
      {
        name: 'Békéscsaba',
        value: 'Békéscsaba',
      },
      {
        name: 'Borsod-Abaúj-Zemplén',
        value: 'Borsod-Abaúj-Zemplén',
      },
      {
        name: 'Budapest',
        value: 'Budapest',
      },
      {
        name: 'Csongrád County',
        value: 'Csongrád County',
      },
      {
        name: 'Debrecen',
        value: 'Debrecen',
      },
      {
        name: 'Dunaújváros',
        value: 'Dunaújváros',
      },
      {
        name: 'Eger',
        value: 'Eger',
      },
      {
        name: 'Érd',
        value: 'Érd',
      },
      {
        name: 'Fejér County',
        value: 'Fejér County',
      },
      {
        name: 'Győr',
        value: 'Győr',
      },
      {
        name: 'Győr-Moson-Sopron County',
        value: 'Győr-Moson-Sopron County',
      },
      {
        name: 'Hajdú-Bihar County',
        value: 'Hajdú-Bihar County',
      },
      {
        name: 'Heves County',
        value: 'Heves County',
      },
      {
        name: 'Hódmezővásárhely',
        value: 'Hódmezővásárhely',
      },
      {
        name: 'Jász-Nagykun-Szolnok County',
        value: 'Jász-Nagykun-Szolnok County',
      },
      {
        name: 'Kaposvár',
        value: 'Kaposvár',
      },
      {
        name: 'Kecskemét',
        value: 'Kecskemét',
      },
      {
        name: 'Komárom-Esztergom',
        value: 'Komárom-Esztergom',
      },
      {
        name: 'Miskolc',
        value: 'Miskolc',
      },
      {
        name: 'Nagykanizsa',
        value: 'Nagykanizsa',
      },
      {
        name: 'Nógrád County',
        value: 'Nógrád County',
      },
      {
        name: 'Nyíregyháza',
        value: 'Nyíregyháza',
      },
      {
        name: 'Pécs',
        value: 'Pécs',
      },
      {
        name: 'Pest County',
        value: 'Pest County',
      },
      {
        name: 'Salgótarján',
        value: 'Salgótarján',
      },
      {
        name: 'Somogy County',
        value: 'Somogy County',
      },
      {
        name: 'Sopron',
        value: 'Sopron',
      },
      {
        name: 'Szabolcs-Szatmár-Bereg County',
        value: 'Szabolcs-Szatmár-Bereg County',
      },
      {
        name: 'Szeged',
        value: 'Szeged',
      },
      {
        name: 'Székesfehérvár',
        value: 'Székesfehérvár',
      },
      {
        name: 'Szekszárd',
        value: 'Szekszárd',
      },
      {
        name: 'Szolnok',
        value: 'Szolnok',
      },
      {
        name: 'Szombathely',
        value: 'Szombathely',
      },
      {
        name: 'Tatabánya',
        value: 'Tatabánya',
      },
      {
        name: 'Tolna County',
        value: 'Tolna County',
      },
      {
        name: 'Vas County',
        value: 'Vas County',
      },
      {
        name: 'Veszprém',
        value: 'Veszprém',
      },
      {
        name: 'Veszprém County',
        value: 'Veszprém County',
      },
      {
        name: 'Zala County',
        value: 'Zala County',
      },
      {
        name: 'Zalaegerszeg',
        value: 'Zalaegerszeg',
      },
    ],
  },
  IS: {
    name: 'Iceland',
    type: null,
    states: [
      {
        name: 'Capital Region',
        value: 'Capital Region',
      },
      {
        name: 'Eastern Region',
        value: 'Eastern Region',
      },
      {
        name: 'Northeastern Region',
        value: 'Northeastern Region',
      },
      {
        name: 'Northwestern Region',
        value: 'Northwestern Region',
      },
      {
        name: 'Southern Peninsula Region',
        value: 'Southern Peninsula Region',
      },
      {
        name: 'Southern Region',
        value: 'Southern Region',
      },
      {
        name: 'Western Region',
        value: 'Western Region',
      },
      {
        name: 'Westfjords',
        value: 'Westfjords',
      },
    ],
  },
  IN: {
    name: 'India',
    type: 'state',
    states: [
      {
        name: 'Andaman and Nicobar Islands',
        value: 'Andaman and Nicobar Islands',
      },
      {
        name: 'Andhra Pradesh',
        value: 'Andhra Pradesh',
      },
      {
        name: 'Arunachal Pradesh',
        value: 'Arunachal Pradesh',
      },
      {
        name: 'Assam',
        value: 'Assam',
      },
      {
        name: 'Bihar',
        value: 'Bihar',
      },
      {
        name: 'Chandigarh',
        value: 'Chandigarh',
      },
      {
        name: 'Chhattisgarh',
        value: 'Chhattisgarh',
      },
      {
        name: 'Dadra and Nagar Haveli and Daman and Diu',
        value: 'Dadra and Nagar Haveli and Daman and Diu',
      },
      {
        name: 'Delhi',
        value: 'Delhi',
      },
      {
        name: 'Goa',
        value: 'Goa',
      },
      {
        name: 'Gujarat',
        value: 'Gujarat',
      },
      {
        name: 'Haryana',
        value: 'Haryana',
      },
      {
        name: 'Himachal Pradesh',
        value: 'Himachal Pradesh',
      },
      {
        name: 'Jammu and Kashmir',
        value: 'Jammu and Kashmir',
      },
      {
        name: 'Jharkhand',
        value: 'Jharkhand',
      },
      {
        name: 'Karnataka',
        value: 'Karnataka',
      },
      {
        name: 'Kerala',
        value: 'Kerala',
      },
      {
        name: 'Ladakh',
        value: 'Ladakh',
      },
      {
        name: 'Lakshadweep',
        value: 'Lakshadweep',
      },
      {
        name: 'Madhya Pradesh',
        value: 'Madhya Pradesh',
      },
      {
        name: 'Maharashtra',
        value: 'Maharashtra',
      },
      {
        name: 'Manipur',
        value: 'Manipur',
      },
      {
        name: 'Meghalaya',
        value: 'Meghalaya',
      },
      {
        name: 'Mizoram',
        value: 'Mizoram',
      },
      {
        name: 'Nagaland',
        value: 'Nagaland',
      },
      {
        name: 'Odisha',
        value: 'Odisha',
      },
      {
        name: 'Puducherry',
        value: 'Puducherry',
      },
      {
        name: 'Punjab',
        value: 'Punjab',
      },
      {
        name: 'Rajasthan',
        value: 'Rajasthan',
      },
      {
        name: 'Sikkim',
        value: 'Sikkim',
      },
      {
        name: 'Tamil Nadu',
        value: 'Tamil Nadu',
      },
      {
        name: 'Telangana',
        value: 'Telangana',
      },
      {
        name: 'Tripura',
        value: 'Tripura',
      },
      {
        name: 'Uttar Pradesh',
        value: 'Uttar Pradesh',
      },
      {
        name: 'Uttarakhand',
        value: 'Uttarakhand',
      },
      {
        name: 'West Bengal',
        value: 'West Bengal',
      },
    ],
  },
  ID: {
    name: 'Indonesia',
    type: 'province',
    states: [
      {
        name: 'Aceh',
        value: 'Aceh',
      },
      {
        name: 'Bali',
        value: 'Bali',
      },
      {
        name: 'Banten',
        value: 'Banten',
      },
      {
        name: 'Bengkulu',
        value: 'Bengkulu',
      },
      {
        name: 'DI Yogyakarta',
        value: 'DI Yogyakarta',
      },
      {
        name: 'DKI Jakarta',
        value: 'DKI Jakarta',
      },
      {
        name: 'Gorontalo',
        value: 'Gorontalo',
      },
      {
        name: 'Jambi',
        value: 'Jambi',
      },
      {
        name: 'Jawa Barat',
        value: 'Jawa Barat',
      },
      {
        name: 'Jawa Tengah',
        value: 'Jawa Tengah',
      },
      {
        name: 'Jawa Timur',
        value: 'Jawa Timur',
      },
      {
        name: 'Kalimantan Barat',
        value: 'Kalimantan Barat',
      },
      {
        name: 'Kalimantan Selatan',
        value: 'Kalimantan Selatan',
      },
      {
        name: 'Kalimantan Tengah',
        value: 'Kalimantan Tengah',
      },
      {
        name: 'Kalimantan Timur',
        value: 'Kalimantan Timur',
      },
      {
        name: 'Kalimantan Utara',
        value: 'Kalimantan Utara',
      },
      {
        name: 'Kepulauan Bangka Belitung',
        value: 'Kepulauan Bangka Belitung',
      },
      {
        name: 'Kepulauan Riau',
        value: 'Kepulauan Riau',
      },
      {
        name: 'Lampung',
        value: 'Lampung',
      },
      {
        name: 'Maluku',
        value: 'Maluku',
      },
      {
        name: 'Maluku Utara',
        value: 'Maluku Utara',
      },
      {
        name: 'Nusa Tenggara Barat',
        value: 'Nusa Tenggara Barat',
      },
      {
        name: 'Nusa Tenggara Timur',
        value: 'Nusa Tenggara Timur',
      },
      {
        name: 'Papua',
        value: 'Papua',
      },
      {
        name: 'Papua Barat',
        value: 'Papua Barat',
      },
      {
        name: 'Riau',
        value: 'Riau',
      },
      {
        name: 'Sulawesi Barat',
        value: 'Sulawesi Barat',
      },
      {
        name: 'Sulawesi Selatan',
        value: 'Sulawesi Selatan',
      },
      {
        name: 'Sulawesi Tengah',
        value: 'Sulawesi Tengah',
      },
      {
        name: 'Sulawesi Tenggara',
        value: 'Sulawesi Tenggara',
      },
      {
        name: 'Sulawesi Utara',
        value: 'Sulawesi Utara',
      },
      {
        name: 'Sumatera Barat',
        value: 'Sumatera Barat',
      },
      {
        name: 'Sumatera Selatan',
        value: 'Sumatera Selatan',
      },
      {
        name: 'Sumatera Utara',
        value: 'Sumatera Utara',
      },
    ],
  },
  IR: {
    name: 'Iran',
    type: 'province',
    states: [
      {
        name: 'Alborz',
        value: 'Alborz',
      },
      {
        name: 'Ardabil',
        value: 'Ardabil',
      },
      {
        name: 'Bushehr',
        value: 'Bushehr',
      },
      {
        name: 'Chaharmahal and Bakhtiari',
        value: 'Chaharmahal and Bakhtiari',
      },
      {
        name: 'East Azerbaijan',
        value: 'East Azerbaijan',
      },
      {
        name: 'Fars',
        value: 'Fars',
      },
      {
        name: 'Gilan',
        value: 'Gilan',
      },
      {
        name: 'Golestan',
        value: 'Golestan',
      },
      {
        name: 'Hamadan',
        value: 'Hamadan',
      },
      {
        name: 'Hormozgan',
        value: 'Hormozgan',
      },
      {
        name: 'Ilam',
        value: 'Ilam',
      },
      {
        name: 'Isfahan',
        value: 'Isfahan',
      },
      {
        name: 'Kerman',
        value: 'Kerman',
      },
      {
        name: 'Kermanshah',
        value: 'Kermanshah',
      },
      {
        name: 'Khuzestan',
        value: 'Khuzestan',
      },
      {
        name: 'Kohgiluyeh and Boyer-Ahmad',
        value: 'Kohgiluyeh and Boyer-Ahmad',
      },
      {
        name: 'Kurdistan',
        value: 'Kurdistan',
      },
      {
        name: 'Lorestan',
        value: 'Lorestan',
      },
      {
        name: 'Markazi',
        value: 'Markazi',
      },
      {
        name: 'Mazandaran',
        value: 'Mazandaran',
      },
      {
        name: 'North Khorasan',
        value: 'North Khorasan',
      },
      {
        name: 'Qazvin',
        value: 'Qazvin',
      },
      {
        name: 'Qom',
        value: 'Qom',
      },
      {
        name: 'Razavi Khorasan',
        value: 'Razavi Khorasan',
      },
      {
        name: 'Semnan',
        value: 'Semnan',
      },
      {
        name: 'Sistan and Baluchestan',
        value: 'Sistan and Baluchestan',
      },
      {
        name: 'South Khorasan',
        value: 'South Khorasan',
      },
      {
        name: 'Tehran',
        value: 'Tehran',
      },
      {
        name: 'West Azarbaijan',
        value: 'West Azarbaijan',
      },
      {
        name: 'Yazd',
        value: 'Yazd',
      },
      {
        name: 'Zanjan',
        value: 'Zanjan',
      },
    ],
  },
  IQ: {
    name: 'Iraq',
    type: 'province',
    states: [
      {
        name: 'Al Anbar',
        value: 'Al Anbar',
      },
      {
        name: 'Al Muthanna',
        value: 'Al Muthanna',
      },
      {
        name: 'Al-Qādisiyyah',
        value: 'Al-Qādisiyyah',
      },
      {
        name: 'Babylon',
        value: 'Babylon',
      },
      {
        name: 'Baghdad',
        value: 'Baghdad',
      },
      {
        name: 'Basra',
        value: 'Basra',
      },
      {
        name: 'Dhi Qar',
        value: 'Dhi Qar',
      },
      {
        name: 'Diyala',
        value: 'Diyala',
      },
      {
        name: 'Dohuk',
        value: 'Dohuk',
      },
      {
        name: 'Erbil',
        value: 'Erbil',
      },
      {
        name: 'Karbala',
        value: 'Karbala',
      },
      {
        name: 'Kirkuk',
        value: 'Kirkuk',
      },
      {
        name: 'Maysan',
        value: 'Maysan',
      },
      {
        name: 'Najaf',
        value: 'Najaf',
      },
      {
        name: 'Nineveh',
        value: 'Nineveh',
      },
      {
        name: 'Saladin',
        value: 'Saladin',
      },
      {
        name: 'Sulaymaniyah',
        value: 'Sulaymaniyah',
      },
      {
        name: 'Wasit',
        value: 'Wasit',
      },
    ],
  },
  IE: {
    name: 'Ireland',
    type: 'county',
    states: [
      {
        name: 'Carlow',
        value: 'Carlow',
      },
      {
        name: 'Cavan',
        value: 'Cavan',
      },
      {
        name: 'Clare',
        value: 'Clare',
      },
      {
        name: 'Connacht',
        value: 'Connacht',
      },
      {
        name: 'Cork',
        value: 'Cork',
      },
      {
        name: 'Donegal',
        value: 'Donegal',
      },
      {
        name: 'Dublin',
        value: 'Dublin',
      },
      {
        name: 'Galway',
        value: 'Galway',
      },
      {
        name: 'Kerry',
        value: 'Kerry',
      },
      {
        name: 'Kildare',
        value: 'Kildare',
      },
      {
        name: 'Kilkenny',
        value: 'Kilkenny',
      },
      {
        name: 'Laois',
        value: 'Laois',
      },
      {
        name: 'Leinster',
        value: 'Leinster',
      },
      {
        name: 'Limerick',
        value: 'Limerick',
      },
      {
        name: 'Longford',
        value: 'Longford',
      },
      {
        name: 'Louth',
        value: 'Louth',
      },
      {
        name: 'Mayo',
        value: 'Mayo',
      },
      {
        name: 'Meath',
        value: 'Meath',
      },
      {
        name: 'Monaghan',
        value: 'Monaghan',
      },
      {
        name: 'Munster',
        value: 'Munster',
      },
      {
        name: 'Offaly',
        value: 'Offaly',
      },
      {
        name: 'Roscommon',
        value: 'Roscommon',
      },
      {
        name: 'Sligo',
        value: 'Sligo',
      },
      {
        name: 'Tipperary',
        value: 'Tipperary',
      },
      {
        name: 'Ulster',
        value: 'Ulster',
      },
      {
        name: 'Waterford',
        value: 'Waterford',
      },
      {
        name: 'Westmeath',
        value: 'Westmeath',
      },
      {
        name: 'Wexford',
        value: 'Wexford',
      },
      {
        name: 'Wicklow',
        value: 'Wicklow',
      },
    ],
  },
  IL: {
    name: 'Israel',
    type: null,
    states: [
      {
        name: 'Central District',
        value: 'Central District',
      },
      {
        name: 'Haifa District',
        value: 'Haifa District',
      },
      {
        name: 'Jerusalem District',
        value: 'Jerusalem District',
      },
      {
        name: 'Northern District',
        value: 'Northern District',
      },
      {
        name: 'Southern District',
        value: 'Southern District',
      },
      {
        name: 'Tel Aviv District',
        value: 'Tel Aviv District',
      },
    ],
  },
  IT: {
    name: 'Italy',
    type: 'province',
    states: [
      {
        name: 'Abruzzo',
        value: 'Abruzzo',
      },
      {
        name: 'Agrigento',
        value: 'Agrigento',
      },
      {
        name: 'Alessandria',
        value: 'Alessandria',
      },
      {
        name: 'Ancona',
        value: 'Ancona',
      },
      {
        name: 'Aosta Valley',
        value: 'Aosta Valley',
      },
      {
        name: 'Apulia',
        value: 'Apulia',
      },
      {
        name: 'Ascoli Piceno',
        value: 'Ascoli Piceno',
      },
      {
        name: 'Asti',
        value: 'Asti',
      },
      {
        name: 'Avellino',
        value: 'Avellino',
      },
      {
        name: 'Barletta-Andria-Trani',
        value: 'Barletta-Andria-Trani',
      },
      {
        name: 'Basilicata',
        value: 'Basilicata',
      },
      {
        name: 'Belluno',
        value: 'Belluno',
      },
      {
        name: 'Benevento',
        value: 'Benevento',
      },
      {
        name: 'Bergamo',
        value: 'Bergamo',
      },
      {
        name: 'Biella',
        value: 'Biella',
      },
      {
        name: 'Brescia',
        value: 'Brescia',
      },
      {
        name: 'Brindisi',
        value: 'Brindisi',
      },
      {
        name: 'Calabria',
        value: 'Calabria',
      },
      {
        name: 'Caltanissetta',
        value: 'Caltanissetta',
      },
      {
        name: 'Campania',
        value: 'Campania',
      },
      {
        name: 'Campobasso',
        value: 'Campobasso',
      },
      {
        name: 'Caserta',
        value: 'Caserta',
      },
      {
        name: 'Catanzaro',
        value: 'Catanzaro',
      },
      {
        name: 'Chieti',
        value: 'Chieti',
      },
      {
        name: 'Como',
        value: 'Como',
      },
      {
        name: 'Cosenza',
        value: 'Cosenza',
      },
      {
        name: 'Cremona',
        value: 'Cremona',
      },
      {
        name: 'Crotone',
        value: 'Crotone',
      },
      {
        name: 'Cuneo',
        value: 'Cuneo',
      },
      {
        name: 'Emilia-Romagna',
        value: 'Emilia-Romagna',
      },
      {
        name: 'Enna',
        value: 'Enna',
      },
      {
        name: 'Fermo',
        value: 'Fermo',
      },
      {
        name: 'Ferrara',
        value: 'Ferrara',
      },
      {
        name: 'Foggia',
        value: 'Foggia',
      },
      {
        name: 'Forlì-Cesena',
        value: 'Forlì-Cesena',
      },
      {
        name: 'Friuli–Venezia Giulia',
        value: 'Friuli–Venezia Giulia',
      },
      {
        name: 'Frosinone',
        value: 'Frosinone',
      },
      {
        name: 'Gorizia',
        value: 'Gorizia',
      },
      {
        name: 'Grosseto',
        value: 'Grosseto',
      },
      {
        name: 'Imperia',
        value: 'Imperia',
      },
      {
        name: 'Isernia',
        value: 'Isernia',
      },
      {
        name: "L'Aquila",
        value: "L'Aquila",
      },
      {
        name: 'La Spezia',
        value: 'La Spezia',
      },
      {
        name: 'Latina',
        value: 'Latina',
      },
      {
        name: 'Lazio',
        value: 'Lazio',
      },
      {
        name: 'Lecce',
        value: 'Lecce',
      },
      {
        name: 'Lecco',
        value: 'Lecco',
      },
      {
        name: 'Liguria',
        value: 'Liguria',
      },
      {
        name: 'Livorno',
        value: 'Livorno',
      },
      {
        name: 'Lodi',
        value: 'Lodi',
      },
      {
        name: 'Lombardy',
        value: 'Lombardy',
      },
      {
        name: 'Lucca',
        value: 'Lucca',
      },
      {
        name: 'Macerata',
        value: 'Macerata',
      },
      {
        name: 'Mantua',
        value: 'Mantua',
      },
      {
        name: 'Marche',
        value: 'Marche',
      },
      {
        name: 'Massa and Carrara',
        value: 'Massa and Carrara',
      },
      {
        name: 'Matera',
        value: 'Matera',
      },
      {
        name: 'Medio Campidano',
        value: 'Medio Campidano',
      },
      {
        name: 'Modena',
        value: 'Modena',
      },
      {
        name: 'Molise',
        value: 'Molise',
      },
      {
        name: 'Monza and Brianza',
        value: 'Monza and Brianza',
      },
      {
        name: 'Novara',
        value: 'Novara',
      },
      {
        name: 'Nuoro',
        value: 'Nuoro',
      },
      {
        name: 'Oristano',
        value: 'Oristano',
      },
      {
        name: 'Padua',
        value: 'Padua',
      },
      {
        name: 'Palermo',
        value: 'Palermo',
      },
      {
        name: 'Parma',
        value: 'Parma',
      },
      {
        name: 'Pavia',
        value: 'Pavia',
      },
      {
        name: 'Perugia',
        value: 'Perugia',
      },
      {
        name: 'Pesaro and Urbino',
        value: 'Pesaro and Urbino',
      },
      {
        name: 'Pescara',
        value: 'Pescara',
      },
      {
        name: 'Piacenza',
        value: 'Piacenza',
      },
      {
        name: 'Piedmont',
        value: 'Piedmont',
      },
      {
        name: 'Pisa',
        value: 'Pisa',
      },
      {
        name: 'Pistoia',
        value: 'Pistoia',
      },
      {
        name: 'Pordenone',
        value: 'Pordenone',
      },
      {
        name: 'Potenza',
        value: 'Potenza',
      },
      {
        name: 'Prato',
        value: 'Prato',
      },
      {
        name: 'Ragusa',
        value: 'Ragusa',
      },
      {
        name: 'Ravenna',
        value: 'Ravenna',
      },
      {
        name: 'Reggio Emilia',
        value: 'Reggio Emilia',
      },
      {
        name: 'Rieti',
        value: 'Rieti',
      },
      {
        name: 'Rimini',
        value: 'Rimini',
      },
      {
        name: 'Rovigo',
        value: 'Rovigo',
      },
      {
        name: 'Salerno',
        value: 'Salerno',
      },
      {
        name: 'Sardinia',
        value: 'Sardinia',
      },
      {
        name: 'Sassari',
        value: 'Sassari',
      },
      {
        name: 'Savona',
        value: 'Savona',
      },
      {
        name: 'Sicily',
        value: 'Sicily',
      },
      {
        name: 'Siena',
        value: 'Siena',
      },
      {
        name: 'Siracusa',
        value: 'Siracusa',
      },
      {
        name: 'Sondrio',
        value: 'Sondrio',
      },
      {
        name: 'South Sardinia',
        value: 'South Sardinia',
      },
      {
        name: 'Taranto',
        value: 'Taranto',
      },
      {
        name: 'Teramo',
        value: 'Teramo',
      },
      {
        name: 'Terni',
        value: 'Terni',
      },
      {
        name: 'Trapani',
        value: 'Trapani',
      },
      {
        name: 'Trentino-South Tyrol',
        value: 'Trentino-South Tyrol',
      },
      {
        name: 'Treviso',
        value: 'Treviso',
      },
      {
        name: 'Trieste',
        value: 'Trieste',
      },
      {
        name: 'Tuscany',
        value: 'Tuscany',
      },
      {
        name: 'Udine',
        value: 'Udine',
      },
      {
        name: 'Umbria',
        value: 'Umbria',
      },
      {
        name: 'Varese',
        value: 'Varese',
      },
      {
        name: 'Veneto',
        value: 'Veneto',
      },
      {
        name: 'Verbano-Cusio-Ossola',
        value: 'Verbano-Cusio-Ossola',
      },
      {
        name: 'Vercelli',
        value: 'Vercelli',
      },
      {
        name: 'Verona',
        value: 'Verona',
      },
      {
        name: 'Vibo Valentia',
        value: 'Vibo Valentia',
      },
      {
        name: 'Vicenza',
        value: 'Vicenza',
      },
      {
        name: 'Viterbo',
        value: 'Viterbo',
      },
    ],
  },
  JM: {
    name: 'Jamaica',
    type: null,
    states: [
      {
        name: 'Clarendon Parish',
        value: 'Clarendon Parish',
      },
      {
        name: 'Hanover Parish',
        value: 'Hanover Parish',
      },
      {
        name: 'Kingston Parish',
        value: 'Kingston Parish',
      },
      {
        name: 'Manchester Parish',
        value: 'Manchester Parish',
      },
      {
        name: 'Portland Parish',
        value: 'Portland Parish',
      },
      {
        name: 'Saint Andrew',
        value: 'Saint Andrew',
      },
      {
        name: 'Saint Ann Parish',
        value: 'Saint Ann Parish',
      },
      {
        name: 'Saint Catherine Parish',
        value: 'Saint Catherine Parish',
      },
      {
        name: 'Saint Elizabeth Parish',
        value: 'Saint Elizabeth Parish',
      },
      {
        name: 'Saint James Parish',
        value: 'Saint James Parish',
      },
      {
        name: 'Saint Mary Parish',
        value: 'Saint Mary Parish',
      },
      {
        name: 'Saint Thomas Parish',
        value: 'Saint Thomas Parish',
      },
      {
        name: 'Trelawny Parish',
        value: 'Trelawny Parish',
      },
      {
        name: 'Westmoreland Parish',
        value: 'Westmoreland Parish',
      },
    ],
  },
  JP: {
    name: 'Japan',
    type: null,
    states: [
      {
        name: 'Aichi Prefecture',
        value: 'Aichi Prefecture',
      },
      {
        name: 'Akita Prefecture',
        value: 'Akita Prefecture',
      },
      {
        name: 'Aomori Prefecture',
        value: 'Aomori Prefecture',
      },
      {
        name: 'Chiba Prefecture',
        value: 'Chiba Prefecture',
      },
      {
        name: 'Ehime Prefecture',
        value: 'Ehime Prefecture',
      },
      {
        name: 'Fukui Prefecture',
        value: 'Fukui Prefecture',
      },
      {
        name: 'Fukuoka Prefecture',
        value: 'Fukuoka Prefecture',
      },
      {
        name: 'Fukushima Prefecture',
        value: 'Fukushima Prefecture',
      },
      {
        name: 'Gifu Prefecture',
        value: 'Gifu Prefecture',
      },
      {
        name: 'Gunma Prefecture',
        value: 'Gunma Prefecture',
      },
      {
        name: 'Hiroshima Prefecture',
        value: 'Hiroshima Prefecture',
      },
      {
        name: 'Hokkaidō Prefecture',
        value: 'Hokkaidō Prefecture',
      },
      {
        name: 'Hyōgo Prefecture',
        value: 'Hyōgo Prefecture',
      },
      {
        name: 'Ibaraki Prefecture',
        value: 'Ibaraki Prefecture',
      },
      {
        name: 'Ishikawa Prefecture',
        value: 'Ishikawa Prefecture',
      },
      {
        name: 'Iwate Prefecture',
        value: 'Iwate Prefecture',
      },
      {
        name: 'Kagawa Prefecture',
        value: 'Kagawa Prefecture',
      },
      {
        name: 'Kagoshima Prefecture',
        value: 'Kagoshima Prefecture',
      },
      {
        name: 'Kanagawa Prefecture',
        value: 'Kanagawa Prefecture',
      },
      {
        name: 'Kōchi Prefecture',
        value: 'Kōchi Prefecture',
      },
      {
        name: 'Kumamoto Prefecture',
        value: 'Kumamoto Prefecture',
      },
      {
        name: 'Kyōto Prefecture',
        value: 'Kyōto Prefecture',
      },
      {
        name: 'Mie Prefecture',
        value: 'Mie Prefecture',
      },
      {
        name: 'Miyagi Prefecture',
        value: 'Miyagi Prefecture',
      },
      {
        name: 'Miyazaki Prefecture',
        value: 'Miyazaki Prefecture',
      },
      {
        name: 'Nagano Prefecture',
        value: 'Nagano Prefecture',
      },
      {
        name: 'Nagasaki Prefecture',
        value: 'Nagasaki Prefecture',
      },
      {
        name: 'Nara Prefecture',
        value: 'Nara Prefecture',
      },
      {
        name: 'Niigata Prefecture',
        value: 'Niigata Prefecture',
      },
      {
        name: 'Ōita Prefecture',
        value: 'Ōita Prefecture',
      },
      {
        name: 'Okayama Prefecture',
        value: 'Okayama Prefecture',
      },
      {
        name: 'Okinawa Prefecture',
        value: 'Okinawa Prefecture',
      },
      {
        name: 'Ōsaka Prefecture',
        value: 'Ōsaka Prefecture',
      },
      {
        name: 'Saga Prefecture',
        value: 'Saga Prefecture',
      },
      {
        name: 'Saitama Prefecture',
        value: 'Saitama Prefecture',
      },
      {
        name: 'Shiga Prefecture',
        value: 'Shiga Prefecture',
      },
      {
        name: 'Shimane Prefecture',
        value: 'Shimane Prefecture',
      },
      {
        name: 'Shizuoka Prefecture',
        value: 'Shizuoka Prefecture',
      },
      {
        name: 'Tochigi Prefecture',
        value: 'Tochigi Prefecture',
      },
      {
        name: 'Tokushima Prefecture',
        value: 'Tokushima Prefecture',
      },
      {
        name: 'Tokyo',
        value: 'Tokyo',
      },
      {
        name: 'Tottori Prefecture',
        value: 'Tottori Prefecture',
      },
      {
        name: 'Toyama Prefecture',
        value: 'Toyama Prefecture',
      },
      {
        name: 'Wakayama Prefecture',
        value: 'Wakayama Prefecture',
      },
      {
        name: 'Yamagata Prefecture',
        value: 'Yamagata Prefecture',
      },
      {
        name: 'Yamaguchi Prefecture',
        value: 'Yamaguchi Prefecture',
      },
      {
        name: 'Yamanashi Prefecture',
        value: 'Yamanashi Prefecture',
      },
    ],
  },
  JO: {
    name: 'Jordan',
    type: null,
    states: [
      {
        name: 'Ajloun',
        value: 'Ajloun',
      },
      {
        name: 'Amman',
        value: 'Amman',
      },
      {
        name: 'Aqaba',
        value: 'Aqaba',
      },
      {
        name: 'Balqa',
        value: 'Balqa',
      },
      {
        name: 'Irbid',
        value: 'Irbid',
      },
      {
        name: 'Jerash',
        value: 'Jerash',
      },
      {
        name: 'Karak',
        value: 'Karak',
      },
      {
        name: "Ma'an",
        value: "Ma'an",
      },
      {
        name: 'Madaba',
        value: 'Madaba',
      },
      {
        name: 'Mafraq',
        value: 'Mafraq',
      },
      {
        name: 'Tafilah',
        value: 'Tafilah',
      },
      {
        name: 'Zarqa',
        value: 'Zarqa',
      },
    ],
  },
  KZ: {
    name: 'Kazakhstan',
    type: null,
    states: [
      {
        name: 'Akmola Region',
        value: 'Akmola Region',
      },
      {
        name: 'Aktobe Region',
        value: 'Aktobe Region',
      },
      {
        name: 'Almaty',
        value: 'Almaty',
      },
      {
        name: 'Almaty Region',
        value: 'Almaty Region',
      },
      {
        name: 'Atyrau Region',
        value: 'Atyrau Region',
      },
      {
        name: 'Baikonur',
        value: 'Baikonur',
      },
      {
        name: 'East Kazakhstan Region',
        value: 'East Kazakhstan Region',
      },
      {
        name: 'Jambyl Region',
        value: 'Jambyl Region',
      },
      {
        name: 'Karaganda Region',
        value: 'Karaganda Region',
      },
      {
        name: 'Kostanay Region',
        value: 'Kostanay Region',
      },
      {
        name: 'Kyzylorda Region',
        value: 'Kyzylorda Region',
      },
      {
        name: 'Mangystau Region',
        value: 'Mangystau Region',
      },
      {
        name: 'North Kazakhstan Region',
        value: 'North Kazakhstan Region',
      },
      {
        name: 'Nur-Sultan',
        value: 'Nur-Sultan',
      },
      {
        name: 'Pavlodar Region',
        value: 'Pavlodar Region',
      },
      {
        name: 'Turkestan Region',
        value: 'Turkestan Region',
      },
      {
        name: 'West Kazakhstan Province',
        value: 'West Kazakhstan Province',
      },
    ],
  },
  KE: {
    name: 'Kenya',
    type: 'county',
    states: [
      {
        name: 'Baringo',
        value: 'Baringo',
      },
      {
        name: 'Bomet',
        value: 'Bomet',
      },
      {
        name: 'Bungoma',
        value: 'Bungoma',
      },
      {
        name: 'Busia',
        value: 'Busia',
      },
      {
        name: 'Elgeyo-Marakwet',
        value: 'Elgeyo-Marakwet',
      },
      {
        name: 'Embu',
        value: 'Embu',
      },
      {
        name: 'Garissa',
        value: 'Garissa',
      },
      {
        name: 'Homa Bay',
        value: 'Homa Bay',
      },
      {
        name: 'Isiolo',
        value: 'Isiolo',
      },
      {
        name: 'Kajiado',
        value: 'Kajiado',
      },
      {
        name: 'Kakamega',
        value: 'Kakamega',
      },
      {
        name: 'Kericho',
        value: 'Kericho',
      },
      {
        name: 'Kiambu',
        value: 'Kiambu',
      },
      {
        name: 'Kilifi',
        value: 'Kilifi',
      },
      {
        name: 'Kirinyaga',
        value: 'Kirinyaga',
      },
      {
        name: 'Kisii',
        value: 'Kisii',
      },
      {
        name: 'Kisumu',
        value: 'Kisumu',
      },
      {
        name: 'Kitui',
        value: 'Kitui',
      },
      {
        name: 'Kwale',
        value: 'Kwale',
      },
      {
        name: 'Laikipia',
        value: 'Laikipia',
      },
      {
        name: 'Lamu',
        value: 'Lamu',
      },
      {
        name: 'Machakos',
        value: 'Machakos',
      },
      {
        name: 'Makueni',
        value: 'Makueni',
      },
      {
        name: 'Mandera',
        value: 'Mandera',
      },
      {
        name: 'Marsabit',
        value: 'Marsabit',
      },
      {
        name: 'Meru',
        value: 'Meru',
      },
      {
        name: 'Migori',
        value: 'Migori',
      },
      {
        name: 'Mombasa',
        value: 'Mombasa',
      },
      {
        name: "Murang'a",
        value: "Murang'a",
      },
      {
        name: 'Nairobi City',
        value: 'Nairobi City',
      },
      {
        name: 'Nakuru',
        value: 'Nakuru',
      },
      {
        name: 'Nandi',
        value: 'Nandi',
      },
      {
        name: 'Narok',
        value: 'Narok',
      },
      {
        name: 'Nyamira',
        value: 'Nyamira',
      },
      {
        name: 'Nyandarua',
        value: 'Nyandarua',
      },
      {
        name: 'Nyeri',
        value: 'Nyeri',
      },
      {
        name: 'Samburu',
        value: 'Samburu',
      },
      {
        name: 'Siaya',
        value: 'Siaya',
      },
      {
        name: 'Taita–Taveta',
        value: 'Taita–Taveta',
      },
      {
        name: 'Tana River',
        value: 'Tana River',
      },
      {
        name: 'Tharaka-Nithi',
        value: 'Tharaka-Nithi',
      },
      {
        name: 'Trans Nzoia',
        value: 'Trans Nzoia',
      },
      {
        name: 'Turkana',
        value: 'Turkana',
      },
      {
        name: 'Uasin Gishu',
        value: 'Uasin Gishu',
      },
      {
        name: 'Vihiga',
        value: 'Vihiga',
      },
      {
        name: 'Wajir',
        value: 'Wajir',
      },
      {
        name: 'West Pokot',
        value: 'West Pokot',
      },
    ],
  },
  KI: {
    name: 'Kiribati',
    type: null,
    states: [
      {
        name: 'Gilbert Islands',
        value: 'Gilbert Islands',
      },
      {
        name: 'Line Islands',
        value: 'Line Islands',
      },
      {
        name: 'Phoenix Islands',
        value: 'Phoenix Islands',
      },
    ],
  },
  XK: {
    name: 'Kosovo',
    type: null,
    states: [
      {
        name: 'Đakovica District (Gjakove)',
        value: 'Đakovica District (Gjakove)',
      },
      {
        name: 'Gjilan District',
        value: 'Gjilan District',
      },
      {
        name: 'Kosovska Mitrovica District',
        value: 'Kosovska Mitrovica District',
      },
      {
        name: 'Peć District',
        value: 'Peć District',
      },
      {
        name: 'Pristina (Priştine)',
        value: 'Pristina (Priştine)',
      },
      {
        name: 'Prizren District',
        value: 'Prizren District',
      },
      {
        name: 'Uroševac District (Ferizaj)',
        value: 'Uroševac District (Ferizaj)',
      },
    ],
  },
  KW: {
    name: 'Kuwait',
    type: null,
    states: [
      {
        name: 'Al Ahmadi',
        value: 'Al Ahmadi',
      },
      {
        name: 'Al Farwaniyah',
        value: 'Al Farwaniyah',
      },
      {
        name: 'Al Jahra',
        value: 'Al Jahra',
      },
      {
        name: 'Capital',
        value: 'Capital',
      },
      {
        name: 'Hawalli',
        value: 'Hawalli',
      },
      {
        name: 'Mubarak Al-Kabeer',
        value: 'Mubarak Al-Kabeer',
      },
    ],
  },
  KG: {
    name: 'Kyrgyzstan',
    type: null,
    states: [
      {
        name: 'Batken Region',
        value: 'Batken Region',
      },
      {
        name: 'Bishkek',
        value: 'Bishkek',
      },
      {
        name: 'Chuy Region',
        value: 'Chuy Region',
      },
      {
        name: 'Issyk-Kul Region',
        value: 'Issyk-Kul Region',
      },
      {
        name: 'Jalal-Abad Region',
        value: 'Jalal-Abad Region',
      },
      {
        name: 'Naryn Region',
        value: 'Naryn Region',
      },
      {
        name: 'Osh',
        value: 'Osh',
      },
      {
        name: 'Osh Region',
        value: 'Osh Region',
      },
      {
        name: 'Talas Region',
        value: 'Talas Region',
      },
    ],
  },
  LA: {
    name: 'Laos',
    type: null,
    states: [
      {
        name: 'Attapeu Province',
        value: 'Attapeu Province',
      },
      {
        name: 'Bokeo Province',
        value: 'Bokeo Province',
      },
      {
        name: 'Bolikhamsai Province',
        value: 'Bolikhamsai Province',
      },
      {
        name: 'Champasak Province',
        value: 'Champasak Province',
      },
      {
        name: 'Houaphanh Province',
        value: 'Houaphanh Province',
      },
      {
        name: 'Khammouane Province',
        value: 'Khammouane Province',
      },
      {
        name: 'Luang Namtha Province',
        value: 'Luang Namtha Province',
      },
      {
        name: 'Luang Prabang Province',
        value: 'Luang Prabang Province',
      },
      {
        name: 'Oudomxay Province',
        value: 'Oudomxay Province',
      },
      {
        name: 'Phongsaly Province',
        value: 'Phongsaly Province',
      },
      {
        name: 'Sainyabuli Province',
        value: 'Sainyabuli Province',
      },
      {
        name: 'Salavan Province',
        value: 'Salavan Province',
      },
      {
        name: 'Savannakhet Province',
        value: 'Savannakhet Province',
      },
      {
        name: 'Sekong Province',
        value: 'Sekong Province',
      },
      {
        name: 'Vientiane Prefecture',
        value: 'Vientiane Prefecture',
      },
      {
        name: 'Vientiane Province',
        value: 'Vientiane Province',
      },
      {
        name: 'Xaisomboun',
        value: 'Xaisomboun',
      },
      {
        name: 'Xaisomboun Province',
        value: 'Xaisomboun Province',
      },
      {
        name: 'Xiangkhouang Province',
        value: 'Xiangkhouang Province',
      },
    ],
  },
  LV: {
    name: 'Latvia',
    type: null,
    states: [
      {
        name: 'Aglona Municipality',
        value: 'Aglona Municipality',
      },
      {
        name: 'Aizkraukle Municipality',
        value: 'Aizkraukle Municipality',
      },
      {
        name: 'Aizpute Municipality',
        value: 'Aizpute Municipality',
      },
      {
        name: 'Aknīste Municipality',
        value: 'Aknīste Municipality',
      },
      {
        name: 'Aloja Municipality',
        value: 'Aloja Municipality',
      },
      {
        name: 'Alsunga Municipality',
        value: 'Alsunga Municipality',
      },
      {
        name: 'Alūksne Municipality',
        value: 'Alūksne Municipality',
      },
      {
        name: 'Amata Municipality',
        value: 'Amata Municipality',
      },
      {
        name: 'Ape Municipality',
        value: 'Ape Municipality',
      },
      {
        name: 'Auce Municipality',
        value: 'Auce Municipality',
      },
      {
        name: 'Babīte Municipality',
        value: 'Babīte Municipality',
      },
      {
        name: 'Baldone Municipality',
        value: 'Baldone Municipality',
      },
      {
        name: 'Baltinava Municipality',
        value: 'Baltinava Municipality',
      },
      {
        name: 'Balvi Municipality',
        value: 'Balvi Municipality',
      },
      {
        name: 'Bauska Municipality',
        value: 'Bauska Municipality',
      },
      {
        name: 'Beverīna Municipality',
        value: 'Beverīna Municipality',
      },
      {
        name: 'Brocēni Municipality',
        value: 'Brocēni Municipality',
      },
      {
        name: 'Burtnieki Municipality',
        value: 'Burtnieki Municipality',
      },
      {
        name: 'Carnikava Municipality',
        value: 'Carnikava Municipality',
      },
      {
        name: 'Cēsis Municipality',
        value: 'Cēsis Municipality',
      },
      {
        name: 'Cesvaine Municipality',
        value: 'Cesvaine Municipality',
      },
      {
        name: 'Cibla Municipality',
        value: 'Cibla Municipality',
      },
      {
        name: 'Dagda Municipality',
        value: 'Dagda Municipality',
      },
      {
        name: 'Daugavpils',
        value: 'Daugavpils',
      },
      {
        name: 'Daugavpils Municipality',
        value: 'Daugavpils Municipality',
      },
      {
        name: 'Dobele Municipality',
        value: 'Dobele Municipality',
      },
      {
        name: 'Dundaga Municipality',
        value: 'Dundaga Municipality',
      },
      {
        name: 'Durbe Municipality',
        value: 'Durbe Municipality',
      },
      {
        name: 'Engure Municipality',
        value: 'Engure Municipality',
      },
      {
        name: 'Ērgļi Municipality',
        value: 'Ērgļi Municipality',
      },
      {
        name: 'Garkalne Municipality',
        value: 'Garkalne Municipality',
      },
      {
        name: 'Grobiņa Municipality',
        value: 'Grobiņa Municipality',
      },
      {
        name: 'Gulbene Municipality',
        value: 'Gulbene Municipality',
      },
      {
        name: 'Iecava Municipality',
        value: 'Iecava Municipality',
      },
      {
        name: 'Ikšķile Municipality',
        value: 'Ikšķile Municipality',
      },
      {
        name: 'Ilūkste Municipality',
        value: 'Ilūkste Municipality',
      },
      {
        name: 'Inčukalns Municipality',
        value: 'Inčukalns Municipality',
      },
      {
        name: 'Jaunjelgava Municipality',
        value: 'Jaunjelgava Municipality',
      },
      {
        name: 'Jaunpiebalga Municipality',
        value: 'Jaunpiebalga Municipality',
      },
      {
        name: 'Jaunpils Municipality',
        value: 'Jaunpils Municipality',
      },
      {
        name: 'Jēkabpils',
        value: 'Jēkabpils',
      },
      {
        name: 'Jēkabpils Municipality',
        value: 'Jēkabpils Municipality',
      },
      {
        name: 'Jelgava',
        value: 'Jelgava',
      },
      {
        name: 'Jelgava Municipality',
        value: 'Jelgava Municipality',
      },
      {
        name: 'Jūrmala',
        value: 'Jūrmala',
      },
      {
        name: 'Kandava Municipality',
        value: 'Kandava Municipality',
      },
      {
        name: 'Kārsava Municipality',
        value: 'Kārsava Municipality',
      },
      {
        name: 'Ķegums Municipality',
        value: 'Ķegums Municipality',
      },
      {
        name: 'Ķekava Municipality',
        value: 'Ķekava Municipality',
      },
      {
        name: 'Kocēni Municipality',
        value: 'Kocēni Municipality',
      },
      {
        name: 'Koknese Municipality',
        value: 'Koknese Municipality',
      },
      {
        name: 'Krāslava Municipality',
        value: 'Krāslava Municipality',
      },
      {
        name: 'Krimulda Municipality',
        value: 'Krimulda Municipality',
      },
      {
        name: 'Krustpils Municipality',
        value: 'Krustpils Municipality',
      },
      {
        name: 'Kuldīga Municipality',
        value: 'Kuldīga Municipality',
      },
      {
        name: 'Lielvārde Municipality',
        value: 'Lielvārde Municipality',
      },
      {
        name: 'Liepāja',
        value: 'Liepāja',
      },
      {
        name: 'Līgatne Municipality',
        value: 'Līgatne Municipality',
      },
      {
        name: 'Limbaži Municipality',
        value: 'Limbaži Municipality',
      },
      {
        name: 'Līvāni Municipality',
        value: 'Līvāni Municipality',
      },
      {
        name: 'Lubāna Municipality',
        value: 'Lubāna Municipality',
      },
      {
        name: 'Ludza Municipality',
        value: 'Ludza Municipality',
      },
      {
        name: 'Madona Municipality',
        value: 'Madona Municipality',
      },
      {
        name: 'Mālpils Municipality',
        value: 'Mālpils Municipality',
      },
      {
        name: 'Mārupe Municipality',
        value: 'Mārupe Municipality',
      },
      {
        name: 'Mazsalaca Municipality',
        value: 'Mazsalaca Municipality',
      },
      {
        name: 'Mērsrags Municipality',
        value: 'Mērsrags Municipality',
      },
      {
        name: 'Naukšēni Municipality',
        value: 'Naukšēni Municipality',
      },
      {
        name: 'Nereta Municipality',
        value: 'Nereta Municipality',
      },
      {
        name: 'Nīca Municipality',
        value: 'Nīca Municipality',
      },
      {
        name: 'Ogre Municipality',
        value: 'Ogre Municipality',
      },
      {
        name: 'Olaine Municipality',
        value: 'Olaine Municipality',
      },
      {
        name: 'Ozolnieki Municipality',
        value: 'Ozolnieki Municipality',
      },
      {
        name: 'Pārgauja Municipality',
        value: 'Pārgauja Municipality',
      },
      {
        name: 'Pāvilosta Municipality',
        value: 'Pāvilosta Municipality',
      },
      {
        name: 'Pļaviņas Municipality',
        value: 'Pļaviņas Municipality',
      },
      {
        name: 'Preiļi Municipality',
        value: 'Preiļi Municipality',
      },
      {
        name: 'Priekule Municipality',
        value: 'Priekule Municipality',
      },
      {
        name: 'Priekuļi Municipality',
        value: 'Priekuļi Municipality',
      },
      {
        name: 'Rauna Municipality',
        value: 'Rauna Municipality',
      },
      {
        name: 'Rēzekne',
        value: 'Rēzekne',
      },
      {
        name: 'Rēzekne Municipality',
        value: 'Rēzekne Municipality',
      },
      {
        name: 'Riebiņi Municipality',
        value: 'Riebiņi Municipality',
      },
      {
        name: 'Riga',
        value: 'Riga',
      },
      {
        name: 'Roja Municipality',
        value: 'Roja Municipality',
      },
      {
        name: 'Ropaži Municipality',
        value: 'Ropaži Municipality',
      },
      {
        name: 'Rucava Municipality',
        value: 'Rucava Municipality',
      },
      {
        name: 'Rugāji Municipality',
        value: 'Rugāji Municipality',
      },
      {
        name: 'Rūjiena Municipality',
        value: 'Rūjiena Municipality',
      },
      {
        name: 'Rundāle Municipality',
        value: 'Rundāle Municipality',
      },
      {
        name: 'Sala Municipality',
        value: 'Sala Municipality',
      },
      {
        name: 'Salacgrīva Municipality',
        value: 'Salacgrīva Municipality',
      },
      {
        name: 'Salaspils Municipality',
        value: 'Salaspils Municipality',
      },
      {
        name: 'Saldus Municipality',
        value: 'Saldus Municipality',
      },
      {
        name: 'Saulkrasti Municipality',
        value: 'Saulkrasti Municipality',
      },
      {
        name: 'Sēja Municipality',
        value: 'Sēja Municipality',
      },
      {
        name: 'Sigulda Municipality',
        value: 'Sigulda Municipality',
      },
      {
        name: 'Skrīveri Municipality',
        value: 'Skrīveri Municipality',
      },
      {
        name: 'Skrunda Municipality',
        value: 'Skrunda Municipality',
      },
      {
        name: 'Smiltene Municipality',
        value: 'Smiltene Municipality',
      },
      {
        name: 'Stopiņi Municipality',
        value: 'Stopiņi Municipality',
      },
      {
        name: 'Strenči Municipality',
        value: 'Strenči Municipality',
      },
      {
        name: 'Talsi Municipality',
        value: 'Talsi Municipality',
      },
      {
        name: 'Tērvete Municipality',
        value: 'Tērvete Municipality',
      },
      {
        name: 'Tukums Municipality',
        value: 'Tukums Municipality',
      },
      {
        name: 'Vaiņode Municipality',
        value: 'Vaiņode Municipality',
      },
      {
        name: 'Valka Municipality',
        value: 'Valka Municipality',
      },
      {
        name: 'Valmiera',
        value: 'Valmiera',
      },
      {
        name: 'Varakļāni Municipality',
        value: 'Varakļāni Municipality',
      },
      {
        name: 'Vārkava Municipality',
        value: 'Vārkava Municipality',
      },
      {
        name: 'Vecpiebalga Municipality',
        value: 'Vecpiebalga Municipality',
      },
      {
        name: 'Vecumnieki Municipality',
        value: 'Vecumnieki Municipality',
      },
      {
        name: 'Ventspils',
        value: 'Ventspils',
      },
      {
        name: 'Ventspils Municipality',
        value: 'Ventspils Municipality',
      },
      {
        name: 'Viesīte Municipality',
        value: 'Viesīte Municipality',
      },
      {
        name: 'Viļaka Municipality',
        value: 'Viļaka Municipality',
      },
      {
        name: 'Viļāni Municipality',
        value: 'Viļāni Municipality',
      },
      {
        name: 'Zilupe Municipality',
        value: 'Zilupe Municipality',
      },
    ],
  },
  LB: {
    name: 'Lebanon',
    type: null,
    states: [
      {
        name: 'Akkar',
        value: 'Akkar',
      },
      {
        name: 'Baalbek-Hermel',
        value: 'Baalbek-Hermel',
      },
      {
        name: 'Beirut',
        value: 'Beirut',
      },
      {
        name: 'Beqaa',
        value: 'Beqaa',
      },
      {
        name: 'Mount Lebanon',
        value: 'Mount Lebanon',
      },
      {
        name: 'Nabatieh',
        value: 'Nabatieh',
      },
      {
        name: 'North',
        value: 'North',
      },
      {
        name: 'South',
        value: 'South',
      },
    ],
  },
  LS: {
    name: 'Lesotho',
    type: null,
    states: [
      {
        name: 'Berea District',
        value: 'Berea District',
      },
      {
        name: 'Butha-Buthe District',
        value: 'Butha-Buthe District',
      },
      {
        name: 'Leribe District',
        value: 'Leribe District',
      },
      {
        name: 'Mafeteng District',
        value: 'Mafeteng District',
      },
      {
        name: 'Maseru District',
        value: 'Maseru District',
      },
      {
        name: "Mohale's Hoek District",
        value: "Mohale's Hoek District",
      },
      {
        name: 'Mokhotlong District',
        value: 'Mokhotlong District',
      },
      {
        name: "Qacha's Nek District",
        value: "Qacha's Nek District",
      },
      {
        name: 'Quthing District',
        value: 'Quthing District',
      },
      {
        name: 'Thaba-Tseka District',
        value: 'Thaba-Tseka District',
      },
    ],
  },
  LR: {
    name: 'Liberia',
    type: null,
    states: [
      {
        name: 'Bomi County',
        value: 'Bomi County',
      },
      {
        name: 'Bong County',
        value: 'Bong County',
      },
      {
        name: 'Gbarpolu County',
        value: 'Gbarpolu County',
      },
      {
        name: 'Grand Bassa County',
        value: 'Grand Bassa County',
      },
      {
        name: 'Grand Cape Mount County',
        value: 'Grand Cape Mount County',
      },
      {
        name: 'Grand Gedeh County',
        value: 'Grand Gedeh County',
      },
      {
        name: 'Grand Kru County',
        value: 'Grand Kru County',
      },
      {
        name: 'Lofa County',
        value: 'Lofa County',
      },
      {
        name: 'Margibi County',
        value: 'Margibi County',
      },
      {
        name: 'Maryland County',
        value: 'Maryland County',
      },
      {
        name: 'Montserrado County',
        value: 'Montserrado County',
      },
      {
        name: 'Nimba',
        value: 'Nimba',
      },
      {
        name: 'River Cess County',
        value: 'River Cess County',
      },
      {
        name: 'River Gee County',
        value: 'River Gee County',
      },
      {
        name: 'Sinoe County',
        value: 'Sinoe County',
      },
    ],
  },
  LY: {
    name: 'Libya',
    type: null,
    states: [
      {
        name: 'Al Wahat District',
        value: 'Al Wahat District',
      },
      {
        name: 'Benghazi',
        value: 'Benghazi',
      },
      {
        name: 'Derna District',
        value: 'Derna District',
      },
      {
        name: 'Ghat District',
        value: 'Ghat District',
      },
      {
        name: 'Jabal al Akhdar',
        value: 'Jabal al Akhdar',
      },
      {
        name: 'Jabal al Gharbi District',
        value: 'Jabal al Gharbi District',
      },
      {
        name: 'Jafara',
        value: 'Jafara',
      },
      {
        name: 'Jufra',
        value: 'Jufra',
      },
      {
        name: 'Kufra District',
        value: 'Kufra District',
      },
      {
        name: 'Marj District',
        value: 'Marj District',
      },
      {
        name: 'Misrata District',
        value: 'Misrata District',
      },
      {
        name: 'Murqub',
        value: 'Murqub',
      },
      {
        name: 'Murzuq District',
        value: 'Murzuq District',
      },
      {
        name: 'Nalut District',
        value: 'Nalut District',
      },
      {
        name: 'Nuqat al Khams',
        value: 'Nuqat al Khams',
      },
      {
        name: 'Sabha District',
        value: 'Sabha District',
      },
      {
        name: 'Sirte District',
        value: 'Sirte District',
      },
      {
        name: 'Tripoli District',
        value: 'Tripoli District',
      },
      {
        name: 'Wadi al Hayaa District',
        value: 'Wadi al Hayaa District',
      },
      {
        name: 'Wadi al Shatii District',
        value: 'Wadi al Shatii District',
      },
      {
        name: 'Zawiya District',
        value: 'Zawiya District',
      },
    ],
  },
  LI: {
    name: 'Liechtenstein',
    type: null,
    states: [
      {
        name: 'Balzers',
        value: 'Balzers',
      },
      {
        name: 'Eschen',
        value: 'Eschen',
      },
      {
        name: 'Gamprin',
        value: 'Gamprin',
      },
      {
        name: 'Mauren',
        value: 'Mauren',
      },
      {
        name: 'Planken',
        value: 'Planken',
      },
      {
        name: 'Ruggell',
        value: 'Ruggell',
      },
      {
        name: 'Schaan',
        value: 'Schaan',
      },
      {
        name: 'Schellenberg',
        value: 'Schellenberg',
      },
      {
        name: 'Triesen',
        value: 'Triesen',
      },
      {
        name: 'Triesenberg',
        value: 'Triesenberg',
      },
      {
        name: 'Vaduz',
        value: 'Vaduz',
      },
    ],
  },
  LT: {
    name: 'Lithuania',
    type: null,
    states: [
      {
        name: 'Akmenė District Municipality',
        value: 'Akmenė District Municipality',
      },
      {
        name: 'Alytus City Municipality',
        value: 'Alytus City Municipality',
      },
      {
        name: 'Alytus County',
        value: 'Alytus County',
      },
      {
        name: 'Alytus District Municipality',
        value: 'Alytus District Municipality',
      },
      {
        name: 'Birštonas Municipality',
        value: 'Birštonas Municipality',
      },
      {
        name: 'Biržai District Municipality',
        value: 'Biržai District Municipality',
      },
      {
        name: 'Druskininkai municipality',
        value: 'Druskininkai municipality',
      },
      {
        name: 'Elektrėnai municipality',
        value: 'Elektrėnai municipality',
      },
      {
        name: 'Ignalina District Municipality',
        value: 'Ignalina District Municipality',
      },
      {
        name: 'Jonava District Municipality',
        value: 'Jonava District Municipality',
      },
      {
        name: 'Joniškis District Municipality',
        value: 'Joniškis District Municipality',
      },
      {
        name: 'Jurbarkas District Municipality',
        value: 'Jurbarkas District Municipality',
      },
      {
        name: 'Kaišiadorys District Municipality',
        value: 'Kaišiadorys District Municipality',
      },
      {
        name: 'Kalvarija municipality',
        value: 'Kalvarija municipality',
      },
      {
        name: 'Kaunas City Municipality',
        value: 'Kaunas City Municipality',
      },
      {
        name: 'Kaunas County',
        value: 'Kaunas County',
      },
      {
        name: 'Kaunas District Municipality',
        value: 'Kaunas District Municipality',
      },
      {
        name: 'Kazlų Rūda municipality',
        value: 'Kazlų Rūda municipality',
      },
      {
        name: 'Kėdainiai District Municipality',
        value: 'Kėdainiai District Municipality',
      },
      {
        name: 'Kelmė District Municipality',
        value: 'Kelmė District Municipality',
      },
      {
        name: 'Klaipeda City Municipality',
        value: 'Klaipeda City Municipality',
      },
      {
        name: 'Klaipėda County',
        value: 'Klaipėda County',
      },
      {
        name: 'Klaipėda District Municipality',
        value: 'Klaipėda District Municipality',
      },
      {
        name: 'Kretinga District Municipality',
        value: 'Kretinga District Municipality',
      },
      {
        name: 'Kupiškis District Municipality',
        value: 'Kupiškis District Municipality',
      },
      {
        name: 'Lazdijai District Municipality',
        value: 'Lazdijai District Municipality',
      },
      {
        name: 'Marijampolė County',
        value: 'Marijampolė County',
      },
      {
        name: 'Marijampolė Municipality',
        value: 'Marijampolė Municipality',
      },
      {
        name: 'Mažeikiai District Municipality',
        value: 'Mažeikiai District Municipality',
      },
      {
        name: 'Molėtai District Municipality',
        value: 'Molėtai District Municipality',
      },
      {
        name: 'Neringa Municipality',
        value: 'Neringa Municipality',
      },
      {
        name: 'Pagėgiai municipality',
        value: 'Pagėgiai municipality',
      },
      {
        name: 'Pakruojis District Municipality',
        value: 'Pakruojis District Municipality',
      },
      {
        name: 'Palanga City Municipality',
        value: 'Palanga City Municipality',
      },
      {
        name: 'Panevėžys City Municipality',
        value: 'Panevėžys City Municipality',
      },
      {
        name: 'Panevėžys County',
        value: 'Panevėžys County',
      },
      {
        name: 'Panevėžys District Municipality',
        value: 'Panevėžys District Municipality',
      },
      {
        name: 'Pasvalys District Municipality',
        value: 'Pasvalys District Municipality',
      },
      {
        name: 'Plungė District Municipality',
        value: 'Plungė District Municipality',
      },
      {
        name: 'Prienai District Municipality',
        value: 'Prienai District Municipality',
      },
      {
        name: 'Radviliškis District Municipality',
        value: 'Radviliškis District Municipality',
      },
      {
        name: 'Raseiniai District Municipality',
        value: 'Raseiniai District Municipality',
      },
      {
        name: 'Rietavas municipality',
        value: 'Rietavas municipality',
      },
      {
        name: 'Rokiškis District Municipality',
        value: 'Rokiškis District Municipality',
      },
      {
        name: 'Šakiai District Municipality',
        value: 'Šakiai District Municipality',
      },
      {
        name: 'Šalčininkai District Municipality',
        value: 'Šalčininkai District Municipality',
      },
      {
        name: 'Šiauliai City Municipality',
        value: 'Šiauliai City Municipality',
      },
      {
        name: 'Šiauliai County',
        value: 'Šiauliai County',
      },
      {
        name: 'Šiauliai District Municipality',
        value: 'Šiauliai District Municipality',
      },
      {
        name: 'Šilalė District Municipality',
        value: 'Šilalė District Municipality',
      },
      {
        name: 'Šilutė District Municipality',
        value: 'Šilutė District Municipality',
      },
      {
        name: 'Širvintos District Municipality',
        value: 'Širvintos District Municipality',
      },
      {
        name: 'Skuodas District Municipality',
        value: 'Skuodas District Municipality',
      },
      {
        name: 'Švenčionys District Municipality',
        value: 'Švenčionys District Municipality',
      },
      {
        name: 'Tauragė County',
        value: 'Tauragė County',
      },
      {
        name: 'Tauragė District Municipality',
        value: 'Tauragė District Municipality',
      },
      {
        name: 'Telšiai County',
        value: 'Telšiai County',
      },
      {
        name: 'Telšiai District Municipality',
        value: 'Telšiai District Municipality',
      },
      {
        name: 'Trakai District Municipality',
        value: 'Trakai District Municipality',
      },
      {
        name: 'Ukmergė District Municipality',
        value: 'Ukmergė District Municipality',
      },
      {
        name: 'Utena County',
        value: 'Utena County',
      },
      {
        name: 'Utena District Municipality',
        value: 'Utena District Municipality',
      },
      {
        name: 'Varėna District Municipality',
        value: 'Varėna District Municipality',
      },
      {
        name: 'Vilkaviškis District Municipality',
        value: 'Vilkaviškis District Municipality',
      },
      {
        name: 'Vilnius City Municipality',
        value: 'Vilnius City Municipality',
      },
      {
        name: 'Vilnius County',
        value: 'Vilnius County',
      },
      {
        name: 'Vilnius District Municipality',
        value: 'Vilnius District Municipality',
      },
      {
        name: 'Visaginas Municipality',
        value: 'Visaginas Municipality',
      },
      {
        name: 'Zarasai District Municipality',
        value: 'Zarasai District Municipality',
      },
    ],
  },
  LU: {
    name: 'Luxembourg',
    type: null,
    states: [
      {
        name: 'Canton of Capellen',
        value: 'Canton of Capellen',
      },
      {
        name: 'Canton of Clervaux',
        value: 'Canton of Clervaux',
      },
      {
        name: 'Canton of Diekirch',
        value: 'Canton of Diekirch',
      },
      {
        name: 'Canton of Echternach',
        value: 'Canton of Echternach',
      },
      {
        name: 'Canton of Esch-sur-Alzette',
        value: 'Canton of Esch-sur-Alzette',
      },
      {
        name: 'Canton of Grevenmacher',
        value: 'Canton of Grevenmacher',
      },
      {
        name: 'Canton of Luxembourg',
        value: 'Canton of Luxembourg',
      },
      {
        name: 'Canton of Mersch',
        value: 'Canton of Mersch',
      },
      {
        name: 'Canton of Redange',
        value: 'Canton of Redange',
      },
      {
        name: 'Canton of Remich',
        value: 'Canton of Remich',
      },
      {
        name: 'Canton of Vianden',
        value: 'Canton of Vianden',
      },
      {
        name: 'Canton of Wiltz',
        value: 'Canton of Wiltz',
      },
      {
        name: 'Diekirch District',
        value: 'Diekirch District',
      },
      {
        name: 'Grevenmacher District',
        value: 'Grevenmacher District',
      },
      {
        name: 'Luxembourg District',
        value: 'Luxembourg District',
      },
    ],
  },
  MG: {
    name: 'Madagascar',
    type: null,
    states: [
      {
        name: 'Antananarivo Province',
        value: 'Antananarivo Province',
      },
      {
        name: 'Antsiranana Province',
        value: 'Antsiranana Province',
      },
      {
        name: 'Fianarantsoa Province',
        value: 'Fianarantsoa Province',
      },
      {
        name: 'Mahajanga Province',
        value: 'Mahajanga Province',
      },
      {
        name: 'Toamasina Province',
        value: 'Toamasina Province',
      },
      {
        name: 'Toliara Province',
        value: 'Toliara Province',
      },
    ],
  },
  MW: {
    name: 'Malawi',
    type: null,
    states: [
      {
        name: 'Balaka District',
        value: 'Balaka District',
      },
      {
        name: 'Blantyre District',
        value: 'Blantyre District',
      },
      {
        name: 'Central Region',
        value: 'Central Region',
      },
      {
        name: 'Chikwawa District',
        value: 'Chikwawa District',
      },
      {
        name: 'Chiradzulu District',
        value: 'Chiradzulu District',
      },
      {
        name: 'Chitipa district',
        value: 'Chitipa district',
      },
      {
        name: 'Dedza District',
        value: 'Dedza District',
      },
      {
        name: 'Dowa District',
        value: 'Dowa District',
      },
      {
        name: 'Karonga District',
        value: 'Karonga District',
      },
      {
        name: 'Kasungu District',
        value: 'Kasungu District',
      },
      {
        name: 'Likoma District',
        value: 'Likoma District',
      },
      {
        name: 'Lilongwe District',
        value: 'Lilongwe District',
      },
      {
        name: 'Machinga District',
        value: 'Machinga District',
      },
      {
        name: 'Mangochi District',
        value: 'Mangochi District',
      },
      {
        name: 'Mchinji District',
        value: 'Mchinji District',
      },
      {
        name: 'Mulanje District',
        value: 'Mulanje District',
      },
      {
        name: 'Mwanza District',
        value: 'Mwanza District',
      },
      {
        name: 'Mzimba District',
        value: 'Mzimba District',
      },
      {
        name: 'Nkhata Bay District',
        value: 'Nkhata Bay District',
      },
      {
        name: 'Nkhotakota District',
        value: 'Nkhotakota District',
      },
      {
        name: 'Northern Region',
        value: 'Northern Region',
      },
      {
        name: 'Nsanje District',
        value: 'Nsanje District',
      },
      {
        name: 'Ntcheu District',
        value: 'Ntcheu District',
      },
      {
        name: 'Ntchisi District',
        value: 'Ntchisi District',
      },
      {
        name: 'Phalombe District',
        value: 'Phalombe District',
      },
      {
        name: 'Rumphi District',
        value: 'Rumphi District',
      },
      {
        name: 'Salima District',
        value: 'Salima District',
      },
      {
        name: 'Southern Region',
        value: 'Southern Region',
      },
      {
        name: 'Thyolo District',
        value: 'Thyolo District',
      },
      {
        name: 'Zomba District',
        value: 'Zomba District',
      },
    ],
  },
  MY: {
    name: 'Malaysia',
    type: null,
    states: [
      {
        name: 'Johor',
        value: 'Johor',
      },
      {
        name: 'Kedah',
        value: 'Kedah',
      },
      {
        name: 'Kelantan',
        value: 'Kelantan',
      },
      {
        name: 'Kuala Lumpur',
        value: 'Kuala Lumpur',
      },
      {
        name: 'Labuan',
        value: 'Labuan',
      },
      {
        name: 'Malacca',
        value: 'Malacca',
      },
      {
        name: 'Negeri Sembilan',
        value: 'Negeri Sembilan',
      },
      {
        name: 'Pahang',
        value: 'Pahang',
      },
      {
        name: 'Penang',
        value: 'Penang',
      },
      {
        name: 'Perak',
        value: 'Perak',
      },
      {
        name: 'Perlis',
        value: 'Perlis',
      },
      {
        name: 'Putrajaya',
        value: 'Putrajaya',
      },
      {
        name: 'Sabah',
        value: 'Sabah',
      },
      {
        name: 'Sarawak',
        value: 'Sarawak',
      },
      {
        name: 'Selangor',
        value: 'Selangor',
      },
      {
        name: 'Terengganu',
        value: 'Terengganu',
      },
    ],
  },
  MV: {
    name: 'Maldives',
    type: null,
    states: [
      {
        name: 'Addu Atoll',
        value: 'Addu Atoll',
      },
      {
        name: 'Alif Alif Atoll',
        value: 'Alif Alif Atoll',
      },
      {
        name: 'Alif Dhaal Atoll',
        value: 'Alif Dhaal Atoll',
      },
      {
        name: 'Central Province',
        value: 'Central Province',
      },
      {
        name: 'Dhaalu Atoll',
        value: 'Dhaalu Atoll',
      },
      {
        name: 'Faafu Atoll',
        value: 'Faafu Atoll',
      },
      {
        name: 'Gaafu Alif Atoll',
        value: 'Gaafu Alif Atoll',
      },
      {
        name: 'Gaafu Dhaalu Atoll',
        value: 'Gaafu Dhaalu Atoll',
      },
      {
        name: 'Gnaviyani Atoll',
        value: 'Gnaviyani Atoll',
      },
      {
        name: 'Haa Alif Atoll',
        value: 'Haa Alif Atoll',
      },
      {
        name: 'Haa Dhaalu Atoll',
        value: 'Haa Dhaalu Atoll',
      },
      {
        name: 'Kaafu Atoll',
        value: 'Kaafu Atoll',
      },
      {
        name: 'Laamu Atoll',
        value: 'Laamu Atoll',
      },
      {
        name: 'Lhaviyani Atoll',
        value: 'Lhaviyani Atoll',
      },
      {
        name: 'Malé',
        value: 'Malé',
      },
      {
        name: 'Meemu Atoll',
        value: 'Meemu Atoll',
      },
      {
        name: 'Noonu Atoll',
        value: 'Noonu Atoll',
      },
      {
        name: 'North Central Province',
        value: 'North Central Province',
      },
      {
        name: 'North Province',
        value: 'North Province',
      },
      {
        name: 'Raa Atoll',
        value: 'Raa Atoll',
      },
      {
        name: 'Shaviyani Atoll',
        value: 'Shaviyani Atoll',
      },
      {
        name: 'South Central Province',
        value: 'South Central Province',
      },
      {
        name: 'South Province',
        value: 'South Province',
      },
      {
        name: 'Thaa Atoll',
        value: 'Thaa Atoll',
      },
      {
        name: 'Upper South Province',
        value: 'Upper South Province',
      },
      {
        name: 'Vaavu Atoll',
        value: 'Vaavu Atoll',
      },
    ],
  },
  ML: {
    name: 'Mali',
    type: null,
    states: [
      {
        name: 'Bamako',
        value: 'Bamako',
      },
      {
        name: 'Gao Region',
        value: 'Gao Region',
      },
      {
        name: 'Kayes Region',
        value: 'Kayes Region',
      },
      {
        name: 'Kidal Region',
        value: 'Kidal Region',
      },
      {
        name: 'Koulikoro Region',
        value: 'Koulikoro Region',
      },
      {
        name: 'Ménaka Region',
        value: 'Ménaka Region',
      },
      {
        name: 'Mopti Region',
        value: 'Mopti Region',
      },
      {
        name: 'Ségou Region',
        value: 'Ségou Region',
      },
      {
        name: 'Sikasso Region',
        value: 'Sikasso Region',
      },
      {
        name: 'Taoudénit Region',
        value: 'Taoudénit Region',
      },
      {
        name: 'Tombouctou Region',
        value: 'Tombouctou Region',
      },
    ],
  },
  MT: {
    name: 'Malta',
    type: null,
    states: [
      {
        name: 'Attard',
        value: 'Attard',
      },
      {
        name: 'Balzan',
        value: 'Balzan',
      },
      {
        name: 'Birgu',
        value: 'Birgu',
      },
      {
        name: 'Birkirkara',
        value: 'Birkirkara',
      },
      {
        name: 'Birżebbuġa',
        value: 'Birżebbuġa',
      },
      {
        name: 'Cospicua',
        value: 'Cospicua',
      },
      {
        name: 'Dingli',
        value: 'Dingli',
      },
      {
        name: 'Fgura',
        value: 'Fgura',
      },
      {
        name: 'Floriana',
        value: 'Floriana',
      },
      {
        name: 'Fontana',
        value: 'Fontana',
      },
      {
        name: 'Għajnsielem',
        value: 'Għajnsielem',
      },
      {
        name: 'Għarb',
        value: 'Għarb',
      },
      {
        name: 'Għargħur',
        value: 'Għargħur',
      },
      {
        name: 'Għasri',
        value: 'Għasri',
      },
      {
        name: 'Għaxaq',
        value: 'Għaxaq',
      },
      {
        name: 'Gudja',
        value: 'Gudja',
      },
      {
        name: 'Gżira',
        value: 'Gżira',
      },
      {
        name: 'Ħamrun',
        value: 'Ħamrun',
      },
      {
        name: 'Iklin',
        value: 'Iklin',
      },
      {
        name: 'Kalkara',
        value: 'Kalkara',
      },
      {
        name: 'Kerċem',
        value: 'Kerċem',
      },
      {
        name: 'Kirkop',
        value: 'Kirkop',
      },
      {
        name: 'Lija',
        value: 'Lija',
      },
      {
        name: 'Luqa',
        value: 'Luqa',
      },
      {
        name: 'Marsa',
        value: 'Marsa',
      },
      {
        name: 'Marsaskala',
        value: 'Marsaskala',
      },
      {
        name: 'Marsaxlokk',
        value: 'Marsaxlokk',
      },
      {
        name: 'Mdina',
        value: 'Mdina',
      },
      {
        name: 'Mellieħa',
        value: 'Mellieħa',
      },
      {
        name: 'Mġarr',
        value: 'Mġarr',
      },
      {
        name: 'Mosta',
        value: 'Mosta',
      },
      {
        name: 'Mqabba',
        value: 'Mqabba',
      },
      {
        name: 'Msida',
        value: 'Msida',
      },
      {
        name: 'Mtarfa',
        value: 'Mtarfa',
      },
      {
        name: 'Munxar',
        value: 'Munxar',
      },
      {
        name: 'Nadur',
        value: 'Nadur',
      },
      {
        name: 'Naxxar',
        value: 'Naxxar',
      },
      {
        name: 'Paola',
        value: 'Paola',
      },
      {
        name: 'Pembroke',
        value: 'Pembroke',
      },
      {
        name: 'Pietà',
        value: 'Pietà',
      },
      {
        name: 'Qala',
        value: 'Qala',
      },
      {
        name: 'Qormi',
        value: 'Qormi',
      },
      {
        name: 'Qrendi',
        value: 'Qrendi',
      },
      {
        name: 'Rabat',
        value: 'Rabat',
      },
      {
        name: 'San Ġwann',
        value: 'San Ġwann',
      },
      {
        name: 'San Lawrenz',
        value: 'San Lawrenz',
      },
      {
        name: 'Sannat',
        value: 'Sannat',
      },
      {
        name: 'Santa Luċija',
        value: 'Santa Luċija',
      },
      {
        name: 'Santa Venera',
        value: 'Santa Venera',
      },
      {
        name: 'Senglea',
        value: 'Senglea',
      },
      {
        name: 'Siġġiewi',
        value: 'Siġġiewi',
      },
      {
        name: 'Sliema',
        value: 'Sliema',
      },
      {
        name: "St. Julian's",
        value: "St. Julian's",
      },
      {
        name: "St. Paul's Bay",
        value: "St. Paul's Bay",
      },
      {
        name: 'Swieqi',
        value: 'Swieqi',
      },
      {
        name: "Ta' Xbiex",
        value: "Ta' Xbiex",
      },
      {
        name: 'Tarxien',
        value: 'Tarxien',
      },
      {
        name: 'Valletta',
        value: 'Valletta',
      },
      {
        name: 'Victoria',
        value: 'Victoria',
      },
      {
        name: 'Xagħra',
        value: 'Xagħra',
      },
      {
        name: 'Xewkija',
        value: 'Xewkija',
      },
      {
        name: 'Xgħajra',
        value: 'Xgħajra',
      },
      {
        name: 'Żabbar',
        value: 'Żabbar',
      },
      {
        name: 'Żebbuġ Gozo',
        value: 'Żebbuġ Gozo',
      },
      {
        name: 'Żebbuġ Malta',
        value: 'Żebbuġ Malta',
      },
      {
        name: 'Żejtun',
        value: 'Żejtun',
      },
      {
        name: 'Żurrieq',
        value: 'Żurrieq',
      },
    ],
  },
  MH: {
    name: 'Marshall Islands',
    type: null,
    states: [
      {
        name: 'Ralik Chain',
        value: 'Ralik Chain',
      },
      {
        name: 'Ratak Chain',
        value: 'Ratak Chain',
      },
    ],
  },
  MR: {
    name: 'Mauritania',
    type: 'region',
    states: [
      {
        name: 'Adrar',
        value: 'Adrar',
      },
      {
        name: 'Assaba',
        value: 'Assaba',
      },
      {
        name: 'Brakna',
        value: 'Brakna',
      },
      {
        name: 'Dakhlet Nouadhibou',
        value: 'Dakhlet Nouadhibou',
      },
      {
        name: 'Gorgol',
        value: 'Gorgol',
      },
      {
        name: 'Guidimaka',
        value: 'Guidimaka',
      },
      {
        name: 'Hodh Ech Chargui',
        value: 'Hodh Ech Chargui',
      },
      {
        name: 'Hodh El Gharbi',
        value: 'Hodh El Gharbi',
      },
      {
        name: 'Inchiri',
        value: 'Inchiri',
      },
      {
        name: 'Nouakchott-Nord',
        value: 'Nouakchott-Nord',
      },
      {
        name: 'Nouakchott-Ouest',
        value: 'Nouakchott-Ouest',
      },
      {
        name: 'Nouakchott-Sud',
        value: 'Nouakchott-Sud',
      },
      {
        name: 'Tagant',
        value: 'Tagant',
      },
      {
        name: 'Tiris Zemmour',
        value: 'Tiris Zemmour',
      },
      {
        name: 'Trarza',
        value: 'Trarza',
      },
    ],
  },
  MU: {
    name: 'Mauritius',
    type: 'dependency',
    states: [
      {
        name: 'Agalega Islands',
        value: 'Agalega Islands',
      },
      {
        name: 'Black River',
        value: 'Black River',
      },
      {
        name: 'Flacq',
        value: 'Flacq',
      },
      {
        name: 'Grand Port',
        value: 'Grand Port',
      },
      {
        name: 'Moka',
        value: 'Moka',
      },
      {
        name: 'Pamplemousses',
        value: 'Pamplemousses',
      },
      {
        name: 'Plaines Wilhems',
        value: 'Plaines Wilhems',
      },
      {
        name: 'Port Louis',
        value: 'Port Louis',
      },
      {
        name: 'Rivière du Rempart',
        value: 'Rivière du Rempart',
      },
      {
        name: 'Rodrigues Island',
        value: 'Rodrigues Island',
      },
      {
        name: 'Saint Brandon Islands',
        value: 'Saint Brandon Islands',
      },
      {
        name: 'Savanne',
        value: 'Savanne',
      },
    ],
  },
  MX: {
    name: 'Mexico',
    type: 'state',
    states: [
      {
        name: 'Aguascalientes',
        value: 'Aguascalientes',
      },
      {
        name: 'Baja California',
        value: 'Baja California',
      },
      {
        name: 'Baja California Sur',
        value: 'Baja California Sur',
      },
      {
        name: 'Campeche',
        value: 'Campeche',
      },
      {
        name: 'Chiapas',
        value: 'Chiapas',
      },
      {
        name: 'Chihuahua',
        value: 'Chihuahua',
      },
      {
        name: 'Ciudad de México',
        value: 'Ciudad de México',
      },
      {
        name: 'Coahuila de Zaragoza',
        value: 'Coahuila de Zaragoza',
      },
      {
        name: 'Colima',
        value: 'Colima',
      },
      {
        name: 'Durango',
        value: 'Durango',
      },
      {
        name: 'Estado de México',
        value: 'Estado de México',
      },
      {
        name: 'Guanajuato',
        value: 'Guanajuato',
      },
      {
        name: 'Guerrero',
        value: 'Guerrero',
      },
      {
        name: 'Hidalgo',
        value: 'Hidalgo',
      },
      {
        name: 'Jalisco',
        value: 'Jalisco',
      },
      {
        name: 'Michoacán de Ocampo',
        value: 'Michoacán de Ocampo',
      },
      {
        name: 'Morelos',
        value: 'Morelos',
      },
      {
        name: 'Nayarit',
        value: 'Nayarit',
      },
      {
        name: 'Nuevo León',
        value: 'Nuevo León',
      },
      {
        name: 'Oaxaca',
        value: 'Oaxaca',
      },
      {
        name: 'Puebla',
        value: 'Puebla',
      },
      {
        name: 'Querétaro',
        value: 'Querétaro',
      },
      {
        name: 'Quintana Roo',
        value: 'Quintana Roo',
      },
      {
        name: 'San Luis Potosí',
        value: 'San Luis Potosí',
      },
      {
        name: 'Sinaloa',
        value: 'Sinaloa',
      },
      {
        name: 'Sonora',
        value: 'Sonora',
      },
      {
        name: 'Tabasco',
        value: 'Tabasco',
      },
      {
        name: 'Tamaulipas',
        value: 'Tamaulipas',
      },
      {
        name: 'Tlaxcala',
        value: 'Tlaxcala',
      },
      {
        name: 'Veracruz de Ignacio de la Llave',
        value: 'Veracruz de Ignacio de la Llave',
      },
      {
        name: 'Yucatán',
        value: 'Yucatán',
      },
      {
        name: 'Zacatecas',
        value: 'Zacatecas',
      },
    ],
  },
  FM: {
    name: 'Micronesia',
    type: null,
    states: [
      {
        name: 'Chuuk State',
        value: 'Chuuk State',
      },
      {
        name: 'Kosrae State',
        value: 'Kosrae State',
      },
      {
        name: 'Pohnpei State',
        value: 'Pohnpei State',
      },
      {
        name: 'Yap State',
        value: 'Yap State',
      },
    ],
  },
  MD: {
    name: 'Moldova',
    type: null,
    states: [
      {
        name: 'Anenii Noi District',
        value: 'Anenii Noi District',
      },
      {
        name: 'Bălți Municipality',
        value: 'Bălți Municipality',
      },
      {
        name: 'Basarabeasca District',
        value: 'Basarabeasca District',
      },
      {
        name: 'Bender Municipality',
        value: 'Bender Municipality',
      },
      {
        name: 'Briceni District',
        value: 'Briceni District',
      },
      {
        name: 'Cahul District',
        value: 'Cahul District',
      },
      {
        name: 'Călărași District',
        value: 'Călărași District',
      },
      {
        name: 'Cantemir District',
        value: 'Cantemir District',
      },
      {
        name: 'Căușeni District',
        value: 'Căușeni District',
      },
      {
        name: 'Chișinău Municipality',
        value: 'Chișinău Municipality',
      },
      {
        name: 'Cimișlia District',
        value: 'Cimișlia District',
      },
      {
        name: 'Criuleni District',
        value: 'Criuleni District',
      },
      {
        name: 'Dondușeni District',
        value: 'Dondușeni District',
      },
      {
        name: 'Drochia District',
        value: 'Drochia District',
      },
      {
        name: 'Dubăsari District',
        value: 'Dubăsari District',
      },
      {
        name: 'Edineț District',
        value: 'Edineț District',
      },
      {
        name: 'Fălești District',
        value: 'Fălești District',
      },
      {
        name: 'Florești District',
        value: 'Florești District',
      },
      {
        name: 'Gagauzia',
        value: 'Gagauzia',
      },
      {
        name: 'Glodeni District',
        value: 'Glodeni District',
      },
      {
        name: 'Hîncești District',
        value: 'Hîncești District',
      },
      {
        name: 'Ialoveni District',
        value: 'Ialoveni District',
      },
      {
        name: 'Nisporeni District',
        value: 'Nisporeni District',
      },
      {
        name: 'Ocnița District',
        value: 'Ocnița District',
      },
      {
        name: 'Orhei District',
        value: 'Orhei District',
      },
      {
        name: 'Rezina District',
        value: 'Rezina District',
      },
      {
        name: 'Rîșcani District',
        value: 'Rîșcani District',
      },
      {
        name: 'Sîngerei District',
        value: 'Sîngerei District',
      },
      {
        name: 'Șoldănești District',
        value: 'Șoldănești District',
      },
      {
        name: 'Soroca District',
        value: 'Soroca District',
      },
      {
        name: 'Ștefan Vodă District',
        value: 'Ștefan Vodă District',
      },
      {
        name: 'Strășeni District',
        value: 'Strășeni District',
      },
      {
        name: 'Taraclia District',
        value: 'Taraclia District',
      },
      {
        name: 'Telenești District',
        value: 'Telenești District',
      },
      {
        name: 'Transnistria autonomous territorial unit',
        value: 'Transnistria autonomous territorial unit',
      },
      {
        name: 'Ungheni District',
        value: 'Ungheni District',
      },
    ],
  },
  MC: {
    name: 'Monaco',
    type: null,
    states: [
      {
        name: 'La Colle',
        value: 'La Colle',
      },
      {
        name: 'La Condamine',
        value: 'La Condamine',
      },
      {
        name: 'Moneghetti',
        value: 'Moneghetti',
      },
    ],
  },
  MN: {
    name: 'Mongolia',
    type: null,
    states: [
      {
        name: 'Arkhangai Province',
        value: 'Arkhangai Province',
      },
      {
        name: 'Bayan-Ölgii Province',
        value: 'Bayan-Ölgii Province',
      },
      {
        name: 'Bayankhongor Province',
        value: 'Bayankhongor Province',
      },
      {
        name: 'Bulgan Province',
        value: 'Bulgan Province',
      },
      {
        name: 'Darkhan-Uul Province',
        value: 'Darkhan-Uul Province',
      },
      {
        name: 'Dornod Province',
        value: 'Dornod Province',
      },
      {
        name: 'Dornogovi Province',
        value: 'Dornogovi Province',
      },
      {
        name: 'Dundgovi Province',
        value: 'Dundgovi Province',
      },
      {
        name: 'Govi-Altai Province',
        value: 'Govi-Altai Province',
      },
      {
        name: 'Govisümber Province',
        value: 'Govisümber Province',
      },
      {
        name: 'Khentii Province',
        value: 'Khentii Province',
      },
      {
        name: 'Khovd Province',
        value: 'Khovd Province',
      },
      {
        name: 'Khövsgöl Province',
        value: 'Khövsgöl Province',
      },
      {
        name: 'Ömnögovi Province',
        value: 'Ömnögovi Province',
      },
      {
        name: 'Orkhon Province',
        value: 'Orkhon Province',
      },
      {
        name: 'Övörkhangai Province',
        value: 'Övörkhangai Province',
      },
      {
        name: 'Selenge Province',
        value: 'Selenge Province',
      },
      {
        name: 'Sükhbaatar Province',
        value: 'Sükhbaatar Province',
      },
      {
        name: 'Töv Province',
        value: 'Töv Province',
      },
      {
        name: 'Uvs Province',
        value: 'Uvs Province',
      },
      {
        name: 'Zavkhan Province',
        value: 'Zavkhan Province',
      },
    ],
  },
  ME: {
    name: 'Montenegro',
    type: null,
    states: [
      {
        name: 'Andrijevica Municipality',
        value: 'Andrijevica Municipality',
      },
      {
        name: 'Bar Municipality',
        value: 'Bar Municipality',
      },
      {
        name: 'Berane Municipality',
        value: 'Berane Municipality',
      },
      {
        name: 'Bijelo Polje Municipality',
        value: 'Bijelo Polje Municipality',
      },
      {
        name: 'Budva Municipality',
        value: 'Budva Municipality',
      },
      {
        name: 'Danilovgrad Municipality',
        value: 'Danilovgrad Municipality',
      },
      {
        name: 'Gusinje Municipality',
        value: 'Gusinje Municipality',
      },
      {
        name: 'Kolašin Municipality',
        value: 'Kolašin Municipality',
      },
      {
        name: 'Kotor Municipality',
        value: 'Kotor Municipality',
      },
      {
        name: 'Mojkovac Municipality',
        value: 'Mojkovac Municipality',
      },
      {
        name: 'Nikšić Municipality',
        value: 'Nikšić Municipality',
      },
      {
        name: 'Old Royal Capital Cetinje',
        value: 'Old Royal Capital Cetinje',
      },
      {
        name: 'Petnjica Municipality',
        value: 'Petnjica Municipality',
      },
      {
        name: 'Plav Municipality',
        value: 'Plav Municipality',
      },
      {
        name: 'Pljevlja Municipality',
        value: 'Pljevlja Municipality',
      },
      {
        name: 'Plužine Municipality',
        value: 'Plužine Municipality',
      },
      {
        name: 'Podgorica Municipality',
        value: 'Podgorica Municipality',
      },
      {
        name: 'Rožaje Municipality',
        value: 'Rožaje Municipality',
      },
      {
        name: 'Šavnik Municipality',
        value: 'Šavnik Municipality',
      },
      {
        name: 'Tivat Municipality',
        value: 'Tivat Municipality',
      },
      {
        name: 'Ulcinj Municipality',
        value: 'Ulcinj Municipality',
      },
      {
        name: 'Žabljak Municipality',
        value: 'Žabljak Municipality',
      },
    ],
  },
  MA: {
    name: 'Morocco',
    type: 'prefecture',
    states: [
      {
        name: 'Agadir-Ida-Ou-Tanane',
        value: 'Agadir-Ida-Ou-Tanane',
      },
      {
        name: 'Al Haouz',
        value: 'Al Haouz',
      },
      {
        name: 'Al Hoceïma',
        value: 'Al Hoceïma',
      },
      {
        name: 'Aousserd (EH)',
        value: 'Aousserd (EH)',
      },
      {
        name: 'Assa-Zag (EH-partial)',
        value: 'Assa-Zag (EH-partial)',
      },
      {
        name: 'Azilal',
        value: 'Azilal',
      },
      {
        name: 'Béni Mellal',
        value: 'Béni Mellal',
      },
      {
        name: 'Béni Mellal-Khénifra',
        value: 'Béni Mellal-Khénifra',
      },
      {
        name: 'Benslimane',
        value: 'Benslimane',
      },
      {
        name: 'Berkane',
        value: 'Berkane',
      },
      {
        name: 'Berrechid',
        value: 'Berrechid',
      },
      {
        name: 'Boujdour (EH)',
        value: 'Boujdour (EH)',
      },
      {
        name: 'Boulemane',
        value: 'Boulemane',
      },
      {
        name: 'Casablanca',
        value: 'Casablanca',
      },
      {
        name: 'Casablanca-Settat',
        value: 'Casablanca-Settat',
      },
      {
        name: 'Chefchaouen',
        value: 'Chefchaouen',
      },
      {
        name: 'Chichaoua',
        value: 'Chichaoua',
      },
      {
        name: 'Chtouka-Ait Baha',
        value: 'Chtouka-Ait Baha',
      },
      {
        name: 'Dakhla-Oued Ed-Dahab (EH)',
        value: 'Dakhla-Oued Ed-Dahab (EH)',
      },
      {
        name: 'Drâa-Tafilalet',
        value: 'Drâa-Tafilalet',
      },
      {
        name: 'Driouch',
        value: 'Driouch',
      },
      {
        name: 'El Hajeb',
        value: 'El Hajeb',
      },
      {
        name: 'El Jadida',
        value: 'El Jadida',
      },
      {
        name: 'El Kelâa des Sraghna',
        value: 'El Kelâa des Sraghna',
      },
      {
        name: 'Errachidia',
        value: 'Errachidia',
      },
      {
        name: 'Es-Semara (EH-partial)',
        value: 'Es-Semara (EH-partial)',
      },
      {
        name: 'Essaouira',
        value: 'Essaouira',
      },
      {
        name: 'Fahs-Anjra',
        value: 'Fahs-Anjra',
      },
      {
        name: 'Fès',
        value: 'Fès',
      },
      {
        name: 'Fès-Meknès',
        value: 'Fès-Meknès',
      },
      {
        name: 'Figuig',
        value: 'Figuig',
      },
      {
        name: 'Fquih Ben Salah',
        value: 'Fquih Ben Salah',
      },
      {
        name: 'Guelmim',
        value: 'Guelmim',
      },
      {
        name: 'Guelmim-Oued Noun (EH-partial)',
        value: 'Guelmim-Oued Noun (EH-partial)',
      },
      {
        name: 'Guercif',
        value: 'Guercif',
      },
      {
        name: 'Ifrane',
        value: 'Ifrane',
      },
      {
        name: 'Inezgane-Ait Melloul',
        value: 'Inezgane-Ait Melloul',
      },
      {
        name: 'Jerada',
        value: 'Jerada',
      },
      {
        name: 'Kénitra',
        value: 'Kénitra',
      },
      {
        name: 'Khémisset',
        value: 'Khémisset',
      },
      {
        name: 'Khénifra',
        value: 'Khénifra',
      },
      {
        name: 'Khouribga',
        value: 'Khouribga',
      },
      {
        name: "L'Oriental",
        value: "L'Oriental",
      },
      {
        name: 'Laâyoune (EH)',
        value: 'Laâyoune (EH)',
      },
      {
        name: 'Laâyoune-Sakia El Hamra (EH-partial)',
        value: 'Laâyoune-Sakia El Hamra (EH-partial)',
      },
      {
        name: 'Larache',
        value: 'Larache',
      },
      {
        name: 'M’diq-Fnideq',
        value: 'M’diq-Fnideq',
      },
      {
        name: 'Marrakech',
        value: 'Marrakech',
      },
      {
        name: 'Marrakesh-Safi',
        value: 'Marrakesh-Safi',
      },
      {
        name: 'Médiouna',
        value: 'Médiouna',
      },
      {
        name: 'Meknès',
        value: 'Meknès',
      },
      {
        name: 'Midelt',
        value: 'Midelt',
      },
      {
        name: 'Mohammadia',
        value: 'Mohammadia',
      },
      {
        name: 'Moulay Yacoub',
        value: 'Moulay Yacoub',
      },
      {
        name: 'Nador',
        value: 'Nador',
      },
      {
        name: 'Nouaceur',
        value: 'Nouaceur',
      },
      {
        name: 'Ouarzazate',
        value: 'Ouarzazate',
      },
      {
        name: 'Oued Ed-Dahab (EH)',
        value: 'Oued Ed-Dahab (EH)',
      },
      {
        name: 'Ouezzane',
        value: 'Ouezzane',
      },
      {
        name: 'Oujda-Angad',
        value: 'Oujda-Angad',
      },
      {
        name: 'Rabat',
        value: 'Rabat',
      },
      {
        name: 'Rabat-Salé-Kénitra',
        value: 'Rabat-Salé-Kénitra',
      },
      {
        name: 'Rehamna',
        value: 'Rehamna',
      },
      {
        name: 'Safi',
        value: 'Safi',
      },
      {
        name: 'Salé',
        value: 'Salé',
      },
      {
        name: 'Sefrou',
        value: 'Sefrou',
      },
      {
        name: 'Settat',
        value: 'Settat',
      },
      {
        name: 'Sidi Bennour',
        value: 'Sidi Bennour',
      },
      {
        name: 'Sidi Ifni',
        value: 'Sidi Ifni',
      },
      {
        name: 'Sidi Kacem',
        value: 'Sidi Kacem',
      },
      {
        name: 'Sidi Slimane',
        value: 'Sidi Slimane',
      },
      {
        name: 'Skhirate-Témara',
        value: 'Skhirate-Témara',
      },
      {
        name: 'Souss-Massa',
        value: 'Souss-Massa',
      },
      {
        name: 'Tan-Tan (EH-partial)',
        value: 'Tan-Tan (EH-partial)',
      },
      {
        name: 'Tanger-Assilah',
        value: 'Tanger-Assilah',
      },
      {
        name: 'Tanger-Tétouan-Al Hoceïma',
        value: 'Tanger-Tétouan-Al Hoceïma',
      },
      {
        name: 'Taounate',
        value: 'Taounate',
      },
      {
        name: 'Taourirt',
        value: 'Taourirt',
      },
      {
        name: 'Tarfaya (EH-partial)',
        value: 'Tarfaya (EH-partial)',
      },
      {
        name: 'Taroudannt',
        value: 'Taroudannt',
      },
      {
        name: 'Tata',
        value: 'Tata',
      },
      {
        name: 'Taza',
        value: 'Taza',
      },
      {
        name: 'Tétouan',
        value: 'Tétouan',
      },
      {
        name: 'Tinghir',
        value: 'Tinghir',
      },
      {
        name: 'Tiznit',
        value: 'Tiznit',
      },
      {
        name: 'Youssoufia',
        value: 'Youssoufia',
      },
      {
        name: 'Zagora',
        value: 'Zagora',
      },
    ],
  },
  MZ: {
    name: 'Mozambique',
    type: null,
    states: [
      {
        name: 'Cabo Delgado Province',
        value: 'Cabo Delgado Province',
      },
      {
        name: 'Gaza Province',
        value: 'Gaza Province',
      },
      {
        name: 'Inhambane Province',
        value: 'Inhambane Province',
      },
      {
        name: 'Manica Province',
        value: 'Manica Province',
      },
      {
        name: 'Maputo',
        value: 'Maputo',
      },
      {
        name: 'Maputo Province',
        value: 'Maputo Province',
      },
      {
        name: 'Nampula Province',
        value: 'Nampula Province',
      },
      {
        name: 'Niassa Province',
        value: 'Niassa Province',
      },
      {
        name: 'Sofala Province',
        value: 'Sofala Province',
      },
      {
        name: 'Tete Province',
        value: 'Tete Province',
      },
      {
        name: 'Zambezia Province',
        value: 'Zambezia Province',
      },
    ],
  },
  MM: {
    name: 'Myanmar',
    type: null,
    states: [
      {
        name: 'Ayeyarwady Region',
        value: 'Ayeyarwady Region',
      },
      {
        name: 'Bago',
        value: 'Bago',
      },
      {
        name: 'Chin State',
        value: 'Chin State',
      },
      {
        name: 'Kachin State',
        value: 'Kachin State',
      },
      {
        name: 'Kayah State',
        value: 'Kayah State',
      },
      {
        name: 'Kayin State',
        value: 'Kayin State',
      },
      {
        name: 'Magway Region',
        value: 'Magway Region',
      },
      {
        name: 'Mandalay Region',
        value: 'Mandalay Region',
      },
      {
        name: 'Mon State',
        value: 'Mon State',
      },
      {
        name: 'Naypyidaw Union Territory',
        value: 'Naypyidaw Union Territory',
      },
      {
        name: 'Rakhine State',
        value: 'Rakhine State',
      },
      {
        name: 'Sagaing Region',
        value: 'Sagaing Region',
      },
      {
        name: 'Shan State',
        value: 'Shan State',
      },
      {
        name: 'Tanintharyi Region',
        value: 'Tanintharyi Region',
      },
      {
        name: 'Yangon Region',
        value: 'Yangon Region',
      },
    ],
  },
  NA: {
    name: 'Namibia',
    type: null,
    states: [
      {
        name: 'Erongo Region',
        value: 'Erongo Region',
      },
      {
        name: 'Hardap Region',
        value: 'Hardap Region',
      },
      {
        name: 'Karas Region',
        value: 'Karas Region',
      },
      {
        name: 'Kavango East Region',
        value: 'Kavango East Region',
      },
      {
        name: 'Kavango West Region',
        value: 'Kavango West Region',
      },
      {
        name: 'Khomas Region',
        value: 'Khomas Region',
      },
      {
        name: 'Kunene Region',
        value: 'Kunene Region',
      },
      {
        name: 'Ohangwena Region',
        value: 'Ohangwena Region',
      },
      {
        name: 'Omaheke Region',
        value: 'Omaheke Region',
      },
      {
        name: 'Omusati Region',
        value: 'Omusati Region',
      },
      {
        name: 'Oshana Region',
        value: 'Oshana Region',
      },
      {
        name: 'Oshikoto Region',
        value: 'Oshikoto Region',
      },
      {
        name: 'Otjozondjupa Region',
        value: 'Otjozondjupa Region',
      },
      {
        name: 'Zambezi Region',
        value: 'Zambezi Region',
      },
    ],
  },
  NR: {
    name: 'Nauru',
    type: null,
    states: [
      {
        name: 'Aiwo District',
        value: 'Aiwo District',
      },
      {
        name: 'Anabar District',
        value: 'Anabar District',
      },
      {
        name: 'Anetan District',
        value: 'Anetan District',
      },
      {
        name: 'Anibare District',
        value: 'Anibare District',
      },
      {
        name: 'Baiti District',
        value: 'Baiti District',
      },
      {
        name: 'Boe District',
        value: 'Boe District',
      },
      {
        name: 'Buada District',
        value: 'Buada District',
      },
      {
        name: 'Denigomodu District',
        value: 'Denigomodu District',
      },
      {
        name: 'Ewa District',
        value: 'Ewa District',
      },
      {
        name: 'Ijuw District',
        value: 'Ijuw District',
      },
      {
        name: 'Meneng District',
        value: 'Meneng District',
      },
      {
        name: 'Nibok District',
        value: 'Nibok District',
      },
      {
        name: 'Uaboe District',
        value: 'Uaboe District',
      },
      {
        name: 'Yaren District',
        value: 'Yaren District',
      },
    ],
  },
  NP: {
    name: 'Nepal',
    type: null,
    states: [
      {
        name: 'Bagmati Zone',
        value: 'Bagmati Zone',
      },
      {
        name: 'Bheri Zone',
        value: 'Bheri Zone',
      },
      {
        name: 'Central Region',
        value: 'Central Region',
      },
      {
        name: 'Dhaulagiri Zone',
        value: 'Dhaulagiri Zone',
      },
      {
        name: 'Eastern Development Region',
        value: 'Eastern Development Region',
      },
      {
        name: 'Far-Western Development Region',
        value: 'Far-Western Development Region',
      },
      {
        name: 'Gandaki Zone',
        value: 'Gandaki Zone',
      },
      {
        name: 'Janakpur Zone',
        value: 'Janakpur Zone',
      },
      {
        name: 'Karnali Zone',
        value: 'Karnali Zone',
      },
      {
        name: 'Kosi Zone',
        value: 'Kosi Zone',
      },
      {
        name: 'Lumbini Zone',
        value: 'Lumbini Zone',
      },
      {
        name: 'Mahakali Zone',
        value: 'Mahakali Zone',
      },
      {
        name: 'Mechi Zone',
        value: 'Mechi Zone',
      },
      {
        name: 'Mid-Western Region',
        value: 'Mid-Western Region',
      },
      {
        name: 'Narayani Zone',
        value: 'Narayani Zone',
      },
      {
        name: 'Rapti Zone',
        value: 'Rapti Zone',
      },
      {
        name: 'Sagarmatha Zone',
        value: 'Sagarmatha Zone',
      },
      {
        name: 'Seti Zone',
        value: 'Seti Zone',
      },
      {
        name: 'Western Region',
        value: 'Western Region',
      },
    ],
  },
  NL: {
    name: 'Netherlands',
    type: 'special municipality',
    states: [
      {
        name: 'Bonaire',
        value: 'Bonaire',
      },
      {
        name: 'Drenthe',
        value: 'Drenthe',
      },
      {
        name: 'Flevoland',
        value: 'Flevoland',
      },
      {
        name: 'Friesland',
        value: 'Friesland',
      },
      {
        name: 'Gelderland',
        value: 'Gelderland',
      },
      {
        name: 'Groningen',
        value: 'Groningen',
      },
      {
        name: 'Limburg',
        value: 'Limburg',
      },
      {
        name: 'North Brabant',
        value: 'North Brabant',
      },
      {
        name: 'North Holland',
        value: 'North Holland',
      },
      {
        name: 'Overijssel',
        value: 'Overijssel',
      },
      {
        name: 'Saba',
        value: 'Saba',
      },
      {
        name: 'Sint Eustatius',
        value: 'Sint Eustatius',
      },
      {
        name: 'South Holland',
        value: 'South Holland',
      },
      {
        name: 'Utrecht',
        value: 'Utrecht',
      },
      {
        name: 'Zeeland',
        value: 'Zeeland',
      },
    ],
  },
  NC: {
    name: 'New Caledonia',
    type: 'province',
    states: [
      {
        name: 'Loyalty Islands Province',
        value: 'Loyalty Islands Province',
      },
      {
        name: 'North Province',
        value: 'North Province',
      },
      {
        name: 'South Province',
        value: 'South Province',
      },
    ],
  },
  NZ: {
    name: 'New Zealand',
    type: null,
    states: [
      {
        name: 'Auckland Region',
        value: 'Auckland Region',
      },
      {
        name: 'Bay of Plenty Region',
        value: 'Bay of Plenty Region',
      },
      {
        name: 'Canterbury Region',
        value: 'Canterbury Region',
      },
      {
        name: 'Chatham Islands',
        value: 'Chatham Islands',
      },
      {
        name: 'Gisborne District',
        value: 'Gisborne District',
      },
      {
        name: "Hawke's Bay Region",
        value: "Hawke's Bay Region",
      },
      {
        name: 'Manawatu-Wanganui Region',
        value: 'Manawatu-Wanganui Region',
      },
      {
        name: 'Marlborough Region',
        value: 'Marlborough Region',
      },
      {
        name: 'Nelson Region',
        value: 'Nelson Region',
      },
      {
        name: 'Northland Region',
        value: 'Northland Region',
      },
      {
        name: 'Otago Region',
        value: 'Otago Region',
      },
      {
        name: 'Southland Region',
        value: 'Southland Region',
      },
      {
        name: 'Taranaki Region',
        value: 'Taranaki Region',
      },
      {
        name: 'Tasman District',
        value: 'Tasman District',
      },
      {
        name: 'Waikato Region',
        value: 'Waikato Region',
      },
      {
        name: 'Wellington Region',
        value: 'Wellington Region',
      },
      {
        name: 'West Coast Region',
        value: 'West Coast Region',
      },
    ],
  },
  NI: {
    name: 'Nicaragua',
    type: 'department',
    states: [
      {
        name: 'Boaco',
        value: 'Boaco',
      },
      {
        name: 'Carazo',
        value: 'Carazo',
      },
      {
        name: 'Chinandega',
        value: 'Chinandega',
      },
      {
        name: 'Chontales',
        value: 'Chontales',
      },
      {
        name: 'Estelí',
        value: 'Estelí',
      },
      {
        name: 'Granada',
        value: 'Granada',
      },
      {
        name: 'Jinotega',
        value: 'Jinotega',
      },
      {
        name: 'León',
        value: 'León',
      },
      {
        name: 'Madriz',
        value: 'Madriz',
      },
      {
        name: 'Managua',
        value: 'Managua',
      },
      {
        name: 'Masaya',
        value: 'Masaya',
      },
      {
        name: 'Matagalpa',
        value: 'Matagalpa',
      },
      {
        name: 'North Caribbean Coast',
        value: 'North Caribbean Coast',
      },
      {
        name: 'Nueva Segovia',
        value: 'Nueva Segovia',
      },
      {
        name: 'Río San Juan',
        value: 'Río San Juan',
      },
      {
        name: 'Rivas',
        value: 'Rivas',
      },
      {
        name: 'South Caribbean Coast',
        value: 'South Caribbean Coast',
      },
    ],
  },
  NE: {
    name: 'Niger',
    type: null,
    states: [
      {
        name: 'Agadez Region',
        value: 'Agadez Region',
      },
      {
        name: 'Diffa Region',
        value: 'Diffa Region',
      },
      {
        name: 'Dosso Region',
        value: 'Dosso Region',
      },
      {
        name: 'Maradi Region',
        value: 'Maradi Region',
      },
      {
        name: 'Tahoua Region',
        value: 'Tahoua Region',
      },
      {
        name: 'Tillabéri Region',
        value: 'Tillabéri Region',
      },
      {
        name: 'Zinder Region',
        value: 'Zinder Region',
      },
    ],
  },
  NG: {
    name: 'Nigeria',
    type: 'state',
    states: [
      {
        name: 'Abia',
        value: 'Abia',
      },
      {
        name: 'Abuja Federal Capital Territory',
        value: 'Abuja Federal Capital Territory',
      },
      {
        name: 'Adamawa',
        value: 'Adamawa',
      },
      {
        name: 'Akwa Ibom',
        value: 'Akwa Ibom',
      },
      {
        name: 'Anambra',
        value: 'Anambra',
      },
      {
        name: 'Bauchi',
        value: 'Bauchi',
      },
      {
        name: 'Bayelsa',
        value: 'Bayelsa',
      },
      {
        name: 'Benue',
        value: 'Benue',
      },
      {
        name: 'Borno',
        value: 'Borno',
      },
      {
        name: 'Cross River',
        value: 'Cross River',
      },
      {
        name: 'Delta',
        value: 'Delta',
      },
      {
        name: 'Ebonyi',
        value: 'Ebonyi',
      },
      {
        name: 'Edo',
        value: 'Edo',
      },
      {
        name: 'Ekiti',
        value: 'Ekiti',
      },
      {
        name: 'Enugu',
        value: 'Enugu',
      },
      {
        name: 'Gombe',
        value: 'Gombe',
      },
      {
        name: 'Imo',
        value: 'Imo',
      },
      {
        name: 'Jigawa',
        value: 'Jigawa',
      },
      {
        name: 'Kaduna',
        value: 'Kaduna',
      },
      {
        name: 'Kano',
        value: 'Kano',
      },
      {
        name: 'Katsina',
        value: 'Katsina',
      },
      {
        name: 'Kebbi',
        value: 'Kebbi',
      },
      {
        name: 'Kogi',
        value: 'Kogi',
      },
      {
        name: 'Kwara',
        value: 'Kwara',
      },
      {
        name: 'Lagos',
        value: 'Lagos',
      },
      {
        name: 'Nasarawa',
        value: 'Nasarawa',
      },
      {
        name: 'Niger',
        value: 'Niger',
      },
      {
        name: 'Ogun',
        value: 'Ogun',
      },
      {
        name: 'Ondo',
        value: 'Ondo',
      },
      {
        name: 'Osun',
        value: 'Osun',
      },
      {
        name: 'Oyo',
        value: 'Oyo',
      },
      {
        name: 'Plateau',
        value: 'Plateau',
      },
      {
        name: 'Rivers',
        value: 'Rivers',
      },
      {
        name: 'Sokoto',
        value: 'Sokoto',
      },
      {
        name: 'Taraba',
        value: 'Taraba',
      },
      {
        name: 'Yobe',
        value: 'Yobe',
      },
      {
        name: 'Zamfara',
        value: 'Zamfara',
      },
    ],
  },
  KP: {
    name: 'North Korea',
    type: 'province',
    states: [
      {
        name: 'Chagang Province',
        value: 'Chagang Province',
      },
      {
        name: 'Kangwon Province',
        value: 'Kangwon Province',
      },
      {
        name: 'North Hamgyong Province',
        value: 'North Hamgyong Province',
      },
      {
        name: 'North Hwanghae Province',
        value: 'North Hwanghae Province',
      },
      {
        name: 'North Pyongan Province',
        value: 'North Pyongan Province',
      },
      {
        name: 'Pyongyang',
        value: 'Pyongyang',
      },
      {
        name: 'Rason',
        value: 'Rason',
      },
      {
        name: 'Ryanggang Province',
        value: 'Ryanggang Province',
      },
      {
        name: 'South Hamgyong Province',
        value: 'South Hamgyong Province',
      },
      {
        name: 'South Hwanghae Province',
        value: 'South Hwanghae Province',
      },
      {
        name: 'South Pyongan Province',
        value: 'South Pyongan Province',
      },
    ],
  },
  MK: {
    name: 'North Macedonia',
    type: null,
    states: [
      {
        name: 'Aerodrom Municipality',
        value: 'Aerodrom Municipality',
      },
      {
        name: 'Aračinovo Municipality',
        value: 'Aračinovo Municipality',
      },
      {
        name: 'Berovo Municipality',
        value: 'Berovo Municipality',
      },
      {
        name: 'Bitola Municipality',
        value: 'Bitola Municipality',
      },
      {
        name: 'Bogdanci Municipality',
        value: 'Bogdanci Municipality',
      },
      {
        name: 'Bogovinje Municipality',
        value: 'Bogovinje Municipality',
      },
      {
        name: 'Bosilovo Municipality',
        value: 'Bosilovo Municipality',
      },
      {
        name: 'Brvenica Municipality',
        value: 'Brvenica Municipality',
      },
      {
        name: 'Butel Municipality',
        value: 'Butel Municipality',
      },
      {
        name: 'Čair Municipality',
        value: 'Čair Municipality',
      },
      {
        name: 'Čaška Municipality',
        value: 'Čaška Municipality',
      },
      {
        name: 'Centar Municipality',
        value: 'Centar Municipality',
      },
      {
        name: 'Centar Župa Municipality',
        value: 'Centar Župa Municipality',
      },
      {
        name: 'Češinovo-Obleševo Municipality',
        value: 'Češinovo-Obleševo Municipality',
      },
      {
        name: 'Čučer-Sandevo Municipality',
        value: 'Čučer-Sandevo Municipality',
      },
      {
        name: 'Debarca Municipality',
        value: 'Debarca Municipality',
      },
      {
        name: 'Delčevo Municipality',
        value: 'Delčevo Municipality',
      },
      {
        name: 'Demir Hisar Municipality',
        value: 'Demir Hisar Municipality',
      },
      {
        name: 'Demir Kapija Municipality',
        value: 'Demir Kapija Municipality',
      },
      {
        name: 'Dojran Municipality',
        value: 'Dojran Municipality',
      },
      {
        name: 'Dolneni Municipality',
        value: 'Dolneni Municipality',
      },
      {
        name: 'Drugovo Municipality',
        value: 'Drugovo Municipality',
      },
      {
        name: 'Gazi Baba Municipality',
        value: 'Gazi Baba Municipality',
      },
      {
        name: 'Gevgelija Municipality',
        value: 'Gevgelija Municipality',
      },
      {
        name: 'Gjorče Petrov Municipality',
        value: 'Gjorče Petrov Municipality',
      },
      {
        name: 'Gostivar Municipality',
        value: 'Gostivar Municipality',
      },
      {
        name: 'Gradsko Municipality',
        value: 'Gradsko Municipality',
      },
      {
        name: 'Greater Skopje',
        value: 'Greater Skopje',
      },
      {
        name: 'Ilinden Municipality',
        value: 'Ilinden Municipality',
      },
      {
        name: 'Jegunovce Municipality',
        value: 'Jegunovce Municipality',
      },
      {
        name: 'Karbinci',
        value: 'Karbinci',
      },
      {
        name: 'Karpoš Municipality',
        value: 'Karpoš Municipality',
      },
      {
        name: 'Kavadarci Municipality',
        value: 'Kavadarci Municipality',
      },
      {
        name: 'Kičevo Municipality',
        value: 'Kičevo Municipality',
      },
      {
        name: 'Kisela Voda Municipality',
        value: 'Kisela Voda Municipality',
      },
      {
        name: 'Kočani Municipality',
        value: 'Kočani Municipality',
      },
      {
        name: 'Konče Municipality',
        value: 'Konče Municipality',
      },
      {
        name: 'Kratovo Municipality',
        value: 'Kratovo Municipality',
      },
      {
        name: 'Kriva Palanka Municipality',
        value: 'Kriva Palanka Municipality',
      },
      {
        name: 'Krivogaštani Municipality',
        value: 'Krivogaštani Municipality',
      },
      {
        name: 'Kruševo Municipality',
        value: 'Kruševo Municipality',
      },
      {
        name: 'Kumanovo Municipality',
        value: 'Kumanovo Municipality',
      },
      {
        name: 'Lipkovo Municipality',
        value: 'Lipkovo Municipality',
      },
      {
        name: 'Lozovo Municipality',
        value: 'Lozovo Municipality',
      },
      {
        name: 'Makedonska Kamenica Municipality',
        value: 'Makedonska Kamenica Municipality',
      },
      {
        name: 'Makedonski Brod Municipality',
        value: 'Makedonski Brod Municipality',
      },
      {
        name: 'Mavrovo and Rostuša Municipality',
        value: 'Mavrovo and Rostuša Municipality',
      },
      {
        name: 'Mogila Municipality',
        value: 'Mogila Municipality',
      },
      {
        name: 'Negotino Municipality',
        value: 'Negotino Municipality',
      },
      {
        name: 'Novaci Municipality',
        value: 'Novaci Municipality',
      },
      {
        name: 'Novo Selo Municipality',
        value: 'Novo Selo Municipality',
      },
      {
        name: 'Ohrid Municipality',
        value: 'Ohrid Municipality',
      },
      {
        name: 'Oslomej Municipality',
        value: 'Oslomej Municipality',
      },
      {
        name: 'Pehčevo Municipality',
        value: 'Pehčevo Municipality',
      },
      {
        name: 'Petrovec Municipality',
        value: 'Petrovec Municipality',
      },
      {
        name: 'Plasnica Municipality',
        value: 'Plasnica Municipality',
      },
      {
        name: 'Prilep Municipality',
        value: 'Prilep Municipality',
      },
      {
        name: 'Probištip Municipality',
        value: 'Probištip Municipality',
      },
      {
        name: 'Radoviš Municipality',
        value: 'Radoviš Municipality',
      },
      {
        name: 'Rankovce Municipality',
        value: 'Rankovce Municipality',
      },
      {
        name: 'Resen Municipality',
        value: 'Resen Municipality',
      },
      {
        name: 'Rosoman Municipality',
        value: 'Rosoman Municipality',
      },
      {
        name: 'Saraj Municipality',
        value: 'Saraj Municipality',
      },
      {
        name: 'Sopište Municipality',
        value: 'Sopište Municipality',
      },
      {
        name: 'Staro Nagoričane Municipality',
        value: 'Staro Nagoričane Municipality',
      },
      {
        name: 'Štip Municipality',
        value: 'Štip Municipality',
      },
      {
        name: 'Struga Municipality',
        value: 'Struga Municipality',
      },
      {
        name: 'Strumica Municipality',
        value: 'Strumica Municipality',
      },
      {
        name: 'Studeničani Municipality',
        value: 'Studeničani Municipality',
      },
      {
        name: 'Šuto Orizari Municipality',
        value: 'Šuto Orizari Municipality',
      },
      {
        name: 'Sveti Nikole Municipality',
        value: 'Sveti Nikole Municipality',
      },
      {
        name: 'Tearce Municipality',
        value: 'Tearce Municipality',
      },
      {
        name: 'Tetovo Municipality',
        value: 'Tetovo Municipality',
      },
      {
        name: 'Valandovo Municipality',
        value: 'Valandovo Municipality',
      },
      {
        name: 'Vasilevo Municipality',
        value: 'Vasilevo Municipality',
      },
      {
        name: 'Veles Municipality',
        value: 'Veles Municipality',
      },
      {
        name: 'Vevčani Municipality',
        value: 'Vevčani Municipality',
      },
      {
        name: 'Vinica Municipality',
        value: 'Vinica Municipality',
      },
      {
        name: 'Vraneštica Municipality',
        value: 'Vraneštica Municipality',
      },
      {
        name: 'Vrapčište Municipality',
        value: 'Vrapčište Municipality',
      },
      {
        name: 'Zajas Municipality',
        value: 'Zajas Municipality',
      },
      {
        name: 'Zelenikovo Municipality',
        value: 'Zelenikovo Municipality',
      },
      {
        name: 'Želino Municipality',
        value: 'Želino Municipality',
      },
      {
        name: 'Zrnovci Municipality',
        value: 'Zrnovci Municipality',
      },
    ],
  },
  NO: {
    name: 'Norway',
    type: 'county',
    states: [
      {
        name: 'Agder',
        value: 'Agder',
      },
      {
        name: 'Innlandet',
        value: 'Innlandet',
      },
      {
        name: 'Jan Mayen',
        value: 'Jan Mayen',
      },
      {
        name: 'Møre og Romsdal',
        value: 'Møre og Romsdal',
      },
      {
        name: 'Nordland',
        value: 'Nordland',
      },
      {
        name: 'Oslo',
        value: 'Oslo',
      },
      {
        name: 'Rogaland',
        value: 'Rogaland',
      },
      {
        name: 'Svalbard',
        value: 'Svalbard',
      },
      {
        name: 'Troms og Finnmark',
        value: 'Troms og Finnmark',
      },
      {
        name: 'Trøndelag',
        value: 'Trøndelag',
      },
      {
        name: 'Vestfold og Telemark',
        value: 'Vestfold og Telemark',
      },
      {
        name: 'Vestland',
        value: 'Vestland',
      },
      {
        name: 'Viken',
        value: 'Viken',
      },
    ],
  },
  OM: {
    name: 'Oman',
    type: null,
    states: [
      {
        name: 'Ad Dakhiliyah',
        value: 'Ad Dakhiliyah',
      },
      {
        name: 'Ad Dhahirah',
        value: 'Ad Dhahirah',
      },
      {
        name: 'Al Batinah North',
        value: 'Al Batinah North',
      },
      {
        name: 'Al Batinah Region',
        value: 'Al Batinah Region',
      },
      {
        name: 'Al Batinah South',
        value: 'Al Batinah South',
      },
      {
        name: 'Al Buraimi',
        value: 'Al Buraimi',
      },
      {
        name: 'Al Wusta',
        value: 'Al Wusta',
      },
      {
        name: 'Ash Sharqiyah North',
        value: 'Ash Sharqiyah North',
      },
      {
        name: 'Ash Sharqiyah Region',
        value: 'Ash Sharqiyah Region',
      },
      {
        name: 'Ash Sharqiyah South',
        value: 'Ash Sharqiyah South',
      },
      {
        name: 'Dhofar',
        value: 'Dhofar',
      },
      {
        name: 'Musandam',
        value: 'Musandam',
      },
      {
        name: 'Muscat',
        value: 'Muscat',
      },
    ],
  },
  PK: {
    name: 'Pakistan',
    type: null,
    states: [
      {
        name: 'Azad Kashmir',
        value: 'Azad Kashmir',
      },
      {
        name: 'Balochistan',
        value: 'Balochistan',
      },
      {
        name: 'Federally Administered Tribal Areas',
        value: 'Federally Administered Tribal Areas',
      },
      {
        name: 'Gilgit-Baltistan',
        value: 'Gilgit-Baltistan',
      },
      {
        name: 'Islamabad Capital Territory',
        value: 'Islamabad Capital Territory',
      },
      {
        name: 'Khyber Pakhtunkhwa',
        value: 'Khyber Pakhtunkhwa',
      },
      {
        name: 'Punjab',
        value: 'Punjab',
      },
      {
        name: 'Sindh',
        value: 'Sindh',
      },
    ],
  },
  PW: {
    name: 'Palau',
    type: null,
    states: [
      {
        name: 'Aimeliik',
        value: 'Aimeliik',
      },
      {
        name: 'Airai',
        value: 'Airai',
      },
      {
        name: 'Angaur',
        value: 'Angaur',
      },
      {
        name: 'Hatohobei',
        value: 'Hatohobei',
      },
      {
        name: 'Kayangel',
        value: 'Kayangel',
      },
      {
        name: 'Koror',
        value: 'Koror',
      },
      {
        name: 'Melekeok',
        value: 'Melekeok',
      },
      {
        name: 'Ngaraard',
        value: 'Ngaraard',
      },
      {
        name: 'Ngarchelong',
        value: 'Ngarchelong',
      },
      {
        name: 'Ngardmau',
        value: 'Ngardmau',
      },
      {
        name: 'Ngatpang',
        value: 'Ngatpang',
      },
      {
        name: 'Ngchesar',
        value: 'Ngchesar',
      },
      {
        name: 'Ngeremlengui',
        value: 'Ngeremlengui',
      },
      {
        name: 'Ngiwal',
        value: 'Ngiwal',
      },
      {
        name: 'Peleliu',
        value: 'Peleliu',
      },
      {
        name: 'Sonsorol',
        value: 'Sonsorol',
      },
    ],
  },
  PS: {
    name: 'Palestinian Territory Occupied',
    type: 'governorate',
    states: [
      {
        name: 'Bethlehem',
        value: 'Bethlehem',
      },
      {
        name: 'Deir El Balah',
        value: 'Deir El Balah',
      },
      {
        name: 'Gaza',
        value: 'Gaza',
      },
      {
        name: 'Hebron',
        value: 'Hebron',
      },
      {
        name: 'Jenin',
        value: 'Jenin',
      },
      {
        name: 'Jericho and Al Aghwar',
        value: 'Jericho and Al Aghwar',
      },
      {
        name: 'Jerusalem',
        value: 'Jerusalem',
      },
      {
        name: 'Khan Yunis',
        value: 'Khan Yunis',
      },
      {
        name: 'Nablus',
        value: 'Nablus',
      },
      {
        name: 'North Gaza',
        value: 'North Gaza',
      },
      {
        name: 'Qalqilya',
        value: 'Qalqilya',
      },
      {
        name: 'Rafah',
        value: 'Rafah',
      },
      {
        name: 'Ramallah',
        value: 'Ramallah',
      },
      {
        name: 'Salfit',
        value: 'Salfit',
      },
      {
        name: 'Tubas',
        value: 'Tubas',
      },
      {
        name: 'Tulkarm',
        value: 'Tulkarm',
      },
    ],
  },
  PA: {
    name: 'Panama',
    type: null,
    states: [
      {
        name: 'Bocas del Toro Province',
        value: 'Bocas del Toro Province',
      },
      {
        name: 'Chiriquí Province',
        value: 'Chiriquí Province',
      },
      {
        name: 'Coclé Province',
        value: 'Coclé Province',
      },
      {
        name: 'Colón Province',
        value: 'Colón Province',
      },
      {
        name: 'Darién Province',
        value: 'Darién Province',
      },
      {
        name: 'Emberá-Wounaan Comarca',
        value: 'Emberá-Wounaan Comarca',
      },
      {
        name: 'Guna Yala',
        value: 'Guna Yala',
      },
      {
        name: 'Herrera Province',
        value: 'Herrera Province',
      },
      {
        name: 'Los Santos Province',
        value: 'Los Santos Province',
      },
      {
        name: 'Ngöbe-Buglé Comarca',
        value: 'Ngöbe-Buglé Comarca',
      },
      {
        name: 'Panamá Oeste Province',
        value: 'Panamá Oeste Province',
      },
      {
        name: 'Panamá Province',
        value: 'Panamá Province',
      },
      {
        name: 'Veraguas Province',
        value: 'Veraguas Province',
      },
    ],
  },
  PG: {
    name: 'Papua New Guinea',
    type: null,
    states: [
      {
        name: 'Bougainville',
        value: 'Bougainville',
      },
      {
        name: 'Central Province',
        value: 'Central Province',
      },
      {
        name: 'Chimbu Province',
        value: 'Chimbu Province',
      },
      {
        name: 'East New Britain',
        value: 'East New Britain',
      },
      {
        name: 'East Sepik',
        value: 'East Sepik',
      },
      {
        name: 'Eastern Highlands Province',
        value: 'Eastern Highlands Province',
      },
      {
        name: 'Enga Province',
        value: 'Enga Province',
      },
      {
        name: 'Gulf',
        value: 'Gulf',
      },
      {
        name: 'Hela',
        value: 'Hela',
      },
      {
        name: 'Jiwaka Province',
        value: 'Jiwaka Province',
      },
      {
        name: 'Madang Province',
        value: 'Madang Province',
      },
      {
        name: 'Manus Province',
        value: 'Manus Province',
      },
      {
        name: 'Milne Bay Province',
        value: 'Milne Bay Province',
      },
      {
        name: 'Morobe Province',
        value: 'Morobe Province',
      },
      {
        name: 'New Ireland Province',
        value: 'New Ireland Province',
      },
      {
        name: 'Oro Province',
        value: 'Oro Province',
      },
      {
        name: 'Port Moresby',
        value: 'Port Moresby',
      },
      {
        name: 'Sandaun Province',
        value: 'Sandaun Province',
      },
      {
        name: 'Southern Highlands Province',
        value: 'Southern Highlands Province',
      },
      {
        name: 'West New Britain Province',
        value: 'West New Britain Province',
      },
      {
        name: 'Western Highlands Province',
        value: 'Western Highlands Province',
      },
      {
        name: 'Western Province',
        value: 'Western Province',
      },
    ],
  },
  PY: {
    name: 'Paraguay',
    type: null,
    states: [
      {
        name: 'Alto Paraguay Department',
        value: 'Alto Paraguay Department',
      },
      {
        name: 'Alto Paraná Department',
        value: 'Alto Paraná Department',
      },
      {
        name: 'Amambay Department',
        value: 'Amambay Department',
      },
      {
        name: 'Asuncion',
        value: 'Asuncion',
      },
      {
        name: 'Boquerón Department',
        value: 'Boquerón Department',
      },
      {
        name: 'Caaguazú',
        value: 'Caaguazú',
      },
      {
        name: 'Caazapá',
        value: 'Caazapá',
      },
      {
        name: 'Canindeyú',
        value: 'Canindeyú',
      },
      {
        name: 'Central Department',
        value: 'Central Department',
      },
      {
        name: 'Concepción Department',
        value: 'Concepción Department',
      },
      {
        name: 'Cordillera Department',
        value: 'Cordillera Department',
      },
      {
        name: 'Guairá Department',
        value: 'Guairá Department',
      },
      {
        name: 'Itapúa',
        value: 'Itapúa',
      },
      {
        name: 'Misiones Department',
        value: 'Misiones Department',
      },
      {
        name: 'Ñeembucú Department',
        value: 'Ñeembucú Department',
      },
      {
        name: 'Paraguarí Department',
        value: 'Paraguarí Department',
      },
      {
        name: 'Presidente Hayes Department',
        value: 'Presidente Hayes Department',
      },
      {
        name: 'San Pedro Department',
        value: 'San Pedro Department',
      },
    ],
  },
  PE: {
    name: 'Peru',
    type: null,
    states: [
      {
        name: 'Amazonas',
        value: 'Amazonas',
      },
      {
        name: 'Áncash',
        value: 'Áncash',
      },
      {
        name: 'Apurímac',
        value: 'Apurímac',
      },
      {
        name: 'Arequipa',
        value: 'Arequipa',
      },
      {
        name: 'Ayacucho',
        value: 'Ayacucho',
      },
      {
        name: 'Cajamarca',
        value: 'Cajamarca',
      },
      {
        name: 'Callao',
        value: 'Callao',
      },
      {
        name: 'Cusco',
        value: 'Cusco',
      },
      {
        name: 'Huancavelica',
        value: 'Huancavelica',
      },
      {
        name: 'Huanuco',
        value: 'Huanuco',
      },
      {
        name: 'Ica',
        value: 'Ica',
      },
      {
        name: 'Junín',
        value: 'Junín',
      },
      {
        name: 'La Libertad',
        value: 'La Libertad',
      },
      {
        name: 'Lambayeque',
        value: 'Lambayeque',
      },
      {
        name: 'Lima',
        value: 'Lima',
      },
      {
        name: 'Loreto',
        value: 'Loreto',
      },
      {
        name: 'Madre de Dios',
        value: 'Madre de Dios',
      },
      {
        name: 'Moquegua',
        value: 'Moquegua',
      },
      {
        name: 'Pasco',
        value: 'Pasco',
      },
      {
        name: 'Piura',
        value: 'Piura',
      },
      {
        name: 'Puno',
        value: 'Puno',
      },
      {
        name: 'San Martín',
        value: 'San Martín',
      },
      {
        name: 'Tacna',
        value: 'Tacna',
      },
      {
        name: 'Tumbes',
        value: 'Tumbes',
      },
      {
        name: 'Ucayali',
        value: 'Ucayali',
      },
    ],
  },
  PH: {
    name: 'Philippines',
    type: 'province',
    states: [
      {
        name: 'Abra',
        value: 'Abra',
      },
      {
        name: 'Agusan del Norte',
        value: 'Agusan del Norte',
      },
      {
        name: 'Agusan del Sur',
        value: 'Agusan del Sur',
      },
      {
        name: 'Aklan',
        value: 'Aklan',
      },
      {
        name: 'Albay',
        value: 'Albay',
      },
      {
        name: 'Antique',
        value: 'Antique',
      },
      {
        name: 'Apayao',
        value: 'Apayao',
      },
      {
        name: 'Aurora',
        value: 'Aurora',
      },
      {
        name: 'Autonomous Region in Muslim Mindanao',
        value: 'Autonomous Region in Muslim Mindanao',
      },
      {
        name: 'Basilan',
        value: 'Basilan',
      },
      {
        name: 'Bataan',
        value: 'Bataan',
      },
      {
        name: 'Batanes',
        value: 'Batanes',
      },
      {
        name: 'Batangas',
        value: 'Batangas',
      },
      {
        name: 'Benguet',
        value: 'Benguet',
      },
      {
        name: 'Bicol',
        value: 'Bicol',
      },
      {
        name: 'Biliran',
        value: 'Biliran',
      },
      {
        name: 'Bohol',
        value: 'Bohol',
      },
      {
        name: 'Bukidnon',
        value: 'Bukidnon',
      },
      {
        name: 'Bulacan',
        value: 'Bulacan',
      },
      {
        name: 'Cagayan',
        value: 'Cagayan',
      },
      {
        name: 'Cagayan Valley',
        value: 'Cagayan Valley',
      },
      {
        name: 'Calabarzon',
        value: 'Calabarzon',
      },
      {
        name: 'Camarines Norte',
        value: 'Camarines Norte',
      },
      {
        name: 'Camarines Sur',
        value: 'Camarines Sur',
      },
      {
        name: 'Camiguin',
        value: 'Camiguin',
      },
      {
        name: 'Capiz',
        value: 'Capiz',
      },
      {
        name: 'Caraga',
        value: 'Caraga',
      },
      {
        name: 'Catanduanes',
        value: 'Catanduanes',
      },
      {
        name: 'Cavite',
        value: 'Cavite',
      },
      {
        name: 'Cebu',
        value: 'Cebu',
      },
      {
        name: 'Central Luzon',
        value: 'Central Luzon',
      },
      {
        name: 'Central Visayas',
        value: 'Central Visayas',
      },
      {
        name: 'Compostela Valley',
        value: 'Compostela Valley',
      },
      {
        name: 'Cordillera Administrative',
        value: 'Cordillera Administrative',
      },
      {
        name: 'Cotabato',
        value: 'Cotabato',
      },
      {
        name: 'Davao',
        value: 'Davao',
      },
      {
        name: 'Davao del Norte',
        value: 'Davao del Norte',
      },
      {
        name: 'Davao del Sur',
        value: 'Davao del Sur',
      },
      {
        name: 'Davao Occidental',
        value: 'Davao Occidental',
      },
      {
        name: 'Davao Oriental',
        value: 'Davao Oriental',
      },
      {
        name: 'Dinagat Islands',
        value: 'Dinagat Islands',
      },
      {
        name: 'Eastern Samar',
        value: 'Eastern Samar',
      },
      {
        name: 'Eastern Visayas',
        value: 'Eastern Visayas',
      },
      {
        name: 'Guimaras',
        value: 'Guimaras',
      },
      {
        name: 'Ifugao',
        value: 'Ifugao',
      },
      {
        name: 'Ilocos',
        value: 'Ilocos',
      },
      {
        name: 'Ilocos Norte',
        value: 'Ilocos Norte',
      },
      {
        name: 'Ilocos Sur',
        value: 'Ilocos Sur',
      },
      {
        name: 'Iloilo',
        value: 'Iloilo',
      },
      {
        name: 'Isabela',
        value: 'Isabela',
      },
      {
        name: 'Kalinga',
        value: 'Kalinga',
      },
      {
        name: 'La Union',
        value: 'La Union',
      },
      {
        name: 'Laguna',
        value: 'Laguna',
      },
      {
        name: 'Lanao del Norte',
        value: 'Lanao del Norte',
      },
      {
        name: 'Lanao del Sur',
        value: 'Lanao del Sur',
      },
      {
        name: 'Leyte',
        value: 'Leyte',
      },
      {
        name: 'Maguindanao',
        value: 'Maguindanao',
      },
      {
        name: 'Marinduque',
        value: 'Marinduque',
      },
      {
        name: 'Masbate',
        value: 'Masbate',
      },
      {
        name: 'Metro Manila',
        value: 'Metro Manila',
      },
      {
        name: 'Mimaropa',
        value: 'Mimaropa',
      },
      {
        name: 'Misamis Occidental',
        value: 'Misamis Occidental',
      },
      {
        name: 'Misamis Oriental',
        value: 'Misamis Oriental',
      },
      {
        name: 'Mountain Province',
        value: 'Mountain Province',
      },
      {
        name: 'Negros Occidental',
        value: 'Negros Occidental',
      },
      {
        name: 'Negros Oriental',
        value: 'Negros Oriental',
      },
      {
        name: 'Northern Mindanao',
        value: 'Northern Mindanao',
      },
      {
        name: 'Northern Samar',
        value: 'Northern Samar',
      },
      {
        name: 'Nueva Ecija',
        value: 'Nueva Ecija',
      },
      {
        name: 'Nueva Vizcaya',
        value: 'Nueva Vizcaya',
      },
      {
        name: 'Occidental Mindoro',
        value: 'Occidental Mindoro',
      },
      {
        name: 'Oriental Mindoro',
        value: 'Oriental Mindoro',
      },
      {
        name: 'Palawan',
        value: 'Palawan',
      },
      {
        name: 'Pampanga',
        value: 'Pampanga',
      },
      {
        name: 'Pangasinan',
        value: 'Pangasinan',
      },
      {
        name: 'Quezon',
        value: 'Quezon',
      },
      {
        name: 'Quirino',
        value: 'Quirino',
      },
      {
        name: 'Rizal',
        value: 'Rizal',
      },
      {
        name: 'Romblon',
        value: 'Romblon',
      },
      {
        name: 'Sarangani',
        value: 'Sarangani',
      },
      {
        name: 'Siquijor',
        value: 'Siquijor',
      },
      {
        name: 'Soccsksargen',
        value: 'Soccsksargen',
      },
      {
        name: 'Sorsogon',
        value: 'Sorsogon',
      },
      {
        name: 'South Cotabato',
        value: 'South Cotabato',
      },
      {
        name: 'Southern Leyte',
        value: 'Southern Leyte',
      },
      {
        name: 'Sultan Kudarat',
        value: 'Sultan Kudarat',
      },
      {
        name: 'Sulu',
        value: 'Sulu',
      },
      {
        name: 'Surigao del Norte',
        value: 'Surigao del Norte',
      },
      {
        name: 'Surigao del Sur',
        value: 'Surigao del Sur',
      },
      {
        name: 'Tarlac',
        value: 'Tarlac',
      },
      {
        name: 'Tawi-Tawi',
        value: 'Tawi-Tawi',
      },
      {
        name: 'Western Samar',
        value: 'Western Samar',
      },
      {
        name: 'Western Visayas',
        value: 'Western Visayas',
      },
      {
        name: 'Zambales',
        value: 'Zambales',
      },
      {
        name: 'Zamboanga del Norte',
        value: 'Zamboanga del Norte',
      },
      {
        name: 'Zamboanga del Sur',
        value: 'Zamboanga del Sur',
      },
      {
        name: 'Zamboanga Peninsula',
        value: 'Zamboanga Peninsula',
      },
      {
        name: 'Zamboanga Sibugay',
        value: 'Zamboanga Sibugay',
      },
    ],
  },
  PL: {
    name: 'Poland',
    type: 'voivodship',
    states: [
      {
        name: 'Greater Poland',
        value: 'Greater Poland',
      },
      {
        name: 'Holy Cross',
        value: 'Holy Cross',
      },
      {
        name: 'Kuyavia-Pomerania',
        value: 'Kuyavia-Pomerania',
      },
      {
        name: 'Lesser Poland',
        value: 'Lesser Poland',
      },
      {
        name: 'Lower Silesia',
        value: 'Lower Silesia',
      },
      {
        name: 'Lublin',
        value: 'Lublin',
      },
      {
        name: 'Lubusz',
        value: 'Lubusz',
      },
      {
        name: 'Łódź',
        value: 'Łódź',
      },
      {
        name: 'Mazovia',
        value: 'Mazovia',
      },
      {
        name: 'Podlaskie',
        value: 'Podlaskie',
      },
      {
        name: 'Pomerania',
        value: 'Pomerania',
      },
      {
        name: 'Silesia',
        value: 'Silesia',
      },
      {
        name: 'Subcarpathia',
        value: 'Subcarpathia',
      },
      {
        name: 'Upper Silesia',
        value: 'Upper Silesia',
      },
      {
        name: 'Warmia-Masuria',
        value: 'Warmia-Masuria',
      },
      {
        name: 'West Pomerania',
        value: 'West Pomerania',
      },
    ],
  },
  PT: {
    name: 'Portugal',
    type: null,
    states: [
      {
        name: 'Açores',
        value: 'Açores',
      },
      {
        name: 'Aveiro',
        value: 'Aveiro',
      },
      {
        name: 'Beja',
        value: 'Beja',
      },
      {
        name: 'Braga',
        value: 'Braga',
      },
      {
        name: 'Bragança',
        value: 'Bragança',
      },
      {
        name: 'Castelo Branco',
        value: 'Castelo Branco',
      },
      {
        name: 'Coimbra',
        value: 'Coimbra',
      },
      {
        name: 'Évora',
        value: 'Évora',
      },
      {
        name: 'Faro',
        value: 'Faro',
      },
      {
        name: 'Guarda',
        value: 'Guarda',
      },
      {
        name: 'Leiria',
        value: 'Leiria',
      },
      {
        name: 'Lisbon',
        value: 'Lisbon',
      },
      {
        name: 'Madeira',
        value: 'Madeira',
      },
      {
        name: 'Portalegre',
        value: 'Portalegre',
      },
      {
        name: 'Porto',
        value: 'Porto',
      },
      {
        name: 'Santarém',
        value: 'Santarém',
      },
      {
        name: 'Setúbal',
        value: 'Setúbal',
      },
      {
        name: 'Viana do Castelo',
        value: 'Viana do Castelo',
      },
      {
        name: 'Vila Real',
        value: 'Vila Real',
      },
      {
        name: 'Viseu',
        value: 'Viseu',
      },
    ],
  },
  PR: {
    name: 'Puerto Rico',
    type: 'outlying area',
    states: [
      {
        name: 'Puerto Rico',
        value: 'Puerto Rico',
      },
    ],
  },
  QA: {
    name: 'Qatar',
    type: null,
    states: [
      {
        name: 'Al Daayen',
        value: 'Al Daayen',
      },
      {
        name: 'Al Khor',
        value: 'Al Khor',
      },
      {
        name: 'Al Rayyan Municipality',
        value: 'Al Rayyan Municipality',
      },
      {
        name: 'Al Wakrah',
        value: 'Al Wakrah',
      },
      {
        name: 'Al-Shahaniya',
        value: 'Al-Shahaniya',
      },
      {
        name: 'Doha',
        value: 'Doha',
      },
      {
        name: 'Madinat ash Shamal',
        value: 'Madinat ash Shamal',
      },
      {
        name: 'Umm Salal Municipality',
        value: 'Umm Salal Municipality',
      },
    ],
  },
  RO: {
    name: 'Romania',
    type: null,
    states: [
      {
        name: 'Alba',
        value: 'Alba',
      },
      {
        name: 'Arad County',
        value: 'Arad County',
      },
      {
        name: 'Arges',
        value: 'Arges',
      },
      {
        name: 'Bacău County',
        value: 'Bacău County',
      },
      {
        name: 'Bihor County',
        value: 'Bihor County',
      },
      {
        name: 'Bistrița-Năsăud County',
        value: 'Bistrița-Năsăud County',
      },
      {
        name: 'Botoșani County',
        value: 'Botoșani County',
      },
      {
        name: 'Braila',
        value: 'Braila',
      },
      {
        name: 'Brașov County',
        value: 'Brașov County',
      },
      {
        name: 'Bucharest',
        value: 'Bucharest',
      },
      {
        name: 'Buzău County',
        value: 'Buzău County',
      },
      {
        name: 'Călărași County',
        value: 'Călărași County',
      },
      {
        name: 'Caraș-Severin County',
        value: 'Caraș-Severin County',
      },
      {
        name: 'Cluj County',
        value: 'Cluj County',
      },
      {
        name: 'Constanța County',
        value: 'Constanța County',
      },
      {
        name: 'Covasna County',
        value: 'Covasna County',
      },
      {
        name: 'Dâmbovița County',
        value: 'Dâmbovița County',
      },
      {
        name: 'Dolj County',
        value: 'Dolj County',
      },
      {
        name: 'Galați County',
        value: 'Galați County',
      },
      {
        name: 'Giurgiu County',
        value: 'Giurgiu County',
      },
      {
        name: 'Gorj County',
        value: 'Gorj County',
      },
      {
        name: 'Harghita County',
        value: 'Harghita County',
      },
      {
        name: 'Hunedoara County',
        value: 'Hunedoara County',
      },
      {
        name: 'Ialomița County',
        value: 'Ialomița County',
      },
      {
        name: 'Iași County',
        value: 'Iași County',
      },
      {
        name: 'Ilfov County',
        value: 'Ilfov County',
      },
      {
        name: 'Maramureș County',
        value: 'Maramureș County',
      },
      {
        name: 'Mehedinți County',
        value: 'Mehedinți County',
      },
      {
        name: 'Mureș County',
        value: 'Mureș County',
      },
      {
        name: 'Neamț County',
        value: 'Neamț County',
      },
      {
        name: 'Olt County',
        value: 'Olt County',
      },
      {
        name: 'Prahova County',
        value: 'Prahova County',
      },
      {
        name: 'Sălaj County',
        value: 'Sălaj County',
      },
      {
        name: 'Satu Mare County',
        value: 'Satu Mare County',
      },
      {
        name: 'Sibiu County',
        value: 'Sibiu County',
      },
      {
        name: 'Suceava County',
        value: 'Suceava County',
      },
      {
        name: 'Teleorman County',
        value: 'Teleorman County',
      },
      {
        name: 'Timiș County',
        value: 'Timiș County',
      },
      {
        name: 'Tulcea County',
        value: 'Tulcea County',
      },
      {
        name: 'Vâlcea County',
        value: 'Vâlcea County',
      },
      {
        name: 'Vaslui County',
        value: 'Vaslui County',
      },
      {
        name: 'Vrancea County',
        value: 'Vrancea County',
      },
    ],
  },
  RU: {
    name: 'Russia',
    type: null,
    states: [
      {
        name: 'Altai Krai',
        value: 'Altai Krai',
      },
      {
        name: 'Altai Republic',
        value: 'Altai Republic',
      },
      {
        name: 'Amur Oblast',
        value: 'Amur Oblast',
      },
      {
        name: 'Arkhangelsk',
        value: 'Arkhangelsk',
      },
      {
        name: 'Astrakhan Oblast',
        value: 'Astrakhan Oblast',
      },
      {
        name: 'Belgorod Oblast',
        value: 'Belgorod Oblast',
      },
      {
        name: 'Bryansk Oblast',
        value: 'Bryansk Oblast',
      },
      {
        name: 'Chechen Republic',
        value: 'Chechen Republic',
      },
      {
        name: 'Chelyabinsk Oblast',
        value: 'Chelyabinsk Oblast',
      },
      {
        name: 'Chukotka Autonomous Okrug',
        value: 'Chukotka Autonomous Okrug',
      },
      {
        name: 'Chuvash Republic',
        value: 'Chuvash Republic',
      },
      {
        name: 'Irkutsk',
        value: 'Irkutsk',
      },
      {
        name: 'Ivanovo Oblast',
        value: 'Ivanovo Oblast',
      },
      {
        name: 'Jewish Autonomous Oblast',
        value: 'Jewish Autonomous Oblast',
      },
      {
        name: 'Kabardino-Balkar Republic',
        value: 'Kabardino-Balkar Republic',
      },
      {
        name: 'Kaliningrad',
        value: 'Kaliningrad',
      },
      {
        name: 'Kaluga Oblast',
        value: 'Kaluga Oblast',
      },
      {
        name: 'Kamchatka Krai',
        value: 'Kamchatka Krai',
      },
      {
        name: 'Karachay-Cherkess Republic',
        value: 'Karachay-Cherkess Republic',
      },
      {
        name: 'Kemerovo Oblast',
        value: 'Kemerovo Oblast',
      },
      {
        name: 'Khabarovsk Krai',
        value: 'Khabarovsk Krai',
      },
      {
        name: 'Khanty-Mansi Autonomous Okrug',
        value: 'Khanty-Mansi Autonomous Okrug',
      },
      {
        name: 'Kirov Oblast',
        value: 'Kirov Oblast',
      },
      {
        name: 'Komi Republic',
        value: 'Komi Republic',
      },
      {
        name: 'Kostroma Oblast',
        value: 'Kostroma Oblast',
      },
      {
        name: 'Krasnodar Krai',
        value: 'Krasnodar Krai',
      },
      {
        name: 'Krasnoyarsk Krai',
        value: 'Krasnoyarsk Krai',
      },
      {
        name: 'Kurgan Oblast',
        value: 'Kurgan Oblast',
      },
      {
        name: 'Kursk Oblast',
        value: 'Kursk Oblast',
      },
      {
        name: 'Leningrad Oblast',
        value: 'Leningrad Oblast',
      },
      {
        name: 'Lipetsk Oblast',
        value: 'Lipetsk Oblast',
      },
      {
        name: 'Magadan Oblast',
        value: 'Magadan Oblast',
      },
      {
        name: 'Mari El Republic',
        value: 'Mari El Republic',
      },
      {
        name: 'Moscow',
        value: 'Moscow',
      },
      {
        name: 'Moscow Oblast',
        value: 'Moscow Oblast',
      },
      {
        name: 'Murmansk Oblast',
        value: 'Murmansk Oblast',
      },
      {
        name: 'Nenets Autonomous Okrug',
        value: 'Nenets Autonomous Okrug',
      },
      {
        name: 'Nizhny Novgorod Oblast',
        value: 'Nizhny Novgorod Oblast',
      },
      {
        name: 'Novgorod Oblast',
        value: 'Novgorod Oblast',
      },
      {
        name: 'Novosibirsk',
        value: 'Novosibirsk',
      },
      {
        name: 'Omsk Oblast',
        value: 'Omsk Oblast',
      },
      {
        name: 'Orenburg Oblast',
        value: 'Orenburg Oblast',
      },
      {
        name: 'Oryol Oblast',
        value: 'Oryol Oblast',
      },
      {
        name: 'Penza Oblast',
        value: 'Penza Oblast',
      },
      {
        name: 'Perm Krai',
        value: 'Perm Krai',
      },
      {
        name: 'Primorsky Krai',
        value: 'Primorsky Krai',
      },
      {
        name: 'Pskov Oblast',
        value: 'Pskov Oblast',
      },
      {
        name: 'Republic of Adygea',
        value: 'Republic of Adygea',
      },
      {
        name: 'Republic of Bashkortostan',
        value: 'Republic of Bashkortostan',
      },
      {
        name: 'Republic of Buryatia',
        value: 'Republic of Buryatia',
      },
      {
        name: 'Republic of Dagestan',
        value: 'Republic of Dagestan',
      },
      {
        name: 'Republic of Ingushetia',
        value: 'Republic of Ingushetia',
      },
      {
        name: 'Republic of Kalmykia',
        value: 'Republic of Kalmykia',
      },
      {
        name: 'Republic of Karelia',
        value: 'Republic of Karelia',
      },
      {
        name: 'Republic of Khakassia',
        value: 'Republic of Khakassia',
      },
      {
        name: 'Republic of Mordovia',
        value: 'Republic of Mordovia',
      },
      {
        name: 'Republic of North Ossetia-Alania',
        value: 'Republic of North Ossetia-Alania',
      },
      {
        name: 'Republic of Tatarstan',
        value: 'Republic of Tatarstan',
      },
      {
        name: 'Rostov Oblast',
        value: 'Rostov Oblast',
      },
      {
        name: 'Ryazan Oblast',
        value: 'Ryazan Oblast',
      },
      {
        name: 'Saint Petersburg',
        value: 'Saint Petersburg',
      },
      {
        name: 'Sakha Republic',
        value: 'Sakha Republic',
      },
      {
        name: 'Sakhalin',
        value: 'Sakhalin',
      },
      {
        name: 'Samara Oblast',
        value: 'Samara Oblast',
      },
      {
        name: 'Saratov Oblast',
        value: 'Saratov Oblast',
      },
      {
        name: 'Smolensk Oblast',
        value: 'Smolensk Oblast',
      },
      {
        name: 'Stavropol Krai',
        value: 'Stavropol Krai',
      },
      {
        name: 'Sverdlovsk',
        value: 'Sverdlovsk',
      },
      {
        name: 'Tambov Oblast',
        value: 'Tambov Oblast',
      },
      {
        name: 'Tomsk Oblast',
        value: 'Tomsk Oblast',
      },
      {
        name: 'Tula Oblast',
        value: 'Tula Oblast',
      },
      {
        name: 'Tuva Republic',
        value: 'Tuva Republic',
      },
      {
        name: 'Tver Oblast',
        value: 'Tver Oblast',
      },
      {
        name: 'Tyumen Oblast',
        value: 'Tyumen Oblast',
      },
      {
        name: 'Udmurt Republic',
        value: 'Udmurt Republic',
      },
      {
        name: 'Ulyanovsk Oblast',
        value: 'Ulyanovsk Oblast',
      },
      {
        name: 'Vladimir Oblast',
        value: 'Vladimir Oblast',
      },
      {
        name: 'Volgograd Oblast',
        value: 'Volgograd Oblast',
      },
      {
        name: 'Vologda Oblast',
        value: 'Vologda Oblast',
      },
      {
        name: 'Voronezh Oblast',
        value: 'Voronezh Oblast',
      },
      {
        name: 'Yamalo-Nenets Autonomous Okrug',
        value: 'Yamalo-Nenets Autonomous Okrug',
      },
      {
        name: 'Yaroslavl Oblast',
        value: 'Yaroslavl Oblast',
      },
      {
        name: 'Zabaykalsky Krai',
        value: 'Zabaykalsky Krai',
      },
    ],
  },
  RW: {
    name: 'Rwanda',
    type: null,
    states: [
      {
        name: 'Eastern Province',
        value: 'Eastern Province',
      },
      {
        name: 'Kigali district',
        value: 'Kigali district',
      },
      {
        name: 'Northern Province',
        value: 'Northern Province',
      },
      {
        name: 'Southern Province',
        value: 'Southern Province',
      },
      {
        name: 'Western Province',
        value: 'Western Province',
      },
    ],
  },
  KN: {
    name: 'Saint Kitts and Nevis',
    type: null,
    states: [
      {
        name: 'Christ Church Nichola Town Parish',
        value: 'Christ Church Nichola Town Parish',
      },
      {
        name: 'Nevis',
        value: 'Nevis',
      },
      {
        name: 'Saint Anne Sandy Point Parish',
        value: 'Saint Anne Sandy Point Parish',
      },
      {
        name: 'Saint George Gingerland Parish',
        value: 'Saint George Gingerland Parish',
      },
      {
        name: 'Saint James Windward Parish',
        value: 'Saint James Windward Parish',
      },
      {
        name: 'Saint John Capisterre Parish',
        value: 'Saint John Capisterre Parish',
      },
      {
        name: 'Saint John Figtree Parish',
        value: 'Saint John Figtree Parish',
      },
      {
        name: 'Saint Kitts',
        value: 'Saint Kitts',
      },
      {
        name: 'Saint Mary Cayon Parish',
        value: 'Saint Mary Cayon Parish',
      },
      {
        name: 'Saint Paul Capisterre Parish',
        value: 'Saint Paul Capisterre Parish',
      },
      {
        name: 'Saint Paul Charlestown Parish',
        value: 'Saint Paul Charlestown Parish',
      },
      {
        name: 'Saint Peter Basseterre Parish',
        value: 'Saint Peter Basseterre Parish',
      },
      {
        name: 'Saint Thomas Lowland Parish',
        value: 'Saint Thomas Lowland Parish',
      },
      {
        name: 'Saint Thomas Middle Island Parish',
        value: 'Saint Thomas Middle Island Parish',
      },
      {
        name: 'Trinity Palmetto Point Parish',
        value: 'Trinity Palmetto Point Parish',
      },
    ],
  },
  LC: {
    name: 'Saint Lucia',
    type: null,
    states: [
      {
        name: 'Anse la Raye Quarter',
        value: 'Anse la Raye Quarter',
      },
      {
        name: 'Canaries',
        value: 'Canaries',
      },
      {
        name: 'Castries Quarter',
        value: 'Castries Quarter',
      },
      {
        name: 'Choiseul Quarter',
        value: 'Choiseul Quarter',
      },
      {
        name: 'Dauphin Quarter',
        value: 'Dauphin Quarter',
      },
      {
        name: 'Dennery Quarter',
        value: 'Dennery Quarter',
      },
      {
        name: 'Gros Islet Quarter',
        value: 'Gros Islet Quarter',
      },
      {
        name: 'Laborie Quarter',
        value: 'Laborie Quarter',
      },
      {
        name: 'Micoud Quarter',
        value: 'Micoud Quarter',
      },
      {
        name: 'Praslin Quarter',
        value: 'Praslin Quarter',
      },
      {
        name: 'Soufrière Quarter',
        value: 'Soufrière Quarter',
      },
      {
        name: 'Vieux Fort Quarter',
        value: 'Vieux Fort Quarter',
      },
    ],
  },
  VC: {
    name: 'Saint Vincent and the Grenadines',
    type: null,
    states: [
      {
        name: 'Charlotte Parish',
        value: 'Charlotte Parish',
      },
      {
        name: 'Grenadines Parish',
        value: 'Grenadines Parish',
      },
      {
        name: 'Saint Andrew Parish',
        value: 'Saint Andrew Parish',
      },
      {
        name: 'Saint David Parish',
        value: 'Saint David Parish',
      },
      {
        name: 'Saint George Parish',
        value: 'Saint George Parish',
      },
      {
        name: 'Saint Patrick Parish',
        value: 'Saint Patrick Parish',
      },
    ],
  },
  WS: {
    name: 'Samoa',
    type: null,
    states: [
      {
        name: "A'ana",
        value: "A'ana",
      },
      {
        name: 'Aiga-i-le-Tai',
        value: 'Aiga-i-le-Tai',
      },
      {
        name: 'Atua',
        value: 'Atua',
      },
      {
        name: "Fa'asaleleaga",
        value: "Fa'asaleleaga",
      },
      {
        name: "Gaga'emauga",
        value: "Gaga'emauga",
      },
      {
        name: "Gaga'ifomauga",
        value: "Gaga'ifomauga",
      },
      {
        name: 'Palauli',
        value: 'Palauli',
      },
      {
        name: "Satupa'itea",
        value: "Satupa'itea",
      },
      {
        name: 'Tuamasaga',
        value: 'Tuamasaga',
      },
      {
        name: "Va'a-o-Fonoti",
        value: "Va'a-o-Fonoti",
      },
      {
        name: 'Vaisigano',
        value: 'Vaisigano',
      },
    ],
  },
  SM: {
    name: 'San Marino',
    type: null,
    states: [
      {
        name: 'Acquaviva',
        value: 'Acquaviva',
      },
      {
        name: 'Borgo Maggiore',
        value: 'Borgo Maggiore',
      },
      {
        name: 'Chiesanuova',
        value: 'Chiesanuova',
      },
      {
        name: 'Domagnano',
        value: 'Domagnano',
      },
      {
        name: 'Faetano',
        value: 'Faetano',
      },
      {
        name: 'Fiorentino',
        value: 'Fiorentino',
      },
      {
        name: 'Montegiardino',
        value: 'Montegiardino',
      },
      {
        name: 'San Marino',
        value: 'San Marino',
      },
      {
        name: 'Serravalle',
        value: 'Serravalle',
      },
    ],
  },
  ST: {
    name: 'Sao Tome and Principe',
    type: null,
    states: [
      {
        name: 'Príncipe Province',
        value: 'Príncipe Province',
      },
      {
        name: 'São Tomé Province',
        value: 'São Tomé Province',
      },
    ],
  },
  SA: {
    name: 'Saudi Arabia',
    type: 'region',
    states: [
      {
        name: "'Asir",
        value: "'Asir",
      },
      {
        name: 'Al Bahah',
        value: 'Al Bahah',
      },
      {
        name: 'Al Jawf',
        value: 'Al Jawf',
      },
      {
        name: 'Al Madinah',
        value: 'Al Madinah',
      },
      {
        name: 'Al-Qassim',
        value: 'Al-Qassim',
      },
      {
        name: 'Eastern Province',
        value: 'Eastern Province',
      },
      {
        name: "Ha'il",
        value: "Ha'il",
      },
      {
        name: 'Jizan',
        value: 'Jizan',
      },
      {
        name: 'Makkah',
        value: 'Makkah',
      },
      {
        name: 'Najran',
        value: 'Najran',
      },
      {
        name: 'Northern Borders',
        value: 'Northern Borders',
      },
      {
        name: 'Riyadh',
        value: 'Riyadh',
      },
      {
        name: 'Tabuk',
        value: 'Tabuk',
      },
    ],
  },
  SN: {
    name: 'Senegal',
    type: null,
    states: [
      {
        name: 'Dakar',
        value: 'Dakar',
      },
      {
        name: 'Diourbel Region',
        value: 'Diourbel Region',
      },
      {
        name: 'Fatick',
        value: 'Fatick',
      },
      {
        name: 'Kaffrine',
        value: 'Kaffrine',
      },
      {
        name: 'Kaolack',
        value: 'Kaolack',
      },
      {
        name: 'Kédougou',
        value: 'Kédougou',
      },
      {
        name: 'Kolda',
        value: 'Kolda',
      },
      {
        name: 'Louga',
        value: 'Louga',
      },
      {
        name: 'Matam',
        value: 'Matam',
      },
      {
        name: 'Saint-Louis',
        value: 'Saint-Louis',
      },
      {
        name: 'Sédhiou',
        value: 'Sédhiou',
      },
      {
        name: 'Tambacounda Region',
        value: 'Tambacounda Region',
      },
      {
        name: 'Thiès Region',
        value: 'Thiès Region',
      },
      {
        name: 'Ziguinchor',
        value: 'Ziguinchor',
      },
    ],
  },
  RS: {
    name: 'Serbia',
    type: null,
    states: [
      {
        name: 'Belgrade',
        value: 'Belgrade',
      },
      {
        name: 'Bor District',
        value: 'Bor District',
      },
      {
        name: 'Braničevo District',
        value: 'Braničevo District',
      },
      {
        name: 'Central Banat District',
        value: 'Central Banat District',
      },
      {
        name: 'Jablanica District',
        value: 'Jablanica District',
      },
      {
        name: 'Kolubara District',
        value: 'Kolubara District',
      },
      {
        name: 'Mačva District',
        value: 'Mačva District',
      },
      {
        name: 'Moravica District',
        value: 'Moravica District',
      },
      {
        name: 'Nišava District',
        value: 'Nišava District',
      },
      {
        name: 'North Bačka District',
        value: 'North Bačka District',
      },
      {
        name: 'North Banat District',
        value: 'North Banat District',
      },
      {
        name: 'Pčinja District',
        value: 'Pčinja District',
      },
      {
        name: 'Pirot District',
        value: 'Pirot District',
      },
      {
        name: 'Podunavlje District',
        value: 'Podunavlje District',
      },
      {
        name: 'Pomoravlje District',
        value: 'Pomoravlje District',
      },
      {
        name: 'Rasina District',
        value: 'Rasina District',
      },
      {
        name: 'Raška District',
        value: 'Raška District',
      },
      {
        name: 'South Bačka District',
        value: 'South Bačka District',
      },
      {
        name: 'South Banat District',
        value: 'South Banat District',
      },
      {
        name: 'Srem District',
        value: 'Srem District',
      },
      {
        name: 'Šumadija District',
        value: 'Šumadija District',
      },
      {
        name: 'Toplica District',
        value: 'Toplica District',
      },
      {
        name: 'Vojvodina',
        value: 'Vojvodina',
      },
      {
        name: 'West Bačka District',
        value: 'West Bačka District',
      },
      {
        name: 'Zaječar District',
        value: 'Zaječar District',
      },
      {
        name: 'Zlatibor District',
        value: 'Zlatibor District',
      },
    ],
  },
  SC: {
    name: 'Seychelles',
    type: null,
    states: [
      {
        name: 'Anse Boileau',
        value: 'Anse Boileau',
      },
      {
        name: 'Anse Royale',
        value: 'Anse Royale',
      },
      {
        name: 'Anse-aux-Pins',
        value: 'Anse-aux-Pins',
      },
      {
        name: 'Au Cap',
        value: 'Au Cap',
      },
      {
        name: 'Baie Lazare',
        value: 'Baie Lazare',
      },
      {
        name: 'Baie Sainte Anne',
        value: 'Baie Sainte Anne',
      },
      {
        name: 'Beau Vallon',
        value: 'Beau Vallon',
      },
      {
        name: 'Bel Air',
        value: 'Bel Air',
      },
      {
        name: 'Bel Ombre',
        value: 'Bel Ombre',
      },
      {
        name: 'Cascade',
        value: 'Cascade',
      },
      {
        name: 'Glacis',
        value: 'Glacis',
      },
      {
        name: "Grand'Anse Mahé",
        value: "Grand'Anse Mahé",
      },
      {
        name: "Grand'Anse Praslin",
        value: "Grand'Anse Praslin",
      },
      {
        name: 'La Digue',
        value: 'La Digue',
      },
      {
        name: 'La Rivière Anglaise',
        value: 'La Rivière Anglaise',
      },
      {
        name: 'Les Mamelles',
        value: 'Les Mamelles',
      },
      {
        name: 'Mont Buxton',
        value: 'Mont Buxton',
      },
      {
        name: 'Mont Fleuri',
        value: 'Mont Fleuri',
      },
      {
        name: 'Plaisance',
        value: 'Plaisance',
      },
      {
        name: 'Pointe La Rue',
        value: 'Pointe La Rue',
      },
      {
        name: 'Port Glaud',
        value: 'Port Glaud',
      },
      {
        name: 'Roche Caiman',
        value: 'Roche Caiman',
      },
      {
        name: 'Saint Louis',
        value: 'Saint Louis',
      },
      {
        name: 'Takamaka',
        value: 'Takamaka',
      },
    ],
  },
  SL: {
    name: 'Sierra Leone',
    type: null,
    states: [
      {
        name: 'Eastern Province',
        value: 'Eastern Province',
      },
      {
        name: 'Northern Province',
        value: 'Northern Province',
      },
      {
        name: 'Southern Province',
        value: 'Southern Province',
      },
      {
        name: 'Western Area',
        value: 'Western Area',
      },
    ],
  },
  SG: {
    name: 'Singapore',
    type: 'district',
    states: [
      {
        name: 'Central Singapore',
        value: 'Central Singapore',
      },
      {
        name: 'North East',
        value: 'North East',
      },
      {
        name: 'North West',
        value: 'North West',
      },
      {
        name: 'South East',
        value: 'South East',
      },
      {
        name: 'South West',
        value: 'South West',
      },
    ],
  },
  SK: {
    name: 'Slovakia',
    type: null,
    states: [
      {
        name: 'Banská Bystrica Region',
        value: 'Banská Bystrica Region',
      },
      {
        name: 'Bratislava Region',
        value: 'Bratislava Region',
      },
      {
        name: 'Košice Region',
        value: 'Košice Region',
      },
      {
        name: 'Nitra Region',
        value: 'Nitra Region',
      },
      {
        name: 'Prešov Region',
        value: 'Prešov Region',
      },
      {
        name: 'Trenčín Region',
        value: 'Trenčín Region',
      },
      {
        name: 'Trnava Region',
        value: 'Trnava Region',
      },
      {
        name: 'Žilina Region',
        value: 'Žilina Region',
      },
    ],
  },
  SI: {
    name: 'Slovenia',
    type: null,
    states: [
      {
        name: 'Ajdovščina Municipality',
        value: 'Ajdovščina Municipality',
      },
      {
        name: 'Ankaran Municipality',
        value: 'Ankaran Municipality',
      },
      {
        name: 'Beltinci Municipality',
        value: 'Beltinci Municipality',
      },
      {
        name: 'Benedikt Municipality',
        value: 'Benedikt Municipality',
      },
      {
        name: 'Bistrica ob Sotli Municipality',
        value: 'Bistrica ob Sotli Municipality',
      },
      {
        name: 'Bled Municipality',
        value: 'Bled Municipality',
      },
      {
        name: 'Bloke Municipality',
        value: 'Bloke Municipality',
      },
      {
        name: 'Bohinj Municipality',
        value: 'Bohinj Municipality',
      },
      {
        name: 'Borovnica Municipality',
        value: 'Borovnica Municipality',
      },
      {
        name: 'Bovec Municipality',
        value: 'Bovec Municipality',
      },
      {
        name: 'Braslovče Municipality',
        value: 'Braslovče Municipality',
      },
      {
        name: 'Brda Municipality',
        value: 'Brda Municipality',
      },
      {
        name: 'Brežice Municipality',
        value: 'Brežice Municipality',
      },
      {
        name: 'Brezovica Municipality',
        value: 'Brezovica Municipality',
      },
      {
        name: 'Cankova Municipality',
        value: 'Cankova Municipality',
      },
      {
        name: 'Cerklje na Gorenjskem Municipality',
        value: 'Cerklje na Gorenjskem Municipality',
      },
      {
        name: 'Cerknica Municipality',
        value: 'Cerknica Municipality',
      },
      {
        name: 'Cerkno Municipality',
        value: 'Cerkno Municipality',
      },
      {
        name: 'Cerkvenjak Municipality',
        value: 'Cerkvenjak Municipality',
      },
      {
        name: 'City Municipality of Celje',
        value: 'City Municipality of Celje',
      },
      {
        name: 'City Municipality of Novo Mesto',
        value: 'City Municipality of Novo Mesto',
      },
      {
        name: 'Črenšovci Municipality',
        value: 'Črenšovci Municipality',
      },
      {
        name: 'Črna na Koroškem Municipality',
        value: 'Črna na Koroškem Municipality',
      },
      {
        name: 'Črnomelj Municipality',
        value: 'Črnomelj Municipality',
      },
      {
        name: 'Destrnik Municipality',
        value: 'Destrnik Municipality',
      },
      {
        name: 'Divača Municipality',
        value: 'Divača Municipality',
      },
      {
        name: 'Dobje Municipality',
        value: 'Dobje Municipality',
      },
      {
        name: 'Dobrepolje Municipality',
        value: 'Dobrepolje Municipality',
      },
      {
        name: 'Dobrna Municipality',
        value: 'Dobrna Municipality',
      },
      {
        name: 'Dobrova–Polhov Gradec Municipality',
        value: 'Dobrova–Polhov Gradec Municipality',
      },
      {
        name: 'Dobrovnik Municipality',
        value: 'Dobrovnik Municipality',
      },
      {
        name: 'Dol pri Ljubljani Municipality',
        value: 'Dol pri Ljubljani Municipality',
      },
      {
        name: 'Dolenjske Toplice Municipality',
        value: 'Dolenjske Toplice Municipality',
      },
      {
        name: 'Domžale Municipality',
        value: 'Domžale Municipality',
      },
      {
        name: 'Dornava Municipality',
        value: 'Dornava Municipality',
      },
      {
        name: 'Dravograd Municipality',
        value: 'Dravograd Municipality',
      },
      {
        name: 'Duplek Municipality',
        value: 'Duplek Municipality',
      },
      {
        name: 'Gorenja Vas–Poljane Municipality',
        value: 'Gorenja Vas–Poljane Municipality',
      },
      {
        name: 'Gorišnica Municipality',
        value: 'Gorišnica Municipality',
      },
      {
        name: 'Gorje Municipality',
        value: 'Gorje Municipality',
      },
      {
        name: 'Gornja Radgona Municipality',
        value: 'Gornja Radgona Municipality',
      },
      {
        name: 'Gornji Grad Municipality',
        value: 'Gornji Grad Municipality',
      },
      {
        name: 'Gornji Petrovci Municipality',
        value: 'Gornji Petrovci Municipality',
      },
      {
        name: 'Grad Municipality',
        value: 'Grad Municipality',
      },
      {
        name: 'Grosuplje Municipality',
        value: 'Grosuplje Municipality',
      },
      {
        name: 'Hajdina Municipality',
        value: 'Hajdina Municipality',
      },
      {
        name: 'Hoče–Slivnica Municipality',
        value: 'Hoče–Slivnica Municipality',
      },
      {
        name: 'Hodoš Municipality',
        value: 'Hodoš Municipality',
      },
      {
        name: 'Horjul Municipality',
        value: 'Horjul Municipality',
      },
      {
        name: 'Hrastnik Municipality',
        value: 'Hrastnik Municipality',
      },
      {
        name: 'Hrpelje–Kozina Municipality',
        value: 'Hrpelje–Kozina Municipality',
      },
      {
        name: 'Idrija Municipality',
        value: 'Idrija Municipality',
      },
      {
        name: 'Ig Municipality',
        value: 'Ig Municipality',
      },
      {
        name: 'Ivančna Gorica Municipality',
        value: 'Ivančna Gorica Municipality',
      },
      {
        name: 'Izola Municipality',
        value: 'Izola Municipality',
      },
      {
        name: 'Jesenice Municipality',
        value: 'Jesenice Municipality',
      },
      {
        name: 'Jezersko Municipality',
        value: 'Jezersko Municipality',
      },
      {
        name: 'Juršinci Municipality',
        value: 'Juršinci Municipality',
      },
      {
        name: 'Kamnik Municipality',
        value: 'Kamnik Municipality',
      },
      {
        name: 'Kanal ob Soči Municipality',
        value: 'Kanal ob Soči Municipality',
      },
      {
        name: 'Kidričevo Municipality',
        value: 'Kidričevo Municipality',
      },
      {
        name: 'Kobarid Municipality',
        value: 'Kobarid Municipality',
      },
      {
        name: 'Kobilje Municipality',
        value: 'Kobilje Municipality',
      },
      {
        name: 'Kočevje Municipality',
        value: 'Kočevje Municipality',
      },
      {
        name: 'Komen Municipality',
        value: 'Komen Municipality',
      },
      {
        name: 'Komenda Municipality',
        value: 'Komenda Municipality',
      },
      {
        name: 'Koper City Municipality',
        value: 'Koper City Municipality',
      },
      {
        name: 'Kostanjevica na Krki Municipality',
        value: 'Kostanjevica na Krki Municipality',
      },
      {
        name: 'Kostel Municipality',
        value: 'Kostel Municipality',
      },
      {
        name: 'Kozje Municipality',
        value: 'Kozje Municipality',
      },
      {
        name: 'Kranj City Municipality',
        value: 'Kranj City Municipality',
      },
      {
        name: 'Kranjska Gora Municipality',
        value: 'Kranjska Gora Municipality',
      },
      {
        name: 'Križevci Municipality',
        value: 'Križevci Municipality',
      },
      {
        name: 'Kungota',
        value: 'Kungota',
      },
      {
        name: 'Kuzma Municipality',
        value: 'Kuzma Municipality',
      },
      {
        name: 'Laško Municipality',
        value: 'Laško Municipality',
      },
      {
        name: 'Lenart Municipality',
        value: 'Lenart Municipality',
      },
      {
        name: 'Lendava Municipality',
        value: 'Lendava Municipality',
      },
      {
        name: 'Litija Municipality',
        value: 'Litija Municipality',
      },
      {
        name: 'Ljubljana City Municipality',
        value: 'Ljubljana City Municipality',
      },
      {
        name: 'Ljubno Municipality',
        value: 'Ljubno Municipality',
      },
      {
        name: 'Ljutomer Municipality',
        value: 'Ljutomer Municipality',
      },
      {
        name: 'Log–Dragomer Municipality',
        value: 'Log–Dragomer Municipality',
      },
      {
        name: 'Logatec Municipality',
        value: 'Logatec Municipality',
      },
      {
        name: 'Loška Dolina Municipality',
        value: 'Loška Dolina Municipality',
      },
      {
        name: 'Loški Potok Municipality',
        value: 'Loški Potok Municipality',
      },
      {
        name: 'Lovrenc na Pohorju Municipality',
        value: 'Lovrenc na Pohorju Municipality',
      },
      {
        name: 'Luče Municipality',
        value: 'Luče Municipality',
      },
      {
        name: 'Lukovica Municipality',
        value: 'Lukovica Municipality',
      },
      {
        name: 'Majšperk Municipality',
        value: 'Majšperk Municipality',
      },
      {
        name: 'Makole Municipality',
        value: 'Makole Municipality',
      },
      {
        name: 'Maribor City Municipality',
        value: 'Maribor City Municipality',
      },
      {
        name: 'Markovci Municipality',
        value: 'Markovci Municipality',
      },
      {
        name: 'Medvode Municipality',
        value: 'Medvode Municipality',
      },
      {
        name: 'Mengeš Municipality',
        value: 'Mengeš Municipality',
      },
      {
        name: 'Metlika Municipality',
        value: 'Metlika Municipality',
      },
      {
        name: 'Mežica Municipality',
        value: 'Mežica Municipality',
      },
      {
        name: 'Miklavž na Dravskem Polju Municipality',
        value: 'Miklavž na Dravskem Polju Municipality',
      },
      {
        name: 'Miren–Kostanjevica Municipality',
        value: 'Miren–Kostanjevica Municipality',
      },
      {
        name: 'Mirna Municipality',
        value: 'Mirna Municipality',
      },
      {
        name: 'Mirna Peč Municipality',
        value: 'Mirna Peč Municipality',
      },
      {
        name: 'Mislinja Municipality',
        value: 'Mislinja Municipality',
      },
      {
        name: 'Mokronog–Trebelno Municipality',
        value: 'Mokronog–Trebelno Municipality',
      },
      {
        name: 'Moravče Municipality',
        value: 'Moravče Municipality',
      },
      {
        name: 'Moravske Toplice Municipality',
        value: 'Moravske Toplice Municipality',
      },
      {
        name: 'Mozirje Municipality',
        value: 'Mozirje Municipality',
      },
      {
        name: 'Municipality of Apače',
        value: 'Municipality of Apače',
      },
      {
        name: 'Municipality of Cirkulane',
        value: 'Municipality of Cirkulane',
      },
      {
        name: 'Municipality of Ilirska Bistrica',
        value: 'Municipality of Ilirska Bistrica',
      },
      {
        name: 'Municipality of Krško',
        value: 'Municipality of Krško',
      },
      {
        name: 'Municipality of Škofljica',
        value: 'Municipality of Škofljica',
      },
      {
        name: 'Murska Sobota City Municipality',
        value: 'Murska Sobota City Municipality',
      },
      {
        name: 'Muta Municipality',
        value: 'Muta Municipality',
      },
      {
        name: 'Naklo Municipality',
        value: 'Naklo Municipality',
      },
      {
        name: 'Nazarje Municipality',
        value: 'Nazarje Municipality',
      },
      {
        name: 'Nova Gorica City Municipality',
        value: 'Nova Gorica City Municipality',
      },
      {
        name: 'Odranci Municipality',
        value: 'Odranci Municipality',
      },
      {
        name: 'Oplotnica',
        value: 'Oplotnica',
      },
      {
        name: 'Ormož Municipality',
        value: 'Ormož Municipality',
      },
      {
        name: 'Osilnica Municipality',
        value: 'Osilnica Municipality',
      },
      {
        name: 'Pesnica Municipality',
        value: 'Pesnica Municipality',
      },
      {
        name: 'Piran Municipality',
        value: 'Piran Municipality',
      },
      {
        name: 'Pivka Municipality',
        value: 'Pivka Municipality',
      },
      {
        name: 'Podčetrtek Municipality',
        value: 'Podčetrtek Municipality',
      },
      {
        name: 'Podlehnik Municipality',
        value: 'Podlehnik Municipality',
      },
      {
        name: 'Podvelka Municipality',
        value: 'Podvelka Municipality',
      },
      {
        name: 'Poljčane Municipality',
        value: 'Poljčane Municipality',
      },
      {
        name: 'Polzela Municipality',
        value: 'Polzela Municipality',
      },
      {
        name: 'Postojna Municipality',
        value: 'Postojna Municipality',
      },
      {
        name: 'Prebold Municipality',
        value: 'Prebold Municipality',
      },
      {
        name: 'Preddvor Municipality',
        value: 'Preddvor Municipality',
      },
      {
        name: 'Prevalje Municipality',
        value: 'Prevalje Municipality',
      },
      {
        name: 'Ptuj City Municipality',
        value: 'Ptuj City Municipality',
      },
      {
        name: 'Puconci Municipality',
        value: 'Puconci Municipality',
      },
      {
        name: 'Rače–Fram Municipality',
        value: 'Rače–Fram Municipality',
      },
      {
        name: 'Radeče Municipality',
        value: 'Radeče Municipality',
      },
      {
        name: 'Radenci Municipality',
        value: 'Radenci Municipality',
      },
      {
        name: 'Radlje ob Dravi Municipality',
        value: 'Radlje ob Dravi Municipality',
      },
      {
        name: 'Radovljica Municipality',
        value: 'Radovljica Municipality',
      },
      {
        name: 'Ravne na Koroškem Municipality',
        value: 'Ravne na Koroškem Municipality',
      },
      {
        name: 'Razkrižje Municipality',
        value: 'Razkrižje Municipality',
      },
      {
        name: 'Rečica ob Savinji Municipality',
        value: 'Rečica ob Savinji Municipality',
      },
      {
        name: 'Renče–Vogrsko Municipality',
        value: 'Renče–Vogrsko Municipality',
      },
      {
        name: 'Ribnica Municipality',
        value: 'Ribnica Municipality',
      },
      {
        name: 'Ribnica na Pohorju Municipality',
        value: 'Ribnica na Pohorju Municipality',
      },
      {
        name: 'Rogaška Slatina Municipality',
        value: 'Rogaška Slatina Municipality',
      },
      {
        name: 'Rogašovci Municipality',
        value: 'Rogašovci Municipality',
      },
      {
        name: 'Rogatec Municipality',
        value: 'Rogatec Municipality',
      },
      {
        name: 'Ruše Municipality',
        value: 'Ruše Municipality',
      },
      {
        name: 'Šalovci Municipality',
        value: 'Šalovci Municipality',
      },
      {
        name: 'Selnica ob Dravi Municipality',
        value: 'Selnica ob Dravi Municipality',
      },
      {
        name: 'Semič Municipality',
        value: 'Semič Municipality',
      },
      {
        name: 'Šempeter–Vrtojba Municipality',
        value: 'Šempeter–Vrtojba Municipality',
      },
      {
        name: 'Šenčur Municipality',
        value: 'Šenčur Municipality',
      },
      {
        name: 'Šentilj Municipality',
        value: 'Šentilj Municipality',
      },
      {
        name: 'Šentjernej Municipality',
        value: 'Šentjernej Municipality',
      },
      {
        name: 'Šentjur Municipality',
        value: 'Šentjur Municipality',
      },
      {
        name: 'Šentrupert Municipality',
        value: 'Šentrupert Municipality',
      },
      {
        name: 'Sevnica Municipality',
        value: 'Sevnica Municipality',
      },
      {
        name: 'Sežana Municipality',
        value: 'Sežana Municipality',
      },
      {
        name: 'Škocjan Municipality',
        value: 'Škocjan Municipality',
      },
      {
        name: 'Škofja Loka Municipality',
        value: 'Škofja Loka Municipality',
      },
      {
        name: 'Slovenj Gradec City Municipality',
        value: 'Slovenj Gradec City Municipality',
      },
      {
        name: 'Slovenska Bistrica Municipality',
        value: 'Slovenska Bistrica Municipality',
      },
      {
        name: 'Slovenske Konjice Municipality',
        value: 'Slovenske Konjice Municipality',
      },
      {
        name: 'Šmarje pri Jelšah Municipality',
        value: 'Šmarje pri Jelšah Municipality',
      },
      {
        name: 'Šmarješke Toplice Municipality',
        value: 'Šmarješke Toplice Municipality',
      },
      {
        name: 'Šmartno ob Paki Municipality',
        value: 'Šmartno ob Paki Municipality',
      },
      {
        name: 'Šmartno pri Litiji Municipality',
        value: 'Šmartno pri Litiji Municipality',
      },
      {
        name: 'Sodražica Municipality',
        value: 'Sodražica Municipality',
      },
      {
        name: 'Solčava Municipality',
        value: 'Solčava Municipality',
      },
      {
        name: 'Šoštanj Municipality',
        value: 'Šoštanj Municipality',
      },
      {
        name: 'Središče ob Dravi',
        value: 'Središče ob Dravi',
      },
      {
        name: 'Starše Municipality',
        value: 'Starše Municipality',
      },
      {
        name: 'Štore Municipality',
        value: 'Štore Municipality',
      },
      {
        name: 'Straža Municipality',
        value: 'Straža Municipality',
      },
      {
        name: 'Sveta Ana Municipality',
        value: 'Sveta Ana Municipality',
      },
      {
        name: 'Sveta Trojica v Slovenskih Goricah Municipality',
        value: 'Sveta Trojica v Slovenskih Goricah Municipality',
      },
      {
        name: 'Sveti Andraž v Slovenskih Goricah Municipality',
        value: 'Sveti Andraž v Slovenskih Goricah Municipality',
      },
      {
        name: 'Sveti Jurij ob Ščavnici Municipality',
        value: 'Sveti Jurij ob Ščavnici Municipality',
      },
      {
        name: 'Sveti Jurij v Slovenskih Goricah Municipality',
        value: 'Sveti Jurij v Slovenskih Goricah Municipality',
      },
      {
        name: 'Sveti Tomaž Municipality',
        value: 'Sveti Tomaž Municipality',
      },
      {
        name: 'Tabor Municipality',
        value: 'Tabor Municipality',
      },
      {
        name: 'Tišina Municipality',
        value: 'Tišina Municipality',
      },
      {
        name: 'Tolmin Municipality',
        value: 'Tolmin Municipality',
      },
      {
        name: 'Trbovlje Municipality',
        value: 'Trbovlje Municipality',
      },
      {
        name: 'Trebnje Municipality',
        value: 'Trebnje Municipality',
      },
      {
        name: 'Trnovska Vas Municipality',
        value: 'Trnovska Vas Municipality',
      },
      {
        name: 'Tržič Municipality',
        value: 'Tržič Municipality',
      },
      {
        name: 'Trzin Municipality',
        value: 'Trzin Municipality',
      },
      {
        name: 'Turnišče Municipality',
        value: 'Turnišče Municipality',
      },
      {
        name: 'Velika Polana Municipality',
        value: 'Velika Polana Municipality',
      },
      {
        name: 'Velike Lašče Municipality',
        value: 'Velike Lašče Municipality',
      },
      {
        name: 'Veržej Municipality',
        value: 'Veržej Municipality',
      },
      {
        name: 'Videm Municipality',
        value: 'Videm Municipality',
      },
      {
        name: 'Vipava Municipality',
        value: 'Vipava Municipality',
      },
      {
        name: 'Vitanje Municipality',
        value: 'Vitanje Municipality',
      },
      {
        name: 'Vodice Municipality',
        value: 'Vodice Municipality',
      },
      {
        name: 'Vojnik Municipality',
        value: 'Vojnik Municipality',
      },
      {
        name: 'Vransko Municipality',
        value: 'Vransko Municipality',
      },
      {
        name: 'Vrhnika Municipality',
        value: 'Vrhnika Municipality',
      },
      {
        name: 'Vuzenica Municipality',
        value: 'Vuzenica Municipality',
      },
      {
        name: 'Zagorje ob Savi Municipality',
        value: 'Zagorje ob Savi Municipality',
      },
      {
        name: 'Žalec Municipality',
        value: 'Žalec Municipality',
      },
      {
        name: 'Zavrč Municipality',
        value: 'Zavrč Municipality',
      },
      {
        name: 'Železniki Municipality',
        value: 'Železniki Municipality',
      },
      {
        name: 'Žetale Municipality',
        value: 'Žetale Municipality',
      },
      {
        name: 'Žiri Municipality',
        value: 'Žiri Municipality',
      },
      {
        name: 'Žirovnica Municipality',
        value: 'Žirovnica Municipality',
      },
      {
        name: 'Zreče Municipality',
        value: 'Zreče Municipality',
      },
      {
        name: 'Žužemberk Municipality',
        value: 'Žužemberk Municipality',
      },
    ],
  },
  SB: {
    name: 'Solomon Islands',
    type: null,
    states: [
      {
        name: 'Central Province',
        value: 'Central Province',
      },
      {
        name: 'Choiseul Province',
        value: 'Choiseul Province',
      },
      {
        name: 'Guadalcanal Province',
        value: 'Guadalcanal Province',
      },
      {
        name: 'Honiara',
        value: 'Honiara',
      },
      {
        name: 'Isabel Province',
        value: 'Isabel Province',
      },
      {
        name: 'Makira-Ulawa Province',
        value: 'Makira-Ulawa Province',
      },
      {
        name: 'Malaita Province',
        value: 'Malaita Province',
      },
      {
        name: 'Rennell and Bellona Province',
        value: 'Rennell and Bellona Province',
      },
      {
        name: 'Temotu Province',
        value: 'Temotu Province',
      },
      {
        name: 'Western Province',
        value: 'Western Province',
      },
    ],
  },
  SO: {
    name: 'Somalia',
    type: null,
    states: [
      {
        name: 'Awdal Region',
        value: 'Awdal Region',
      },
      {
        name: 'Bakool',
        value: 'Bakool',
      },
      {
        name: 'Banaadir',
        value: 'Banaadir',
      },
      {
        name: 'Bari',
        value: 'Bari',
      },
      {
        name: 'Bay',
        value: 'Bay',
      },
      {
        name: 'Galguduud',
        value: 'Galguduud',
      },
      {
        name: 'Gedo',
        value: 'Gedo',
      },
      {
        name: 'Hiran',
        value: 'Hiran',
      },
      {
        name: 'Lower Juba',
        value: 'Lower Juba',
      },
      {
        name: 'Lower Shebelle',
        value: 'Lower Shebelle',
      },
      {
        name: 'Middle Juba',
        value: 'Middle Juba',
      },
      {
        name: 'Middle Shebelle',
        value: 'Middle Shebelle',
      },
      {
        name: 'Mudug',
        value: 'Mudug',
      },
      {
        name: 'Nugal',
        value: 'Nugal',
      },
      {
        name: 'Sanaag Region',
        value: 'Sanaag Region',
      },
      {
        name: 'Togdheer Region',
        value: 'Togdheer Region',
      },
    ],
  },
  ZA: {
    name: 'South Africa',
    type: null,
    states: [
      {
        name: 'Eastern Cape',
        value: 'Eastern Cape',
      },
      {
        name: 'Free State',
        value: 'Free State',
      },
      {
        name: 'Gauteng',
        value: 'Gauteng',
      },
      {
        name: 'KwaZulu-Natal',
        value: 'KwaZulu-Natal',
      },
      {
        name: 'Limpopo',
        value: 'Limpopo',
      },
      {
        name: 'Mpumalanga',
        value: 'Mpumalanga',
      },
      {
        name: 'North West',
        value: 'North West',
      },
      {
        name: 'Northern Cape',
        value: 'Northern Cape',
      },
      {
        name: 'Western Cape',
        value: 'Western Cape',
      },
    ],
  },
  KR: {
    name: 'South Korea',
    type: null,
    states: [
      {
        name: 'Busan',
        value: 'Busan',
      },
      {
        name: 'Daegu',
        value: 'Daegu',
      },
      {
        name: 'Daejeon',
        value: 'Daejeon',
      },
      {
        name: 'Gangwon Province',
        value: 'Gangwon Province',
      },
      {
        name: 'Gwangju',
        value: 'Gwangju',
      },
      {
        name: 'Gyeonggi Province',
        value: 'Gyeonggi Province',
      },
      {
        name: 'Incheon',
        value: 'Incheon',
      },
      {
        name: 'Jeju',
        value: 'Jeju',
      },
      {
        name: 'North Chungcheong Province',
        value: 'North Chungcheong Province',
      },
      {
        name: 'North Gyeongsang Province',
        value: 'North Gyeongsang Province',
      },
      {
        name: 'North Jeolla Province',
        value: 'North Jeolla Province',
      },
      {
        name: 'Sejong City',
        value: 'Sejong City',
      },
      {
        name: 'Seoul',
        value: 'Seoul',
      },
      {
        name: 'South Chungcheong Province',
        value: 'South Chungcheong Province',
      },
      {
        name: 'South Gyeongsang Province',
        value: 'South Gyeongsang Province',
      },
      {
        name: 'South Jeolla Province',
        value: 'South Jeolla Province',
      },
      {
        name: 'Ulsan',
        value: 'Ulsan',
      },
    ],
  },
  SS: {
    name: 'South Sudan',
    type: null,
    states: [
      {
        name: 'Central Equatoria',
        value: 'Central Equatoria',
      },
      {
        name: 'Eastern Equatoria',
        value: 'Eastern Equatoria',
      },
      {
        name: 'Jonglei State',
        value: 'Jonglei State',
      },
      {
        name: 'Lakes',
        value: 'Lakes',
      },
      {
        name: 'Northern Bahr el Ghazal',
        value: 'Northern Bahr el Ghazal',
      },
      {
        name: 'Unity',
        value: 'Unity',
      },
      {
        name: 'Upper Nile',
        value: 'Upper Nile',
      },
      {
        name: 'Warrap',
        value: 'Warrap',
      },
      {
        name: 'Western Bahr el Ghazal',
        value: 'Western Bahr el Ghazal',
      },
      {
        name: 'Western Equatoria',
        value: 'Western Equatoria',
      },
    ],
  },
  ES: {
    name: 'Spain',
    type: 'province',
    states: [
      {
        name: 'A Coruña',
        value: 'A Coruña',
      },
      {
        name: 'Albacete',
        value: 'Albacete',
      },
      {
        name: 'Alicante',
        value: 'Alicante',
      },
      {
        name: 'Almeria',
        value: 'Almeria',
      },
      {
        name: 'Araba',
        value: 'Araba',
      },
      {
        name: 'Asturias',
        value: 'Asturias',
      },
      {
        name: 'Ávila',
        value: 'Ávila',
      },
      {
        name: 'Badajoz',
        value: 'Badajoz',
      },
      {
        name: 'Barcelona',
        value: 'Barcelona',
      },
      {
        name: 'Bizkaia',
        value: 'Bizkaia',
      },
      {
        name: 'Burgos',
        value: 'Burgos',
      },
      {
        name: 'Caceres',
        value: 'Caceres',
      },
      {
        name: 'Cádiz',
        value: 'Cádiz',
      },
      {
        name: 'Canarias',
        value: 'Canarias',
      },
      {
        name: 'Cantabria',
        value: 'Cantabria',
      },
      {
        name: 'Castellón',
        value: 'Castellón',
      },
      {
        name: 'Ceuta',
        value: 'Ceuta',
      },
      {
        name: 'Ciudad Real',
        value: 'Ciudad Real',
      },
      {
        name: 'Córdoba',
        value: 'Córdoba',
      },
      {
        name: 'Cuenca',
        value: 'Cuenca',
      },
      {
        name: 'Gipuzkoa',
        value: 'Gipuzkoa',
      },
      {
        name: 'Girona',
        value: 'Girona',
      },
      {
        name: 'Granada',
        value: 'Granada',
      },
      {
        name: 'Guadalajara',
        value: 'Guadalajara',
      },
      {
        name: 'Huelva',
        value: 'Huelva',
      },
      {
        name: 'Huesca',
        value: 'Huesca',
      },
      {
        name: 'Islas Baleares',
        value: 'Islas Baleares',
      },
      {
        name: 'Jaén',
        value: 'Jaén',
      },
      {
        name: 'La Rioja',
        value: 'La Rioja',
      },
      {
        name: 'Las Palmas',
        value: 'Las Palmas',
      },
      {
        name: 'León',
        value: 'León',
      },
      {
        name: 'Lleida',
        value: 'Lleida',
      },
      {
        name: 'Lugo',
        value: 'Lugo',
      },
      {
        name: 'Madrid',
        value: 'Madrid',
      },
      {
        name: 'Málaga',
        value: 'Málaga',
      },
      {
        name: 'Melilla',
        value: 'Melilla',
      },
      {
        name: 'Murcia',
        value: 'Murcia',
      },
      {
        name: 'Navarra',
        value: 'Navarra',
      },
      {
        name: 'Ourense',
        value: 'Ourense',
      },
      {
        name: 'Palencia',
        value: 'Palencia',
      },
      {
        name: 'Pontevedra',
        value: 'Pontevedra',
      },
      {
        name: 'Salamanca',
        value: 'Salamanca',
      },
      {
        name: 'Santa Cruz de Tenerife',
        value: 'Santa Cruz de Tenerife',
      },
      {
        name: 'Segovia',
        value: 'Segovia',
      },
      {
        name: 'Sevilla',
        value: 'Sevilla',
      },
      {
        name: 'Soria',
        value: 'Soria',
      },
      {
        name: 'Tarragona',
        value: 'Tarragona',
      },
      {
        name: 'Teruel',
        value: 'Teruel',
      },
      {
        name: 'Toledo',
        value: 'Toledo',
      },
      {
        name: 'Valencia',
        value: 'Valencia',
      },
      {
        name: 'Valladolid',
        value: 'Valladolid',
      },
      {
        name: 'Zamora',
        value: 'Zamora',
      },
      {
        name: 'Zaragoza',
        value: 'Zaragoza',
      },
    ],
  },
  LK: {
    name: 'Sri Lanka',
    type: null,
    states: [
      {
        name: 'Ampara District',
        value: 'Ampara District',
      },
      {
        name: 'Anuradhapura District',
        value: 'Anuradhapura District',
      },
      {
        name: 'Badulla District',
        value: 'Badulla District',
      },
      {
        name: 'Batticaloa District',
        value: 'Batticaloa District',
      },
      {
        name: 'Central Province',
        value: 'Central Province',
      },
      {
        name: 'Colombo District',
        value: 'Colombo District',
      },
      {
        name: 'Eastern Province',
        value: 'Eastern Province',
      },
      {
        name: 'Galle District',
        value: 'Galle District',
      },
      {
        name: 'Gampaha District',
        value: 'Gampaha District',
      },
      {
        name: 'Hambantota District',
        value: 'Hambantota District',
      },
      {
        name: 'Jaffna District',
        value: 'Jaffna District',
      },
      {
        name: 'Kalutara District',
        value: 'Kalutara District',
      },
      {
        name: 'Kandy District',
        value: 'Kandy District',
      },
      {
        name: 'Kegalle District',
        value: 'Kegalle District',
      },
      {
        name: 'Kilinochchi District',
        value: 'Kilinochchi District',
      },
      {
        name: 'Mannar District',
        value: 'Mannar District',
      },
      {
        name: 'Matale District',
        value: 'Matale District',
      },
      {
        name: 'Matara District',
        value: 'Matara District',
      },
      {
        name: 'Monaragala District',
        value: 'Monaragala District',
      },
      {
        name: 'Mullaitivu District',
        value: 'Mullaitivu District',
      },
      {
        name: 'North Central Province',
        value: 'North Central Province',
      },
      {
        name: 'North Western Province',
        value: 'North Western Province',
      },
      {
        name: 'Northern Province',
        value: 'Northern Province',
      },
      {
        name: 'Nuwara Eliya District',
        value: 'Nuwara Eliya District',
      },
      {
        name: 'Polonnaruwa District',
        value: 'Polonnaruwa District',
      },
      {
        name: 'Puttalam District',
        value: 'Puttalam District',
      },
      {
        name: 'Ratnapura district',
        value: 'Ratnapura district',
      },
      {
        name: 'Sabaragamuwa Province',
        value: 'Sabaragamuwa Province',
      },
      {
        name: 'Southern Province',
        value: 'Southern Province',
      },
      {
        name: 'Trincomalee District',
        value: 'Trincomalee District',
      },
      {
        name: 'Uva Province',
        value: 'Uva Province',
      },
      {
        name: 'Vavuniya District',
        value: 'Vavuniya District',
      },
      {
        name: 'Western Province',
        value: 'Western Province',
      },
    ],
  },
  SD: {
    name: 'Sudan',
    type: null,
    states: [
      {
        name: 'Al Jazirah',
        value: 'Al Jazirah',
      },
      {
        name: 'Al Qadarif',
        value: 'Al Qadarif',
      },
      {
        name: 'Blue Nile',
        value: 'Blue Nile',
      },
      {
        name: 'Central Darfur',
        value: 'Central Darfur',
      },
      {
        name: 'East Darfur',
        value: 'East Darfur',
      },
      {
        name: 'Kassala',
        value: 'Kassala',
      },
      {
        name: 'Khartoum',
        value: 'Khartoum',
      },
      {
        name: 'North Darfur',
        value: 'North Darfur',
      },
      {
        name: 'North Kordofan',
        value: 'North Kordofan',
      },
      {
        name: 'Northern',
        value: 'Northern',
      },
      {
        name: 'Red Sea',
        value: 'Red Sea',
      },
      {
        name: 'River Nile',
        value: 'River Nile',
      },
      {
        name: 'Sennar',
        value: 'Sennar',
      },
      {
        name: 'South Darfur',
        value: 'South Darfur',
      },
      {
        name: 'South Kordofan',
        value: 'South Kordofan',
      },
      {
        name: 'West Darfur',
        value: 'West Darfur',
      },
      {
        name: 'West Kordofan',
        value: 'West Kordofan',
      },
      {
        name: 'White Nile',
        value: 'White Nile',
      },
    ],
  },
  SR: {
    name: 'Suriname',
    type: null,
    states: [
      {
        name: 'Brokopondo District',
        value: 'Brokopondo District',
      },
      {
        name: 'Commewijne District',
        value: 'Commewijne District',
      },
      {
        name: 'Coronie District',
        value: 'Coronie District',
      },
      {
        name: 'Marowijne District',
        value: 'Marowijne District',
      },
      {
        name: 'Nickerie District',
        value: 'Nickerie District',
      },
      {
        name: 'Para District',
        value: 'Para District',
      },
      {
        name: 'Paramaribo District',
        value: 'Paramaribo District',
      },
      {
        name: 'Saramacca District',
        value: 'Saramacca District',
      },
      {
        name: 'Sipaliwini District',
        value: 'Sipaliwini District',
      },
      {
        name: 'Wanica District',
        value: 'Wanica District',
      },
    ],
  },
  SE: {
    name: 'Sweden',
    type: null,
    states: [
      {
        name: 'Blekinge County',
        value: 'Blekinge County',
      },
      {
        name: 'Dalarna County',
        value: 'Dalarna County',
      },
      {
        name: 'Gävleborg County',
        value: 'Gävleborg County',
      },
      {
        name: 'Gotland County',
        value: 'Gotland County',
      },
      {
        name: 'Halland County',
        value: 'Halland County',
      },
      {
        name: 'Jämtland County',
        value: 'Jämtland County',
      },
      {
        name: 'Jönköping County',
        value: 'Jönköping County',
      },
      {
        name: 'Kalmar County',
        value: 'Kalmar County',
      },
      {
        name: 'Kronoberg County',
        value: 'Kronoberg County',
      },
      {
        name: 'Norrbotten County',
        value: 'Norrbotten County',
      },
      {
        name: 'Örebro County',
        value: 'Örebro County',
      },
      {
        name: 'Östergötland County',
        value: 'Östergötland County',
      },
      {
        name: 'Skåne County',
        value: 'Skåne County',
      },
      {
        name: 'Södermanland County',
        value: 'Södermanland County',
      },
      {
        name: 'Stockholm County',
        value: 'Stockholm County',
      },
      {
        name: 'Uppsala County',
        value: 'Uppsala County',
      },
      {
        name: 'Värmland County',
        value: 'Värmland County',
      },
      {
        name: 'Västerbotten County',
        value: 'Västerbotten County',
      },
      {
        name: 'Västernorrland County',
        value: 'Västernorrland County',
      },
      {
        name: 'Västmanland County',
        value: 'Västmanland County',
      },
      {
        name: 'Västra Götaland County',
        value: 'Västra Götaland County',
      },
    ],
  },
  CH: {
    name: 'Switzerland',
    type: 'canton',
    states: [
      {
        name: 'Aargau',
        value: 'Aargau',
      },
      {
        name: 'Appenzell Ausserrhoden',
        value: 'Appenzell Ausserrhoden',
      },
      {
        name: 'Appenzell Innerrhoden',
        value: 'Appenzell Innerrhoden',
      },
      {
        name: 'Basel-Land',
        value: 'Basel-Land',
      },
      {
        name: 'Basel-Stadt',
        value: 'Basel-Stadt',
      },
      {
        name: 'Bern',
        value: 'Bern',
      },
      {
        name: 'Fribourg',
        value: 'Fribourg',
      },
      {
        name: 'Geneva',
        value: 'Geneva',
      },
      {
        name: 'Glarus',
        value: 'Glarus',
      },
      {
        name: 'Graubünden',
        value: 'Graubünden',
      },
      {
        name: 'Jura',
        value: 'Jura',
      },
      {
        name: 'Lucerne',
        value: 'Lucerne',
      },
      {
        name: 'Neuchâtel',
        value: 'Neuchâtel',
      },
      {
        name: 'Nidwalden',
        value: 'Nidwalden',
      },
      {
        name: 'Obwalden',
        value: 'Obwalden',
      },
      {
        name: 'Schaffhausen',
        value: 'Schaffhausen',
      },
      {
        name: 'Schwyz',
        value: 'Schwyz',
      },
      {
        name: 'Solothurn',
        value: 'Solothurn',
      },
      {
        name: 'St. Gallen',
        value: 'St. Gallen',
      },
      {
        name: 'Thurgau',
        value: 'Thurgau',
      },
      {
        name: 'Ticino',
        value: 'Ticino',
      },
      {
        name: 'Uri',
        value: 'Uri',
      },
      {
        name: 'Valais',
        value: 'Valais',
      },
      {
        name: 'Vaud',
        value: 'Vaud',
      },
      {
        name: 'Zug',
        value: 'Zug',
      },
      {
        name: 'Zürich',
        value: 'Zürich',
      },
    ],
  },
  SY: {
    name: 'Syria',
    type: null,
    states: [
      {
        name: 'Al-Hasakah',
        value: 'Al-Hasakah',
      },
      {
        name: 'Al-Raqqah',
        value: 'Al-Raqqah',
      },
      {
        name: 'Aleppo',
        value: 'Aleppo',
      },
      {
        name: 'As-Suwayda',
        value: 'As-Suwayda',
      },
      {
        name: 'Damascus',
        value: 'Damascus',
      },
      {
        name: 'Daraa',
        value: 'Daraa',
      },
      {
        name: 'Deir ez-Zor',
        value: 'Deir ez-Zor',
      },
      {
        name: 'Hama',
        value: 'Hama',
      },
      {
        name: 'Homs',
        value: 'Homs',
      },
      {
        name: 'Idlib',
        value: 'Idlib',
      },
      {
        name: 'Latakia',
        value: 'Latakia',
      },
      {
        name: 'Quneitra',
        value: 'Quneitra',
      },
      {
        name: 'Rif Dimashq',
        value: 'Rif Dimashq',
      },
      {
        name: 'Tartus',
        value: 'Tartus',
      },
    ],
  },
  TW: {
    name: 'Taiwan',
    type: 'county',
    states: [
      {
        name: 'Changhua',
        value: 'Changhua',
      },
      {
        name: 'Chiayi',
        value: 'Chiayi',
      },
      {
        name: 'Chiayi',
        value: 'Chiayi',
      },
      {
        name: 'Hsinchu',
        value: 'Hsinchu',
      },
      {
        name: 'Hsinchu',
        value: 'Hsinchu',
      },
      {
        name: 'Hualien',
        value: 'Hualien',
      },
      {
        name: 'Kaohsiung',
        value: 'Kaohsiung',
      },
      {
        name: 'Keelung',
        value: 'Keelung',
      },
      {
        name: 'Kinmen',
        value: 'Kinmen',
      },
      {
        name: 'Lienchiang',
        value: 'Lienchiang',
      },
      {
        name: 'Miaoli',
        value: 'Miaoli',
      },
      {
        name: 'Nantou',
        value: 'Nantou',
      },
      {
        name: 'New Taipei',
        value: 'New Taipei',
      },
      {
        name: 'Penghu',
        value: 'Penghu',
      },
      {
        name: 'Pingtung',
        value: 'Pingtung',
      },
      {
        name: 'Taichung',
        value: 'Taichung',
      },
      {
        name: 'Tainan',
        value: 'Tainan',
      },
      {
        name: 'Taipei',
        value: 'Taipei',
      },
      {
        name: 'Taitung',
        value: 'Taitung',
      },
      {
        name: 'Taoyuan',
        value: 'Taoyuan',
      },
      {
        name: 'Yilan',
        value: 'Yilan',
      },
      {
        name: 'Yunlin',
        value: 'Yunlin',
      },
    ],
  },
  TJ: {
    name: 'Tajikistan',
    type: null,
    states: [
      {
        name: 'districts of Republican Subordination',
        value: 'districts of Republican Subordination',
      },
      {
        name: 'Gorno-Badakhshan Autonomous Province',
        value: 'Gorno-Badakhshan Autonomous Province',
      },
      {
        name: 'Khatlon Province',
        value: 'Khatlon Province',
      },
      {
        name: 'Sughd Province',
        value: 'Sughd Province',
      },
    ],
  },
  TZ: {
    name: 'Tanzania',
    type: 'Region',
    states: [
      {
        name: 'Arusha',
        value: 'Arusha',
      },
      {
        name: 'Dar es Salaam',
        value: 'Dar es Salaam',
      },
      {
        name: 'Dodoma',
        value: 'Dodoma',
      },
      {
        name: 'Geita',
        value: 'Geita',
      },
      {
        name: 'Iringa',
        value: 'Iringa',
      },
      {
        name: 'Kagera',
        value: 'Kagera',
      },
      {
        name: 'Katavi',
        value: 'Katavi',
      },
      {
        name: 'Kigoma',
        value: 'Kigoma',
      },
      {
        name: 'Kilimanjaro',
        value: 'Kilimanjaro',
      },
      {
        name: 'Lindi',
        value: 'Lindi',
      },
      {
        name: 'Manyara',
        value: 'Manyara',
      },
      {
        name: 'Mara',
        value: 'Mara',
      },
      {
        name: 'Mbeya',
        value: 'Mbeya',
      },
      {
        name: 'Morogoro',
        value: 'Morogoro',
      },
      {
        name: 'Mtwara',
        value: 'Mtwara',
      },
      {
        name: 'Mwanza',
        value: 'Mwanza',
      },
      {
        name: 'Njombe',
        value: 'Njombe',
      },
      {
        name: 'Pemba North',
        value: 'Pemba North',
      },
      {
        name: 'Pemba South',
        value: 'Pemba South',
      },
      {
        name: 'Pwani',
        value: 'Pwani',
      },
      {
        name: 'Rukwa',
        value: 'Rukwa',
      },
      {
        name: 'Ruvuma',
        value: 'Ruvuma',
      },
      {
        name: 'Shinyanga',
        value: 'Shinyanga',
      },
      {
        name: 'Simiyu',
        value: 'Simiyu',
      },
      {
        name: 'Singida',
        value: 'Singida',
      },
      {
        name: 'Songwe',
        value: 'Songwe',
      },
      {
        name: 'Tabora',
        value: 'Tabora',
      },
      {
        name: 'Tanga',
        value: 'Tanga',
      },
      {
        name: 'Zanzibar North',
        value: 'Zanzibar North',
      },
      {
        name: 'Zanzibar South',
        value: 'Zanzibar South',
      },
      {
        name: 'Zanzibar West',
        value: 'Zanzibar West',
      },
    ],
  },
  TH: {
    name: 'Thailand',
    type: null,
    states: [
      {
        name: 'Amnat Charoen',
        value: 'Amnat Charoen',
      },
      {
        name: 'Ang Thong',
        value: 'Ang Thong',
      },
      {
        name: 'Bangkok',
        value: 'Bangkok',
      },
      {
        name: 'Bueng Kan',
        value: 'Bueng Kan',
      },
      {
        name: 'Buri Ram',
        value: 'Buri Ram',
      },
      {
        name: 'Chachoengsao',
        value: 'Chachoengsao',
      },
      {
        name: 'Chai Nat',
        value: 'Chai Nat',
      },
      {
        name: 'Chaiyaphum',
        value: 'Chaiyaphum',
      },
      {
        name: 'Chanthaburi',
        value: 'Chanthaburi',
      },
      {
        name: 'Chiang Mai',
        value: 'Chiang Mai',
      },
      {
        name: 'Chiang Rai',
        value: 'Chiang Rai',
      },
      {
        name: 'Chon Buri',
        value: 'Chon Buri',
      },
      {
        name: 'Chumphon',
        value: 'Chumphon',
      },
      {
        name: 'Kalasin',
        value: 'Kalasin',
      },
      {
        name: 'Kamphaeng Phet',
        value: 'Kamphaeng Phet',
      },
      {
        name: 'Kanchanaburi',
        value: 'Kanchanaburi',
      },
      {
        name: 'Khon Kaen',
        value: 'Khon Kaen',
      },
      {
        name: 'Krabi',
        value: 'Krabi',
      },
      {
        name: 'Lampang',
        value: 'Lampang',
      },
      {
        name: 'Lamphun',
        value: 'Lamphun',
      },
      {
        name: 'Loei',
        value: 'Loei',
      },
      {
        name: 'Lop Buri',
        value: 'Lop Buri',
      },
      {
        name: 'Mae Hong Son',
        value: 'Mae Hong Son',
      },
      {
        name: 'Maha Sarakham',
        value: 'Maha Sarakham',
      },
      {
        name: 'Mukdahan',
        value: 'Mukdahan',
      },
      {
        name: 'Nakhon Nayok',
        value: 'Nakhon Nayok',
      },
      {
        name: 'Nakhon Pathom',
        value: 'Nakhon Pathom',
      },
      {
        name: 'Nakhon Phanom',
        value: 'Nakhon Phanom',
      },
      {
        name: 'Nakhon Ratchasima',
        value: 'Nakhon Ratchasima',
      },
      {
        name: 'Nakhon Sawan',
        value: 'Nakhon Sawan',
      },
      {
        name: 'Nakhon Si Thammarat',
        value: 'Nakhon Si Thammarat',
      },
      {
        name: 'Nan',
        value: 'Nan',
      },
      {
        name: 'Narathiwat',
        value: 'Narathiwat',
      },
      {
        name: 'Nong Bua Lam Phu',
        value: 'Nong Bua Lam Phu',
      },
      {
        name: 'Nong Khai',
        value: 'Nong Khai',
      },
      {
        name: 'Nonthaburi',
        value: 'Nonthaburi',
      },
      {
        name: 'Pathum Thani',
        value: 'Pathum Thani',
      },
      {
        name: 'Pattani',
        value: 'Pattani',
      },
      {
        name: 'Pattaya',
        value: 'Pattaya',
      },
      {
        name: 'Phangnga',
        value: 'Phangnga',
      },
      {
        name: 'Phatthalung',
        value: 'Phatthalung',
      },
      {
        name: 'Phayao',
        value: 'Phayao',
      },
      {
        name: 'Phetchabun',
        value: 'Phetchabun',
      },
      {
        name: 'Phetchaburi',
        value: 'Phetchaburi',
      },
      {
        name: 'Phichit',
        value: 'Phichit',
      },
      {
        name: 'Phitsanulok',
        value: 'Phitsanulok',
      },
      {
        name: 'Phra Nakhon Si Ayutthaya',
        value: 'Phra Nakhon Si Ayutthaya',
      },
      {
        name: 'Phrae',
        value: 'Phrae',
      },
      {
        name: 'Phuket',
        value: 'Phuket',
      },
      {
        name: 'Prachin Buri',
        value: 'Prachin Buri',
      },
      {
        name: 'Prachuap Khiri Khan',
        value: 'Prachuap Khiri Khan',
      },
      {
        name: 'Ranong',
        value: 'Ranong',
      },
      {
        name: 'Ratchaburi',
        value: 'Ratchaburi',
      },
      {
        name: 'Rayong',
        value: 'Rayong',
      },
      {
        name: 'Roi Et',
        value: 'Roi Et',
      },
      {
        name: 'Sa Kaeo',
        value: 'Sa Kaeo',
      },
      {
        name: 'Sakon Nakhon',
        value: 'Sakon Nakhon',
      },
      {
        name: 'Samut Prakan',
        value: 'Samut Prakan',
      },
      {
        name: 'Samut Sakhon',
        value: 'Samut Sakhon',
      },
      {
        name: 'Samut Songkhram',
        value: 'Samut Songkhram',
      },
      {
        name: 'Saraburi',
        value: 'Saraburi',
      },
      {
        name: 'Satun',
        value: 'Satun',
      },
      {
        name: 'Si Sa Ket',
        value: 'Si Sa Ket',
      },
      {
        name: 'Sing Buri',
        value: 'Sing Buri',
      },
      {
        name: 'Songkhla',
        value: 'Songkhla',
      },
      {
        name: 'Sukhothai',
        value: 'Sukhothai',
      },
      {
        name: 'Suphan Buri',
        value: 'Suphan Buri',
      },
      {
        name: 'Surat Thani',
        value: 'Surat Thani',
      },
      {
        name: 'Surin',
        value: 'Surin',
      },
      {
        name: 'Tak',
        value: 'Tak',
      },
      {
        name: 'Trang',
        value: 'Trang',
      },
      {
        name: 'Trat',
        value: 'Trat',
      },
      {
        name: 'Ubon Ratchathani',
        value: 'Ubon Ratchathani',
      },
      {
        name: 'Udon Thani',
        value: 'Udon Thani',
      },
      {
        name: 'Uthai Thani',
        value: 'Uthai Thani',
      },
      {
        name: 'Uttaradit',
        value: 'Uttaradit',
      },
      {
        name: 'Yala',
        value: 'Yala',
      },
      {
        name: 'Yasothon',
        value: 'Yasothon',
      },
    ],
  },
  BS: {
    name: 'The Bahamas',
    type: null,
    states: [
      {
        name: 'Acklins',
        value: 'Acklins',
      },
      {
        name: 'Acklins and Crooked Islands',
        value: 'Acklins and Crooked Islands',
      },
      {
        name: 'Berry Islands',
        value: 'Berry Islands',
      },
      {
        name: 'Bimini',
        value: 'Bimini',
      },
      {
        name: 'Black Point',
        value: 'Black Point',
      },
      {
        name: 'Cat Island',
        value: 'Cat Island',
      },
      {
        name: 'Central Abaco',
        value: 'Central Abaco',
      },
      {
        name: 'Central Andros',
        value: 'Central Andros',
      },
      {
        name: 'Central Eleuthera',
        value: 'Central Eleuthera',
      },
      {
        name: 'Crooked Island',
        value: 'Crooked Island',
      },
      {
        name: 'East Grand Bahama',
        value: 'East Grand Bahama',
      },
      {
        name: 'Exuma',
        value: 'Exuma',
      },
      {
        name: 'Freeport',
        value: 'Freeport',
      },
      {
        name: 'Fresh Creek',
        value: 'Fresh Creek',
      },
      {
        name: "Governor's Harbour",
        value: "Governor's Harbour",
      },
      {
        name: 'Grand Cay',
        value: 'Grand Cay',
      },
      {
        name: 'Green Turtle Cay',
        value: 'Green Turtle Cay',
      },
      {
        name: 'Harbour Island',
        value: 'Harbour Island',
      },
      {
        name: 'High Rock',
        value: 'High Rock',
      },
      {
        name: 'Hope Town',
        value: 'Hope Town',
      },
      {
        name: 'Inagua',
        value: 'Inagua',
      },
      {
        name: 'Kemps Bay',
        value: 'Kemps Bay',
      },
      {
        name: 'Long Island',
        value: 'Long Island',
      },
      {
        name: 'Mangrove Cay',
        value: 'Mangrove Cay',
      },
      {
        name: 'Marsh Harbour',
        value: 'Marsh Harbour',
      },
      {
        name: 'Mayaguana District',
        value: 'Mayaguana District',
      },
      {
        name: 'New Providence',
        value: 'New Providence',
      },
      {
        name: 'Nichollstown and Berry Islands',
        value: 'Nichollstown and Berry Islands',
      },
      {
        name: 'North Abaco',
        value: 'North Abaco',
      },
      {
        name: 'North Andros',
        value: 'North Andros',
      },
      {
        name: 'North Eleuthera',
        value: 'North Eleuthera',
      },
      {
        name: 'Ragged Island',
        value: 'Ragged Island',
      },
      {
        name: 'Rock Sound',
        value: 'Rock Sound',
      },
      {
        name: 'Rum Cay District',
        value: 'Rum Cay District',
      },
      {
        name: 'San Salvador and Rum Cay',
        value: 'San Salvador and Rum Cay',
      },
      {
        name: 'San Salvador Island',
        value: 'San Salvador Island',
      },
      {
        name: 'Sandy Point',
        value: 'Sandy Point',
      },
      {
        name: 'South Abaco',
        value: 'South Abaco',
      },
      {
        name: 'South Andros',
        value: 'South Andros',
      },
      {
        name: 'South Eleuthera',
        value: 'South Eleuthera',
      },
      {
        name: 'Spanish Wells',
        value: 'Spanish Wells',
      },
      {
        name: 'West Grand Bahama',
        value: 'West Grand Bahama',
      },
    ],
  },
  TL: {
    name: 'Timor-Leste',
    type: null,
    states: [
      {
        name: 'Aileu municipality',
        value: 'Aileu municipality',
      },
      {
        name: 'Ainaro Municipality',
        value: 'Ainaro Municipality',
      },
      {
        name: 'Baucau Municipality',
        value: 'Baucau Municipality',
      },
      {
        name: 'Bobonaro Municipality',
        value: 'Bobonaro Municipality',
      },
      {
        name: 'Cova Lima Municipality',
        value: 'Cova Lima Municipality',
      },
      {
        name: 'Dili municipality',
        value: 'Dili municipality',
      },
      {
        name: 'Ermera District',
        value: 'Ermera District',
      },
      {
        name: 'Lautém Municipality',
        value: 'Lautém Municipality',
      },
      {
        name: 'Liquiçá Municipality',
        value: 'Liquiçá Municipality',
      },
      {
        name: 'Manatuto District',
        value: 'Manatuto District',
      },
      {
        name: 'Manufahi Municipality',
        value: 'Manufahi Municipality',
      },
      {
        name: 'Viqueque Municipality',
        value: 'Viqueque Municipality',
      },
    ],
  },
  TG: {
    name: 'Togo',
    type: null,
    states: [
      {
        name: 'Centrale Region',
        value: 'Centrale Region',
      },
      {
        name: 'Kara Region',
        value: 'Kara Region',
      },
      {
        name: 'Maritime',
        value: 'Maritime',
      },
      {
        name: 'Plateaux Region',
        value: 'Plateaux Region',
      },
      {
        name: 'Savanes Region',
        value: 'Savanes Region',
      },
    ],
  },
  TO: {
    name: 'Tonga',
    type: null,
    states: [
      {
        name: 'Haʻapai',
        value: 'Haʻapai',
      },
      {
        name: 'ʻEua',
        value: 'ʻEua',
      },
      {
        name: 'Niuas',
        value: 'Niuas',
      },
      {
        name: 'Tongatapu',
        value: 'Tongatapu',
      },
      {
        name: 'Vavaʻu',
        value: 'Vavaʻu',
      },
    ],
  },
  TT: {
    name: 'Trinidad and Tobago',
    type: null,
    states: [
      {
        name: 'Arima',
        value: 'Arima',
      },
      {
        name: 'Chaguanas',
        value: 'Chaguanas',
      },
      {
        name: 'Couva-Tabaquite-Talparo Regional Corporation',
        value: 'Couva-Tabaquite-Talparo Regional Corporation',
      },
      {
        name: 'Diego Martin Regional Corporation',
        value: 'Diego Martin Regional Corporation',
      },
      {
        name: 'Eastern Tobago',
        value: 'Eastern Tobago',
      },
      {
        name: 'Penal-Debe Regional Corporation',
        value: 'Penal-Debe Regional Corporation',
      },
      {
        name: 'Point Fortin',
        value: 'Point Fortin',
      },
      {
        name: 'Port of Spain',
        value: 'Port of Spain',
      },
      {
        name: 'Princes Town Regional Corporation',
        value: 'Princes Town Regional Corporation',
      },
      {
        name: 'Rio Claro-Mayaro Regional Corporation',
        value: 'Rio Claro-Mayaro Regional Corporation',
      },
      {
        name: 'San Fernando',
        value: 'San Fernando',
      },
      {
        name: 'San Juan-Laventille Regional Corporation',
        value: 'San Juan-Laventille Regional Corporation',
      },
      {
        name: 'Sangre Grande Regional Corporation',
        value: 'Sangre Grande Regional Corporation',
      },
      {
        name: 'Siparia Regional Corporation',
        value: 'Siparia Regional Corporation',
      },
      {
        name: 'Tunapuna-Piarco Regional Corporation',
        value: 'Tunapuna-Piarco Regional Corporation',
      },
      {
        name: 'Western Tobago',
        value: 'Western Tobago',
      },
    ],
  },
  TN: {
    name: 'Tunisia',
    type: 'governorate',
    states: [
      {
        name: 'Ariana',
        value: 'Ariana',
      },
      {
        name: 'Béja',
        value: 'Béja',
      },
      {
        name: 'Ben Arous',
        value: 'Ben Arous',
      },
      {
        name: 'Bizerte',
        value: 'Bizerte',
      },
      {
        name: 'Gabès',
        value: 'Gabès',
      },
      {
        name: 'Gafsa',
        value: 'Gafsa',
      },
      {
        name: 'Jendouba',
        value: 'Jendouba',
      },
      {
        name: 'Kairouan',
        value: 'Kairouan',
      },
      {
        name: 'Kasserine',
        value: 'Kasserine',
      },
      {
        name: 'Kebili',
        value: 'Kebili',
      },
      {
        name: 'Kef',
        value: 'Kef',
      },
      {
        name: 'Mahdia',
        value: 'Mahdia',
      },
      {
        name: 'Manouba',
        value: 'Manouba',
      },
      {
        name: 'Medenine',
        value: 'Medenine',
      },
      {
        name: 'Monastir',
        value: 'Monastir',
      },
      {
        name: 'Nabeul',
        value: 'Nabeul',
      },
      {
        name: 'Sfax',
        value: 'Sfax',
      },
      {
        name: 'Sidi Bouzid',
        value: 'Sidi Bouzid',
      },
      {
        name: 'Siliana',
        value: 'Siliana',
      },
      {
        name: 'Sousse',
        value: 'Sousse',
      },
      {
        name: 'Tataouine',
        value: 'Tataouine',
      },
      {
        name: 'Tozeur',
        value: 'Tozeur',
      },
      {
        name: 'Tunis',
        value: 'Tunis',
      },
      {
        name: 'Zaghouan',
        value: 'Zaghouan',
      },
    ],
  },
  TR: {
    name: 'Turkey',
    type: 'province',
    states: [
      {
        name: 'Adana',
        value: 'Adana',
      },
      {
        name: 'Adıyaman',
        value: 'Adıyaman',
      },
      {
        name: 'Afyonkarahisar',
        value: 'Afyonkarahisar',
      },
      {
        name: 'Ağrı',
        value: 'Ağrı',
      },
      {
        name: 'Aksaray',
        value: 'Aksaray',
      },
      {
        name: 'Amasya',
        value: 'Amasya',
      },
      {
        name: 'Ankara',
        value: 'Ankara',
      },
      {
        name: 'Antalya',
        value: 'Antalya',
      },
      {
        name: 'Ardahan',
        value: 'Ardahan',
      },
      {
        name: 'Artvin',
        value: 'Artvin',
      },
      {
        name: 'Aydın',
        value: 'Aydın',
      },
      {
        name: 'Balıkesir',
        value: 'Balıkesir',
      },
      {
        name: 'Bartın',
        value: 'Bartın',
      },
      {
        name: 'Batman',
        value: 'Batman',
      },
      {
        name: 'Bayburt',
        value: 'Bayburt',
      },
      {
        name: 'Bilecik',
        value: 'Bilecik',
      },
      {
        name: 'Bingöl',
        value: 'Bingöl',
      },
      {
        name: 'Bitlis',
        value: 'Bitlis',
      },
      {
        name: 'Bolu',
        value: 'Bolu',
      },
      {
        name: 'Burdur',
        value: 'Burdur',
      },
      {
        name: 'Bursa',
        value: 'Bursa',
      },
      {
        name: 'Çanakkale',
        value: 'Çanakkale',
      },
      {
        name: 'Çankırı',
        value: 'Çankırı',
      },
      {
        name: 'Çorum',
        value: 'Çorum',
      },
      {
        name: 'Denizli',
        value: 'Denizli',
      },
      {
        name: 'Diyarbakır',
        value: 'Diyarbakır',
      },
      {
        name: 'Düzce',
        value: 'Düzce',
      },
      {
        name: 'Edirne',
        value: 'Edirne',
      },
      {
        name: 'Elazığ',
        value: 'Elazığ',
      },
      {
        name: 'Erzincan',
        value: 'Erzincan',
      },
      {
        name: 'Erzurum',
        value: 'Erzurum',
      },
      {
        name: 'Eskişehir',
        value: 'Eskişehir',
      },
      {
        name: 'Gaziantep',
        value: 'Gaziantep',
      },
      {
        name: 'Giresun',
        value: 'Giresun',
      },
      {
        name: 'Gümüşhane',
        value: 'Gümüşhane',
      },
      {
        name: 'Hakkâri',
        value: 'Hakkâri',
      },
      {
        name: 'Hatay',
        value: 'Hatay',
      },
      {
        name: 'Iğdır',
        value: 'Iğdır',
      },
      {
        name: 'Isparta',
        value: 'Isparta',
      },
      {
        name: 'İstanbul',
        value: 'İstanbul',
      },
      {
        name: 'İzmir',
        value: 'İzmir',
      },
      {
        name: 'Kahramanmaraş',
        value: 'Kahramanmaraş',
      },
      {
        name: 'Karabük',
        value: 'Karabük',
      },
      {
        name: 'Karaman',
        value: 'Karaman',
      },
      {
        name: 'Kars',
        value: 'Kars',
      },
      {
        name: 'Kastamonu',
        value: 'Kastamonu',
      },
      {
        name: 'Kayseri',
        value: 'Kayseri',
      },
      {
        name: 'Kilis',
        value: 'Kilis',
      },
      {
        name: 'Kırıkkale',
        value: 'Kırıkkale',
      },
      {
        name: 'Kırklareli',
        value: 'Kırklareli',
      },
      {
        name: 'Kırşehir',
        value: 'Kırşehir',
      },
      {
        name: 'Kocaeli',
        value: 'Kocaeli',
      },
      {
        name: 'Konya',
        value: 'Konya',
      },
      {
        name: 'Kütahya',
        value: 'Kütahya',
      },
      {
        name: 'Malatya',
        value: 'Malatya',
      },
      {
        name: 'Manisa',
        value: 'Manisa',
      },
      {
        name: 'Mardin',
        value: 'Mardin',
      },
      {
        name: 'Mersin',
        value: 'Mersin',
      },
      {
        name: 'Muğla',
        value: 'Muğla',
      },
      {
        name: 'Muş',
        value: 'Muş',
      },
      {
        name: 'Nevşehir',
        value: 'Nevşehir',
      },
      {
        name: 'Niğde',
        value: 'Niğde',
      },
      {
        name: 'Ordu',
        value: 'Ordu',
      },
      {
        name: 'Osmaniye',
        value: 'Osmaniye',
      },
      {
        name: 'Rize',
        value: 'Rize',
      },
      {
        name: 'Sakarya',
        value: 'Sakarya',
      },
      {
        name: 'Samsun',
        value: 'Samsun',
      },
      {
        name: 'Şanlıurfa',
        value: 'Şanlıurfa',
      },
      {
        name: 'Siirt',
        value: 'Siirt',
      },
      {
        name: 'Sinop',
        value: 'Sinop',
      },
      {
        name: 'Sivas',
        value: 'Sivas',
      },
      {
        name: 'Şırnak',
        value: 'Şırnak',
      },
      {
        name: 'Tekirdağ',
        value: 'Tekirdağ',
      },
      {
        name: 'Tokat',
        value: 'Tokat',
      },
      {
        name: 'Trabzon',
        value: 'Trabzon',
      },
      {
        name: 'Tunceli',
        value: 'Tunceli',
      },
      {
        name: 'Uşak',
        value: 'Uşak',
      },
      {
        name: 'Van',
        value: 'Van',
      },
      {
        name: 'Yalova',
        value: 'Yalova',
      },
      {
        name: 'Yozgat',
        value: 'Yozgat',
      },
      {
        name: 'Zonguldak',
        value: 'Zonguldak',
      },
    ],
  },
  TM: {
    name: 'Turkmenistan',
    type: null,
    states: [
      {
        name: 'Ahal Region',
        value: 'Ahal Region',
      },
      {
        name: 'Ashgabat',
        value: 'Ashgabat',
      },
      {
        name: 'Balkan Region',
        value: 'Balkan Region',
      },
      {
        name: 'Daşoguz Region',
        value: 'Daşoguz Region',
      },
      {
        name: 'Lebap Region',
        value: 'Lebap Region',
      },
      {
        name: 'Mary Region',
        value: 'Mary Region',
      },
    ],
  },
  TV: {
    name: 'Tuvalu',
    type: null,
    states: [
      {
        name: 'Funafuti',
        value: 'Funafuti',
      },
      {
        name: 'Nanumanga',
        value: 'Nanumanga',
      },
      {
        name: 'Nanumea',
        value: 'Nanumea',
      },
      {
        name: 'Niutao Island Council',
        value: 'Niutao Island Council',
      },
      {
        name: 'Nui',
        value: 'Nui',
      },
      {
        name: 'Nukufetau',
        value: 'Nukufetau',
      },
      {
        name: 'Nukulaelae',
        value: 'Nukulaelae',
      },
      {
        name: 'Vaitupu',
        value: 'Vaitupu',
      },
    ],
  },
  UG: {
    name: 'Uganda',
    type: null,
    states: [
      {
        name: 'Abim District',
        value: 'Abim District',
      },
      {
        name: 'Adjumani District',
        value: 'Adjumani District',
      },
      {
        name: 'Agago District',
        value: 'Agago District',
      },
      {
        name: 'Alebtong District',
        value: 'Alebtong District',
      },
      {
        name: 'Amolatar District',
        value: 'Amolatar District',
      },
      {
        name: 'Amudat District',
        value: 'Amudat District',
      },
      {
        name: 'Amuria District',
        value: 'Amuria District',
      },
      {
        name: 'Amuru District',
        value: 'Amuru District',
      },
      {
        name: 'Apac District',
        value: 'Apac District',
      },
      {
        name: 'Arua District',
        value: 'Arua District',
      },
      {
        name: 'Budaka District',
        value: 'Budaka District',
      },
      {
        name: 'Bududa District',
        value: 'Bududa District',
      },
      {
        name: 'Bugiri District',
        value: 'Bugiri District',
      },
      {
        name: 'Buhweju District',
        value: 'Buhweju District',
      },
      {
        name: 'Buikwe District',
        value: 'Buikwe District',
      },
      {
        name: 'Bukedea District',
        value: 'Bukedea District',
      },
      {
        name: 'Bukomansimbi District',
        value: 'Bukomansimbi District',
      },
      {
        name: 'Bukwo District',
        value: 'Bukwo District',
      },
      {
        name: 'Bulambuli District',
        value: 'Bulambuli District',
      },
      {
        name: 'Buliisa District',
        value: 'Buliisa District',
      },
      {
        name: 'Bundibugyo District',
        value: 'Bundibugyo District',
      },
      {
        name: 'Bunyangabu District',
        value: 'Bunyangabu District',
      },
      {
        name: 'Bushenyi District',
        value: 'Bushenyi District',
      },
      {
        name: 'Busia District',
        value: 'Busia District',
      },
      {
        name: 'Butaleja District',
        value: 'Butaleja District',
      },
      {
        name: 'Butambala District',
        value: 'Butambala District',
      },
      {
        name: 'Butebo District',
        value: 'Butebo District',
      },
      {
        name: 'Buvuma District',
        value: 'Buvuma District',
      },
      {
        name: 'Buyende District',
        value: 'Buyende District',
      },
      {
        name: 'Central Region',
        value: 'Central Region',
      },
      {
        name: 'Dokolo District',
        value: 'Dokolo District',
      },
      {
        name: 'Eastern Region',
        value: 'Eastern Region',
      },
      {
        name: 'Gomba District',
        value: 'Gomba District',
      },
      {
        name: 'Gulu District',
        value: 'Gulu District',
      },
      {
        name: 'Ibanda District',
        value: 'Ibanda District',
      },
      {
        name: 'Iganga District',
        value: 'Iganga District',
      },
      {
        name: 'Isingiro District',
        value: 'Isingiro District',
      },
      {
        name: 'Jinja District',
        value: 'Jinja District',
      },
      {
        name: 'Kaabong District',
        value: 'Kaabong District',
      },
      {
        name: 'Kabale District',
        value: 'Kabale District',
      },
      {
        name: 'Kabarole District',
        value: 'Kabarole District',
      },
      {
        name: 'Kaberamaido District',
        value: 'Kaberamaido District',
      },
      {
        name: 'Kagadi District',
        value: 'Kagadi District',
      },
      {
        name: 'Kakumiro District',
        value: 'Kakumiro District',
      },
      {
        name: 'Kalangala District',
        value: 'Kalangala District',
      },
      {
        name: 'Kaliro District',
        value: 'Kaliro District',
      },
      {
        name: 'Kalungu District',
        value: 'Kalungu District',
      },
      {
        name: 'Kampala District',
        value: 'Kampala District',
      },
      {
        name: 'Kamuli District',
        value: 'Kamuli District',
      },
      {
        name: 'Kamwenge District',
        value: 'Kamwenge District',
      },
      {
        name: 'Kanungu District',
        value: 'Kanungu District',
      },
      {
        name: 'Kapchorwa District',
        value: 'Kapchorwa District',
      },
      {
        name: 'Kasese District',
        value: 'Kasese District',
      },
      {
        name: 'Katakwi District',
        value: 'Katakwi District',
      },
      {
        name: 'Kayunga District',
        value: 'Kayunga District',
      },
      {
        name: 'Kibaale District',
        value: 'Kibaale District',
      },
      {
        name: 'Kiboga District',
        value: 'Kiboga District',
      },
      {
        name: 'Kibuku District',
        value: 'Kibuku District',
      },
      {
        name: 'Kiruhura District',
        value: 'Kiruhura District',
      },
      {
        name: 'Kiryandongo District',
        value: 'Kiryandongo District',
      },
      {
        name: 'Kisoro District',
        value: 'Kisoro District',
      },
      {
        name: 'Kitgum District',
        value: 'Kitgum District',
      },
      {
        name: 'Koboko District',
        value: 'Koboko District',
      },
      {
        name: 'Kole District',
        value: 'Kole District',
      },
      {
        name: 'Kotido District',
        value: 'Kotido District',
      },
      {
        name: 'Kumi District',
        value: 'Kumi District',
      },
      {
        name: 'Kween District',
        value: 'Kween District',
      },
      {
        name: 'Kyankwanzi District',
        value: 'Kyankwanzi District',
      },
      {
        name: 'Kyegegwa District',
        value: 'Kyegegwa District',
      },
      {
        name: 'Kyenjojo District',
        value: 'Kyenjojo District',
      },
      {
        name: 'Kyotera District',
        value: 'Kyotera District',
      },
      {
        name: 'Lamwo District',
        value: 'Lamwo District',
      },
      {
        name: 'Lira District',
        value: 'Lira District',
      },
      {
        name: 'Luuka District',
        value: 'Luuka District',
      },
      {
        name: 'Luwero District',
        value: 'Luwero District',
      },
      {
        name: 'Lwengo District',
        value: 'Lwengo District',
      },
      {
        name: 'Lyantonde District',
        value: 'Lyantonde District',
      },
      {
        name: 'Manafwa District',
        value: 'Manafwa District',
      },
      {
        name: 'Maracha District',
        value: 'Maracha District',
      },
      {
        name: 'Masaka District',
        value: 'Masaka District',
      },
      {
        name: 'Masindi District',
        value: 'Masindi District',
      },
      {
        name: 'Mayuge District',
        value: 'Mayuge District',
      },
      {
        name: 'Mbale District',
        value: 'Mbale District',
      },
      {
        name: 'Mbarara District',
        value: 'Mbarara District',
      },
      {
        name: 'Mitooma District',
        value: 'Mitooma District',
      },
      {
        name: 'Mityana District',
        value: 'Mityana District',
      },
      {
        name: 'Moroto District',
        value: 'Moroto District',
      },
      {
        name: 'Moyo District',
        value: 'Moyo District',
      },
      {
        name: 'Mpigi District',
        value: 'Mpigi District',
      },
      {
        name: 'Mubende District',
        value: 'Mubende District',
      },
      {
        name: 'Mukono District',
        value: 'Mukono District',
      },
      {
        name: 'Nakapiripirit District',
        value: 'Nakapiripirit District',
      },
      {
        name: 'Nakaseke District',
        value: 'Nakaseke District',
      },
      {
        name: 'Nakasongola District',
        value: 'Nakasongola District',
      },
      {
        name: 'Namayingo District',
        value: 'Namayingo District',
      },
      {
        name: 'Namisindwa District',
        value: 'Namisindwa District',
      },
      {
        name: 'Namutumba District',
        value: 'Namutumba District',
      },
      {
        name: 'Napak District',
        value: 'Napak District',
      },
      {
        name: 'Nebbi District',
        value: 'Nebbi District',
      },
      {
        name: 'Ngora District',
        value: 'Ngora District',
      },
      {
        name: 'Northern Region',
        value: 'Northern Region',
      },
      {
        name: 'Ntoroko District',
        value: 'Ntoroko District',
      },
      {
        name: 'Ntungamo District',
        value: 'Ntungamo District',
      },
      {
        name: 'Nwoya District',
        value: 'Nwoya District',
      },
      {
        name: 'Omoro District',
        value: 'Omoro District',
      },
      {
        name: 'Otuke District',
        value: 'Otuke District',
      },
      {
        name: 'Oyam District',
        value: 'Oyam District',
      },
      {
        name: 'Pader District',
        value: 'Pader District',
      },
      {
        name: 'Pakwach District',
        value: 'Pakwach District',
      },
      {
        name: 'Pallisa District',
        value: 'Pallisa District',
      },
      {
        name: 'Rakai District',
        value: 'Rakai District',
      },
      {
        name: 'Rubanda District',
        value: 'Rubanda District',
      },
      {
        name: 'Rubirizi District',
        value: 'Rubirizi District',
      },
      {
        name: 'Rukiga District',
        value: 'Rukiga District',
      },
      {
        name: 'Rukungiri District',
        value: 'Rukungiri District',
      },
      {
        name: 'Sembabule District',
        value: 'Sembabule District',
      },
      {
        name: 'Serere District',
        value: 'Serere District',
      },
      {
        name: 'Sheema District',
        value: 'Sheema District',
      },
      {
        name: 'Sironko District',
        value: 'Sironko District',
      },
      {
        name: 'Soroti District',
        value: 'Soroti District',
      },
      {
        name: 'Tororo District',
        value: 'Tororo District',
      },
      {
        name: 'Wakiso District',
        value: 'Wakiso District',
      },
      {
        name: 'Western Region',
        value: 'Western Region',
      },
      {
        name: 'Yumbe District',
        value: 'Yumbe District',
      },
      {
        name: 'Zombo District',
        value: 'Zombo District',
      },
    ],
  },
  UA: {
    name: 'Ukraine',
    type: 'republic',
    states: [
      {
        name: 'Autonomous Republic of Crimea',
        value: 'Autonomous Republic of Crimea',
      },
      {
        name: 'Cherkaska oblast',
        value: 'Cherkaska oblast',
      },
      {
        name: 'Chernihivska oblast',
        value: 'Chernihivska oblast',
      },
      {
        name: 'Chernivetska oblast',
        value: 'Chernivetska oblast',
      },
      {
        name: 'Dnipropetrovska oblast',
        value: 'Dnipropetrovska oblast',
      },
      {
        name: 'Donetska oblast',
        value: 'Donetska oblast',
      },
      {
        name: 'Ivano-Frankivska oblast',
        value: 'Ivano-Frankivska oblast',
      },
      {
        name: 'Kharkivska oblast',
        value: 'Kharkivska oblast',
      },
      {
        name: 'Khersonska oblast',
        value: 'Khersonska oblast',
      },
      {
        name: 'Khmelnytska oblast',
        value: 'Khmelnytska oblast',
      },
      {
        name: 'Kirovohradska oblast',
        value: 'Kirovohradska oblast',
      },
      {
        name: 'Kyiv',
        value: 'Kyiv',
      },
      {
        name: 'Kyivska oblast',
        value: 'Kyivska oblast',
      },
      {
        name: 'Luhanska oblast',
        value: 'Luhanska oblast',
      },
      {
        name: 'Lvivska oblast',
        value: 'Lvivska oblast',
      },
      {
        name: 'Mykolaivska oblast',
        value: 'Mykolaivska oblast',
      },
      {
        name: 'Odeska oblast',
        value: 'Odeska oblast',
      },
      {
        name: 'Poltavska oblast',
        value: 'Poltavska oblast',
      },
      {
        name: 'Rivnenska oblast',
        value: 'Rivnenska oblast',
      },
      {
        name: 'Sevastopol',
        value: 'Sevastopol',
      },
      {
        name: 'Sumska oblast',
        value: 'Sumska oblast',
      },
      {
        name: 'Ternopilska oblast',
        value: 'Ternopilska oblast',
      },
      {
        name: 'Vinnytska oblast',
        value: 'Vinnytska oblast',
      },
      {
        name: 'Volynska oblast',
        value: 'Volynska oblast',
      },
      {
        name: 'Zakarpatska Oblast',
        value: 'Zakarpatska Oblast',
      },
      {
        name: 'Zaporizka oblast',
        value: 'Zaporizka oblast',
      },
      {
        name: 'Zhytomyrska oblast',
        value: 'Zhytomyrska oblast',
      },
    ],
  },
  AE: {
    name: 'United Arab Emirates',
    type: 'state',
    states: [
      {
        name: 'Abu Dhabi Emirate',
        value: 'Abu Dhabi Emirate',
      },
      {
        name: 'Ajman Emirate',
        value: 'Ajman Emirate',
      },
      {
        name: 'Dubai',
        value: 'Dubai',
      },
      {
        name: 'Fujairah',
        value: 'Fujairah',
      },
      {
        name: 'Ras al-Khaimah',
        value: 'Ras al-Khaimah',
      },
      {
        name: 'Sharjah Emirate',
        value: 'Sharjah Emirate',
      },
      {
        name: 'Umm al-Quwain',
        value: 'Umm al-Quwain',
      },
    ],
  },
  GB: {
    name: 'United Kingdom',
    type: null,
    states: [
      {
        name: 'Aberdeen',
        value: 'Aberdeen',
      },
      {
        name: 'Aberdeenshire',
        value: 'Aberdeenshire',
      },
      {
        name: 'Angus',
        value: 'Angus',
      },
      {
        name: 'Antrim',
        value: 'Antrim',
      },
      {
        name: 'Antrim and Newtownabbey',
        value: 'Antrim and Newtownabbey',
      },
      {
        name: 'Ards',
        value: 'Ards',
      },
      {
        name: 'Ards and North Down',
        value: 'Ards and North Down',
      },
      {
        name: 'Argyll and Bute',
        value: 'Argyll and Bute',
      },
      {
        name: 'Armagh City and District Council',
        value: 'Armagh City and District Council',
      },
      {
        name: 'Armagh, Banbridge and Craigavon',
        value: 'Armagh, Banbridge and Craigavon',
      },
      {
        name: 'Ascension Island',
        value: 'Ascension Island',
      },
      {
        name: 'Ballymena Borough',
        value: 'Ballymena Borough',
      },
      {
        name: 'Ballymoney',
        value: 'Ballymoney',
      },
      {
        name: 'Banbridge',
        value: 'Banbridge',
      },
      {
        name: 'Barnsley',
        value: 'Barnsley',
      },
      {
        name: 'Bath and North East Somerset',
        value: 'Bath and North East Somerset',
      },
      {
        name: 'Bedford',
        value: 'Bedford',
      },
      {
        name: 'Belfast district',
        value: 'Belfast district',
      },
      {
        name: 'Birmingham',
        value: 'Birmingham',
      },
      {
        name: 'Blackburn with Darwen',
        value: 'Blackburn with Darwen',
      },
      {
        name: 'Blackpool',
        value: 'Blackpool',
      },
      {
        name: 'Blaenau Gwent County Borough',
        value: 'Blaenau Gwent County Borough',
      },
      {
        name: 'Bolton',
        value: 'Bolton',
      },
      {
        name: 'Bournemouth',
        value: 'Bournemouth',
      },
      {
        name: 'Bracknell Forest',
        value: 'Bracknell Forest',
      },
      {
        name: 'Bradford',
        value: 'Bradford',
      },
      {
        name: 'Bridgend County Borough',
        value: 'Bridgend County Borough',
      },
      {
        name: 'Brighton and Hove',
        value: 'Brighton and Hove',
      },
      {
        name: 'Buckinghamshire',
        value: 'Buckinghamshire',
      },
      {
        name: 'Bury',
        value: 'Bury',
      },
      {
        name: 'Caerphilly County Borough',
        value: 'Caerphilly County Borough',
      },
      {
        name: 'Calderdale',
        value: 'Calderdale',
      },
      {
        name: 'Cambridgeshire',
        value: 'Cambridgeshire',
      },
      {
        name: 'Carmarthenshire',
        value: 'Carmarthenshire',
      },
      {
        name: 'Carrickfergus Borough Council',
        value: 'Carrickfergus Borough Council',
      },
      {
        name: 'Castlereagh',
        value: 'Castlereagh',
      },
      {
        name: 'Causeway Coast and Glens',
        value: 'Causeway Coast and Glens',
      },
      {
        name: 'Central Bedfordshire',
        value: 'Central Bedfordshire',
      },
      {
        name: 'Ceredigion',
        value: 'Ceredigion',
      },
      {
        name: 'Cheshire East',
        value: 'Cheshire East',
      },
      {
        name: 'Cheshire West and Chester',
        value: 'Cheshire West and Chester',
      },
      {
        name: 'City and County of Cardiff',
        value: 'City and County of Cardiff',
      },
      {
        name: 'City and County of Swansea',
        value: 'City and County of Swansea',
      },
      {
        name: 'City of Bristol',
        value: 'City of Bristol',
      },
      {
        name: 'City of Derby',
        value: 'City of Derby',
      },
      {
        name: 'City of Kingston upon Hull',
        value: 'City of Kingston upon Hull',
      },
      {
        name: 'City of Leicester',
        value: 'City of Leicester',
      },
      {
        name: 'City of London',
        value: 'City of London',
      },
      {
        name: 'City of Nottingham',
        value: 'City of Nottingham',
      },
      {
        name: 'City of Peterborough',
        value: 'City of Peterborough',
      },
      {
        name: 'City of Plymouth',
        value: 'City of Plymouth',
      },
      {
        name: 'City of Portsmouth',
        value: 'City of Portsmouth',
      },
      {
        name: 'City of Southampton',
        value: 'City of Southampton',
      },
      {
        name: 'City of Stoke-on-Trent',
        value: 'City of Stoke-on-Trent',
      },
      {
        name: 'City of Sunderland',
        value: 'City of Sunderland',
      },
      {
        name: 'City of Westminster',
        value: 'City of Westminster',
      },
      {
        name: 'City of Wolverhampton',
        value: 'City of Wolverhampton',
      },
      {
        name: 'City of York',
        value: 'City of York',
      },
      {
        name: 'Clackmannanshire',
        value: 'Clackmannanshire',
      },
      {
        name: 'Coleraine Borough Council',
        value: 'Coleraine Borough Council',
      },
      {
        name: 'Conwy County Borough',
        value: 'Conwy County Borough',
      },
      {
        name: 'Cookstown District Council',
        value: 'Cookstown District Council',
      },
      {
        name: 'Cornwall',
        value: 'Cornwall',
      },
      {
        name: 'County Durham',
        value: 'County Durham',
      },
      {
        name: 'Coventry',
        value: 'Coventry',
      },
      {
        name: 'Craigavon Borough Council',
        value: 'Craigavon Borough Council',
      },
      {
        name: 'Cumbria',
        value: 'Cumbria',
      },
      {
        name: 'Darlington',
        value: 'Darlington',
      },
      {
        name: 'Denbighshire',
        value: 'Denbighshire',
      },
      {
        name: 'Derbyshire',
        value: 'Derbyshire',
      },
      {
        name: 'Derry City and Strabane',
        value: 'Derry City and Strabane',
      },
      {
        name: 'Derry City Council',
        value: 'Derry City Council',
      },
      {
        name: 'Devon',
        value: 'Devon',
      },
      {
        name: 'Doncaster',
        value: 'Doncaster',
      },
      {
        name: 'Dorset',
        value: 'Dorset',
      },
      {
        name: 'Down District Council',
        value: 'Down District Council',
      },
      {
        name: 'Dudley',
        value: 'Dudley',
      },
      {
        name: 'Dumfries and Galloway',
        value: 'Dumfries and Galloway',
      },
      {
        name: 'Dundee',
        value: 'Dundee',
      },
      {
        name: 'Dungannon and South Tyrone Borough Council',
        value: 'Dungannon and South Tyrone Borough Council',
      },
      {
        name: 'East Ayrshire',
        value: 'East Ayrshire',
      },
      {
        name: 'East Dunbartonshire',
        value: 'East Dunbartonshire',
      },
      {
        name: 'East Lothian',
        value: 'East Lothian',
      },
      {
        name: 'East Renfrewshire',
        value: 'East Renfrewshire',
      },
      {
        name: 'East Riding of Yorkshire',
        value: 'East Riding of Yorkshire',
      },
      {
        name: 'East Sussex',
        value: 'East Sussex',
      },
      {
        name: 'Edinburgh',
        value: 'Edinburgh',
      },
      {
        name: 'England',
        value: 'England',
      },
      {
        name: 'Essex',
        value: 'Essex',
      },
      {
        name: 'Falkirk',
        value: 'Falkirk',
      },
      {
        name: 'Fermanagh and Omagh',
        value: 'Fermanagh and Omagh',
      },
      {
        name: 'Fermanagh District Council',
        value: 'Fermanagh District Council',
      },
      {
        name: 'Fife',
        value: 'Fife',
      },
      {
        name: 'Flintshire',
        value: 'Flintshire',
      },
      {
        name: 'Gateshead',
        value: 'Gateshead',
      },
      {
        name: 'Glasgow',
        value: 'Glasgow',
      },
      {
        name: 'Gloucestershire',
        value: 'Gloucestershire',
      },
      {
        name: 'Gwynedd',
        value: 'Gwynedd',
      },
      {
        name: 'Halton',
        value: 'Halton',
      },
      {
        name: 'Hampshire',
        value: 'Hampshire',
      },
      {
        name: 'Hartlepool',
        value: 'Hartlepool',
      },
      {
        name: 'Herefordshire',
        value: 'Herefordshire',
      },
      {
        name: 'Hertfordshire',
        value: 'Hertfordshire',
      },
      {
        name: 'Highland',
        value: 'Highland',
      },
      {
        name: 'Inverclyde',
        value: 'Inverclyde',
      },
      {
        name: 'Isle of Wight',
        value: 'Isle of Wight',
      },
      {
        name: 'Isles of Scilly',
        value: 'Isles of Scilly',
      },
      {
        name: 'Kent',
        value: 'Kent',
      },
      {
        name: 'Kirklees',
        value: 'Kirklees',
      },
      {
        name: 'Knowsley',
        value: 'Knowsley',
      },
      {
        name: 'Lancashire',
        value: 'Lancashire',
      },
      {
        name: 'Larne Borough Council',
        value: 'Larne Borough Council',
      },
      {
        name: 'Leeds',
        value: 'Leeds',
      },
      {
        name: 'Leicestershire',
        value: 'Leicestershire',
      },
      {
        name: 'Limavady Borough Council',
        value: 'Limavady Borough Council',
      },
      {
        name: 'Lincolnshire',
        value: 'Lincolnshire',
      },
      {
        name: 'Lisburn and Castlereagh',
        value: 'Lisburn and Castlereagh',
      },
      {
        name: 'Lisburn City Council',
        value: 'Lisburn City Council',
      },
      {
        name: 'Liverpool',
        value: 'Liverpool',
      },
      {
        name: 'London Borough of Barking and Dagenham',
        value: 'London Borough of Barking and Dagenham',
      },
      {
        name: 'London Borough of Barnet',
        value: 'London Borough of Barnet',
      },
      {
        name: 'London Borough of Bexley',
        value: 'London Borough of Bexley',
      },
      {
        name: 'London Borough of Brent',
        value: 'London Borough of Brent',
      },
      {
        name: 'London Borough of Bromley',
        value: 'London Borough of Bromley',
      },
      {
        name: 'London Borough of Camden',
        value: 'London Borough of Camden',
      },
      {
        name: 'London Borough of Croydon',
        value: 'London Borough of Croydon',
      },
      {
        name: 'London Borough of Ealing',
        value: 'London Borough of Ealing',
      },
      {
        name: 'London Borough of Enfield',
        value: 'London Borough of Enfield',
      },
      {
        name: 'London Borough of Hackney',
        value: 'London Borough of Hackney',
      },
      {
        name: 'London Borough of Hammersmith and Fulham',
        value: 'London Borough of Hammersmith and Fulham',
      },
      {
        name: 'London Borough of Haringey',
        value: 'London Borough of Haringey',
      },
      {
        name: 'London Borough of Harrow',
        value: 'London Borough of Harrow',
      },
      {
        name: 'London Borough of Havering',
        value: 'London Borough of Havering',
      },
      {
        name: 'London Borough of Hillingdon',
        value: 'London Borough of Hillingdon',
      },
      {
        name: 'London Borough of Hounslow',
        value: 'London Borough of Hounslow',
      },
      {
        name: 'London Borough of Islington',
        value: 'London Borough of Islington',
      },
      {
        name: 'London Borough of Lambeth',
        value: 'London Borough of Lambeth',
      },
      {
        name: 'London Borough of Lewisham',
        value: 'London Borough of Lewisham',
      },
      {
        name: 'London Borough of Merton',
        value: 'London Borough of Merton',
      },
      {
        name: 'London Borough of Newham',
        value: 'London Borough of Newham',
      },
      {
        name: 'London Borough of Redbridge',
        value: 'London Borough of Redbridge',
      },
      {
        name: 'London Borough of Richmond upon Thames',
        value: 'London Borough of Richmond upon Thames',
      },
      {
        name: 'London Borough of Southwark',
        value: 'London Borough of Southwark',
      },
      {
        name: 'London Borough of Sutton',
        value: 'London Borough of Sutton',
      },
      {
        name: 'London Borough of Tower Hamlets',
        value: 'London Borough of Tower Hamlets',
      },
      {
        name: 'London Borough of Waltham Forest',
        value: 'London Borough of Waltham Forest',
      },
      {
        name: 'London Borough of Wandsworth',
        value: 'London Borough of Wandsworth',
      },
      {
        name: 'Magherafelt District Council',
        value: 'Magherafelt District Council',
      },
      {
        name: 'Manchester',
        value: 'Manchester',
      },
      {
        name: 'Medway',
        value: 'Medway',
      },
      {
        name: 'Merthyr Tydfil County Borough',
        value: 'Merthyr Tydfil County Borough',
      },
      {
        name: 'Metropolitan Borough of Wigan',
        value: 'Metropolitan Borough of Wigan',
      },
      {
        name: 'Mid and East Antrim',
        value: 'Mid and East Antrim',
      },
      {
        name: 'Mid Ulster',
        value: 'Mid Ulster',
      },
      {
        name: 'Middlesbrough',
        value: 'Middlesbrough',
      },
      {
        name: 'Midlothian',
        value: 'Midlothian',
      },
      {
        name: 'Milton Keynes',
        value: 'Milton Keynes',
      },
      {
        name: 'Monmouthshire',
        value: 'Monmouthshire',
      },
      {
        name: 'Moray',
        value: 'Moray',
      },
      {
        name: 'Moyle District Council',
        value: 'Moyle District Council',
      },
      {
        name: 'Neath Port Talbot County Borough',
        value: 'Neath Port Talbot County Borough',
      },
      {
        name: 'Newcastle upon Tyne',
        value: 'Newcastle upon Tyne',
      },
      {
        name: 'Newport',
        value: 'Newport',
      },
      {
        name: 'Newry and Mourne District Council',
        value: 'Newry and Mourne District Council',
      },
      {
        name: 'Newry, Mourne and Down',
        value: 'Newry, Mourne and Down',
      },
      {
        name: 'Newtownabbey Borough Council',
        value: 'Newtownabbey Borough Council',
      },
      {
        name: 'Norfolk',
        value: 'Norfolk',
      },
      {
        name: 'North Ayrshire',
        value: 'North Ayrshire',
      },
      {
        name: 'North Down Borough Council',
        value: 'North Down Borough Council',
      },
      {
        name: 'North East Lincolnshire',
        value: 'North East Lincolnshire',
      },
      {
        name: 'North Lanarkshire',
        value: 'North Lanarkshire',
      },
      {
        name: 'North Lincolnshire',
        value: 'North Lincolnshire',
      },
      {
        name: 'North Somerset',
        value: 'North Somerset',
      },
      {
        name: 'North Tyneside',
        value: 'North Tyneside',
      },
      {
        name: 'North Yorkshire',
        value: 'North Yorkshire',
      },
      {
        name: 'Northamptonshire',
        value: 'Northamptonshire',
      },
      {
        name: 'Northern Ireland',
        value: 'Northern Ireland',
      },
      {
        name: 'Northumberland',
        value: 'Northumberland',
      },
      {
        name: 'Nottinghamshire',
        value: 'Nottinghamshire',
      },
      {
        name: 'Oldham',
        value: 'Oldham',
      },
      {
        name: 'Omagh District Council',
        value: 'Omagh District Council',
      },
      {
        name: 'Orkney Islands',
        value: 'Orkney Islands',
      },
      {
        name: 'Outer Hebrides',
        value: 'Outer Hebrides',
      },
      {
        name: 'Oxfordshire',
        value: 'Oxfordshire',
      },
      {
        name: 'Pembrokeshire',
        value: 'Pembrokeshire',
      },
      {
        name: 'Perth and Kinross',
        value: 'Perth and Kinross',
      },
      {
        name: 'Poole',
        value: 'Poole',
      },
      {
        name: 'Powys',
        value: 'Powys',
      },
      {
        name: 'Reading',
        value: 'Reading',
      },
      {
        name: 'Redcar and Cleveland',
        value: 'Redcar and Cleveland',
      },
      {
        name: 'Renfrewshire',
        value: 'Renfrewshire',
      },
      {
        name: 'Rhondda Cynon Taf',
        value: 'Rhondda Cynon Taf',
      },
      {
        name: 'Rochdale',
        value: 'Rochdale',
      },
      {
        name: 'Rotherham',
        value: 'Rotherham',
      },
      {
        name: 'Royal Borough of Greenwich',
        value: 'Royal Borough of Greenwich',
      },
      {
        name: 'Royal Borough of Kensington and Chelsea',
        value: 'Royal Borough of Kensington and Chelsea',
      },
      {
        name: 'Royal Borough of Kingston upon Thames',
        value: 'Royal Borough of Kingston upon Thames',
      },
      {
        name: 'Rutland',
        value: 'Rutland',
      },
      {
        name: 'Saint Helena',
        value: 'Saint Helena',
      },
      {
        name: 'Salford',
        value: 'Salford',
      },
      {
        name: 'Sandwell',
        value: 'Sandwell',
      },
      {
        name: 'Scotland',
        value: 'Scotland',
      },
      {
        name: 'Scottish Borders',
        value: 'Scottish Borders',
      },
      {
        name: 'Sefton',
        value: 'Sefton',
      },
      {
        name: 'Sheffield',
        value: 'Sheffield',
      },
      {
        name: 'Shetland Islands',
        value: 'Shetland Islands',
      },
      {
        name: 'Shropshire',
        value: 'Shropshire',
      },
      {
        name: 'Slough',
        value: 'Slough',
      },
      {
        name: 'Solihull',
        value: 'Solihull',
      },
      {
        name: 'Somerset',
        value: 'Somerset',
      },
      {
        name: 'South Ayrshire',
        value: 'South Ayrshire',
      },
      {
        name: 'South Gloucestershire',
        value: 'South Gloucestershire',
      },
      {
        name: 'South Lanarkshire',
        value: 'South Lanarkshire',
      },
      {
        name: 'South Tyneside',
        value: 'South Tyneside',
      },
      {
        name: 'Southend-on-Sea',
        value: 'Southend-on-Sea',
      },
      {
        name: 'St Helens',
        value: 'St Helens',
      },
      {
        name: 'Staffordshire',
        value: 'Staffordshire',
      },
      {
        name: 'Stirling',
        value: 'Stirling',
      },
      {
        name: 'Stockport',
        value: 'Stockport',
      },
      {
        name: 'Stockton-on-Tees',
        value: 'Stockton-on-Tees',
      },
      {
        name: 'Strabane District Council',
        value: 'Strabane District Council',
      },
      {
        name: 'Suffolk',
        value: 'Suffolk',
      },
      {
        name: 'Surrey',
        value: 'Surrey',
      },
      {
        name: 'Swindon',
        value: 'Swindon',
      },
      {
        name: 'Tameside',
        value: 'Tameside',
      },
      {
        name: 'Telford and Wrekin',
        value: 'Telford and Wrekin',
      },
      {
        name: 'Thurrock',
        value: 'Thurrock',
      },
      {
        name: 'Torbay',
        value: 'Torbay',
      },
      {
        name: 'Torfaen',
        value: 'Torfaen',
      },
      {
        name: 'Trafford',
        value: 'Trafford',
      },
      {
        name: 'United Kingdom',
        value: 'United Kingdom',
      },
      {
        name: 'Vale of Glamorgan',
        value: 'Vale of Glamorgan',
      },
      {
        name: 'Wakefield',
        value: 'Wakefield',
      },
      {
        name: 'Wales',
        value: 'Wales',
      },
      {
        name: 'Walsall',
        value: 'Walsall',
      },
      {
        name: 'Warrington',
        value: 'Warrington',
      },
      {
        name: 'Warwickshire',
        value: 'Warwickshire',
      },
      {
        name: 'West Berkshire',
        value: 'West Berkshire',
      },
      {
        name: 'West Dunbartonshire',
        value: 'West Dunbartonshire',
      },
      {
        name: 'West Lothian',
        value: 'West Lothian',
      },
      {
        name: 'West Sussex',
        value: 'West Sussex',
      },
      {
        name: 'Wiltshire',
        value: 'Wiltshire',
      },
      {
        name: 'Windsor and Maidenhead',
        value: 'Windsor and Maidenhead',
      },
      {
        name: 'Wirral',
        value: 'Wirral',
      },
      {
        name: 'Wokingham',
        value: 'Wokingham',
      },
      {
        name: 'Worcestershire',
        value: 'Worcestershire',
      },
      {
        name: 'Wrexham County Borough',
        value: 'Wrexham County Borough',
      },
    ],
  },
  US: {
    name: 'United States',
    type: 'state',
    states: [
      {
        name: 'Alabama',
        value: 'Alabama',
      },
      {
        name: 'Alaska',
        value: 'Alaska',
      },
      {
        name: 'Arizona',
        value: 'Arizona',
      },
      {
        name: 'Arkansas',
        value: 'Arkansas',
      },
      {
        name: 'California',
        value: 'California',
      },
      {
        name: 'Colorado',
        value: 'Colorado',
      },
      {
        name: 'Connecticut',
        value: 'Connecticut',
      },
      {
        name: 'Delaware',
        value: 'Delaware',
      },
      {
        name: 'Florida',
        value: 'Florida',
      },
      {
        name: 'Georgia',
        value: 'Georgia',
      },
      {
        name: 'Hawaii',
        value: 'Hawaii',
      },
      {
        name: 'Idaho',
        value: 'Idaho',
      },
      {
        name: 'Illinois',
        value: 'Illinois',
      },
      {
        name: 'Indiana',
        value: 'Indiana',
      },
      {
        name: 'Iowa',
        value: 'Iowa',
      },
      {
        name: 'Kansas',
        value: 'Kansas',
      },
      {
        name: 'Kentucky',
        value: 'Kentucky',
      },
      {
        name: 'Louisiana',
        value: 'Louisiana',
      },
      {
        name: 'Maine',
        value: 'Maine',
      },
      {
        name: 'Maryland',
        value: 'Maryland',
      },
      {
        name: 'Massachusetts',
        value: 'Massachusetts',
      },
      {
        name: 'Michigan',
        value: 'Michigan',
      },
      {
        name: 'Minnesota',
        value: 'Minnesota',
      },
      {
        name: 'Mississippi',
        value: 'Mississippi',
      },
      {
        name: 'Missouri',
        value: 'Missouri',
      },
      {
        name: 'Montana',
        value: 'Montana',
      },
      {
        name: 'Nebraska',
        value: 'Nebraska',
      },
      {
        name: 'Nevada',
        value: 'Nevada',
      },
      {
        name: 'New Hampshire',
        value: 'New Hampshire',
      },
      {
        name: 'New Jersey',
        value: 'New Jersey',
      },
      {
        name: 'New Mexico',
        value: 'New Mexico',
      },
      {
        name: 'New York',
        value: 'New York',
      },
      {
        name: 'North Carolina',
        value: 'North Carolina',
      },
      {
        name: 'North Dakota',
        value: 'North Dakota',
      },
      {
        name: 'Ohio',
        value: 'Ohio',
      },
      {
        name: 'Oklahoma',
        value: 'Oklahoma',
      },
      {
        name: 'Oregon',
        value: 'Oregon',
      },
      {
        name: 'Pennsylvania',
        value: 'Pennsylvania',
      },
      {
        name: 'Rhode Island',
        value: 'Rhode Island',
      },
      {
        name: 'South Carolina',
        value: 'South Carolina',
      },
      {
        name: 'South Dakota',
        value: 'South Dakota',
      },
      {
        name: 'Tennessee',
        value: 'Tennessee',
      },
      {
        name: 'Texas',
        value: 'Texas',
      },
      {
        name: 'Utah',
        value: 'Utah',
      },
      {
        name: 'Vermont',
        value: 'Vermont',
      },
      {
        name: 'Virginia',
        value: 'Virginia',
      },
      {
        name: 'Washington',
        value: 'Washington',
      },
      {
        name: 'West Virginia',
        value: 'West Virginia',
      },
      {
        name: 'Wisconsin',
        value: 'Wisconsin',
      },
      {
        name: 'Wyoming',
        value: 'Wyoming',
      },
    ],
  },
  AS: {
    name: 'American Samoa',
    type: 'outlying area',
    states: [
      {
        name: 'American Samoa',
        value: 'American Samoa',
      },
    ],
  },
  'UM-81': {
    name: 'Baker Island',
    type: 'islands / groups of islands',
    states: [
      {
        name: 'Baker Island',
        value: 'Baker Island',
      },
    ],
  },
  DC: {
    name: 'District of Columbia',
    type: 'district',
    states: [
      {
        name: 'District of Columbia',
        value: 'District of Columbia',
      },
    ],
  },
  GU: {
    name: 'Guam',
    type: 'outlying area',
    states: [
      {
        name: 'Guam',
        value: 'Guam',
      },
    ],
  },
  'UM-84': {
    name: 'Howland Island',
    type: 'islands / groups of islands',
    states: [
      {
        name: 'Howland Island',
        value: 'Howland Island',
      },
    ],
  },
  'UM-86': {
    name: 'Jarvis Island',
    type: 'islands / groups of islands',
    states: [
      {
        name: 'Jarvis Island',
        value: 'Jarvis Island',
      },
    ],
  },
  'UM-67': {
    name: 'Johnston Atoll',
    type: 'islands / groups of islands',
    states: [
      {
        name: 'Johnston Atoll',
        value: 'Johnston Atoll',
      },
    ],
  },
  'UM-89': {
    name: 'Kingman Reef',
    type: 'islands / groups of islands',
    states: [
      {
        name: 'Kingman Reef',
        value: 'Kingman Reef',
      },
    ],
  },
  'UM-71': {
    name: 'Midway Atoll',
    type: 'islands / groups of islands',
    states: [
      {
        name: 'Midway Atoll',
        value: 'Midway Atoll',
      },
    ],
  },
  'UM-76': {
    name: 'Navassa Island',
    type: 'islands / groups of islands',
    states: [
      {
        name: 'Navassa Island',
        value: 'Navassa Island',
      },
    ],
  },
  MP: {
    name: 'Northern Mariana Islands',
    type: 'outlying area',
    states: [
      {
        name: 'Northern Mariana Islands',
        value: 'Northern Mariana Islands',
      },
    ],
  },
  'UM-95': {
    name: 'Palmyra Atoll',
    type: 'islands / groups of islands',
    states: [
      {
        name: 'Palmyra Atoll',
        value: 'Palmyra Atoll',
      },
    ],
  },
  UM: {
    name: 'United States Minor Outlying Islands',
    type: 'outlying area',
    states: [
      {
        name: 'United States Minor Outlying Islands',
        value: 'United States Minor Outlying Islands',
      },
      {
        name: 'Baker Island',
        value: 'Baker Island',
      },
      {
        name: 'Howland Island',
        value: 'Howland Island',
      },
      {
        name: 'Jarvis Island',
        value: 'Jarvis Island',
      },
      {
        name: 'Johnston Atoll',
        value: 'Johnston Atoll',
      },
      {
        name: 'Kingman Reef',
        value: 'Kingman Reef',
      },
      {
        name: 'Midway Islands',
        value: 'Midway Islands',
      },
      {
        name: 'Navassa Island',
        value: 'Navassa Island',
      },
      {
        name: 'Palmyra Atoll',
        value: 'Palmyra Atoll',
      },
      {
        name: 'Wake Island',
        value: 'Wake Island',
      },
    ],
  },
  VI: {
    name: 'United States Virgin Islands',
    type: 'outlying area',
    states: [
      {
        name: 'United States Virgin Islands',
        value: 'United States Virgin Islands',
      },
      {
        name: 'Saint Croix',
        value: 'Saint Croix',
      },
      {
        name: 'Saint John',
        value: 'Saint John',
      },
      {
        name: 'Saint Thomas',
        value: 'Saint Thomas',
      },
    ],
  },
  'UM-79': {
    name: 'Wake Island',
    type: 'islands / groups of islands',
    states: [
      {
        name: 'Wake Island',
        value: 'Wake Island',
      },
    ],
  },
  UY: {
    name: 'Uruguay',
    type: null,
    states: [
      {
        name: 'Artigas',
        value: 'Artigas',
      },
      {
        name: 'Canelones',
        value: 'Canelones',
      },
      {
        name: 'Cerro Largo',
        value: 'Cerro Largo',
      },
      {
        name: 'Colonia',
        value: 'Colonia',
      },
      {
        name: 'Durazno',
        value: 'Durazno',
      },
      {
        name: 'Flores',
        value: 'Flores',
      },
      {
        name: 'Florida',
        value: 'Florida',
      },
      {
        name: 'Lavalleja',
        value: 'Lavalleja',
      },
      {
        name: 'Maldonado',
        value: 'Maldonado',
      },
      {
        name: 'Montevideo',
        value: 'Montevideo',
      },
      {
        name: 'Paysandú',
        value: 'Paysandú',
      },
      {
        name: 'Río Negro',
        value: 'Río Negro',
      },
      {
        name: 'Rivera',
        value: 'Rivera',
      },
      {
        name: 'Rocha',
        value: 'Rocha',
      },
      {
        name: 'Salto',
        value: 'Salto',
      },
      {
        name: 'San José',
        value: 'San José',
      },
      {
        name: 'Soriano',
        value: 'Soriano',
      },
      {
        name: 'Tacuarembó',
        value: 'Tacuarembó',
      },
      {
        name: 'Treinta y Tres',
        value: 'Treinta y Tres',
      },
    ],
  },
  UZ: {
    name: 'Uzbekistan',
    type: null,
    states: [
      {
        name: 'Andijan Region',
        value: 'Andijan Region',
      },
      {
        name: 'Bukhara Region',
        value: 'Bukhara Region',
      },
      {
        name: 'Fergana Region',
        value: 'Fergana Region',
      },
      {
        name: 'Jizzakh Region',
        value: 'Jizzakh Region',
      },
      {
        name: 'Karakalpakstan',
        value: 'Karakalpakstan',
      },
      {
        name: 'Namangan Region',
        value: 'Namangan Region',
      },
      {
        name: 'Navoiy Region',
        value: 'Navoiy Region',
      },
      {
        name: 'Qashqadaryo Region',
        value: 'Qashqadaryo Region',
      },
      {
        name: 'Samarqand Region',
        value: 'Samarqand Region',
      },
      {
        name: 'Sirdaryo Region',
        value: 'Sirdaryo Region',
      },
      {
        name: 'Surxondaryo Region',
        value: 'Surxondaryo Region',
      },
      {
        name: 'Tashkent',
        value: 'Tashkent',
      },
      {
        name: 'Tashkent Region',
        value: 'Tashkent Region',
      },
      {
        name: 'Xorazm Region',
        value: 'Xorazm Region',
      },
    ],
  },
  VU: {
    name: 'Vanuatu',
    type: null,
    states: [
      {
        name: 'Malampa',
        value: 'Malampa',
      },
      {
        name: 'Penama',
        value: 'Penama',
      },
      {
        name: 'Sanma',
        value: 'Sanma',
      },
      {
        name: 'Shefa',
        value: 'Shefa',
      },
      {
        name: 'Tafea',
        value: 'Tafea',
      },
      {
        name: 'Torba',
        value: 'Torba',
      },
    ],
  },
  VE: {
    name: 'Venezuela',
    type: 'state',
    states: [
      {
        name: 'Amazonas',
        value: 'Amazonas',
      },
      {
        name: 'Anzoátegui',
        value: 'Anzoátegui',
      },
      {
        name: 'Apure',
        value: 'Apure',
      },
      {
        name: 'Aragua',
        value: 'Aragua',
      },
      {
        name: 'Barinas',
        value: 'Barinas',
      },
      {
        name: 'Bolívar',
        value: 'Bolívar',
      },
      {
        name: 'Carabobo',
        value: 'Carabobo',
      },
      {
        name: 'Cojedes',
        value: 'Cojedes',
      },
      {
        name: 'Delta Amacuro',
        value: 'Delta Amacuro',
      },
      {
        name: 'Distrito Capital',
        value: 'Distrito Capital',
      },
      {
        name: 'Falcón',
        value: 'Falcón',
      },
      {
        name: 'Federal Dependencies of Venezuela',
        value: 'Federal Dependencies of Venezuela',
      },
      {
        name: 'Guárico',
        value: 'Guárico',
      },
      {
        name: 'La Guaira',
        value: 'La Guaira',
      },
      {
        name: 'Lara',
        value: 'Lara',
      },
      {
        name: 'Mérida',
        value: 'Mérida',
      },
      {
        name: 'Miranda',
        value: 'Miranda',
      },
      {
        name: 'Monagas',
        value: 'Monagas',
      },
      {
        name: 'Nueva Esparta',
        value: 'Nueva Esparta',
      },
      {
        name: 'Portuguesa',
        value: 'Portuguesa',
      },
      {
        name: 'Sucre',
        value: 'Sucre',
      },
      {
        name: 'Táchira',
        value: 'Táchira',
      },
      {
        name: 'Trujillo',
        value: 'Trujillo',
      },
      {
        name: 'Yaracuy',
        value: 'Yaracuy',
      },
      {
        name: 'Zulia',
        value: 'Zulia',
      },
    ],
  },
  VN: {
    name: 'Vietnam',
    type: null,
    states: [
      {
        name: 'An Giang',
        value: 'An Giang',
      },
      {
        name: 'Bà Rịa-Vũng Tàu',
        value: 'Bà Rịa-Vũng Tàu',
      },
      {
        name: 'Bắc Giang',
        value: 'Bắc Giang',
      },
      {
        name: 'Bắc Kạn',
        value: 'Bắc Kạn',
      },
      {
        name: 'Bạc Liêu',
        value: 'Bạc Liêu',
      },
      {
        name: 'Bắc Ninh',
        value: 'Bắc Ninh',
      },
      {
        name: 'Bến Tre',
        value: 'Bến Tre',
      },
      {
        name: 'Bình Dương',
        value: 'Bình Dương',
      },
      {
        name: 'Bình Định',
        value: 'Bình Định',
      },
      {
        name: 'Bình Phước',
        value: 'Bình Phước',
      },
      {
        name: 'Bình Thuận',
        value: 'Bình Thuận',
      },
      {
        name: 'Cà Mau',
        value: 'Cà Mau',
      },
      {
        name: 'Cần Thơ',
        value: 'Cần Thơ',
      },
      {
        name: 'Cao Bằng',
        value: 'Cao Bằng',
      },
      {
        name: 'Đà Nẵng',
        value: 'Đà Nẵng',
      },
      {
        name: 'Đắk Lắk',
        value: 'Đắk Lắk',
      },
      {
        name: 'Đắk Nông',
        value: 'Đắk Nông',
      },
      {
        name: 'Điện Biên',
        value: 'Điện Biên',
      },
      {
        name: 'Đồng Nai',
        value: 'Đồng Nai',
      },
      {
        name: 'Đồng Tháp',
        value: 'Đồng Tháp',
      },
      {
        name: 'Gia Lai',
        value: 'Gia Lai',
      },
      {
        name: 'Hà Giang',
        value: 'Hà Giang',
      },
      {
        name: 'Hà Nam',
        value: 'Hà Nam',
      },
      {
        name: 'Hà Nội',
        value: 'Hà Nội',
      },
      {
        name: 'Hà Tĩnh',
        value: 'Hà Tĩnh',
      },
      {
        name: 'Hải Dương',
        value: 'Hải Dương',
      },
      {
        name: 'Hải Phòng',
        value: 'Hải Phòng',
      },
      {
        name: 'Hậu Giang',
        value: 'Hậu Giang',
      },
      {
        name: 'Hồ Chí Minh',
        value: 'Hồ Chí Minh',
      },
      {
        name: 'Hòa Bình',
        value: 'Hòa Bình',
      },
      {
        name: 'Hưng Yên',
        value: 'Hưng Yên',
      },
      {
        name: 'Khánh Hòa',
        value: 'Khánh Hòa',
      },
      {
        name: 'Kiên Giang',
        value: 'Kiên Giang',
      },
      {
        name: 'Kon Tum',
        value: 'Kon Tum',
      },
      {
        name: 'Lai Châu',
        value: 'Lai Châu',
      },
      {
        name: 'Lâm Đồng',
        value: 'Lâm Đồng',
      },
      {
        name: 'Lạng Sơn',
        value: 'Lạng Sơn',
      },
      {
        name: 'Lào Cai',
        value: 'Lào Cai',
      },
      {
        name: 'Long An',
        value: 'Long An',
      },
      {
        name: 'Nam Định',
        value: 'Nam Định',
      },
      {
        name: 'Nghệ An',
        value: 'Nghệ An',
      },
      {
        name: 'Ninh Bình',
        value: 'Ninh Bình',
      },
      {
        name: 'Ninh Thuận',
        value: 'Ninh Thuận',
      },
      {
        name: 'Phú Thọ',
        value: 'Phú Thọ',
      },
      {
        name: 'Phú Yên',
        value: 'Phú Yên',
      },
      {
        name: 'Quảng Bình',
        value: 'Quảng Bình',
      },
      {
        name: 'Quảng Nam',
        value: 'Quảng Nam',
      },
      {
        name: 'Quảng Ngãi',
        value: 'Quảng Ngãi',
      },
      {
        name: 'Quảng Ninh',
        value: 'Quảng Ninh',
      },
      {
        name: 'Quảng Trị',
        value: 'Quảng Trị',
      },
      {
        name: 'Sóc Trăng',
        value: 'Sóc Trăng',
      },
      {
        name: 'Sơn La',
        value: 'Sơn La',
      },
      {
        name: 'Tây Ninh',
        value: 'Tây Ninh',
      },
      {
        name: 'Thái Bình',
        value: 'Thái Bình',
      },
      {
        name: 'Thái Nguyên',
        value: 'Thái Nguyên',
      },
      {
        name: 'Thanh Hóa',
        value: 'Thanh Hóa',
      },
      {
        name: 'Thừa Thiên-Huế',
        value: 'Thừa Thiên-Huế',
      },
      {
        name: 'Tiền Giang',
        value: 'Tiền Giang',
      },
      {
        name: 'Trà Vinh',
        value: 'Trà Vinh',
      },
      {
        name: 'Tuyên Quang',
        value: 'Tuyên Quang',
      },
      {
        name: 'Vĩnh Long',
        value: 'Vĩnh Long',
      },
      {
        name: 'Vĩnh Phúc',
        value: 'Vĩnh Phúc',
      },
      {
        name: 'Yên Bái',
        value: 'Yên Bái',
      },
    ],
  },
  YE: {
    name: 'Yemen',
    type: null,
    states: [
      {
        name: "'Adan",
        value: "'Adan",
      },
      {
        name: "'Amran",
        value: "'Amran",
      },
      {
        name: 'Abyan',
        value: 'Abyan',
      },
      {
        name: "Al Bayda'",
        value: "Al Bayda'",
      },
      {
        name: 'Al Hudaydah',
        value: 'Al Hudaydah',
      },
      {
        name: 'Al Jawf',
        value: 'Al Jawf',
      },
      {
        name: 'Al Mahrah',
        value: 'Al Mahrah',
      },
      {
        name: 'Al Mahwit',
        value: 'Al Mahwit',
      },
      {
        name: 'Amanat Al Asimah',
        value: 'Amanat Al Asimah',
      },
      {
        name: 'Dhamar',
        value: 'Dhamar',
      },
      {
        name: 'Hadhramaut',
        value: 'Hadhramaut',
      },
      {
        name: 'Hajjah',
        value: 'Hajjah',
      },
      {
        name: 'Ibb',
        value: 'Ibb',
      },
      {
        name: 'Lahij',
        value: 'Lahij',
      },
      {
        name: "Ma'rib",
        value: "Ma'rib",
      },
      {
        name: 'Raymah',
        value: 'Raymah',
      },
      {
        name: 'Saada',
        value: 'Saada',
      },
      {
        name: "Sana'a",
        value: "Sana'a",
      },
      {
        name: 'Shabwah',
        value: 'Shabwah',
      },
      {
        name: 'Socotra',
        value: 'Socotra',
      },
      {
        name: "Ta'izz",
        value: "Ta'izz",
      },
    ],
  },
  ZM: {
    name: 'Zambia',
    type: null,
    states: [
      {
        name: 'Central Province',
        value: 'Central Province',
      },
      {
        name: 'Copperbelt Province',
        value: 'Copperbelt Province',
      },
      {
        name: 'Eastern Province',
        value: 'Eastern Province',
      },
      {
        name: 'Luapula Province',
        value: 'Luapula Province',
      },
      {
        name: 'Lusaka Province',
        value: 'Lusaka Province',
      },
      {
        name: 'Muchinga Province',
        value: 'Muchinga Province',
      },
      {
        name: 'Northern Province',
        value: 'Northern Province',
      },
      {
        name: 'Northwestern Province',
        value: 'Northwestern Province',
      },
      {
        name: 'Southern Province',
        value: 'Southern Province',
      },
      {
        name: 'Western Province',
        value: 'Western Province',
      },
    ],
  },
  ZW: {
    name: 'Zimbabwe',
    type: null,
    states: [
      {
        name: 'Bulawayo Province',
        value: 'Bulawayo Province',
      },
      {
        name: 'Harare Province',
        value: 'Harare Province',
      },
      {
        name: 'Manicaland',
        value: 'Manicaland',
      },
      {
        name: 'Mashonaland Central Province',
        value: 'Mashonaland Central Province',
      },
      {
        name: 'Mashonaland East Province',
        value: 'Mashonaland East Province',
      },
      {
        name: 'Mashonaland West Province',
        value: 'Mashonaland West Province',
      },
      {
        name: 'Masvingo Province',
        value: 'Masvingo Province',
      },
      {
        name: 'Matabeleland North Province',
        value: 'Matabeleland North Province',
      },
      {
        name: 'Matabeleland South Province',
        value: 'Matabeleland South Province',
      },
      {
        name: 'Midlands Province',
        value: 'Midlands Province',
      },
    ],
  },
};
export default StateList;
