import React, { useEffect } from 'react';
import Layout from '../Components/Layout';
import HeroSectionF from '../Components/Features/HeroSectionF';
import AllFeatures from '../Components/Features/AllFeatures';
import TitleDescWithKeyword from '../Components/Helmet/TitleDescWithKeyword';

const Features = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TitleDescWithKeyword
        title="Features | DCDial"
        description="Optimize your business with DCDial's communication tools, automation, CRM integration, and secure payment solutions."
        keywords="Cutting-edge dialer technology, Predictive dialer solutions,Power dialer tools, Preview manual dialer, Voice broadcast software, Click-to-dial feature, Campaign management tools, Two-way text messaging, SMS and email broadcast campaigns, Inbound call routing, Call recording and monitoring, Interactive voice response (IVR), Automatic call distribution (ACD), Cloud-based agent interface, CRM integration with DCDial, API integration for contact management, AI-powered workflow automation, Automated call transcription, Secure payment processing integration, Customizable reporting and dashboards"
        isPageURL="https://dcdial.com/features"
      />
      <Layout>
        <HeroSectionF />
        <AllFeatures />
      </Layout>
    </>
  );
};

export default Features;
