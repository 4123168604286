import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ExploreDcDial = ({ itemRefs }) => {
  const { t } = useTranslation();

  const [discoverList1, setDiscoverList1] = useState(
    getTranslatedList(t, 'list1')
  );
  const [discoverList2, setDiscoverList2] = useState(
    getTranslatedList(t, 'list2')
  );

  useEffect(() => {
    // Update lists when language changes
    setDiscoverList1(getTranslatedList(t, 'list1'));
    setDiscoverList2(getTranslatedList(t, 'list2'));
  }, [t]);
  return (
    <>
      <div className="w-[100%] mt-[150px] max_xl:mt-[100px] max_md:hidden max_sm:hidden">
        <div id="work-container">
          <div className="discover-text">
            <div id="featured-work-text" className="text-content">
              <div id="firstDiv" className="w-[90%]">
                <h2 className="headingText">{t('ExploreDCDial')}</h2>
                <div className="paragraphText mt-4">
                  {t('ExploreDCDialText')}
                </div>
              </div>
            </div>
            <div>
              <div className={`w-[100%] flex gap-5`}>
                <div className={` flex flex-col gap-5`}>
                  {discoverList1?.map((item1, index1) => {
                    return (
                      <React.Fragment key={index1 + 1}>
                        <div
                          className={`mainCard 
                          bg-BgPrimary rounded-[10px] max-w-[250px] max_sm:max-w-[220px] p-5`}
                          ref={(el) => (itemRefs.current.left[index1] = el)}
                        >
                          <div className="subHeadingText">{item1.heading}</div>
                          <div
                            className="tracking-[-1px] leading-[30px] subParagraphText mt-5 max_esm:mt-3"
                          >
                            {item1.text}
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
                <div className="flex flex-col gap-5 mt-[70px] max_md:mt-0">
                  {discoverList2.map((item2, index2) => {
                    return (
                      <React.Fragment key={index2 + 1}>
                        <div
                          className={`mainCard 
                           bg-BgPrimary rounded-[10px] max-w-[250px] max_sm:max-w-[220px] p-5`}
                          ref={(el) => (itemRefs.current.right[index2] = el)}
                        >
                          <div className="subHeadingText">{item2.heading}</div>
                          <div className="tracking-[-1px] leading-[30px] subParagraphText mt-5 max_esm:mt-3 ">
                            {item2.text}
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* MOBILE DESIGN */}
      <div
        id="discover-section"
        className="hidden max_md:block m-auto max_md:px-10 max_sm:px-5 pb-[100px] max_md:pb-[60px] max_sm:pb-0 mt-[100px] max_sm:mt-[60px]"
      >
        <div className="flex justify-between flex-col items-center">
          <div id="firstDiv" className="text-center">
            <div className="headingText">{t('ExploreDCDial')}</div>
            <div className="paragraphText mt-4">{t('ExploreDCDialText')}</div>
          </div>
          <div
            className={`flex gap-5 w-[100%] justify-center mt-10 h-auto max_sm:flex-col max_sm:items-center`}
          >
            <div className={` flex flex-col gap-5`}>
              {discoverList1?.map((item3, index3) => {
                return (
                  <React.Fragment key={index3 + 1}>
                    <div
                      className={`mainCardRes  bg-[#EEF3FC] rounded-[10px] max-w-[250px] max_sm:max-w-[250px] p-5`}
                      ref={(el) => (itemRefs.current.leftRes[index3] = el)}
                    >
                      <div className="subHeadingText">{item3.heading}</div>
                      <div className="tracking-[-1px] leading-[30px] max_sm:leading-6 subParagraphText mt-5 max_esm:mt-3">
                        {item3.text}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            <div
              className="flex flex-col gap-5 mt-16 max_md:mt-0"
              // ref={cardRef2}
            >
              {discoverList2.map((item4, index4) => {
                return (
                  <React.Fragment key={index4 + 1}>
                    <div
                      className={`mainCardRes bg-[#EEF3FC] rounded-[10px] max-w-[250px] max_sm:max-w-[250px] p-5`}
                      ref={(el) => (itemRefs.current.rightRes[index4] = el)}
                    >
                      <div className="subHeadingText">{item4.heading}</div>
                      <div className="tracking-[-1px] leading-[30px] max_sm:leading-6 subParagraphText mt-5 max_esm:mt-3 ">
                        {item4.text}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const getTranslatedList = (t, listKey) => {
  // Define your lists here
  const lists = {
    list1: [
      {
        heading: t('TransparentPricing'),
        text: t('TransparentText'),
      },
      {
        heading: t('EasyIntegration'),
        text: t('EasyIntegrationText'),
      },
      {
        heading: t('Customization'),
        text: t('CustomizationText'),
      },
      {
        heading: t('Security'),
        text: t('SecurityText'),
      },
    ],
    list2: [
      {
        heading: t('ReliableSupport'),
        text: t('ReliableSupportText'),
      },
      {
        heading: t('IndustryTrust'),
        text: t('IndustryTrustText'),
      },
      {
        heading: t('DataInsights'),
        text: t('DataInsightsText'),
      },
      {
        heading: t('DataPortability'),
        text: t('DataPortabilityText'),
      },
    ],
  };

  return lists[listKey];
};

export default ExploreDcDial;
