import React, { useEffect, useRef, useState } from 'react';
import Layout from '../Components/Layout';
import Banner from '../Components/HomePage/Banner';
import Potential from '../Components/HomePage/Potential';
import Feature from '../Components/HomePage/Feature';
import HeroSectionBg from '../Components/HomePage/HeroSectionBg';
import ExploreDcDial from '../Components/HomePage/ExploreDcDial';
import CompanyInfo from '../Components/HomePage/CompanyInfo';
import CustomerReviews from '../Components/HomePage/CustomerReviews';
import DCDialGuidance from '../Components/HomePage/DCDialGuidance';
import FAQ from '../Components/HomePage/FAQComponent';
import ContinueWithDC from '../Components/HomePage/ContinueWithDC';
import { useTranslation } from 'react-i18next';
import TitleAndDesc from '../Components/Helmet/TitleAndDesc';

const Home = () => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);

  const [FAQList, setFAQList] = useState([]);
  useEffect(() => {
    // Update lists when language changes
    setFAQList(getTranslatedList(t));
  }, [t]);

  const itemRefs = useRef({
    left: [],
    right: [],
    leftRes: [],
    rightRes: [],
  });

  // Function to handle the intersection changes
  function handleIntersection(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Add the "flip" class to the observed item
        entry.target.classList.add('flip');
        // Stop observing it if you don't need further animations
        observer.unobserve(entry.target);
      }
    });
  }

  useEffect(() => {
    const options = { root: null, rootMargin: '0px', threshold: 0.5 };
    const observer = new IntersectionObserver(handleIntersection, options);

    Object.values(itemRefs.current)
      .flat()
      .forEach((ref) => {
        if (ref) observer.observe(ref);
      });

    return () => observer.disconnect();
  }, [handleIntersection]);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  useEffect(() => {
    localStorage.removeItem('scrollPositionT');
    localStorage.removeItem('scrollPositionS');
    localStorage.removeItem('scrollPositionDPA');
    localStorage.removeItem('scrollPositionA');
    localStorage.removeItem('scrollPositionD');
    localStorage.removeItem('scrollPositionDT');
    localStorage.removeItem('scrollPositionWT');
    localStorage.removeItem('scrollPositionWA');
    localStorage.removeItem('scrollPositionPP');
    localStorage.removeItem('scrollPositionCP');
    localStorage.removeItem('scrollPositionSD');
    localStorage.removeItem('appsCount');
  }, []);

  return (
    <>
      <TitleAndDesc
        title="DCDial | Communication And Payment Solutions For Businesses"
        description="Maximize efficiency with DCDial's all-in-one solution for customer communication and payment management."
        isImageURL="https://images.dcdial.com/dcdial.com/v2/AccelerateGrowth.svg"
        isPageURL="https://dcdial.com/"
      />
      <div className="relative w-[100%]">
        <Layout toggleModal={toggleModal}>
          <div className="h-fit w-full F-JC-AI-CENTER">
            <HeroSectionBg>
              <Banner />
            </HeroSectionBg>
          </div>

          <Potential />

          <Feature />

          <ExploreDcDial itemRefs={itemRefs} />

          <CompanyInfo />

          <CustomerReviews />

          <DCDialGuidance />

          <FAQ data={FAQList} />

          <ContinueWithDC
            title={t('LetsGo')}
            subTitle1={t('LetsGoText')}
            link="/pricing"
          />
        </Layout>
      </div>
    </>
  );
};
const getTranslatedList = (t) => {
  // Define your lists here
  const lists = [
    {
      question: t('WhatIsDCDial'),
      answer: t('WhatIsDCDialAns'),
    },
    {
      question: t('WhoWillBenefit'),
      answer: t('WhoWillBenefitAns'),
    },
    {
      question: t('DoYouOffer'),
      answer: t('DoYouOfferAns'),
    },
    {
      question: t('CanIntegrate'),
      answer: t('CanIntegrateAns'),
    },
    {
      question: t('OnboardingAssitant'),
      answer: t('OnboardingAssitantAns'),
    },
  ];

  return lists;
};

export default Home;
