import React, { useEffect } from 'react';
import Layout from '../Components/Layout';
import LegalSideLayout from '../Components/LegalSideLayout';
import TermsOfServiceContent from '../Components/Legal/TermsOfServiceContent';
import TitleAndDesc from '../Components/Helmet/TitleAndDesc';
import { useLocation } from 'react-router-dom';

const TermesOfService = () => {
  // var scrollPosition = localStorage.getItem('scrollPositionT');
  // if (scrollPosition !== null) {
  //   window.scrollTo(0, parseInt(scrollPosition));
  // }

  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    if (state && state !== null) {
      window.scrollTo(0, 0);
    }
  }, [state]);

  var scrollPosition = localStorage.getItem('scrollPositionT');
  if (scrollPosition !== null) {
    window.scrollTo(0, parseInt(scrollPosition));
  }

  return (
    <>
      <TitleAndDesc
        title="Customer Terms of Service"
        description="By using DCDial's services, you agree to our Customer Terms of Service, ensuring a fair and transparent relationship between you and DCDial."
      />
      <Layout>
        <LegalSideLayout>
          <TermsOfServiceContent />
        </LegalSideLayout>
      </Layout>
    </>
  );
};

export default TermesOfService;
