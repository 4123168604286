import React from 'react'

const CardContainer = ({ service, minHeight, NoMinHeight, isFixWidth }) => {
  return (
    <div className={`border-LightOutline border-[1px] rounded-[20px] p-[30px] max_xl:p-5 flex flex-col items-center  ${isFixWidth ? 'w-[360px]':''}`}>
      <div>
        {service?.icon}</div>
      <p
        className={`subHeadingText mt-6 text-center max_xl:min-h-[66px] max_md:min-h-0 leading-[33px] ${
          minHeight ? 'min-h-[66px]' : ''
        } ${NoMinHeight ? 'max_xl:min-h-0' : ''}`}
      >
        {service?.title}
      </p>
      <p className="subParagraphText font-normal mt-3 text-center">
        {service?.text}
      </p>
    </div>
  );
};

export default CardContainer