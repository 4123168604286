import React, { useEffect } from 'react';
import Layout from '../../Components/Layout';
import LegalSideLayout from '../../Components/LegalSideLayout';
import AcceptableUseContent from '../../Components/Legal/AcceptableUseContent';
import TitleAndDesc from '../../Components/Helmet/TitleAndDesc';
import { useLocation } from 'react-router-dom';

const AcceptableUsePolicy = () => {
   const location = useLocation();
   const state = location.state;
   useEffect(() => {
     if (state && state !== null) {
       window.scrollTo(0, 0);
     }
   }, [state]);

   var scrollPosition = localStorage.getItem('scrollPositionA');
   if (scrollPosition !== null) {
     window.scrollTo(0, parseInt(scrollPosition));
   } 
  return (
    <>
      <TitleAndDesc
        title="Acceptable Use Policy"
        description="Our Acceptable Use Policy sets the standards for acceptable behavior when using DCDial's services, promoting a safe and respectful environment for all users."
      />
      <Layout>
        <LegalSideLayout>
          <AcceptableUseContent />
        </LegalSideLayout>
      </Layout>
    </>
  );
};

export default AcceptableUsePolicy;
