import React, { useEffect, useState } from 'react';
import CommonSection from '../../CommonSection';
import TagsComp from '../../utils/TagsComp';
import { useTranslation } from 'react-i18next';
import {
  CloseCircleIcon,
  TickMarkGreenIcon,
} from '../../../assets/Icons/Security/SecurityIcon';
import CommonSizeContainer from '../../CommonSizeContainer';
import ChatbotImg from '../../../assets/images/Products/ChatbotGraphImg.svg';
import {
  Availability,
  DataInsightS,
  IncreasedRevenue,
  InstantResponses,
  Scalability,
  SentimentAnalysis,
} from '../../../assets/Icons/Products/ProductsIcon';
import CardContainer from '../CardContainer';
import { useWindowSize } from '../../../Hooks/useWindowSize';

const AITransformAndChatbot = () => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 500;
  const [withoutAI, setWithoutAI] = useState([]);
  const [withAI, setWithAI] = useState([]);
  const [services, setServices] = useState([]);
  const [predictiveData, setPredictiveData] = useState([]);
  useEffect(() => {
    setWithoutAI(getTranslatedList(t, 'Without_AI'));
    setWithAI(getTranslatedList(t, 'With_AI'));
    setServices(getTranslatedList(t, 'services'));
    setPredictiveData(getTranslatedList(t, 'predictive'));
  }, [t]);
  return (
    <>
      <div className="mt-[150px] max_xl:mt-[100px] max_sm:mt-[60px] bg-BgPrimary">
        <CommonSection>
          <div
            className="flex flex-col items-center py-[100px] max_md:py-[60px]"
            id="WorkAutomation"
          >
            <TagsComp
              px="16px"
              py="8px"
              text={t('WorkAutomationC')}
              color="#00A0FC"
              borderColor="#00A0FC"
            />
            <h2 className="mt-4 text50Product text-center">
              {t('WorkAutoTitle')}
            </h2>

            <div className="mt-[60px] max_sm:mt-10 flex justify-between gap-[60px] max_md:flex-col max_md:gap-10 max_sm:gap-5">
              <div className="bg-white rounded-[20px] p-[30px] max_sm:p-5 max-w-[540px] max_md:max-w-[420px]">
                <p className="subHeadingText max_md:text-subHeadingTxt max_sm:text-headingNormal ">
                  {t('WithoutAI')}
                </p>
                <div className="mt-5 flex flex-col gap-4">
                  {withoutAI.map((point, index1) => (
                    <div className="flex gap-2" key={index1}>
                      <div className="mt-[2px]">
                        <CloseCircleIcon />
                      </div>
                      <p className="subParagraphText font-normal">{point}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="bg-white rounded-[20px] p-[30px] max_sm:p-5 max-w-[540px] max_md:max-w-[420px]">
                <p className="subHeadingText max_md:text-subHeadingTxt max_sm:text-headingNormal ">
                  {t('WithAI')}
                </p>
                <div className="mt-5 flex flex-col gap-4">
                  {withAI.map((point, index2) => (
                    <div className="flex gap-2" key={index2}>
                      <div className="mt-[2px]">
                        <TickMarkGreenIcon />
                      </div>
                      <p className="subParagraphText font-normal">{point}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </CommonSection>
      </div>
      <CommonSizeContainer>
        <div id="Chatbots">
          <p className="text50Product">{t('Chatbots')}</p>
          <p className="paragraphText font-normal mt-4">{t('ChatbotsT')}</p>
          <div className="mt-10 flex justify-between items-center max_xl:gap-[30px] max_md:flex-col max_md:gap-[60px] max_sm:gap-10 max_md:items-start max_md:mt-0">
            <div className="relative max_xl:max-w-[420px] max_md:m-auto max_md:mt-[-20px]">
              <img src={ChatbotImg} alt="chatbot" />
              <p className="absolute top-[106px] text-end left-24 max_xl:left-14 text-textMedium font-DMSansFont font-normal text-ButtonPrimary tracking-[-1px] max_sm:text-[13px] max_sm:left-22 max_sm_380:left-8 max_sm_380:top-20 max_esm:left-0 max_esm:top-14">
                Up to 40% <br />
                productivity boost with AI
              </p>
            </div>
            <div className="flex flex-col gap-10 max-w-[500px] max_md:max-w-full">
              {services.map((details, index) => (
                <div className="flex gap-3" key={index}>
                  <div>
                    {React.cloneElement(details.icon, {
                      style: {
                        width: isMobileView ? 32 : 40,
                        height: isMobileView ? 32 : 40,
                      },
                    })}
                  </div>
                  <div>
                    <p className="subHeadingText">{details.name}</p>
                    <p className="smallText18 font-medium text-LightTextParagraph mt-3 max_sm:text-textSmall">
                      {details.text}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </CommonSizeContainer>

      <CommonSizeContainer>
        <h2 className="text50Product text-center" id="PredictiveEngagement">
          {t('PredictiveEngagement')}
        </h2>
        <p className="paragraphText font-normal mt-4 text-center">
          {t('PredictiveEngagementText')}
        </p>
        <div className="max_md:hidden max_720:block">
          <div className="grid grid-cols-3 gap-6 max_xl:gap-4 mt-[60px] max_600:mt-10 max_720:grid-cols-1">
            {predictiveData?.map((service, index1) => (
              <CardContainer key={index1} service={service} NoMinHeight />
            ))}
          </div>
        </div>
        <div className="hidden max_md:block max_720:hidden mt-[60px]">
          <div className="flex justify-center">
            <CardContainer service={predictiveData[0]} isFixWidth />
          </div>
          <div className="flex justify-center gap-[30px] mt-[30px]">
            <CardContainer service={predictiveData[1]} isFixWidth />
            <CardContainer service={predictiveData[2]} isFixWidth />
          </div>
        </div>
      </CommonSizeContainer>
    </>
  );
};
const getTranslatedList = (t, listKey) => {
  const lists = {
    Without_AI: [t('WithoutAI1'), t('WithoutAI2'), t('WithoutAI3')],
    With_AI: [t('WithAI1'), t('WithAI1'), t('WithAI1')],
    services: [
      {
        name: t('Availability24'),
        text: t('PredictableRevenueT'),
        icon: <Availability />,
      },
      {
        name: t('InstantResponses'),
        text: t('InstantResponsesT'),
        icon: <InstantResponses />,
      },
      {
        name: t('Scalability'),
        text: t('ScalabilityT'),
        icon: <Scalability />,
      },
    ],
    predictive: [
      {
        title: t('IncreasedRevenue'),
        text: t('IncreasedRevT'),
        icon: <IncreasedRevenue />,
      },
      {
        title: t('SentimentAnalysis'),
        text: t('SentimentAnalysisT'),
        icon: <SentimentAnalysis />,
      },
      {
        title: t('DataInsightS'),
        text: t('DataInsightT'),
        icon: <DataInsightS />,
      },
    ],
  };

  return lists[listKey];
};

export default AITransformAndChatbot;
