import React, { useEffect, useRef, useState } from 'react';
import ChartDataCard from '../ChartDataCard';
import CommonSection from '../../CommonSection';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import useVisibility from '../../../Hooks/useVisibility';
import { ButtonFilled } from '../../utils/Button';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);
const AIChartComp = () => {
  const { t } = useTranslation();
  const [chartDetails, setChartDetails] = useState([]);
  const chartContainerRef = useRef(null);
    const AnimIsInView = useVisibility(chartContainerRef, 0.5);
  useEffect(() => {
    setChartDetails(getTranslatedList(t));
  }, [t]);


    const options = {
      responsive: true, // Set to true for responsiveness
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
          text: 'Chart.js Line Chart',
        },
      },
      scales: {
        // to remove the labels
        x: {
          ticks: {
            display: false,
          },

          // to remove the x-axis grid
          grid: {
            drawBorder: false,
            display: false,
          },
        },
        // to remove the y-axis
        y: {
          ticks: {
            display: false,
            beginAtZero: false,
          },
          // to remove the y-axis grid
          grid: {
            drawBorder: false,
            display: false,
          },
        },
      },
    };
  const labels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

  const data1 = {
    labels,
    datasets: [
      {
        fill: false,
        label: '',
        data: [140, 240, 320, 460, 500, 640, 680, 820],
        borderColor: 'rgb(53, 162, 235)',
        borderWidth: 2,
        radius: 4,
        backgroundColor: '#ffffff',
      },
      {
        fill: false,
        label: '',
        data: [100, 120, 150, 200, 220, 250, 295, 300],
        borderColor: 'rgba(253, 182, 0)',
        borderWidth: 2,
        radius: 4,
        backgroundColor: '#ffffff',
      },
      {
        fill: false,
        label: '',
        data: [100, 200, 300, 400, 500, 600, 800, 1000],
        borderColor: 'rgba(255,255,255, 1 )',
        borderWidth: 2,
        radius: 0,
      },
    ],
  };
  const data2 = {
    labels,
    datasets: [
      {
        fill: false,
        label: '',
        data: [140, 240, 380, 550, 600, 700, 900, 1000],
        borderColor: 'rgb(53, 162, 235)',
        borderWidth: 2,
        radius: 4,
        backgroundColor:'#ffffff',
      },
      {
        fill: false,
        label: '',
        data: [100, 150, 210, 290, 310, 380, 440, 450],
        borderColor: 'rgba(253, 182, 0)',
        borderWidth: 2,
        radius: 4,
        backgroundColor:'#ffffff',
      },
      {
        fill: false,
        label: '',
        data: [100,200,300,400,500,600,800,1000],
        borderColor: 'rgba(255,255,255, 1 )',
        borderWidth: 2,
        radius: 0,
      },
    ],
  };
  const data3 = {
    labels,
    datasets: [
      {
        fill: false,
        label: '',
        data: [300, 340, 360, 480, 510, 600, 670, 700],
        borderColor: 'rgb(53, 162, 235)',
        borderWidth: 2,
        radius: 4,
        backgroundColor:'#ffffff',
      },
      {
        fill: false,
        label: '',
        data: [100, 140, 160, 250, 270, 300, 320, 360],
        borderColor: 'rgba(253, 182, 0)',
        borderWidth: 2,
        radius: 4,
        backgroundColor:'#ffffff',
      },
      {
        fill: false,
        label: '',
        data: [100, 200, 300, 400, 500, 600, 800, 1000],
        borderColor: 'rgba(255,255,255, 1 )',
        borderWidth: 2,
        radius: 0,
      },
    ],
  };
  return (
    <CommonSection>
      <div
        className="flex flex-col gap-10  max_md:pt-5 max_600:pt-0"
        ref={chartContainerRef}
      >
        <div className="flex items-end justify-between min-h-[200px] max_xl:gap-5 max_md:hidden">
          <div className="chart-class-ai w-[402px] max_xl:w-[312px] ml-[-4px]">
            {AnimIsInView && <Line options={options} data={data1} />}
          </div>
          <div className="chart-class-ai w-[402px] max_xl:w-[312px] ml-[-4px]">
            {AnimIsInView && <Line options={options} data={data2} />}
          </div>
          <div className="chart-class-ai w-[402px] max_xl:w-[312px] ml-[-4px]">
            {AnimIsInView && <Line options={options} data={data3} />}
          </div>
        </div>
        <div className="flex justify-between max_xl:gap-5 max_md:hidden">
          {chartDetails.map((details, index) => (
            <ChartDataCard details={details} key={index} isLine />
          ))}
        </div>

        {/* Tablate design */}
        <div className="hidden max_md:block max_600:hidden">
          <div className="flex flex-col max_md:gap-[60px]">
            <div className="flex justify-between gap-[30px] items-center max_720:gap-4 max_600:hidden">
              <div className='max_720:max-w-[270px]'>
                <Line options={options} data={data1} />
              </div>
              <div>
                <ChartDataCard details={chartDetails[0]} isBy />
              </div>
            </div>
            <div className="flex justify-between gap-[30px] items-center max_720:gap-4 max_600:hidden">
              <div className='max_720:max-w-[270px]'>
                <Line options={options} data={data2} />
              </div>
              <div>
                <ChartDataCard details={chartDetails[1]} isBy />
              </div>
            </div>
            <div className="flex justify-between gap-[30px] items-center max_720:gap-4 max_600:hidden">
              <div className='max_720:max-w-[270px]'>
                <Line options={options} data={data3} />
              </div>
              <div>
                <ChartDataCard details={chartDetails[2]} isBy />
              </div>
            </div>
          </div>
        </div>

        {/* Mobile DEIGN */}
        <div className="hidden max_600:block">
          <div className="flex flex-col gap-10">
            {chartDetails.map((details, index) => (
              <ChartDataCard details={details} key={index} isBy />
            ))}
          </div>
          <div className="max_600:block mt-10">
            <a
              href="https://calendly.com/dcdial-sales/30min"
              rel="noopener noreferrer"
              target="_blank"
            >
              <ButtonFilled
                title={t('BookADemo')}
                fontSize="16px"
                width="140px"
                fontWeight="600"
                height="48px"
              />
            </a>
          </div>
        </div>
      </div>
    </CommonSection>
  );
};
const getTranslatedList = (t) => {
  const list = [
    {
      text: t('AIChart1'),
      data: '60%',
    },
    {
      text: t('AIChart2'),
      data: '91%',
    },
    {
      text: t('AIChart3'),
      data: '35%',
    },
  ];

  return list;
};

export default AIChartComp;




// scales: {
      //   // to remove the labels
      //   x: {
      //     ticks: {
      //       display: false,
      //     },

      //     // to remove the x-axis grid
      //     grid: {
      //       drawBorder: false,
      //       display: false,
      //     },
      //   },
      //   // to remove the y-axis
      //   y: {
      //     ticks: {
      //       display: false,
      //       beginAtZero: false,
      //     },
      //     // to remove the y-axis grid
      //     grid: {
      //       drawBorder: false,
      //       display: false,
      //     },
      //   },
      // },