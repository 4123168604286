import React, { useEffect } from 'react'
import Layout from '../../Components/Layout';
import LegalSideLayout from '../../Components/LegalSideLayout';
import WebAccessContent from '../../Components/Legal/WebAccessContent';
import TitleAndDesc from '../../Components/Helmet/TitleAndDesc';
import { useLocation } from 'react-router-dom';

const WebAccessibility = () => {
    const location = useLocation();
    const state = location.state;
    useEffect(() => {
      if (state && state !== null) {
        window.scrollTo(0, 0);
      }
    }, [state]);

    var scrollPosition = localStorage.getItem('scrollPositionWA');
    if (scrollPosition !== null) {
      window.scrollTo(0, parseInt(scrollPosition));
    }
  return (
    <>
      <TitleAndDesc
        title="Website Accessibility"
        description="DCDial is committed to ensuring our website is accessible to all users, following accessibility standards and guidelines for an inclusive experience."
      />
      <Layout>
        <LegalSideLayout>
          <WebAccessContent />
        </LegalSideLayout>
      </Layout>
    </>
  );
}

export default WebAccessibility