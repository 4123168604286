import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContactForm from './ContactForm';
import axios from 'axios';

const GetInTouchContent = () => {
  const { t } = useTranslation();
  const [userCountry, setUserCountry] = useState({
    ip: '',
    countryName: '',
    country_code: '',
  });
  const getGeoInfo = () => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        let data = response.data;
        setUserCountry({
          ...userCountry,
          ip: data.ip,
          countryName: data.country_name,
          country_code: data.country_code,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);
  return (
    <div className="bg-BgPrimary m-0">
      <div className="max-w-[1240px] max_xl:max-w-[980px] m-auto py-[100px] max_md:py-[40px] max_md:px-10 max_sm:px-5 flex justify-between max_md:flex-col max_md:gap-[100px] max_sm:gap-[60px]">
        <div className="max-w-[400px] flex flex-col gap-[50px] max_sm:gap-10 max_sm:w-[100%]">
          <div className="">
            <p className="headingText">{t('ContactUs')}</p>
            <div className="mt-10 max_sm:mt-5 flex flex-col gap-5 max_sm:gap-4 subParagraphText">
              <p className="">{t('ContacUsText')}</p>
              <p>
                <span>sales</span>
                <span>@</span>
                <span>dcdial.</span>
                <span>com</span>
              </p>
              <p>888-320-4442</p>
            </div>
          </div>
          {/* Customer Support */}
          <div className="">
            <p className="text-text2xl max_sm:text-subHeadingTxt font-DMSansFont font-bold text-LightPrimary">
              {t('CustomerSupportHead')}
            </p>
            <div className="mt-4 max_sm:mt-3 flex flex-col gap-5 max_sm:gap-3 subParagraphText">
              <p className="">{t('CustomerSupportText')}</p>
              <p>
                <span>support</span>
                <span>@</span>
                <span>dcdial.</span>
                <span>com</span>
              </p>
              <p>888-640-2260</p>
            </div>
          </div>

          <div className="">
            <p className="text-text2xl max_sm:text-subHeadingTxt font-DMSansFont font-bold text-LightPrimary">
              {t('BusinessHours')}
            </p>
            <p className="mt-4 max_sm:mt-3 subParagraphText">
              {t('BusinessTime')}
            </p>
          </div>

          <div className="">
            <p className="text-text2xl max_sm:text-subHeadingTxt font-DMSansFont font-bold text-LightPrimary">
              {t('Address')}
            </p>
            <p className="mt-4 max_sm:mt-3 subParagraphText">{t('Add1')}</p>
            <p className="subParagraphText">{t('Add2')}</p>
          </div>
        </div>

        {/* FORM */}
        <ContactForm userCountry={userCountry} />
      </div>
    </div>
  );
};

export default GetInTouchContent;
