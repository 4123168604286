import { t } from 'i18next';
// export const pricingOptions = [
//   { id: 1, name: t('WeeklyBilling'), val: 'weekly' },
//   { id: 2, name: t('MonthlyBilling'), val: 'monthly' },
//   { id: 2, name: t('YearlyBilling'), val: 'yearly' },
// ];

import {
  BasicPlanIcon,
  EverythingPlanIcon,
  GoldPlanIcon,
  GrowPlanIcon,
  OnDemandIcon,
  PlatinumIcon,
  ProPlanIcon,
  SilverPlanIcon,
  StartupIcon,
} from '../../assets/Icons/Pricing/pricingIcons';

export const pricingPlansY = [
  {
    icon: <OnDemandIcon />,
    planName: 'On Demand',
    price: '4¢',
    priceUnit: 'per minute/text',
    timeValue: 'no monthly fee',
    emailValue: '1K',
    perMinTextValue: '4.0¢',
    perNumberValue: '$5',
    isPopular: false,
  },
  {
    icon: <BasicPlanIcon />,
    planName: 'Basic',
    price: '$79',
    priceUnit: '/month',
    timeValue: '2.8K',
    emailValue: '3K',
    perMinTextValue: '3.5¢',
    perNumberValue: '$4',
    isPopular: false,
  },
  {
    icon: <StartupIcon />,
    planName: 'Startup',
    price: '$239',
    priceUnit: '/month',
    timeValue: '10K',
    emailValue: '6K',
    perMinTextValue: '3.0¢',
    perNumberValue: '$3',
    isPopular: false,
  },
  {
    icon: <GrowPlanIcon />,
    planName: 'Grow',
    price: '$719',
    priceUnit: '/month',
    timeValue: '36K',
    emailValue: '10K',
    perMinTextValue: '2.5¢',
    perNumberValue: '$2',
    isPopular: true,
  },
  {
    icon: <ProPlanIcon />,
    planName: 'Pro',
    price: '$1,199',
    priceUnit: '/month',
    timeValue: '75K',
    emailValue: '20K',
    perMinTextValue: '2.0¢',
    perNumberValue: '$1',
    isPopular: false,
  },
];
export const pricingPlansM = [
  {
    icon: <OnDemandIcon />,
    planName: 'On Demand',
    price: '5¢',
    priceUnit: 'per minute/text',
    timeValue: 'no monthly fee',
    emailValue: '1K',
    perMinTextValue: '4.0¢',
    perNumberValue: '$5',
    isPopular: false,
  },
  {
    icon: <BasicPlanIcon />,
    planName: 'Basic',
    price: '$99',
    priceUnit: '/month',
    timeValue: '2.8K',
    emailValue: '3K',
    perMinTextValue: '3.5¢',
    perNumberValue: '$4',
    isPopular: false,
  },
  {
    icon: <StartupIcon />,
    planName: 'Startup',
    price: '$299',
    priceUnit: '/month',
    timeValue: '10K',
    emailValue: '6K',
    perMinTextValue: '3.0¢',
    perNumberValue: '$3',
    isPopular: false,
  },
  {
    icon: <GrowPlanIcon />,
    planName: 'Grow',
    price: '$899',
    priceUnit: '/month',
    timeValue: '36K',
    emailValue: '10K',
    perMinTextValue: '2.5¢',
    perNumberValue: '$2',
    isPopular: true,
  },
  {
    icon: <ProPlanIcon />,
    planName: 'Pro',
    price: '$1,499',
    priceUnit: '/month',
    timeValue: '75K',
    emailValue: '20K',
    perMinTextValue: '2.0¢',
    perNumberValue: '$1',
    isPopular: false,
  },
];
export const pricingPlansW = [
  {
    icon: <OnDemandIcon />,
    planName: 'On Demand',
    price: '4¢',
    priceUnit: 'per minute/text',
    timeValue: 'no weekly fee',
    emailValue: '250',
    perMinTextValue: '4.0¢',
    perNumberValue: '$5',
    isPopular: false,
  },
  {
    icon: <BasicPlanIcon />,
    planName: 'Basic',
    price: '$23',
    priceUnit: '/week',
    timeValue: '653',
    emailValue: '750',
    perMinTextValue: '3.5¢',
    perNumberValue: '$4',
    isPopular: false,
  },
  {
    icon: <StartupIcon />,
    planName: 'Startup',
    price: '$69',
    priceUnit: '/week',
    timeValue: '2.3K',
    emailValue: '1.5K',
    perMinTextValue: '3.0¢',
    perNumberValue: '$3',
    isPopular: false,
  },
  {
    icon: <GrowPlanIcon />,
    planName: 'Grow',
    price: '$207',
    priceUnit: '/week',
    timeValue: '8.3K',
    emailValue: '2.5K',
    perMinTextValue: '2.5¢',
    perNumberValue: '$2',
    isPopular: true,
  },
  {
    icon: <ProPlanIcon />,
    planName: 'Pro',
    price: '$346',
    priceUnit: '/week',
    timeValue: '17.3K',
    emailValue: '5K',
    perMinTextValue: '2.0¢',
    perNumberValue: '$1',
    isPopular: false,
  },
];
export const HighVolumePlansY = [
  {
    icon: <SilverPlanIcon />,
    planName: 'Silver',
    price: '$2,000',
    priceUnit: '/month',
    timeValue: '156K',
    emailValue: '50K',
    perMinTextValue: '1.6¢',
    perNumberValue: '$0.8',
    isPopular: false,
  },
  {
    icon: <GoldPlanIcon />,
    planName: 'Gold',
    price: '$4,800',
    priceUnit: '/month',
    timeValue: '500K',
    emailValue: '150K',
    perMinTextValue: '1.2¢',
    perNumberValue: '$0.7',
    isPopular: true,
  },
  {
    icon: <PlatinumIcon />,
    planName: 'Platinum',
    price: '$9,600',
    priceUnit: '/month',
    timeValue: '1.2M',
    emailValue: '400K',
    perMinTextValue: '1.0¢',
    perNumberValue: '$0.6',
    isPopular: false,
  },
  {
    icon: <EverythingPlanIcon />,
    planName: 'Everything',
    price: '$16,000',
    priceUnit: '/month',
    timeValue: '4M',
    emailValue: '1M',
    perMinTextValue: '0.5¢',
    perNumberValue: '$0.5',
    isPopular: false,
  },
];
export const HighVolumePlansM = [
  {
    icon: <SilverPlanIcon />,
    planName: 'Silver',
    price: '$2,500',
    priceUnit: '/month',
    timeValue: '156K',
    emailValue: '50K',
    perMinTextValue: '1.6¢',
    perNumberValue: '$0.8',
    isPopular: false,
  },
  {
    icon: <GoldPlanIcon />,
    planName: 'Gold',
    price: '$6,000',
    priceUnit: '/month',
    timeValue: '500K',
    emailValue: '150k',
    perMinTextValue: '1.2¢',
    perNumberValue: '$0.7',
    isPopular: true,
  },
  {
    icon: <PlatinumIcon />,
    planName: 'Platinum',
    price: '$12,000',
    priceUnit: '/month',
    timeValue: '1.2M',
    emailValue: '400k',
    perMinTextValue: '1.0¢',
    perNumberValue: '$0.6',
    isPopular: false,
  },
  {
    icon: <EverythingPlanIcon />,
    planName: 'Everything',
    price: '$20,000',
    priceUnit: '/month',
    timeValue: '4M',
    emailValue: '1M',
    perMinTextValue: '0.5¢',
    perNumberValue: '$0.5',
    isPopular: false,
  },
];
export const HighVolumePlansW = [
  {
    icon: <SilverPlanIcon />,
    planName: 'Silver',
    price: '$577',
    priceUnit: '/week',
    timeValue: '36K',
    emailValue: '12.5K',
    perMinTextValue: '1.6¢',
    perNumberValue: '$0.8',
    isPopular: false,
  },
  {
    icon: <GoldPlanIcon />,
    planName: 'Gold',
    price: '$1,385',
    priceUnit: '/week',
    timeValue: '115.4K',
    emailValue: '37.5K',
    perMinTextValue: '1.2¢',
    perNumberValue: '$0.7',
    isPopular: true,
  },
  {
    icon: <PlatinumIcon />,
    planName: 'Platinum',
    price: '$2,769',
    priceUnit: '/week',
    timeValue: '277K',
    emailValue: '100K',
    perMinTextValue: '1.0¢',
    perNumberValue: '$0.6',
    isPopular: false,
  },
  {
    icon: <EverythingPlanIcon />,
    planName: 'Everything',
    price: '$4,615',
    priceUnit: '/week',
    timeValue: '923K',
    emailValue: '250K',
    perMinTextValue: '0.5¢',
    perNumberValue: '$0.5',
    isPopular: false,
  },
];

export const industryList = [
  { name: 'Accounting' },
  { name: 'Airlines/Aviation' },
  { name: 'Alternative Dispute Resolution' },
  { name: 'Alternative Medicine' },
  { name: 'Animation' },
  { name: 'Apparel & Fashion' },
  { name: 'Architecture & Planning' },
  { name: 'Arts and Crafts' },
  { name: 'Automotive' },
  { name: 'Aviation & Aerospace' },
  { name: 'Banking' },
  { name: 'Biotechnology' },
  { name: 'Broadcast Media' },
  { name: 'Building Materials' },
  { name: 'Business Supplies and Equipment' },
  { name: 'Capital Markets' },
  { name: 'Chemicals' },
  { name: 'Civic & Social Organization' },
  { name: 'Civil Engineering' },
  { name: 'Commercial Real Estate' },
  { name: 'Computer & Network Security' },
  { name: 'Computer Games' },
  { name: 'Computer Hardware' },
  { name: 'Computer Networking' },
  { name: 'Computer Software' },
  { name: 'Internet' },
  { name: 'Construction' },
  { name: 'Consumer Electronics' },
  { name: 'Consumer Goods' },
  { name: 'Consumer Services' },
  { name: 'Cosmetics' },
  { name: 'Dairy' },
  { name: 'Defense & Space' },
  { name: 'Design' },
  { name: 'Education Management' },
  { name: 'E-Learning' },
  { name: 'Electrical/Electronic Manufacturing' },
  { name: 'Entertainment' },
  { name: 'Environmental Services' },
  { name: 'Events Services' },
  { name: 'Executive Office' },
  { name: 'Facilities Services' },
  { name: 'Farming' },
  { name: 'Financial Services' },
  { name: 'Fine Art' },
  { name: 'Fishery' },
  { name: 'Food & Beverages' },
  { name: 'Food Production' },
  { name: 'Fund-Raising' },
  { name: 'Furniture' },
  { name: 'Gambling & Casinos' },
  { name: 'Glass, Ceramics & Concrete' },
  { name: 'Government Administration' },
  { name: 'Government Relations' },
  { name: 'Graphic Design' },
  { name: 'Health, Wellness and Fitness' },
  { name: 'Higher Education' },
  { name: 'Hospital & Health Care' },
  { name: 'Hospitality' },
  { name: 'Human Resources' },
  { name: 'Import and Export' },
  { name: 'Individual & Family Services' },
  { name: 'Industrial Automation' },
  { name: 'Information Services' },
  { name: 'Information Technology and Services' },
  { name: 'Insurance' },
  { name: 'International Affairs' },
  { name: 'International Trade and Development' },
  { name: 'Investment Banking' },
  { name: 'Investment Management' },
  { name: 'Judiciary' },
  { name: 'Law Enforcement' },
  { name: 'Law Practice' },
  { name: 'Legal Services' },
  { name: 'Legislative Office' },
  { name: 'Leisure, Travel & Tourism' },
  { name: 'Libraries' },
  { name: 'Logistics and Supply Chain' },
  { name: 'Luxury Goods & Jewelry' },
  { name: 'Machinery' },
  { name: 'Management Consulting' },
  { name: 'Maritime' },
  { name: 'Market Research' },
  { name: 'Marketing and Advertising' },
  { name: 'Mechanical or Industrial Engineering' },
  { name: 'Media Production' },
  { name: 'Medical Devices' },
  { name: 'Medical Practice' },
  { name: 'Mental Health Care' },
  { name: 'Military' },
  { name: 'Mining & Metals' },
  { name: 'Motion Pictures and Film' },
  { name: 'Museums and Institutions' },
  { name: 'Music' },
  { name: 'Nanotechnology' },
  { name: 'Newspapers' },
  { name: 'Nonprofit Organization Management' },
  { name: 'Oil & Energy' },
  { name: 'Online Media' },
  { name: 'Outsourcing/Offshoring' },
  { name: 'Package/Freight Delivery' },
  { name: 'Packaging and Containers' },
  { name: 'Paper & Forest Products' },
  { name: 'Performing Arts' },
  { name: 'Pharmaceuticals' },
  { name: 'Philanthropy' },
  { name: 'Photography' },
  { name: 'Plastics' },
  { name: 'Political Organization' },
  { name: 'Primary/Secondary Education' },
  { name: 'Printing' },
  { name: 'Professional Training & Coaching' },
  { name: 'Program Development' },
  { name: 'Public Policy' },
  { name: 'Public Relations and Communications' },
  { name: 'Public Safety' },
  { name: 'Publishing' },
  { name: 'Railroad Manufacture' },
  { name: 'Ranching' },
  { name: 'Real Estate' },
  { name: 'Recreational Facilities and Services' },
  { name: 'Religious Institutions' },
  { name: 'Renewables & Environment' },
  { name: 'Research' },
  { name: 'Restaurants' },
  { name: 'Retail' },
  { name: 'Security and Investigations' },
  { name: 'Semiconductors' },
  { name: 'Shipbuilding' },
  { name: 'Sporting Goods' },
  { name: 'Sports' },
  { name: 'Staffing and Recruiting' },
  { name: 'Supermarkets' },
  { name: 'Telecommunications' },
  { name: 'Textiles' },
  { name: 'Think Tanks' },
  { name: 'Tobacco' },
  { name: 'Translation and Localization' },
  { name: 'Transportation/Trucking/Railroad' },
  { name: 'Utilities' },
  { name: 'Venture Capital & Private Equity' },
  { name: 'Veterinary' },
  { name: 'Warehousing' },
  { name: 'Wholesale' },
  { name: 'Wine and Spirits' },
  { name: 'Wireless' },
  { name: 'Writing and Editing' },
  { name: 'Software Development' },
  { name: 'Collections' },
  { name: 'Legal' },
  { name: 'Education' },
  { name: 'Finance' },
  { name: 'Credit Card Collections' },
  { name: 'Computer Services' },
  { name: 'Contact Center Solutions' },
  { name: 'Marketing' },
  { name: 'Office and Administrative Services' },
];
