import React, { useEffect } from 'react';
import Layout from '../Components/Layout';
import HeroSection from '../Components/AboutUs/HeroSection';
import EmpoweringBusComp from '../Components/AboutUs/EmpoweringBusComp';
import MissionAndVission from '../Components/AboutUs/MissionAndVission';
import BussinessStrategy from '../Components/AboutUs/BussinessStrategy';
import TitleAndDesc from '../Components/Helmet/TitleAndDesc';

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TitleAndDesc
        title="About DCDial"
        description="Learn about DCDial's mission, vision, and the team behind our innovative solutions. Discover how we help businesses achieve peak efficiency."
        isPageURL="https://dcdial.com/about-us"
      />
      <Layout>
        <HeroSection />
        <EmpoweringBusComp />
        <MissionAndVission />
        <BussinessStrategy />
      </Layout>
    </>
  );
};

export default AboutUs;
