import React, { useEffect, useState } from 'react';
import CommonSection from '../CommonSection';
import { useTranslation } from 'react-i18next';
import {
  CollectionIcon,
  EcommerceIcon,
  InsuranceIcon,
  NonProfitIcon,
  ProfessionalServiceIcon,
  UtilityIcon,
} from '../../assets/Icons/Solution/SolutionIcon';
// import Image1 from '../../assets/images/Solution/Professional Services.svg';
// import Image2 from '../../assets/images/Solution/Utilities and Telecommunications.svg';
// import Image3 from '../../assets/images/Solution/Collection.svg';
// import Image4 from '../../assets/images/Solution/E-commerce & Retail.svg';
// import Image5 from '../../assets/images/Solution/Insurance.svg';
// import Image6 from '../../assets/images/Solution/NonProfit.svg';
import IndustrySelectCard from './IndustrySelectCard';
import CommonSizeContainer from '../CommonSizeContainer';
import LayoutWithBGLayer from '../utils/LayoutWithBGLayer';
import ServiceComponent from './ServiceComponent';
import { useNavigate } from 'react-router-dom';

const SelectSolution = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  
  const [services, setServices] = useState([]);
  useEffect(() => {
    setServices(getTranslatedList(t));
  }, [t]);
  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <CommonSection>
        <div className="overflow-hidden">
          <p className="text40class text-LightPrimary text-center">
            {t('SelectIndustry')}
          </p>

          <div className="mt-10">
            <div className="grid grid-cols-3 gap-[30px] max_md:grid-cols-2 max_sm:grid-cols-1">
              {services.map((industry, index) => (
                <React.Fragment key={index + 1}>
                  <IndustrySelectCard
                    industry={industry}
                    handleClickScroll={handleClickScroll}
                  />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </CommonSection>
      <CommonSizeContainer>
        <div className="flex flex-col gap-[150px] max_md:gap-[100px] max_sm:gap-[60px]">
          {services.map((serviceList, index2) => (
            <React.Fragment key={index2}>
              <ServiceComponent serviceList={serviceList} index2={index2} />
            </React.Fragment>
          ))}
        </div>
        <div className="mt-[150px] max_xl:mt-[100px] max_sm:mt-[60px]">
          <LayoutWithBGLayer
            title={t('YourSolution')}
            text={t('YourSolutionText')}
            buttonText={t('ContactUsNow')}
            handleClick={() => navigate('/contact/get-in-touch')}
          />
        </div>
      </CommonSizeContainer>
    </>
  );
};

const getTranslatedList = (t) => {
  // Define your lists here
  const serviceList = [
    {
      id: 'professional',
      name: t('ServiceName1'),
      text: t('ServiceText'),
      services: [
        t('Service1Text1'),
        t('Service1Text2'),
        t('Service1Text3'),
        t('Service1Text4'),
      ],
      icon: <ProfessionalServiceIcon />,
      tags: [t('LegalFirms'), t('Consulting')],
      isTag: true,
      image: 'https://images.dcdial.com/dcdial.com/v2/ProfessionalServices.svg',
    },
    {
      id: 'utilities',
      name: t('ServiceName2'),
      text: t('Service2Text'),
      services: [
        t('Service2Text1'),
        t('Service2Text2'),
        t('Service2Text3'),
        t('Service2Text4'),
      ],
      icon: <UtilityIcon />,
      tags: [],
      isTag: false,
      image: 'https://images.dcdial.com/dcdial.com/v2/UtilitiesAndTelecom.svg',
    },
    {
      id: 'collections',
      name: t('ServiceName3'),
      text: t('Service3Text'),
      services: [
        t('Service3Text1'),
        t('Service3Text2'),
        t('Service3Text3'),
        t('Service3Text4'),
      ],
      icon: <CollectionIcon />,
      tags: [t('CollectionAgencies')],
      isTag: true,
      image: 'https://images.dcdial.com/dcdial.com/v2/Collection.svg',
    },
    {
      id: 'e-commerce',
      name: t('ServiceName5'),
      text: t('Service5Text'),
      services: [
        t('Service5Text1'),
        t('Service5Text2'),
        t('Service5Text3'),
        t('Service5Text4'),
      ],
      icon: <EcommerceIcon />,
      tags: [t('OnlineStores'), t('Sales')],
      isTag: true,
      image: 'https://images.dcdial.com/dcdial.com/v2/E-commerceAndRetail.svg',
    },
    {
      id: 'insurance',
      name: t('ServiceName4'),
      text: t('Service4Text'),
      services: [
        t('Service4Text1'),
        t('Service4Text2'),
        t('Service4Text3'),
        t('Service4Text4'),
      ],
      icon: <InsuranceIcon />,
      tags: [t('HealthInsurance'), t('AutoInsurance')],
      isTag: true,
      image: 'https://images.dcdial.com/dcdial.com/v2/Insurance.svg',
    },

    {
      id: 'nonprofit',
      name: t('ServiceName6'),
      text: t('Service6Text'),
      services: [
        t('Service6Text1'),
        t('Service6Text2'),
        t('Service6Text3'),
        t('Service6Text4'),
      ],
      icon: <NonProfitIcon />,
      tags: [t('PrivateSector'), t('Fundraising')],
      isTag: true,
      image: 'https://images.dcdial.com/dcdial.com/v2/NonProfit.svg',
    },
  ];

  return serviceList;
};

export default SelectSolution;
