import React, { useEffect } from 'react'
import Layout from '../Components/Layout'
import LegalPage from '../Components/Legal/LegalPage'
import TitleAndDesc from '../Components/Helmet/TitleAndDesc';

const Legal = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TitleAndDesc
        title="DCDial Legal Repository"
        description="Access DCDial's comprehensive legal repository for important documents, terms of service, privacy policies, and compliance information. Stay informed and compliant with our resources."
      />
      <Layout>
        <LegalPage />
      </Layout>
    </>
  );
}

export default Legal