import React, { useEffect, useState } from 'react';
import DcDialLogoEn from '../assets/images/Navbar/LogoEn.svg';
import CloseIcon from '../assets/images/Navbar/CloseIcon.svg';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ButtonFilled } from './utils/Button';

const MobileMenu = ({
  setIsMobileMenuOpen,
  outsideRef,
  nodeRef,
  activeLangauge,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [MenuList, setMenuList] = useState([]);
  useEffect(() => {
    setMenuList(getTranslatedList(t, activeLangauge));
  }, [activeLangauge, t]);
  const handleGetStarted = () => {
    navigate('/signup');
  };
  return (
    <div
      ref={nodeRef}
      className="z-[999] fixed top-0 flex h-screen w-[100vw] !overflow-hidden"
    >
      <div ref={outsideRef} className="w-full bg-white">
        <div className="mobilemenu-shadow F-JC-AI-CENTER h-[66px] justify-between px-[40px] max_sm:pl-[20px] max_sm:pr-[15px] w-[100%]">
          <div className="w-[140px] max_sm:w-[100px]">
            <img draggable="false" src={DcDialLogoEn} alt="Logo" />
          </div>
          <div className="flex items-center gap-2">
            <div className="F-JC-AI-CENTER text-textExtraSmall font-DMSansFont font-medium text-ButtonPrimary cursor-pointer">
              <a
                href="https://www.dcdial.com/app/login"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('SignIn')}
              </a>
            </div>

            <div className="z-[60]">
              {/* <a
                href="https://www.dcdial.com/signup/?v=5&d=m/"
                rel="noopener noreferrer"
                target="_blank"
              > */}
              <ButtonFilled
                title={t('GetStarted')}
                fontSize="14px"
                fontWeight="500"
                onClick={handleGetStarted}
              />
              {/* </a> */}
            </div>

            <div
              className="cursor-pointer "
              onClick={() => setIsMobileMenuOpen(false)}
            >
              <img draggable="false" src={CloseIcon} alt="CloseIcon" />
            </div>
          </div>
        </div>

        <div className="w-full bg-white h-[calc(100vh-70px)] z-[100] overflow-y-scroll mt-5">
          {MenuList.map((menu, index) => (
            <React.Fragment key={index}>
              <NavLink
                className={`flex w-full items-center border-[#00000033] no-underline`}
                to={menu.link}
              >
                {({ isActive }) => (
                  <div className="flex w-full flex-col">
                    <div
                      className={`flex gap-2 items-center w-full px-[30px] py-5`}
                    >
                      <div
                        className={`cursor-pointer text-LightTextParagraph text-textMedium font-medium font-DMSansFont hover:text-LightPrimary `}
                      >
                        {menu.name}
                      </div>
                    </div>
                  </div>
                )}
              </NavLink>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
const getTranslatedList = (t) => {
  // Define your lists here
  const lists = [
    {
      name: t('Products'),
      link: '/products',
      langauge: false,
    },
    {
      name: t('Solutions'),
      link: '/solutions',
      langauge: false,
    },
    {
      name: t('Pricing'),
      link: '/pricing',
      langauge: false,
    },
    // {
    //   name: activeLangauge,
    //   link: '',
    //   langauge: true,
    // },
  ];

  return lists;
};

export default MobileMenu;
