import React from 'react'

const QuestionAnswer = ({data}) => {
  return (
    <div className="flex flex-col gap-2 mt-10 max_md:mt-[30px] max_sm:mt-5">
      <p className="subHeadingText">{data.question}</p>
      <p
        className="subParagraphText text-LightSecondary font-normal"
        dangerouslySetInnerHTML={{ __html: data.answer }}
      >
        {/* {data.answer} */}
      </p>
    </div>
  );
}

export default QuestionAnswer