import React from 'react';
import CommonSection from './CommonSection';
import { useWindowSize } from '../Hooks/useWindowSize';
import { ButtonFilled } from './utils/Button';

const HeroSection = ({ children, title, text, isButton }) => {
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 500;
  return (
    <CommonSection>
      <div className="flex flex-col items-center">
        <h1
          className="headingText text-center max-w-[920px] max_md:max-w-[524px] max_sm:max-w-[100%]"
          dangerouslySetInnerHTML={{ __html: title }}
        ></h1>
        <p className="subHeadingText max_md:text-headingNormal max_sm:text-textSmall mt-10 max_sm:mt-5 text-LightTextParagraph font-medium text-center w-[88%] max_md:w-full">
          {text}
        </p>
        {isButton && (
          <div className="mt-[50px] max_sm:mt-5 w-fit m-auto">
            <a
              href="https://calendly.com/dcdial-sales/30min"
              rel="noopener noreferrer"
              target="_blank"
            >
              <ButtonFilled
                title="Get a demo"
                height={isMobileView ? '45px' : '60px'}
                fontSize={isMobileView ? '16px' : '20px'}
                width={isMobileView ? '184px' : '230px'}
                rounded="10px"
                fontWeight="500"
              />
            </a>
          </div>
        )}
      </div>
      {children}
    </CommonSection>
  );
};

export default HeroSection;
