import React from 'react';
import CommonSection from '../CommonSection';
import { useTranslation } from 'react-i18next';
import HeroSection from '../HeroSection';
import LegalGroups from './LegalGroups';

const LegalPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <HeroSection title={t('Legal')} text={t('LegalText')} />
      <LegalGroups />
    </>
  );
};

export default LegalPage;
