import React, { useState } from 'react';
import CommonSizeContainer from '../CommonSizeContainer';
import { useTranslation } from 'react-i18next';
import {
  DcDialProcessorIcon,
  OwnProcessorIcon,
} from '../../assets/Icons/Pricing/pricingIcons';

const AddOns = () => {
  const { t } = useTranslation();
  const [addOnInfo,] = useState([
    {
      icon: <DcDialProcessorIcon />,
      name: 'Use DCDial processor',
      value: '$0.00',
      additionalService: [
        'Card 3.5% + 50¢',
        'Bank 2% (capped @ $20)',
        'Wire $20',
      ],
      revenu: 'per successful transaction',
    },
    {
      icon: <OwnProcessorIcon />,
      name: 'Use your own processor',
      value: '$599',
      additionalService: ['+0.9% of revenue'],
      revenu: 'Annual revenue under $2.4M ',
    },
    {
      icon: <OwnProcessorIcon />,
      name: 'Use your own processor',
      value: '$1,499',
      additionalService: ['+0.7% of revenue'],
      revenu: 'Annual revenue $2.4M-$6M',
    },
    {
      icon: <OwnProcessorIcon />,
      name: 'Use your own processor',
      value: '$3,499',
      additionalService: ['+0.5% of revenue'],
      revenu: 'Annual revenue over $6M',
    },
  ]);
  return (
    <CommonSizeContainer>
      <div className="flex flex-col">
        <p className="text-center text-text3Xl font-DMSansFont font-bold text-LightPrimary">
          {t('Add-ons')}
        </p>
        <p className="paragraphText max_md:text-center mt-10 max_md:mt-5">{t('PaymentServices')}</p>
        <div className="flex gap-[26px] mt-10 max_sm:mt-5 max_md:flex-wrap max_md:justify-center">
          {addOnInfo.map((addOnDetails, index) => {
            return (
              <React.Fragment key={index}>
                <div className="p-5 rounded-xl border-[1px] border-LightOutline w-[290px] max_720:w-[266px] max_600:w-[290px]">
                  <div className="flex flex-col gap-3">
                    <div>{addOnDetails.icon}</div>
                    <p className="smallText16 font-semibold max_xl:min-h-[48px]">
                      {addOnDetails.name}
                    </p>
                    <p className="text-[32px] font-DMSansFont font-semibold text-LightPrimary">
                      {addOnDetails.value}
                      <span className="text-[14px] font-semibold font-DMSansFont text-LightTextParagraph ml-1">
                        /month
                      </span>
                    </p>
                  </div>
                  <div className="mt-4">
                    <div className="flex flex-col gap-y-2 min-h-20">
                      {addOnDetails.additionalService.map(
                        (services, index1) => {
                          return (
                            <React.Fragment key={index1}>
                              <p className="text-[14px] font-medium font-DMSansFont text-ButtonPrimary">
                                {services}
                              </p>
                            </React.Fragment>
                          );
                        }
                      )}
                    </div>
                  </div>
                  <p className="mt-4 text-[14px] font-medium font-DMSansFont text-LightTextParagraph">
                    {addOnDetails.revenu}
                  </p>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </CommonSizeContainer>
  );
};

export default AddOns;
