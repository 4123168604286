import React, { useEffect } from 'react';
import Layout from '../../Components/Layout';
import LegalSideLayout from '../../Components/LegalSideLayout';
import DeveloperPolicyContent from '../../Components/Legal/DeveloperPolicyContent';
import TitleAndDesc from '../../Components/Helmet/TitleAndDesc';
import { useLocation } from 'react-router-dom';

const DeveloperPolicy = () => {
  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    if (state && state !== null) {
      window.scrollTo(0, 0);
    }
  }, [state]);

  var scrollPosition = localStorage.getItem('scrollPositionD');
  if (scrollPosition !== null) {
    window.scrollTo(0, parseInt(scrollPosition));
  }
  return (
    <>
      <TitleAndDesc
        title="DCDial Developer Policy"
        description="Read our Developer Policy for guidelines and best practices when working with DCDial's platform, ensuring smooth integration and optimal performance."
      />
      <Layout>
        <LegalSideLayout>
          <DeveloperPolicyContent />
        </LegalSideLayout>
      </Layout>
    </>
  );
};

export default DeveloperPolicy;
