import React, { useEffect, useState } from 'react';
import Layout from '../Components/Layout';
import HeroSection from '../Components/Pricing/HeroSection';
import Plans from '../Components/Pricing/Plans';
import AddOns from '../Components/Pricing/AddOns';
import AllPlans from '../Components/Pricing/AllPlans';
import FAQ from '../Components/HomePage/FAQComponent';
import { useTranslation } from 'react-i18next';
import ContinueWithDC from '../Components/HomePage/ContinueWithDC';
import TitleAndDesc from '../Components/Helmet/TitleAndDesc';
import { BASE_URL } from '../api/config';
import axios from 'axios';

const Pricing = () => {
  const { t } = useTranslation();
  const [FAQList, setFAQList] = useState([]);
  const [allPlansData, setAllPlansData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setFAQList(getTranslatedList(t));
  }, [t]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getPlans = async () => {
    let payload = {
      type: 'get_signup_plans',
      api_key: '71a629e3685846a9173884a03f8f559a4d3c6510',
      clusterid: '10217',
    };
    const response = await axios.post(BASE_URL, payload);
    if (response.status === 200 && response.data.success === 1) {
      setIsLoading(false);

      setAllPlansData(response.data.plans);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getPlans();
  }, []);

  return (
    <>
      <TitleAndDesc
        title="Pricing | DCDial"
        description="Explore DCDial's flexible, affordable pricing plans with no setup fees and the freedom to cancel anytime. Save 20% with annual plans. "
        isPageURL="https://dcdial.com/pricing"
      />
      <Layout>
        <HeroSection />
        <Plans allPlansData={allPlansData} isLoading={isLoading} />
        <AddOns />
        <AllPlans />
        <FAQ data={FAQList} isMargin={true} />
        <ContinueWithDC
          title={t('ReadyToStarted')}
          subTitle1={t('ReadyToStartedText1')}
          subTitle2={t('ReadyToStartedText2')}
          isbreakable
        />
      </Layout>
    </>
  );
};
const getTranslatedList = (t) => {
  // Define your lists here
  const lists = [
    {
      question: t('PricingQue1'),
      answer: t('PricingAns1'),
    },
    {
      question: t('PricingQue2'),
      answer: t('PricingAns2'),
    },
    {
      question: t('PricingQue3'),
      answer: t('PricingAns3'),
    },
    {
      question: t('PricingQue4'),
      answer: t('PricingAns4'),
    },
    {
      question: t('PricingQue5'),
      answer: t('PricingAns5'),
    },
    {
      question: t('PricingQue6'),
      answer: t('PricingAns6'),
    },
    {
      question: t('PricingQue7'),
      answer: t('PricingAns7'),
    },
  ];

  return lists;
};

export default Pricing;
