import React, { useEffect, useState } from 'react'
import Layout from '../Layout'
import CommonProductBanner from './CommonProductBanner'
import CommunicationAreaComp from './CommunicationAreaComp'
import IVRComp from './IVRComp'
import CommonFullBgComp from './CommonFullBgComp'
import { useTranslation } from 'react-i18next'
import { AutomatedDialIcon, AutomatedIcon, CampaignManIcon, CompailanceIcon, FlexibilityIcon, PredictiveDialIcon, RealTimeIcon } from '../../assets/Icons/Products/ProductsIcon'
import BgCenterComp from './BgCenterComp'
// import OutboundSecImg from '../../assets/images/Products/OutboundSec.svg'
import ProductServices from './ProductServices'
import HeroSection from '../HeroSection'
import TitleDescWithKeyword from '../Helmet/TitleDescWithKeyword'

const Communication = () => {
  const { t } = useTranslation()
  const [data, setData] = useState({
    AgentData: [],
    OutBoundData:[]
  })
  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      AgentData: getTranslatedList(t, 'agent_data'),
      OutBoundData:getTranslatedList(t,'outbound_data'),
    }));
  }, [t])
  
   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
  return (
    <>
      <TitleDescWithKeyword
        title="DCDial | Communication Solutions"
        description="Optimize customer connections with DCDial's communication tools. Handle calls, automate tasks, and connect via SMS, email, and voice for superior engagement."
        keywords="Advanced call routing, Inbound call center solutions, Outbound call center management, Interactive voice response (IVR), Automatic call distribution (ACD), Real-time call management, Agent productivity tools, Customer support automation, Cloud-based agent interface, AI-powered call center, Seamless customer communication, Predictive dialing software, Power dialing features, SMS broadcast campaigns, Email broadcast campaigns, Unified communications platform, Workflow automation for call centers, Call recording and monitoring, Compliance features for call centers, Personalized customer greetings, Call transfer and monitoring, Enhanced customer engagement, Customer identity verification, Payment reminder calls, Customizable call handling, Cross-channel customer interaction, Call center campaign management, Reduce cost per acquisition, Increase first call resolution, Scale call center operations"
        isPageURL="https://dcdial.com/products/communication"
        isImageURL="https://images.dcdial.com/dcdial.com/v2/Communication.svg"
      />
      <Layout>
        <HeroSection title={t('Communication')} text={t('CommunicationText')} />
        <CommonProductBanner
          tagText={t('InboundCenterC')}
          title={t('InboundTitle')}
          text={t('InboundCenterText')}
          id="inbound"
        />

        <CommunicationAreaComp />

        <IVRComp />

        <CommonFullBgComp data={data.AgentData} id="AgentInterface" />

        <BgCenterComp
          title={t('OutboundTitle')}
          text={t('OutboundCenterText')}
          tagText={t('OutboundCenterU')}
          background="#ffffff"
          data={data.OutBoundData}
          id="Outbound"
        >
          <div className="relative flex justify-center mt-[60px] max_md:mt-0 max-w-[800px] m-auto">
            <img
              src="https://images.dcdial.com/dcdial.com/v2/OutboundCall.svg"
              alt="OutboundCall"
            />
          </div>
        </BgCenterComp>

        <ProductServices />
      </Layout>
    </>
  );
}
const getTranslatedList = (t,listKey) => {
  const lists = {
    agent_data: [
      {
        name: t('Flexibility'),
        text: t('FlexibilityText'),
        icon: <FlexibilityIcon />,
      },
      {
        name: t('AutomatedWorkflows'),
        text: t('AutomatedWorkflowsText'),
        icon: <AutomatedIcon />,
      },
      {
        name: t('RealTimecall'),
        text: t('RealTimecallText'),
        icon: <RealTimeIcon />,
      },
    ],
    outbound_data: [
      {
        name: t('AutomatedDialing'),
        text: t('AutomatedDialingT'),
        icon: <AutomatedDialIcon />,
      },
      {
        name: t('PredictiveDialerS'),
        text: t('PredictiveDialerST'),
        icon: <PredictiveDialIcon />,
      },
      {
        name: t('CampaignManagementS'),
        text: t('CampaignManagementST'),
        icon: <CampaignManIcon />,
      },
      {
        name: t('Compliance'),
        text: t('ComplianceT'),
        icon: <CompailanceIcon />,
      },
    ],
  };

  return lists[listKey];
};


export default Communication