import React from 'react';
import TagsComp from '../utils/TagsComp';
import { useTranslation } from 'react-i18next';

const CommonFullBgComp = ({ data,id }) => {
  const { t } = useTranslation();
  return (
    <div
      className="mt-[150px] max_xl:mt-[100px] max_sm:mt-[60px] bg-BgPrimary py-[100px] max_md:py-[60px] max_sm:py-10"
      id={id}
    >
      <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] max_md:px-10 max_sm:px-5 flex justify-between max_md:flex-col">
        <div className="flex flex-col gap-6 max_md:gap-4 max-w-[500px] max_xl:max-w-[410px] max_md:max-w-full">
          <TagsComp
            px="16px"
            py="8px"
            text={t('Tools')}
            color="#00A0FC"
            borderColor="#00A0FC"
          />
          <h2 className="text50Product max_md:mt-[14px] max_sm:mt-0">
            {t('AgentinterfaceC')}
          </h2>
          <p className="paragraphText">{t('AgentInterfaceT')}</p>
          <div className="mt-20 w-max max_md:hidden">
            <div className="max-w-[470px] max_xl:max-w-[410px] ">
              <img
                src="https://images.dcdial.com/dcdial.com/v2/AgentInterfaceCall.svg"
                alt="AgentInterfaceCall"
              />
            </div>
          </div>
        </div>
        {/* TABLATE IMAGE */}
        <div className="hidden max_md:block my-[60px]">
          <div className="">
            <img
              src="https://images.dcdial.com/dcdial.com/v2/AgentInterfaceRes.png"
              alt="image1"
            />
          </div>
        </div>
        <div className="flex flex-col gap-10 max-w-[500px] max_xl:max-w-[470px]">
          {data.map((details, index) => (
            <div className="flex gap-3" key={index}>
              <div>{details.icon}</div>
              <div>
                <p className="paragraphText font-bold text-LightPrimary max_md:text-subHeadingTxt max_sm:text-headingNormal">
                  {details.name}
                </p>
                <p className="smallText18 font-medium text-LightTextParagraph max_sm:text-textSmall">
                  {details.text}
                </p>
              </div>
            </div>
          ))}
          <div className="mt-[60px] relative max_xl:max-w-[400px] max_md:hidden">
            <img
              src="https://images.dcdial.com/dcdial.com/v2/AgentInterfaceSchedule.svg"
              alt="AgentInterfaceSchedule"
              className=""
            />
            <div className="absolute bottom-2 left-[-460px] max_xl:left-[-295px] max_xl:max-w-[485px]">
              <img
                src="https://images.dcdial.com/dcdial.com/v2/AgentInterfaceConference.svg"
                alt="AgentInterfaceConference"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonFullBgComp;
