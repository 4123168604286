import { useEffect, useState } from 'react';

const useVisibility = (ref, threshold = 0.7) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update visibility state based on the element's visibility
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null, // observing with respect to the viewport
        threshold: threshold, // percentage of the observed element which is visible
      }
    );

    const currentElement = ref.current;
    if (currentElement) {
      observer.observe(currentElement);
    }

    // Cleanup function to unobserve the element
    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [ref, threshold]); // Dependencies to re-run the effect

  return isVisible;
};

export default useVisibility;
