import React from 'react'
import CommonSizeContainer from '../CommonSizeContainer'
import { useTranslation } from 'react-i18next';
import LayoutWithBGLayer from '../utils/LayoutWithBGLayer';


const BussinessStrategy = () => {
  const { t } = useTranslation();
  

  return (
    <CommonSizeContainer>
      
      <LayoutWithBGLayer
        title={t('BusinessStrategy')}
        text={t('BusinessStrategyText')}
        buttonText="Get a demo"
        link="https://calendly.com/dcdial-sales/30min"
        isLink
      />
    </CommonSizeContainer>
  );
}

export default BussinessStrategy