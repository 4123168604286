import React from 'react';
import CommonSection from '../CommonSection';
import { useTranslation } from 'react-i18next';
import { ExploreArrow } from '../../assets/Icons/Features/FeatureIcon';
import { useWindowSize } from '../../Hooks/useWindowSize';
import { ButtonFilled } from '../utils/Button';
import { useNavigate } from 'react-router-dom';

const HeroSectionF = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 500;
  return (
    <CommonSection>
      <div className="flex flex-col items-center">
        <div
          className="hero-button-shadow rounded-lg border-[1px] border-LightOutline flex gap-3 w-fit m-auto px-5 py-3 cursor-pointer max_sm:py-[10px] max_sm:px-3"
        >
          <p
            className="smallText18 text-ButtonPrimary max_md:text-headingNormal font-medium max_sm:text-textSmall"
            onClick={() => navigate('/products')}
          >
            {t('ExploreProducts')}
          </p>
          <div>
            <ExploreArrow />
          </div>
        </div>
        <h1
          className="max_md:hidden headingText mt-[50px] text-center max_sm:mt-5"
          dangerouslySetInnerHTML={{ __html: t('PowerOfSmart') }}
        ></h1>
        <p className="hidden max_md:block headingText mt-[50px] text-center max_sm:mt-5 max-w-[550px] max_sm:max-w-[400px]">
          {t('PowerOfSmartRes')}
        </p>
        <p className="subHeadingText mt-10 max_sm:mt-5 text-LightTextParagraph font-medium text-center max_md:headingNormal max_sm:text-textSmall">
          {t('PowerOfSmartText')}
        </p>
        <div className="mt-[50px] max_sm:mt-5 w-fit m-auto">
          <a
            href="https://calendly.com/dcdial-sales/30min"
            rel="noopener noreferrer"
            target="_blank"
          >
            <ButtonFilled
              title="Get a demo"
              height={isMobileView ? '45px' : '60px'}
              fontSize={isMobileView ? '16px' : '20px'}
              width={isMobileView ? '184px' : '230px'}
              rounded="10px"
              fontWeight="500"
            />
          </a>
        </div>
      </div>
    </CommonSection>
  );
};

export default HeroSectionF;
