import React, { useEffect } from 'react';
import Layout from '../Components/Layout';
import HeroSection from '../Components/FAQ/HeroSection';
import AllQuestions from '../Components/FAQ/AllQuestions';
import TitleAndDesc from '../Components/Helmet/TitleAndDesc';

const FAQ = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TitleAndDesc
        title="FAQs"
        description="Find answers to common questions about DCDial's features, pricing, integrations, and more. Our FAQ section provides detailed information to help you get the most out of our solutions."
      />
      <Layout>
        <HeroSection />

        <AllQuestions />
      </Layout>
    </>
  );
};

export default FAQ;
