import React, { useEffect, useState } from 'react';
import CommonSizeContainer from '../CommonSizeContainer';
import { useTranslation } from 'react-i18next';
import {
  ClutchLogo,
  G2Logo,
  GoodFirmsLogo,
  TrustPilotLogo,
  TrustRadiusLogo,
} from '../../assets/Icons/icons';
import StarRatings from 'react-star-ratings';
const CustomerReviews = () => {
  const { t } = useTranslation();
  const [reviewList, setReviewList] = useState([]);
  useEffect(() => {
    setReviewList(getTranslatedList(t));
  }, [t]);
  return (
    <CommonSizeContainer>
      <h2 className="headingText text-center">{t('CustomerReviews')}</h2>
      <div className="mt-[100px] max_xl:mt-[70px] max_md:mt-[60px] max_sm:mt-10 flex gap-5 max_xl:gap-[10px] max_md:flex-wrap max_md:justify-center">
        {reviewList?.map((review, index) => {
          return (
            <React.Fragment key={index}>
              <a href={review.link} rel="noopener noreferrer" target="_blank">
                <div className="rounded-2xl border-[1px] border-LightOutline w-[232px] max_xl:w-[188px] max_md:w-[216px] max_sm:w-[232px] flex flex-col items-center py-5 gap-[10px]">
                  <div className="max_xl:w-[83%] flex justify-center">
                    {' '}
                    {React.cloneElement(review.icon, { width: '100%' })}
                  </div>
                  <p className="text-[16px] font-DMSansFont font-medium text-LightTextParagraph">
                    {review.value} out of 5
                  </p>
                  <div className="start-rating flex w-full justify-center">
                    <StarRatings
                      rating={review.value}
                      starRatedColor="#FDD12F"
                      starEmptyColor="#EFEFEF"
                      numberOfStars={5}
                      starWidthAndHeight="15px"
                      isSelectable={false}
                      starSpacing="5px"
                    />
                  </div>
                </div>
              </a>
            </React.Fragment>
          );
        })}
      </div>
    </CommonSizeContainer>
  );
};
const getTranslatedList = (t) => {
  // Define your lists here
  const list = [
    {
      value: 5,
      total: 5,
      icon: <GoodFirmsLogo />,
      link: 'https://www.goodfirms.co/software/dcdial',
    },
    {
      value: 5,
      total: 5,
      icon: <G2Logo />,
      link: 'https://www.g2.com/products/dcdial/reviews',
    },
    {
      value: 4.5,
      total: 5,
      icon: <TrustPilotLogo />,
      link: 'https://www.trustpilot.com/review/dcdial.com',
    },
    {
      value: 4.7,
      total: 5,
      icon: <TrustRadiusLogo />,
      link: 'https://www.trustradius.com/products/dcdial/reviews#overvie',
    },
    {
      value: 4.8,
      total: 5,
      icon: <ClutchLogo />,
      link: 'https://clutch.co/profile/dcdial#highlights',
    },
  ];

  return list;
};

export default CustomerReviews;
