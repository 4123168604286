import React, { useEffect } from 'react';

const ChatTawkToScript = () => {
  useEffect(() => {
    // Create the script element
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://embed.tawk.to/645b796f6a9aad4bc579edc7/1h02l4mt2';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');

    // Insert the script into the DOM
    const scriptTag = document.getElementsByTagName('script')[0];
    scriptTag.parentNode.insertBefore(script, scriptTag);

    // Clean up the script when the component unmounts
    return () => {
      scriptTag.parentNode.removeChild(script);
    };
  }, []);

  return null; // This component does not render anything
};

export default ChatTawkToScript;
