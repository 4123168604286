import React, { useEffect, useMemo, useState } from 'react';
import GradientBg from './GradientBg';
import LogoEn from '../../assets/images/Navbar/LogoEn.svg';
import { ButtonFilled } from '../utils/Button';
import CompanyDetails from './CompanyDetails';
import VerifyOtp from './VerifyOtp';
import EmailDetails from './EmailDetails';
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import { useLocation } from 'react-router-dom';
import { BASE_URL } from '../../api/config';
// import { isValidPhoneNumber } from 'react-phone-number-input';
// import { getCountry } from 'country-state-picker';
import { Country } from 'country-state-city';
import { PhoneNumberUtil } from 'google-libphonenumber';

const SignUp = () => {
  // const navigate = useNavigate();
  const location = useLocation();
  const { pr_id } = location.state || {};
  // const BASE_URL = process.env.REACT_APP_BASE_URL
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [userInput, setUserInput] = useState({
    email: '',
    password: '',
    name: '',
    otp: '',
  });

  const errorInitial = {
    name: false,
    email: false,
    password: false,
  };
  const [errors, setErrors] = useState(errorInitial);
  const [isExist, setIsExist] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({
    company: '',
    industry: '',
    number_of_employees: '',
    company_website: 'https://',
    company_email: '',
    country: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postal_code: '',
  });
  const initialCompanyErr = {
    company: '',
    industry: '',
    number_of_employees: '',
    company_website: '',
    company_email: '',
    country: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postal_code: '',
    mobile_no: '',
  };
  const [companyError, setCompanyError] = useState(initialCompanyErr);
  const [inputMobileData, setInputMobileData] = useState({
    country_code: '',
    phone_number: '',
  });
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpErr, setOtpErr] = useState(false);
  const [counter, setCounter] = useState(30);
  const [token, setToken] = useState('');
  const [clusterId, setClusterId] = useState('');

  const [userCountry, setUserCountry] = useState({
    ip: '',
    countryName: '',
    countryCode: '',
    country_code: '',
  });
  const [selectedCountry, setSelectedCountry] = useState();
  const handleOtpChange = (e) => {
    setOtp(e);
    setOtpErr(false)
  }

  const phoneUtil = PhoneNumberUtil.getInstance();

  const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };

  const getGeoInfo = () => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        let data = response.data;
        setUserCountry({
          ...userCountry,
          ip: data.ip,
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          country_code: data.country_code,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);
  useEffect(() => {
    if (userCountry.countryCode) {
      let getCountryName = Country.getCountryByCode(userCountry.country_code);
      if (getCountryName) {
        setCompanyDetails({ ...companyDetails, country: getCountryName.name });
      }
    }
  }, [userCountry]);

  // Password Requirments /////////////////
  const inputPassword = useMemo(() => {
    const value = userInput.password;
    return {
      minLength: value ? value?.length >= 8 : false,
      uppercase: value ? /[A-Z]/.test(value) : false,
      lowercase: value ? /[a-z]/.test(value) : false,
      number: value ? /\d/.test(value) : false,
      specialChar: value
        ? /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value)
        : false,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInput]);

  // OTP COUNTER //////////////////////
  useEffect(() => {
    let interval;

    if (counter > 0 && otpSent) {
      interval = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [counter, otpSent]);

  const formattedSeconds = formatSeconds(counter);

  function formatSeconds(seconds) {
    return String(seconds).padStart(2, '0');
  }

  const validateForm = (type) => {
    const newErrors = {};
    // Check for non-empty fields
    if (type === 'email_details') {
      newErrors.name = userInput.name.length < 2 || userInput.name.length > 50;
      newErrors.email =
        userInput.email.length < 5 || userInput.email.length > 100;
      newErrors.password = !userInput.password;

      if (!newErrors.email) {
        newErrors.email = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userInput.email);
      }
    } else {
      const isValidNum = isPhoneValid(inputMobileData?.phone_number);
      newErrors.company =
        companyDetails.company.length < 2 ||
        companyDetails.company.length > 100;
      newErrors.company_email =
        companyDetails.company_email.length < 5 ||
        companyDetails.company_email.length > 100;
      newErrors.company_website =
        companyDetails.company_website.length < 5 ||
        companyDetails.company_website.length > 100;
      newErrors.address1 =
        companyDetails.address1.length < 5 ||
        companyDetails.address1.length > 100;
      newErrors.city =
        companyDetails.city.length < 2 || companyDetails.city.length > 50;
      newErrors.postal_code =
        companyDetails.postal_code.length < 5 ||
        companyDetails.postal_code.length > 10;
      newErrors.phone_number = !isValidNum;
      if (!newErrors.company_email) {
        newErrors.company_email = companyError.company_email
      }

      if (!newErrors.company_website) {
        newErrors.company_website =
          !/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
            companyDetails.company_website
          );
      }
    }
    return newErrors;
  };

  const handleOnBlur = async () => {
    if (companyDetails.company_email.length > 0) {
      const isValidCEmail = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
        companyDetails.company_email
      );
      setCompanyError({ ...companyError, company_email: isValidCEmail });
      if (isValidCEmail) {
        return;
      }
      const data = {
        type: 'validate_email_api',
        email: companyDetails.company_email,
        api_key: '71a629e3685846a9173884a03f8f559a4d3c6510',
      };
      const response = await axios.post(`${BASE_URL}index.php?`, data);
      if (response.status === 200) {
        if (response.data.format === true && response.data.mx_found === true) {
          setCompanyError({ ...companyError, company_email: false });
        } else {
          setCompanyError({ ...companyError, company_email: true });
        }
      }
    }
  };
   
  const handleBlurURL = (name) => {
    if (name === 'company') {
      if (companyDetails.company.length > 0) {
        const isValidCName =
          companyDetails.company.length < 2 ||
          companyDetails.company.length > 100;
        setCompanyError({ ...companyError, company: isValidCName });
      }
    }
    if (name === 'company_website') {
      if (companyDetails.company_website.length > 0) {
        const isValidCURL =
          !/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
            companyDetails.company_website
          );
        setCompanyError({ ...companyError, company_website: isValidCURL });
      }
    }
    if (name === 'address1') {
      if (companyDetails.address1.length > 0) {
        const isValidAdd1 =
          companyDetails.address1.length < 5 ||
          companyDetails.address1.length > 100;
        setCompanyError({ ...companyError, address1: isValidAdd1 });
      }
    }
    if (name === 'city') {
      if (companyDetails.city.length > 0) {
        const isValidAdd1 =
          companyDetails.city.length < 2 || companyDetails.city.length > 50;
        setCompanyError({ ...companyError, city: isValidAdd1 });
      }
    }
    if (name === 'postal_code') {
      if (companyDetails.postal_code.length > 0) {
        const isValidPincode =
          companyDetails.postal_code.length < 5 ||
          companyDetails.postal_code.length > 10;
        setCompanyError({ ...companyError, postal_code: isValidPincode });
      }
    }
    if (name === 'phone') {
      if (inputMobileData?.phone_number?.length > 0) {
        const isValidNum = isPhoneValid(inputMobileData?.phone_number);
        setCompanyError({ ...companyError, phone_number: !isValidNum });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') {
      setIsExist(false);
    }
    setUserInput({ ...userInput, [name]: value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };
  const handleCompanyChange = (e, name) => {
    if (
      name === 'industry' ||
      name === 'number_of_employees' ||
      name === 'country' ||
      name === 'state'
    ) {
      if (name === 'country') {
        setCompanyDetails({
          ...companyDetails,
          country: e,
          state: '',
          city: '',
          address2: '',
          address1: '',
        });
      } else {
        setCompanyDetails({ ...companyDetails, [name]: e });
      }
    } else {
      const { name, value } = e.target;
      setCompanyDetails({ ...companyDetails, [name]: value });
      setCompanyError((prevErrors) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
  };

  const isContinueDisable =
    isExist ||
    (currentStep === 1 &&
      (userInput?.email?.length === 0 ||
        userInput?.name?.length === 0 ||
        userInput?.password?.length === 0 ||
        Object.values(inputPassword).includes(false))) ||
    (currentStep === 2 && (otp.length < 6)) ||
    (currentStep === 3 &&
      (companyDetails?.company?.length === 0 ||
        companyDetails?.industry?.length === 0 ||
        companyDetails?.number_of_employees?.length === 0 ||
        companyDetails?.company_website?.length === 0 ||
        companyDetails?.company_email?.length === 0 ||
        companyDetails?.country?.length === 0 ||
        companyDetails?.address1?.length === 0 ||
        companyDetails?.city?.length === 0 ||
        ((selectedCountry?.type === 'state' ||
        selectedCountry?.type === 'province')
          && companyDetails?.state?.length === 0
          ) ||
        companyDetails?.postal_code?.length === 0 ||
        inputMobileData?.phone_number?.length === 0));

  /////// TO VALIDATE API ////////
  const validateUser = async (token) => {
    const data = {
      email: userInput.email,
      dca_token: token,
      type: 'validate_user',
    };
    const splitname = userInput.name.split(' ');
    const firstName = splitname[0];
    const send_email = {
      type: 'send_verification_email',
      email: userInput.email,
      dca_token: token,
      first_name: firstName,
    };

    try {
      const validate_response = await axios.post(`${BASE_URL}`, data);
      // setValidationResult(response.data);
      if (validate_response.status === 200) {
        if (validate_response.data.response.result === 'true') {
          const email_response = await axios.post(`${BASE_URL}`, send_email);
          if (email_response.status === 200 || email_response.status === 201) {
            setCurrentStep(2);
            setOtpSent(true);
            setIsLoading(false);
          }
        } else {
          setIsExist(true);
          setIsLoading(false);
        }
      } else {
        // console.log('4444444444444');
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error validating the user:', error);
      setIsLoading(false);
      // setError('Error validating the user');
    }
  };

  const resend_email = async () => {
    setOtpErr(false);
    setIsLoading(true);
    const splitname = userInput.name.split(' ');
    const firstName = splitname[0];
    const send_email = {
      type: 'send_verification_email',
      email: userInput.email,
      dca_token: token,
      first_name: firstName,
    };
    const email_response = await axios.post(`${BASE_URL}`, send_email);
    if (email_response.status === 200) {
      setCounter(30);
      setOtpSent(true);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const verify_otp = async () => {
    const otp_data = {
      type: 'confirm_verification',
      code: otp,
      email: userInput.email,
      dca_token: token,
    };
    try {
      const verify_response = await axios.post(`${BASE_URL}`, otp_data);
      // console.log('verify_response', verify_response);
      if (
        verify_response.status === 200 &&
        verify_response.data.success === 1
      ) {
        setIsLoading(false);
        setCurrentStep((prev) => prev + 1);
      } else {
        setOtpErr(true);
        setIsLoading(false);
      }
    } catch (error) {
      // console.log('error-', error);
      setIsLoading(false);
    }
  };

  const toStoreData = async () => {
    const splitname = userInput.name.split(' ');
    const firstName = splitname[0];

    // Join the remaining elements for the last name
    const lastName = splitname.slice(1).join(' ');
    const storeData = {
      type: 'add_account',
      // api_key: '1137acc32a96ea830fddeaa4975d9b14fd2e68d3',
      api_key: '71a629e3685846a9173884a03f8f559a4d3c6510',
      account: {
        first_name: firstName,
        last_name: lastName,
        // company_id: '10217',
        company_id: '10696',
        contact_source: 'web',
        account_status: 'new_account',
        company_name: companyDetails.company,
        company_website: companyDetails.company_website,
        email: companyDetails.email,
      },
      phones: [
        {
          phone_number: inputMobileData.phone_number,
          phone_type: 'MOBILE',
        },
      ],
      emails: [userInput.email],
      accounts_leads: {
        list_id: '9923013041216',
      },
    };

    axios
      .post(process.env.REACT_APP_BASE_URL, storeData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        // console.log('111', response);
      })
      .catch((err) => {
        // console.log('22222222222');
      });
  };

  //////// TO GET API TOKEN /////////
  const getDcdialAPIToken = async () => {
    // Remote API URL
    setIsLoading(true);
    const data = {
      user: process.env.REACT_APP_USER,
      pass: process.env.REACT_APP_USER_PASS,
      type: 'get_token',
    };

    try {
      const response = await axios.post(`${BASE_URL}`, data);
      if (response.data.response.code === 200) {
        validateUser(response.data.response.dca_token);
        setToken(response.data.response.dca_token);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching the token:', error);
      setIsLoading(false);
    }
  };

  // To Submit Company details
  const sendCompanyDetails = async () => {
    const splitname = userInput.name.split(' ');
    const firstName = splitname[0];

    // Join the remaining elements for the last name
    const lastName = splitname.slice(1).join(' ');
    const payload = {
      ...companyDetails,
      type: 'create_cluster',
      first_name: firstName,
      last_name: lastName,
      pass: userInput.password,
      phone_number: inputMobileData?.phone_number,
      email: userInput.email,
      productcode: '',
      crm_id: '1001',
      referral_survey: 'Yes',
      referral_survey_other: 'Other response',
      couponcode: '',
      dca_token: token,
    };

    try {
      const company_response = await axios.post(`${BASE_URL}`, payload);
      // console.log('RESPONSE', company_response);
      if (
        company_response.status === 200 &&
        company_response.data.response.code === 200
      ) {
        // console.log('----', 'https://payv2-qa.dcdial.com/subscribe');
        setClusterId(company_response.data.response.clusterid);
        const user_data = {
          type: 'create_signup_user',
          full_name: userInput.name,
          last_name: lastName,
          user_level: 'admin',
          email: userInput.email,
          state:
            selectedCountry?.type === 'state' ||
            selectedCountry?.type === 'province'
              ? companyDetails.state
              : '',
          mobile_number: inputMobileData.phone_number,
          signupkey: company_response.data.response.signupkey,
          dca_token: token,
          user: userInput.email,
          pass: userInput.password,
        };
        toStoreData();

        const user_response = await axios.post(`${BASE_URL}`, user_data);
        // console.log('user_response', user_response, clusterId);
        if (user_response.data.response.success === 1) {
          if (pr_id) {
            window.open(
              `http://signup.dcdial.com/${company_response.data.response.signupkey}?id=${pr_id}`,
              '_self'
            );
          } else {
            window.open(
              `http://signup.dcdial.com/${company_response.data.response.signupkey}`,
              '_self'
            );
          }
        }
      } else {
        // console.log('======');
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error', error);
    }
  };

  const createAccount = () => {
    if (currentStep === 1) {
      const formErrors = validateForm('email_details');
      setErrors(formErrors);
      const hasErrors = Object.values(formErrors).some((error) => error);
      if (!hasErrors) {
        // setCurrentStep(2);
        // setOtpSent(true);
        getDcdialAPIToken();
      }
    }
    if (currentStep === 2) {
      setOtpErr(false);
      setIsLoading(true);
      verify_otp();
      // setCurrentStep((prev) => prev + 1);
    }
    if (currentStep === 3) {
      const formErrors = validateForm('company');
      setCompanyError(formErrors);
      const hasErrors = Object.values(formErrors).some((error) => error);
      if (!hasErrors) {
        setIsLoading(true);
        sendCompanyDetails();
      }
    }
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${tokenResponse.access_token}`
        )
        .then((res) => {
          const message = {
            type: 'GOOGLE_LOGIN',
            data: { ...res.data, token: tokenResponse.access_token },
          };
          if (message?.data) {
            console.log('111111111----------', message?.data);
            // googleLogin(message.data);
          }
        })
        .catch((err) => console.log(err));
    },
    onError: () => console.log('Login Failed'),
  });

  // const googleLogin = async (data) => {
  //   const { email, id, given_name, family_name, picture } = data;
  //   const payload = {
  //     email: email,
  //     google_id: id,
  //     first_name: given_name,
  //     last_name: family_name,
  //     profile_picture: picture,
  //     ...(ReferCode && { reference_code: ReferCode }),
  //   };

  //   try {
  //     const res = await loginWithGoogle(payload);
  //     if (res.data?.Error || res.detail) {
  //       if (res.data?.is_deleted) {
  //         navigate('/account-error');
  //         return;
  //       }
  //       console.log(res);
  //       // setErrorMsg({ ...errorMsg, email: res.data.Message });
  //       return;
  //     }

  //     setIsLoading(true);
  //     const { access_token } = res.Result || {};
  //     sendTokenToExe(access_token);
  //     localStorage.setItem('token', JSON.stringify(access_token));
  //     window.location.href = '/profile';
  //   } catch (error) {
  //     console.log(error);
  //     setIsLoading(false);
  //   }
  // };
  return (
    <GradientBg>
      <div className="w-full flex justify-center items-center my-[120px] max_1440:my-10">
        <div className="w-[600px] max_sm:mx-5 h-fit rounded-[20px] bg-[#fff] py-[30px] px-[60px] max_sm:px-5 flex flex-col items-center">
          <div>
            <img src={LogoEn} alt="Logo" />
          </div>

          <p className="mt-[30px] text-[26px] font-bold font-DMSansFont text-LightPrimary leading-[33px] max_sm:text-[22px]">
            {currentStep === 1
              ? 'Create your account'
              : currentStep === 2
              ? 'Verify your email address'
              : 'Company Details'}
          </p>
          <div className="flex flex-col gap-4 mt-7 w-full">
            {currentStep === 1 ? (
              <EmailDetails
                userInput={userInput}
                handleChange={handleChange}
                isContinueDisable={isContinueDisable}
                inputPassword={inputPassword}
                createAccount={createAccount}
                error={errors}
                onSingnUp={login}
                isExist={isExist}
                isLoading={isLoading}
              />
            ) : currentStep === 2 ? (
              <VerifyOtp
                userInput={userInput}
                otp={otp}
                setOtp={setOtp}
                formattedSeconds={formattedSeconds}
                counter={counter}
                resend_email={resend_email}
                isLoading={isLoading}
                otpErr={otpErr}
                handleOtpChange={handleOtpChange}
              />
            ) : (
              <>
                <CompanyDetails
                  companyDetails={companyDetails}
                  handleChange={handleCompanyChange}
                  setInputMobileData={setInputMobileData}
                  inputMobileData={inputMobileData}
                  error={companyError}
                  userCountry={userCountry}
                  setErrors={setCompanyError}
                  handleOnBlur={handleOnBlur}
                  handleBlurURL={handleBlurURL}
                  selectedCountry={selectedCountry}
                  setSelectedCountry={setSelectedCountry}
                  // handleBlurAdd1={handleBlurAdd1}
                  // handleBlurCity={handleBlurCity}
                />
              </>
            )}
          </div>

          {currentStep === 1 && (
            <div className="mt-4 font-DMSansFont text-textExtraSmall flex gap-[5px] items-center">
              <span className=" text-LightSecondary font-medium">
                Already have an account?
              </span>
              <a
                href="https://www.dcdial.com/app/login"
                rel="noopener
              noreferrer"
              >
                <button
                  className="text-ButtonPrimary"
                  onClick={() => {
                    // navigate('/login');
                  }}
                >
                  Log in
                </button>
              </a>
            </div>
          )}
          {(currentStep === 2 || currentStep === 3) && (
            <>
              <div className="mt-[30px] w-full">
                <ButtonFilled
                  title="Next"
                  fontSize="16px"
                  fontWeight="500"
                  height="48px"
                  disabled={isContinueDisable}
                  onClick={createAccount}
                  isLoading={isLoading}
                />
              </div>
              {currentStep === 2 && (
                <div className="mt-[6px] F-JC-AI-CENTER">
                  <p
                    className="text-textSmall font-DMSansFont font-medium px-4 py-[5px] text-center text-ButtonPrimary cursor-pointer w-max"
                    onClick={() => {
                      setCurrentStep((prev) => prev - 1);
                      setCounter(30);
                      setOtp('');
                      setOtpErr(false);
                    }}
                  >
                    Go Back
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </GradientBg>
  );
};

export default SignUp;
