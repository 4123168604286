import React, { useEffect, useRef, useState } from 'react';
import {
  SyncLine,
  SyncLine1440,
  SyncLineTab,
} from '../../../assets/Icons/Products/ProductsIcon';
import { useTranslation } from 'react-i18next';
import {
  Bitrix24Icon,
  ClosePIcon,
  DataManagement,
  DCDIALLogo,
  FreshsalesIcon,
  KeapIcon,
  KommoIcon,
  MeaningfulInteractions,
  PersonalizedInsights,
  PipDriveIcon,
  SalesforceIcon,
  ZOHOIcon,
} from '../../../assets/Icons/IntegrationPayment/paymentIcon';
import { useWindowSize } from '../../../Hooks/useWindowSize';
import CommonSizeContainer from '../../CommonSizeContainer';
import CardContainer from '../CardContainer';

const SynchronizeAndCustomizeCRm = () => {
  const { t } = useTranslation();
  const animateRef = useRef();
  const animateRefRes = useRef();
  const [customizeCRMData, setCustomizeCRMData] = useState([]);
  const [isAnimating1, setIsAnimating1] = useState(false);
  const [isAnimating2, setIsAnimating2] = useState(false);
  

  const [index1, setIndex1] = useState(0);
  const [index2, setIndex2] = useState(0);

  const array1 = [
    <SalesforceIcon />,
    <ZOHOIcon />,
    <ClosePIcon />,
    <Bitrix24Icon />,
  ];

  const array2 = [
    <KeapIcon />,
    <KommoIcon />,
    <PipDriveIcon />,
    <FreshsalesIcon />,
  ];

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setIndex1((prevIndex) => (prevIndex + 1) % array1.length);
  //     setIndex2((prevIndex) => (prevIndex + 1) % array2.length);
  //   }, 2000);

  //   return () => clearInterval(interval);
  // }, [array1.length, array2.length]);

  // const getStyledIcon = (iconArray, index) => {
  //   const icon = iconArray[index];
  //   const style = {
  //     width: isTabView ? 40 : isSmallDesktop ? 60 : 70,
  //     height: isTabView ? 40 : isSmallDesktop ? 60 : 70,
  //   };
  //   return React.cloneElement(icon, { style });
  // };

  const { width: windowWidth } = useWindowSize();
  const isSmallDesktop = windowWidth <= 1440;
  const isTabView = windowWidth <= 980;
  const isMobileView = windowWidth <= 600;
  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating1(true);
      setIsAnimating2(true);
      setTimeout(() => {
        setIndex1((prevIndex) => (prevIndex + 1) % array1.length);
        setIndex2((prevIndex) => (prevIndex + 1) % array2.length);
      }, 300);
    }, 2900);

    return () => clearInterval(interval);
  }, [array1.length, array2.length]);

  useEffect(() => {
    if (isAnimating1) {
      setTimeout(() => setIsAnimating1(false), 300);
    }
    if (isAnimating2) {
      setTimeout(() => setIsAnimating2(false), 300);
    }
  }, [index1, index2]);

  const getStyledIcon = (iconArray, index, isAnimating) => {
    const icon = iconArray[index];
    const style = {
      width: isMobileView ? 30 :isTabView ? 40 : isSmallDesktop ? 60 : 70,
      height: isMobileView? 30 : isTabView ? 40 : isSmallDesktop ? 60 : 70,
      transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
      opacity: isAnimating ? 0 : 1,
      transform: isAnimating ? 'scale(0.5)' : 'scale(1)',
    };
    return React.cloneElement(icon, { style });
  };

  // const isAnimating = useVisibility(animateRef, 0.7);
  // const isAnimatingRes = useVisibility(animateRefRes, 0.7);
  // const [startAnim, setStartAnim] = useState(false);
  // const [startAnimRes, setStartAnimRes] = useState(false);

  useEffect(() => {
    setCustomizeCRMData(getTranslatedList(t));
  }, [t]);

  // useEffect(() => {
  //   if (!startAnim && isAnimating) {
  //     setStartAnim(true);
  //   }
  //   if (isMobileView && !startAnimRes && isAnimatingRes) {
  //     setStartAnimRes(true);
  //   }
  // }, [isAnimating]);
  return (
    <>
      <div
        className="m-auto w-full max-w-[1920px] mt-[150px] max_xl:mt-[100px] max_sm:mt-[60px]"
        id="Synchronize"
      >
        <div className="m-auto max-w-[872px] max_md:px-10 max_sm:px-5">
          <h2 className="text50Product text-center">{t('SynchronizeCRM')}</h2>
          <p className="paragraphText font-normal text-center mt-4">
            {t('CRMDataSyncingText')}
          </p>
        </div>
        <div
          className="w-full mt-[-60px] relative max_1440:mt-[-60px] max_md:mt-[-20px] pb-[50px] max_800:hidden"
          ref={animateRef}
        >
          {isSmallDesktop ? (
            <SyncLine1440 style={{ width: '100%' }} />
          ) : (
            <SyncLine style={{ width: '100%' }} />
          )}

          <div
            className="absolute top-10 max_1680:top-12 max_md:top-20 left-[23%] max_1440:left-[15%] bg-white p-5 max_1440:p-[14px] rounded-[20px]"
            style={{ boxShadow: '0px 12px 30px 0px #3C425714' }}
          >
            {getStyledIcon(array1, index1, isAnimating1)}
          </div>
          <div className="absolute top-16 max_1680:top-11 max_1500:top-9 left-[39%] max_1440:top-[106px] max_xl:top-[70px] max_1366:top-[80px] max_1440:left-[37%] max_xl:left-[36%] max_xl1160:left-[35%] max_md:left-[33.5%]">
            <DCDIALLogo
              style={{
                width: isTabView ? 280 : isSmallDesktop ? 330 : 380,
                height: isTabView ? 280 : isSmallDesktop ? 330 : 380,
              }}
            />
          </div>
          <div
            className="absolute top-10 max_1680:top-12 max_md:top-20 right-[23%] max_1440:right-[15%] bg-white p-5 max_1440:p-[14px] rounded-[20px]"
            style={{ boxShadow: '0px 12px 30px 0px #3C425714' }}
          >
            {getStyledIcon(array2, index2, isAnimating2)}
          </div>
        </div>
        {/* Tab Mobile view RESPONSIVE //////  */}
        <div className="hidden max_800:block mt-5 mb-[-50px]">
          {/* <img src={SyncImg} alt="SyncImg" /> */}
          <div className="relative">
            <SyncLineTab style={{ width: '100%' }} />

            <div className="icon-shadow absolute top-9 left-[14%] max_720:left-[12%] max_600:left-[13%] max_600:top-16 max_sm:top-20  bg-white p-[10px] max_600:p-[6px] rounded-[20px]">
              {getStyledIcon(array1, index1, isAnimating1)}
            </div>
            <div className="icon-shadow absolute top-9 right-[14%] max_720:right-[12%] max_600:right-[13%] max_600:top-16 max_sm:top-20 bg-white p-[10px] max_600:p-[6px] rounded-[20px]">
              {getStyledIcon(array2, index2, isAnimating2)}
            </div>
          </div>
        </div>
        <div id="Customizable"></div>
      </div>
      <CommonSizeContainer>
        <h2 className="text50Product text-center">
          {t('CustomizableCRMFields')}
        </h2>
        <p className="paragraphText font-normal mt-4 text-center">
          {t('CustomizableText')}
        </p>
        <div className="max_md:hidden max_720:block">
          <div className="grid grid-cols-3 gap-6 max_xl:gap-4 mt-[60px] max_720:mt-10 max_720:grid-cols-1 max_720:gap-5">
            {customizeCRMData?.map((service, index1) => (
              <CardContainer key={index1} service={service} NoMinHeight />
            ))}
          </div>
        </div>
        <div className="hidden max_md:block max_720:hidden mt-[60px]">
          <div className="flex justify-center">
            <CardContainer service={customizeCRMData[0]} isFixWidth />
          </div>
          <div className="flex justify-center gap-[30px] mt-[30px]">
            <CardContainer service={customizeCRMData[1]} isFixWidth />
            <CardContainer service={customizeCRMData[2]} isFixWidth />
          </div>
        </div>
      </CommonSizeContainer>
    </>
  );
};
const getTranslatedList = (t) => {
  const lists = [
    {
      title: t('DataManagement'),
      text: t('DataManagementT'),
      icon: <DataManagement />,
    },
    {
      title: t('PersonalizedInsights'),
      text: t('PersonalizedInsightsT'),
      icon: <PersonalizedInsights />,
    },
    {
      title: t('DataInsightS'),
      text: t('DataInsightT'),
      icon: <MeaningfulInteractions />,
    },
  ];

  return lists;
};

export default SynchronizeAndCustomizeCRm;
