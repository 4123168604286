import React from 'react';
import { useTranslation } from 'react-i18next';
import CommonSection from '../CommonSection';

const HeroSection = () => {
  const { t } = useTranslation();
  return (
    <CommonSection>
      <h1 className="headingText text-center">{t('DCDialPricing')}</h1>
      <div className="flex gap-[30px] mt-[40px] justify-center max_md:gap-6 max_800:flex-wrap max_sm:gap-y-4">
        <p className="text-subHeadingTxt font-DMSansFont font-semibold text-LightTextParagraph max_md:text-headingNormal max_md:font-normal max_sm:text-textSmall">
          {t('NoSetupFee')}
        </p>
        <p className="text-subHeadingTxt font-DMSansFont font-semibold text-LightTextParagraph max_md:text-headingNormal max_md:font-normal max_sm:text-textSmall">
          {t('CancelAnytime')}
        </p>
        <p className="text-subHeadingTxt font-DMSansFont font-semibold text-LightTextParagraph max_md:text-headingNormal max_md:font-normal max_sm:text-textSmall">
          {t('SavePlan')}
        </p>
      </div>
    </CommonSection>
  );
};

export default HeroSection;
