import React from 'react';
import { ReactComponent as Grad1 } from '../../assets/images/Login/Bg1.svg';
import { ReactComponent as Grad2 } from '../../assets/images/Login/Bg2.svg';

const GradientBg = ({ children }) => {
  return (
    <div className="relative h-screen overflow-hidden bg-[#F9F9FB]">
      <Grad1 className="absolute z-10 -top-[80px] -left-[100px] opacity-30" />
      <Grad2 className="absolute z-10 bottom-[-100px] -right-[100px] opacity-30" />
      <div className="absolute inset-0 z-20 h-screen overflow-auto">
        {children}
      </div>
    </div>
  );
};

export default GradientBg;
