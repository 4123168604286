import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const DigitalServiceContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [serviceData, setServiceData] = useState([]);
  useEffect(() => {
    setServiceData(getTranslatedList(t));
  }, [t]);
  const handleOnClick = (event) => {
    let element = event.target;
    while (element != null && !element.classList.contains('underlineSpan')) {
      element = element.parentElement;
    }

    if (element && element.dataset.url) {
      if (element.dataset.url.includes('https://')) {
        window.open(element.dataset.url, '_blank');
      } else {
        navigate(element.dataset.url, { state: 'send' });
        localStorage.setItem('scrollPositionSD', window.scrollY);
      }
    }
  };
  return (
    <div>
      <p className="text40class text-LightPrimary">{t('ForEveryone5Title')}</p>
      <p className="mt-5 paragraphText">{t('LastModifyDate')}</p>
      <p className="smallText16Normal mt-10">{t('ServiceT1')}</p>
      <p className="smallText16Normal mt-4">{t('ServiceT2')}</p>

      <div className="mt-[30px] flex flex-col gap-4">
        {serviceData?.map((list, index) => (
          <React.Fragment key={index}>
            <p className="mt-4 paragraphText text-[18px] font-bold">
              {list.title}
            </p>
            {list.text && (
              <>
                {list.text.map((innerText, index1) => (
                  <p
                    key={index1}
                    className="smallText16Normal"
                    dangerouslySetInnerHTML={{ __html: innerText }}
                    onClick={handleOnClick}
                  ></p>
                ))}
              </>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
const getTranslatedList = (t) => {
  const lists = [
    {
      title: t('Service_1'),
      text: [t('Service_1_1')],
    },
    {
      title: t('Service_2'),
      text: [t('Service_2_1')],
    },
    {
      title: t('Service_3'),
      text: [t('Service_3_1')],
    },
    {
      title: t('Service_4'),
      text: [t('Service_4_1'), t('Service_4_2')],
    },
    {
      title: t('Service_5'),
      text: [t('Service_5_1')],
    },
  ];
  return lists;
};

export default DigitalServiceContent;
