import React from 'react';
import { ButtonFilled } from '../utils/Button';
import Rectangle from '../../assets/images/About Us/Rectangle.png';
import Star from '../../assets/images/About Us/Star.png';
import Ellipse from '../../assets/images/About Us/Ellipse.png';
import Polygon from '../../assets/images/About Us/Polygon.png';
import { useWindowSize } from '../../Hooks/useWindowSize';

const LayoutWithBGLayer = ({
  title,
  text,
  buttonText,
  link,
  handleClick,
  isLink,
}) => {
  const { width: windowWidth } = useWindowSize();

  const isMobileView = windowWidth <= 500;
  return (
    <div className="relative w-full bg-BgPrimary rounded-[20px] px-20 py-[60px] flex flex-col gap-10 overflow-hidden max_xl:py-10 max_md:py-[45px] max_md:gap-5 max_md:px-[30px]">
      <p className="text-text4xl font-DMSansFont font-bold text-LightPrimary text-center px-6 leading-[65px] max_xl:px-0 max_md:text-[24px]  max_md:leading-[31px] z-10">
        {title}
      </p>
      <p className="paragraphText text-center px-4 max_xl:px-0 z-10">{text}</p>
      <div className="w-fit m-auto">
        {isLink ? (
          <a href={link} rel="noopener noreferrer" target="_blank">
            <ButtonFilled
              title={buttonText}
              height={isMobileView ? '45px' : '60px'}
              fontSize={isMobileView ? '16px' : '20px'}
              width={isMobileView ? '184px' : '230px'}
              rounded="10px"
              fontWeight="500"
            />
          </a>
        ) : (
          <ButtonFilled
            title={buttonText}
            height={isMobileView ? '45px' : '60px'}
            fontSize={isMobileView ? '16px' : '20px'}
            width={isMobileView ? '184px' : '230px'}
            rounded="10px"
            fontWeight="500"
            onClick={handleClick}
          />
        )}
      </div>

      {/* VECTORS ABSOLUTE */}
      <div className="absolute bottom-0 left-0 w-[6%] max_sm:w-[9%]">
        <img src={Rectangle} alt="reactangle" />
      </div>
      <div className="absolute bottom-0 right-[200px] max_md:w-[10%] max_sm:w-[13%] max_md:right-[110px] max_sm:right-16">
        <img src={Star} alt="Star" />
      </div>
      <div className="absolute top-5 right-0 max_md:w-[5%] max_sm:w-[9%]">
        <img src={Ellipse} alt="Ellipse" />
      </div>
      <div className="absolute top-3 left-10 w-[3%] max_sm:w-[6%]">
        <img src={Polygon} alt="Ellipse" />
      </div>
    </div>
  );
};

export default LayoutWithBGLayer;
