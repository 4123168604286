import React from 'react';
import TagsComp from '../utils/TagsComp';
import { useWindowSize } from '../../Hooks/useWindowSize';

const BgCenterComp = ({
  background,
  title,
  text,
  tagText,
  data,
  children,
  id,
  noChange,
}) => {
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 500;
  return (
    <div
      id={id}
      className={`mt-[150px] max_xl:mt-[100px] max_sm:mt-[60px] bg-BgPrimary ${
        background === '#EDF8FF'
          ? 'pt-[100px] max_md:pt-[60px] max_sm:pt-10 max_md:pb-[60px] max_sm:pb-10'
          :""
      }`}
      style={{ backgroundColor: background ? background : '' }}
    >
      <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] max_md:px-10 max_sm:px-5">
        <div className="flex justify-between max_xl:gap-[60px] max_sm:gap-10 max_md:flex-col">
          <div className="flex flex-col gap-6 max-w-[500px] max_xl:max-w-[450px] max_md:max-w-full max_md:gap-4">
            {tagText && (
              <TagsComp
                px="16px"
                py="8px"
                text={tagText}
                color="#00A0FC"
                borderColor="#00A0FC"
              />
            )}

            <h2 className="text50Product max_md:mt-[14px] max_sm:mt-0">
              {title}
            </h2>
            <p className="paragraphText">{text}</p>
          </div>
          <div className={`${noChange ? 'hidden' : 'hidden max_md:block'}`}>
            {children}
          </div>{' '}
          <div className="flex flex-col gap-10 max-w-[500px] max_md:max-w-full">
            {data.map((details, index) => (
              <div className="flex gap-3" key={index}>
                <div>
                  {' '}
                  {React.cloneElement(details.icon, {
                    style: {
                      width: isMobileView ? 32 : 40,
                      height: isMobileView ? 32 : 40,
                    },
                  })}
                </div>
                <div>
                  <p className="subHeadingText">{details.name}</p>
                  <p className="smallText18 font-medium text-LightTextParagraph mt-3 leading-[23px] max_sm:text-textSmall">
                    {details.text}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={`${noChange ? '' : 'max_md:hidden'}`}>{children}</div>
      </div>
    </div>
  );
};

export default BgCenterComp;
