import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../Layout';
import HeroSection from '../../HeroSection';
import CommonProductBanner from '../CommonProductBanner';
import { useTranslation } from 'react-i18next';
import AIChartComp from './AIChartComp';
import AITransformAndChatbot from './AITransformAndChatbot';
import BgCenterComp from '../BgCenterComp';

import {
  ConsistencyPrecision,
  CustomerCare,
  SwiftInfo,
} from '../../../assets/Icons/Products/ProductsIcon';
import VirtualAssistant from './VirtualAssistant';
import Lottie from 'react-lottie';
// import AIChatAnimation from '../../../animation/en/Product/DCDial_Chat.json';
import useVisibility from '../../../Hooks/useVisibility';
import TitleDescWithKeyword from '../../Helmet/TitleDescWithKeyword';

const AIAssitant = () => {
  const { t } = useTranslation();
  const AIChatRef = useRef();
  const containerRef = useRef();
  const [customerToolData, setCustomerToolData] = useState([]);
  const [animationIsInview, setAnimationIsInView] = useState(false);
  const isInView = useVisibility(containerRef, 0.2);
  const [animationData, setAnimationData] = useState(null);
  useEffect(() => {
    if (isInView && !animationIsInview) {
      setAnimationIsInView(true);
    }
  }, [isInView, animationIsInview]);
  useEffect(() => {
    setCustomerToolData(getTranslatedList(t, 'knowledge_tool'));
  }, [t]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const AIChatAnimationData = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: AIChatAnimation,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice',
  //   },
  // };

  useEffect(() => {
    const fetchAnimationData = async () => {
      try {
        const response = await fetch(
          'https://images.dcdial.com/dcdial.com/v2/DCDial_Chat.json'
        );
        const data = await response.json();
        setAnimationData(data);
      } catch (error) {
        console.error('Error fetching animation data:', error);
      }
    };

    fetchAnimationData();
  }, []);

  const AIChatAnimationData = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      <TitleDescWithKeyword
        title="DCDial | AI-Powered Tools"
        description="Boost agent productivity, automate workflows, and provide 24/7 support with chatbots and voicebots for seamless customer interactions."
        keywords="AI-powered customer service tools, Agent productivity with AI, AI-driven customer service automation, Real-time agent guidance, First-contact resolution improvement, Reduce customer service costs with AI, Workflow automation, AI task prioritization, Prevent agent burnout with AI, AI chatbots, 24/7 chatbot availability, Instant chatbot responses, Scalable chatbot solutions, Predictive customer engagement, AI upsell and cross-sell opportunities, Real-time sentiment analysis, AI-driven data insights, Personalized customer service with AI, AI knowledge management tools, Swift information retrieval, Consistent customer support responses, Personalized customer recommendations, AI virtual assistant, AI email assistant, AI meeting scheduler, Live chat with AI, Website chatbot solutions, SMS assistant with AI, AI-generated reports and insights, Voicebots with natural language understanding"
        isPageURL="https://dcdial.com/products/ai-assistant"
        isImageURL="https://images.dcdial.com/dcdial.com/v2/AIAssistant.svg"
      />
      <Layout>
        <HeroSection title={t('AIAssistant')} text={t('DiscoverAIText')} />
        <CommonProductBanner
          tagText={t('AgentAssistanceU')}
          title={t('AgentAssistanceUT')}
          text={t('AgentAssistanceUText')}
          id="AgentAssistance"
        />

        <AIChartComp />

        <AITransformAndChatbot />

        <BgCenterComp
          title={t('KnowledgeToolsTitle')}
          text={t('KnowledgeToolsText')}
          tagText={t('KnowledgeTools')}
          background="#EDF8FF"
          data={customerToolData}
          id="KnowledgeTools"
          noChange
        >
          <div
            className={`flex justify-center mt-[60px] w-[85%] max_md:w-full m-auto ${
              !animationIsInview
                ? 'min-h-[740px] max_xl:min-h-[584px] max_md:min-h-[492px]'
                : ''
            }`}
            ref={containerRef}
          >
            {/* <img src={knowledgeToolImg} alt="OutboundSec" className="" /> */}
            {/* {animationIsInview && (
              <Lottie options={AIChatAnimationData} ref={AIChatRef} />
            )} */}
            {animationIsInview && animationData && (
              <Lottie options={AIChatAnimationData} ref={AIChatRef} />
            )}
          </div>
        </BgCenterComp>

        <VirtualAssistant />
      </Layout>
    </>
  );
};
const getTranslatedList = (t, listKey) => {
  const lists = {
    knowledge_tool: [
      {
        name: t('SwiftInfo'),
        text: t('SwiftInfoT'),
        icon: <SwiftInfo />,
      },
      {
        name: t('ConsistencyPrecision'),
        text: t('ConsistencyPrecisionT'),
        icon: <ConsistencyPrecision />,
      },
      {
        name: t('CustomerCare'),
        text: t('CustomerCareT'),
        icon: <CustomerCare />,
      },
    ],
  };

  return lists[listKey];
};

export default AIAssitant;
