import React from 'react'
import Layout from '../Components/Layout'
import HeroSection from '../Components/HeroSection'
import { useTranslation } from 'react-i18next'
import AllNewProducts from '../Components/Products/AllNewProducts'
import TitleAndDesc from '../Components/Helmet/TitleAndDesc'

const Products = () => {
  const {t} = useTranslation()
  return (
    <>
      <TitleAndDesc
        title="DCDial | Explore All Products"
        description="Explore automated dialing, SMS/email campaigns, secure transactions, invoicing, recurring billing, and detailed analytics."
        isImageURL="https://images.dcdial.com/dcdial.com/v2/CRM.svg"
        isPageURL="https://dcdial.com/products"
      />
      <Layout>
        <HeroSection
          title={t('ProductHeading')}
          text={t('ProductText')}
          isButton
        />

        <AllNewProducts />
      </Layout>
    </>
  );
}

export default Products