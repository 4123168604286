import React, { useEffect } from 'react';
import Layout from '../../Components/Layout';
import LegalSideLayout from '../../Components/LegalSideLayout';
import PrivacyPolicyContent from '../../Components/Legal/PrivacyPolicyContent';
import TitleAndDesc from '../../Components/Helmet/TitleAndDesc';
import { useLocation } from 'react-router-dom';

const PrivacyPolicy = () => {
  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    if (state && state !== null) {
      window.scrollTo(0, 0);
    }
  }, [state]);

  var scrollPosition = localStorage.getItem('scrollPositionPP');
  if (scrollPosition !== null) {
    window.scrollTo(0, parseInt(scrollPosition));
  }
  return (
    <>
      <TitleAndDesc
        title="Privacy Policy"
        description="DCDial's Privacy Policy explains how we collect, use, and protect your personal data, ensuring transparency and confidentiality."
      />
      <Layout>
        <LegalSideLayout>
          <PrivacyPolicyContent />
        </LegalSideLayout>
      </Layout>
    </>
  );
};

export default PrivacyPolicy;
