import React from 'react';
import CommonSizeContainer from '../CommonSizeContainer';
import { useTranslation } from 'react-i18next';

const MissionAndVission = () => {
  const {t} = useTranslation()
  return (
    <CommonSizeContainer>
      <div className="flex gap-20 max_xl:gap-10 max_md:flex-col max_md:max-w-[580px] max_md:gap-20 max_sm:gap-10 m-auto">
        <div className="p-10 rounded-[20px] max_xl:p-7">
          <p className="text40class text-LightPrimary">{t('OurMission')}</p>
          <p className="mt-6 max_xl:mt-4 subHeadingText font-medium text-LightTextParagraph max_sm:text-textSmall max_sm:mt-6">
            {t('OurMissionText')}
          </p>
        </div>          
        <div className="p-10 rounded-[20px] bg-LightPrimary max_xl:p-7">
          <p className="text40class text-white">{t('OurVision')}</p>
          <p className="mt-6 max_xl:mt-4 subHeadingText font-medium text-white max_sm:text-textSmall max_sm:mt-6">
            {t('OurVisionText')}
          </p>
        </div>
      </div>
    </CommonSizeContainer>
  );
};

export default MissionAndVission;
