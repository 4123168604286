import React, {  useState } from 'react';
import { useTranslation } from 'react-i18next';
import DownArrow from '../../assets/images/Homepage/arrow-down.svg';
import ArrowUp from '../../assets/images/Homepage/arrow-up.svg';

const FAQComponent = ({ data,isMargin }) => {
  const { t } = useTranslation();
  const [openQuestionIndex, setOpenQuestionIndex] = useState(-1);
  return (
    <>
      <div
        style={{
          zIndex: 20,
        }}
        className={``}
      >
        <div className={`z-40 m-auto max-w-[1040px] max_xl:max-w-[980px] max_md:px-[40px] max_sm:px-[20px] pb-[160px] max_xl:pb-[90px] max_md:pb-[120px] max_sm:pb-[60px] ${isMargin ? 'mt-[150px] max_xl:mt-[100px]':''}`}>
          <div className="flex flex-col">
            <h2 className="headingText text-center">{t('FAQ')}</h2>
            <div className="flex flex-col mt-5">
              {data?.map((list, index) => {
                return (
                  <React.Fragment key={index + 1}>
                    <div
                      className="py-10 max_md:py-5 border-b-[1px] border-LightOutline cursor-pointer"
                      onClick={() => {
                        if (openQuestionIndex === index) {
                          setOpenQuestionIndex(-1);
                        } else {
                          setOpenQuestionIndex(index);
                        }
                      }}
                    >
                      <div className="flex justify-between">
                        <div
                          className={`subHeadingText tracking-[-1px] ${
                            openQuestionIndex === index
                              ? 'text-LightPrimary font-bold'
                              : 'text-LightSecondary font-normal'
                          }  w-[95%]`}
                        >
                          {list.question}
                        </div>
                        <div className="w-[30px]">
                          <img
                            src={
                              openQuestionIndex === index ? ArrowUp : DownArrow
                            }
                            alt="Downarrow"
                            className={`${
                              openQuestionIndex !== index
                                ? 'rotate-[360deg]'
                                : 'rotate-180'
                            } transition-all`}
                          />
                        </div>
                      </div>
                      <div
                        className={`answerSection ${
                          openQuestionIndex === index ? 'open' : ''
                        }`}
                      >
                        <div
                          className={`tracking-[-1px] subParagraphText leading-[30px] mt-2 w-[90%] max_sm:w-[97%] `}
                        >
                          {list.answer}
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQComponent;
