import React, { useEffect } from 'react'
import Layout from '../Components/Layout'
import HeroSection from '../Components/HeroSection';
import { useTranslation } from 'react-i18next';
import TitleAndDesc from '../Components/Helmet/TitleAndDesc';

const Blogs = () => {
  const {t} = useTranslation()
  // useEffect(() => {
  //   fetch('http://localhost:1337/api/blogs', {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization:
  //         'bearer 9b5fc9ba16f26c029ade3b91c3b24423bd49ba347a376b0e93961d33d667d8eefba251b57a46c9f91abd0d1b98e3c9f1bb630d9c264d74ae004c072c54ee9e35f34c0adc0bd46740bd2becc149d495dd9f6ce03044e017d0b8b37f31d7620f3307594763bbe981a9bda0f8c0dc8fd8ed3599af572cf8591b1861d28d41c4f10c',
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => console.log(data));
  // },[])
 
  return (
    <>
      <TitleAndDesc
        title="DCDial Blog"
        description="Stay updated with the latest insights and trends in business communication and automation on the DCDial Blog. Discover expert tips, product updates, and industry news."
      />
      <Layout>
        <HeroSection
          title={t('RidingTechwave')}
          text={t('RidingTechwaveText')}
        />
      </Layout>
    </>
  );
}

export default Blogs

// useEffect(() => {
//   const fetchData = async () => {
//     const spaceId = 'your_space_id';
//     const accessToken = 'your_access_token';
//     const contentTypeId = 'yourContentTypeId';

//     try {
//       const response = await fetch(
//         `https://cdn.contentful.com/spaces/31w0pgvdf7co/environments/master/entries?access_token=1SjkkQeo2QU4BgFwsnlZe8sH2jHc3LB1XwtckCpvUjE&content_type=componentQuote`
//       );
//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }
//       const data = await response.json();
//       console.log('::::--', data);
//       //  setEntries(data.items);
//     } catch (error) {
//       console.log('ERROR', error);
//       //  setError(error.message);
//     } finally {
//       //  setIsLoading(false);
//     }
//   };

//   fetchData();
// }, []);
