import React, { useEffect, useState } from 'react'
import CommonSizeContainer from '../../CommonSizeContainer'
import { useTranslation } from 'react-i18next'
import { HubspotIcon, SalesforceIcon, StripeIcon, ZapierIcon } from '../../../assets/Icons/IntegrationPayment/paymentIcon'
import CommonAppsComp from '../../IntegrationAndPayments/CommonAppsComp'
import { useNavigate } from 'react-router-dom'
import { useWindowSize } from '../../../Hooks/useWindowSize'

const ThirdpartyIntegration = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { width: windowWidth } = useWindowSize();
  const isTabView = windowWidth <= 980;
  const [appList, setAppList] = useState([])
  const [hoverIndex, setHoverIndex] = useState(null);
  useEffect(() => {
    setAppList(getTranslatedList(t, 'appList'));
  },[t])
  return (
    <CommonSizeContainer>
      <div className="flex flex-col gap-[60px]" id="Thirdparty">
        <div className="flex items-center flex-col gap-4">
          <h2 className="text50Product">{t('ThirdPartyIntegration')}</h2>
          <p className="paragraphText font-normal text-center">
            {t('ThirdPartyText')}
          </p>
          <div
            className="hero-button-shadow max_md:mt-[14px] rounded-lg border-[1px] border-LightOutline w-fit m-auto px-5 py-3 cursor-pointer max_sm:py-[10px] max_sm:px-3"
            onClick={() => navigate('/apps-payments')}
          >
            <p className="smallText18 text-ButtonPrimary font-medium max_md:text-textSmall">
              {t('ViewIntegrations')}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-[28px] max_md:grid-cols-2 max_600:grid-cols-1 max_600:gap-[30px]">
          {appList?.map((app, index1) => {
            return (
              <React.Fragment key={index1}>
                {/* <a href={app.link} rel="noopener noreferrer" target="_blank"> */}
                <CommonAppsComp
                  index={index1}
                  app={app}
                  hoverIndex={hoverIndex}
                  setHoverIndex={setHoverIndex}
                  isLearnMore
                />
                {/* </a> */}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </CommonSizeContainer>
  );
}
const getTranslatedList = (t, listkey) => {
  const lists = {
    appList: [
      {
        name: 'Hubspot',
        icon: <HubspotIcon />,
        description: t('HubspotDesc'),
        tags: [t('Marketing'), t('Automation')],
        bgColor: '#FFFBFA',
        color: '#FF7A59',
        link: '/apps-payments',
      },
      {
        name: 'Salesforce',
        icon: <SalesforceIcon />,
        description: t('SalesforceDesc'),
        tags: [t('CRM')],
        bgColor: '#E8F8FF',
        color: '#00A1E0',
        link: '/apps-payments',
      },
      {
        name: 'Stripe',
        icon: <StripeIcon />,
        description: t('StripeDesc'),
        tags: [t('PaymentProcessing')],
        bgColor: '#F5F4FF',
        color: '#635BFF',
        link: '/apps-payments',
      },
      // {
      //   name: 'Zapier',
      //   icon: <ZapierIcon />,
      //   description: t('ZapierDesc'),
      //   tags: [t('Marketing'), t('Automation')],
      //   bgColor: '#FFFBFA',
      //   color: '#FF4A00',
      //   link: 'https://zapier.com/',
      // },
    ],
  };
  return lists[listkey];
};

export default ThirdpartyIntegration