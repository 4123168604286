import React from 'react';
import { TextField } from '../utils/InputField';
import PasswordValidator from './PasswordValidator';
import { ButtonFilled, Socialbutton } from '../utils/Button';
import { GoogleIcon } from '../../assets/Icons/icons';

const EmailDetails = ({
  userInput,
  handleChange,
  inputPassword,
  isContinueDisable,
  createAccount,
  onSingnUp,
  error,
  isExist,
  isLoading,
}) => {
  return (
    <>
      <TextField
        type="text"
        placeholder="Enter your full name"
        name="name"
        label="Name"
        value={userInput.name}
        handleOnChange={handleChange}
        error={error.name}
        errorMsg="Please enter a valid name."
      />
      <div>
        <TextField
          type="text"
          placeholder="Enter your email"
          name="email"
          label="Email"
          value={userInput.email}
          handleOnChange={handleChange}
          error={error.email}
          errorMsg="Please enter a valid email address"
        />
        {isExist && (
          <p className="smallText16 text-[#ff0000] max_sm:text-[14px] ml-[2px] max_sm:ml-0 mt-[1px]">
            This email is already registered
          </p>
        )}
      </div>
      <TextField
        type="password"
        placeholder="Enter your password"
        name="password"
        label="Password"
        value={userInput.password}
        handleOnChange={handleChange}
        error={error.password}
      />

      {userInput.password !== '' && (
        <PasswordValidator require={inputPassword} />
      )}

      <div className="mt-5">
        <ButtonFilled
          title="Create account"
          fontSize="16px"
          fontWeight="500"
          height="48px"
          disabled={isContinueDisable}
          onClick={createAccount}
          isLoading={isLoading}
        />
      </div>

      {/* <div className="h-[1px] bg-LightOutline w-full min-h-[1px] relative mt-3">
        <p className="absolute top-[-8px] left-1/2 text-textExtraSmall font-DMSansFont leading-[16px] font-bold text-LightBright px-2 bg-white w-fit -translate-x-1/5">
          Or
        </p>
      </div> */}

      {/* <div className="mt-2">
        <Socialbutton
          icon={<GoogleIcon />}
          text={'Continue with Google'}
          onClick={() => onSingnUp()}
        />
      </div> */}
    </>
  );
};

export default EmailDetails;
