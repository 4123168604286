import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BulletPoints from '../BulletPoints';
import { useNavigate } from 'react-router-dom';

const DeveloperPolicyContent = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const [devPolicy, setDevPolicy] = useState([]);
  useEffect(() => {
    setDevPolicy(getTranslatedList(t, 'policyList'));
  }, [t]);
  const handleOnClick = (event) => {
    let element = event.target;
    while (element != null && !element.classList.contains('underlineSpan')) {
      element = element.parentElement;
    }

    if (element && element.dataset.url) {
      if (element.dataset.url.includes('https://')) {
        window.open(element.dataset.url, '_blank');
      } else {
        navigate(element.dataset.url, { state: 'send' });
        localStorage.setItem('scrollPositionD', window.scrollY);
      }
    }
  };
  return (
    <div>
      <p className="text40class text-LightPrimary">{t('DeveloperPolicy')}</p>
      <p className="mt-5 paragraphText">{t('LastModifyDate')}</p>
      <div className="mt-[30px] flex flex-col gap-4">
        <p
          className="smallText16Normal"
          dangerouslySetInnerHTML={{ __html: t('DevPolicyT1') }}
          onClick={handleOnClick}
        ></p>
        <p className="smallText16Normal">{t('DevPolicyT2')}</p>
        <p className="smallText16Normal">{t('DevPolicyT3')}</p>
        <p className="smallText16Normal">{t('DevPolicyT4')}</p>
      </div>

      {devPolicy.map((policyList, index) => (
        <div className="mt-[30px] flex flex-col gap-4" key={index}>
          <p className="text22">
            {policyList.id}. <span className="pl-1">{policyList.name}</span>
          </p>

          <p className="smallText16Normal">{policyList.text}</p>

          {policyList.list.map((subPoints, index1) => (
            <BulletPoints
              data={subPoints}
              key={index1}
              pl="24px"
              handleOnClick={handleOnClick}
            />
          ))}

          {policyList.subText && (
            <>
              {policyList.subText.map((innerText, index2) => (
                <p key={index2} className="smallText16Normal">
                  {innerText}
                </p>
              ))}
            </>
          )}
        </div>
      ))}
    </div>
  );
};
const getTranslatedList = (t, key) => {
  const lists = {
    policyList: [
      {
        id: 'A',
        name: t('DevPolicy_A'),
        text: t('DevPolicy_A_T'),
        list: [
          t('DevPolicy_A_L1'),
          t('DevPolicy_A_L2'),
          t('DevPolicy_A_L3'),
          t('DevPolicy_A_L4'),
          t('DevPolicy_A_L5'),
          t('DevPolicy_A_L6'),
          t('DevPolicy_A_L7'),
          t('DevPolicy_A_L8'),
          t('DevPolicy_A_L9'),
        ],
        subText: [t('DevPolicy_A_Text1'), t('DevPolicy_A_Text2')],
      },
      {
        id: 'B',
        name: t('SecurityT'),
        text: t('SecurityT_T'),
        list: [
          t('SecurityT_L1'),
          t('SecurityT_L2'),
          t('SecurityT_L3'),
          t('SecurityT_L4'),
          t('SecurityT_L5'),
          t('SecurityT_L6'),
          t('SecurityT_L7'),
        ],
      },
      {
        id: 'C',
        name: t('DevPolicy_C'),
        text: t('DevPolicy_C_T'),
        list: [
          t('DevPolicy_C_L1'),
          t('DevPolicy_C_L2'),
          t('DevPolicy_C_L3'),
          t('DevPolicy_C_L4'),
          t('DevPolicy_C_L5'),
          t('DevPolicy_C_L6'),
        ],
      },
      {
        id: 'D',
        name: t('A_ComplianceLaws'),
        text: t('A_ComplianceLaws_T'),
        list: [
          t('A_ComplianceLaws_L1'),
          t('A_ComplianceLaws_L2'),
          t('A_ComplianceLaws_L3'),
          t('A_ComplianceLaws_L4'),
        ],
      },
      {
        id: 'E',
        name: t('DevPolicy_E'),
        text: t('DevPolicy_E_T'),
        list: [t('DevPolicy_E_L1'), t('DevPolicy_E_L2'), t('DevPolicy_E_L3')],
      },
      {
        id: 'F',
        name: t('DevPolicy_F'),
        text: t('DevPolicy_F_T'),
        list: [
          t('DevPolicy_F_L1'),
          t('DevPolicy_F_L2'),
          t('DevPolicy_F_L3'),
          t('DevPolicy_F_L4'),
        ],
        subText: [t('DevPolicy_F_Text')],
      },
    ],
  };

  return lists[key];
};

export default DeveloperPolicyContent;
