import React from 'react';
import Rectangle from '../../assets/images/Navbar/Rectangle.png'
import Star from '../../assets/images/Navbar/Star.png'
import Round from '../../assets/images/Navbar/Ellipse.png'

const HeroSectionBg = ({ children }) => {
  return (
    <div className='w-full relative max-w-[1920px]'>
      <div className="absolute w-full h-full max_md:h-[50%] inset-0 bg-BgPrimaryLight z-[-1] overflow-hidden" style={{
        // clip-path: 'polygon( 0.00% 0.00%, 100% 0.00%, 100% 18.22%, 100% 77.67%, 99.56% 82.00%, 98.89% 83.67%, 98.11% 85.33%, 96.67% 87.11%, 94.56% 88.78%, 92.56% 89.78%, 9.44% 100%, 8.44% 99.78%, 6.78% 99.33%, 5.33% 98.67%, 3.67% 97.67%, 3.22% 97.33%, 2.00% 96.00%, 1.11% 94.44%, 0.56% 92.89%, 0.00% 90.22%, 0.00% 82.33%, 0.00% 43.56%, 0.00% 38.22%, 0.00% 34.89% )'
        clipPath: 'polygon( 0% 0%,100% 0%,100% 63.119%,100% 63.119%,99.841% 66.829%,99.378% 70.371%,98.635% 73.703%,97.634% 76.781%,96.396% 79.56%,94.945% 81.999%,93.303% 84.053%,91.492% 85.678%,89.536% 86.832%,87.455% 87.469%,15.233% 99.838%,15.233% 99.838%,12.823% 99.886%,10.514% 99.229%,8.343% 97.925%,6.344% 96.031%,4.555% 93.605%,3.011% 90.703%,1.747% 87.383%,0.801% 83.702%,0.206% 79.718%,0% 75.488%,0% 0% )'
      }}>
        {/* SHAPES */}
        <div className="relative w-full h-full">
          <div className="absolute bottom-[-18px] left-[-228px] max_md:bottom-[-110px] max_md:left-[-235px] max_sm:left-[-112px] max_sm_380:left-[-100px] max_sm:w-[34%] max_sm:bottom-[-22px]">
            <img src={Rectangle} alt="back_rac" />
          </div>
          <div className="absolute top-[8px] right-[-62px] w-[12%] max_sm:w-[16%] max_sm:right-[-16px] max_sm:top-1">
            <img src={Round} alt="round" />
          </div>
          <div className="absolute bottom-[52px] right-[48px] max_md:top-[144px] max_md:w-[12%] max_md:right-[100px] max_sm:right-[70px] max_sm_380:top-[156px] max_sm:w-[24%] max_sm:top-[122px]">
            <img src={Star} alt="star" />
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

export default HeroSectionBg;
