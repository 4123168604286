import React, { useEffect, useRef, useState } from 'react';
import CommonSection from '../CommonSection';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import useVisibility from '../../Hooks/useVisibility';
import ChartDataCard from './ChartDataCard';
import { ButtonFilled } from '../utils/Button';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);
const CommunicationAreaComp = () => {
  const { t } = useTranslation();
  const [chartDetails, setChartDetails] = useState([]);
  const chartContainerRef = useRef(null);
  useEffect(() => {
    setChartDetails(getTranslatedList(t));
  }, [t]);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
    scales: {
      // to remove the labels
      x: {
        ticks: {
          display: false,
        },

        // to remove the x-axis grid
        grid: {
          drawBorder: false,
          display: false,
        },
      },
      // to remove the y-axis
      y: {
        ticks: {
          display: false,
          beginAtZero: false,
        },
        // to remove the y-axis grid
        grid: {
          drawBorder: false,
          display: false,
        },
      },
    },
  };

  const labels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];

  const data1 = {
    labels,
    datasets: [
      {
        fill: true,
        label: '',
        data: [350, 340, 355, 360, 355, 350, 360, 345, 340, 345, 350, 355, 360],
        borderColor: 'rgb(53, 162, 235)',
        borderWidth: 2,
        backgroundColor: (context) => {
          const { ctx, chartArea } = context.chart;
          if (!chartArea) {
            return;
          }
          const { top, bottom } = chartArea;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0.4, 'rgba(0, 160, 252, 0.3)');
          gradientBg.addColorStop(1, 'rgba(255,255,255,0.6)');
          return gradientBg;
        },
        radius: 0,
      },
      {
        fill: true,
        label: '',
        data: [360, 342, 346, 340, 335, 333, 340, 344, 350, 385, 380],
        borderColor: 'rgba(253, 182, 0)',
        borderWidth: 2,
        backgroundColor: (context) => {
          const { ctx, chartArea } = context.chart;
          if (!chartArea) {
            return;
          }
          const { top, bottom } = chartArea;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, 'rgba(253, 182, 0, 0.3)');
          gradientBg.addColorStop(1, 'rgba(255,255,255,0.6)');
          return gradientBg;
        },
        radius: 0,
      },
      {
        fill: false,
        label: '',
        data: [300, 335, 340, 330, 325, 333, 340, 318, 330, 350, 450],
        borderColor: 'rgba(255,255,255, 1 )',
        borderWidth: 1,
        radius: 0,
      },
    ],
  };
  const data2 = {
    labels,
    datasets: [
      {
        fill: true,
        label: '',
        data: [360, 350, 365, 370, 365, 360, 370, 355, 350, 355, 360, 365, 370],
        borderColor: 'rgb(53, 162, 235)',
        borderWidth: 2,
        backgroundColor: (context) => {
          const { ctx, chartArea } = context.chart;
          if (!chartArea) {
            return;
          }
          const { top, bottom } = chartArea;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0.4, 'rgba(0, 160, 252, 0.3)');
          gradientBg.addColorStop(1, 'rgba(255,255,255,0.6)');
          return gradientBg;
        },
        radius: 0,
      },
      {
        fill: true,
        label: '',
        data: [370, 352, 356, 350, 345, 343, 350, 354, 360, 395, 390],
        borderColor: 'rgba(253, 182, 0)',
        borderWidth: 2,
        backgroundColor: (context) => {
          const { ctx, chartArea } = context.chart;
          if (!chartArea) {
            return;
          }
          const { top, bottom } = chartArea;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, 'rgba(253, 182, 0, 0.3)');
          gradientBg.addColorStop(0.8, 'rgba(255,255,255,0.6)');
          return gradientBg;
        },
        radius: 0,
      },
      {
        fill: false,
        label: '',
        data: [300, 335, 340, 330, 325, 333, 340, 318, 330, 350, 400],
        borderColor: 'rgba(255,255,255, 1 )',
        borderWidth: 1,
        radius: 0,
      },
    ],
  };
  const data3 = {
    labels,
    datasets: [
      {
        fill: true,
        label: '',
        data: [
          400, 385, 380, 375, 390, 400, 385, 405, 390, 380, 385, 380, 370, 360,
        ],
        borderColor: 'rgb(53, 162, 235)',
        borderWidth: 2,
        backgroundColor: (context) => {
          const { ctx, chartArea } = context.chart;
          if (!chartArea) {
            return;
          }
          const { top, bottom } = chartArea;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0.4, 'rgba(0, 160, 252, 0.3)');
          gradientBg.addColorStop(1, 'rgba(255,255,255,0.6)');
          return gradientBg;
        },
        radius: 0,
      },
      {
        fill: true,
        label: '',
        data: [460, 440, 455, 450, 440, 430, 445, 455, 460, 500, 490],
        borderColor: 'rgba(253, 182, 0)',
        borderWidth: 2,
        backgroundColor: (context) => {
          const { ctx, chartArea } = context.chart;
          if (!chartArea) {
            return;
          }
          const { top, bottom } = chartArea;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, 'rgba(253, 182, 0, 0.3)');
          gradientBg.addColorStop(0.8, 'rgba(255,255,255,0.6)');
          return gradientBg;
        },
        radius: 0,
      },
      {
        fill: false,
        label: '',
        data: [300, 335, 340, 330, 325, 333, 340, 318, 330, 350, 400, 300],
        borderColor: 'rgba(255,255,255, 1 )',
        borderWidth: 1,
        radius: 0,
      },
    ],
  };

  const AnimIsInView = useVisibility(chartContainerRef, 0.5);
  return (
    <CommonSection>
      <div
        className="flex flex-col gap-10 max_md:pt-5 max_600:pt-0"
        ref={chartContainerRef}
      >
        <div className="flex gap-[30px] items-end justify-between min-h-[200px] max_md:hidden">
          <div className="chart-class w-[360px] ml-[-8px]">
            {AnimIsInView && (
              <Line options={options} data={data1} dot={false} />
            )}
          </div>
          <div className="chart-class w-[355px]">
            {AnimIsInView && (
              <Line options={options} data={data2} dot={false} />
            )}
          </div>
          <div className="chart-class w-[370px]">
            {AnimIsInView && (
              <Line options={options} data={data3} dot={false} />
            )}
          </div>
        </div>
        <div className="flex max_md:flex-col justify-between gap-[30px] max_md:hidden">
          {chartDetails.map((details, index) => (
            <ChartDataCard details={details} key={index} isBy />
          ))}
        </div>
        {/* Tablate design */}
        <div className="hidden max_md:block max_600:hidden">
          <div className="flex flex-col max_md:gap-[60px]">
            <div className="flex justify-between gap-[30px] items-center max_720:gap-4 max_600:hidden">
              <div>
                <Line options={options} data={data1} dot={false} />
              </div>
              <div>
                <ChartDataCard details={chartDetails[0]} isBy />
              </div>
            </div>
            <div className="flex justify-between gap-[30px] items-center max_720:gap-4 max_600:hidden">
              <div>
                <Line options={options} data={data2} dot={false} />
              </div>
              <div>
                <ChartDataCard details={chartDetails[1]} isBy />
              </div>
            </div>
            <div className="flex justify-between gap-[30px] items-center max_720:gap-4 max_600:hidden">
              <div>
                <Line options={options} data={data3} dot={false} />
              </div>
              <div>
                <ChartDataCard details={chartDetails[2]} isBy />
              </div>
            </div>
          </div>
        </div>

        {/* Mobile DEIGN */}
        <div className="hidden max_600:block">
          <div className="flex flex-col gap-10">
            {chartDetails.map((details, index) => (
              <ChartDataCard details={details} key={index} isBy />
            ))}
          </div>
          <div className="max_600:block mt-10">
            <a
              href="https://calendly.com/dcdial-sales/30min"
              rel="noopener noreferrer"
              target="_blank"
            >
              <ButtonFilled
                title={t('BookADemo')}
                fontSize="16px"
                width="140px"
                fontWeight="600"
                height="48px"
              />
            </a>
          </div>
        </div>
      </div>
    </CommonSection>
  );
};
const getTranslatedList = (t) => {
  const list = [
    {
      text: t('ComChart1'),
      data: '10 - 20%',
    },
    {
      text: t('ComChart2'),
      data: '25%',
    },
    {
      text: t('ComChart3'),
      data: '86%',
    },
  ];

  return list;
};

export default CommunicationAreaComp;
