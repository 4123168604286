import React from 'react';
import { UseDcDialButton } from '../../Components/utils/Button';
import Layout from '../../Components/Layout';

const UninstallSuccess = () => {
  return (
    <Layout>
      <div className="backImage">
        <div className="py-10 h-[calc(100vh-80px)] overflow-y-auto">
          <div className="flex flex-col gap-5 items-center justify-center h-full">
            <p className="text-[30px] font-DMSansFont font-bold text-LightPrimary max-w-[490px] text-center">
              😢 We’re sorry to see you go DCDial was uninstalled
            </p>
            <p className="text-LightTextParagraph text-[22px] font-DMSansFont font-medium max-w-[800px] text-center">
              We will make every effort to address your concerns. We read and
              individually reply to every mail, often with a solution!
            </p>
            <div>
              <UseDcDialButton width="236px" title="Continue to use DCDial" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UninstallSuccess;
